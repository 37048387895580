import React, { useMemo } from 'react'
import { Button, Grid } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { UseParamsType } from 'types'
import encouragementpng from '../../../assets/illustrations/encouragement.png'

interface ListTokenParams extends UseParamsType {
  loc: string
}
const { REACT_APP_IMATTER_HOME_URL, REACT_APP_IMATTER_HOME_ES_URL } = process.env
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backgroundstyle: {
      backgroundImage: `url(${encouragementpng})`,
      backgroundPosition: 'center',
      backgroundSize: '100%',
      backgroundRepeat: 'no-repeat',
      minHeight: '100%'
    },
    buttonStyles: {
      color: 'white',
      padding: '6px 25px',
      fontSize: '12px',
      fontWeight: 600,
      borderRadius: '25px',
      marginRight: '5px',
      textTransform: 'capitalize'
    },
    button1bg: {
      border: '1px solid #F1583A',
      backgroundColor: '#F1583A',
      '&:hover': {
        backgroundColor: '#F1583A'
      }
    },
    button2bg: {
      border: '1px solid #00004F',
      backgroundColor: '#00004F',
      '&:hover': {
        backgroundColor: '#00004F'
      }
    },
    textstyle: {
      textDecoration: 'none'
    },
    outerGrid: {
      minHeight: 'calc(100vh - 186px)',
      display: 'flex',
      width: '100%',
      fontFamily: 'Poppins !important',
      backgroundColor: '#FF51A7'
    },
    popin: {
      fontFamily: 'Poppins !important'
    },
    divsec: {
      minHeight: 'calc(100vh - 120px)',
      width: '82%',
      margin: 'auto',
      padding: '50px 0px',
      [theme.breakpoints.down('xs')]: {
        width: '98%',
        padding: '5px 0px'
      }
    },
    fullH: {
      height: '100%'
    },
    dflex: {
      display: 'flex'
    },
    divCenter: {
      margin: 'auto',
      padding: '40px 60px'
    },
    textJustify: {
      textAlign: 'justify'
    },
    fontSCol: {
      fontSize: '18px'
      // color: '#4f9ed7'
    },
    mt10: {
      marginTop: '10px'
    },
    mt15: {
      marginTop: '15px'
    },
    mt20: {
      marginTop: '25px'
    },
    ml20: {
      marginLeft: '20px',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '0px'
      }
    },
    fleft: {
      float: 'left',
      [theme.breakpoints.down('xs')]: {
        float: 'none'
      }
    },
    dInline: {
      display: 'inline-block'
    },
    fs12: {
      fontSize: '12px'
    },
    fs14: {
      fontSize: '14px'
    },
    fs25: {
      fontSize: '25px'
    },
    whiteBg: {
      backgroundColor: '#FFF'
    },
    bold: {
      fontWeight: 'bold'
    },
    alink: {
      color: 'rgba(0, 0, 0, 0.87)'
    },
    textcolor: {
      color: '#F1583A',
      fontSize: '15px',
      fontWeight: 'bold'
    }
  })
)

export const EncouragingView = () => {
  const classes = useStyles()
  const { loc } = useParams<ListTokenParams>()
  const { t, i18n } = useTranslation()
  useMemo(() => {
    if (loc === 'en' || loc === 'es') i18n.changeLanguage(loc || 'en')
    else i18n.changeLanguage('en')
  }, [i18n, loc])
  const params = new URLSearchParams(window.location.search)
  const locallang = i18n?.language || 'en'
  let isAgeAbove18 = false

  if (params.get('q') === 'true') isAgeAbove18 = true

  return (
    <>
      <Grid className={classes.outerGrid}>
        <div className={classes.divsec}>
          <Grid
            container
            className={`${classes.fullH} ${classes.dflex} ${classes.whiteBg} ${classes.popin}`}
          >
            <Grid item md={12} sm={12} className={classes.dflex}>
              <div className={classes.divCenter}>
                <Grid item md={12} sm={12} className={classes.fontSCol}>
                  {!isAgeAbove18 && (
                    <>
                      <div className={`${classes.mt10} ${classes.fs12} ${classes.bold}`}>
                        {t('youthsurveypage.encourage1')}
                      </div>
                      <div className={`${classes.mt15} ${classes.bold} ${classes.fs25}`}>
                        {t('youthsurveypage.encourage2')}
                      </div>
                      <div className={`${classes.mt20} ${classes.fs14}`}>
                        <div>{t('youthsurveypage.encourage3')}</div>
                      </div>
                      <div className={`${classes.mt20} ${classes.fs14}`}>
                        <div>
                          {t('youthsurveypage.encourage4')}{' '}
                          <a
                            className={`${classes.fs14} ${classes.alink}`}
                            href={`${
                              i18n.language === 'es'
                                ? REACT_APP_IMATTER_HOME_ES_URL
                                : REACT_APP_IMATTER_HOME_URL
                            }/content/resource.pdf`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {t('youthsurveypage.encourage5')}
                          </a>{' '}
                          {t('youthsurveypage.encourage6')}
                        </div>
                      </div>
                      <div className={`${classes.mt20} ${classes.fs14}`}>
                        <div>
                          {t('youthsurveypage.encourage7')}
                          {', '}
                          {t('youthsurveypage.ccsT1')}
                          {', '}
                          {t('youthsurveypage.ccsT2')}{' '}
                          <a
                            href={`${
                              locallang === 'es'
                                ? 'https://coloradocrisisservices.org/es/'
                                : 'https://coloradocrisisservices.org'
                            }`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t('youthsurveypage.ccsT3')}
                          </a>{' '}
                          {t('youthsurveypage.ccsT4')}
                        </div>
                      </div>
                      <div className={`${classes.mt20} ${classes.dInline}`}>
                        <div className={classes.fleft}>
                          <a
                            href={
                              i18n.language === 'es'
                                ? REACT_APP_IMATTER_HOME_ES_URL
                                : REACT_APP_IMATTER_HOME_URL
                            }
                            rel="noreferrer"
                            target="_self"
                            className={`${classes.fs14} ${classes.ml20} ${classes.alink}`}
                          >
                            {t('youthsurveypage.encourage10')}
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                  {isAgeAbove18 && (
                    <>
                      <div className={`${classes.mt15} ${classes.bold} ${classes.fs25}`}>
                        {t('parentsurveypage.encourageaboveage1')}
                      </div>
                      <div className={`${classes.mt20} ${classes.fs14}`}>
                        {t('parentsurveypage.encourageaboveage2')}{' '}
                        <a
                          className={`${classes.fs14} ${classes.alink}`}
                          href={`${
                            i18n.language === 'es'
                              ? REACT_APP_IMATTER_HOME_ES_URL
                              : REACT_APP_IMATTER_HOME_URL
                          }/content/resource.pdf`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {t('parentsurveypage.encourageaboveage3')}
                        </a>{' '}
                        {t('parentsurveypage.encourageaboveage4')}
                      </div>
                      <div className={`${classes.mt20} ${classes.textcolor}`}>
                        <div>
                          {t('parentsurveypage.ccsT1')}
                          {', '}
                          {t('parentsurveypage.ccsT2')}{' '}
                          <a
                            href={`${
                              locallang === 'es'
                                ? 'https://coloradocrisisservices.org/es/'
                                : 'https://coloradocrisisservices.org'
                            }`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t('parentsurveypage.ccsT3')}
                          </a>{' '}
                          {t('parentsurveypage.ccsT4')}
                        </div>
                      </div>
                    </>
                  )}
                  <div style={{ marginTop: '50px', borderBottom: '1px solid lightGrey' }} />
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  )
}
