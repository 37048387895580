import { makeStyles, createStyles, Theme, createMuiTheme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    width: {
      marginLeft: '147px',
      marginTop: '-22px'
    },
    paddxy: {
      padding: '6px 32px',
      fontSize: '16px'
    },
    paddry: {
      padding: '6px 32px 6px 0px',
      fontSize: '16px'
    }
  })
)
