/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography, TextField } from '@material-ui/core'
import {
  useGetAdminUserQuery,
  useChangePasswordMutation,
  useUserPasswordTokenQuery
} from 'graphql/hooks.generated'
import { theme } from 'config/constants/theme'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import Dialog from 'components/ui/panel/dialog/Dialog'
import IconButton from 'components/ui/panel/icon-button/IconButton'
import useStyles from './Style'
import { AuthenticationContext } from '../../../types'
import { useAuth } from '../../../components/providers/auth'

export type ChangePasswordFormProps = {
  email: string
  id: string
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  email,
  id
}: ChangePasswordFormProps) => {
  const classes = useStyles()
  const { logout }: AuthenticationContext = useAuth()
  const [open, setOpen] = React.useState(true)
  // const [currPaswd, setCurrPaswd] = useState<string>('')
  // const [showcurrPaswd, toggleCurrPaswd] = useState<boolean>(false)
  const [newPaswd, setNewPaswd] = useState<string>('')
  const [shownewPaswd, toggleNewPaswd] = useState<boolean>(false)
  const [confiPaswd, setConfiPaswd] = useState<string>('')
  const [showconfiPaswd, toggleConfiPaswd] = useState<boolean>(false)
  const [showErrorPattern, setErrorPattern] = useState<string>('')
  const [showSpinner, setSpinner] = useState<boolean>(false)
  const [changPasswdErrMsg, setchangPasswdErrMsg] = useState<string>('')
  const [changPasswdSusMsg, setchangPasswdSusMsg] = useState<string>('')
  let showChangePasswordBox = false
  const { data, loading } = useGetAdminUserQuery({
    variables: { username: email },
    fetchPolicy: 'network-only'
  })
  const { data: tokenPassword } = useUserPasswordTokenQuery({
    variables: { id: id },
    fetchPolicy: 'network-only'
  })
  const [changePasswordMutation] = useChangePasswordMutation()

  if (data && data?.getAdminUser && data?.getAdminUser.IsPasswordExpaired)
    showChangePasswordBox = true
  else showChangePasswordBox = false

  useEffect(() => {})
  const closeDialog = () => {
    setOpen(false)
  }
  const changePasswordFn = async (id, { Password }) => {
    setSpinner(true)
    try {
      await changePasswordMutation({
        variables: { input: { Password }, token: String(tokenPassword?.userPasswordToken) }
      })
      setSpinner(false)
      setchangPasswdSusMsg('Password changed successfully.')
      setTimeout(() => {
        closeDialog()
        logout()
      }, 3000)
    } catch (e) {
      const errmsg = e.graphQLErrors[0].message
      // console.log(errmsg)
      setchangPasswdErrMsg(errmsg)
      setSpinner(false)
    }
  }
  const submitDialog = () => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{7,15}$/

    if (!newPaswd.match(regex) || !confiPaswd.match(regex)) {
      setErrorPattern(
        'Password should contain 8 - 15 characters with at least one uppercase, lowercase, numeric and special character, white space not allowed!'
      )

      return
    }

    const inputdata = {
      Password: newPaswd
    }
    changePasswordFn(id, inputdata)
  }
  const isNewConfirmPawdMatch = () => {
    if (newPaswd === confiPaswd) return true

    return false
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {loading || showSpinner ? <ProgressIndicator fullHeight /> : ''}
      {showChangePasswordBox && (
        <Dialog
          handleClose={closeDialog}
          handleSubmit={submitDialog}
          disabled={
            !(
              newPaswd &&
              newPaswd.length >= 8 &&
              confiPaswd &&
              confiPaswd.length >= 8 &&
              isNewConfirmPawdMatch()
            )
          }
          submit="Submit"
          open={open}
          backDrop
          custSubmitBtn
        >
          <form>
            <Grid className={classes.resetPassword}>
              <Grid className={classes.pb10}>
                <Typography variant="h1">Reset Password?</Typography>
                <p>Password need to be reset every 90 Days.</p>
                <p style={{ fontSize: '12px' }}>
                  Password should have at least one uppercase, lowercase, numeric and special
                  character !
                </p>
              </Grid>

              <Grid item sm={12} className={classes.mb10}>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    fullWidth
                    label="New Password"
                    id="new-password"
                    type={shownewPaswd ? 'text' : 'password'}
                    value={newPaswd}
                    variant="filled"
                    name="email"
                    onChange={(e) => {
                      setNewPaswd(e.target.value)
                      setErrorPattern('')
                      setchangPasswdErrMsg('')
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className={classes.rightIcon} position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => toggleNewPaswd(!shownewPaswd)}
                            edge="end"
                          >
                            {shownewPaswd ? (
                              <Visibility className={classes.EyeIcon} />
                            ) : (
                              <VisibilityOff className={classes.EyeIcon} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} className={classes.mb10}>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    label="Confirm Password"
                    fullWidth
                    id="confim-password"
                    type={showconfiPaswd ? 'text' : 'password'}
                    value={confiPaswd}
                    variant="filled"
                    name="email"
                    onChange={(e) => {
                      setConfiPaswd(e.target.value)
                      setErrorPattern('')
                      setchangPasswdErrMsg('')
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className={classes.rightIcon} position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => toggleConfiPaswd(!showconfiPaswd)}
                            edge="end"
                          >
                            {showconfiPaswd ? (
                              <Visibility className={classes.EyeIcon} />
                            ) : (
                              <VisibilityOff className={classes.EyeIcon} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid>
                {!isNewConfirmPawdMatch() && (
                  <div style={{ color: theme.palette.error.dark }}>Password not match</div>
                )}
                {showErrorPattern && (
                  <div style={{ color: theme.palette.error.dark }}>{showErrorPattern}</div>
                )}
                {changPasswdErrMsg && (
                  <div style={{ color: theme.palette.error.dark }}>{changPasswdErrMsg}</div>
                )}
                {changPasswdSusMsg && (
                  <div style={{ color: theme.palette.success.dark }}>
                    <strong>{changPasswdSusMsg}</strong>
                  </div>
                )}
              </Grid>
            </Grid>
          </form>
        </Dialog>
      )}
    </Box>
  )
}

export default ChangePasswordForm
