import { Box, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useDrop, DragObjectWithType } from 'react-dnd'
import GraphImg from 'graphcms-image'

export enum DropAreaGame {
  ordering = 'ordering',
  dragReveal = 'drag-reveal'
}

interface IGraphImage {
  handle: string
  height: number
  width: number
}

interface IDropItem extends DragObjectWithType {
  order?: number | string | null | undefined
  image?: IGraphImage | null | undefined
}

interface DropAreaProps {
  spotNumber?: number
  game?: string
  imageWidth?: number
}

export const DropArea = (props: DropAreaProps) => {
  const { spotNumber, game, imageWidth } = props
  const [correctImage, setCorrectImage] = useState<IGraphImage | null | undefined>()
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'card',
    canDrop: (item: IDropItem) => {
      if (game === DropAreaGame.ordering) {
        if (spotNumber === item.order) return true

        return false
      }

      return true
    },
    drop: (item: IDropItem) => {
      if (game === DropAreaGame.ordering) setCorrectImage(item.image)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  })
  const isActive = canDrop && isOver

  return (
    <Box
      borderRadius={16}
      bgcolor={isActive ? 'success.main' : 'white'}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      style={{ overflow: 'hidden' }}
    >
      <div
        style={{
          width: '100%',
          minHeight: 200,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        ref={drop}
      >
        {game === DropAreaGame.dragReveal && (
          <Typography gutterBottom variant="subtitle1" color="secondary">
            {isActive ? 'Drop em!' : 'Drag cards here'}
          </Typography>
        )}
        {game === DropAreaGame.ordering && !correctImage && (
          <Typography gutterBottom variant="subtitle1" color="secondary">
            {spotNumber}
          </Typography>
        )}
        {game === DropAreaGame.ordering && correctImage && (
          <GraphImg
            image={correctImage}
            style={{ width: '100%', minWidth: imageWidth, minHeight: 200 }}
            maxHeight={400}
          />
        )}
      </div>
    </Box>
  )
}
