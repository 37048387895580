import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import {
  Box,
  Grid,
  styled,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  TableBody
} from '@material-ui/core'
import { useActiveproviderQuery } from 'graphql/hooks.generated'
import { UseParamsType } from 'types'
import moment from 'moment'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { Title } from '@material-ui/icons'
import { useStyles } from '../../../components/ui/my-generated-reports/Styles'
import { PageContainer } from '../../../components/ui/panel/page-container/PageContainer'
import Paper from '../../../components/ui/panel/paper/Paper'
import Button from '../../../components/ui/panel/button/Button'

interface ListActiveproviderViewParams extends UseParamsType {
  id: string
}

export const ActiveproviderView: React.FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { id: studentId } = useParams<ListActiveproviderViewParams>()
  const classes = useStyles()
  const { data, loading, refetch } = useActiveproviderQuery({
    variables: { student_id: Number(studentId) },
    fetchPolicy: 'network-only'
  })
  const [activeprovidersData, setData] = useState<any>()
  const isLoading = () => (loading ? <ProgressIndicator fullHeight /> : '')
  const Title = styled(Typography)(({ theme }) => ({}))
  const dataname = data?.activeproviders?.[0]
  useEffect(() => {
    // console.log(dataname)

    if (data !== undefined && activeprovidersData === undefined) {
      const active = 'active'

      if (active && active.trim().length > 0) {
        const activeprovidersData = data?.activeproviders?.filter(
          (activeprovider) => activeprovider.id.toLocaleLowerCase() === active
        )
        setData(activeprovidersData)
      }
    }
  }, [dataname, data, activeprovidersData])
  const handleChange = (e) => {
    const keyWord = e.target.value

    if (keyWord && keyWord.trim().length > 0) {
      const activeprovidersData = data?.activeproviders?.filter((obj) => {
        if (obj.id.toLocaleLowerCase() === keyWord.toLocaleLowerCase()) return obj

        return false
      })
      setData(activeprovidersData)
    }
  }

  return (
    <>
      <PageContainer>
        <Grid>
          {/* {data?.activeproviders?.map((activeprovider) => ( */}

          <Box py={3} display="flex" justifyContent="center">
            <Title variant="h2">
              {' '}
              Active Providers For Clients : {dataname?.student_first_name}{' '}
              {dataname?.student_last_name}
            </Title>
          </Box>
          {/* ))} */}
        </Grid>

        <Grid>
          <TableContainer square={false} component={Paper}>
            <Table>
              <TableHead className={classes.headRoot}>
                <TableRow>
                  <TableCell>PROVIDER</TableCell>
                  <TableCell>MINUTES</TableCell>
                  <TableCell>TIME PERIOD</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.activeproviders?.map((activeprovider) => (
                  <TableRow key={activeprovider.id}>
                    <TableCell>
                      {activeprovider.provider_first_name} {activeprovider.provider_last_name}
                    </TableCell>
                    <TableCell>{activeprovider.minutes}</TableCell>
                    <TableCell>{activeprovider.time_period}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Box py={3} display="flex" alignItems="center" justifyContent="any">
          <section style={{ padding: '25px 500px' }}>
            <Button color="inherit" variant="contained" size="small" onClick={history.goBack}>
              Back to Clients
            </Button>
          </section>
        </Box>
        {isLoading}
      </PageContainer>
    </>
  )
}
