/* eslint-disable import/no-duplicates */
import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography
} from '@material-ui/core'
import { DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs'
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs'
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons'
import moment from 'moment'
import Button from '../../../../components/ui/panel/button/Button'
import {
  useUpdateOngoingCareMutation,
  useAdminOngoingUsersListQuery,
  useInprogressInsertMutation,
  useFurtherInsertMutation,
  useReferralInsertMutation
} from '../../../../graphql/hooks.generated'
import { UseParamsType } from '../../../../types'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import { useCurrentUser } from '../../../../graphql/hooks/useCurrentUser/useCurrentUser'

export interface ActionsDailogParams {
  action: string
  data: any
  cancel?: any
}

export interface TextDetailParams {
  name: any
  value: any
  type: any
  data?: any
  onChange?: any
  error?: any
}

const TextDetail = (props: TextDetailParams) => {
  const { name, value, type, data, onChange, error } = props
  const therapistFields = { text: 'name', value: 'id' }

  return (
    <div style={{ display: 'flex', padding: '3px 10px', alignItems: 'center' }}>
      <Typography style={{ width: '130px', color: error ? 'red' : '' }}>{name}</Typography>
      <Typography>:</Typography>
      {type === 'text' && <Typography style={{ padding: '0px 10px' }}>{value}</Typography>}
      {type === 'select' && (
        <div style={{ width: 'calc(100% - 140px)', padding: '0px 10px' }}>
          <DropDownListComponent
            id={name}
            //   openOnClick
            placeholder="Select Assignee"
            data-name="EventTherapist"
            className="e-field"
            fields={therapistFields}
            dataSource={data}
            value={value}
            change={(value) => onChange(value)}
          />
        </div>
      )}
    </div>
  )
}

export const ActionDialog = (props: ActionsDailogParams) => {
  const { action, data, cancel } = props
  const { data: adminList, loading, refetch } = useAdminOngoingUsersListQuery({
    fetchPolicy: 'network-only'
  })
  const { data: currentUserData } = useCurrentUser()
  const [updateOngoingCare, { loading: updateOngoingLoading }] = useUpdateOngoingCareMutation()
  const [InprogressUpdate, { loading: inprogressUpdating }] = useInprogressInsertMutation()
  const [FurtherSessionUpdate, { loading: FurtherSessionUpdating }] = useFurtherInsertMutation()
  const [CompleteReferal, { loading: CompleteReferalLoding }] = useReferralInsertMutation()
  const therapistData = [
    { name: 'therapist1', id: '1' },
    { name: 'therapist2', id: '2' },
    { name: 'therapist3', id: '3' }
  ]
  const isLoading = () =>
    inprogressUpdating ||
    updateOngoingLoading ||
    FurtherSessionUpdating ||
    CompleteReferalLoding ? (
      <ProgressIndicator fullHeight />
    ) : (
      ''
    )
  const [assignee, setAssinee] = useState<any>()
  const [approved, setApproved] = useState<any>(false)
  const [denied, setDenied] = useState<any>(false)
  const [assigneeError, setAssineeError] = useState(false)
  const [inprogressError, setInprogressError] = useState(false)
  const [inProgressText, setInProgressText] = useState<any>(null)
  const [grantedNumber, setGrantedNumber] = useState<any>(null)
  const [FurtherError, setFurtherError] = useState(false)
  const [referalDate, setReferalDate] = useState(null)
  const [referalError, setReferalError] = useState(false)
  const [charLength, setCharLength] = useState(false)
  const addAssignee = async () => {
    if (assignee?.value)
      updateOngoingCare({
        variables: {
          input: {
            action_id: '1',
            assigned_to: assignee?.value,
            id: data.id
          }
        }
      }).then(() => cancel('refech'))
    else setAssineeError(true)
  }
  const InprogressInsert = () => {
    if (inProgressText && !charLength)
      InprogressUpdate({
        variables: {
          id: data.id,
          input: {
            note: inProgressText,
            student_id: data?.student_id?.id,
            created_by: currentUserData?.currentUser?.id
          }
        }
      }).then(() => cancel('refech'))
    else if (!inProgressText) setInprogressError(true)
  }
  const furtherSesionUpdateCall = () => {
    if (denied)
      FurtherSessionUpdate({
        variables: {
          id: data.id,
          input: {
            further_sessions_approval: false,
            student_id: data?.student_id?.id,
            created_by: currentUserData?.currentUser?.id
          }
        }
      }).then(() => cancel('refech'))
    else if (approved && grantedNumber)
      FurtherSessionUpdate({
        variables: {
          id: data.id,
          input: {
            further_sessions_approval: true,
            student_id: data?.student_id?.id,
            session_approval: Number(grantedNumber),
            created_by: currentUserData?.currentUser?.id
          }
        }
      }).then(() => cancel('refech'))
    else setFurtherError(true)
  }
  const onDeniedCheckbox = (value) => {
    setDenied(value)
    setApproved(false)
    setGrantedNumber(null)
    setFurtherError(false)
  }
  const onApprovedCheckbox = (value) => {
    setDenied(false)
    setApproved(value)
    setFurtherError(false)
  }
  const referalSumit = () => {
    if (referalDate)
      CompleteReferal({
        variables: {
          id: data.id,
          input: {
            referral_date: referalDate,
            student_id: data?.student_id?.id,
            created_by: currentUserData?.currentUser?.id
          }
        }
      }).then(() => cancel('refech'))
    else setReferalError(true)
  }
  const checkInprogressNotes = (value) => {
    setInProgressText(value?.value || '')

    if (value?.value?.length < 1000) setCharLength(false)
    else setCharLength(true)
  }

  return (
    <>
      {isLoading()}
      {action === 'Assign' && (
        <div style={{ minHeight: '120px', minWidth: '350px' }}>
          <h4>Assign Ongoingcare</h4>
          {/* <TextDetail name="Assign Ongoincare" value="" /> */}
          <TextDetail
            name="Client Name "
            value={`${data?.student_id?.first_name} ${data?.student_id?.last_name}`}
            type="text"
          />
          <TextDetail
            name="Contact Details"
            value={`${data?.student_id?.contact_email} | ${data?.student_id?.contact_phone_number}`}
            type="text"
          />
          <TextDetail
            name="Age"
            value={moment(data?.student_id?.birth_date, 'YYYYMMDD').fromNow().split('years ago')}
            type="text"
          />
          <TextDetail
            name="Insurance"
            value={data?.student_id?.insurance_company?.name}
            type="text"
          />
          <TextDetail
            name="Assign To*"
            value=""
            type="select"
            data={adminList?.adminUsersList || []}
            onChange={(value) => setAssinee(value)}
            error={!assignee?.value && assigneeError}
          />
        </div>
      )}
      {action === 'In Progress' && (
        <div style={{ minHeight: '120px', minWidth: '350px' }}>
          <h4 style={{ color: inprogressError && !inProgressText ? 'red' : '' }}>
            In Progress Notes*
          </h4>
          <TextBoxComponent
            className="p-x"
            value={inProgressText}
            input={(value) => checkInprogressNotes(value)}
            multiline
            // maxlength={1000}
          />
          {charLength && (
            <Typography style={{ color: 'red' }}> Please enter below 1000 characters</Typography>
          )}
          {/* <TextDetail name="Assign Ongoincare" value="" /> */}
        </div>
      )}
      {action === 'Completed' && data?.further_session && (
        <div style={{ minHeight: '120px', minWidth: '350px' }}>
          <h4 style={{ color: FurtherError && !approved && !denied ? 'red' : '' }}>
            Approval for further Sessions*
          </h4>
          <div style={{ display: 'flex' }}>
            <CheckBoxComponent
              label="Approved"
              checked={approved}
              change={(value) => onApprovedCheckbox(value?.checked)}
            />
            <div style={{ marginLeft: '20px' }}>
              <CheckBoxComponent
                label="Denied"
                checked={denied}
                change={(value) => onDeniedCheckbox(value?.checked)}
              />
            </div>
          </div>
          {approved && (
            <>
              <h4 style={{ color: FurtherError && !grantedNumber ? 'red' : '' }}>
                If approved, number of Sessions granted
              </h4>
              <div style={{ width: '100px' }}>
                {/* <TextBoxComponent
              className="p-x"
              value={inProgressText}
              change={(value) => setInProgressText(value?.value || '')}
              type="number"
            /> */}
                <NumericTextBoxComponent
                  min={0}
                  step={1}
                  change={(value) => setGrantedNumber(value?.value || '')}
                  format="###.##"
                />
              </div>
            </>
          )}
          {/* <TextDetail name="Assign Ongoincare" value="" /> */}
        </div>
      )}
      {action === 'Completed' && !data?.further_session && (
        <div style={{ minHeight: '120px', minWidth: '350px' }}>
          <h4 style={{ color: referalError && !referalDate ? 'red' : '' }}>
            Resources/Referrals sent to provider*
          </h4>
          <DatePickerComponent
            id="datepicker"
            placeholder="Select date"
            min={new Date(moment.tz('US/Mountain').subtract(1, 'months').format('MM-DD-YYYY'))}
            onChange={(value) => setReferalDate(value.value)}
          />
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          color="primary"
          style={{ margin: '5px' }}
          onClick={() => cancel('cancel')}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '5px' }}
          onClick={() =>
            action === 'Assign'
              ? addAssignee()
              : action === 'In Progress'
              ? InprogressInsert()
              : action === 'Completed' && data?.further_session
              ? furtherSesionUpdateCall()
              : referalSumit()
          }
        >
          {action === 'Assign' ? 'Assign' : 'Save'}
        </Button>
      </div>
    </>
  )
}

export default ActionDialog
