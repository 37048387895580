import { Box, CardMedia, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { PageContainer } from '../../../components/ui/panel/page-container/PageContainer'
import { ResourceQuery } from '../../../graphql/hooks.generated'
import { CmsResourceContentType } from '../../../graphql/enumTypes'

interface ArticleViewProps {
  articleResource: ResourceQuery['GCMS_resource']
}

export const ArticleView = (props: ArticleViewProps) => {
  const { articleResource } = props

  if (articleResource?.content?.__typename !== CmsResourceContentType.Article)
    throw Error('Unexpected type of Resource content')

  return (
    <PageContainer>
      <CardMedia
        component="img"
        height="320"
        image={articleResource?.coverImage.url}
        style={{
          borderRadius: '6px'
        }}
      />

      <Box my={3}>
        <Typography gutterBottom variant="h1" color="secondary">
          {articleResource?.title}
        </Typography>
      </Box>

      <Typography variant="body1" color="textPrimary" style={{ lineHeight: 2 }}>
        <ReactMarkdown source={articleResource?.content?.body} />
      </Typography>

      <Typography variant="body2" color="textSecondary" component="p">
        Last updated: {format(new Date(articleResource?.updatedAt), 'MM/dd/yyyy')}
      </Typography>
    </PageContainer>
  )
}
