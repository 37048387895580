import React, { useMemo, useEffect } from 'react'
import { Select } from 'mui-rff'
import { MenuItem } from '@material-ui/core'
import { useStatesLazyQuery } from '../../../../graphql/hooks.generated'
import { useStyles } from '../Styles'

interface StateSelectProps {
  label: string
  name: string
  required?: boolean
}

export const StateSelect = (props: StateSelectProps) => {
  const classes = useStyles()
  const [executeQuery, { data }] = useStatesLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetch = useMemo(() => () => executeQuery(), [executeQuery])
  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <>
      {data?.states?.length && (
        <Select {...props} variant="filled" className={classes.selectDropdown}>
          {data?.states?.map((state) => (
            <MenuItem key={state.id} value={Number(state.id)}>
              {state.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  )
}
