import { useState } from 'react'
import { AuthenticationTokens } from '../../../graphql/hooks.generated'

const { REACT_APP_API_URL, REACT_APP_COGNITO_CLIENT_ID: clientId } = process.env

export type ExecutionQuery = (code?: string, verifier?: string | null) => Promise<void>

export type QueryState = {
  data: AuthenticationTokens | null
  loading: boolean
  reset(): void
}

export const useAuthenticationTokensQuery = (): [ExecutionQuery, QueryState] => {
  const [data, setData] = useState<AuthenticationTokens | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const reset = () => setData(null)
  const pms: ExecutionQuery = async (code, verifier) => {
    if (!code) throw Error('No code provided')
    if (!verifier) throw Error('No verifier provided')

    try {
      setLoading(true)
      const response = await fetch(`${REACT_APP_API_URL}/graphql`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify([
          {
            operationName: 'AuthenticationTokens',
            query: `query AuthenticationTokens($code: String!, $verifier: String!, $clientId: String!) {
            authenticationTokens(code: $code, verifier: $verifier, clientId: $clientId) {
                id,
                access
            }
          }`,
            variables: { code, verifier, clientId },
            code,
            verifier,
            clientId
          }
        ])
      })
      const {
        data: { authenticationTokens }
      } = (await response.json())[0]
      setData(authenticationTokens)
      setLoading(false)
    } catch (e) {
      throw new Error('Error while fetching token')
    }
  }

  return [
    pms,
    {
      data,
      loading,
      reset
    }
  ]
}

export default useAuthenticationTokensQuery
