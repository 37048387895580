import React, { useMemo, useEffect, useRef, useState, useCallback } from 'react'
import { Button, Grid, Link, MenuItem, Typography, Select, InputLabel } from '@material-ui/core'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import {
  useGetUpdateSlotsListQuery,
  useGetUserAvailableProfileSlotsLazyQuery,
  useSignalServicesQuery,
  useCurrentUserQuery,
  useCreateNewProfileSlotsMutation,
  useGetUpdateSlotsListLazyQuery,
  useDeleteNewProfileSlotsMutation,
  useGetMasterSlotsLazyQuery
} from 'graphql/hooks.generated'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import CloseIcon from '@material-ui/icons/Close'
import { UseParamsType } from '../../../../types'

interface ListUserViewParams extends UseParamsType {
  userid: string
}

export interface DayEditFormProps {
  open: boolean
  handleClose: () => unknown
  slotDetails: any
  serviceid: any
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiDialog-paperScrollPaper': {
        height: 'calc(100% - 64px)'
      },
      '& .MuiOutlinedInput-input': {
        padding: '8.5px 14px'
      }
    },
    root1: {
      '& .MuiOutlinedInput-root': {
        width: '210px',
        height: '35px'
      }
    },
    inpersonBg: {
      background: '#90EE90'
    },
    teleBg: {
      background: '#ADD8E6'
    },
    bothBg: {
      background: '#FFFF66'
    },
    btn: {
      color: '#fff',
      border: '1px solid rgba(241, 88, 58, 0.5)',
      background: '#F1583A',
      '&:hover': {
        backgroundColor: '#F1583A',
        boxShadow: 'none'
      }
    },
    fs20: {
      fontSize: '20px'
    },
    closeicon: {
      position: 'absolute',
      top: '-10px',
      right: '-10px',
      borderRadius: '50%',
      height: '20px',
      cursor: 'pointer'
    },
    blackclr: {
      color: '#000'
    }
  })
)

export const ScheduleDayEditModal = (props: DayEditFormProps) => {
  const { userid: userId } = useParams<ListUserViewParams>()
  const { open, handleClose, slotDetails, serviceid } = props
  const classes = useStyles()
  const [appointType, setAppointType] = useState(serviceid)
  const [slotid, setSlotid] = useState()
  const [slotError, setSlotError] = useState(false)
  const [selectedStartTime, setSelectedStarttime] = useState<any[]>([])
  const [timeSlotDropList, setTimeSlotDropList] = useState<any[]>([])
  const [newAddTimeSlots, setnewAddTimeSlots] = useState<any[]>([])
  const [displayTimeSlots, setDisplayTimeSlots] = useState<any[]>([])
  const [removedFrmDropDnList, setRemovedFrmDropDnList] = useState<any[]>([])
  const [deleteids, setDeleteids] = useState<any[]>([])
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const { data: appointmntTypeData, loading: appointmntLoading } = useSignalServicesQuery({
    fetchPolicy: 'network-only'
  })
  // start time all slots get by below query
  const [
    availProfSlots,
    { data: allAvailSlots, loading: availSlotLoading }
  ] = useGetUserAvailableProfileSlotsLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [
    masterSlotsQuery,
    { data: masterSlotsData, loading: masterSlotLoading }
  ] = useGetMasterSlotsLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [
    getSavedDaySlots,
    { data: savedSlots, loading: getsavedslotsloading }
  ] = useGetUpdateSlotsListLazyQuery({ fetchPolicy: 'network-only' })
  const [createslotmutation, { loading: createSlotloading }] = useCreateNewProfileSlotsMutation()
  const [deleteSlots, { loading: delSlotLoading }] = useDeleteNewProfileSlotsMutation()
  useEffect(() => {
    if (userId)
      availProfSlots({
        variables: {
          user_id: userId,
          slot_date: slotDetails[0].slot_date || `${slotDetails[0]?.timeperiod}T00:00:00.000Z`
        }
      })
    if (currentUserdata && !userId)
      availProfSlots({
        variables: {
          user_id: currentUserdata?.data?.currentUser?.id,
          slot_date: slotDetails[0].slot_date || `${slotDetails[0]?.timeperiod}T00:00:00.000Z`
        }
      })
  }, [currentUserdata, availProfSlots, slotDetails, userId])
  useEffect(() => {
    if (userId)
      getSavedDaySlots({
        variables: {
          user_id: userId,
          slot_date: slotDetails[0].slot_date
        }
      })
    if (currentUserdata && !userId)
      getSavedDaySlots({
        variables: {
          user_id: currentUserdata?.data?.currentUser?.id,
          slot_date: slotDetails[0].slot_date
        }
      })
  }, [currentUserdata, getSavedDaySlots, slotDetails, userId])
  useEffect(() => {
    if (userId)
      masterSlotsQuery({
        variables: {
          slot_date: slotDetails[0].slot_date || `${slotDetails[0]?.timeperiod}T00:00:00.000Z`,
          user_id: userId
        }
      })
    if (currentUserdata && !userId)
      masterSlotsQuery({
        variables: {
          slot_date: slotDetails[0].slot_date || `${slotDetails[0]?.timeperiod}T00:00:00.000Z`,
          user_id: currentUserdata?.data?.currentUser?.id
        }
      })
  }, [currentUserdata, masterSlotsQuery, slotDetails, userId])
  const newSavedSlots = useMemo(() => {
    const newSavedSlotsTem: any[] = []

    if (savedSlots)
      Object.entries(savedSlots?.getUpdateSlotsList).forEach(([k, v], index) => {
        newSavedSlotsTem[index] = v
      })

    return newSavedSlotsTem
  }, [savedSlots])
  const saveAppointment = async () => {
    let dalSameSlotids: any[] = []
    let addSameDelSlotData: any[] = []

    if (newSavedSlots && newSavedSlots?.length > 0)
      newAddTimeSlots.map((addslotobj) => {
        newSavedSlots[0].map((savedbobj) => {
          if (
            savedbobj?.stime === addslotobj?.stime &&
            savedbobj?.service_type_id === addslotobj?.service_type_id
          ) {
            addSameDelSlotData = [...addSameDelSlotData, addslotobj]
            dalSameSlotids = [...dalSameSlotids, savedbobj.id]
          }

          return true
        })

        return true
      })

    const finalnewaddlist: any[] = newAddTimeSlots.filter(
      (obj) => addSameDelSlotData.indexOf(obj) === -1
    )
    const finaldellist: any[] = deleteids.filter((obj) => dalSameSlotids.indexOf(obj) === -1)

    if (finaldellist?.length) {
      try {
        await deleteSlots({
          variables: {
            id: finaldellist
          }
        })
      } catch (e) {
        console.log(e)
      }

      if (!finalnewaddlist?.length) handleClose()
    }
    if (finalnewaddlist?.length) {
      const inputData: any[] = []
      finalnewaddlist.map((obj) => {
        inputData.push({
          slot_date: slotDetails[0]?.timeperiod,
          user_id: userId || currentUserdata?.data?.currentUser?.id,
          stime: obj?.slottime,
          etime: obj?.endslottime,
          service_type_id: obj?.service_type_id,
          week_day: slotDetails[0]?.weekday,
          created_by: userId || currentUserdata?.data?.currentUser?.id,
          updated_by: userId || currentUserdata?.data?.currentUser?.id
        })

        return true
      })
      try {
        await createslotmutation({
          variables: {
            input: inputData
          }
        })
        handleClose()
      } catch (e) {
        console.log(e)
      }
    }
    if (!finalnewaddlist?.length && !finaldellist?.length) handleClose()
  }
  const removeSelectedChildTimeSlots = (removedFrmDropDnListArr) => {
    const filterList: any[] = timeSlotDropList.filter((obj1) => {
      return removedFrmDropDnListArr.indexOf(obj1) === -1
    })
    const rmDupFronList = filterList.filter(
      (obj, index, self) => self.findIndex((obj1) => obj1.id === obj.id) === index
    )
    setTimeSlotDropList([...rmDupFronList])
  }
  const updateRemovedtimeSlots = (rangeTimeSlotArr) => {
    const filterList: any[] = removedFrmDropDnList.filter((obj1) => {
      return rangeTimeSlotArr.indexOf(obj1) === -1
    })
    const rmDupFronList = filterList.filter((obj, index) => filterList.indexOf(obj) === index)
    setRemovedFrmDropDnList([...rmDupFronList])
  }
  const nosequelSlots = (childTimeSlotsPar, childTimeSlots) => {
    childTimeSlotsPar.map(({ parentid, ...obj }) => childTimeSlots.push(obj))
    const tmpDpList = [...timeSlotDropList, ...childTimeSlots]
    const sortdata = tmpDpList.sort((obj1, obj2) => {
      const date1: any = moment(obj1?.slottime, 'H:mm:ss')
      const date2: any = moment(obj2?.slottime, 'H:mm:ss')

      return date1 - date2
    })
    const rmDupFrondpList = sortdata.filter(
      (obj, index, self) => self.findIndex((obj1) => obj1.id === obj.id) === index
    )
    setTimeSlotDropList([...rmDupFrondpList])
    updateRemovedtimeSlots([...childTimeSlotsPar])
  }
  const getChildTimeRanges = (selectedTime, isaddnewslot, nextSaveSlot?) => {
    const start = isaddnewslot
      ? moment(selectedTime[0]?.slottime, 'H:mm:ss').subtract(1, 'hour')
      : moment(selectedTime[0]?.slottime, 'H:mm:ss')
    const end = moment(selectedTime[0]?.endslottime, 'H:mm:ss')
    const childTimeSlots: any[] = []
    let childTimeSlotsPar: any[] = []

    if (isaddnewslot) {
      if (timeSlotDropList?.length)
        timeSlotDropList?.map((timeObj) => {
          const valiTime = moment(timeObj?.slottime, 'H:mm:ss')

          if (valiTime > start && valiTime < end) childTimeSlots.push(timeObj)

          return true
        })

      childTimeSlots.map((obj) =>
        childTimeSlotsPar.push({ ...obj, parentid: selectedTime[0]?.slottime })
      )
      const removedlist = [...removedFrmDropDnList, ...childTimeSlotsPar]
      const rmDupFronList = removedlist.filter((obj, index) => removedlist.indexOf(obj) === index)
      setRemovedFrmDropDnList([...rmDupFronList])
      removeSelectedChildTimeSlots([...removedFrmDropDnList, ...childTimeSlots])
    }
    if (!isaddnewslot) {
      if (removedFrmDropDnList?.length)
        childTimeSlotsPar = removedFrmDropDnList.filter(
          (timeObj) => timeObj.parentid === selectedTime[0]?.parentid
        )
      if (nextSaveSlot) {
        const nextstartless = moment(nextSaveSlot?.stime, 'H:mm:ss').subtract(1, 'hour')
        const nextstart = moment(nextSaveSlot?.stime, 'H:mm:ss')
        const nextend = moment(nextSaveSlot?.etime, 'H:mm:ss')
        const overridetimeslots: any[] = []
        const childTimeSlotsPar1: any[] = []
        const overridechildTimeSlotsPar: any[] = []
        const zeromin = moment('00', 'mm')
        const ffmin = moment('45', 'mm')
        const mindiff = moment(nextstart.diff(end, 'minutes'), 'mm')

        if (zeromin <= mindiff && mindiff <= ffmin) {
          if (childTimeSlotsPar?.length)
            childTimeSlotsPar?.map((timeObj) => {
              const valiTime = moment(timeObj?.slottime, 'H:mm:ss')

              if (valiTime > nextstartless && valiTime < nextend) overridetimeslots.push(timeObj)
              else childTimeSlotsPar1.push(timeObj)

              return true
            })

          overridetimeslots.map((obj) =>
            overridechildTimeSlotsPar.push({ ...obj, parentid: nextSaveSlot?.stime })
          )
          childTimeSlotsPar1.map(({ parentid, ...obj }) => childTimeSlots.push(obj))
          const tmpDpList = [...timeSlotDropList, ...childTimeSlots]
          const sortdata = tmpDpList.sort((obj1, obj2) => {
            const date1: any = moment(obj1?.slottime, 'H:mm:ss')
            const date2: any = moment(obj2?.slottime, 'H:mm:ss')

            return date1 - date2
          })
          const rmDupFrondpList = sortdata.filter(
            (obj, index, self) => self.findIndex((obj1) => obj1.id === obj.id) === index
          )
          setTimeSlotDropList([...rmDupFrondpList])
          updateRemovedtimeSlots([...childTimeSlotsPar1])

          if (overridechildTimeSlotsPar && overridechildTimeSlotsPar?.length > 0)
            overridechildTimeSlotsPar.map((obj) => {
              removedFrmDropDnList.map((dpobj) => {
                if (dpobj?.id === obj?.id) dpobj.parentid = obj.parentid

                return true
              })

              return true
            })

          const removedlist = [...removedFrmDropDnList]
          const rmDupFronList = removedlist.filter(
            (obj, index) => removedlist.indexOf(obj) === index
          )
          setRemovedFrmDropDnList([...rmDupFronList])
        } else nosequelSlots(childTimeSlotsPar, childTimeSlots)
      } else nosequelSlots(childTimeSlotsPar, childTimeSlots)
    }
  }
  const addSlotFun = () => {
    if (!slotid) setSlotError(true)
    if (slotid && appointType) {
      setnewAddTimeSlots([
        ...newAddTimeSlots,
        {
          ...selectedStartTime[0],
          service_type_id: appointType,
          parentid: selectedStartTime[0]?.slottime
        }
      ])
      const tmpDpList = [
        ...displayTimeSlots,
        {
          ...selectedStartTime[0],
          service_type_id: appointType,
          parentid: selectedStartTime[0]?.slottime
        }
      ]
      const sortdata = tmpDpList.sort((obj1, obj2) => {
        const st1 = obj1?.slottime || obj1?.stime
        const st2 = obj2?.slottime || obj2?.stime
        const date1: any = moment(st1, 'H:mm:ss')
        const date2: any = moment(st2, 'H:mm:ss')

        return date1 - date2
      })
      setDisplayTimeSlots([...sortdata])
      setSlotid(undefined)
      getChildTimeRanges(selectedStartTime, true)
    }
  }
  const handleAppntChange = (e) => {
    setAppointType(e.target.value)
  }
  const handleSlatChange = async (e) => {
    setSlotid(e.target.value)
    setSlotError(false)
    const selectedTime = timeSlotDropList?.filter((timeObj) => e.target.value === timeObj.id)
    setSelectedStarttime([...selectedTime])
  }
  const deleteSlot = async (e, id, isSavedDelete, delObj, index) => {
    const afterdelData = displayTimeSlots?.filter((obj) => obj?.id !== id)
    setDisplayTimeSlots([...afterdelData])

    if (isSavedDelete) {
      getChildTimeRanges(
        [{ ...delObj, slottime: delObj.stime, endslottime: delObj.etime, parentid: delObj.stime }],
        false,
        displayTimeSlots[index + 1]
      )
      setDeleteids([...deleteids, id])
    }
    if (!isSavedDelete) {
      const afterRmFmNewData = newAddTimeSlots?.filter((obj) => obj?.id !== id)
      setnewAddTimeSlots([...afterRmFmNewData])
      getChildTimeRanges([delObj], false, displayTimeSlots[index + 1])
      setSlotid(undefined)
    }
  }
  const initGetChildTimeRanges = useCallback((selectedTime, newdroplist, newdellist) => {
    const start = moment(selectedTime[0]?.stime, 'H:mm:ss').subtract(1, 'hour')
    const end = moment(selectedTime[0]?.etime, 'H:mm:ss')
    const childTimeSlots: any[] = []
    const childTimeSlotsPar: any[] = []

    if (newdroplist?.length)
      newdroplist?.map((timeObj) => {
        const valiTime = moment(timeObj?.slottime, 'H:mm:ss')

        if (valiTime > start && valiTime < end) childTimeSlots.push(timeObj)

        return true
      })

    childTimeSlots.map((obj) =>
      childTimeSlotsPar.push({ ...obj, parentid: selectedTime[0]?.stime })
    )
    newdellist = [...newdellist, ...childTimeSlotsPar]
    const filterList: any[] = newdroplist.filter((obj1) => {
      return childTimeSlots.indexOf(obj1) === -1
    })

    return [newdellist, filterList]
  }, [])
  useEffect(() => {
    if (masterSlotsData) setTimeSlotDropList(masterSlotsData?.getMasterSlots)
  }, [masterSlotsData])
  useEffect(() => {
    if (newSavedSlots && newSavedSlots?.length > 0) setDisplayTimeSlots([...newSavedSlots[0]])
  }, [newSavedSlots])
  useEffect(() => {
    if (newSavedSlots && newSavedSlots?.length > 0 && masterSlotsData) {
      let newtimeSlotDropList: any[] = masterSlotsData?.getMasterSlots
      let dellistranges: any[] = []
      newSavedSlots[0].map((saveDbObj) => {
        const res = initGetChildTimeRanges([saveDbObj], newtimeSlotDropList, dellistranges)
        dellistranges = [...res[0]]
        newtimeSlotDropList = [...res[1]]

        return true
      })
      const rmDupFrondpList = newtimeSlotDropList.filter(
        (obj, index, self) => self.findIndex((obj1) => obj1.id === obj.id) === index
      )
      setTimeSlotDropList(rmDupFrondpList)
      const rmDupFronList = dellistranges.filter(
        (obj, index) => dellistranges.indexOf(obj) === index
      )
      setRemovedFrmDropDnList(rmDupFronList)
    }
  }, [newSavedSlots, initGetChildTimeRanges, masterSlotsData])

  return (
    <>
      {(appointmntLoading ||
        availSlotLoading ||
        createSlotloading ||
        getsavedslotsloading ||
        delSlotLoading ||
        masterSlotLoading) && <ProgressIndicator fullHeight />}
      <div className={classes.root}>
        <Dialog
          fullWidth
          maxWidth="md"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div className={classes.fs20}>
              Edit Availability for{' '}
              {moment(slotDetails[0]?.timeperiod, 'YYYY-MM-DD').format('MM/DD/YYYY')}
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid container md={12} sm={12}>
                <div style={{ fontSize: '15px', fontWeight: 600, color: '#000' }}>
                  <span style={{ background: '#ADD8E6', padding: '2px 5px', borderRadius: '5px' }}>
                    Blue
                  </span>{' '}
                  - Telehealth Only, &nbsp;&nbsp;&nbsp;
                  <span style={{ background: '#90EE90', padding: '2px 5px', borderRadius: '5px' }}>
                    Green
                  </span>{' '}
                  - In Person Only, &nbsp;&nbsp;&nbsp;
                  <span style={{ background: '#FFFF66', padding: '2px 5px', borderRadius: '5px' }}>
                    Yellow
                  </span>{' '}
                  - Both
                </div>
              </Grid>
              <Grid container style={{ padding: '20px 0px 15px 0px' }} className={classes.root1}>
                <Grid item md={5}>
                  <span className={classes.blackclr}>Start Time:&nbsp;&nbsp;</span>
                  {timeSlotDropList && (
                    <Select
                      labelId="label-startTime"
                      id="label-startTime"
                      variant="outlined"
                      name="startTime"
                      required
                      defaultValue={slotid}
                      key={Math.floor(Math.random() * 100)}
                      onChange={(value) => handleSlatChange(value)}
                    >
                      {timeSlotDropList?.map((dropDown) => (
                        <MenuItem key={dropDown.id} value={dropDown.id}>
                          {dropDown?.slots}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  {slotError && <div style={{ color: 'red' }}>Start time required</div>}
                </Grid>
                <Grid item md={5}>
                  <span className={classes.blackclr}>Appointment Type:&nbsp;&nbsp;</span>
                  {currentUserdata && appointmntTypeData && (
                    <Select
                      labelId="label-appType"
                      id="label-appType"
                      variant="outlined"
                      name="startTime"
                      required
                      disabled={serviceid === 1 || serviceid === 2}
                      defaultValue={appointType}
                      onChange={(e) => handleAppntChange(e)}
                    >
                      {appointmntTypeData?.signalServices?.map((dropDown) => (
                        <MenuItem value={dropDown.id}>{dropDown.name}</MenuItem>
                      ))}
                    </Select>
                  )}
                </Grid>
                <Grid item md={2} style={{ textAlign: 'end' }}>
                  <Button variant="contained" onClick={addSlotFun} className={classes.btn}>
                    Add Slot
                  </Button>
                </Grid>
              </Grid>
              <Grid container style={{ padding: '30px 0px 10px' }}>
                <Grid md={1} item>
                  <span className={classes.blackclr}>Slots:</span>
                </Grid>
                <Grid md={11} item>
                  {displayTimeSlots?.length > 0 &&
                    displayTimeSlots?.map((fixedSlots, index) => (
                      <>
                        {((fixedSlots?.stime && fixedSlots?.etime) ||
                          (fixedSlots?.slottime && fixedSlots?.endslottime)) && (
                          <div
                            style={{
                              padding: '5px',
                              marginRight: '20px',
                              marginBottom: '20px',
                              borderRadius: '5px',
                              position: 'relative',
                              display: 'inline-block',
                              float: 'left',
                              color: '#000',
                              fontWeight: 600
                            }}
                            className={
                              fixedSlots?.service_type_id === 1
                                ? classes.inpersonBg
                                : fixedSlots?.service_type_id === 2
                                ? classes.teleBg
                                : fixedSlots?.service_type_id === 3
                                ? classes.bothBg
                                : ''
                            }
                          >
                            {fixedSlots?.stime
                              ? moment(fixedSlots?.stime, 'hh:mm:ss').format('hh:mm A')
                              : moment(fixedSlots?.slottime, 'hh:mm:ss').format('hh:mm A')}
                            &nbsp;-&nbsp;
                            {fixedSlots?.etime
                              ? moment(fixedSlots?.etime, 'hh:mm:ss').format('hh:mm A')
                              : moment(fixedSlots?.endslottime, 'hh:mm:ss').format('hh:mm A')}
                            <span
                              role="button"
                              className={`${classes.closeicon} ${
                                fixedSlots?.service_type_id === 1
                                  ? classes.inpersonBg
                                  : fixedSlots?.service_type_id === 2
                                  ? classes.teleBg
                                  : fixedSlots?.service_type_id === 3
                                  ? classes.bothBg
                                  : ''
                              }`}
                              onKeyPress={() => console.log('')}
                              onClick={(e) =>
                                deleteSlot(
                                  e,
                                  fixedSlots.id,
                                  fixedSlots?.created_at,
                                  fixedSlots,
                                  index
                                )
                              }
                              tabIndex={0}
                            >
                              <CloseIcon style={{ fontSize: '20px' }} />
                            </span>
                          </div>
                        )}
                      </>
                    ))}
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ paddingRight: '30px' }}>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={saveAppointment}
              disabled={!newAddTimeSlots?.length && !deleteids?.length}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}
