import { Grid } from '@material-ui/core'
import { makeValidate, TextField, Checkboxes } from 'mui-rff'
import React from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import Button from '../../../../components/ui/panel/button/Button'
import { InfoButton } from '../../../../components/ui/panel/button/InfoButton'
import { Section } from '../../../../components/ui/panel/section/Section'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'

export interface AgencyFormModel {
  name: any
  address: any
  city: any
  state: any
  zipcode: any
  restriction?: boolean | null
}

type AgencyFormProps = {
  onSubmit(values: AgencyFormModel): void
  submitLabel: string
  initialValues?: Partial<AgencyFormModel> | null
}
const validator = makeValidate(
  yup.object<AgencyFormModel>({
    name: yup.string().label('Agency Name').required(),
    address: yup.string().label('Agency Address').required(),
    city: yup.string().label('City').required(),
    state: yup.string().label('State').required(),
    zipcode: yup
      .number()
      .label('Zip Code')
      .required()
      .typeError('Zip code is a number')
      .test('len', ' Zip code needs to be excatly 5 digits', (val) => val?.toString().length === 5)
  })
)

export const AgencyForm = (props: AgencyFormProps) => {
  const history = useHistory()
  const classes = useStyles()
  const { initialValues } = props
  const onSubmit = (values: AgencyFormModel) => props.onSubmit(values)
  const renderForm = (renderProps: FormRenderProps<AgencyFormModel>) => {
    const { handleSubmit } = renderProps

    return (
      <form>
        <Grid container spacing={4} style={{ maxWidth: '800px' }}>
          <Grid item sm={12}>
            <Section title="Agency Details">
              <Grid container spacing={3}>
                <Grid item sm={6}>
                  <TextField
                    required
                    variant="filled"
                    name="name"
                    label="Agency Name"
                    className={classes.labelField}
                  />
                </Grid>

                <Grid item sm={6}>
                  <TextField
                    required
                    variant="filled"
                    name="address"
                    label="Agency Address"
                    className={classes.labelField}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    required
                    variant="filled"
                    name="city"
                    label="City"
                    className={classes.labelField}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    required
                    variant="filled"
                    name="state"
                    label="State"
                    className={classes.labelField}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    required
                    variant="filled"
                    name="zipcode"
                    label="Zip Code"
                    className={classes.labelField}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Checkboxes
                    name="restriction"
                    data={[
                      { label: 'Restrict Telehealth services by Counties served', value: true }
                    ]}
                  />
                </Grid>
              </Grid>
            </Section>
          </Grid>

          <Grid item sm={12} style={{ paddingTop: 35, marginLeft: '5px' }}>
            <InfoButton
              size="large"
              variant="contained"
              onClick={handleSubmit}
              style={{ marginRight: 12 }}
            >
              {props.submitLabel}
            </InfoButton>
            <Button size="large" variant="outlined" color="primary" onClick={history.goBack}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <Form<AgencyFormModel>
      initialValues={initialValues ?? {}}
      validate={validator}
      render={renderForm}
      onSubmit={onSubmit}
    />
  )
}
