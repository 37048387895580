import React, { useMemo } from 'react'
import { useUserSelectLazyQuery, Role } from '../../../../graphql/hooks.generated'
import { AsyncSelect, SelectOption } from '../async-select/AsyncSelect'

interface UserSelectProps {
  label: string
  multiple?: boolean
  name: string
  required?: boolean
  withRoles?: Role[]
}

export const UserSelect = ({ withRoles, ...props }: UserSelectProps) => {
  const [executeQuery, { data }] = useUserSelectLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetch = useMemo(
    () => (query: string) =>
      executeQuery({
        variables: {
          input: { query, withRoles }
        }
      }),
    [executeQuery, withRoles]
  )

  return (
    <AsyncSelect
      {...props}
      fetchOptions={fetch}
      getOptionValue={(option: SelectOption) => option?.id}
      getOptionLabel={(option: SelectOption) => option?.name || ''}
      options={data?.users ?? []}
    />
  )
}
