import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import { Typography, useTheme, Grid, Paper, Box, Checkbox } from '@material-ui/core'
import { createStyles, Theme, makeStyles, styled } from '@material-ui/core/styles'
import { ReactComponent as BlankImage } from 'assets/icons/blank.svg'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import moment from 'moment'
import Button from '../../../../components/ui/panel/button/Button'
import { Section } from '../../../../components/ui/panel/section/Section'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import {
  useSignalSlotsQuery,
  useCurrentUserQuery,
  useSignalTherapistUpdateViewLazyQuery,
  useNextDaysLazyQuery,
  useGetNewProfileSlotsLazyQuery,
  useGetUserDetailsInProfileLazyQuery
} from '../../../../graphql/hooks.generated'
import { UseParamsType } from '../../../../types'

interface ListUserViewParams extends UseParamsType {
  userid: string
}
const ButtonsRow = styled(Box)({
  padding: '32px'
})
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1)
    },
    rightIcon: {
      '& :visited': { color: 'blue' },
      '& :hover': { color: 'blue' },
      '& :active': { color: 'blue' }
    },
    leftIcon: {
      color: 'slategray'
    },
    popover: {
      pointerEvents: 'none'
    },
    paper: {
      padding: theme.spacing(1)
    },
    paperText: {
      maxWidth: 1000,
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(2),
      background: '#fff !important'
    },
    paperDate: {
      maxWidth: 1000,
      margin: `${theme.spacing(1)}px auto`,
      padding: '20px',
      background: '#fff !important'
    },
    texBox: {
      '& .MuiFilledInput-root': {
        border: '1px solid',
        background: '#fff !important'
      }
    },
    upload: {
      '& .e-upload': {
        border: 'none'
      }
    },
    imgContainer: { display: 'flex', height: '152px' },
    previewImg: {
      height: '100%',
      width: '25%',
      padding: '20px',
      borderRight: ' 1px solid #c1c1c1'
    },
    timeZoneTxt: {
      fontSize: '14px',
      margin: '0px',
      marginLeft: '20px',
      marginTop: '15px'
    },
    timeZoneTxtspan: {
      fontSsize: '20px',
      color: '#ff6400'
    },
    passWordText: {
      fontSize: '16px',
      paddingLeft: '35px',
      fontWeight: 500,
      margin: 0
    },
    inpersonBg: {
      background: '#90EE90'
    },
    teleBg: {
      background: '#ADD8E6'
    },
    bothBg: {
      background: '#FFFF66'
    }
  })
)

export const TherapistDetailView = () => {
  const classes = useStyles()
  const history = useHistory()
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const { data: currentUserData, hasAnyRole } = useCurrentUser()
  const { userid: userId } = useParams<ListUserViewParams>()
  const [slots, setSlots] = useState<any[]>([])
  let newProfSlots: any[] = []
  // const [therapistLogQuery, { data, loading }] = useSignalTherapistUpdateViewLazyQuery({
  //   fetchPolicy: 'network-only'
  // })
  const [therapistLogQuery, { data, loading }] = useGetUserDetailsInProfileLazyQuery({
    fetchPolicy: 'network-only'
  })
  const { data: slotsDropdownData, loading: slotsDropdownLoading } = useSignalSlotsQuery({
    fetchPolicy: 'network-only'
  })
  const [slotDateQuery, { data: slotsDates }] = useNextDaysLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [
    fetchListSlots,
    { data: listslots, loading: listSlotsLoading }
  ] = useGetNewProfileSlotsLazyQuery({
    fetchPolicy: 'network-only'
  })
  const slotDatesFetch = useMemo(() => () => slotDateQuery(), [slotDateQuery])
  const isLoading = () =>
    loading || slotsDropdownLoading || listSlotsLoading ? <ProgressIndicator fullHeight /> : ''
  const fetchtherapistLogData = useMemo(
    () => ({ user_id }) => therapistLogQuery({ variables: { user_id } }),
    [therapistLogQuery]
  )
  useEffect(() => {
    if (userId) fetchtherapistLogData({ user_id: userId })
    if (currentUserdata && !userId) {
      const input = {
        user_id: currentUserdata.data?.currentUser.id ?? ''
      }
      fetchtherapistLogData(input)
    }
  }, [fetchtherapistLogData, currentUserdata, userId])
  useEffect(() => {
    if (userId)
      fetchListSlots({
        variables: {
          user_id: userId,
          timeperiod: new Date().toISOString()
        }
      })
    if (currentUserdata && !userId)
      fetchListSlots({
        variables: {
          user_id: currentUserdata?.data?.currentUser?.id,
          timeperiod: new Date().toISOString()
        }
      })
  }, [currentUserdata, fetchListSlots, userId])
  const hancleclickPush = () => {
    if (userId) history.push(`/TherapistInviteUpdate/${userId}`)
    else history.push(`/TherapistInviteUpdate`)
  }
  const days = useCallback(() => {
    const availabilitySlots = data?.getUserDetailsInProfile?.[0]?.userAvailabilityInput
    const slotsDatesAvailable = slotsDates?.nextDays.map((val, i) => {
      const selectedSlots = availabilitySlots?.[val.timeperiod]?.map((item) => {
        const selectedTimeSlots = slotsDropdownData?.signalSlots?.filter(
          (slots) => item.user_availability_id === slots.id
        )

        return item.week_day
      })
      const tmpObj = { ...val, selectedSlots: selectedSlots || [] }

      return tmpObj
    })
    setSlots(slotsDatesAvailable)

    return slotsDatesAvailable
  }, [data?.getUserDetailsInProfile, slotsDates?.nextDays, slotsDropdownData?.signalSlots])
  const handlePushTo = () => {
    history.push(`/TherapistInviteUpdate/${data?.getUserDetailsInProfile?.map((v) => v.id)}`)
  }
  useEffect(() => {
    slotDatesFetch()
  }, [slotDatesFetch])
  useEffect(() => {
    if (data?.getUserDetailsInProfile?.[0]?.userAvailabilityInput && slotsDates?.nextDays) days()
  }, [data?.getUserDetailsInProfile, slotsDates?.nextDays, days])

  if (listslots) {
    newProfSlots = []
    Object.entries(listslots?.getNewProfileSlots).forEach(([k, v], index) => {
      newProfSlots[index] = v
    })
  }

  return (
    <>
      <NavBar title="Providers" goBackBtn />
      <PageContainer>
        <Section title="Profile">
          <Grid container>
            <Grid container>
              <Grid
                item
                sm={6}
                alignItems="center"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <div>
                  <div style={{ paddingBottom: '10px' }}>
                    <strong> Name </strong> : {data?.getUserDetailsInProfile?.map((v) => v.name)}
                  </div>
                  <div>
                    <strong>Email </strong> : {data?.getUserDetailsInProfile?.map((v) => v.email)}
                  </div>
                </div>
              </Grid>
              <Grid item sm={6} alignItems="center">
                <div style={{ display: 'flex', height: '152px', justifyContent: 'center' }}>
                  {data?.getUserDetailsInProfile?.map((obj) => (
                    <>
                      {obj?.picture && <img src={obj?.picture} alt="User" />}
                      {!obj?.picture && <BlankImage style={{ height: '150px' }} />}
                    </>
                  ))}
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={12} style={{ paddingTop: '10px' }}>
                <Paper className={classes.paperText}>
                  <p>
                    <h2>About</h2>
                  </p>
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs>
                      <Typography>
                        {data?.getUserDetailsInProfile?.map((v) => v.description)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={12}>
                <Paper className={classes.paperDate}>
                  <Grid>
                    <Typography>
                      <p className={classes.timeZoneTxt}>
                        <span className={classes.timeZoneTxtspan}>
                          All times shown in Mountain(MT){' '}
                        </span>{' '}
                        Hawaiian Time = -3 hrs, Alaskan Time = -2 hrs, Pacific Time = -1 hr, Central
                        Time = +1 hr, Eastern Time = +2 hrs
                      </p>
                    </Typography>
                    <h2>Availability </h2>
                    <div style={{ fontSize: '15px', fontWeight: 600 }}>
                      <span
                        style={{ background: '#ADD8E6', padding: '2px 5px', borderRadius: '5px' }}
                      >
                        Blue
                      </span>{' '}
                      - Telehealth Only, &nbsp;&nbsp;&nbsp;
                      <span
                        style={{ background: '#90EE90', padding: '2px 5px', borderRadius: '5px' }}
                      >
                        Green
                      </span>{' '}
                      - In Person Only, &nbsp;&nbsp;&nbsp;
                      <span
                        style={{ background: '#FFFF66', padding: '2px 5px', borderRadius: '5px' }}
                      >
                        Yellow
                      </span>{' '}
                      - Both
                    </div>
                    <div style={{ paddingTop: '10px', paddingBottom: '10px', fontSize: '18px' }}>
                      {data &&
                        data?.getUserDetailsInProfile?.map((v) => (
                          <Checkbox disabled checked={v.recurrence === 1 || false} />
                        ))}
                      Repeat Schedule
                    </div>
                  </Grid>
                  {newProfSlots.length > 0 && (
                    <Grid container item xs={12} sm={12}>
                      <Grid md={6}>
                        {newProfSlots?.length > 0 &&
                          newProfSlots.map((day, index) => (
                            <>
                              {(newProfSlots?.length - 1) / 2 > index && index < 14 && (
                                <Grid item xs={12} style={{ padding: '5px 5px 5px 0px' }}>
                                  <div style={{ display: 'flex' }}>
                                    <Grid md={4} color="secondary">
                                      <Typography style={{ fontFamily: 'Brandon Grotesque' }}>
                                        {moment(day[0]?.timeperiod, 'YYYY-MM-DD').format(
                                          'MM/DD/YYYY'
                                        )}
                                        &nbsp;-&nbsp;{day[0]?.dayname}
                                      </Typography>
                                    </Grid>
                                    <Grid md={8} style={{ padding: '5px 5px 5px 10px' }}>
                                      {day &&
                                        day.map((fixedSlots) => (
                                          <>
                                            {fixedSlots?.stime && fixedSlots?.etime && (
                                              <span
                                                style={{
                                                  padding: '5px',
                                                  marginLeft: '5px',
                                                  marginBottom: '10px',
                                                  fontWeight: 600,
                                                  borderRadius: '5px',
                                                  display: 'inline-block',
                                                  float: 'left',
                                                  fontSize: '13px'
                                                }}
                                                className={
                                                  fixedSlots?.service_type_id === 1
                                                    ? classes.inpersonBg
                                                    : fixedSlots?.service_type_id === 2
                                                    ? classes.teleBg
                                                    : fixedSlots?.service_type_id === 3
                                                    ? classes.bothBg
                                                    : ''
                                                }
                                              >
                                                {moment(fixedSlots?.stime, 'hh:mm:ss').format(
                                                  'hh:mm A'
                                                )}
                                                &nbsp;-&nbsp;
                                                {moment(fixedSlots?.etime, 'hh:mm:ss').format(
                                                  'hh:mm A'
                                                )}
                                              </span>
                                            )}
                                          </>
                                        ))}
                                    </Grid>
                                  </div>
                                </Grid>
                              )}
                            </>
                          ))}
                      </Grid>
                      <Grid md={6}>
                        {newProfSlots?.length > 0 &&
                          newProfSlots.map((day, index) => (
                            <>
                              {(newProfSlots?.length - 1) / 2 <= index && index < 14 && (
                                <Grid item xs={12} style={{ padding: '5px 10px' }}>
                                  <div style={{ display: 'flex' }}>
                                    <Grid md={4} color="secondary">
                                      <Typography style={{ fontFamily: 'Brandon Grotesque' }}>
                                        {moment(day[0]?.timeperiod, 'YYYY-MM-DD').format(
                                          'MM/DD/YYYY'
                                        )}
                                        &nbsp;-&nbsp;
                                        {day[0]?.dayname}
                                      </Typography>
                                    </Grid>
                                    <Grid md={8} style={{ padding: '5px 5px 5px 10px' }}>
                                      {day &&
                                        day.map((fixedSlots) => (
                                          <>
                                            {fixedSlots?.stime && fixedSlots?.etime && (
                                              <div
                                                style={{
                                                  padding: '5px',
                                                  marginRight: '5px',
                                                  marginBottom: '10px',
                                                  fontWeight: 600,
                                                  borderRadius: '5px',
                                                  display: 'inline-block',
                                                  float: 'left',
                                                  fontSize: '13px'
                                                }}
                                                className={
                                                  fixedSlots?.service_type_id === 1
                                                    ? classes.inpersonBg
                                                    : fixedSlots?.service_type_id === 2
                                                    ? classes.teleBg
                                                    : fixedSlots?.service_type_id === 3
                                                    ? classes.bothBg
                                                    : ''
                                                }
                                              >
                                                {moment(fixedSlots?.stime, 'hh:mm:ss').format(
                                                  'hh:mm A'
                                                )}
                                                &nbsp;-&nbsp;
                                                {moment(fixedSlots?.etime, 'hh:mm:ss').format(
                                                  'hh:mm A'
                                                )}
                                              </div>
                                            )}
                                          </>
                                        ))}
                                    </Grid>
                                  </div>
                                </Grid>
                              )}
                            </>
                          ))}
                      </Grid>
                      {/* <Grid md={6}>
                        {slots?.map((day, index) => (
                          <>
                            {slots.length / 2 > index && (
                              <Grid item xs={12} sm={12} style={{ padding: '5px 15px' }}>
                                <div style={{ display: 'flex' }}>
                                  <Grid container>
                                    <Grid item sm={3}>
                                      <span style={{ fontWeight: 'bold' }}>
                                        {' '}
                                        {day.timeperiod} : &nbsp;
                                        <div style={{ paddingLeft: '20px' }}>{day.dayname}</div>
                                      </span>
                                    </Grid>
                                    <Grid item sm={7}>
                                      {data?.signalTherapistUpdateView?.[0]?.userAvailabilityInput[
                                        day?.timeperiod
                                      ]?.map((item) => (
                                        <>
                                          {slotsDropdownData?.signalSlots?.map((slots) => {
                                            return (
                                              <>
                                                {item.user_availability_id === slots.id && (
                                                  <div
                                                    style={{
                                                      backgroundColor: 'lightgray',
                                                      padding: '5px 8px',
                                                      marginRight: '10px',
                                                      marginBottom: '3px',
                                                      borderRadius: '25px',
                                                      fontSize: '12px',
                                                      display: 'inline-block'
                                                    }}
                                                  >
                                                    {slots?.time_slot}
                                                  </div>
                                                )}
                                              </>
                                            )
                                          })}
                                        </>
                                      ))}
                                    </Grid>
                                  </Grid>
                                </div>
                              </Grid>
                            )}
                          </>
                        ))}
                      </Grid>
                      <Grid md={6}>
                        {slots?.map((day, index) => (
                          <>
                            {slots.length / 2 <= index && (
                              <Grid item xs={12} sm={12} style={{ padding: '5px 15px' }}>
                                <div style={{ display: 'flex' }}>
                                  <Grid container>
                                    <Grid item sm={3}>
                                      <span style={{ fontWeight: 'bold' }}>
                                        {' '}
                                        {day.timeperiod} : &nbsp;
                                        <div style={{ paddingLeft: '20px' }}>{day.dayname}</div>
                                      </span>
                                    </Grid>
                                    <Grid item sm={7}>
                                      {data?.signalTherapistUpdateView?.[0]?.userAvailabilityInput[
                                        day?.timeperiod
                                      ]?.map((item) => (
                                        <>
                                          {slotsDropdownData?.signalSlots?.map((slots) => {
                                            return (
                                              <>
                                                {item.user_availability_id === slots.id && (
                                                  <div
                                                    style={{
                                                      backgroundColor: 'lightgray',
                                                      padding: '5px 8px',
                                                      marginRight: '10px',
                                                      marginBottom: '3px',
                                                      borderRadius: '25px',
                                                      fontSize: '12px',
                                                      display: 'inline-block'
                                                    }}
                                                  >
                                                    {slots?.time_slot}
                                                  </div>
                                                )}
                                              </>
                                            )
                                          })}
                                        </>
                                      ))}
                                    </Grid>
                                  </Grid>
                                </div>
                              </Grid>
                            )}
                          </>
                        ))}
                      </Grid> */}
                    </Grid>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <ButtonsRow>
            <Box display="flex" justifyContent="center">
              <Button variant="contained" color="primary" size="medium" onClick={hancleclickPush}>
                Edit
              </Button>
            </Box>
          </ButtonsRow>
        </Section>
        {isLoading()}
      </PageContainer>
      {/* ))} */}
    </>
  )
}
