import React, { PropsWithChildren, useContext } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import { VideoContext } from 'views/public/video/video'
import { engLoc, spaLoc } from 'config/constants/translations'
import { TwilioError } from 'twilio-video'
import enhanceMessage from './enhanceMessage'

interface ErrorDialogProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  dismissError: Function
  error: TwilioError | Error | null
}

function ErrorDialog({ dismissError, error }: PropsWithChildren<ErrorDialogProps>) {
  const { message, code } = error || {}
  const loc = useContext(VideoContext)
  const enhancedMessage = enhanceMessage(message, code)

  return (
    <Dialog open={error !== null} onClose={() => dismissError()} fullWidth maxWidth="xs">
      <DialogTitle>{loc === 'es' ? spaLoc.video.error : engLoc.video.error}</DialogTitle>
      <DialogContent>
        <DialogContentText>{enhancedMessage}</DialogContentText>
        {Boolean(code) && (
          <pre>
            <code>
              {loc === 'es' ? spaLoc.video.errCode : engLoc.video.errCode}: {code}
            </code>
          </pre>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dismissError()} color="primary" autoFocus>
          {loc === 'es' ? spaLoc.video.ok : engLoc.video.ok}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ErrorDialog
