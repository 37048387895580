import { Dispatch } from 'redux'
import { Client, Conversation, Message, Participant } from '@twilio/conversations'
import { ActionType } from '../action-types'
import { Action } from '../actions'

export const login = (token: string) => {
  return (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: ActionType.LOGIN,
      payload: token
    })
  }
}

export const logout = () => {
  return (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: ActionType.LOGOUT
    })
  }
}

export const updateUnreadMessages = (channelSid: string, unreadCount: number) => {
  return (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: ActionType.UPDATE_UNREAD_MESSAGES,
      payload: { channelSid, unreadCount }
    })
  }
}

export const updateClient = (client: Client) => {
  return (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: ActionType.UPDATE_CLIENT,
      payload: client
    })
  }
}
