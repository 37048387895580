import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  ToolbarItems,
  ExcelExport,
  Grid,
  Inject,
  Toolbar,
  Page,
  ExcelExportProperties,
  SortSettingsModel,
  Sort,
  Search
} from '@syncfusion/ej2-react-grids'
import React, { useMemo, useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { Typography, Link, Select, MenuItem } from '@material-ui/core'
import { theme } from 'config/constants/theme'
import { useDispatch, useSelector } from 'react-redux'
import { SearchFilterData } from 'views/redux/searchFilterRedux/actions/searchFilterActions'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import { useSessionapprovalreportQuery } from '../../../../graphql/hooks.generated'
import { useStyles } from '../../../../config/constants/theme/profile'

export const Sessionapprovalreport = () => {
  const history = useHistory()
  const classes = useStyles()
  const sfStoreState: any = useSelector((state) => state)
  const dispatch = useDispatch()
  const toolbar: ToolbarItems[] = ['ExcelExport']
  let grid: Grid | null
  const { data, loading, refetch } = useSessionapprovalreportQuery({
    fetchPolicy: 'network-only'
  })
  const toolbarOptions: ToolbarItems[] = ['Search']
  const searchOptions = {
    fields: ['client_name'],
    key: sfStoreState?.clientlistq?.searchText
    // ignoreCase: true
  }
  const createdevent = () => {
    document
      .getElementById(`${grid?.element?.id}_searchbutton`)
      ?.addEventListener('click', (event) => {
        const val = (document.getElementById(`${grid?.element?.id}_searchbar`) as HTMLInputElement)
          ?.value
        dispatch(
          SearchFilterData({
            ...sfStoreState,
            clientlistq: { ...sfStoreState.clientlistq, searchText: val }
          })
        )
      })
    document
      .getElementById(`${grid?.element?.id}_searchbar`)
      ?.addEventListener('keyup', (event) => {
        if (event?.key === 'Enter') {
          const val = (document.getElementById(
            `${grid?.element?.id}_searchbar`
          ) as HTMLInputElement)?.value
          dispatch(
            SearchFilterData({
              ...sfStoreState,
              clientlistq: { ...sfStoreState.clientlistq, searchText: val }
            })
          )
        }
      })
  }
  const sortSettings: SortSettingsModel = {
    columns: [
      { field: 'agency', direction: 'Ascending' },
      { field: 'providername', direction: 'Ascending' },
      { field: 'appointment_date', direction: 'Ascending' }
    ]
  }
  const expandRow = (additional_sessions_number) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <Typography style={{ width: '60px' }}>Notes</Typography>
          <Typography>:</Typography>
          <Typography style={{ paddingLeft: '10px' }}>
            {additional_sessions_number || '-'}
          </Typography>
        </div>
      </>
    )
  }

  return (
    <>
      <PageContainer>
        <Typography
          variant="h3"
          style={{ color: theme.palette.info.main, padding: '12px', fontSize: '24px' }}
        >
          Additional Sessions Report
        </Typography>

        <div style={{ position: 'relative' }}>
          <div
            style={{
              textAlign: 'end',
              position: 'absolute',
              top: '21px',
              right: '10px',
              zIndex: 99
            }}
          />
          {loading && <ProgressIndicator fullHeight />}
          <GridComponent
            dataSource={data?.sessionapprovalreports || []}
            allowTextWrap
            allowPaging
            pageSettings={{ pageSizes: [15, 25, 50, 100], pageSize: 15, pageCount: 5 }}
            allowSorting
            allowExcelExport
            id="grid"
            searchSettings={searchOptions}
            created={createdevent}
            toolbar={toolbarOptions}
            // className={classes.searchoption}
            detailTemplate={(value) => expandRow(value.additionalsessionsrequested)}
          >
            <ColumnsDirective>
              <ColumnDirective field="client_id" headerText="Client ID" width="120" />
              <ColumnDirective
                field="client_name"
                template={(report) => (
                  <Link
                    onClick={() =>
                      history.push(
                        `/studentsinfo/${btoa(`${report?.student_id}_${report?.student_id}`)}`
                      )
                    }
                    style={{ color: 'black', cursor: 'pointer' }}
                  >
                    <span>{`${report?.client_name}`}</span>
                  </Link>
                )}
                headerText="Client Name"
                width="130"
                allowFiltering={false}
              />
              <ColumnDirective
                field="Provider"
                headerText="Provider"
                width="140"
                allowFiltering={false}
              />
              <ColumnDirective
                field="completed"
                template={(age) => (
                  <>
                    <div>{age?.completed}</div>
                  </>
                )}
                headerText="Sessions Completed Successfully"
                width="90"
                allowFiltering={false}
              />
              <ColumnDirective
                field="additional_session_count"
                headerText="Additional Sessions Requested"
                width="90"
                allowFiltering={false}
              />
              <ColumnDirective
                field="Approval"
                template={(sessionapproval) => (
                  <>
                    <div>{sessionapproval?.Approval}</div>
                  </>
                )}
                headerText="Approval"
                width="110"
                allowFiltering={false}
              />
              <ColumnDirective
                field="additionalsessions_num"
                template={(approvedsessions) => (
                  <>
                    <div style={{ color: '#FF0000' }}>
                      {approvedsessions?.additionalsessions_num}
                    </div>
                  </>
                )}
                headerText="If approved,how many additional sessions?"
                width="110"
                allowFiltering={false}
              />
              <ColumnDirective
                field="approval_date"
                template={(approval) => (
                  <div>
                    {approval?.approval_date
                      ? moment(approval?.approval_date).format('MM-DD-YYYY')
                      : ''}
                  </div>
                )}
                headerText="Date of Approval"
                width="120"
                allowFiltering={false}
              />
            </ColumnsDirective>
            <Inject services={[Toolbar, Search, ExcelExport, Page, Sort]} />
          </GridComponent>
        </div>
      </PageContainer>
    </>
  )
}
