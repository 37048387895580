import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import { RootPrivateView } from './private/RootPrivateView'
import { RootPublicView } from './public/RootPublicView'
import { Login } from '../components/ui/auth/login/Login'
import { Forgotpassword } from '../components/ui/auth/forgot-password/Forgotpassword'
import { theme, surveytheme } from '../config/constants/theme'

export const RootView = () => {
  const location = useLocation()
  let themetemplate = theme

  if (location.pathname.includes('public/survey') || location.pathname.includes('public/register'))
    themetemplate = surveytheme

  return (
    <ThemeProvider theme={themetemplate}>
      <Switch>
        <Route path="/public" component={RootPublicView} />
        <Route path="/login" component={Login} />
        <Route path="/forgotpassword" component={Forgotpassword} />
        <Route component={RootPrivateView} />
      </Switch>
    </ThemeProvider>
  )
}
