import React from 'react'
import { useHistory } from 'react-router-dom'
import Dashboard from '../../../components/ui/dashboard/Dashboard'
import Tour from '../../../components/ui/tour/Tour'
import { Role } from '../../../graphql/hooks.generated'
import { useCurrentUser } from '../../../graphql/hooks/useCurrentUser/useCurrentUser'
import { ReactComponent as DirectServicesIcon } from '../../../assets/icons/attachments.svg'
import { ReactComponent as ReportsIcon } from '../../../assets/icons/reports.svg'
import { ReactComponent as TherapistsIcon } from '../../../assets/icons/therapists.svg'
import { getTherapyLogUrl } from '../../../helpers/getTherapyLogUrl'

export const TeleteachersAdminDashboard = () => {
  const { hasAnyRole } = useCurrentUser()
  const history = useHistory()

  return (
    <>
      <Tour steps={Role.TeleteachersAdmin} />
      <Dashboard
        // assistantOptions={[
        //   {
        //     title: 'How do I get started?',
        //     handler: () => {
        //        tourRunStatusVar(true)
        //     }
        //   },
        //   { title: 'How do I change my personal information?' },
        //   { title: 'What are alerts?' },
        //   { title: 'What are generated alerts?' }
        // ]}
        // @todo:  need new icons for districts and schools
        cards={[
          {
            icon: <TherapistsIcon />,
            title: 'Users',
            visible: hasAnyRole(Role.TeleteachersAdmin),
            handler: () => history.push('/users')
          },
          {
            icon: <DirectServicesIcon />,
            title: 'Districts',
            visible: hasAnyRole(Role.TeleteachersAdmin),
            handler: () => history.push('/districts')
          },
          // {
          //   icon: <DirectServicesIcon />,
          //   title: 'States',
          //   visible: hasAnyRole(Role.TeleteachersAdmin),
          //   handler: () => history.push('/states')
          // },
          {
            icon: <DirectServicesIcon />,
            title: 'Services',
            visible: hasAnyRole(Role.TeleteachersAdmin),
            handler: () => history.push('/services')
          },
          // {
          //   icon: <DirectServicesIcon />,
          //   title: 'Icd 10 Codes',
          //   visible: hasAnyRole(Role.TeleteachersAdmin),
          //   handler: () => history.push('/icdCodes')
          // },
          {
            icon: <ReportsIcon />,
            title: 'Reports',
            visible: hasAnyRole(Role.TeleteachersAdmin),
            handler: () => history.push('/reports')
          }
          // {
          //   icon: <DirectServicesIcon />,
          //   title: 'Service Requests',
          //   visible: hasAnyRole(Role.TeleteachersAdmin),
          //   handler: () => history.push('/serviceRequests')
          // },
          // {
          //   icon: <ReportsIcon />,
          //   title: 'Assign Therapist',
          //   visible: hasAnyRole(Role.TeleteachersAdmin),
          //   handler: () => history.push('/providerAssignments/create')
          // }
        ]}
      />
    </>
  )
}
