import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Person from '@material-ui/icons/Person'
import { StateContextType } from '../../../../state'

export function getInitials(name: string) {
  return name
    .split(' ')
    .map((text) => text[0])
    .join('')
    .toUpperCase()
}

export default function UserAvatar({ user }: { user: StateContextType['user'] }) {
  return <Avatar className="e-avatar e-avatar-large e-avatar-circle w-4- h-40">ME</Avatar>
}
