import React from 'react'
import { Box, Typography } from '@material-ui/core'
import Paper from '../../../panel/paper/Paper'
import Button from '../../../panel/button/Button'
import useStyles from './Styles'

export type DashboardCardProps = {
  icon: JSX.Element
  title: string
  handler?()
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  handler,
  title,
  icon
}: DashboardCardProps) => {
  const classes = useStyles()
  const comingSoon = () => {
    alert('coming soon!')
  }
  const handleClick = () => {
    if (handler) handler()
    else comingSoon()
  }

  return (
    <Paper square={false} style={{ display: 'flex', alignItems: 'center', padding: '32px' }}>
      <Box
        className={classes.icon}
        borderRadius="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="64px"
        width="64px"
      >
        {icon}
      </Box>

      <Typography className={classes.title} variant="h5" color="secondary" style={{ flexGrow: 1 }}>
        {title}
      </Typography>
      <Button color="primary" variant="contained" size="small" onClick={handleClick}>
        View
      </Button>
    </Paper>
  )
}

export default DashboardCard
