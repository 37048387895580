import { useSnackbar } from 'notistack'
import { ApolloError } from '@apollo/client'
import { GraphQLError } from 'graphql'

export const useErrorHandler = () => {
  const { enqueueSnackbar } = useSnackbar()
  const showErrorSnack = (message: string) => enqueueSnackbar(message, { variant: 'error' })
  const onGraphQLError = (error: GraphQLError) => {
    if (process.env.NODE_ENV === 'development') console.debug('[GraphQL error]', error)
    if (error.message) showErrorSnack(error.message)
    else {
      showErrorSnack('Unexpected error.')

      throw error
    }
  }

  return (error: ApolloError) => {
    if (error?.graphQLErrors) error.graphQLErrors.forEach(onGraphQLError)
  }
}
