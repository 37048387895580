import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { TherapistListView } from './list/TherapistListView'
import { UpdateTherapistView } from './update/EditTherapist'
import { DetailView } from './details/DetailView'
import { AppointmentsRootView } from '../appointments/AppointmentsRootView'
import { caseloadRootView } from '../caseload/caseloadRootView'
import { ScheduleRootView } from '../schedule/scheduleRootView'

export const TherapistsRootView = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:user_id/edit`} component={UpdateTherapistView} />
      <Route path={`${path}/:id/appointments`} component={AppointmentsRootView} />
      <Route path={`${path}/:id/details`} component={DetailView} />
      <Route path={`${path}/:userId/caseload`} component={caseloadRootView} />
      <Route path={`${path}/:userId/calendar`} component={ScheduleRootView} />
      <Route component={TherapistListView} />
    </Switch>
  )
}
