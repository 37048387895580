import React from 'react'
import { useField } from 'react-final-form'
import { FormControl, FormHelperText, FormLabel, Typography, styled } from '@material-ui/core'
import { showError } from '../util/showError'

interface FileFieldProps {
  name: string
  label?: string
  subLabel?: string
  required?: boolean
}
const SubLabel = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 'normal',
  paddingBottom: '8px'
}))

export const FileField = (props: FileFieldProps) => {
  const { label, name, required, subLabel } = props
  const {
    input: { value, onChange, ...rest },
    meta
  } = useField(name)
  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    onChange(target.files?.[0])
  }
  const isError = showError<typeof value>({ meta })

  return (
    <FormControl>
      <FormLabel required={required} error={isError} style={{ paddingBottom: 8 }}>
        {label}
      </FormLabel>
      {subLabel && <SubLabel>{subLabel}</SubLabel>}
      <input {...rest} type="file" onChange={handleChange} />
      {isError && <FormHelperText error={isError}>{meta.error}</FormHelperText>}
    </FormControl>
  )
}
