import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Search,
  Inject,
  ToolbarItems,
  Page,
  Sort,
  Filter,
  FilterSettingsModel
} from '@syncfusion/ej2-react-grids'
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import Button from 'components/ui/panel/button/Button'
import NavBar from 'components/ui/panel/nav-bar/NavBar'
import { PageContainer } from 'components/ui/panel/page-container/PageContainer'
import Paper from 'components/ui/panel/paper/Paper'
import { Section } from 'components/ui/panel/section/Section'
import { format } from 'date-fns'
import { useMyServiceRequestsQuery } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { request } from 'http'
import { NoData } from 'components/ui/panel/table/no-data/NoData'
import { serviceRequestTypeDescription } from '../util/enumDescription'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'

export const MyServiceRequestsView = () => {
  const history = useHistory()
  const classes = useStyles()
  const { data, loading } = useMyServiceRequestsQuery({ fetchPolicy: 'network-only' })
  const hasData = !!data?.myServiceRequests
  const message = loading ? 'Loading requests...' : 'No service requests found.'
  const handleCreate = () => history.push('/serviceRequests/create')
  const toolbarOptions: ToolbarItems[] = ['Search']

  return (
    <>
      <NavBar title="My service requests" goBackBtn />
      <PageContainer>
        <Section title="My service requests">
          <div className="control-pane">
            <div className="control-section">
              <GridComponent
                key={Math.floor(Math.random() * 100)}
                allowTextWrap
                dataSource={data?.myServiceRequests || []}
                width="auto"
                toolbar={toolbarOptions}
                allowPaging
                pageSettings={{ pageSizes: [15, 25, 50, 100], pageSize: 15, pageCount: 5 }}
                allowSorting
                className={classes.headerCls}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    template={() => (
                      <Avatar className="e-avatar e-avatar-large e-avatar-circle w-4- h-40" />
                    )}
                    width="20"
                  />
                  <ColumnDirective
                    field="student.name"
                    template={(disname) => (
                      <span className={classes.nameCls}>{disname.student.name}</span>
                    )}
                    headerText="STUDENT"
                    width="100"
                  />
                  <ColumnDirective
                    headerText="TYPE"
                    field="types"
                    template={(typesData) => (
                      <span>{serviceRequestTypeDescription[typesData.type]}</span>
                    )}
                    width="100"
                  />
                  <ColumnDirective
                    headerText="REQUESTED"
                    field="requested"
                    template={(createTypes) => (
                      <span>{format(new Date(createTypes.createdAt), 'MM/dd/yyyy HH:mm')}</span>
                    )}
                    width="100"
                  />
                </ColumnsDirective>
                <Inject services={[Search, Toolbar, Sort, Page]} />
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  className="mar-rt-15 custom-btn addEvent"
                  onClick={handleCreate}
                >
                  Request new service
                </Button>
              </GridComponent>
            </div>
          </div>
        </Section>
      </PageContainer>
    </>
  )
}
