/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, useContext } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreIcon from '@material-ui/icons/MoreVert'
import { VideoContext } from 'views/public/video/video'
import { engLoc, spaLoc } from 'config/constants/translations'
import {
  Button,
  styled,
  Theme,
  useMediaQuery,
  Menu as MenuContainer,
  MenuItem,
  Typography
} from '@material-ui/core'
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog'
import InfoIconOutlined from '../../../icons/InfoIconOutlined'
import StartRecordingIcon from '../../../icons/StartRecordingIcon'
import StopRecordingIcon from '../../../icons/StopRecordingIcon'
import SettingsIcon from '../../../icons/SettingsIcon'
import { useAppState } from '../../../state'
import useIsRecording from '../../../hooks/useIsRecording/useIsRecording'
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext'
import FlipCameraIcon from '../../../icons/FlipCameraIcon'
import useFlipCameraToggle from '../../../hooks/useFlipCameraToggle/useFlipCameraToggle'

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '1.5em',
  marginRight: '0.3em'
})

export default function Menu(props: { buttonClassName?: string }) {
  const loc = useContext(VideoContext)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [aboutOpen, setAboutOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [settingsOpen, setSettingsOpen] = useState(false)
  const { isFetching, updateRecordingRules, roomType } = useAppState()
  const { room } = useVideoContext()
  const isRecording = useIsRecording()
  const anchorRef = useRef<HTMLButtonElement>(null)
  const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle()

  return (
    <>
      <Button
        onClick={() => setMenuOpen((isOpen) => !isOpen)}
        ref={anchorRef}
        className={props.buttonClassName}
        data-cy-more-button
      >
        {isMobile ? (
          <MoreIcon />
        ) : (
          <>
            {loc === 'es' ? spaLoc.video.more : engLoc.video.more}
            <ExpandMoreIcon />
          </>
        )}
      </Button>
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen((isOpen) => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: isMobile ? -55 : 'bottom',
          horizontal: 'center'
        }}
      >
        {roomType !== 'peer-to-peer' && roomType !== 'go' && (
          <MenuItem
            disabled={isFetching}
            onClick={() => {
              setMenuOpen(false)

              if (isRecording) updateRecordingRules(room!.sid, [{ type: 'exclude', all: true }])
              else updateRecordingRules(room!.sid, [{ type: 'include', all: true }])
            }}
            data-cy-recording-button
          >
            <IconContainer>
              {isRecording ? <StopRecordingIcon /> : <StartRecordingIcon />}
            </IconContainer>
            <Typography variant="body1">
              {isRecording
                ? `${loc === 'es' ? spaLoc.video.stop : engLoc.video.stop}`
                : `${loc === 'es' ? spaLoc.video.start : engLoc.video.start}`}{' '}
              {loc === 'es' ? spaLoc.video.record : engLoc.video.record}
            </Typography>
          </MenuItem>
        )}
        {flipCameraSupported && (
          <MenuItem disabled={flipCameraDisabled} onClick={toggleFacingMode}>
            <IconContainer>
              <FlipCameraIcon />
            </IconContainer>
            <Typography variant="body1">
              {loc === 'es' ? spaLoc.video.flip : engLoc.video.flip}
            </Typography>
          </MenuItem>
        )}

        <MenuItem onClick={() => setSettingsOpen(true)}>
          <IconContainer>
            <SettingsIcon />
          </IconContainer>
          <Typography variant="body1">
            {loc === 'es' ? spaLoc.video.audioVideoset : engLoc.video.audioVideoset}
          </Typography>
        </MenuItem>

        <MenuItem onClick={() => setAboutOpen(true)}>
          <IconContainer>
            <InfoIconOutlined />
          </IconContainer>
          <Typography variant="body1">
            {loc === 'es' ? spaLoc.video.about : engLoc.video.about}
          </Typography>
        </MenuItem>
      </MenuContainer>
      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false)
          setMenuOpen(false)
        }}
      />
    </>
  )
}
