import { makeStyles } from '@material-ui/core'

export default makeStyles({
  resetPassword: {
    // minWidth: '300px'
    width: '300px'
  },
  fldWidth: {
    width: '100%',
    marginBottom: '15px'
  },
  pb10: {
    paddingBottom: '10px'
  },
  mb10: {
    marginBottom: '10px'
  },
  pb20: {
    paddingBottom: '20px'
  },
  rightIcon: {
    '& :visited': { color: 'blue' },
    '& :hover': { color: 'blue' },
    '& :active': { color: 'blue' }
  },
  leftIcon: {
    color: 'slategray'
  },
  EyeIcon: {
    color: 'slategray'
  }
})
