import React, { Component } from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import Blackoutdays from './blackoutdays'
import { Blackoutdayslist } from './blackoutdayslist'

export const BlackoutRootView = () => {
  const { url } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${url}/create`} component={Blackoutdays} />
      <Route path={`${url}/:id`} component={Blackoutdays} />
      <Route component={Blackoutdayslist} />
    </Switch>
  )
}
