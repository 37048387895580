import React from 'react'
import {
  Dialog as MDialog,
  DialogProps as MDialogProps,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Slide
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions/transition'

export interface DialogProps extends MDialogProps {
  handleClose?: () => unknown
  handleOnDelete?: () => unknown
  open: boolean
  name?: string
}

const ConfirmDialog: React.FC<DialogProps> = (props: DialogProps) => {
  const { open, handleClose, handleOnDelete, name, ...mDialogProps } = props
  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  return (
    <MDialog
      style={{ minWidth: '385px' }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <div style={{ padding: '35px' }}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div
              className="icons"
              style={{ display: 'flex', fontSize: '80px', justifyContent: 'center' }}
            >
              <span className="e-icons e-delete e-hover" style={{ color: '#F1583A' }} />
            </div>
            <div
              style={{
                textAlign: 'center',
                paddingTop: '45px',
                paddingBottom: '10px',
                fontWeight: 'bold',
                color: '#696969'
              }}
            >
              Are you sure you’d like to remove
              <br /> {name} from your caseload?
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            onClick={handleOnDelete}
            variant="contained"
            color="primary"
            style={{ fontWeight: 'normal' }}
          >
            Yes, remove
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            style={{ color: 'grey', fontWeight: 'normal', marginLeft: '20px' }}
          >
            Cancel
          </Button>
        </DialogActions>
      </div>
    </MDialog>
  )
}

export default ConfirmDialog
