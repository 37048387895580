import React, { useRef, useEffect, useMemo, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { Button, FormControl, MenuItem, Select } from '@material-ui/core'
import { NotificationsActiveSharp } from '@material-ui/icons'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import {
  useCurrentUserQuery,
  useTherapistIepsLazyQuery,
  useTherapistCaseloadsLazyQuery,
  useTherapistminutesLazyQuery,
  useTherapistabsencesLazyQuery,
  useUnDocumentedSessionsLazyQuery,
  useTherapistNewMetricsLazyQuery,
  Role,
  useProviderNameListLazyQuery
} from 'graphql/hooks.generated'
import { useHistory } from 'react-router-dom'
import { useCurrentUser } from '../../../graphql/hooks/useCurrentUser/useCurrentUser'
// import ScheduleImg from '../../../assets/illustrations/Schedule.png'
// import { ReactComponent as Group5Icon } from '../../../assets/icons/group5.svg'
// import { ReactComponent as Group6Icon } from '../../../assets/icons/group6.svg'
// import SayHello from '../../../assets/illustrations/Hello.png'
// import StudentFirst from '../../../assets/illustrations/StudentsFirst1.png'
// import Team from '../../../assets/illustrations/Team.png'
import useStyles from './Styles'
import Activity from './activity'
import Requests from './therapist/requests'
import { DashboardScheduer } from '../TherapistScheduler'
import { GetDashboardTimePeriods } from './dashboadHepler'
import { Chart } from './therapist/echarts'
import AutoGrid from './therapist/Grids'

export const MainTherapistDashboard = () => {
  const classes = useStyles()
  const [providername, setProvidername] = useState<any>('')
  const { hasAnyRole } = useCurrentUser()
  const history = useHistory()
  const yearToDates = GetDashboardTimePeriods('YTD')
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const [metricsData, setMetricsData] = useState<any>()
  const [iepDueQuery, { data: iepDueData, loading: iepDueLoading }] = useTherapistIepsLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [
    caseloadCountQuery,
    { data: caseloadCountData, loading: caseloadCountLoading }
  ] = useTherapistCaseloadsLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [
    hourlyCountQuery,
    { data: hourlyCountData, loading: hourlyCountLoading }
  ] = useTherapistminutesLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [stuAbsQuery, { data: stuAbsData, loading: stuAbsLoading }] = useTherapistabsencesLazyQuery(
    {
      fetchPolicy: 'network-only'
    }
  )
  const [undocQuery, { data: undocData, loading: undocLoading }] = useUnDocumentedSessionsLazyQuery(
    {
      fetchPolicy: 'network-only'
    }
  )
  const fetchiepdue = useMemo(
    () => ({ user_id }) =>
      iepDueQuery({
        variables: { user_id }
      }),
    [iepDueQuery]
  )
  const fetchCaseloadCnt = useMemo(
    () => ({ user_id, startDate, endDate }) =>
      caseloadCountQuery({
        variables: { user_id, startDate, endDate }
      }),
    [caseloadCountQuery]
  )
  const fetchHourCnt = useMemo(
    () => ({ user_id, startDate, endDate }) =>
      hourlyCountQuery({
        variables: { user_id, startDate, endDate }
      }),
    [hourlyCountQuery]
  )
  const fetchStuAbs = useMemo(
    () => ({ user_id, startDate, endDate }) =>
      stuAbsQuery({
        variables: { user_id, startDate, endDate }
      }),
    [stuAbsQuery]
  )
  const fetchUndoc = useMemo(
    () => ({ user_id, startDate, endDate }) =>
      undocQuery({
        variables: { user_id, startDate, endDate }
      }),
    [undocQuery]
  )
  const [
    executeThepistQuery,
    { data: therapistData, loading: therapistDocumentLoading }
  ] = useProviderNameListLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      agency: String(
        currentUserdata?.data?.currentUser?.signalAgencies &&
          currentUserdata?.data?.currentUser?.signalAgencies[0]?.name
          ? currentUserdata?.data?.currentUser?.signalAgencies[0]?.name
          : 0
      )
    }
  })
  const fetchTherapists = useMemo(() => () => executeThepistQuery(), [executeThepistQuery])
  useEffect(() => {
    fetchTherapists()
  }, [fetchTherapists])
  useEffect(() => {
    if (therapistData && currentUserdata) {
      const provider = therapistData?.providerName?.[0]?.provider_id
      const currentprovider = currentUserdata?.data?.currentUser?.roles?.[0]
      setProvidername(
        currentprovider === Role.AgencySupervisorProvider
          ? currentUserdata?.data?.currentUser?.id || null
          : provider || null
      )
    }
  }, [currentUserdata, therapistData, therapistData?.providerName])
  const providerchange = (e) => {
    setProvidername(e.target.value)
  }
  const { data } = useCurrentUser()
  const providerName = data?.currentUser.name
  // useEffect(() => {
  //   if (currentUserdata) {
  //     const input = {
  //       user_id: currentUserdata?.data?.currentUser?.id,
  //       startDate: yearToDates.startDate,
  //       endDate: yearToDates.endDate
  //     }
  //     const user_obj = { user_id: currentUserdata?.data?.currentUser?.id }
  //     fetchiepdue(user_obj)
  //     fetchCaseloadCnt(input)
  //     fetchHourCnt(input)
  //     fetchStuAbs(input)
  //     fetchUndoc(input)
  //   }
  // }, [
  //   currentUserdata,
  //   yearToDates.startDate,
  //   yearToDates.endDate,
  //   fetchiepdue,
  //   fetchCaseloadCnt,
  //   fetchHourCnt,
  //   fetchStuAbs,
  //   fetchUndoc
  // ])

  function createBreakdown(name: string, rec: number, rem: number) {
    return { name, rec, rem }
  }

  // const breakdown = [
  //   createBreakdown(' Amanda Dunkin', 350, -173),
  //   createBreakdown(' Lisa Moore', 230, -180),
  //   createBreakdown(' Emily Smith', 318, -150),
  //   createBreakdown(' Shannan Rouff', 310, -160),
  //   createBreakdown(' Jesse Clark', 54, -80),
  //   createBreakdown(' Amanda Dunkin', 350, -173),
  //   createBreakdown(' Lisa Moore', 230, -180),
  //   createBreakdown(' Emily Smith', 318, -150),
  //   createBreakdown(' Shannan Rouff', 310, -160),
  //   createBreakdown(' Jesse Clark', 54, -80),
  //   createBreakdown(' Amanda Dunkin', 350, -173),
  //   createBreakdown(' Lisa Moore', 230, -180),
  //   createBreakdown(' Emily Smith', 318, -150)
  // ]

  function createReports(date: string, name: string) {
    return { date, name }
  }

  // const reports = [
  //   createReports('05/25/21', 'L. Dunkin Progress Summary'),
  //   createReports('04/25/21', 'T.Smith Progress Report'),
  //   createReports('03/25/21', ' Moore Missing Minutes'),
  //   createReports('02/25/21', 'P.Press Progress Summary'),
  //   createReports('05/25/21', 'L. Dunkin Progress Summary'),
  //   createReports('04/25/21', 'T.Smith Progress Report'),
  //   createReports('03/25/21', ' Moore Missing Minutes'),
  //   createReports('02/25/21', 'P.Press Progress Summary'),
  //   createReports('02/25/21', 'P.Press Progress Summary')
  // ]
  let undocvalue = 0
  let caseloadStu = 0
  let hourCount = 0

  if (undocData && undocData?.unDocumentedSessions)
    undocData.unDocumentedSessions.map((doc) => {
      undocvalue = doc?.unDocumentedSession ? doc.unDocumentedSession : 0

      return true
    })
  if (caseloadCountData && caseloadCountData?.therapistCaseloads)
    caseloadCountData?.therapistCaseloads.map((stud) => {
      caseloadStu = stud?.caseload_count ? stud.caseload_count : 0

      return true
    })
  if (hourlyCountData && hourlyCountData?.therapistminutes)
    hourlyCountData?.therapistminutes.map((hourC) => {
      hourCount = hourC?.minutes ? hourC.minutes : 0

      return true
    })

  const handleClick = () => {
    if (hasAnyRole(Role.AgencySupervisorProvider, Role.AgencySupervisor))
      history.push({
        pathname: '/documentation',
        search: '?type=tp',
        state: providername
      })
    else if (hasAnyRole(Role.Therapist, Role.SchoolTherapist))
      history.push(`/documentation?type=tp`)
  }

  return (
    <div>
      {/* {(iepDueLoading ||
        caseloadCountLoading ||
        hourlyCountLoading ||
        stuAbsLoading ||
        undocLoading) && <ProgressIndicator fullHeight />} */}
      {hasAnyRole(Role.AgencySupervisorProvider, Role.AgencySupervisor) && (
        <>
          <Grid container style={{ justifyContent: 'end', padding: '10px' }}>
            <Grid item xs={2}>
              <span>
                <FormControl size="medium" fullWidth>
                  {/* <b style={{ fontSize: '16px' }}>Provider:</b> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant="filled"
                    style={{
                      height: '36px',
                      fontSize: '17px'
                    }}
                    value={providername || ''}
                    className={classes.selectDropdown}
                    onChange={providerchange}
                  >
                    {therapistData?.providerName && therapistData?.providerName?.length > 0 ? (
                      therapistData?.providerName?.map((listTherapists) => (
                        <MenuItem
                          key={listTherapists.provider_id?.toString()}
                          value={listTherapists.provider_id?.toString()}
                          className={classes.ellipsistext}
                        >
                          {listTherapists.provider_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="No Data Found">No Data Found</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </span>
            </Grid>
          </Grid>
        </>
      )}
      <Grid container>
        <Grid item xs={7} className={classes.leftContainer}>
          <div className={classes.dataCls}>
            <Grid item xs={12}>
              <Paper className={classes.paperNewStyle1}>
                <Grid xs={1} className={classes.headingClsNew}>
                  <NotificationsActiveSharp className={classes.alertIcon} />
                </Grid>
                <Grid
                  xs={7}
                  className={classes.headingClsNew1}
                  style={{ overflow: 'hidden', maxWidth: '100%' }}
                >
                  <span>
                    Welcome,{' '}
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {providerName}
                    </span>
                    !
                  </span>
                  <p>{`You have ${metricsData?.incomplete_session_count || 0} incomplete notes &  ${
                    metricsData?.complete_session_count || 0
                  } completed notes in the last 7 days`}</p>

                  {/* <span>{`You have ${undocvalue} undocmuented sessions`}</span>
                  <div
                    style={{
                      width: '100%',
                      maxWidth: 60,
                      minHeight: 44,
                      backgroundImage: `url(${ScheduleImg})`,
                      backgroundSize: 'cover',
                      borderRadius: 8,
                      display: 'inline',
                      float: 'right',
                      marginRight: '60px'
                    }}
                  /> */}
                </Grid>
                <Grid xs={4} className={classes.headingSelect}>
                  <Button
                    variant="outlined"
                    className={classes.submitViewCls1}
                    onClick={handleClick}
                  >
                    View & Document
                  </Button>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} className={classes.calenderCls}>
              {/* <Paper className={classes.papercalender}>calender</Paper> */}
              <Paper className={classes.papercalender2}>
                {/* <ScheduleComponent
                  width="100%"
                  height="510px"
                  selectedDate={new Date(2018, 5, 20)}
                  eventSettings={{ dataSource: data }}
                  ref={scheduleObj}
                >
                  <ViewsDirective>
                    <ViewDirective option="MonthAgenda" />
                  </ViewsDirective>
                  <Inject services={[MonthAgenda]} />
                </ScheduleComponent> */}
                <DashboardScheduer providerId={providername} />
              </Paper>
            </Grid>
            {/* <Paper className={classes.paperNew}> */}
            <Grid item xs={12} className={classes.calenderCls}>
              <Paper className={classes.chart}>
                <Chart providerId={providername} />
              </Paper>
            </Grid>
            {/* </Paper> */}
            <Grid item xs={12} className={classes.calenderCls}>
              <Paper className={classes.paperNew}>
                <Grid item xs={12} className={classes.tableProvideCls}>
                  <Requests providerId={providername} />
                </Grid>
              </Paper>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={5} className={classes.rightContainer}>
          <Grid item xs={12} className={classes.rightCls1}>
            <AutoGrid metrics={(value) => setMetricsData(value)} providerId={providername} />
            {/* <Grid item xs={12} className={classes.tableMainCls}>
              <TableContainer component={Paper}>
                <Table className={classes.tableBorder} aria-label="simple table">
                  <Paper className={classes.paperNewStyle}>activity</Paper>
                </Table>
              </TableContainer>
            </Grid>
             */}
            <Paper className={classes.paperNew}>
              <Grid item xs={12} className={classes.lookUpCls}>
                <Activity providerId={providername} />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
