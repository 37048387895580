import React, { useMemo, useEffect } from 'react'
import { MenuItem } from '@material-ui/core'
import { Select } from 'mui-rff'
import { usePrimaryLanguageLazyQuery } from '../../../../graphql/hooks.generated'
import { useStyles } from '../Styles'

interface PrimaryLaguageProps {
  label: string
  name: string
  required?: boolean
}

export const PrimaryLaguageSelect = (props: PrimaryLaguageProps) => {
  const active: any = 'ACTIVE'
  const classes = useStyles()
  const { label, name } = props
  const [executeQuery, { data }] = usePrimaryLanguageLazyQuery({
    fetchPolicy: 'network-only'
  })
  console.log(data)
  const fetch = useMemo(() => () => executeQuery(), [executeQuery])
  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <>
      {data?.primaryLanguage && data?.primaryLanguage?.length > 0 ? (
        <Select
          variant="filled"
          name={name}
          placeholder={name}
          label={label}
          className={classes.selectDropdown}
          required
          // options={data?.agesServed.filter((agesServed) => agesServed.status === active)}
          // getOptionLabel={(option) => option?.name}
          // getOptionValue={(option) => option?.name}
          multiple
        >
          {data?.primaryLanguage.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select {...props} variant="filled">
          <MenuItem>No Data Found</MenuItem>
        </Select>
      )}
    </>
  )
}
