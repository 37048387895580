import React, { useContext } from 'react'
import { FormControl, MenuItem, Typography, Select } from '@material-ui/core'
import { VideoContext } from 'views/public/video/video'
import { engLoc, spaLoc } from 'config/constants/translations'
import { useAppState } from '../../../state'
import useDevices from '../../../hooks/useDevices/useDevices'

export default function AudioOutputList() {
  const { audioOutputDevices } = useDevices()
  const loc = useContext(VideoContext)
  const { activeSinkId, setActiveSinkId } = useAppState()
  const activeOutputLabel = audioOutputDevices.find((device) => device.deviceId === activeSinkId)
    ?.label

  return (
    <div className="inputSelect">
      {audioOutputDevices.length > 1 ? (
        <FormControl fullWidth>
          <Typography variant="subtitle2" gutterBottom>
            {loc === 'es' ? spaLoc.video.audOut : engLoc.video.audOut}
          </Typography>
          <Select
            onChange={(e) => setActiveSinkId(e.target.value as string)}
            value={activeSinkId}
            variant="outlined"
          >
            {audioOutputDevices.map((device) => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <>
          <Typography variant="subtitle2">
            {loc === 'es' ? spaLoc.video.audOut : engLoc.video.audOut}
          </Typography>
          <Typography>
            {activeOutputLabel ||
              `${loc === 'es' ? spaLoc.video.sysDefAudOut : engLoc.video.sysDefAudOut}`}
          </Typography>
        </>
      )}
    </div>
  )
}
