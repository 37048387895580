import React from 'react'
import { RouteProps, Route, Redirect } from 'react-router-dom'
import { Role } from '../../graphql/hooks.generated'
import { useCurrentUser } from '../../graphql/hooks/useCurrentUser/useCurrentUser'

interface PrivateRouteProps extends RouteProps {
  roles: Role[]
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { roles, ...rest } = props
  const { hasAnyRole } = useCurrentUser()

  if (hasAnyRole(...roles)) return <Route {...rest} />

  return <Redirect to="/" />
}
