import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { WaitlistListView } from './list/WaitlistListView'
import { WaitlistLog } from './waitlistlog/waitlistlog'

export const WaitlistRootView = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      {/* <Route path={`${path}/create`} component={CreateAgencyView} />
      <Route path={`${path}/:id/edit`} component={UpdateAgencyView} /> */}
      <Route path={`${path}/waitlistlogs`} component={WaitlistLog} />
      <Route component={WaitlistListView} />
    </Switch>
  )
}
