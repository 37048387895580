import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { Sessionapprovalreport } from './list/Sessionapprovalreport'

export const SessionReportRootView = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route component={Sessionapprovalreport} />
    </Switch>
  )
}
