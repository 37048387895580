import React, { PropsWithChildren } from 'react'
import { ApolloClient, ApolloProvider, InMemoryCache, ApolloLink, from } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { onError, ErrorResponse } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { extractFiles } from 'extract-files'
import { ServerError } from '@apollo/client/link/utils'
import fragmetIntrospection from '../../../graphql/schema-fragments.generated.json'
import { tourRunStatusVar } from '../../../graphql/vars'
import { useAuth } from '../auth'
import { AuthenticationContext } from '../../../types'

const { REACT_APP_API_URL } = process.env
let userToken: string | null = null
let logout: () => void = () => {}
const cache = new InMemoryCache({
  possibleTypes: fragmetIntrospection.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        tourRunStatus: {
          read() {
            return tourRunStatusVar()
          }
        }
      }
    }
  }
})
const httpOptions = { uri: `${REACT_APP_API_URL}/graphql` }
const batchLink = new BatchHttpLink(httpOptions)
const uploadLink = createUploadLink(httpOptions)
const uploadAndBatchLink = ApolloLink.split(
  (operation) => extractFiles(operation).files.size > 0,
  uploadLink, // https://github.com/DefinitelyTyped/DefinitelyTyped/pull/46191
  batchLink
)
const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: userToken ? `Bearer ${userToken}` : ''
  }
}))
const errorLink = onError((error: ErrorResponse) => {
  const { graphQLErrors } = error
  const unautheticatedErr = graphQLErrors?.find(
    (gqlErr) => gqlErr?.extensions?.code === 'UNAUTHENTICATED'
  )

  if (userToken && (unautheticatedErr || (error?.networkError as ServerError)?.statusCode === 401))
    logout()
})
const link = from([errorLink, authLink, uploadAndBatchLink])

export const client = new ApolloClient({ link, cache })

export const GraphQLProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { tokens, logout: realLogout }: AuthenticationContext = useAuth()
  userToken = tokens?.id ?? ''
  logout = realLogout

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
