/* eslint-diable */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Grid, Box, Divider, TextField, InputLabel, Container, Step } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { UseParamsType } from 'types'
import TagManager from 'react-gtm-module'
import Button from '../../panel/button/Button'
import useStyles from './Styles'
import { RegistrationForm } from './registrationForm'
import { RegistrationFormNext } from './registrationFormNext'
import { defaultRegistrationState } from './constants'
import { TherapistsList } from './therapistsList'
import { Register } from './Register'
import { ReviewForm } from './reviewForm'
import { WaitListForm } from './waitlistForm'
import { Urlservice } from '../../../../views/public/serviceHelpers/urlhelper'

interface ListTokenParams extends UseParamsType {
  loc: string
}

export const AboutYou = () => {
  // useEffect(() => {
  //   const tagManagerArgs = {
  //     gtmId: 'GTM-T644KWW'
  //   }
  //   TagManager.initialize(tagManagerArgs)
  // }, [])
  const classes = useStyles()
  const { loc } = useParams<ListTokenParams>()
  const { t, i18n } = useTranslation()
  useMemo(() => {
    if (loc === 'en' || loc === 'es') i18n.changeLanguage(loc || 'en')
    else i18n.changeLanguage('en')
  }, [i18n, loc])
  const backgroundStyle = {
    backgroundImage: 'url(../../comment.svg)',
    backgroundPosition: '100% 0px',
    backgroundSize: 'auto 50px',
    backgroundRepeat: 'no-repeat',
    marginTop: '62px'
  }
  let backgroundColors
  let backgroundSubColors
  let headerTitle
  let paragraphTxt
  // const getLocStor = localStorage.getItem('i18nextLng') || 'en'
  const headerTxt = {
    1: `${t('registrationform.sideH')}`,
    2: `${t('Registration.sideH')}`,
    3: `${t('scheduling.screenH')}`
  }
  const { enqueueSnackbar } = useSnackbar()
  const [state, setState] = useState(defaultRegistrationState)
  const [isWaitlistFlow, setIsWaitlistFlow] = useState(false)
  const [formData, setFormData] = useState(null)
  const [selectedTherapist, setSelectedTherapist] = useState(null)

  if (!isWaitlistFlow) {
    backgroundColors = {
      1: '#201057',
      2: '#FF1C00',
      3: '#00DE74',
      4: '#FF51A7',
      5: '#46A7DD'
    }
    backgroundSubColors = {
      1: '#dcd5f6',
      2: '#fce2de',
      3: '#e5fef2',
      4: '#fae5f0',
      5: '#46A7DD'
    }
    headerTitle = {
      1: `${t('registrationform.registration')}`,
      2: `${t('Registration.registration')}`,
      3: `${t('scheduling.scheduling')}`,
      4: `${t('review.Review')}`
    }
    paragraphTxt = {
      1: `${t('registrationform.sidetext')}`,
      2: `${t('Registration.sideT2')}`,
      3: `${t('scheduling.screentext')}`,
      4: `${t('review.reviewnote')}`
    }
  } else {
    backgroundColors = {
      1: '#201057',
      2: '#FF1C00',
      3: '#00DE74',
      4: '#844191',
      5: '#FF51A7',
      6: '#46A7DD'
    }
    backgroundSubColors = {
      1: '#dcd5f6',
      2: '#fce2de',
      3: '#e5fef2',
      4: '#fae5f0',
      5: '#fae5f0',
      6: '#46A7DD'
    }
    headerTitle = {
      1: `${t('registrationform.registration')}`,
      2: `${t('Registration.registration')}`,
      3: `${t('scheduling.scheduling')}`,
      4: `${t('waitlist.waitlist')}`,
      5: `${t('review.Review')}`
    }
    paragraphTxt = {
      1: `${t('registrationform.sidetext')}`,
      2: `${t('Registration.sideT2')}`,
      3: `${t('scheduling.screentext')}`,
      5: `${t('review.reviewnote')}`
    }
  }

  const prevStep = () => {
    const { step } = state
    setState({ ...state, step: step - 1 })
  }
  const history = useHistory()
  const nextStep = () => {
    const { step } = state
    setState({ ...state, step: step + 1 })
  }
  const surveyIdData = React.useCallback(() => {
    const surveyIdSession = sessionStorage.getItem('sid')

    if (surveyIdSession) {
      // const surveyId = {
      //   surveyid: '4cee5b5a-1262-4ff2-b48f-e2069e52caa9'
      // }
      const surveyId = {
        surveyid: surveyIdSession
      }
      Urlservice.posturl('signal/studentinfo', surveyId)
        .then((surveyData) => {
          if (
            typeof surveyData?.data === 'string' &&
            surveyData?.data?.includes('Slot has already booked')
          ) {
            enqueueSnackbar(`${t('errormessages.resurvey')}`, {
              variant: 'info'
            })
            history.push('/public/survey')
          }

          setFormData(surveyData?.data)
        })
        .catch((error) => {
          enqueueSnackbar(error.message || `${t('errormessages.reload')}`, {
            variant: 'error'
          })
        })
    } else history.push('/public/survey')
  }, [history, enqueueSnackbar, t])
  // const handleChange = (input) => (e) => {
  //   setState({ ...state, [input]: e.target.value })
  // }
  useEffect(() => {
    surveyIdData()
  }, [surveyIdData])
  const handleChange = (data) => {
    setFormData(data)
  }
  const therapistHandleChange = (flag, data?, selectTherpist?) => {
    setFormData(data)
    setSelectedTherapist(selectTherpist)
    setIsWaitlistFlow(flag)
  }
  const onSubmit = (values) => {
    nextStep()
  }
  const getStepContent = () => {
    const { step } = state

    if (!isWaitlistFlow)
      switch (step) {
        case 1:
          return (
            <RegistrationForm
              nextStep={nextStep}
              handleChange={handleChange}
              onSubmit={onSubmit}
              values={formData}
            />
          )
        case 2:
          return (
            <RegistrationFormNext
              nextStep={nextStep}
              handleChange={handleChange}
              prevStep={prevStep}
              values={formData}
            />
          )
        case 3:
          return (
            <TherapistsList
              nextStep={nextStep}
              handleChange={therapistHandleChange}
              prevStep={prevStep}
              values={formData}
              // formExpand={selectedTherapist}
            />
          )
        case 4:
          return (
            <ReviewForm
              nextStep={nextStep}
              handleChange={handleChange}
              prevStep={prevStep}
              values={formData}
            />
          )
        case 5:
          return <Register />
        default:
          return <></>
      }
    else
      switch (step) {
        case 1:
          return (
            <RegistrationForm
              nextStep={nextStep}
              handleChange={handleChange}
              onSubmit={onSubmit}
              values={formData}
            />
          )
        case 2:
          return (
            <RegistrationFormNext
              nextStep={nextStep}
              handleChange={handleChange}
              prevStep={prevStep}
              values={formData}
            />
          )
        case 3:
          return (
            <TherapistsList
              nextStep={nextStep}
              handleChange={therapistHandleChange}
              prevStep={prevStep}
              values={formData}
              // formExpand={selectedTherapist}
            />
          )
        case 4:
          return (
            <WaitListForm
              nextStep={nextStep}
              handleChange={handleChange}
              prevStep={prevStep}
              values={formData}
            />
          )
        case 5:
          return (
            <ReviewForm
              nextStep={nextStep}
              handleChange={handleChange}
              prevStep={prevStep}
              values={formData}
              isWaitlistFlow={isWaitlistFlow}
            />
          )
        case 6:
          return <Register />
        default:
          return <></>
      }
  }

  return (
    <div
      className={`${classes.root} ${classes.errormsgs}`}
      style={{ backgroundColor: backgroundColors[state.step] }}
    >
      <Container
        style={{
          backgroundColor: backgroundColors[state.step],
          maxWidth: '83%',
          paddingRight: '0px'
        }}
      >
        <Grid container direction="row">
          <Grid
            container
            md={9}
            lg={9}
            sm={12}
            direction="row"
            style={{
              padding: '10px 5px 20px 30px',
              backgroundColor: 'white'
            }}
          >
            <Grid item sm={12}>
              <h1 className={classes.popin} style={{ color: backgroundColors[state.step] }}>
                {headerTitle[state.step]}
              </h1>
              <Divider variant="middle" />
            </Grid>

            {getStepContent()}
          </Grid>

          <Grid
            container
            direction="column"
            alignItems="center"
            md={3}
            lg={3}
            sm={12}
            className={classes.popin}
            style={{ backgroundColor: backgroundSubColors[state.step], padding: '0px 30px' }}
          >
            <Grid
              item
              md={8}
              lg={8}
              spacing={5}
              style={{ ...backgroundStyle, color: backgroundColors[state.step] }}
            >
              <h2 style={{ marginTop: '80px' }}> {headerTxt[state.step]}</h2>
              <p style={{ fontSize: '16px' }}>{paragraphTxt[state.step]}</p>
              {/* <h1 style={{ marginTop: '80px' }}>{[1].includes(state.step) && 'Tell us more!'}</h1>
              <p style={{ fontSize: '22px' }}>
                {![1, 2].includes(state.step) && 'Review your provider matches'}
                {[1, 2].includes(state.step) &&
                  'Please tell us more information about yourself to help us find the right provider.'}
              </p> */}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
