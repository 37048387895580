import React, { useMemo, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { UseParamsType } from 'types'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
// import CircularProgress from '@material-ui/core/CircularProgress'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { useLocation, useParams } from 'react-router-dom'
import { Select, MenuItem } from '@material-ui/core'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import {
  useUserStudentsForDocLazyQuery,
  useCurrentUserQuery,
  Role,
  useTherapistListForDocLazyQuery,
  useProviderNameListLazyQuery
} from 'graphql/hooks.generated'
import NavBar from '../../../../../../components/ui/panel/nav-bar/NavBar'
import { useStyles } from '../../../../../../config/constants/theme/profile'
import CollapsibleTable from './table'
import { PageContainer } from '../../../../../../components/ui/panel/page-container/PageContainer'

interface CaseloadParams extends UseParamsType {
  caseload_id: string
  stu_id: string
}
interface ParentParams {
  caseloaddata: any
}

export default function Documentation(props: ParentParams) {
  const classes = useStyles()
  const location = useLocation()
  const { state }: any = location
  const { hasAnyRole } = useCurrentUser()
  const [providername, setProvidername] = useState<any>('')
  const { caseloaddata } = props
  const params = new URLSearchParams(window.location.search)
  let isTherapst = false
  let isAdmin = false

  if (params.get('type') === 'tp') isTherapst = true
  if (params.get('type') === 'da') isAdmin = true

  const lastOneWeek = moment().subtract(7, 'days').format('YYYY-MM-DD')
  const currentDate = moment().format('YYYY-MM-DD')
  const [selectedFromDate, setSelectedFromDate] = useState(lastOneWeek)
  // Get last one week data for date filter
  const [selectedToDate, setSelectedToDate] = useState<Date | null>(new Date())
  const [count, setcount] = useState(0)
  const [getStuId, setStuId] = useState<any>('')
  const [therapistId, setTherapistId] = useState('')
  const { stu_id } = useParams<CaseloadParams>()
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const [
    executeUserStuQuery,
    { data: userStuData, loading: userDocumentLoading, refetch: userDocumentrefetch }
  ] = useUserStudentsForDocLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetchUserStu = useMemo(
    () => ({ user_id }) =>
      executeUserStuQuery({
        variables: { user_id }
      }),
    [executeUserStuQuery]
  )
  const [
    executeThepistQuery,
    { data: therapistData, loading: therapistDocumentLoading }
  ] = useTherapistListForDocLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      district_id: Number(currentUserdata?.data?.currentUser?.district?.id)
    }
  })
  const [
    executeproviderQuery,
    { data: providerData, loading: providerDocumentLoading }
  ] = useProviderNameListLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      agency: String(
        currentUserdata?.data?.currentUser?.signalAgencies &&
          currentUserdata?.data?.currentUser?.signalAgencies[0]?.name
          ? currentUserdata?.data?.currentUser?.signalAgencies[0]?.name
          : 0
      )
    }
  })
  const fetchprovider = useMemo(() => () => executeproviderQuery(), [executeproviderQuery])
  useEffect(() => {
    fetchprovider()
  }, [fetchprovider])
  const providerchange = (e: any) => {
    if (e.target.value === 'All') setStuId(-1)
    else setStuId('')

    setProvidername(e.target.value)
  }
  const fetchTherapists = useMemo(() => () => executeThepistQuery(), [executeThepistQuery])
  const setStudentId = (e) => {
    setStuId(e.target.value)
  }
  const handleChangeTherapist = (e) => {
    setTherapistId(e.target.value)
    const input = {
      user_id: String(e.target.value)
    }
    fetchUserStu(input)
    setStuId('')
  }
  const totalsession = (value) => {
    setcount(value)
  }
  const fromdate = (e) => {
    setSelectedFromDate(e.target.value)
  }
  const todate = (e) => {
    setSelectedToDate(e.target.value)
  }
  useEffect(() => {
    if (providername) {
      const input = {
        user_id: providername
      }
      fetchUserStu(input)
    }
  }, [providername, fetchUserStu])
  useEffect(() => {
    const provider = providerData?.providerName?.[0]?.provider_id
    const rolesName = currentUserdata?.data?.currentUser?.roles?.[0]

    if (state?.length > 0) setProvidername(state)
    else if (rolesName === Role.AgencySupervisor) {
      setProvidername('All')
      setStuId(-1)
    } else setProvidername((currentUserdata && currentUserdata?.data?.currentUser?.id) || '')
  }, [currentUserdata, providerData?.providerName, state])
  useEffect(() => {
    if (currentUserdata) {
      if (!stu_id && isTherapst) {
        const input = {
          user_id: String(currentUserdata?.data?.currentUser?.id)
        }
        fetchUserStu(input)
      }
      if (!stu_id && isAdmin) fetchTherapists()

      fetchprovider()
    }
  }, [currentUserdata, fetchUserStu, stu_id, isTherapst, isAdmin, fetchTherapists, fetchprovider])
  const isLoading = () =>
    userDocumentLoading || therapistDocumentLoading ? <ProgressIndicator fullHeight /> : ''
  const parentView = () => {
    return (
      <Grid container style={{ justifyContent: 'space-between' }}>
        <Grid item md={!stu_id && isAdmin ? 2 : 2} className={classes.docbuttonsalignStyle}>
          <div className={classes.namecard}>
            <div className={classes.sessionStyle}>
              <div>
                <p className={classes.blueback}>
                  Total Sessions : <strong className={classes.bluelabel}>{count}</strong>
                </p>
              </div>
            </div>
          </div>
        </Grid>
        {!hasAnyRole(Role.AgencySupervisorProvider, Role.AgencySupervisor) && !stu_id && isAdmin && (
          <Grid item md={2}>
            <Grid container style={{ marginTop: '24px' }}>
              <Grid style={{ textAlign: 'right' }} item md={6}>
                <span className={classes.filteralign}>Providers:&nbsp;</span>
              </Grid>
              <Grid item md={6}>
                <Select
                  className={classes.selectDropdown}
                  variant="filled"
                  defaultValue=""
                  onChange={handleChangeTherapist}
                  classes={{
                    root: 'muisc-root',
                    select: 'muisc-select',
                    selectMenu: 'muisc-select-menu'
                  }}
                >
                  {therapistData?.therapistListForDoc?.map((listTherapists) => (
                    <MenuItem
                      key={listTherapists.id?.toString()}
                      placeholder="Select provider"
                      value={listTherapists.id?.toString()}
                    >
                      {listTherapists.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        )}
        {hasAnyRole(Role.AgencySupervisorProvider, Role.AgencySupervisor) && (
          <Grid item md={2}>
            <Grid container style={{ marginTop: '24px' }}>
              <Grid style={{ textAlign: 'right' }} item md={6}>
                <span className={classes.filteralign}>Providers:&nbsp;</span>
              </Grid>
              <Grid item md={6}>
                <Select
                  className={classes.selectDropdown}
                  variant="filled"
                  value={providername || ''}
                  onChange={providerchange}
                  classes={{
                    root: 'muisc-root',
                    select: 'muisc-select',
                    selectMenu: 'muisc-select-menu'
                  }}
                >
                  <MenuItem value="All">All</MenuItem>
                  {providerData?.providerName && providerData?.providerName?.length > 0 ? (
                    providerData?.providerName?.map((listTherapists) => (
                      <MenuItem
                        key={listTherapists.provider_id?.toString()}
                        placeholder="Select provider"
                        value={listTherapists.provider_id?.toString()}
                        style={{
                          maxWidth: '280px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {listTherapists.provider_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="No Data Found">No Data Found</MenuItem>
                  )}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item md={!stu_id && isAdmin ? 2 : 2}>
          {!stu_id && (
            <Grid container style={{ marginTop: '24px' }}>
              <Grid style={{ textAlign: 'right' }} item md={6}>
                <span className={classes.filteralign}>Clients:&nbsp;</span>
              </Grid>
              <Grid item md={6}>
                <Select
                  disabled={isAdmin && !therapistId && !providername}
                  className={classes.selectDropdown}
                  variant="filled"
                  defaultValue=""
                  value={getStuId}
                  onChange={setStudentId}
                  classes={{
                    root: 'muisc-root',
                    select: 'muisc-select',
                    selectMenu: 'muisc-select-menu'
                  }}
                >
                  {isTherapst && <MenuItem value={-1}>All</MenuItem>}
                  {userStuData?.userStudentsForDoc?.map((listStudents) => (
                    <MenuItem
                      key={listStudents.id?.toString()}
                      placeholder="Select clients"
                      value={listStudents.id?.toString()}
                    >
                      {listStudents.student_name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item md={6} className={`${classes.docbuttonsalign} ${classes.pr15}`}>
          <div className={classes.namecard}>
            <p className={classes.filteralign}>From Date : </p>
            <TextField
              className={`${classes.textField} ${classes.input}`}
              name="fromdate"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              aria-invalid="true"
              label=""
              defaultValue={lastOneWeek}
              onChange={fromdate}
            />
          </div>
          <div className={classes.namecard}>
            <span className={classes.filteralign}> To Date : </span>
            <TextField
              className={`${classes.textField} ${classes.input}`}
              name="todate"
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              aria-invalid="true"
              label=""
              defaultValue={currentDate}
              onChange={todate}
            />
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.straightlineStyle} />
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.docTableAlign}>
          {caseloaddata && stu_id && (
            <CollapsibleTable
              fromDate={selectedFromDate}
              toDate={selectedToDate}
              caseloaddata={caseloaddata}
              totalsession={totalsession}
            />
          )}
          {!stu_id && isTherapst && (
            <CollapsibleTable
              fromDate={selectedFromDate}
              toDate={selectedToDate}
              totalsession={totalsession}
              studentFilter={getStuId}
              providername={providername}
              isTherapst
            />
          )}
          {!stu_id && isAdmin && (
            <CollapsibleTable
              fromDate={selectedFromDate}
              toDate={selectedToDate}
              totalsession={totalsession}
              studentFilter={getStuId}
              therapistFilter={therapistId}
              isAdmin
            />
          )}
        </Grid>
        {isLoading()}
      </Grid>
    )
  }

  return (
    <div>
      {stu_id ? (
        parentView()
      ) : (
        <>
          <NavBar className="container-adjust" title="Documentation" goBackBtn />
          <PageContainer>{parentView()}</PageContainer>
        </>
      )}
    </div>
  )
}
