import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import Paper from '../../../panel/paper/Paper'
import Button from '../../../panel/button/Button'
import { ReactComponent as MessageIcon } from '../../../../../assets/icons/message.svg'
import sayHello from '../../../../../assets/illustrations/Say-Hello.png'

const unreadMessageCountQuery = gql`
  query unreadMessageCount {
    unreadMessageCount {
      count
    }
  }
`
const MessageCard: React.FC = ({ ...props }) => {
  const history = useHistory()
  const goToMessages = () => {
    history.push('/messages')
  }
  const { data: unreadMessageCountData } = useQuery(unreadMessageCountQuery)

  return (
    <Paper {...props} id="messaging">
      <Box style={{ padding: 24 }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{ marginBottom: 10 }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MessageIcon />
            <Typography variant="h3" color="secondary" style={{ marginLeft: 12 }}>
              Messages
            </Typography>
          </div>
          <Typography
            gutterBottom
            variant="subtitle1"
            color="primary"
            style={{ fontWeight: 'bold' }}
          >
            {unreadMessageCountData?.unreadMessageCount.count}
          </Typography>
        </Box>
        <div
          style={{
            width: '100%',
            maxWidth: 300,
            minHeight: 150,
            backgroundColor: '#F7F7F7',
            backgroundImage: `url(${sayHello})`,
            backgroundSize: 'cover',
            borderRadius: 8
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 10,
            alignItems: 'center'
          }}
        >
          <Typography style={{ marginTop: 5, marginRight: 2 }} gutterBottom variant="subtitle1">
            Read, write, and respond to messages
          </Typography>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={goToMessages}
            style={{ maxHeight: 32 }}
          >
            Go
          </Button>
        </div>
      </Box>
    </Paper>
  )
}

export default MessageCard
