import React from 'react'
import { SelectProps } from 'mui-rff'
import { serviceRequestTypeDescription } from 'views/private/serviceRequests/util/enumDescription'
import { DictionarySelect } from '../dictionary-select/DictionarySelect'
import { ServiceRequestType } from '../../../../graphql/hooks.generated'

type ServiceRequestTypeSelectProps = SelectProps

const options = [
  ServiceRequestType.Evaluation,
  ServiceRequestType.RecordReview,
  ServiceRequestType.Screening,
  ServiceRequestType.Therapy
]

export const ServiceRequestTypeSelect = (props: ServiceRequestTypeSelectProps) => {
  return (
    <DictionarySelect
      optionsDictionary={serviceRequestTypeDescription}
      options={options}
      {...props}
    />
  )
}
