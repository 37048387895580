import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { ResourceView } from './detail/ResourceView'
import { ResourcesView } from './ResourcesView'

export const ResourcesRootView = () => {
  const { url } = useRouteMatch()

  return (
    <DndProvider backend={HTML5Backend}>
      <Switch>
        <Route path={`${url}/:id`} component={ResourceView} />
        <Route component={ResourcesView} />
      </Switch>
    </DndProvider>
  )
}
