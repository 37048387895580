import React from 'react'
import { Link as MLink, LinkProps as MLinkProps } from '@material-ui/core'
import { Link as ReactRouterLink } from 'react-router-dom'
import useStyles from './Styles'

interface RouterLinkProps extends MLinkProps {
  href: string
}

export const RouterLink = (props: RouterLinkProps) => {
  const { href, ...rest } = props
  const classes = useStyles()

  return <MLink to={href} className={classes.root} component={ReactRouterLink} {...rest} />
}
