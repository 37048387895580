import React, { useEffect, useState } from 'react'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Search,
  Inject,
  ToolbarItems,
  Page,
  Sort,
  SortSettingsModel,
  Filter,
  FilterSettingsModel,
  Grid
} from '@syncfusion/ej2-react-grids'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  useTheme
  // Grid
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { SearchFilterData } from 'views/redux/searchFilterRedux/actions/searchFilterActions'
import { SchoolStatusDescription } from 'views/private/serviceRequests/util/enumDescription'
import Paper from '../../../../components/ui/panel/paper/Paper'
import Button from '../../../../components/ui/panel/button/Button'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { useSchoolsQuery, useDeleteSchoolMutation } from '../../../../graphql/hooks.generated'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'
import { UseParamsType } from '../../../../types'

interface ListSchoolViewParams extends UseParamsType {
  id: string
}

export const SchoolListView: React.FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { id: districtId } = useParams<ListSchoolViewParams>()
  const classes = useStyles()
  const dispatch = useDispatch()
  const sfStoreState: any = useSelector((state) => state)
  let grid: Grid | null
  const { data, loading, refetch } = useSchoolsQuery({
    variables: { district_id: Number(districtId) },
    fetchPolicy: 'network-only'
  })
  const [schoolsData, setData] = useState<any>()
  const [deleteSchool] = useDeleteSchoolMutation()
  const handleClick = () => {
    history.push(`${url}/create`)
  }
  const onDeleteSchool = async (id) => {
    await deleteSchool({ variables: { id } })
    refetch()
  }
  const isLoading = () => (loading ? <ProgressIndicator fullHeight /> : '')
  useEffect(() => {
    if (data !== undefined && schoolsData === undefined) {
      const active = 'active'

      if (active && active.trim().length > 0) {
        const schoolsData = data?.schools?.filter(
          (school) => school.status.toLocaleLowerCase() === active
        )
        setData(schoolsData)
      }
    }
  }, [data, schoolsData])
  const handleChange = (e) => {
    const keyWord = e.target.value

    if (keyWord && keyWord.trim().length > 0) {
      const schoolsData = data?.schools?.filter(
        (obj) => obj.status.toLocaleLowerCase() === keyWord.toLocaleLowerCase()
      )
      setData(schoolsData)
    }
  }
  const sortSettings: SortSettingsModel = {
    columns: [
      {
        field: sfStoreState?.schoolslistq?.columnName
          ? sfStoreState?.schoolslistq?.columnName
          : 'name',
        direction: sfStoreState?.schoolslistq?.columnDirection
          ? sfStoreState?.schoolslistq?.columnDirection
          : 'Ascending'
      }
      // { field: 'street_address', direction: 'Ascending' },
      // { field: 'district.name', direction: 'Ascending' }
    ]
  }
  const searchOptions = {
    fields: ['name', 'street_address', 'city', 'zip', 'district.name', 'status'],
    key: sfStoreState?.schoolslistq?.searchText,
    ignoreCase: true
  }
  const toolbarOptions: ToolbarItems[] = ['Search']
  const filterOption: FilterSettingsModel = {
    ignoreAccent: true,
    type: 'Excel'
  }
  const pageOptions = {
    pageSizes: [15, 25, 50, 100],
    pageSize: sfStoreState?.schoolslistq?.pageSize ? sfStoreState?.schoolslistq?.pageSize : 15,
    pageCount: 5,
    currentPage: sfStoreState?.schoolslistq?.currentPage
      ? sfStoreState?.schoolslistq?.currentPage
      : 1
  }
  const createdevent = () => {
    document
      .getElementById(`${grid?.element?.id}_searchbutton`)
      ?.addEventListener('click', (event) => {
        const val = (document.getElementById(`${grid?.element?.id}_searchbar`) as HTMLInputElement)
          ?.value
        dispatch(
          SearchFilterData({
            ...sfStoreState,
            schoolslistq: { ...sfStoreState.schoolslistq, searchText: val }
          })
        )
      })
    document
      .getElementById(`${grid?.element?.id}_searchbar`)
      ?.addEventListener('keyup', (event) => {
        if (event?.key === 'Enter') {
          const val = (document.getElementById(
            `${grid?.element?.id}_searchbar`
          ) as HTMLInputElement)?.value
          dispatch(
            SearchFilterData({
              ...sfStoreState,
              schoolslistq: { ...sfStoreState.schoolslistq, searchText: val }
            })
          )
        }
      })
  }
  const gridref = (e) => {
    grid = e
  }
  const handleActionBegin = (args) => {
    if (args?.requestType === 'sorting')
      dispatch(
        SearchFilterData({
          ...sfStoreState,
          schoolslistq: {
            ...sfStoreState.schoolslistq,
            columnName: args?.columnName,
            columnDirection: args?.direction
          }
        })
      )
    if (args && args?.requestType === 'paging')
      dispatch(
        SearchFilterData({
          ...sfStoreState,
          schoolslistq: {
            ...sfStoreState.schoolslistq,
            currentPage: grid?.pageSettings?.currentPage,
            pageSize: grid?.pageSettings?.pageSize
          }
        })
      )
  }

  return (
    <>
      <NavBar title="School Settings" goBackBtn />
      <PageContainer>
        <div className={classes.plr10}>
          <Box py={3} display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Typography variant="h3" style={{ color: theme.palette.info.main }}>
                Schools
              </Typography>
            </Box>
          </Box>
          <div className="control-pane">
            <div className="control-section">
              {data && (
                <GridComponent
                  key={Math.floor(Math.random() * 100)}
                  allowTextWrap
                  dataSource={data?.schools || []}
                  width="auto"
                  toolbar={toolbarOptions}
                  allowPaging
                  pageSettings={pageOptions}
                  allowSorting
                  // filterSettings={filterOption}
                  // allowFiltering
                  sortSettings={sortSettings}
                  searchSettings={searchOptions}
                  className={classes.headerCls}
                  ref={(g) => gridref(g)}
                  created={createdevent}
                  actionComplete={(value) => handleActionBegin(value)}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="name"
                      template={(school) => <span className={classes.nameCls}>{school.name}</span>}
                      headerText="NAME"
                      width="110"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      field="street_address"
                      template={(school) => (
                        <span style={{ color: 'black' }}>
                          {`${school.street_address} ${school.city} ${school.zip}`}
                        </span>
                      )}
                      headerText="LOCATION"
                      width="180"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      field="district.name"
                      template={(school) => (
                        <span style={{ color: 'black' }}>{school.district.name}</span>
                      )}
                      headerText="DISTRICT"
                      width="120"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      field="status"
                      template={(school) => (
                        <span style={{ color: 'black' }}>
                          {SchoolStatusDescription[school.status]}
                        </span>
                      )}
                      headerText="STATUS"
                      width="90"
                      allowFiltering={false}
                      // filter={{ type: 'CheckBox' }}
                    />
                    <ColumnDirective
                      template={(school) => (
                        <Link
                          onClick={() => history.push(`${url}/${school.id}/edit`)}
                          style={{ color: 'black', cursor: 'pointer' }}
                        >
                          {' '}
                          Edit{' '}
                        </Link>
                      )}
                      headerText="ACTIONS"
                      width="68"
                      allowFiltering={false}
                    />
                  </ColumnsDirective>
                  <Inject services={[Filter, Search, Toolbar, Sort, Page]} />
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    className="mar-rt-15 custom-btn addEvent"
                    onClick={handleClick}
                  >
                    Add school
                  </Button>
                </GridComponent>
              )}
            </div>
          </div>
        </div>
        {isLoading()}
      </PageContainer>
    </>
  )
}
