import React, { useState, useEffect } from 'react'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Search,
  Inject,
  ToolbarItems,
  Page,
  Sort,
  SortSettingsModel
} from '@syncfusion/ej2-react-grids'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  useTheme
} from '@material-ui/core'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import Paper from '../../../../components/ui/panel/paper/Paper'
import Button from '../../../../components/ui/panel/button/Button'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { useMyAssignmentsQuery } from '../../../../graphql/hooks.generated'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'

export const MyAssignmentsView: React.FC = () => {
  const theme = useTheme()
  const classes = useStyles()
  const { data, loading, refetch } = useMyAssignmentsQuery()
  const userDetails = useCurrentUser()
  const isLoading = () =>
    loading ? (
      <Box textAlign="center" height="100px" my={3}>
        <CircularProgress />
      </Box>
    ) : (
      ''
    )
  // localStorage.getItem(userId)
  const [assignments, setAssignments] = useState<any>()
  useEffect(() => {
    // console.log(userDetails.data)
    let userId

    if (userDetails.data && userDetails.data)
      // console.log(userDetails.data.currentUser.id)
      userId = userDetails.data.currentUser.id

    // localStorage.setItem('userId', userId)
    let finalarr

    if (data?.myAssignments && data?.myAssignments)
      // if(data?.myAssignments?.)

      finalarr = data?.myAssignments.filter((item) => {
        return item.user?.id === userId
      })

    // console.log(finalarr)
    setAssignments(finalarr)
  }, [data, userDetails?.data])
  const toolbarOptions: ToolbarItems[] = ['Search']
  const searchOptions = {
    fields: ['student.name', 'caseloadType.name'],
    ignoreCase: true
  }
  const sortSettings: SortSettingsModel = {
    columns: [
      { field: 'student.name', direction: 'Ascending' },
      { field: 'caseloadType.name', direction: 'Ascending' }
    ]
  }

  return (
    <>
      <NavBar title="My assignments" goBackBtn />
      <PageContainer>
        <Box
          py={3}
          style={{ paddingBottom: 0 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.assignCls}
        >
          <Box style={{ paddingBottom: 0 }}>
            <Typography variant="h3" style={{ color: theme.palette.info.main }}>
              Assignments
            </Typography>
          </Box>
        </Box>
        <div className="control-pane">
          <div className="control-section">
            {assignments && (
              <GridComponent
                key={Math.floor(Math.random() * 100)}
                allowTextWrap
                dataSource={assignments || []}
                width="auto"
                toolbar={toolbarOptions}
                allowPaging
                pageSettings={{ pageSizes: [15, 25, 50, 100], pageSize: 15, pageCount: 5 }}
                allowSorting
                allowFiltering
                sortSettings={sortSettings}
                searchSettings={searchOptions}
                className={classes.headerCls}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="student.name"
                    template={(names) => <span>{names.student.name}</span>}
                    headerText="CLIENT"
                    width="120"
                  />
                  <ColumnDirective
                    field="caseloadType.name"
                    template={(assign) => <span>{assign.caseloadType.name}</span>}
                    headerText="ASSIGNMENT"
                    width="80"
                  />
                </ColumnsDirective>
                <Inject services={[Search, Toolbar, Sort, Page]} />
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  className="mar-rt-15 custom-btn addEvent"
                  onClick={() => refetch()}
                >
                  Refresh
                </Button>
              </GridComponent>
            )}
          </div>
        </div>
        {isLoading()}
      </PageContainer>
    </>
  )
}
