import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Snackbar from '@material-ui/core/Snackbar'
import { Button, Grid, Radio, RadioGroup } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import { useStyles } from 'config/constants/theme/reports'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import {
  useCreateReportshistoryMutation,
  useCurrentUserQuery
} from '../../../../graphql/hooks.generated'

export default function Groupaudit() {
  const classes = useStyles()
  const initialValues = {
    fromDate: '',
    toDate: '',
    fileType: 'excel',
    userId: '',
    sortby: 'Provider'
  }
  const errorValues = {
    fromDate: '',
    toDate: ''
  }
  const [open, setOpen] = useState(false)
  const [formInput, setFormInput] = useState(initialValues)
  const [formErrors, setFormErrors] = useState(errorValues)
  const [isSubmit, setIsSubmit] = useState(false)
  const [studentsData, { loading }] = useCreateReportshistoryMutation()
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const history = useHistory()
  const handleSubmit = async () => {
    const data = {
      parameters: {
        from_date: formInput.fromDate,
        to_date: formInput.toDate,
        file_type: formInput.fileType,
        user_id: currentUserdata.data?.currentUser.id,
        created_by: currentUserdata.data?.currentUser.id,
        sortBy: formInput.sortby
      }
    }
    await studentsData({ variables: { input: data } })
    setOpen(true)
    setTimeout(() => {
      history.push('/reports')
    }, 2000)
  }
  const handleClick = () => {
    setIsSubmit(true)

    if (!formInput.fromDate) errorValues.fromDate = 'Required'
    if (!formInput.toDate) errorValues.toDate = 'Required'
    if (errorValues.fromDate || errorValues.toDate) {
      setFormErrors(errorValues)

      return
    }

    handleSubmit()
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormInput({ ...formInput, [name]: value })
    setFormErrors({ ...formErrors, [name]: '' })
  }

  return (
    <div>
      <NavBar title="Provider Group Timesheet Audit" goBackBtn />
      <br />
      <form>
        {loading && <ProgressIndicator fullHeight />}
        <Grid container spacing={0} direction="column" alignItems="center" justify="center">
          <Grid item sm={6}>
            <Grid item sm={12}>
              <div
                className={`${isSubmit && formErrors.fromDate ? classes.errortext : ''} ${
                  classes.labeltext
                }`}
              >
                From Date*
              </div>
              <TextField
                id="date"
                name="fromDate"
                type="date"
                onChange={handleChange}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <br />
            <Grid item sm={12}>
              <div
                className={`${isSubmit && formErrors.toDate ? classes.errortext : ''} ${
                  classes.labeltext
                }`}
              >
                To Date*
              </div>
              <TextField
                id="date"
                name="toDate"
                type="date"
                onChange={handleChange}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <br />
            <Grid item sm={12}>
              <div className={classes.labeltext}>File Type</div>
              <select onChange={handleChange} className={classes.fileSelect}>
                {/* <option>pdf</option> */}
                <option>excel</option>
              </select>
            </Grid>
            <br />
            <Grid item sm={12}>
              <span className={classes.pl10}>
                <b>Sort by: </b>
              </span>
              &nbsp;
              <span> Provider</span>
              <Radio
                checked={formInput.sortby === 'Provider'}
                onChange={handleChange}
                value="Provider"
                name="sortby"
                inputProps={{ 'aria-label': 'Provider' }}
              />
              <span>District</span>
              <Radio
                checked={formInput.sortby === 'District'}
                onChange={handleChange}
                value="District"
                name="sortby"
                inputProps={{ 'aria-label': 'District' }}
              />
            </Grid>
            <Grid container>
              <Grid item sm={12} className={classes.textcenter}>
                <Button
                  onClick={handleClick}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Build Report
                </Button>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Alert onClose={handleClose}>
                  {' '}
                  Report is being generated. Email will be sent to{' '}
                  {currentUserdata.data?.currentUser.email} with a link when it is finished.
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}
