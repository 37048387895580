import { Paper, withStyles, createStyles } from '@material-ui/core'

export default withStyles(
  createStyles(({ palette }) => ({
    root: {
      boxShadow: 'none',
      backgroundColor: palette.common.white
    }
  }))
)(Paper)
