import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import AppBar from 'components/ui/panel/app-bar/AppBar'
import Footer from 'components/ui/panel/footer/Footer'
import Footer2 from 'components/ui/panel/footer/Footer2'
import { makeStyles, Theme } from '@material-ui/core'
import { Register } from 'components/ui/auth/register/Register'
import { AboutYou } from 'components/ui/auth/register/aboutyou'
import { WaitListForm } from 'components/ui/auth/register/waitlistForm'
import { Confirm } from 'components/ui/auth/register/confirm'
import AppStateProvider from 'views/private/newSessions/view/video/state'
import { ClientFillingForm } from 'views/private/clientFilling/clientfilling'
import { ClientDisclosureFormMessage } from 'views/private/disclosureform/clientDisclosureMessage'
import { ClientDisclosureFormDownload } from 'views/private/disclosureform/clientDisclosureDownload'
import { ContactUsForm } from 'views/private/clientFilling/contactusbutton'
import { ClientDisclosureFormRedirectMessage } from 'views/private/disclosureform/clientDisclosureCompleteMessage'
import { SurveyForm } from 'views/private/newSessions/view/survey'
import { HomeView } from './home/home'
import { ParentView } from './parent/parentsurveyform'
import { YouthView } from './youth/youthsurveyform'
import { VideoView } from './video/video'
import { EncouragingView } from './encourage/encouragement'
import Artboard from '../../assets/icons/artboard.svg'
import { ActivateAccountView } from './invite/ActivateAccountView'
// import { DisclosureForm } from '../private/disclosureform/disclosureform'
import { StudentDisclosureForm } from './studentDisclosure/studentDisclosure'

const useStyle = makeStyles(({ palette }: Theme) => ({
  root: {
    backgroundImage: `url(${Artboard})`,
    backgroundColor: palette.background.default,
    backgroundBlendMode: 'multiply',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  bodyRoot: {
    width: '100%',
    display: 'inline-block',
    margin: '0px auto 0px',
    minHeight: 'calc(100vh - 60px - 65px)',
    fontFamily: 'Brandon Grotesque !important'
  }
}))

export const RootPublicView = () => {
  const classes = useStyle()
  const { url } = useRouteMatch()

  return (
    <>
      <AppBar />
      <div className={`${classes.root} ${classes.bodyRoot}`}>
        <AppStateProvider>
          <Switch>
            <Route path={`${url}/activateAccount/:token`} component={ActivateAccountView} />
            <Route path={`${url}/survey/youth/:loc`} component={YouthView} />
            <Route path={`${url}/survey/parent/:loc`} component={ParentView} />
            <Route path={`${url}/survey/encourage/:loc`} component={EncouragingView} />
            <Route path={`${url}/register/about-you/:loc/confirm`} component={Confirm} />
            <Route path={`${url}/register/about-you/:loc/waitlistform`} component={WaitListForm} />
            <Route path={`${url}/register/about-you/:loc`} component={AboutYou} />
            <Route path={`${url}/register/`} component={Register} />
            {/* <Route path={`${url}/survey`} component={HomeView} /> */}
            <Route path={`${url}/video/:token/:loc`} component={VideoView} />
            <Route path={`${url}/video/:token`} component={VideoView} />
            <Route path={`${url}/room/:URLRoomName`} component={VideoView} />
            <Route
              path={`${url}/studentDisclosure/:token/:loc/:aptClient?`}
              component={StudentDisclosureForm}
            />
            <Route
              path={`${url}/clientMessage/:token/:language_id`}
              component={ClientFillingForm}
            />
            <Route path={`${url}/contactus/:language_id`} component={ContactUsForm} />
            <Route
              path={`${url}/studentDisclosureDownload/:token/:language_id`}
              component={ClientDisclosureFormDownload}
            />
            <Route
              path={`${url}/ClientDisclosureFormRedirectMessage/:language_id`}
              component={ClientDisclosureFormRedirectMessage}
            />
            <Route
              path={`${url}/clientDisclosureFormMessage/:language_id`}
              component={ClientDisclosureFormMessage}
            />
            <Route path={`${url}/surveyForm/:token/:language_id`} component={SurveyForm} />
            <Route exact path="/public" render={() => <Redirect to="" />} />
          </Switch>
        </AppStateProvider>
      </div>
      {window.location.pathname.includes('public/surveyForm') ? <Footer2 /> : <Footer ispublic />}
    </>
  )
}
