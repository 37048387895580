import { Grid, MenuItem } from '@material-ui/core'
import { makeValidate, Select, TextField, Checkboxes } from 'mui-rff'
import React from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { DistrictLevelOfServiceSelect } from '../../../../components/ui/form/district-level-of-service-select/DistrictLevelOfServiceSelect'
import { PlanTypeSelect } from '../../../../components/ui/form/plan-type-select/PlanTypeSelect'
import { DistrictSignatureRequiredSelect } from '../../../../components/ui/form/district-signature-required-select/DistrictSignatureRequiredSelect'
import Button from '../../../../components/ui/panel/button/Button'
import { InfoButton } from '../../../../components/ui/panel/button/InfoButton'
import { Section } from '../../../../components/ui/panel/section/Section'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'
import {
  DistrictLevelOfService,
  useStatesQuery,
  PlanType,
  DistrictSignatureRequired
} from '../../../../graphql/hooks.generated'

export interface DistrictsFormModel {
  city: string
  levelOfService: DistrictLevelOfService
  name: string
  offerBillingQuestion?: boolean | null
  billTrackingIdEnabled?: boolean | null
  allowConferencing?: boolean | null
  therapyComponentEnabled?: boolean | null
  enableProviderPins?: boolean | null
  planTypes: PlanType[]
  signatureRequired: DistrictSignatureRequired
  streetAddress: string
  stateId: number
  zip: string
}

type DistrictFormProps = {
  onSubmit(values: DistrictsFormModel): void
  submitLabel: string
  initialValues?: Partial<DistrictsFormModel> | null
}
const validator = makeValidate(
  yup.object<DistrictsFormModel>({
    city: yup.string().label('City').required(),
    levelOfService: yup.mixed<DistrictLevelOfService>().label('Level of service').required(),
    name: yup.string().label('District name').required(),
    planTypes: yup.array<PlanType>().label('Plan Type').required(),
    signatureRequired: yup
      .mixed<DistrictSignatureRequired>()
      .label('Signature Required')
      .required(),
    streetAddress: yup.string().label('Street Address').required(),
    stateId: yup.number().label('State').required(),
    zip: yup.string().label('Zip').required()
  })
)

export const DistrictForm = (props: DistrictFormProps) => {
  const { data } = useStatesQuery()
  const classes = useStyles()
  const history = useHistory()
  const { initialValues } = props
  const onSubmit = (values: DistrictsFormModel) => props.onSubmit(values)
  const renderForm = (renderProps: FormRenderProps<DistrictsFormModel>) => {
    const { handleSubmit } = renderProps

    return (
      <form>
        <Grid container spacing={4} style={{ maxWidth: '800px' }}>
          <Grid item sm={12}>
            <Section title="Name">
              <TextField
                required
                fullWidth
                variant="filled"
                name="name"
                label="District name"
                className={classes.labelField}
              />
            </Section>
          </Grid>
          <Grid item sm={12}>
            <Section title="Level of service">
              <DistrictLevelOfServiceSelect
                required
                name="levelOfService"
                label="Level of service"
              />
            </Section>
          </Grid>
          <Grid item sm={12}>
            <Section title="Location">
              <Grid container spacing={3}>
                <Grid item sm={6}>
                  <TextField
                    required
                    variant="filled"
                    name="streetAddress"
                    label="Street Address"
                    className={classes.labelField}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    required
                    variant="filled"
                    name="city"
                    label="City"
                    className={classes.labelField}
                  />
                </Grid>
                <Grid item sm={6} className={classes.stateCls}>
                  {data?.states?.length ? (
                    <Select
                      name="stateId"
                      label="State"
                      required
                      variant="filled"
                      className={classes.selectDropdownState}
                    >
                      {data?.states?.map((state) => (
                        <MenuItem key={state.id} value={state.id}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    required
                    variant="filled"
                    name="zip"
                    label="Zip"
                    className={classes.labelFieldOne}
                  />
                </Grid>
              </Grid>
            </Section>
          </Grid>
          <Grid item sm={12}>
            <Section title="Plan">
              <Grid container spacing={3}>
                <Grid item sm={6}>
                  <DistrictSignatureRequiredSelect
                    required
                    name="signatureRequired"
                    label="Signature Required"
                  />
                </Grid>
                <Grid item sm={6}>
                  <PlanTypeSelect required label="Plan Type" name="planTypes" multiple />
                </Grid>
              </Grid>
            </Section>
          </Grid>

          <Grid item sm={12} style={{ paddingTop: 35, marginLeft: 6 }}>
            <InfoButton
              size="large"
              variant="contained"
              onClick={handleSubmit}
              style={{ marginRight: 12 }}
            >
              {props.submitLabel}
            </InfoButton>
            <Button size="large" variant="outlined" color="primary" onClick={history.goBack}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <Form<DistrictsFormModel>
      initialValues={initialValues ?? {}}
      validate={validator}
      render={renderForm}
      onSubmit={onSubmit}
    />
  )
}
