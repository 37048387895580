import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    overrides: {
      backgroundColor: 'white',
      color: 'black'
    },
    tabbackground: {
      backgroundColor: 'white',
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
    },
    tabRoot: {
      color: '#0D1F48',
      fontWeight: 600,
      textTransform: 'capitalize',
      fontSize: 16,
      fontFamily: 'Brandon Grotesque'
    },
    indicator: {
      backgroundColor: '#F1583A'
    },
    selected: {
      color: '#F1583A'
    },
    textField: {
      width: 200
    },
    textareaField: {
      width: 200,
      height: 70
    },
    pt15: {
      paddingTop: '15px'
    },
    pb15: {
      paddingBottom: '15px'
    },
    center: {
      textAlign: 'center'
    },
    pb3: {
      paddingBottom: '3px'
    },
    brandonFF: {
      fontFamily: 'Brandon Grotesque',
      fontSize: '15px'
    },
    input: {
      '&:focus-visible': {
        outline: 'none !important'
      }
    },
    errorColor: {
      color: theme.palette.error.dark
    },
    ml20: {
      marginLeft: '20px'
    },
    textLinkClr: {
      color: '#4b99d1'
    },
    submitBtn: {
      backgroundColor: 'rgb(75 153 209)',
      textTransform: 'capitalize',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: 'rgb(75 153 209)'
      }
    }
  })
)
