import NavBar from 'components/ui/panel/nav-bar/NavBar'
import { PageContainer } from 'components/ui/panel/page-container/PageContainer'
import { useCreateUserMutation } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory } from 'react-router-dom'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { useErrorHandler } from 'graphql/hooks/useErrorHandler/useErrorHandler'
import { format } from 'date-fns'
import { UserForm } from '../form/UserForm'
import { UserFormModel } from '../form/UserFormModel'

export const CreateUserView = () => {
  const [createUser, { loading }] = useCreateUserMutation()
  const handleError = useErrorHandler()
  const history = useHistory()
  const handleFormSubmit = (values: UserFormModel) =>
    createUser({
      variables: {
        input: {
          ...values,
          // telemedicineTrainedAt:
          //   values.telemedicineTrainedAt && values.telemedicineTrainedAt.toISOString(),
          certDate: values.certDate && format(values.certDate, 'yyyy-MM-dd'),
          certExpireDate: values.certExpireDate && format(values.certExpireDate, 'yyyy-MM-dd')
        }
      }
    })
      .then(() => {
        history.push('/')
      })
      .catch(handleError)

  return (
    <>
      {loading && <ProgressIndicator fullHeight />}
      <NavBar title="Add a user" goBackBtn />
      <PageContainer>
        <UserForm onSubmit={handleFormSubmit} submitLabel="Send Invite" />
      </PageContainer>
    </>
  )
}
