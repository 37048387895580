import React, { useMemo, useEffect, useState } from 'react'
import { MenuItem } from '@material-ui/core'
import { Select } from 'mui-rff'
import { useAgesServedSelectLazyQuery } from '../../../../graphql/hooks.generated'
import { useStyles } from '../Styles'

interface MaxAgeSelectProps {
  label: string
  name: string
  required?: boolean
  // values: any
  // handleChange: (input: string) => any
}

export const MaxAgeSelect = (props: MaxAgeSelectProps) => {
  const active: any = 'ACTIVE'
  const classes = useStyles()
  // const { label, name, handleChange } = props
  const { label, name } = props
  // const [ageState, setAgeState] = useState
  const [executeQuery, { data }] = useAgesServedSelectLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetch = useMemo(() => () => executeQuery(), [executeQuery])
  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <>
      {data?.agesServed && data?.agesServed?.length > 0 ? (
        <Select
          variant="filled"
          name={name}
          placeholder={name}
          label={label}
          // defaultValue={ageState}
          className={classes.selectDropdown}
          required
        >
          {data?.agesServed.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select {...props} variant="filled">
          <MenuItem>No Data Found</MenuItem>
        </Select>
      )}
    </>
  )
}
