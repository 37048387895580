import * as React from 'react'
import Joyride, { EVENTS, CallBackProps, Styles, Step } from 'react-joyride'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { tourRunStatusVar } from 'graphql/vars'
import { palette } from '../../../config/constants/theme/palette'
import { Role } from '../../../graphql/hooks.generated'
import steps from './steps'

interface IProps {
  steps: Role | Array<Step>
}

// From Theme.MuiButton
const buttonStyles = {
  borderRadius: '30px',
  padding: '8px 25px',
  lineHeight: '16px',
  fontWeight: 600,
  fontSize: 12
}
const Tour = (props: IProps) => {
  const { steps: propsSteps } = props
  const styles: Styles = {
    options: { primaryColor: palette.primary.main },
    tooltip: {
      width: 'inherit',
      height: 'inherit'
    },
    buttonNext: {
      ...buttonStyles
    },
    buttonBack: {
      ...buttonStyles
    },
    buttonSkip: {
      ...buttonStyles
    }
  }
  const callback = ({ type }: CallBackProps) => {
    if (type === EVENTS.TOUR_END) {
      localStorage.setItem('tourAlreadyRun', 'true')
      tourRunStatusVar(false)
    }
  }
  const GET_TOUR_STATUS = gql`
    query GetTourRunStatus {
      tourRunStatus @client
    }
  `
  const { data } = useQuery(GET_TOUR_STATUS)
  const locale = { last: 'Done' }

  return (
    <Joyride
      continuous
      showSkipButton
      locale={locale}
      styles={styles}
      run={data.tourRunStatus}
      scrollOffset={100}
      callback={callback}
      steps={propsSteps instanceof Array ? propsSteps : steps[propsSteps]}
    />
  )
}

export default Tour
