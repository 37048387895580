import React from 'react'
import { PageContainer } from '../../components/ui/panel/page-container/PageContainer'

export const FaqView: React.FC = () => {
  return (
    <PageContainer>
      <h4>FAQ</h4>
    </PageContainer>
  )
}
