import React, { useEffect, useMemo, useState } from 'react'
import { FormControl, Grid, MenuItem, Select } from '@material-ui/core'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/dist/echarts.esm'
import TableContainer from '@material-ui/core/TableContainer'
import {
  useCurrentUserQuery,
  useAdminDocumentedSessionLazyQuery,
  useSessionDeliveryLazyQuery,
  useAppointmentStatusLazyQuery,
  useWaitlistAssignmentsQuery,
  useOngoingcareAssignmentsQuery,
  useMandatoryDiscluserDelQuery
} from 'graphql/hooks.generated'
import moment from 'moment'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import useStyles from '../Styles'

export const AdminChart = () => {
  const classes = useStyles()
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const [chartsdate, setchartSdate] = useState(
    moment.tz(new Date(), 'US/Mountain').format('YYYY-MM-DD')
  )
  const [chartedate, setchartEdate] = useState(
    moment.tz(new Date(), 'US/Mountain').format('YYYY-MM-DD')
  )
  const [chartfilterval, setchartfilterval] = useState('Today')
  const { data: ongcdata, loading: ongcloading } = useOngoingcareAssignmentsQuery({
    fetchPolicy: 'network-only'
  })
  const { data: wldata, loading: wlloading } = useWaitlistAssignmentsQuery({
    fetchPolicy: 'network-only'
  })
  const { data: mddata, loading: mdloading } = useMandatoryDiscluserDelQuery({
    fetchPolicy: 'network-only'
  })
  const [
    executeDocSessionQuery,
    { data: docdata, loading: docloading }
  ] = useAdminDocumentedSessionLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetchDocSessionQuery = useMemo(
    () => ({ from_date, to_date }) =>
      executeDocSessionQuery({
        variables: { from_date, to_date }
      }),
    [executeDocSessionQuery]
  )
  const [
    executeApptStatusQuery,
    { data: apptstdata, loading: apptstloading }
  ] = useAppointmentStatusLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetchAppointStatusQuery = useMemo(
    () => ({ from_date, to_date }) =>
      executeApptStatusQuery({
        variables: { from_date, to_date }
      }),
    [executeApptStatusQuery]
  )
  const [
    executeSessionDelQuery,
    { data: sesdeldata, loading: sdloading }
  ] = useSessionDeliveryLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetchSessionDelQuery = useMemo(
    () => ({ from_date, to_date }) =>
      executeSessionDelQuery({
        variables: { from_date, to_date }
      }),
    [executeSessionDelQuery]
  )
  useEffect(() => {
    fetchDocSessionQuery({ from_date: chartsdate, to_date: chartedate })
    fetchSessionDelQuery({ from_date: chartsdate, to_date: chartedate })
    fetchAppointStatusQuery({ from_date: chartsdate, to_date: chartedate })
  }, [fetchDocSessionQuery, fetchSessionDelQuery, fetchAppointStatusQuery, chartsdate, chartedate])
  const getoptions = (title, data) => {
    const dataset: any[] = []
    data.map((item) => {
      if (
        item.name === 'Incomplete' ||
        item.name === 'In-Person' ||
        item.name === 'Unlaunched' ||
        item.name === 'Unassigned'
      )
        dataset.push({
          name: item.name || '',
          value: item?.value || 0,
          itemStyle: { color: '#D0779D' }
        })
      if (
        item.name === 'Complete' ||
        item.name === 'Telehealth' ||
        item.name === 'Launched' ||
        item.name === 'Assigned'
      )
        dataset.push({
          name: item.name || '',
          value: item?.value || 0,
          itemStyle: { color: '#FFD765' }
        })

      return true
    })
    const option = {
      title: {
        text: title,
        left: 10,
        top: 15,
        bottom: 30,
        textStyle: {
          fontSize: '14px'
        }
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      series: [
        {
          name: title,
          type: 'pie',
          radius: ['30%', '70%'],
          avoidLabelOverlap: false,
          width: 'auto',
          height: 'auto',
          label: {
            alignTo: 'edge',
            formatter: '{name|{b}}{value|({c})} \n {per|{d} %}',
            edgeDistance: 10,
            lineHeight: 25,
            rich: {
              name: {
                fontSize: 12,
                color: '#000',
                fontWeight: 'bold'
              },
              value: {
                fontSize: 13,
                color: '#000',
                fontWeight: 'bold'
              },
              per: {
                color: '#000'
              }
            }
          },
          data: dataset
        }
      ]
    }

    return option
  }
  const changePeriod = (e) => {
    setchartfilterval(e.target.value)

    if (e.target.value === 'Today')
      setchartSdate(moment.tz(new Date(), 'US/Mountain').format('YYYY-MM-DD'))
    if (e.target.value === 'L7days')
      setchartSdate(moment.tz(new Date(), 'US/Mountain').subtract(7, 'days').format('YYYY-MM-DD'))
    if (e.target.value === 'L30days')
      setchartSdate(moment.tz(new Date(), 'US/Mountain').subtract(30, 'days').format('YYYY-MM-DD'))
  }

  return (
    <div id="main">
      <Grid container>
        <Grid item md={12}>
          <div
            className={classes.selectCls}
            style={{ padding: '10px', width: '250px', float: 'right' }}
          >
            <FormControl size="medium" fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="outlined"
                defaultValue={chartfilterval}
                value={chartfilterval}
                className={classes.tdd}
                onChange={(e) => changePeriod(e)}
              >
                <MenuItem value="Today">Today</MenuItem>
                <MenuItem value="L7days">Last 7 days</MenuItem>
                <MenuItem value="L30days">Last 30 days</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Grid>
      </Grid>
      {/* {docdata && sesdeldata && apptstdata && ongcdata && wldata && mddata && ( */}
      {(docloading || sdloading || apptstloading || ongcloading || wlloading || mdloading) && (
        <ProgressIndicator position="relative" />
      )}
      <Grid container>
        <Grid item md={4}>
          {docdata && (
            <ReactEChartsCore
              style={{ backgroundColor: '#FFF', margin: '10px 5px 10px 10px' }}
              echarts={echarts}
              option={getoptions('Documentation Status', docdata?.DocumentedSession)}
              notMerge
              lazyUpdate
              theme="theme_name"
              opts={{}}
            />
          )}
        </Grid>
        <Grid item md={4}>
          {sesdeldata && (
            <ReactEChartsCore
              style={{ backgroundColor: '#FFF', margin: '10px 5px 10px 5px' }}
              echarts={echarts}
              option={getoptions('Session Delivery', sesdeldata?.ServiceSession)}
              notMerge
              lazyUpdate
              theme="theme_name"
              opts={{}}
            />
          )}
        </Grid>
        <Grid item md={4}>
          {apptstdata && (
            <ReactEChartsCore
              style={{ backgroundColor: '#FFF', margin: '10px 10px 10px 5px' }}
              echarts={echarts}
              option={getoptions('Appointment Status', apptstdata?.Sessionstatus)}
              notMerge
              lazyUpdate
              theme="theme_name"
              opts={{}}
            />
          )}
        </Grid>
        {!apptstdata && <Grid item md={12} style={{ height: '200px' }} />}
        <Grid item md={4}>
          {ongcdata && (
            <ReactEChartsCore
              style={{ backgroundColor: '#FFF', margin: '0px 5px 10px 10px' }}
              echarts={echarts}
              option={getoptions('Ongoingcare Assignments', ongcdata?.ongoingcarecount)}
              notMerge
              lazyUpdate
              theme="theme_name"
              opts={{}}
            />
          )}
        </Grid>
        <Grid item md={4}>
          {wldata && (
            <ReactEChartsCore
              style={{ backgroundColor: '#FFF', margin: '0px 5px 10px 5px' }}
              echarts={echarts}
              option={getoptions('Waitlist Assignments', wldata?.wailistassignment)}
              notMerge
              lazyUpdate
              theme="theme_name"
              opts={{}}
            />
          )}
        </Grid>
        <Grid item md={4}>
          {mddata && (
            <ReactEChartsCore
              style={{ backgroundColor: '#FFF', margin: '0px 10px 10px 5px' }}
              echarts={echarts}
              option={getoptions('Mandatory Disclosures', mddata?.Mandatorystatus)}
              notMerge
              lazyUpdate
              theme="theme_name"
              opts={{}}
            />
          )}
        </Grid>
      </Grid>
      {/* )} */}
      {/* <div className={classes.requestProgress}> */}
      {/* </div> */}
      {/* </TableContainer> */}
    </div>
  )
}
