import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { FaqView } from 'views/faq/FaqView'
import { makeStyles, Theme } from '@material-ui/core'
import { Provider } from 'react-redux'
import AutoLogout from '../../components/ui/auth/auto-logout/AutoLogout'
import AppBar from '../../components/ui/panel/app-bar/AppBar'
import Footer from '../../components/ui/panel/footer/Footer'
import { PrivateRoute } from '../../components/router/PrivateRoute'
import ProgressIndicator from '../../components/ui/panel/progress-indicator/ProgressIndicator'
import { useAuth } from '../../components/providers/auth'
import { Role, UserDocument } from '../../graphql/hooks.generated'
import { DashboardView } from '../dashboard/DashboardView'
import { ResourcesRootView } from '../resources/ResourcesRootView'
import { MessagesRootView } from '../messages/MessagesRootView'
import { ServiceRequestsRootView } from './serviceRequests/ServiceRequestsRootView'
import { UsersRootView } from './users/UsersRootView'
import { DistrictsRootView } from './districts/DistrictsRootView'
import { ProviderAssignmentsRootView } from './providerAssignments/ProviderAssignmentsRootView'
import { AuthenticationContext } from '../../types'
import { UserGate } from '../UserGate'
import { StatesRootView } from './states/StatesRootView'
import { ServicesRootView } from './services/ServicesRootView'
import { IcdCodesRootView } from './icdCodes/IcdCodesRootView'
import { TherapistsRootView } from './therapist/TherapistsRootView'
import { StudentsRootView } from './students/StudentsRootView'
import { ReportsRootView } from './reports/ReportsRootView'
import { ScheduleRootView } from './schedule/scheduleRootView'
import { caseloadRootView } from './caseload/caseloadRootView'
import { StudentsinfoRootView } from './studentsinfo/studentsinfoRootView'
import { BlackoutRootView } from './blackoutdays/blackoutRootView'
import Documentation from './studentsinfo/components/studentsinfoView/documentation/viewpage'
import { TherapistInviteUpdateView } from './therapist/details/TherapistInviteUpdate'
import { TherapistDetailView } from './therapist/details/TherapistDetailView'
import { Trainingdocument } from './therapist/details/Trainingdocument'
import { AgencyRootView } from './agency/AgencyRootView'
import { DisclosureForm } from './disclosureform/disclosureform'
import { OngoingRootView } from './ongoing care/OngoingRootView'
import { WaitlistRootView } from './waitlist/WaitlistRootView'
import { MandatoryPdf } from './disclosureform/mandatoryPdf'
import sfstore from '../redux/searchFilterRedux/store/searchFilterStore'
import { SessionReportRootView } from './sessionreport/SessionReportsRootView'
import { ProviderManulaDoc } from './therapist/details/providerManualDocs'
import { ProviderGuideDoc } from './therapist/details/providerGuideDoc'
import { SurveyForm } from './newSessions/view/survey'

const useStyle = makeStyles(({ palette }: Theme) => ({
  bodyRoot: {
    width: '100%',
    display: 'inline-block',
    margin: '0px auto 0px',
    minHeight: 'calc(100vh - 60px - 75px)',
    fontFamily: 'Brandon Grotesque'
  }
}))

export const RootPrivateView = () => {
  const classes = useStyle()
  const { isAuthenticated }: AuthenticationContext = useAuth()

  if (!isAuthenticated) return <ProgressIndicator fullHeight />

  return (
    <UserGate>
      <AutoLogout />
      <AppBar />
      <div className={`${classes.bodyRoot}`}>
        <Provider store={sfstore}>
          <Switch>
            <Route path="/resources" component={ResourcesRootView} />
            <Route path="/messages" component={MessagesRootView} />
            <PrivateRoute
              roles={[Role.TeleteachersAdmin, Role.SchoolAdmin]}
              path="/districts"
              component={DistrictsRootView}
            />
            <PrivateRoute
              roles={[Role.TeleteachersAdmin]}
              path="/states"
              component={StatesRootView}
            />
            <PrivateRoute
              roles={[Role.TeleteachersAdmin]}
              path="/services"
              component={ServicesRootView}
            />
            <PrivateRoute
              roles={[Role.TeleteachersAdmin]}
              path="/icdCodes"
              component={IcdCodesRootView}
            />
            <PrivateRoute
              roles={[
                Role.TeleteachersAdmin,
                Role.SchoolAdmin,
                Role.Therapist,
                Role.SchoolTherapist,
                Role.AgencySupervisor,
                Role.AgencySupervisorProvider
              ]}
              path="/reports"
              component={ReportsRootView}
            />
            <PrivateRoute
              roles={[Role.TeleteachersAdmin, Role.SchoolAdmin]}
              path="/users"
              component={UsersRootView}
            />
            <PrivateRoute
              roles={[Role.TeleteachersAdmin, Role.SchoolAdmin]}
              path="/students"
              component={StudentsRootView}
            />
            <PrivateRoute
              roles={[Role.TeleteachersAdmin, Role.SchoolAdmin]}
              path="/serviceRequests"
              component={ServiceRequestsRootView}
            />
            <PrivateRoute
              roles={[Role.TeleteachersAdmin, Role.SchoolAdmin]}
              path="/therapists"
              component={TherapistsRootView}
            />
            <PrivateRoute
              roles={[
                Role.TeleteachersAdmin,
                Role.Therapist,
                Role.SchoolTherapist,
                Role.SchoolAdmin
              ]}
              path="/providerAssignments"
              component={ProviderAssignmentsRootView}
            />
            <PrivateRoute
              roles={[
                Role.TeleteachersAdmin,
                Role.Therapist,
                Role.SchoolTherapist,
                Role.SchoolAdmin,
                Role.AgencySupervisorProvider,
                Role.AgencySupervisor
              ]}
              path="/calendar"
              component={ScheduleRootView}
            />
            <PrivateRoute
              roles={[
                Role.TeleteachersAdmin,
                Role.Therapist,
                Role.SchoolTherapist,
                Role.SchoolAdmin,
                Role.AgencySupervisorProvider,
                Role.AgencySupervisor
              ]}
              path="/caseload"
              component={caseloadRootView}
            />
            <PrivateRoute
              roles={[
                Role.TeleteachersAdmin,
                Role.Therapist,
                Role.SchoolTherapist,
                Role.SchoolAdmin,
                Role.AgencySupervisorProvider,
                Role.AgencySupervisor
              ]}
              path="/studentsinfo/:stu_id"
              component={StudentsinfoRootView}
            />
            <PrivateRoute
              roles={[Role.TeleteachersAdmin, Role.SchoolAdmin]}
              path="/blackoutdays"
              component={BlackoutRootView}
            />
            <PrivateRoute
              roles={[
                Role.SchoolAdmin,
                Role.SchoolTherapist,
                Role.Therapist,
                Role.AgencySupervisorProvider,
                Role.AgencySupervisor
              ]}
              path="/documentation"
              component={Documentation}
            />
            <PrivateRoute
              roles={[
                Role.SchoolAdmin,
                Role.SchoolTherapist,
                Role.Therapist,
                Role.TeleteachersAdmin
              ]}
              path="/TherapistInviteView/:userid"
              component={TherapistDetailView}
            />
            <PrivateRoute
              roles={[
                Role.SchoolAdmin,
                Role.SchoolTherapist,
                Role.Therapist,
                Role.TeleteachersAdmin
              ]}
              path="/TherapistInviteView"
              component={TherapistDetailView}
            />
            <PrivateRoute
              roles={[Role.SchoolTherapist, Role.Therapist]}
              path="/Trainingdocument"
              component={Trainingdocument}
            />
            <PrivateRoute
              roles={[
                Role.SchoolAdmin,
                Role.SchoolTherapist,
                Role.Therapist,
                Role.TeleteachersAdmin
              ]}
              path="/TherapistInviteUpdate/:userid"
              component={TherapistInviteUpdateView}
            />
            <PrivateRoute
              roles={[
                Role.SchoolAdmin,
                Role.SchoolTherapist,
                Role.Therapist,
                Role.TeleteachersAdmin,
                Role.AgencySupervisorProvider,
                Role.AgencySupervisor
              ]}
              path="/TherapistInviteUpdate"
              component={TherapistInviteUpdateView}
            />
            <PrivateRoute
              roles={[
                Role.SchoolAdmin,
                Role.SchoolTherapist,
                Role.Therapist,
                Role.TeleteachersAdmin
              ]}
              path="/MandatoryFormPdf"
              component={MandatoryPdf}
            />
            <PrivateRoute
              roles={[Role.TeleteachersAdmin, Role.SchoolAdmin]}
              path="/agency"
              component={AgencyRootView}
            />
            <PrivateRoute
              roles={[Role.TeleteachersAdmin, Role.SchoolAdmin]}
              path="/ongoing care"
              component={OngoingRootView}
            />
            <PrivateRoute
              roles={[Role.TeleteachersAdmin, Role.SchoolAdmin]}
              path="/waitlist"
              component={WaitlistRootView}
            />
            <PrivateRoute
              roles={[Role.TeleteachersAdmin, Role.SchoolAdmin]}
              path="/sessionreport"
              component={SessionReportRootView}
            />
            <PrivateRoute
              roles={[
                Role.SchoolTherapist,
                Role.Therapist,
                Role.TeleteachersAdmin,
                Role.SchoolAdmin
              ]}
              path="/ProviderManualDoc"
              component={ProviderManulaDoc}
            />
            <PrivateRoute
              roles={[
                Role.SchoolTherapist,
                Role.Therapist,
                Role.TeleteachersAdmin,
                Role.SchoolAdmin
              ]}
              path="/ProviderGuideDoc"
              component={ProviderGuideDoc}
            />
            {/* <PrivateRoute
                roles={[
                  Role.SchoolAdmin,
                  Role.SchoolTherapist,
                  Role.Therapist,
                  Role.TeleteachersAdmin
                ]}
                path="/chat"
                component={ChatApplication}
              /> */}
            <Route path="/faq" component={FaqView} />
            <Route path="/disclosureform" component={DisclosureForm} />
            <Route component={DashboardView} />
          </Switch>
        </Provider>
      </div>
      {window.location.pathname.includes('public/surveyForm') ? null : <Footer ispublic />}
    </UserGate>
  )
}
