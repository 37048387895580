import React, { useMemo, useEffect } from 'react'
import { MenuItem } from '@material-ui/core'
import { Select } from 'mui-rff'
import { useEthnicGroupSelectLazyQuery } from '../../../../graphql/hooks.generated'
import { useStyles } from '../Styles'

interface EthnicGroupSelectProps {
  label: string
  name: string
  required?: boolean
}

export const EthnicGroupSelect = (props: EthnicGroupSelectProps) => {
  const active: any = 'ACTIVE'
  const classes = useStyles()
  const { label, name } = props
  const [executeQuery, { data }] = useEthnicGroupSelectLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetch = useMemo(() => () => executeQuery(), [executeQuery])
  useEffect(() => {
    fetch()
  }, [fetch])
  const sortedEthnicGroups = useMemo(() => {
    return data?.ethnicGroup?.slice().sort((a, b) => a.name.localeCompare(b.name)) || []
  }, [data?.ethnicGroup])

  return (
    <>
      {sortedEthnicGroups?.length > 0 ? (
        <Select
          variant="filled"
          name={name}
          placeholder={name}
          label={label}
          className={classes.selectDropdown}
          // multiple
          // placeholder="EthnicGroup"
          // options={data?.ethnicGroup.filter((ethnicGroup) => ethnicGroup.status === active)}
          // getOptionLabel={(option) => option?.name}
          // getOptionValue={(option) => option?.name}
          // isMulti
        >
          {sortedEthnicGroups.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select {...props} variant="filled">
          <MenuItem>No Data Found</MenuItem>
        </Select>
      )}
    </>
  )
}
