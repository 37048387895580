import React, { Component } from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import Caseload from './caseloadView'

export const caseloadRootView = () => {
  // const { path } = useRouteMatch()

  return (
    <Switch>
      <Route component={Caseload} />
    </Switch>
  )
}
