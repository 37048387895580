// create blackout date
import React, { useEffect } from 'react'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import Grid from '@material-ui/core/Grid'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { useCurrentUserQuery, useListBlackoutDayQuery } from 'graphql/hooks.generated'
import NavBar from '../../../components/ui/panel/nav-bar/NavBar'
import { PageContainer } from '../../../components/ui/panel/page-container/PageContainer'
import Button from '../../../components/ui/panel/button/Button'
import {
  useCreateBlackoutDaysMutation,
  useUpdateBlackoutDaysMutation
} from '../../../graphql/hooks.generated'
import { useStyles } from './blackoutcreatestyle'

interface UrlParams {
  id: string
}

export default function Blackoutdays() {
  const css = `.MuiFormControlLabel-label {
    font-family: 'Brandon Grotesque'
    }`
  const classes = useStyles()
  const blackoutObj = {
    title: '',
    start_date: '',
    end_date: '',
    day_portion: '',
    from_time: '',
    until_time: ''
  }
  const errorObj = {
    title: '',
    start_date: '',
    day_portion: '',
    from_time: '',
    until_time: ''
  }
  const history = useHistory()
  const { id } = useParams<UrlParams>()
  const [formValues, setformValues] = React.useState(blackoutObj)
  const [formErrors, setformErrors] = React.useState(errorObj)
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const { data: blackoutdayObj, loading: blackoutdayloading } = useListBlackoutDayQuery({
    variables: {
      id
    }
  })
  const [value, setValue] = React.useState('')
  useEffect(() => {
    if (id && blackoutdayObj && blackoutdayObj.blackoutDay) {
      const initObj: any = {
        title: '',
        start_date: '',
        end_date: '',
        day_portion: '',
        from_time: '',
        until_time: ''
      }
      blackoutdayObj?.blackoutDay.map((blackObj) => {
        initObj.title = blackObj?.title
        initObj.start_date = moment(blackObj.start_date).format('YYYY-MM-DD')
        initObj.day_portion = blackObj.day_portion ? '1' : '0'
        initObj.from_time = moment(blackObj.start_date).format('H:mm')
        initObj.until_time = moment(blackObj.end_date).format('H:mm')

        return true
      })
      setformValues(initObj)
      setValue(initObj.day_portion)
    }
  }, [id, blackoutdayObj])
  const handleChange = (e) => {
    // console.log(e)
    const { name, value } = e.target
    setValue(e.target.value)
    setformValues({ ...formValues, [name]: value })

    if (!value) setformErrors({ ...formErrors, [name]: 'Required' })
    if (value) setformErrors({ ...formErrors, [name]: '' })
  }
  const onChangeValues = (e) => {
    // console.log(e)
    const { name, value } = e.target
    setformValues({ ...formValues, [name]: value })

    if (!value) setformErrors({ ...formErrors, [name]: 'Required' })
    if (value) setformErrors({ ...formErrors, [name]: '' })
  }
  const [
    createBlackoutDaysMutation,
    { data: createdata, loading: createLoading }
  ] = useCreateBlackoutDaysMutation()
  const [
    upadteBlackoutDaysMutation,
    { data: updatedata, loading: updateLoading }
  ] = useUpdateBlackoutDaysMutation()
  const onUpdateMutation = async ({
    appointmentTypeId,
    districtId,
    userId,
    title,
    serviceId,
    startDate,
    endDate,
    day_portion,
    updatedBy
  }) => {
    try {
      const result = await upadteBlackoutDaysMutation({
        variables: {
          id: String(id),
          input: {
            appointmentTypeId,
            districtId,
            userId,
            title,
            serviceId,
            startDate,
            endDate,
            day_portion,
            updatedBy
          }
        }
      })
      setTimeout(() => {
        history.push('/blackoutDays')
      }, 100)
      // console.log('student save data', result)
    } catch (e) {
      // console.log(e)
    }
  }
  const blackoutFun = async ({
    appointmentTypeId,
    districtId,
    userId,
    title,
    serviceId,
    startDate,
    endDate,
    day_portion,
    updatedBy
  }) => {
    try {
      const result = await createBlackoutDaysMutation({
        variables: {
          input: {
            appointmentTypeId,
            districtId,
            userId,
            title,
            serviceId,
            startDate,
            endDate,
            day_portion,
            createdBy: currentUserdata?.data?.currentUser.id,
            updatedBy
          }
        }
      })
      setTimeout(() => {
        history.push('/blackoutDays')
      }, 100)
      // console.log('student save data', result)
    } catch (e) {
      // console.log(e)
    }
  }
  const saveBlackout = () => {
    // console.log('blackoutdays', formValues)
    let finalenddate
    let finalstartdate
    let haserror = false

    if (!formValues.start_date || !formValues.title || !formValues.day_portion) {
      if (!formValues.start_date) errorObj.start_date = 'Required'
      if (!formValues.title) errorObj.title = 'Required'
      if (!formValues.day_portion) errorObj.day_portion = 'Required'
    }
    if (formValues.day_portion !== '1')
      if (!formValues.from_time || !formValues.until_time) {
        if (!formValues.from_time) errorObj.from_time = 'Required'
        if (!formValues.until_time) errorObj.until_time = 'Required'
      }

    Object.entries(errorObj).forEach(([key, value]) => {
      if (value) haserror = true
    })

    if (haserror) {
      setformErrors(errorObj)

      return
    }
    if (formValues.day_portion === '1') {
      const dayendTime = '23:59:59'
      const enddate = `${formValues.start_date} ${dayendTime}`
      // console.log('full day end date', moment().format())
      finalenddate = moment(enddate).format()
    } else {
      const startdt = `${formValues.start_date} ${formValues.from_time}`
      const enddt = `${formValues.start_date} ${formValues.until_time}`
      finalstartdate = moment(startdt).format()
      finalenddate = moment(enddt).format()
    }

    const inputdata = {
      appointmentTypeId: '3',
      districtId: currentUserdata.data?.currentUser.district?.id
        ? Number(currentUserdata.data?.currentUser.district?.id)
        : 0,
      userId: currentUserdata.data?.currentUser.id,
      title: formValues.title,
      startDate:
        formValues.day_portion === '1' ? moment(formValues.start_date).format() : finalstartdate,
      endDate: finalenddate,
      updatedBy: currentUserdata.data?.currentUser.id,
      serviceId: 22,
      day_portion: formValues.day_portion === '1'
    }
    // console.log('inputdata', inputdata)

    if (id) onUpdateMutation(inputdata)
    else blackoutFun(inputdata)
  }
  const handleBack = () => {
    history.push('/blackoutdays')
  }
  const formTep = (blackObj?) => {
    return (
      <>
        <Grid
          container
          spacing={3}
          className={classes.brandonFF}
          style={{ justifyContent: 'center' }}
        >
          <Grid item md={5}>
            <Grid container item md={12} className={classes.pb15}>
              <Grid item md={6}>
                <div> Date:</div>
              </Grid>
              <Grid item md={6}>
                <div>
                  <input
                    type="date"
                    aria-invalid="true"
                    defaultValue={
                      blackObj?.start_date ? moment(blackObj.start_date).format('YYYY-MM-DD') : ''
                    }
                    name="start_date"
                    onChange={onChangeValues}
                    className={`${classes.textField} ${classes.brandonFF} ${classes.input}`}
                  />
                </div>
                {formErrors.start_date && (
                  <div className={classes.errorColor}>{formErrors.start_date}</div>
                )}
              </Grid>
            </Grid>
            <Grid container item md={12} className={classes.pb15}>
              <Grid item md={6}>
                <div>Comments:</div>
              </Grid>
              <Grid item md={6}>
                <textarea
                  name="title"
                  defaultValue={blackObj?.title ? blackObj.title : ''}
                  onChange={onChangeValues}
                  className={`${classes.textareaField} ${classes.brandonFF} ${classes.input}`}
                />
                {formErrors.title && <div className={classes.errorColor}>{formErrors.title}</div>}
              </Grid>
            </Grid>
            <Grid item md={12} className={classes.pb15}>
              <RadioGroup
                row
                aria-label="position"
                onChange={handleChange}
                name="day_portion"
                defaultValue={blackObj ? (blackObj?.day_portion ? '1' : '0') : ''}
              >
                <style>{css}</style>
                <FormControlLabel value="1" control={<Radio color="primary" />} label="Full Day" />
                <FormControlLabel
                  value="0"
                  control={<Radio color="primary" />}
                  label="Day Portion"
                />
              </RadioGroup>
              {formErrors.day_portion && (
                <div className={classes.errorColor}>{formErrors.day_portion}</div>
              )}
            </Grid>
            {value === '0' && (
              <Grid container item md={12} className={classes.pb15}>
                <Grid item md={6}>
                  <Grid item md={6}>
                    <div className={classes.pb3}> From : </div>
                  </Grid>
                  <Grid item md={6}>
                    <input
                      type="time"
                      defaultValue={
                        blackObj?.start_date
                          ? moment(blackObj.start_date).format('HH:mm')
                          : formValues.from_time
                      }
                      name="from_time"
                      onChange={onChangeValues}
                      className={`${classes.textField} ${classes.brandonFF} ${classes.input}`}
                    />
                  </Grid>
                  {formErrors.from_time && (
                    <div className={classes.errorColor}>{formErrors.from_time}</div>
                  )}
                </Grid>
                <Grid item md={6}>
                  <Grid item md={6}>
                    <div className={classes.pb3}> Until : </div>
                  </Grid>
                  <Grid item md={6}>
                    <input
                      type="time"
                      defaultValue={
                        blackObj?.end_date
                          ? moment(blackObj.end_date).format('HH:mm')
                          : formValues.until_time
                      }
                      name="until_time"
                      onChange={onChangeValues}
                      className={`${classes.textField} ${classes.brandonFF} ${classes.input}`}
                    />
                  </Grid>
                  {formErrors.until_time && (
                    <div className={classes.errorColor}>{formErrors.until_time}</div>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <div className={classes.root}>
      {(createLoading || updateLoading || blackoutdayloading) && <ProgressIndicator fullHeight />}
      <NavBar title="Blackout Days" goBackBtn onClick={handleBack} />
      <PageContainer>
        <Paper className={classes.brandonFF}>
          <div className={classes.ml20}>
            <br />
            <div className={classes.center}>
              <h3 className={classes.textLinkClr}>{`${id ? `Update` : ''} Blackout Date for ${
                currentUserdata ? currentUserdata?.data?.currentUser?.district?.name : `Admin`
              }`}</h3>
            </div>
            <br />
            <form>
              {id &&
                blackoutdayObj &&
                blackoutdayObj.blackoutDay &&
                blackoutdayObj.blackoutDay.length > 0 &&
                blackoutdayObj.blackoutDay.map((blackObj) => <>{formTep(blackObj)}</>)}
              {!id && formTep()}
            </form>
            <br />
            <br />
            <div className={classes.center}>
              <Button size="large" className={classes.submitBtn} onClick={saveBlackout}>
                Submit
              </Button>
            </div>
            <br />
            <div className={classes.center}>
              <Button
                onClick={handleBack}
                className={`${classes.textLinkClr} ${classes.brandonFF}`}
              >
                Back To Blackout Dates
              </Button>
            </div>
          </div>
        </Paper>
      </PageContainer>
    </div>
  )
}
