import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import { StateForm, StateFormModel } from '../form/StateForm'
import { State, useUpdateStateMutation, useStateQuery } from '../../../../graphql/hooks.generated'
import { UseParamsType } from '../../../../types'

interface UpdateStateViewParams extends UseParamsType {
  id: string
}

export const UpdateStateView = () => {
  const history = useHistory()
  const { id } = useParams<UpdateStateViewParams>()
  const [updateState] = useUpdateStateMutation()
  const { data } = useStateQuery({ variables: { id: parseInt(id) }, fetchPolicy: 'network-only' })
  const state:
    | ({ __typename?: 'State' | undefined } & Pick<
        State,
        | 'name'
        | 'abbreviation'
        | 'medicaid_name'
        | 'ein'
        | 'street_address'
        | 'city'
        | 'zip'
        | 'contact_info'
      >)
    | undefined = data?.state
  const initialValues: StateFormModel | null = state
    ? {
        abbreviation: state.abbreviation,
        streetAddress: state.street_address ?? undefined,
        medicaidName: state.medicaid_name ?? undefined,
        name: state.name,
        city: state.city ?? undefined,
        contactInfo: state.contact_info ?? undefined,
        zip: state.zip ?? undefined,
        ein: state.ein ?? undefined
      }
    : null

  return (
    <>
      <NavBar title="Update State" goBackBtn />
      <PageContainer>
        <StateForm
          submitLabel="Save"
          initialValues={initialValues}
          onSubmit={(input: StateFormModel) =>
            updateState({
              variables: {
                id: parseInt(id),
                input
              }
            }).then(history.goBack)
          }
        />
      </PageContainer>
    </>
  )
}
