import { ServiceRequestType } from 'graphql/hooks.generated'
import { makeValidate } from 'mui-rff'
import * as yup from 'yup'
import { CreateServiceRequestFormModel } from './CreateServiceRequestFormModel'

export const validator = makeValidate(
  yup.object<CreateServiceRequestFormModel>({
    grade: yup.string().label('Grade').max(255),
    minutes: yup
      .string()
      .matches(/^[0-9]+$/, '${path} should only contain numbers')
      .label('Minutes')
      .max(10),
    student: yup.string().label('Student').nullable().required(),
    type: yup.mixed<ServiceRequestType>().label('Type').required(),
    upload: yup.mixed<File>().label('Upload').required()
  })
)
