import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles(({ palette }: Theme) => ({
  headRoot: {
    heigth: '40px',
    '& > tr > th': {
      borderBottom: `1px dotted ${palette.divider}`,
      color: '#848484',
      fontSize: '10px',
      padding: '8px 32px'
    }
  },
  labelText: {
    fontSize: '14px',
    color: '#2C9BD6',
    fontWeight: 'bold',
    fontFamily: 'Brandon Grotesque'
  },
  labelField: {
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff !important',
      border: '1px solid #000'
    },
    '& .MuiSelect-filled.MuiSelect-filled': {
      backgroundColor: '#fff !important'
    }
  },
  '&.MuiFilledInput-root': {
    backgroundColor: '#fff'
  },
  labelFieldNew: {
    border: '1px solid #000',
    backgroundColor: '#fff !important',
    '&.MuiFilledInput-adornedStart': {
      backgroundColor: '#fff !important',
      border: '1px solid #000',
      '&.MuiFilledInput-root': {
        backgroundColor: '#fff'
      }
    }
  },
  labelFieldOne: {
    marginTop: '10px',
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff !important',
      border: '1px solid #000'
    },
    '& .MuiSelect-filled.MuiSelect-filled': {
      backgroundColor: '#fff !important'
    }
  },
  '&.MuiFilledInput-adornedStart': {
    backgroundColor: '#fff !important',
    border: '1px solid #000'
  },
  selectDropdown: {
    width: '100%',
    height: '50px',
    marginTop: '9px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff !important',
      border: '1px solid #000'
    },
    '& .MuiSelect-filled.MuiSelect-filled': {
      backgroundColor: '#fff !important'
    }
  },
  title: {
    borderBottom: `1px dotted ${palette.divider}`,
    padding: '16px 32px',
    '& > *': {
      color: palette.info.main
    }
  }
}))
