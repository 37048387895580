import {
  Avatar,
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@material-ui/core'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Search,
  Inject,
  ToolbarItems,
  Page,
  Sort,
  SortSettingsModel,
  Filter,
  FilterSettingsModel
} from '@syncfusion/ej2-react-grids'
import { Link as RouterLink } from 'react-router-dom'
import NavBar from 'components/ui/panel/nav-bar/NavBar'
import { PageContainer } from 'components/ui/panel/page-container/PageContainer'
import Paper from 'components/ui/panel/paper/Paper'
import { Section } from 'components/ui/panel/section/Section'
import { NoData } from 'components/ui/panel/table/no-data/NoData'
import { format } from 'date-fns'
import { useAllServiceRequestsQuery } from 'graphql/hooks.generated'
import React from 'react'
import { serviceRequestTypeDescription } from '../util/enumDescription'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'

export const AllServiceRequestsView = () => {
  const theme = useTheme()
  const { data, loading } = useAllServiceRequestsQuery({ fetchPolicy: 'network-only' })
  const hasData = !!data?.serviceRequests
  const classes = useStyles()
  const message = loading ? 'Loading requests...' : 'No service requests found.'
  const toolbarOptions: ToolbarItems[] = ['Search']
  const sortSettings: SortSettingsModel = {
    columns: [
      { field: 'student.name', direction: 'Ascending' },
      { field: 'requester.name', direction: 'Ascending' },
      { field: 'type', direction: 'Ascending' }
    ]
  }
  const searchOptions = {
    fields: ['student.name', 'requester.name', 'type'],
    ignoreCase: true
  }

  return (
    <>
      <NavBar title="Service requests" navOptions={[]} goBackBtn />
      <PageContainer>
        <div className={classes.plr10}>
          <Box py={3} display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Typography variant="h3" style={{ color: theme.palette.info.main }}>
                Service requests
              </Typography>
            </Box>
          </Box>
          <div className="control-pane">
            <div className="control-section">
              {!hasData && <NoData message={message} colSpan={5} />}
              {data && (
                <GridComponent
                  key={Math.floor(Math.random() * 100)}
                  allowTextWrap
                  dataSource={data?.serviceRequests || []}
                  width="auto"
                  toolbar={toolbarOptions}
                  allowPaging
                  pageSettings={{ pageSizes: [15, 25, 50, 100], pageSize: 15, pageCount: 5 }}
                  allowSorting
                  sortSettings={sortSettings}
                  searchSettings={searchOptions}
                  className={classes.headerCls}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      template={() => (
                        <Avatar className="e-avatar e-avatar-large e-avatar-circle w-4- h-40" />
                      )}
                      width="40"
                    />
                    <ColumnDirective
                      field="student.name"
                      template={(disname) => (
                        <span className={classes.nameCls}>{disname.student.name}</span>
                      )}
                      headerText="NAME"
                      width="110"
                    />
                    <ColumnDirective field="requester.name" headerText="REQUESTER" width="110" />
                    <ColumnDirective
                      field="type"
                      headerText="TYPE"
                      template={(request) => (
                        <span style={{ color: 'black' }}>
                          {`${serviceRequestTypeDescription[request.type]}`}
                        </span>
                      )}
                      width="110"
                    />
                    <ColumnDirective
                      field="createdAt"
                      template={(request) => (
                        <span style={{ color: 'black' }}>
                          {format(new Date(request.createdAt), 'MM/dd/yyyy HH:mm')}
                        </span>
                      )}
                      headerText="CREATED"
                      width="110"
                    />
                    <ColumnDirective
                      field="download"
                      template={(download) => (
                        <Link style={{ color: 'black' }} href={download.fileUrl}>
                          Download file
                        </Link>
                      )}
                      headerText="DOWNLOAD"
                      width="110"
                    />
                    <ColumnDirective
                      field="assign"
                      template={(assign) => (
                        <Link
                          style={{ color: 'black' }}
                          component={RouterLink}
                          to={`providerAssignments/create/${assign.student.id}/${assign.type}`}
                        >
                          Assign Provider
                        </Link>
                      )}
                      headerText="ASSIGN"
                      width="110"
                    />
                  </ColumnsDirective>
                  <Inject services={[Search, Toolbar, Sort, Page]} />
                </GridComponent>
              )}
            </div>
          </div>
        </div>
      </PageContainer>
    </>
  )
}
