import React, { useEffect, useState, useMemo } from 'react'
import {
  Box,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormLabel,
  FormControl,
  Checkbox
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import Stepper from '@material-ui/core/Stepper'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import TagManager from 'react-gtm-module'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { UseParamsType } from 'types'
import { Urlservice } from '../serviceHelpers/urlhelper'
import { useStyles } from '../styles/surveyformstyle'

interface ListTokenParams extends UseParamsType {
  loc: string
}
const { REACT_APP_IMATTER_HOME_URL } = process.env
const getSteps = () => {
  return ['One', 'Two', 'Three', 'Four', 'Five', 'Six']
}

export const ParentView = () => {
  // useEffect(() => {
  //   const tagManagerArgs = {
  //     gtmId: 'GTM-T644KWW'
  //   }
  //   TagManager.initialize(tagManagerArgs)
  // }, [])
  const { t, i18n } = useTranslation()
  const { loc } = useParams<ListTokenParams>()
  // console.log(loc)
  const params = new URLSearchParams(window.location.search)
  const [activeStep, setActiveStep] = useState(0)
  useMemo(() => {
    if (loc === 'en' || loc === 'es') i18n.changeLanguage(loc || 'en')
    else i18n.changeLanguage('en')
  }, [i18n, loc])
  const locallang = i18n?.language || 'es'
  // const locallang = loc
  const classes = useStyles()
  const colors = {
    0: '#46A7DD',
    1: '#FF51A7',
    2: '#FFC22D',
    3: '#844191',
    4: '#FF6400',
    5: '#00DE74'
  }
  const css = `.MuiFormControlLabel-label {
    font-family: 'Poppins'
    }
    .MuiFormLabel-root.Mui-focused {
    color: #293f70
    }
    .nextBtn {
      color: #fff;
      background-color: ${colors[activeStep]};
      box-shadow: none;
    }
    .nextBtn:hover {
      background-color: ${colors[activeStep]};
    }
    .backBtn {
      border: 1px solid ${colors[activeStep]}
    }
    .backBtn:hover {
      background-color: transparent;
    }
    .MuiRadio-colorPrimary.Mui-checked {
      color: ${colors[activeStep]}
    }
    .MuiStepIcon-root.MuiStepIcon-active {
      color: ${colors[activeStep]}
    }
    .MuiStepIcon-root.MuiStepIcon-completed {
      color: ${colors[activeStep]}
    }
    .MuiCheckbox-colorSecondary.Mui-checked {
      color: ${colors[activeStep]}
    }`
  const history = useHistory()
  const steps = getSteps()
  const [data, setData] = useState<any>()
  const [changeRef, setChangeRef] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [surveyscore, setSurveyscore] = useState(0)
  const [sdohscore, setSdohscore] = useState(0)
  const [disableNext, setDisableNext] = useState(false)
  const [breakNext, setbreakNext] = useState(false)
  const scorefetch = () => {
    setLoading(true)
    Urlservice.posturl(Urlservice.surveyScore, { surveyid: data.surveyid })
      .then((score) => {
        setLoading(false)
        let totscore = 0
        let sdohscore = 0
        score?.data.map((item) => {
          if (item.questiontypeId === 2) totscore += item.score ? Number(item.score) : 0
          if (item.questiontypeId === 7) sdohscore += item.score ? Number(item.score) : 0

          return true
        })
        setSurveyscore(totscore)
        setSdohscore(sdohscore)
        setChangeRef(!changeRef)
        setData(data)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }
  const breakSaveCall = (flag) => {
    setLoading(true)
    Urlservice.posturl(Urlservice.saveQuestionsUrl, data)
      .then((ques) => {
        setLoading(false)

        if (flag) history.push(`/public/survey/encourage/${loc || 'en'}`)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }
  const savecall = (gonext) => {
    setLoading(true)

    if (activeStep === steps.length - 1) {
      data.proceed = 1
      data.surveystatusid = 3
    }

    let isAgeAbove18 = false
    let isSpecialServ = false
    Urlservice.posturl(Urlservice.saveQuestionsUrl, data)
      .then((ques) => {
        setLoading(false)
        ques?.data.signal_survey?.map((item) => {
          item.error = ''

          if (
            activeStep === 1 &&
            item.questionId === 35 &&
            item.selected_value &&
            item.selected_value === 'No'
          )
            history.push(`/public/survey/encourage/${loc || 'en'}`)
          if (
            activeStep === 2 &&
            item.questionId === 36 &&
            item.selected_value &&
            Number(item.selected_value) >= 19
          )
            isAgeAbove18 = true
          if (
            activeStep === 2 &&
            item.questionId === 37 &&
            item.selected_value &&
            item.selected_value === 'No'
          )
            isSpecialServ = true
          if (isAgeAbove18 && isSpecialServ)
            history.push(`/public/survey/encourage/q=true&${loc || 'en'}`)

          return true
        })

        if (breakNext) {
          if (activeStep === 2) history.push(`/public/survey/encourage/q=true&${loc || 'en'}`)
          else history.push(`/public/survey/encourage/${loc || 'en'}`)

          return
        }
        if (activeStep !== steps.length - 1 && gonext) {
          setData(ques.data)
          setActiveStep((prevActiveStep) => prevActiveStep + 1)
          window.scrollTo(0, 0)
        }
        if (activeStep === steps.length - 1) {
          let nofreeSess = true
          data.signal_survey?.map((item) => {
            if (
              activeStep === 5 &&
              item.questionId === 60 &&
              item.selected_value &&
              item.selected_value === 'No'
            )
              nofreeSess = false

            return true
          })

          if (nofreeSess) {
            sessionStorage.setItem('sid', data.surveyid)
            history.push(`/public/register/about-you/${loc || 'en'}`)
          } else history.push(`/public/survey/encourage/${loc || 'en'}`)
        }
        // if (activeStep === steps.length - 1 && surveyscore <= 14)
        //   history.push('/public/survey/encourage')
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }
  const saveAndScoreCall = async (gonext) => {
    try {
      await savecall(gonext)
      scorefetch()
    } catch (e) {
      console.log(e)
    }
  }
  const handleNext = () => {
    let hasError = false
    data.signal_survey
      ?.filter((filval) => filval.page_no === activeStep + 1)
      .map((itemObj) => {
        // if (activeStep === steps.length - 1) {
        //   if (!itemObj.selected_value && itemObj.questionId === 60 && surveyscore > 14) {
        //     hasError = true
        //     itemObj.error = 'Required'
        //   }
        // } else
        if (activeStep !== 0 && !itemObj.selected_value) {
          hasError = true
          itemObj.error = `${t('parentsurveypage.error')}`
        }

        return true
      })
    setChangeRef(!changeRef)
    setData(data)

    if (hasError) return
    if (activeStep !== steps.length - 2) savecall(true)
    if (activeStep === steps.length - 2) saveAndScoreCall(true)
  }
  const handleBack = () => {
    setbreakNext(false)
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    window.scrollTo(0, 0)
  }
  const checkboxChange = (e, item, choiceobj) => {
    const { checked } = e.target
    item.error = ''

    if (checked) {
      if (item.choiceId) {
        if (
          choiceobj.choiceId === 183 ||
          choiceobj.choiceId === 184 ||
          choiceobj.choiceId === 187 ||
          choiceobj.choiceId === 188
        )
          item.choiceId = []

        item.choiceId.push(choiceobj.choiceId)
      } else {
        item.choiceId = []
        item.choiceId.push(choiceobj.choiceId)
      }
      if (item.selected_value)
        if (
          choiceobj.choiceId === 183 ||
          choiceobj.choiceId === 184 ||
          choiceobj.choiceId === 187 ||
          choiceobj.choiceId === 188
        )
          item.selected_value = choiceobj.choiceId.toString()
        else
          item.selected_value = item.selected_value.concat(
            ','.concat(choiceobj.choiceId.toString())
          )
      else item.selected_value = choiceobj.choiceId.toString()
    }
    if (!checked) {
      const index = item.choiceId.indexOf(choiceobj.choiceId)
      item.choiceId.splice(index, 1)
      const selectvaluesArr = item.selected_value.split(',')
      selectvaluesArr.splice(index, 1)
      item.selected_value = selectvaluesArr.join(',')
    }

    setChangeRef(!changeRef)
    setData(data)
  }
  const handleChange = (e, item) => {
    const { value } = e.target
    item.selected_value = value
    item.error = ''
    item.signal_survey_questiontypechoices.map((choiceobj) => {
      if (choiceobj.choiceEnText === value) {
        item.score = choiceobj.score
        item.choiceId = choiceobj.choiceId
      }

      return true
    })

    if (item.questionId === 34 && item.selected_value) {
      if (item.selected_value === 'Yes') {
        data.crisis = true
        setDisableNext(true)
        breakSaveCall(false)
      }
      if (item.selected_value === 'No') {
        data.crisis = false
        setDisableNext(false)
      }
    }
    if (item.questionId === 35 && item.selected_value) {
      if (item.selected_value === 'Yes') {
        data.ismenatalhealth = true
        setDisableNext(false)
        setbreakNext(false)
      }
      if (item.selected_value === 'No') {
        data.ismenatalhealth = false
        setDisableNext(false)
        // breakSaveCall(true)
        setbreakNext(true)
      }
    }
    if (item.questionId === 60 && item.selected_value) {
      if (item.selected_value === 'Yes') {
        setDisableNext(false)
        setbreakNext(false)
      }
      if (item.selected_value === 'No') {
        setDisableNext(false)
        // breakSaveCall(true)
        setbreakNext(true)
      }
    }
    if (item.questionId === 36 && item.selected_value) data.agegroupid = Number(item.selected_value)

    let isAgeAbove18 = false
    let isSpecialServ = false
    data.signal_survey.map((quedata) => {
      if (
        quedata.questionId === 36 &&
        quedata.selected_value &&
        Number(quedata.selected_value) >= 19
      )
        isAgeAbove18 = true
      if (
        quedata.questionId === 36 &&
        quedata.selected_value &&
        Number(quedata.selected_value) < 19
      )
        isAgeAbove18 = false
      if (quedata.questionId === 37 && quedata.selected_value && quedata.selected_value === 'No')
        isSpecialServ = true
      if (quedata.questionId === 37 && quedata.selected_value && quedata.selected_value === 'Yes')
        isSpecialServ = false

      return true
    })

    if ((item.questionId === 36 || item.questionId === 37) && isAgeAbove18 && isSpecialServ)
      setbreakNext(true)
    if ((item.questionId === 36 || item.questionId === 37) && (!isAgeAbove18 || !isSpecialServ))
      setbreakNext(false)
    // if (item.questionId === 57 || item.questionId === 58) saveAndScoreCall(false)

    setChangeRef(!changeRef)
    setData(data)
  }
  const getStepContent = (data, stepIndex: number) => {
    return (
      <>
        <Grid container className={classes.parentPop}>
          <style>{css}</style>
          {data.signal_survey
            .filter((filval) => filval.page_no === stepIndex + 1)
            .map((itemObj, index) => (
              <Grid
                item
                md={stepIndex + 1 === 4 ? 6 : 12}
                className={classes.pb10}
                key={itemObj.questionId}
              >
                {itemObj.choiceTypeId === 2 && (
                  <>
                    <div className={`${classes.fs} ${classes.primaryColor}  ${classes.popin}`}>
                      {locallang === 'en' ? itemObj?.questionEnText : itemObj?.questionESText}
                    </div>
                    <Select
                      className={`${classes.selectDropdown} ${classes.popin}`}
                      variant="filled"
                      defaultValue={itemObj.selected_value ? itemObj.selected_value : ''}
                      onChange={(e) => handleChange(e, itemObj)}
                      classes={{
                        root: 'muisc-root',
                        select: 'muisc-select',
                        selectMenu: 'muisc-select-menu'
                      }}
                    >
                      {/* <style>{css}</style> */}
                      {itemObj?.signal_survey_questiontypechoices?.map((opt) => (
                        <MenuItem
                          className={classes.popin}
                          key={opt.choiceId}
                          placeholder="Age"
                          value={opt.choiceEnText}
                        >
                          {locallang === 'en' ? opt.choiceEnText : opt.choiceEsText}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {itemObj.choiceTypeId === 3 && (
                  <>
                    <div className={`${classes.fs} ${classes.primaryColor} ${classes.popin}`}>
                      {stepIndex + 1 === 5 ? `${index + 1}. ` : ''}
                      {locallang === 'en' ? itemObj?.questionEnText : itemObj?.questionESText}
                    </div>
                    {itemObj?.signal_survey_questiontypechoices?.map((opt) => (
                      <div className={`${classes.fs1}  ${classes.popin}`}>
                        <Checkbox
                          key={opt.choiceId}
                          disabled={
                            (itemObj?.selected_value === '183' ||
                              itemObj?.selected_value === '184' ||
                              itemObj?.selected_value === '187' ||
                              itemObj?.selected_value === '188') &&
                            !(opt.choiceId === 183 && itemObj?.selected_value === '183') &&
                            !(opt.choiceId === 184 && itemObj?.selected_value === '184') &&
                            !(opt.choiceId === 187 && itemObj?.selected_value === '187') &&
                            !(opt.choiceId === 188 && itemObj?.selected_value === '188')
                          }
                          checked={
                            itemObj.selected_value
                              ? itemObj.selected_value.split(',').includes(opt.choiceId.toString())
                              : false
                          }
                          onChange={(e) => checkboxChange(e, itemObj, opt)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <span
                          className={
                            (itemObj?.selected_value === '183' ||
                              itemObj?.selected_value === '184' ||
                              itemObj?.selected_value === '187' ||
                              itemObj?.selected_value === '188') &&
                            !(opt.choiceId === 183 && itemObj?.selected_value === '183') &&
                            !(opt.choiceId === 184 && itemObj?.selected_value === '184') &&
                            !(opt.choiceId === 187 && itemObj?.selected_value === '187') &&
                            !(opt.choiceId === 188 && itemObj?.selected_value === '188')
                              ? classes.textdisable
                              : ''
                          }
                        >
                          {locallang === 'en' ? opt.choiceEnText : opt.choiceEsText}
                        </span>
                      </div>
                    ))}
                  </>
                )}
                {itemObj.choiceTypeId === 1 && (
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      className={`${classes.ff} ${classes.fs} ${classes.primaryColor}`}
                    >
                      {stepIndex + 1 === 4 || stepIndex + 1 === 5 ? `${index + 1}. ` : ''}
                      {locallang === 'en' ? itemObj?.questionEnText : itemObj?.questionESText}
                    </FormLabel>
                    {itemObj.questionId !== 34 && (
                      <RadioGroup
                        row
                        aria-label="position"
                        name={`${itemObj?.questionName}`}
                        className={`${classes.fs}`}
                        defaultValue={itemObj.selected_value ? itemObj.selected_value : ''}
                        onChange={(e) => handleChange(e, itemObj)}
                      >
                        <style>{css}</style>
                        {itemObj?.signal_survey_questiontypechoices?.map((opt) => (
                          <FormControlLabel
                            key={opt.choiceId}
                            value={opt.choiceEnText}
                            control={<Radio color="primary" />}
                            label={locallang === 'en' ? opt.choiceEnText : opt.choiceEsText}
                            className={stepIndex + 1 === 5 ? classes.widthFull : ''}
                          />
                        ))}
                      </RadioGroup>
                    )}
                  </FormControl>
                )}
                {/* {itemObj.choiceTypeId === 1 && stepIndex + 1 === 6 && surveyscore >= 15 && (
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      className={`${classes.ff} ${classes.fs} ${classes.primaryColor}`}
                    >
                      {stepIndex + 1 === 4 || stepIndex + 1 === 5 ? `${index + 1}. ` : ''}
                      {itemObj?.questionName}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name={`${itemObj?.questionName}`}
                      defaultValue={itemObj.selected_value ? itemObj.selected_value : ''}
                      onChange={(e) => handleChange(e, itemObj)}
                    >
                      <style>{css}</style>
                      {itemObj?.signal_survey_questiontypechoices?.map((opt) => (
                        <FormControlLabel
                          key={opt.choiceId}
                          value={opt.choiceEnText}
                          control={<Radio color="primary" />}
                          label={opt.choiceEnText}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                )} */}
                {(itemObj.questionId === 34 ||
                  (itemObj.questionId === 35 && itemObj.selected_value === 'No')) && (
                  <Grid className={`${classes.popin}`}>
                    {itemObj.questionId === 34 && (
                      <>
                        <div
                          className={`${classes.textcolor} ${classes.textJustify} ${classes.pt10}`}
                        >
                          {t('parentsurveypage.ccsT1')}
                          {', '}
                          {t('parentsurveypage.ccsT2')}{' '}
                          <a
                            href={`${
                              loc === 'es'
                                ? 'https://coloradocrisisservices.org/es/'
                                : 'https://coloradocrisisservices.org'
                            }`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t('parentsurveypage.ccsT3')}
                          </a>{' '}
                          {t('parentsurveypage.ccsT4')}
                        </div>
                        <div
                          className={`${classes.textcolor} ${classes.textJustify} ${classes.pt10}`}
                        >
                          {t('parentsurveypage.plzClickNextCompSur')}
                        </div>
                      </>
                    )}
                    {/* {itemObj.questionId === 35 && itemObj.selected_value === 'No' && (
                      <span className={`${classes.textcolor} ${classes.textJustify}`}>
                        Here are some{' '}
                        <a
                          target="_blank"
                          className={classes.primaryColor}
                          href={`${REACT_APP_IMATTER_HOME_URL}/content/resource.pdf`}
                          rel="noreferrer"
                        >
                          resources
                        </a>{' '}
                        that may help you, your child or friend with mental health or substance use
                        challenges.
                      </span>
                    )} */}
                  </Grid>
                )}
                {/* {itemObj.questionId === 60 && itemObj.selected_value === 'No' && (
                  <div className={`${classes.textcolor} ${classes.textJustify}`}>
                    Here are some{' '}
                    <a
                      target="_blank"
                      className={classes.primaryColor}
                      href={`${REACT_APP_IMATTER_HOME_URL}/content/resource.pdf`}
                      rel="noreferrer"
                    >
                      resources
                    </a>{' '}
                    you may find helpful to better understand the ways you can stay healthy or help
                    a friend or family member with a mental health or substance use issue. Please
                    visit again, if you are interested in scheduling counseling services.
                  </div>
                )} */}
                {itemObj.questionId === 38 && itemObj.selected_value === 'Yes' && (
                  <div className={`${classes.textcolor} ${classes.textJustify} ${classes.popin}`}>
                    {t('parentsurveypage.yourChildIsEnrolled')}
                  </div>
                )}
                {itemObj.error && <div className={classes.errorColor}>{itemObj.error}</div>}
              </Grid>
            ))}
        </Grid>
      </>
    )
  }
  useEffect(() => {
    const inputdata = {
      surveytypes: '2,5,7',
      surveystatusid: 2,
      languageid: 1,
      isparentsurvey: 1
    }
    setLoading(true)
    Urlservice.posturl(Urlservice.questionsUrl, inputdata)
      .then((ques) => {
        setLoading(false)
        ques?.data.signal_survey?.map((item) => {
          item.error = ''

          return true
        })

        if (sessionStorage.getItem('sid')) sessionStorage.removeItem('sid')

        setData(ques.data)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }, [setData, setLoading])

  return (
    <div className={`${classes.bodyh}`} style={{ backgroundColor: colors[activeStep] }}>
      <div className={classes.root}>
        {loading && <ProgressIndicator fullHeight />}
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={`${classes.instructions} ${classes.p10} ${classes.center}`}>
                {t('parentsurveypage.steps')}
              </Typography>
            </div>
          ) : (
            <div>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={`${classes.pt10} ${classes.pb10} ${classes.plr20} ${classes.popin}`}
                >
                  <div className={classes.midiumtextBold}>{t('parentsurveypage.parentSurvey')}</div>
                  <div style={{ fontSize: '16px' }}>{t('parentsurveypage.freeSessionMsg')}</div>
                  <div className={classes.line} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={`${classes.pt10} ${classes.pb10} ${classes.plr20}`}
                >
                  {activeStep === 2 && (
                    <div className={`${classes.midiumtextBold} ${classes.pb15} ${classes.popin}`}>
                      {t('parentsurveypage.aboutUrChildHeader')}
                    </div>
                  )}
                  {activeStep === 3 && (
                    <>
                      <div className={`${classes.midiumtextBold} ${classes.pb5} ${classes.popin}`}>
                        {t('parentsurveypage.psc')}
                      </div>
                      <div
                        style={{ fontSize: '18px' }}
                        className={`${classes.pb10} ${classes.popin}`}
                      >
                        {t('parentsurveypage.offenUrChildMsg')} ...
                      </div>
                    </>
                  )}
                  {activeStep === 4 && (
                    <div
                      className={`${classes.midiumtextBold} ${classes.pb15}  ${classes.popin} ${classes.popin}`}
                    >
                      {t('parentsurveypage.lifeNeeds')}
                    </div>
                  )}
                  {activeStep === steps.length - 1 && (
                    <div className={`${classes.widthFull} ${classes.dInline}  ${classes.popin}`}>
                      <div
                        className={`${classes.bold} ${classes.fs16} ${classes.pb5} ${classes.mt2}`}
                      >
                        {t('parentsurveypage.childScore')}: {surveyscore}
                      </div>
                    </div>
                  )}
                  {activeStep === steps.length - 1 && (
                    <Grid className={`${classes.pb10}  ${classes.popin}`}>
                      {surveyscore < 15 && (
                        <>
                          <div
                            className={`${classes.textcolor} ${classes.pb15} ${classes.textJustify}`}
                          >
                            {t('parentsurveypage.suggestUrChild')}
                          </div>
                          {/* <div className={`${classes.textcolor}`}>
                            Here are some{' '}
                            <a
                              target="_blank"
                              className={classes.primaryColor}
                              href={`${REACT_APP_IMATTER_HOME_URL}/content/resource.pdf`}
                              rel="noreferrer"
                            >
                              resources
                            </a>{' '}
                            that may help you learn more about the signs of mental health conditions
                            and to talk with your child about their stress, feelings or behaviors.
                          </div> */}
                          <div className={`${classes.textcolor}`}>
                            {t('parentsurveypage.coloradoServicesT1')}{' '}
                            <a
                              href={`${
                                loc === 'es'
                                  ? 'https://coloradocrisisservices.org/es/'
                                  : 'https://coloradocrisisservices.org'
                              }`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {t('parentsurveypage.coloradoServicesT2')}
                            </a>{' '}
                            {t('parentsurveypage.coloradoServicesT3')}
                          </div>
                        </>
                      )}
                      {surveyscore >= 15 && (
                        <>
                          <div
                            className={`${classes.textcolor} ${classes.pb15} ${classes.textJustify}`}
                          >
                            {t('parentsurveypage.childriskMsg')}
                          </div>
                          <div className={`${classes.textcolor} ${classes.textJustify}`}>
                            {t('parentsurveypage.whileThisScreeningMsg')}
                          </div>
                        </>
                      )}
                    </Grid>
                  )}
                  {data &&
                    data?.signal_survey?.length &&
                    data?.signal_survey?.map((item, ind) => (
                      <>
                        {((!changeRef && ind === activeStep) ||
                          (changeRef && ind === activeStep)) && (
                          <Grid key={`${item.questionId}${Math.floor(Math.random() * 100)}`}>
                            {getStepContent(data, activeStep)}
                          </Grid>
                        )}
                      </>
                    ))}
                  {/* {activeStep === 4 && sdohscore > 0 && (
                  <>
                    <div className={classes.textcolor}>
                      <a
                        target="_blank"
                        className={classes.primaryColor}
                        href="https://signal-tt-portal-storage-dev.s3.amazonaws.com/youth-resources/Youth+Resources.pdf"
                        rel="noreferrer"
                      >
                        Source:
                        https://www.nachc.org/wp-content/uploads/2020/04/PRAPARE-One-Pager-9-2-16-with-logo-and-trademark.pdf.
                      </a>{' '}
                    </div>
                  </>
                )} */}
                </Grid>
              </Grid>
              <div>
                <Grid container className={`${classes.jleft} ${classes.popin}`}>
                  <Grid item className={`${classes.ptbl20} ${classes.popin}`}>
                    <Button
                      className={`${
                        activeStep === 0 || disableNext ? classes.disableBorder : 'backBtn'
                      } ${classes.ff} ${classes.btnPadd}`}
                      disabled={activeStep === 0 || disableNext}
                      onClick={handleBack}
                    >
                      {t('parentsurveypage.back')}
                    </Button>
                  </Grid>
                  <Grid item className={`${classes.p20}`}>
                    <Button
                      disabled={disableNext}
                      variant="contained"
                      onClick={handleNext}
                      className={`${classes.ff} ${classes.btnPadd} ${classes.popin} nextBtn`}
                    >
                      {activeStep === steps.length - 1
                        ? `${t('parentsurveypage.proceed')}`
                        : `${t('parentsurveypage.next')}`}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
