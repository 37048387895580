import { Grid } from '@material-ui/core'
import { makeValidate, TextField, Checkboxes } from 'mui-rff'
import React from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { TherapistDisciplineSelect } from 'components/ui/form/therapist-discipline-select/TherapistDisciplineSelect'
import Button from '../../../../components/ui/panel/button/Button'
import { InfoButton } from '../../../../components/ui/panel/button/InfoButton'
import { Section } from '../../../../components/ui/panel/section/Section'
import { TherapistDiscipline } from '../../../../graphql/hooks.generated'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'

export interface ServiceFormModel {
  name: string
  code: string
  therapistDiscipline?: TherapistDiscipline | null
  is_direct?: boolean | null
  is_indirect?: boolean | null
  is_therapy?: boolean | null
  is_evaluation?: boolean | null
  is_support?: boolean | null
}

type ServiceFormProps = {
  onSubmit(values: ServiceFormModel): void
  submitLabel: string
  initialValues?: Partial<ServiceFormModel> | null
}
const validator = makeValidate(
  yup.object<ServiceFormModel>({
    name: yup.string().label('Service Name').required(),
    code: yup.string().label('Service Code').required()
  })
)

export const ServiceForm = (props: ServiceFormProps) => {
  const history = useHistory()
  const classes = useStyles()
  const { initialValues } = props
  const onSubmit = (values: ServiceFormModel) => props.onSubmit(values)
  const renderForm = (renderProps: FormRenderProps<ServiceFormModel>) => {
    const { handleSubmit } = renderProps

    return (
      <form>
        <Grid container spacing={4} style={{ maxWidth: '800px' }}>
          <Grid item sm={12}>
            <Section title="New Service ">
              <Grid container spacing={3}>
                <Grid item sm={6}>
                  <TextField
                    required
                    fullWidth
                    variant="filled"
                    name="name"
                    label="Service Name"
                    className={classes.labelField}
                  />
                </Grid>

                <Grid item sm={6}>
                  <TextField
                    required
                    variant="filled"
                    name="code"
                    label="Service Code"
                    className={classes.labelField}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TherapistDisciplineSelect
                    required
                    label="Provider Discipline"
                    name="therapistDiscipline"
                  />
                </Grid>
              </Grid>
            </Section>
            <Grid container spacing={3} style={{ marginLeft: '-2px' }}>
              <Grid item sm={4}>
                <Checkboxes name="is_therapy" data={[{ label: 'Therapy', value: true }]} />
              </Grid>
              <Grid item sm={4}>
                <Checkboxes name="is_evaluation" data={[{ label: 'Evaluation', value: true }]} />
              </Grid>
              <Grid item sm={4}>
                <Checkboxes name="is_support" data={[{ label: 'Support', value: true }]} />
              </Grid>
              <Grid item sm={4}>
                <Checkboxes name="is_direct" data={[{ label: 'Direct Service', value: true }]} />
              </Grid>
              <Grid item sm={4}>
                <Checkboxes
                  name="is_indirect"
                  data={[{ label: 'Indirect Service', value: true }]}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={12} style={{ paddingTop: 35, marginLeft: '6px' }}>
            <InfoButton
              size="large"
              variant="contained"
              onClick={handleSubmit}
              style={{ marginRight: 12 }}
            >
              {props.submitLabel}
            </InfoButton>
            <Button size="large" variant="outlined" color="primary" onClick={history.goBack}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <Form<ServiceFormModel>
      initialValues={initialValues ?? {}}
      validate={validator}
      render={renderForm}
      onSubmit={onSubmit}
    />
  )
}
