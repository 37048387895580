import { Grid, TextField } from '@material-ui/core'
import React, { useState, FormEvent } from 'react'
import 'video-react/dist/video-react.css'
import { CategorySelect } from '../../../components/ui/form/category-select/CategorySelect'
import Button from '../../../components/ui/panel/button/Button'

export interface SearchInput {
  searchQuery?: string
  discipline?: string
}

interface ResourcesFilterBarProps {
  onSearch(input: SearchInput): void
}

export const ResourcesFilterBar = (props: ResourcesFilterBarProps) => {
  const { onSearch } = props
  const [inputValue, setInputValue] = useState('')
  const [disciplineValue, setDisciplineValue] = useState<unknown>('')
  const handleInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }
  const handleDisciplineChanged = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setDisciplineValue(e.target.value)
  }
  const handleSearch = (
    e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.preventDefault()
    onSearch({
      searchQuery: inputValue,
      discipline: disciplineValue !== '' ? (disciplineValue as string) : undefined
    })
  }

  return (
    <form onSubmit={handleSearch}>
      <Grid container spacing={2} alignItems="center">
        <Grid item sm={7}>
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Search for resources..."
            value={inputValue}
            onChange={handleInputChanged}
          />
        </Grid>
        <Grid item sm={3}>
          <CategorySelect fullWidth value={disciplineValue} onChange={handleDisciplineChanged} />
        </Grid>
        <Grid item sm={2}>
          <Button
            disableElevation
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSearch}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
