import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSurveyInviteQuery, useUpdateStudentSurveyFormMutation } from 'graphql/hooks.generated'
import { Survey } from 'survey-react-ui'
import { Model, ComputedUpdater } from 'survey-core'
import { engLoc, spaLoc } from 'config/constants/translations'
import 'survey-core/modern.min.css'
import 'survey-core/defaultV2.css'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import './survey.css'
import { Step, StepLabel, Stepper, makeStyles } from '@material-ui/core'

interface ActivateAccountParams {
  token: string
  language_id: string
}

export const SurveyForm = () => {
  const params = useParams<ActivateAccountParams>()
  const [thankYouScreen, setThankYouScreen] = useState(false)
  const [youththankYouScreen, setyouthThankYouScreen] = useState<any>()
  const [spanishthankYouScreen, setspanishThankYouScreen] = useState<any>()
  const { data: surveyData, loading, error } = useSurveyInviteQuery({
    variables: { token: params.token }
  })
  const [surveyDatas, setSurveyData] = useState(null)
  useEffect(() => {
    const surveyInvite = surveyData?.surveyInvite

    if (surveyInvite?.[0]?.result_json) setSurveyData(JSON.parse(surveyInvite[0].result_json))
  }, [surveyData])
  const language = params.language_id
  const langcode = surveyData?.surveyInvite?.[0]?.language_id === 1 ? 'en' : 'es'
  const survey = new Model(surveyData?.surveyInvite?.[0]?.form_json)
  const [updateStudentSurvey] = useUpdateStudentSurveyFormMutation()
  const [pageNo, setPageNo] = React.useState(0)
  const isLoading = () => (loading ? <ProgressIndicator fullHeight /> : '')
  const saveFinalise = async () => {
    survey.ignoreValidation = false

    if (survey.completeLastPage())
      updateStudentSurvey({
        variables: {
          token: params.token,
          input: {
            id: String(surveyData?.surveyInvite?.[0]?.form_id),
            result_json: JSON.stringify(survey.data),
            iscompleted: true
          }
        }
      })
  }

  function filterCommonWithValues(array, object) {
    // Filter common keys between the array and the object
    const commonKeys = array.filter((item) => Object.keys(object).includes(item))
    // Map each common key to its corresponding value in the object
    const commonObject = {}
    commonKeys.forEach((key) => {
      commonObject[key] = object[key]
    })

    return commonObject
  }

  const youth = [
    'pageNo',
    'Please start by telling us who you are:',
    'Please select the option that best describes you:',
    'How are you doing? How are things going in your life? Please place a mark on the scale to let us know. The closer to the smiley face, the better things are. The closer to the frowny face, things are not so good.',
    'rating',
    'How true is this for you? Overall, I am doing better than when I started I Matter.',
    'Please indicate how true this statement is for you: Overall, I am doing better than when I started I Matter.',
    'Please rate your experience with your provider so far.',
    'Is there anything else you would like to tell us about your time with I Matter?',
    'Is there anything else you would like to tell us about your experience with I Matter?',
    'How is it going with your provider? Choose the face that best shows how you feel.',
    'If a friend needed help, would you tell them about I Matter?',
    'Would you recommend the I Matter program to a friend?',
    'Para empezar, díganos quién es usted:',
    'question22',
    'question21',
    'Selecciona la opción que mejor te describa:',
    '¿Cómo estás? ¿Cómo van las cosas en tu vida? Por favor, pon una marca en la escala para dejarnos saber. Cuanto más cerca de la carita sonriente, mejor van las cosas. Cuanto más cerca de la carita ceñuda, peor.',
    '¿Cómo te va con tu proveedor? Elige la carita que mejor muestre cómo te sientes.',
    `Ayúdenos a entender cómo te has sentido indicando lo bien que te ha ido en las siguientes áreas de tu vida desde que te inscribiste en Yo Importo, desde ''no me va nada bien'' hasta ''me va muy bien.''`,
    '¿Qué tan cierto es esto para ti ? En general, me va mejor ahora que cuando empecé el programa Yo Importo.',
    '¿Qué tan cierto es esto para ti? En general, me va mejor ahora que cuando empecé el programa Yo Importo.',
    'Por favor, califica tu experiencia con tu proveedor hasta ahora.',
    '¿Hay algo más que quieras contarnos sobre cómo te va con Yo Importo?',
    '¿Hay algo más que quieras contarnos sobre tu experiencia con Yo Importo?',
    'Si un amigo necesitaba ayuda, ¿le hablarías de Yo Importo?',
    '¿Recomendarías el programa Yo Importo a un amigo?'
  ]
  const parent = [
    'Please start by telling us who you are:',
    'Please select the age range that best describes the child participating in I Matter.',
    'Parent ratings',
    'Overall, my child seems to be doing better than when they started I Matter.',
    'Please let us know what would you improve about the I Matter program:',
    'Would you recommend the I Matter program to any others?',
    'Para empezar, díganos quién es usted:',
    'question29',
    'Por favor, seleccione el rango de edad que mejor describe al niño participante en el programa Yo Importo.',
    `Ayúdenos a entender cómo cree que se ha sentido su hijo desde que se inscribió en Yo importo valorando cómo le ha ido en las siguientes áreas de su vida, desde ''no le va nada bien'' hasta ''le va muy bien.''`,
    'En general, parece que a mi hijo le va mejor ahora que cuando empezó Yo Importo.',
    'Por favor, díganos qué mejoraría el programa Yo Importo:',
    '¿Recomendaría el programa Yo Importo a otras personas?'
  ]
  const counseller = [
    'Please start by telling us who you are:',
    'Which of the following best describes about you?',
    'Please select the age range that best describes the child participating in the I Matter.',
    `Help us understand how you think this child has been feeling since they enrolled in I Matter by rating how well they have been doing in the following areas of their life, from ''not doing well at all'' to ''doing very well.''`,
    'Overall, this child seems to be doing better than when they started I Matter.',
    'Please let us know what would you improve about I Matter program:',
    'Would you recommend the I Matter program to others?',
    'Para empezar, díganos quién es usted:',
    'question25',
    'question24',
    'Por favor, seleccione el rango de edad que mejor describa al niño que participa en Yo Importo.',
    '¿Cuál de las siguientes opciones te describe mejor?',
    `Ayúdenos a entender cómo te has sentido indicando lo bien que te ha ido en las siguientes áreas de tu vida desde que te inscribiste en Yo Importo, desde '' no me va nada bien'' hasta ''me va muy bien.''`,
    'En general, parece que a este niño le va mejor ahora que cuando empezó Yo Importo.',
    'Por favor, díganos qué mejoraría el programa Yo Importo :',
    '¿Recomendaría el programa Yo Importo a otras personas ?',
    `Ayúdenos a entender cómo te has sentido indicando lo bien que te ha ido en las siguientes áreas de tu vida desde que te inscribiste en Yo Importo, desde '' no me va nada bien'' hasta ''me va muy bien.''`
  ]
  const saveSurveyData = (survey: any) => {
    const data = { ...survey.data }
    let commonObject: any = {}

    if (
      data['Please start by telling us who you are:'] === '1' ||
      data['Para empezar, díganos quién es usted:'] === '1'
    )
      commonObject = filterCommonWithValues(youth, data)
    if (
      data['Please start by telling us who you are:'] === '2' ||
      data['Para empezar, díganos quién es usted:'] === '2'
    )
      commonObject = filterCommonWithValues(parent, data)
    if (
      data['Please start by telling us who you are:'] === '3' ||
      data['Para empezar, díganos quién es usted:'] === '3'
    )
      commonObject = filterCommonWithValues(counseller, data)

    commonObject.pageNo = survey.currentPageNo
    survey.textUpdateMode = 'onTyping'
    updateStudentSurvey({
      variables: {
        token: params.token,
        input: {
          id: String(surveyData?.surveyInvite?.[0]?.form_id),
          result_json: JSON.stringify(commonObject),
          iscompleted: false
        }
      }
    })
    setSurveyData(commonObject)
  }
  survey.data = surveyDatas
  const prevPage = () => {
    survey.prevPage()
    saveSurveyData(survey)
    setPageNo(survey.currentPageNo)
    window.scrollTo(0, 0)

    // autoSaveSurveyDataButtonClick() // #TODO need to finalize one fix for auto-save fetaure
  }

  if (survey.pageCount > 1)
    survey.addNavigationItem({
      id: 'survey_prev_page',
      title: `${
        surveyData?.surveyInvite?.[0]?.form_template_id === 'd8aece0a-cc2b-11ee-8b15-0e0e2e7ccd91'
          ? 'Back'
          : 'Regresar'
      }`,
      visibleIndex: 49,
      visible: new ComputedUpdater(() => {
        return !survey.isFirstPage
      }),
      css: 'nav-button',
      innerCss: `sd-btn nav-input-${pageNo}`,
      action: () => {
        prevPage()
      }
    })

  const nextPage = () => {
    if (survey.isCurrentPageValid) {
      survey.nextPage()
      saveSurveyData(survey)
      setPageNo(survey.currentPageNo)
      window.scrollTo(0, 0)
    }
  }

  if (survey.pageCount > 0)
    survey.addNavigationItem({
      id: 'survey_next_page',
      // title: 'Next',
      title: `${
        surveyData?.surveyInvite?.[0]?.form_template_id === 'd8aece0a-cc2b-11ee-8b15-0e0e2e7ccd91'
          ? 'Next'
          : 'Próximo'
      }`,
      visibleIndex: 49,
      visible: new ComputedUpdater(() => {
        return !survey.isLastPage
      }),
      css: 'nav-button',
      innerCss: `sd-btn nav-input-${pageNo}`,
      action: () => {
        nextPage()
      }
    })

  survey.render('surveyContainer')
  survey.addNavigationItem({
    id: 'sv-nav-complete',
    css: 'nav-button',
    innerCss: `sd-btn nav-input-${pageNo}`,
    title: `${
      surveyData?.surveyInvite?.[0]?.form_template_id === 'd8aece0a-cc2b-11ee-8b15-0e0e2e7ccd91'
        ? 'Submit'
        : 'entregar'
    }`,
    visibleIndex: 49,
    visible: new ComputedUpdater(() => {
      return survey.isLastPage
    }),
    action: () => {
      saveFinalise()
      setyouthThankYouScreen(survey.data['Please start by telling us who you are:'])
      setspanishThankYouScreen(survey.data['Para empezar, díganos quién es usted:'])
      setThankYouScreen(true)
    }
  })

  if (error)
    return (
      <div className="makeStyles-bodyRoot-2 spinner" style={{ backgroundColor: 'red' }}>
        <div
          style={{
            marginTop: '17%',
            backgroundColor: 'white',
            margin: '15%',
            justifyContent: 'center',
            // width: '50%',
            color: 'black',
            padding: '2%',
            fontFamily: 'poppins'
          }}
        >
          {error.message === 'English Invalid Token'
            ? 'Link Expired.'
            : error.message === 'Spanish Invalid Token'
            ? 'Enlace expirado.'
            : error.message === 'jwt expired' && language === '1'
            ? 'Sorry, this survey has closed. Thank you.'
            : 'Lo sentimos, esta encuesta está cerrada. Muchas gracias.'}
        </div>
      </div>
    )

  const devstylename =
    survey.data['Please select the option that best describes you:'] === '2' &&
    survey.data['Please start by telling us who you are:'] === '1' &&
    pageNo === 5
      ? `innerstyle-${pageNo}-12yrs`
      : survey.data['Please start by telling us who you are:'] === '3' && pageNo === 5
      ? `innerstyle-${pageNo}-c`
      : survey.data['Selecciona la opción que mejor te describa:'] === '2' &&
        survey.data['Para empezar, díganos quién es usted:'] === '1' &&
        pageNo === 5
      ? `innerstyle-${pageNo}-12yrs`
      : survey.data['Para empezar, díganos quién es usted:'] === '3' && pageNo === 5
      ? `innerstyle-${pageNo}-c`
      : `innerstyle-${pageNo}`
  survey.applyTheme({
    cssVariables: {
      backgroundColor:
        pageNo === 0
          ? '#844191'
          : pageNo === 1
          ? '#00de74'
          : pageNo === 2
          ? '#ff51a7'
          : pageNo === 3
          ? '#ff6400'
          : pageNo === 4
          ? '#3e49b5'
          : pageNo === 5
          ? '#ffc22d'
          : pageNo === 6
          ? '#ff1c00'
          : pageNo === 7
          ? '#201057'
          : 'red'
    },
    themeName: 'ContrastLight',
    colorPalette: 'dark',
    isPanelless: false
  })
  const steps = ['', '', '', '', '', '', '']
  const colors = {
    0: '#844191',
    1: '#00de74',
    2: '#ff51a7',
    3: '#ff6400',
    4: '#3e49b5',
    5: '#ffc22d',
    6: '#ff1c00',
    7: '#201057'
  }
  const css = `.MuiFormControlLabel-label {
    font-family: 'Poppins'
    }
    .MuiFormLabel-root.Mui-focused {
    color: #293f70
    }
    .nextBtn {
      color: #fff;
      background-color: ${colors[pageNo]};
      box-shadow: none;
    }
    .nextBtn:hover {
      background-color: ${colors[pageNo]};
    }
    .backBtn {
      border: 1px solid ${colors[pageNo]}
    }
    .backBtn:hover {
      background-color: transparent;
    }
    .MuiRadio-colorPrimary.Mui-checked {
      color: ${colors[pageNo]}
    }
    .MuiStepIcon-root.MuiStepIcon-active {
      color: ${colors[pageNo]}
    }
    .MuiStepIcon-root.MuiStepIcon-completed {
      color: ${colors[pageNo]}
    }
    .MuiCheckbox-colorSecondary.Mui-checked {
      color: ${colors[pageNo]}
    }`

  return (
    <>
      {isLoading()}
      {/* {!loading && !thankYouScreen ? 
      <Survey currentPageNo={pageNo} model={survey} /> : ''} */}
      {!loading && !thankYouScreen && (
        <>
          <div>
            <style>{css}</style>
            <Stepper activeStep={pageNo} alternativeLabel>
              {steps.map((pageNo) => (
                <Step key={pageNo}>
                  <StepLabel>{pageNo}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <div className={devstylename}>
            <Survey currentPageNo={pageNo} model={survey} isLoading={survey.isLoadingFromJson} />
          </div>
        </>
      )}
      {thankYouScreen && (
        <div className="makeStyles-bodyRoot-2" style={{ backgroundColor: '#00de74' }}>
          <div
            className="spinner"
            style={{
              backgroundColor: 'white',
              margin: '15%',
              justifyContent: 'center',
              // width: '50%',
              color: 'black',
              padding: '2%',
              fontFamily: 'poppins'
            }}
          >
            {youththankYouScreen === '1' && langcode === 'en'
              ? engLoc.youthThankYouMessage.text1
              : youththankYouScreen === '2' && langcode === 'en'
              ? engLoc.parentThankYouMessage.text1
              : youththankYouScreen === '3' && langcode === 'en'
              ? engLoc.counsellorMessage.text1
              : spanishthankYouScreen === '1' && langcode === 'es'
              ? spaLoc.youthThankYouMessage.text1
              : spanishthankYouScreen === '2' && langcode === 'es'
              ? spaLoc.parentThankYouMessage.text1
              : spaLoc.counsellorMessage.text1}
          </div>
        </div>
      )}{' '}
    </>
  )
}
