import { Link as MLink, LinkProps as MLinkProps } from '@material-ui/core'
import React from 'react'
import useStyles from './Styles'

type IProps = MLinkProps

const Link: React.FC<IProps> = (props) => {
  const classes = useStyles()

  return <MLink {...props} className={classes.root} />
}

export default Link
