import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import { useSnackbar } from 'notistack'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import { AgencyFormModel, AgencyForm } from '../form/AgencyForm'
import {
  useUpdateAgencyMutation,
  useListAgencyQuery,
  useDeleteAgencyMutation,
  Agency,
  useAgencyQuery,
  useAgencyCheckQuery
} from '../../../../graphql/hooks.generated'
import { UseParamsType } from '../../../../types'

interface UpdateAgencyViewParams extends UseParamsType {
  id: string
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const UpdateAgencyView = () => {
  const history = useHistory()
  const { id } = useParams<UpdateAgencyViewParams>()
  const [updateAgency] = useUpdateAgencyMutation()
  const { data, loading } = useAgencyQuery({
    variables: { id: parseInt(id) },
    fetchPolicy: 'network-only'
  })
  const agency = data?.agency
  const [deleteAgency, { loading: deleteloading }] = useDeleteAgencyMutation()
  const onDelete = async () => {
    await deleteAgency({ variables: { id: parseInt(id) } })
    history.goBack()
  }
  const { data: agencycheck } = useAgencyCheckQuery({
    variables: { agency_id: Number(id) },
    fetchPolicy: 'network-only'
  })
  const val = agencycheck?.agencycheck
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const deleteDialoge = () => {
    return (
      <>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to Delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button onClick={onDelete} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
  const showDialoge = () => {
    return (
      <>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              There are active Providers with this Agency
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
  const initialValues: AgencyFormModel | null = agency
    ? {
        name: agency[0].name,
        address: agency[0].address,
        city: agency[0].city,
        state: agency[0].state,
        zipcode: agency[0].zipcode,
        restriction: agency[0].restriction
      }
    : null

  return (
    <>
      <NavBar title="Update Agency" goBackBtn />
      {(loading || deleteloading) && <ProgressIndicator fullHeight />}
      <PageContainer>
        <AgencyForm
          submitLabel="Save"
          initialValues={initialValues}
          onSubmit={
            (input: AgencyFormModel) =>
              updateAgency({
                variables: {
                  id: parseInt(id),
                  input: { ...input }
                }
              }).then(history.goBack)
            // .catch(() => {
            //   enqueueSnackbar('Agency already exists', {
            //     variant: 'error'
            //   })
            // })
          }
        />
        <Button
          style={{ marginLeft: 259, marginTop: -73 }}
          size="large"
          variant="outlined"
          color="primary"
          onClick={handleClickOpen}
        >
          Delete
        </Button>
        {val === false ? deleteDialoge() : showDialoge()}
      </PageContainer>
    </>
  )
}
