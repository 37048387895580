import { Role, TherapistDiscipline, UserStatus } from 'graphql/hooks.generated'
import { CurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import { makeValidate } from 'mui-rff'
import { UserFormModel } from 'views/private/users/form/UserFormModel'
import * as yup from 'yup'

const therapistRoles = [Role.Therapist, Role.SchoolTherapist]

export const requireRoles = (roles: Role[] | undefined) =>
  therapistRoles.filter((role) => roles?.includes(role)).length > 0

export const TherapistValidator = (user: CurrentUser) =>
  makeValidate(
    yup.object<UserFormModel>({
      streetAddress: yup
        .mixed<string>()
        .label('Street Address')
        .when(
          'roles',
          (roles: Role[] | undefined, streetAddressSchema: yup.MixedSchema<string>) => {
            if (requireRoles(roles)) return streetAddressSchema.required()

            return streetAddressSchema
          }
        ),
      licenseNumber: yup
        .mixed<string>()
        .label('License Number')
        .when(
          'roles',
          (roles: Role[] | undefined, licenseNumberSchema: yup.MixedSchema<string>) => {
            if (requireRoles(roles)) return licenseNumberSchema.required()

            return licenseNumberSchema
          }
        ),
      email: yup.string().email().label('Email address').required(),
      firstName: yup.string().label('FirstName').required(),
      lastName: yup.string().label('LastName').required(),
      roles: yup.array<Role>().required(),
      therapistDiscipline: yup
        .mixed<TherapistDiscipline>()
        .label('Therapist discipline')
        .when(
          'roles',
          (roles: Role[] | undefined, disciplineSchema: yup.MixedSchema<TherapistDiscipline>) => {
            if (requireRoles(roles)) return disciplineSchema.required()

            return disciplineSchema
          }
        ),
      stateLicense: yup
        .mixed<number>()
        .label('State License')
        .when('roles', (roles: Role[] | undefined, stateLicenceSchema: yup.MixedSchema<number>) => {
          if (requireRoles(roles)) return stateLicenceSchema.required()

          return stateLicenceSchema
        }),
      mobilePhone: yup
        .mixed<string>()
        .label('Mobile Phone')
        .when('roles', (roles: Role[] | undefined, mobilePhoneSchema: yup.MixedSchema<string>) => {
          if (requireRoles(roles)) return mobilePhoneSchema.required()

          return mobilePhoneSchema
        }),
      certDate: yup
        .date()
        .label('Cert Date')
        .when('roles', (roles: Role[] | undefined, certDateSchema: yup.DateSchema<Date>) => {
          if (requireRoles(roles)) return certDateSchema.required()

          return certDateSchema
        }),
      certExpireDate: yup
        .date()
        .label('Cert Expire Date')
        .when('roles', (roles: Role[] | undefined, certExpireDateSchema: yup.DateSchema<Date>) => {
          if (requireRoles(roles)) return certExpireDateSchema.required()

          return certExpireDateSchema
        }),
      //   status: yup
      //     .mixed<UserStatus>()
      //     .label('Status')
      //     .when('roles', (roles: Role[] | undefined, statusSchema: yup.MixedSchema<UserStatus>) => {
      //       if (requireRoles(roles)) return statusSchema.required()

      //       return statusSchema
      //     }),
      city: yup
        .mixed<string>()
        .label('City')
        .when('roles', (roles: Role[] | undefined, citySchema: yup.MixedSchema<string>) => {
          if (requireRoles(roles)) return citySchema.required()

          return citySchema
        }),
      zip: yup
        .mixed<string>()
        .label('Zip Code')
        .when('roles', (roles: Role[] | undefined, zipSchema: yup.MixedSchema<string>) => {
          if (requireRoles(roles)) return zipSchema.required()

          return zipSchema
        })
    })
  )
