import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { ServiceListView } from './list/ServiceListView'
import { CreateServiceView } from './create/CreateServicesView'
import { UpdateServiceView } from './update/UpdateServiceView'

export const ServicesRootView = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/create`} component={CreateServiceView} />
      <Route path={`${path}/:id/edit`} component={UpdateServiceView} />
      <Route component={ServiceListView} />
    </Switch>
  )
}
