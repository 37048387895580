import React, { useState } from 'react'
import { useDrag } from 'react-dnd'
import useSound from 'use-sound'
import { CmsAsset } from '../../../../graphql/hooks.generated'
import { DropAreaGame } from './DropArea'

interface DraggableCardProps {
  width?: number
  height?: number
  children?: JSX.Element | JSX.Element[]
  game?: string
  order?: number
  image?: CmsAsset | null | undefined
}

export const DraggableCard = (props: DraggableCardProps) => {
  const { width, height, children, game, order, image } = props
  const [playOn] = useSound('/sounds/bicycle-bell.mp3', { volume: 0.25 })
  const [isVisible, setIsVisible] = useState(true)
  const [doneDragging, setDoneDragging] = useState(false)
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', order, image },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()

      if (item && dropResult) {
        playOn()
        setIsVisible(false)
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    begin: () => {
      setDoneDragging(false)
    }
  })
  const opacity = isDragging && !doneDragging ? 0.3 : 1
  const display = isVisible ? 'block' : 'none'

  if (game === DropAreaGame.dragReveal)
    return (
      <div
        ref={drag}
        style={{
          width,
          height,
          backgroundColor: '#4B99D1',
          borderRadius: 5,
          position: 'relative',
          top: -200,
          zIndex: 999,
          opacity,
          display
        }}
      />
    )

  return <div ref={drag}>{children}</div>
}
