import React, { useMemo, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import {
  useListsurveystudentQuery,
  useListStudentCaseloadQuery,
  useListStudentsProfileQuery,
  ListStudentsProfileQuery
} from 'graphql/hooks.generated'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { UseParamsType } from 'types'
import { useStyles } from '../../../../../../config/constants/theme/profile'
import { PageContainer } from '../../../../../../components/ui/panel/page-container/PageContainer'

interface SurveyParams extends UseParamsType {
  stu_id: string
}

interface Parentparams {
  studentDetails: any
}

export default function SurveyDetails(props: Parentparams) {
  const classes = useStyles()
  const { studentDetails } = props
  const { stu_id } = useParams<SurveyParams>()
  let stud_id: any
  let decode1
  let decode2
  try {
    ;[decode1, decode2] = atob(stu_id).split('_')
  } catch (e) {
    // console.log(e)
  }

  if (decode1 && decode2 && decode1 === decode2) stud_id = decode1

  let pscdata: any[] = []
  let craftdata: any[] = []
  let sdohdata: any[] = []
  // let generaldata: any[] = []
  const youthgeneral: any[] = [
    { questionId: 5, answer: [], questionName: '' },
    { questionId: 6, answer: [], questionName: '' }
  ]
  const parentgeneral: any[] = [
    { questionId: 37, answer: [], questionName: '' },
    { questionId: 38, answer: [], questionName: '' }
  ]
  const sdohans: any[] = []
  const parentsdoh: any[] = [
    { questionId: 58, answer: [], questionName: '' },
    { questionId: 59, answer: [], questionName: '' }
  ]
  const youthsdoh: any[] = [
    { questionId: 56, answer: [] },
    { questionId: 57, answer: [] }
  ]
  // let studentDetails
  const { data: surveystudData, loading: loading1 } = useListsurveystudentQuery({
    fetchPolicy: 'network-only',
    variables: {
      sid: Number(stud_id)
    }
  })
  const { data: studDet, loading: loading2 } = useListStudentCaseloadQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: Number(stud_id)
    }
  })
  // const { data: studentData, loading: studentDataLoading } = useListStudentsProfileQuery({
  //   fetchPolicy: 'network-only',
  //   variables: { id: Number(stud_id) }
  // })
  // if (studentData) studentDetails = studentData?.studentsProfile?.find(() => true)
  // useEffect(() => {
  //   if (studentData) {
  //     const stuobj = studentData?.studentsProfile?.find(() => true)
  //     setuseridHandler(stuobj)
  //   }
  // }, [studentData, setuseridHandler])
  const parentView = () => {
    if (surveystudData && (surveystudData?.surveystudent[4] || surveystudData?.surveystudent[5]))
      pscdata = surveystudData?.surveystudent[4]
    if (surveystudData?.surveystudent[5])
      surveystudData?.surveystudent[5].map((obj) => {
        if (obj.questionId === 58 || obj.questionId === 59)
          sdohdata = surveystudData?.surveystudent[5]
        else pscdata = surveystudData?.surveystudent[5]

        return true
      })
    if (surveystudData && surveystudData?.surveystudent[3])
      surveystudData?.surveystudent[3].map((data) => {
        if (data?.questionId === 37) {
          parentgeneral[0].answer.push(data?.choiceEnText)
          parentgeneral[0].questionName = data?.questionName
        }
        if (data?.questionId === 38) {
          parentgeneral[1].answer.push(data?.choiceEnText)
          parentgeneral[1].questionName = data?.questionName
        }

        return true
      })
    if (surveystudData && surveystudData?.surveystudent[4])
      surveystudData?.surveystudent[4].map((data) => {
        if (data?.questionId === 5) {
          youthgeneral[0].answer.push(data?.choiceEnText)
          youthgeneral[0].questionName = data?.questionName
        }
        if (data?.questionId === 6) {
          youthgeneral[1].answer.push(data?.choiceEnText)
          youthgeneral[1].questionName = data?.questionName
        }

        return true
      })
    if (surveystudData && surveystudData?.surveystudent[7])
      craftdata = surveystudData?.surveystudent[7]
    if (surveystudData && surveystudData?.surveystudent[6])
      sdohdata = surveystudData?.surveystudent[6]
    if (sdohdata && sdohdata.length)
      sdohdata.map((data) => {
        if (data?.questionId === 58) {
          parentsdoh[0].answer.push(data?.choiceEnText)
          parentsdoh[0].questionName = data?.questionName
        }
        if (data?.questionId === 59) {
          parentsdoh[1].answer.push(data?.choiceEnText)
          parentsdoh[1].questionName = data?.questionName
        }
        if (data?.questionId === 56) {
          youthsdoh[0].answer.push(data?.choiceEnText)
          youthsdoh[0].questionName = data?.questionName
        }
        if (data?.questionId === 57) {
          youthsdoh[1].answer.push(data?.choiceEnText)
          youthsdoh[1].questionName = data?.questionName
        }

        return true
      })

    return (
      <>
        {(loading1 || loading2) && <ProgressIndicator fullHeight />}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.namecard}>
              <div>
                <Avatar style={{ float: 'left' }} className={classes.avatar}>
                  {studentDetails &&
                    studentDetails?.name?.split(/[\s]+/).map((value) => value.slice(0, 1))}
                </Avatar>
                <div style={{ float: 'left', padding: '10px', fontFamily: 'Poppins' }}>
                  {studentDetails && (
                    <>
                      <span
                        style={{
                          fontWeight: 'bold',
                          color: '#F1583A',
                          fontSize: '22px',
                          fontFamily: 'Brandon Grotesque'
                        }}
                      >
                        {studentDetails?.name}
                      </span>
                      <div style={{ fontFamily: 'Brandon Grotesque' }}>
                        {moment().diff(moment(studentDetails?.birth_date), 'years', false)} years
                        old
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={classes.session}>
                <div>
                  <strong className={classes.bluelabel}>
                    PSC Score:
                    <strong className={classes.bluelabel}>
                      {studDet?.student?.psc_score || 0}
                    </strong>
                  </strong>
                </div>
                <div className={classes.namelabel}>
                  <strong className={classes.bluelabel}>
                    CRAFFT Score:
                    <strong className={classes.bluelabel}>
                      {studDet?.student?.craft_score || 0}
                    </strong>
                  </strong>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.straightlineStyle} />
          </Grid>
        </Grid>
        <PageContainer>
          <Grid className={classes.makeStylesRoot1258} item md={12}>
            <div className={classes.sessionSurvey}>
              <h3 className={classes.nameSdh}>Survey Results</h3>
            </div>
          </Grid>
          <Grid className={classes.surveyBorder} container spacing={0}>
            <Grid item md={4}>
              <div className={classes.nameSd}>
                <p>
                  <u>Selected ``sometimes`` in the PSC survey</u>
                </p>
                {pscdata &&
                  pscdata?.length > 0 &&
                  pscdata.map((item) => (
                    <>
                      {(item?.score === '1' || item?.score === 1) && (
                        <div className={classes.nameSd}>{item?.questionName}</div>
                      )}
                    </>
                  ))}
              </div>
            </Grid>
            <Grid item md={4}>
              <div className={classes.nameSd}>
                <p>
                  <u>Selected ``Often`` in the PSCsurvey</u>
                </p>
                {pscdata &&
                  pscdata?.length > 0 &&
                  pscdata.map((item) => (
                    <>
                      {(item?.score === '2' || item?.score === 2) && (
                        <div className={classes.nameSd}>{item?.questionName}</div>
                      )}
                    </>
                  ))}
              </div>
            </Grid>
            <Grid item md={4}>
              <div className={classes.nameSd}>
                <p>
                  <u>Social Determinants of Health</u>
                </p>
                {parentsdoh &&
                  parentsdoh?.length > 0 &&
                  parentsdoh.map((item: any) => (
                    <>
                      <div>{item?.questionName}</div>
                      <div>
                        {item?.answer?.map((ans) => (
                          <div>--{ans}</div>
                        ))}
                      </div>
                    </>
                  ))}
                {youthsdoh &&
                  youthsdoh?.length > 0 &&
                  youthsdoh.map((item: any) => (
                    <>
                      <div>{item?.questionName}</div>
                      <div>
                        {item?.answer?.map((ans) => (
                          <div>--{ans}</div>
                        ))}
                      </div>
                    </>
                  ))}
              </div>
            </Grid>
            <Grid item md={12}>
              <div className={classes.nameSd}>
                <p>
                  <u>General Questions:</u>
                </p>
                {parentgeneral &&
                  parentgeneral?.length > 0 &&
                  parentgeneral.map((item: any) => (
                    <>
                      <div>
                        <span>{item?.questionName} </span>
                        <span>
                          {item?.answer?.map((ans) => (
                            <span>
                              {' '}
                              <b>--{ans}</b>
                            </span>
                          ))}
                        </span>
                      </div>
                    </>
                  ))}
                {youthgeneral &&
                  youthgeneral?.length > 0 &&
                  youthgeneral.map((item: any) => (
                    <>
                      <div>
                        <span>{item?.questionName}</span>
                        <span>
                          {item?.answer?.map((ans) => (
                            <span>
                              {' '}
                              <b>--{ans}</b>
                            </span>
                          ))}
                        </span>
                      </div>
                    </>
                  ))}
              </div>
            </Grid>
            {craftdata && craftdata?.length > 0 && (
              <Grid item md={12}>
                <div className={classes.nameSd}>
                  <p>
                    <u>CRAFFT Survey Results:</u>
                  </p>
                  {craftdata.map((item) => (
                    <>
                      <div className={classes.nameSd}>
                        {item?.questionName}
                        {(item.questionId === 24 ||
                          item.questionId === 25 ||
                          item.questionId === 26) && (
                          <span>
                            {' '}
                            <b>--{Number(item?.score)}</b>
                          </span>
                        )}
                        {item.questionId !== 24 &&
                          item.questionId !== 25 &&
                          item.questionId !== 26 && (
                            <span>
                              {' '}
                              <b>--{Number(item?.score) > 0 ? 'YES' : 'NO'}</b>
                            </span>
                          )}
                      </div>
                    </>
                  ))}
                </div>
              </Grid>
            )}
          </Grid>
        </PageContainer>
      </>
    )
  }

  return <div>{stu_id && parentView()}</div>
}
