import React, { useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { useAdminCountsLazyQuery, useCurrentUserQuery } from 'graphql/hooks.generated'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import useStyles from '../Styles'

export interface TextParams {
  name: any
  value: any
}

const GridMetrics = (props: TextParams) => {
  const classes = useStyles()
  const { name, value } = props

  return (
    <Grid item xs={4} className={classes.columnOne}>
      <div className={classes.colSpanNew}>{name}</div>
      <h1 className={classes.colSpanNum}>{value}</h1>
    </Grid>
  )
}

export default function AdminAutoGrid() {
  const classes = useStyles()
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const [
    executeAdminCountsQuery,
    { data: adminCounts, loading: adminctloading }
  ] = useAdminCountsLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetchAdminCountsQuery = useMemo(
    () => ({ user_id }) =>
      executeAdminCountsQuery({
        variables: { user_id }
      }),
    [executeAdminCountsQuery]
  )
  useEffect(() => {
    if (currentUserdata) fetchAdminCountsQuery({ user_id: currentUserdata?.data?.currentUser?.id })
  }, [fetchAdminCountsQuery, currentUserdata])

  return (
    <div style={{ minHeight: '150px' }}>
      {adminCounts && (
        <>
          <Paper className={classes.paperNew}>
            <GridMetrics
              name="Clients on Waitlist Assigned to You"
              value={adminCounts?.adimncount?.[0]?.wailistcilent || 0}
            />
            <GridMetrics
              name="Resources/Referral Request Assigned to You"
              value={adminCounts?.adimncount?.[0]?.referral_assigned || 0}
            />
            <GridMetrics
              name="Additional Session Requests Assigned to You"
              value={adminCounts?.adimncount?.[0]?.future_assigned || 0}
            />
          </Paper>
          <Paper className={classes.paperNew}>
            <GridMetrics
              name="New Clients to Waitlist in the Last 7 Days"
              value={adminCounts?.adimncount?.[0]?.waitlist || 0}
            />
            <GridMetrics
              name="Clients Exceeding 6 Sessions in Program"
              value={adminCounts?.adimncount?.[0]?.['6sessions'] || 0}
            />
            <GridMetrics
              name="Clients Missing 2 or More Sessions in the Last 30 Days"
              value={adminCounts?.adimncount?.[0]?.['2absent'] || 0}
            />
          </Paper>
        </>
      )}
      {/* <ProgressIndicator position="relative" /> */}
      {adminctloading && <ProgressIndicator position="relative" />}
    </div>
  )
}
