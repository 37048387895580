import React from 'react'
import { Select, MenuItem, SelectProps } from '@material-ui/core'
import {
  CmsDiscipline,
  GetDisciplinesQueryResult,
  useGetDisciplinesQuery
} from '../../../../graphql/hooks.generated'

type CategorySelectProps = SelectProps

export const CategorySelect = (props: CategorySelectProps) => {
  const { data: disciplinesData }: GetDisciplinesQueryResult = useGetDisciplinesQuery()

  return (
    <Select
      displayEmpty
      variant="outlined"
      {...props}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        transformOrigin: { vertical: 'top', horizontal: 'center' }
      }}
    >
      <MenuItem value="">
        <em>Category</em>
      </MenuItem>
      {disciplinesData?.GCMS_disciplines.map((discipline: Pick<CmsDiscipline, 'id' | 'name'>) => (
        <MenuItem key={discipline.id} value={discipline.id}>
          {discipline.name}
        </MenuItem>
      ))}
    </Select>
  )
}
