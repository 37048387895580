import React from 'react'
import { Box, Typography } from '@material-ui/core'
import Paper from '../../../panel/paper/Paper'
import useStyles, { MetricCardStyleProps } from './Styles'

export interface MetricCardProps extends MetricCardStyleProps {
  metricNumber: number
  summary: string
  title: string
  cardFooter?: JSX.Element
}

const MetricCard = ({
  cardFooter,
  backgroundColor,
  metricNumber,
  title,
  summary
}: MetricCardProps) => {
  const classes = useStyles({ backgroundColor })

  return (
    <Paper square={false}>
      <Box p={5} display="flex">
        <Box
          mr={2}
          className={classes.metricNumber}
          borderRadius="8px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="64px"
          width="64px"
        >
          {metricNumber || 0}
        </Box>
        <Box>
          <Typography gutterBottom variant="h5" color="secondary">
            {title}
          </Typography>
          <Typography variant="subtitle1">{summary}</Typography>
        </Box>
      </Box>
      {cardFooter ? (
        <Box py={3} px={5} className={classes.cardFooter}>
          {cardFooter}
        </Box>
      ) : (
        ''
      )}
    </Paper>
  )
}

export default MetricCard
