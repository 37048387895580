import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { OngoingCareListView } from './list/OngoingCareListView'

export const OngoingRootView = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      {/* <Route path={`${path}/create`} component={CreateAgencyView} />
      <Route path={`${path}/:id/edit`} component={UpdateAgencyView} /> */}
      <Route component={OngoingCareListView} />
    </Switch>
  )
}
