import { Box, Typography, Grid } from '@material-ui/core'
import { ArrowForwardOutlined as ArrowForwardIcon } from '@material-ui/icons'
import React, { useState, KeyboardEvent } from 'react'
import { useHistory } from 'react-router-dom'
import GraphImg from 'graphcms-image'
import useSound from 'use-sound'
import { Progress } from 'react-sweet-progress'
import { PageContainer } from '../../../components/ui/panel/page-container/PageContainer'
import 'react-sweet-progress/lib/style.css'
import { ResourceCard } from '../components/ResourceCard'
import { ResourceQuery } from '../../../graphql/hooks.generated'
import { CmsResourceContentType } from '../../../graphql/enumTypes'

interface COGameViewProps {
  gameResource: ResourceQuery['GCMS_resource']
}

export const COGameView = (props: COGameViewProps) => {
  const { gameResource } = props

  if (gameResource?.content?.__typename !== CmsResourceContentType.CoGame)
    throw Error('Unexpected type of Resource content')

  const [answerChosen, setAnswerChosen] = useState<string | null>(null)
  const [numberQuestion, setNumberQuestion] = useState(0)
  const [gameDone, setGameDone] = useState(false)
  const history = useHistory()
  const { title, related, content } = gameResource
  const questions = content?.questions || []
  const numQuestions = questions.length
  const [playOn] = useSound('/sounds/bicycle-bell.mp3', { volume: 0.25 })
  const chooseAnswer = (answer) => {
    setAnswerChosen(answer.id)

    if (answer.isCorrect) playOn()
  }
  const nextQuestion = () => {
    if (numberQuestion < numQuestions - 1) setNumberQuestion(numberQuestion + 1)
    else setGameDone(true)
  }
  const nextQuestionKeyPressed = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.charCode === 13) nextQuestion()
  }
  const onView = (resource: ResourceQuery['GCMS_resource']) => {
    // @todo: need to update this to deal with videos
    return history.push(`/resources/${resource?.id}`)
  }

  return (
    <PageContainer>
      <Box my={3}>
        <Typography gutterBottom variant="h1" color="secondary">
          {title}
        </Typography>
      </Box>
      {!gameDone && (
        <>
          <Box>
            <Progress percent={((numberQuestion + 1) / numQuestions) * 100} />
          </Box>
          <Box display="flex" flexDirection="row-reverse">
            <div
              style={{ display: 'flex', flexDirection: 'row' }}
              onKeyPress={nextQuestionKeyPressed}
              onClick={nextQuestion}
              role="button"
              tabIndex={0}
            >
              <Typography variant="subtitle1" color="secondary">
                Next
              </Typography>
              <ArrowForwardIcon />
            </div>
          </Box>

          <Box my={3}>
            <Typography gutterBottom variant="h4" color="secondary">
              {questions[numberQuestion].question}
            </Typography>
          </Box>
          <Grid container spacing={8}>
            {questions[numberQuestion].answers.map((answer) => (
              <Grid key={answer.id} item sm={6} md={3} onClick={() => chooseAnswer(answer)}>
                <GraphImg image={answer.image} maxHeight={400} />
                {answerChosen === answer.id && (
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      marginTop: 20
                    }}
                  >
                    {answer.isCorrect ? 'Correct!' : 'Try Again!'}
                  </div>
                )}
              </Grid>
            ))}
          </Grid>
        </>
      )}
      {gameDone && (
        <>
          <Box my={3}>
            <Typography gutterBottom variant="h4" color="secondary">
              {`Congratulations! You've finished ${title}. You might want to check out some of these other activities.`}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {related?.map((resource) => (
              <Grid key={resource.id} item sm={12} md={4}>
                <ResourceCard
                  title={resource.title}
                  image={resource.coverImage}
                  onView={() => onView(resource as ResourceQuery['GCMS_resource'])}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </PageContainer>
  )
}
