import React, { PropsWithChildren } from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import Artboard from '../../../assets/icons/artboard.svg'

const useStyle = makeStyles(({ palette }: Theme) => ({
  root: {
    backgroundImage: `url(${Artboard})`,
    backgroundColor: palette.background.default,
    backgroundBlendMode: 'multiply',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }
}))
const ArtboardBG = (props: PropsWithChildren<unknown>) => {
  const { root } = useStyle()
  const { children } = props

  return <div className={root}>{children}</div>
}

export default ArtboardBG
