import { id } from 'date-fns/locale'
import { useCurrentUserQuery, Role } from '../../hooks.generated'
import { useLogout } from '../useLogout/useLogout'

export interface CurrentUser {
  id: number
  email: string
  roles: Role[]
  district: number
  hasRole(role: Role): boolean
  hasAnyRole(...roles: Role[]): boolean
}

export const useCurrentUser = () => {
  const logout = useLogout()
  const queryResult = useCurrentUserQuery({ fetchPolicy: 'cache-first' })
  const { data } = queryResult
  const userRoles = data?.currentUser.roles ?? []
  const hasRole = (role: Role) => {
    return userRoles.filter((userRole) => userRole === role).length !== 0
  }
  const hasAnyRole = (...roles: Role[]) =>
    userRoles.filter((userRole) => roles.includes(userRole)).length !== 0

  if (data && data?.currentUser?.district?.id) {
    const disId = data?.currentUser?.district?.id
    // const userId = data?.currentUser?.id
    localStorage.setItem('districtId', disId)
    // localStorage.setItem('userId', userId)
  }

  return {
    ...queryResult,
    logout,
    // TODO typescript error
    user: ({
      ...data?.currentUser,
      hasRole,
      hasAnyRole
    } as unknown) as CurrentUser,
    hasRole,
    hasAnyRole
  }
}
