import React, { ChangeEvent, ReactNode } from 'react'
import { Field, FieldRenderProps, FieldProps } from 'react-final-form'
import TextField, { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField'
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteRenderInputParams as MuiAutocompleteRenderInputParams
} from '@material-ui/lab/Autocomplete'
import { showError } from '../util/showError'

export type AutocompleteOption = {
  id?: string | undefined
  name?: string | undefined
}

type OmittedAutocompleteProps<T> = Partial<
  MuiAutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>
>

export interface AutocompleteProps<TFieldValueType>
  extends OmittedAutocompleteProps<TFieldValueType> {
  name: string
  label: ReactNode
  helperText?: string
  required?: boolean
  multiple?: boolean
  getOptionValue?: (option: TFieldValueType) => TFieldValueType | string | null | undefined
  getOptionLabel?: (options: TFieldValueType) => string
  fieldProps?: Partial<FieldProps<TFieldValueType, FieldRenderProps<TFieldValueType>>>
  textFieldProps?: Partial<MuiTextFieldProps>
}
interface AutocompleteWrapperProps extends FieldRenderProps<AutocompleteOption, HTMLElement> {
  label: ReactNode
  required?: boolean
  multiple?: boolean
  textFieldProps?: Partial<AutocompleteOption>
  getOptionValue?: (option: AutocompleteOption) => AutocompleteOption | string | null | undefined
  getOptionLabel?: (options: AutocompleteOption) => string
}

const AutocompleteWrapper = (props: AutocompleteWrapperProps) => {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    options,
    label,
    required,
    multiple,
    textFieldProps,
    getOptionValue,
    ...rest
  } = props

  // @todo: refactor searching of defaultValue for separated methods
  function getValue(values: AutocompleteOption | Array<AutocompleteOption>) {
    if (!getOptionValue) return values
    if (multiple && Array.isArray(values)) return values.map(getOptionValue)
    if (!multiple && !Array.isArray(values)) return getOptionValue(values)

    return null
  }

  const { helperText, ...lessrest } = rest
  const { ...restTextFieldProps } = textFieldProps || {}
  let defaultValue: AutocompleteOption | Array<AutocompleteOption> | null = multiple ? [] : null

  if (!getOptionValue) defaultValue = value
  else if (value !== null)
    options.forEach((option: AutocompleteOption) => {
      const optionValue: AutocompleteOption | string | null | undefined = getOptionValue(option)

      if (multiple && Array.isArray(value))
        value.forEach((v: AutocompleteOption | string | null | undefined) =>
          v === optionValue && Array.isArray(defaultValue) ? defaultValue?.push(option) : null
        )
      else if (value === optionValue) defaultValue = option
    })

  const onChangeFunc = (_e: ChangeEvent<Record<string, unknown>>, values: unknown | unknown[]) =>
    onChange(getValue(values as AutocompleteOption | AutocompleteOption[]))
  const { error, submitError } = meta
  const isError = showError<AutocompleteOption>({ meta })

  return (
    <MuiAutocomplete
      multiple={multiple}
      onChange={onChangeFunc}
      options={options}
      value={defaultValue}
      renderInput={(params: MuiAutocompleteRenderInputParams) => (
        <TextField
          label={label}
          required={required}
          helperText={isError ? error || submitError : helperText}
          error={isError}
          name={name}
          {...params}
          {...restTextFieldProps}
          {...restInput}
        />
      )}
      {...lessrest}
    />
  )
}

export const Autocomplete = (props: AutocompleteProps<AutocompleteOption>) => {
  const { name, fieldProps, ...rest } = props

  return (
    <Field
      name={name}
      render={(fieldRenderProps) => <AutocompleteWrapper {...fieldRenderProps} {...rest} />}
      {...fieldProps}
    />
  )
}
