/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react'
import { Box } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useCurrentUser } from '../../../../graphql/hooks/useCurrentUser/useCurrentUser'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import {
  useListCaseloadLazyQuery,
  useCurrentUserQuery,
  useDeleteCaseloadMutation,
  useUserListDistrictsForCaseloadLazyQuery,
  useRemoveClientMutation,
  useAddClientMutation,
  useProviderNameListLazyQuery,
  Role
} from '../../../../graphql/hooks.generated'
import { SearchFilterData } from '../../../redux/searchFilterRedux/actions/searchFilterActions'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import DetailTemplate from './detail'

// class Caseload extends React.Component
const Caseload = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { state } = location
  const [providername, setProvidername] = useState('')
  const { hasAnyRole } = useCurrentUser()
  const storedata = useSelector((state) => state)
  const [therapistName, setTherapistName] = useState('')
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const [
    executeThepistQuery,
    { data: therapistData, loading: therapistDocumentLoading }
  ] = useProviderNameListLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      agency: String(
        currentUserdata?.data?.currentUser?.signalAgencies &&
          currentUserdata?.data?.currentUser?.signalAgencies[0]?.name
          ? currentUserdata?.data?.currentUser?.signalAgencies[0]?.name
          : 0
      )
    }
  })
  const fetchTherapists = useMemo(() => () => executeThepistQuery(), [executeThepistQuery])
  useEffect(() => {
    fetchTherapists()
  }, [fetchTherapists])
  const [studentDataListQuery, { data, loading: listLoading }] = useListCaseloadLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [userDataListQuery, { data: userdata }] = useUserListDistrictsForCaseloadLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetchStudentListData = useMemo(
    () => ({ user_id }) => studentDataListQuery({ variables: { user_id } }),
    [studentDataListQuery]
  )
  const fetchUserListData = useMemo(
    () => ({ user_id }) => userDataListQuery({ variables: { user_id } }),
    [userDataListQuery]
  )
  const [deleteCaseLoadMutation, { loading: delLoading }] = useDeleteCaseloadMutation()
  const [removeStudent, { loading: removeClient }] = useRemoveClientMutation()
  const [addStudent, { loading: addClient }] = useAddClientMutation()
  useEffect(() => {
    const provider = therapistData?.providerName?.[0]?.provider_id
    const rolesName = currentUserdata?.data?.currentUser?.roles?.[0]

    if (state?.length > 0) setProvidername(state)
    else if (rolesName === Role.AgencySupervisor) setProvidername(provider)
    else setProvidername(currentUserdata?.data?.currentUser?.id)
  }, [currentUserdata, therapistData?.providerName, state])
  useEffect(() => {
    if (props.match.params.userId) {
      const input = {
        user_id: props.match.params.userId
      }
      fetchStudentListData(input)
      fetchUserListData(input)
    } else if (currentUserdata) {
      const input = {
        user_id: providername
      }
      fetchStudentListData(input)
      fetchUserListData(input)
    }
  }, [
    currentUserdata,
    providername,
    fetchStudentListData,
    fetchUserListData,
    props.match.params.userId,
    props
  ])
  // useEffect(() => {
  //   if (storedata.caseloadid && props.match.params.userId !== storedata.caseloadid)
  //     dispatch(SearchFilterData({ ...storedata, caseloadlistq: '', caseloadid: '' }))
  // }, [props.match.params.userId, dispatch, storedata])
  const handleRefresh = () => {
    const id = Object.keys(props.match.params).length ? props.match.params.userId : providername
    const input = {
      user_id: id
    }
    fetchStudentListData(input)
  }
  const deleteCaseload = (id) => {
    deleteCaseLoadMutation({ variables: { id } })
      .then(() => {
        handleRefresh()
      })
      .catch((error) => {
        // console.log(error)
      })
  }
  const removeClientFromCaseload = (props) => {
    removeStudent({
      variables: {
        user_id: props.user_id,
        student_id: props.student_id
      }
    }).then(() => handleRefresh())
  }
  const addClientTOCaseload = (props) => {
    addStudent({
      variables: {
        user_id: props.user_id,
        student_id: props.student_id
      }
    }).then(() => handleRefresh())
  }
  const selecteName = new URLSearchParams(props.location.search).get('name')
  const dispatchStore = (stateobj) => {
    dispatch(SearchFilterData(stateobj))
  }

  return (
    <div>
      <NavBar
        className="container-adjust"
        title={`Caseload ${selecteName ? `of ${selecteName}` : ''}`}
        goBackBtn
      />
      <PageContainer>
        <Box py={3} display="flex" alignItems="center" justifyContent="space-between">
          {listLoading || delLoading || removeClient || addClient ? (
            <ProgressIndicator fullHeight />
          ) : (
            ''
          )}
          {/* <AppBar position="static" className="app-background">
        <Toolbar className="app-tool">
          <div>
          <Typography className="total-head"  noWrap>
            Total Students
          </Typography>
          <Typography className={classes.title} variant="h2" noWrap>
           2
          </Typography>
          </div>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon className="search-clr" />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
          <div style={{display:"flex",alignItems:"center"}}>
            <div><p className="sort">Sort by:</p></div>
           <div>
          <FormControl className={classes.formControl}>
        <NativeSelect
          className={classes.selectEmpty}
          value={state.age}
          name="age"
          onChange={handleChange}
          inputProps={{ 'aria-label': 'age' }}
        >
          <option value="" disabled>
            Created At:From Newest
          </option>
          <option value={10}>Created At:From Newest</option>
          <option value={20}>FullName:A-Z</option>
          <option value={30}>FullName:Z-A</option>
        </NativeSelect>
       
      </FormControl>
      </div>
          </div>
          <div>
            <Button variant="contained"startIcon={<FlashOnIcon />} className="orange-btn">Quick Start</Button>
          </div>
         
          <div>
          <Button variant="contained" className="blue-btn" startIcon={<PersonAddIcon />}>Add Student</Button>
          </div>
         
        </Toolbar>
      </AppBar> */}
          {/* {data  && props && (Object.keys(props.match.params).length)
             && (<DetailTemplate props={props} caseloadData={data}  
             handleRefresh={handleRefresh} deleteCaseload={deleteCaseload}/>)} */}
          {/* {data && userdata && props && !(Object.keys(props.match.params).length) && (<DetailTemplate props={props} 
            caseloadData={data} userdata={userdata} handleRefresh={handleRefresh} 
            deleteCaseload={deleteCaseload}/>)} */}

          {data && userdata && currentUserdata && Object.keys(props.match.params).length > 0 && (
            <DetailTemplate
              key={Math.floor(Math.random() * 100)}
              isTherapist={false}
              props={props}
              caseloadData={data}
              userdata={userdata}
              currentUserData={currentUserdata}
              therapistData={therapistData}
              providername={providername}
              setProvidername={setProvidername}
              hasAnyRole={hasAnyRole}
              Role={Role}
              handleRefresh={handleRefresh}
              deleteCaseload={deleteCaseload}
              removeClientFromCaseload={removeClientFromCaseload}
              addClientTOCaseload={addClientTOCaseload}
              storedata={storedata}
              dispatchStore={dispatchStore}
            />
          )}
          {data && currentUserdata && !Object.keys(props.match.params).length > 0 && (
            <DetailTemplate
              key={Math.floor(Math.random() * 100)}
              isTherapist
              props={props}
              caseloadData={data}
              userdata={userdata}
              therapistData={therapistData}
              providername={providername}
              Role={Role}
              setProvidername={setProvidername}
              hasAnyRole={hasAnyRole}
              currentUserData={currentUserdata}
              handleRefresh={handleRefresh}
              deleteCaseload={deleteCaseload}
              removeClientFromCaseload={removeClientFromCaseload}
              addClientTOCaseload={addClientTOCaseload}
              storedata={storedata}
              dispatchStore={dispatchStore}
            />
          )}
        </Box>
      </PageContainer>
    </div>
  )
}

export default Caseload
