import React, { useState } from 'react'
import { styled, Theme } from '@material-ui/core/styles'
import MenuBar from './components/MenuBar/MenuBar'
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens'
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification'
import RecordingNotifications from './components/RecordingNotifications/RecordingNotifications'
import Room from './components/Room/Room'
import useHeight from './hooks/useHeight/useHeight'
import useRoomState from './hooks/useRoomState/useRoomState'

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
  marginTop: '4.09rem'
})
const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `72 px`, // Leave some space for the footer
  background: 'black',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${56 + 52}px` // Leave some space for the mobile header and footer
  }
}))

interface VideoSessionProps {
  authToken: string
}

export default function VideoSession({ authToken }: VideoSessionProps) {
  const roomState = useRoomState()
  const [clientdata, setClientdata] = useState<any>(undefined)
  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight()

  return (
    <Container style={{ height, width: '100%' }}>
      {roomState === 'disconnected' ? (
        <PreJoinScreens authToken={authToken} />
      ) : (
        <Main>
          <ReconnectingNotification />
          <RecordingNotifications />
          <Room setClientdata={setClientdata} />
          <MenuBar clientdata={clientdata} />
        </Main>
      )}
    </Container>
  )
}
