export const palette = {
  primary: {
    main: '#F1583A'
  },
  secondary: {
    main: '#0D1F48'
  },
  background: {
    default: '#F7F7F7'
  },
  info: {
    main: '#4B99D1'
  },
  text: {
    main: '#4D4D4D',
    secondary: '#AAAAAA'
  }
}

// css
export const customPalette = {
  blue: {
    light: '#4B99D1',
    main: '#0D1F48'
  },
  green: {
    main: '#24CB9A'
  },
  yellow: {
    main: '#ECB157'
  },
  red: {
    main: '#DB330F'
  }
}
