/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Typography,
  Box,
  Icon,
  AppBar as MAppBar,
  AppBarProps,
  Toolbar,
  List,
  ListItem,
  Link,
  Button,
  Menu,
  MenuItem,
  Select
} from '@material-ui/core'
import { config as dotenv } from 'dotenv'
import { HelpOutline as HelpIcon, CheckBoxOutlined as OnboardingIcon } from '@material-ui/icons'
import { tourRunStatusVar } from 'graphql/vars'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import ChangePasswordForm from 'views/useractions/changepassword/ChangePasswordForm'
import { Role } from 'graphql/hooks.generated'
import LoggedUser from 'components/ui/auth/logged-user/LoggedUser'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { UseParamsType } from 'types'
import { Conversation, Client, Message, Paginator } from '@twilio/conversations'
import axios from 'axios'
import { getToken, getWaitList } from 'views/private/chatapp/api'
import { actionCreators, AppState } from 'views/private/chatapp/store'
import { SetUreadMessagesType } from 'views/private/chatapp/types'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import IconButton from '../icon-button/IconButton'
import SettingsMenu, { SettingsItem } from '../settings-menu/SettingsMenu'
import useStyles from './Styles'
import { RouterLink } from '../link/RouterLink'

// import { ReactComponent as Logo } from '../../../../assets/icons/tt-logo.svg'

interface ListTokenParams extends UseParamsType {
  loc: string
}

type IProps = AppBarProps

async function loadUnreadMessagesCount(
  convo: Conversation,
  updateUnreadMessages: SetUreadMessagesType
) {
  let count = 0

  try {
    count = (await convo.getUnreadMessagesCount()) ?? (await convo.getMessagesCount())
  } catch (e) {
    console.error('getUnreadMessagesCount threw an error', e)
  }

  updateUnreadMessages(convo.sid, count)
}

const { REACT_APP_IMATTER_HOME_URL, REACT_APP_IMATTER_HOME_ES_URL } = process.env

async function updateConvoList(
  conversation: Conversation,
  updateUnreadMessages: SetUreadMessagesType
) {
  loadUnreadMessagesCount(conversation, updateUnreadMessages)
}

const AppBar: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { data } = useCurrentUser()
  const { loc } = useParams<ListTokenParams>()
  const history = useHistory()
  const isPublicpath = history.location.pathname.includes('/public/')
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const { updateUnreadMessages, login, updateClient } = bindActionCreators(actionCreators, dispatch)
  const [chatTotCount, setchatTotCount] = useState(0)
  const [isNewMesAlert, setisNewMesAlert] = useState(false)
  const [displayChat, setDisplayChat] = useState(false)
  const sid = useSelector((state: AppState) => state.sid)
  const unreadMessages = useSelector((state: AppState) => state.unreadMessages)
  const client = useSelector((state: AppState) => state.client)
  const sidRef = useRef('')
  sidRef.current = sid
  // #TODO for future development start

  async function getSubscribedConversations(client: Client): Promise<Conversation[]> {
    let subscribedConversations = await client.getSubscribedConversations()
    let conversations = subscribedConversations.items

    while (subscribedConversations.hasNextPage) {
      // eslint-disable-next-line no-restricted-syntax,no-await-in-loop
      subscribedConversations = await subscribedConversations.nextPage()
      conversations = [...conversations, ...subscribedConversations.items]
    }
    const filteredConversations = conversations.filter((val: any) => val?.lastMessage?.index >= 0)
    await Promise.all(
      filteredConversations.map(async (conversation: Conversation) => {
        try {
          await updateConvoList(conversation, updateUnreadMessages)
        } catch (e) {
          console.log(e)
        }
      })
    )

    return filteredConversations
  }

  useEffect(() => {
    let count
    count = 0

    if (unreadMessages && Object.entries(unreadMessages).length > 0) {
      Object.entries(unreadMessages).forEach(([key, value]) => {
        count += value
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      count = count > 99 ? '99+' : count
      setchatTotCount(count)
      setDisplayChat(true)
    } else setDisplayChat(true)
  }, [setchatTotCount, unreadMessages])
  // #TODO for future development end
  useMemo(() => {
    const params = new URLSearchParams(window.location.search)

    if (i18n?.language === 'en' || i18n?.language === 'es')
      i18n.changeLanguage(i18n?.language || 'en')
    else i18n.changeLanguage('en')
  }, [i18n])
  const { data: currentUserData } = useCurrentUser()
  const navlinks = useMemo(() => {
    const userRoles = currentUserData?.currentUser.roles

    if (userRoles?.includes(Role.TeleteachersAdmin))
      return [
        // { title: 'Dashboard', path: '/' },
        { title: 'Districts', path: '/districts' },
        { title: 'Users', path: '/users' },
        // { title: 'Service Requests', path: '/serviceRequests' },
        { title: 'Documentation', path: '/documentation' },
        { title: 'Reports', path: '/reports' },
        { title: 'Services', path: '/services' },
        { title: 'Agency', path: '/agency' }
        // { title: 'Messages', path: '/messages' }
        // { title: 'Chat', path: '/chat' }
      ]
    if (userRoles?.includes(Role.SchoolAdmin))
      return [
        // { title: 'Dashboard', path: '/' },
        // { title: 'Profile', path: '/TherapistInviteView' },
        { title: 'Dashboard', path: '/' },
        { title: 'Users', path: '/users' },
        { title: 'Documentation', path: `/documentation?type=da` },
        { title: 'Reports', path: '/reports' },
        // { title: 'Messages', path: '/messages' },
        { title: 'Clients', path: '/students' },
        { title: 'Provider', path: '/therapists' },
        { title: 'Agency', path: '/agency' }
        // { title: 'Chat', path: '/chat' }
        // { title: 'Blackout Days', path: '/blackoutdays' },
        // { title: 'Messages', path: '/messages' }
      ]
    if (userRoles?.includes(Role.SchoolTherapist) || userRoles?.includes(Role.Therapist))
      return [
        // { title: 'Dashboard', path: '/' },
        // { title: 'Profile', path: '/TherapistInviteView' },
        { title: 'Dashboard', path: '/dashboard' },
        { title: 'Calendar', path: '/calendar' },
        { title: 'Caseload', path: '/caseload' },
        { title: 'Documentation', path: `/documentation?type=tp` }
        // { title: 'Chat', path: '/chat' }
        // { title: 'Reports', path: '/reports' },
        // { title: 'Resources', path: '/resources' }
        // { title: 'Messages', path: '/messages'}
      ]
    if (
      userRoles?.includes(Role.AgencySupervisor) ||
      userRoles?.includes(Role.AgencySupervisorProvider)
    )
      return [
        { title: 'Dashboard', path: '/dashboard' },
        { title: 'Calendar', path: '/calendar' },
        { title: 'Caseload', path: '/caseload' },
        { title: 'Documentation', path: `/documentation?type=tp` },
        { title: 'Reports', path: '/reports' }
      ]

    return []
  }, [currentUserData])
  const morelinks = useMemo(() => {
    const userRoles = currentUserData?.currentUser.roles

    if (userRoles?.includes(Role.TeleteachersAdmin))
      return [
        {
          title: 'Additional Session Report',
          path: '/sessionreport',
          badge: 0,
          ismesgrec: false
        },
        { title: 'Ongoingcare', path: '/ongoing care', badge: 0, ismesgrec: false },
        { title: 'Waitlist', path: '/waitlist', badge: 0, ismesgrec: false }
      ]
    if (userRoles?.includes(Role.SchoolAdmin))
      return [
        {
          title: 'Additional Session Report',
          path: '/sessionreport',
          badge: 0,
          ismesgrec: false
        },
        { title: 'Ongoingcare', path: '/ongoing care', badge: 0, ismesgrec: false },
        { title: 'Waitlist', path: '/waitlist', badge: 0, ismesgrec: false }
      ]
    // if (userRoles?.includes(Role.SchoolTherapist) || userRoles?.includes(Role.Therapist))
    //   return [{ title: 'Assignments', path: '/providerAssignments/my' }]

    return []
  }, [currentUserData])
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget)
  }
  useEffect(() => {
    // console.log(currentUserData)
    // browser tab name will change based on locale
    document.title = `${i18n.language === 'en' ? 'I Matter' : 'Yo Importo'}`
  }, [i18n])
  const handleClose = () => {
    setAnchor(null)
  }
  const settings = [
    {
      icon: <HelpIcon fontSize="small" />,
      name: 'FAQ',
      handleClick: () => {
        window.open('https://teleteachershelp.zendesk.com/hc/en-us', '_blank')
      }
    },
    {
      icon: <OnboardingIcon fontSize="small" />,
      name: 'Onboarding',
      handleClick: () => {
        tourRunStatusVar(true)
        handleClose()
      }
    }
  ]
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  const socketEvents = async (client, token) => {
    client.removeAllListeners('conversationAdded')
    client.setMaxListeners(20)
    client.on('conversationAdded', async (conversation: Conversation) => {
      try {
        await updateConvoList(conversation, updateUnreadMessages)
      } catch {
        console.log('error')
      }
    })
    getSubscribedConversations(client)
    // const subscribedConversations = await client.getSubscribedConversations()
    // const subscribedConversations: Paginator<Conversation> = await client.getSubscribedConversations()
    // const conversationsList: any[] = []
    // conversationsList.push(...subscribedConversations.items)
    //
    // function getNextPage(subsList: Paginator<Conversation>) {
    //   if (subsList.hasNextPage)
    //     subsList.nextPage().then((res) => {
    //       conversationsList.push(...res.items)
    //       getNextPage(res)
    //     })
    // }
    //
    // if (subscribedConversations?.hasNextPage)
    //   subscribedConversations.nextPage().then((res) => {
    //     conversationsList.push(...res.items)
    //
    //     if (res.hasNextPage) getNextPage(res)
    //   })
    //
    //
    // const filteredConvoList = conversationsList.filter((val: any) => val?.lastMessage?.index >= 0)
    // // if (subscribedConversations && subscribedConversations.items)
    // filteredConvoList?.forEach(async (conversation) => {
    // try {
    //   // console.log(conversation)
    //   // console.log(conversation.notificationLevel === 'muted')
    //
    //   // if (conversation.notificationLevel !== 'muted')  //#TODO Un comment after confirmation!
    //   // await updateConvoList(conversation, updateUnreadMessages)
    // } catch {
    //   console.log('error')
    // }
    // })
  }
  const TOKENS_ITEM_KEY = 'authentication:tokens'
  const localTokensJSON: any = localStorage.getItem(TOKENS_ITEM_KEY)
    ? JSON.parse(localStorage.getItem(TOKENS_ITEM_KEY) || '')
    : { id: '' }
  const [checkWaitList, setCheckWaitList] = useState<any>([])
  useEffect(() => {
    const waitLisRes = async (userId: any) => {
      try {
        getWaitList(userId, localTokensJSON?.id).then((res: any) => {
          setCheckWaitList(res.data?.usersList[0])
        })
      } catch (e) {
        console.log(e)
      }
    }

    if (currentUserData && currentUserData?.currentUser) waitLisRes(currentUserData?.currentUser.id)
  }, [currentUserData, localTokensJSON?.id])
  const checkChatAuthPermission =
    checkWaitList?.waitlist_assignee === 1 ||
    checkWaitList?.role_id === 'school_therapist' ||
    checkWaitList?.role_id === 'therapist' ||
    checkWaitList?.role_id === 'agency_supervisor' ||
    checkWaitList?.role_id === 'agency_supervisor_provider'
  const tokendata: any = {
    user_email: localTokensJSON?.id // dev
  }
  // #TODO for future development START
  const setLogin = useCallback(() => {
    const callLogin = async () => {
      let token: string | undefined | any
      try {
        const response = await getToken(tokendata).then((response: any) => response.data.token)
        token = response
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401)
          return error.response.data ?? 'Authentication error.'

        return 'Something went wrong.'
      }

      if (token === '') return 'Something went wrong.'

      login(token)
      const client = new Client(token)
      updateClient(client)
      setInterval(async () => {
        await socketEvents(client, token)
      }, 2000)

      return ''
    }
    callLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const initChatdata = useCallback(() => {
    const setloginwaitdefault = async () => {
      if (data) await setLogin()
    }
    setloginwaitdefault()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useMemo(() => {
    if (checkChatAuthPermission) initChatdata()
  }, [checkChatAuthPermission, initChatdata])
  // #TODO for future development END
  useEffect(() => {
    return () => {
      client?.removeAllListeners()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const alertcss = `.blink_me {
    animation: blinker 1s linear infinite;
  }
  @keyframes blinker {  
    50% { opacity: 0; }
  }`

  return (
    <Box display="flex" alignItems="center" style={{ height: '60px' }}>
      <MAppBar {...props} position="fixed" className={classes.rootHeader}>
        <Toolbar style={{ minHeight: '60px' }}>
          <Typography className={classes.titleCls}>
            {/* <RouterLink color="secondary" href="/" >
              <div className={classes.logoCls} />
            </RouterLink> */}
            <a
              href={`${
                isPublicpath
                  ? i18n.language === 'es'
                    ? REACT_APP_IMATTER_HOME_ES_URL
                    : REACT_APP_IMATTER_HOME_URL
                  : window.location.origin
              }`}
            >
              <div
                className={
                  i18n.language === 'es' ||
                  window.location.href.split('/').slice(-1)[0] === '2' ||
                  window.location.href.split('/').slice(-2, -1)[0] === '2'
                    ? classes.spanishlogoCls
                    : classes.logoCls
                }
              />
            </a>
          </Typography>
          <List component="nav" className={classes.flexContainer} aria-labelledby="main navigation">
            {currentUserData &&
              navlinks.map(({ title, path }) => {
                let selectedClass = classes.buttonStyles
                // console.log('path--', path)
                // console.log('history', history.location)
                const pathToCompare = history.location.pathname + history.location.search

                if (pathToCompare === path)
                  selectedClass = `${selectedClass} ${classes.selectedStyles}`

                return (
                  <ListItem key={title} button className={classes.navStyle}>
                    <style>{alertcss}</style>
                    <Link
                      component="button"
                      style={{ textDecoration: 'none' }}
                      onClick={() => history.push(`${path}`)}
                      className={selectedClass}
                    >
                      {title}
                    </Link>
                  </ListItem>
                )
              })}
          </List>
          {currentUserData &&
            // !currentUserData?.currentUser.roles?.includes(Role.TeleteachersAdmin) &&
            // !currentUserData?.currentUser.roles?.includes(Role.SchoolAdmin) &&
            !currentUserData?.currentUser.roles?.includes(Role.SchoolTherapist) &&
            !currentUserData?.currentUser.roles?.includes(Role.Therapist) &&
            !currentUserData?.currentUser.roles?.includes(Role.Parent) &&
            !currentUserData?.currentUser.roles?.includes(Role.AgencySupervisor) &&
            !currentUserData?.currentUser.roles?.includes(Role.AgencySupervisorProvider) && (
              <Button
                style={{
                  marginLeft: '10px',
                  paddingLeft: '13px',
                  marginTop: '10px',
                  border: '1px solid #ccc',
                  fontSize: '16px'
                }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClickMenu}
              >
                More..
              </Button>
            )}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            style={{ width: '100%', maxWidth: '250px' }}
          >
            {currentUserData &&
              morelinks.map(({ title, path }) => {
                let selectedClass = classes.buttonStyles

                if (history.location.pathname === path)
                  selectedClass = `${selectedClass} ${classes.selectedStyles}`

                return (
                  <Link
                    component="button"
                    color="secondary"
                    onClick={() => history.push(`${path}`)}
                    className={selectedClass}
                  >
                    <MenuItem
                      style={{ textDecoration: 'none', fontSize: '18px' }}
                      onClick={handleCloseMenu}
                    >
                      {title}
                    </MenuItem>
                  </Link>
                )
              })}
          </Menu>
          <Typography variant="h3" className={classes.space}>
            &nbsp;
          </Typography>

          <Box display="flex" height="48px">
            {/* <Box mr={2}>
              <IconButton bordered id="help-btn" onClick={handleClick}>
                <Icon fontSize="small">?</Icon>
              </IconButton>
              <SettingsMenu
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={handleClose}
              >
                {settings.map((setting) => (
                  <SettingsItem onClick={setting.handleClick} key={setting.name}>
                    <Box mr={1}>{setting.icon}</Box>
                    {setting.name}
                  </SettingsItem>
                ))}
              </SettingsMenu>
            </Box> */}
            {currentUserData && displayChat && checkChatAuthPermission && (
              <>
                <Box mr={1} className={classes.buttonBox}>
                  <IconButton id="help-btn" onClick={() => history.push('/messages')}>
                    <ChatBubbleIcon className={classes.icon} />
                    <Typography component="span" className={classes.count}>
                      {chatTotCount}
                    </Typography>
                  </IconButton>
                </Box>
              </>
            )}

            {currentUserData && (
              <>
                <LoggedUser />
                <ChangePasswordForm
                  email={currentUserData?.currentUser.email}
                  id={currentUserData?.currentUser.id}
                />
              </>
            )}
          </Box>
        </Toolbar>
      </MAppBar>
      {/* <Toolbar style={{ minHeight: '90px' }} /> */}
      {currentUserData && (
        <>
          <ChangePasswordForm
            email={currentUserData?.currentUser.email}
            id={currentUserData?.currentUser.id}
          />
        </>
      )}
    </Box>
  )
}

export default AppBar
