import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { AgencyListView } from './list/AgencyListView'
import { CreateAgencyView } from './create/CreateAgency'
import { UpdateAgencyView } from './update/UpdateAgencyView'

export const AgencyRootView = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/create`} component={CreateAgencyView} />
      <Route path={`${path}/:id/edit`} component={UpdateAgencyView} />
      <Route component={AgencyListView} />
    </Switch>
  )
}
