import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import { StudentForm, StudentsFormModel } from '../form/StudentForm'
import {
  useUpdateStudentMutation,
  useStudentQuery,
  useDeleteStudentMutation
} from '../../../../graphql/hooks.generated'
import { UseParamsType } from '../../../../types'

interface UpdateStudentViewParams extends UseParamsType {
  student_id: string
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const UpdateStudentView = () => {
  const history = useHistory()
  const { student_id } = useParams<UpdateStudentViewParams>()
  const [updateStudent] = useUpdateStudentMutation()
  const { data } = useStudentQuery({
    variables: { id: parseInt(student_id) },
    fetchPolicy: 'network-only'
  })
  const [deleteStudent] = useDeleteStudentMutation()
  const onDelete = async () => {
    await deleteStudent({ variables: { id: parseInt(student_id) } })
    history.goBack()
  }
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const student = data?.student
  const initialValues: StudentsFormModel | null = student
    ? {
        firstName: student?.first_name,
        lastName: student?.last_name,
        // studentId: student?.student_id,
        // district: Number(student?.district?.id),
        // school: student?.school?.id,
        // icdCode: student?.icdCode?.id,
        // iepDate: new Date(student?.iep_date),
        // reEvlDate: student?.re_evl_date ? new Date(student.re_evl_date) : undefined,
        // gradeId: student?.grade?.id,
        // primaryLanguageId: student?.primaryLanguage?.id,
        contactPhoneNumber: student?.contact_phone_number
          ? student.contact_phone_number
          : undefined,
        contactEmail: student?.contact_email ? student?.contact_email : undefined,
        birthDate: student?.birth_date ? new Date(student?.birth_date) : undefined,
        gender: student?.gender ? student?.gender : undefined,
        parent1_phone_number: student?.parent1_phone_number
          ? student.parent1_phone_number
          : undefined,
        // parent2_phone_number: student?.parent2_phone_number
        //   ? student.parent2_phone_number
        //   : undefined,
        parent1_email: student?.parent1_email ? student.parent1_email : undefined
        // parent2_email: student?.parent2_email ? student.parent2_email : undefined
      }
    : null

  return (
    <>
      <NavBar title="Update Client" goBackBtn />
      <PageContainer>
        {/* <StudentForm
          submitLabel="Save"
          initialValues={initialValues}
          onSubmit={(values) =>
            updateStudent({
              variables: {
                id: Number(student_id),
                input: {
                  ...values,
                  birthDate: values.birthDate && format(values.birthDate, 'yyyy-MM-dd'),
                  iepDate: values.iepDate && format(values.iepDate, 'yyyy-MM-dd'),
                  reEvlDate: values.reEvlDate && format(values.reEvlDate, 'yyyy-MM-dd')
                }
              }
            }).then(history.goBack)
          }
        /> */}
        <Button
          style={{ marginLeft: 259, marginTop: -73 }}
          size="large"
          variant="outlined"
          color="primary"
          onClick={handleClickOpen}
        >
          Delete
        </Button>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure want to Delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button onClick={onDelete} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </PageContainer>
    </>
  )
}
