/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState, useMemo } from 'react'
import { Button, InputLabel, Grid, MenuItem } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Select, makeValidate, TextField } from 'mui-rff'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Field, Form, FormRenderProps } from 'react-final-form'
import {
  useCurrentUserQuery,
  useCreateWaitlistOutreachMutation,
  useSignalOutreachTypeQuery
} from 'graphql/hooks.generated'
import * as yup from 'yup'
import moment from 'moment'

interface CreateOutreachFormModel {
  outReachType: any
  notes: any
}

export interface OutreachFormProps {
  open: boolean
  handleClose: () => unknown
  waitlistobj: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiOutlinedInput-input': {
        padding: '11px 14px',
        backgroundColor: '#fff !important',
        border: '1px solid #000'
      },
      '& .MuiOutlinedInput-inputMultiline': {
        minHeight: '80px',
        padding: '10px',
        border: '1px solid #000'
      },
      '& .MuiOutlinedInput-multiline': {
        padding: '0px'
      },
      '& .MuiFormHelperText-root.Mui-error': {
        display: 'none'
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderWidth: '1px'
      }
    },
    labelField: {
      '& .MuiFilledInput-input': {
        backgroundColor: '#fff !important',
        border: '1px solid #000',
        padding: '11px 12px 10px'
      },
      '& .MuiSelect-filled.MuiSelect-filled': {
        backgroundColor: '#fff !important'
      }
    },
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff !important'
    },
    '& .MuiSelect-filled.MuiSelect-filled': {
      backgroundColor: '#fff !important'
    },
    fs20: {
      fontSize: '20px'
    },
    colBlak: {
      color: '#000'
    },
    paddingrt: {
      paddingRight: '10px'
    },
    pleft: {
      paddingLeft: '10px'
    },
    fleft: {
      float: 'left'
    },
    iBlock: {
      display: 'inline-block'
    },
    fright: {
      float: 'right'
    },
    contpadd: {
      padding: '15px 15px 0px 10px'
    },
    mAuto: {
      margin: 'auto'
    },
    relative: {
      position: 'relative'
    },
    inputField: {
      '& .MuiOutlinedInput-input': {
        paddingTop: '10.5px',
        paddingBottom: '10.5px',
        border: `1px solid #E5E5E5`,
        borderRadius: '3px',
        opacity: '1'
      }
    },
    errorCol: {
      color: '#f44336'
    },
    fForm: {
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)'
      }
    }
  })
)
const notemaxErrMsg = 'Exceeded maximum 1000 characters'
const addOutreachvalidator = () =>
  makeValidate(
    yup.object<CreateOutreachFormModel>({
      outReachType: yup.string().label('Outreach Type').required(),
      notes: yup
        .string()
        .label('Notes')
        .required()
        .test('len', notemaxErrMsg, (val) => {
          let flag = false

          if (val && val?.toString().length < 1000) flag = true

          return flag
        })
    })
  )

export const Outreach = (props: OutreachFormProps) => {
  const classes = useStyles()
  const { open, handleClose, waitlistobj } = props
  const validator = useMemo(() => addOutreachvalidator(), [])
  const age = moment().diff(moment(waitlistobj?.student_id?.birth_date, 'YYYY-MM-DD'), 'years')
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const { data: outreachtypedata, loading: typeloading } = useSignalOutreachTypeQuery({
    fetchPolicy: 'network-only'
  })
  const [outreachSavemutation, { loading: saveloading }] = useCreateWaitlistOutreachMutation()
  const handleFormSubmit = async (values: CreateOutreachFormModel) => {
    // console.log('Values', values)
    const inputobj = {
      waitlist_id: waitlistobj?.id,
      outreach_type_id: Number(values?.outReachType),
      notes: values?.notes,
      user_id: currentUserdata?.data?.currentUser?.id,
      created_by: currentUserdata?.data?.currentUser?.id,
      updated_by: currentUserdata?.data?.currentUser?.id,
      student_id: waitlistobj?.student_id?.id,
      studentName: waitlistobj?.name,
      userName: currentUserdata?.data?.currentUser?.name
    }
    try {
      await outreachSavemutation({
        variables: {
          input: inputobj
        }
      })
      handleClose()
    } catch (e) {
      console.log(e)
    }
  }
  const renderForm = (renderProps: FormRenderProps<CreateOutreachFormModel>) => {
    const { handleSubmit, submitFailed, errors } = renderProps

    return (
      <>
        <Dialog
          fullWidth
          open={open}
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div className={classes.fs20}>Outreach</div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid container xs={12} md={12} sm={12} lg={12} className={classes.fForm}>
                <Grid item md={12}>
                  <Grid container md={12} className={classes.contpadd}>
                    <Grid item md={4} className={classes.mAuto}>
                      <InputLabel
                        htmlFor="clientName"
                        className={`${classes.colBlak} ${classes.iBlock}`}
                      >
                        Client Name
                      </InputLabel>
                      <div
                        className={`${classes.colBlak} ${classes.iBlock} ${classes.fright} ${classes.paddingrt}`}
                      >
                        :
                      </div>
                    </Grid>
                    <Grid item md={8} className={classes.root}>
                      <div className={`${classes.colBlak} ${classes.pleft}`}>
                        {waitlistobj?.name || '---'}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container md={12} className={classes.contpadd}>
                    <Grid item md={4} className={classes.mAuto}>
                      <InputLabel
                        htmlFor="contactDetails"
                        className={`${classes.colBlak} ${classes.iBlock}`}
                      >
                        Contact Details
                      </InputLabel>
                      <div
                        className={`${classes.colBlak} ${classes.iBlock} ${classes.fright} ${classes.paddingrt}`}
                      >
                        :
                      </div>
                    </Grid>
                    <Grid item md={8} className={classes.root}>
                      <div className={`${classes.colBlak} ${classes.pleft}`}>
                        <div>Email - {waitlistobj?.student_id?.contact_email || '---'}</div>
                        <div>Phone - {waitlistobj?.student_id?.contact_phone_number || '---'}</div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container md={12} className={classes.contpadd}>
                    <Grid item md={4} className={classes.mAuto}>
                      <InputLabel htmlFor="age" className={`${classes.colBlak} ${classes.iBlock}`}>
                        Age
                      </InputLabel>
                      <div
                        className={`${classes.colBlak} ${classes.iBlock} ${classes.fright} ${classes.paddingrt}`}
                      >
                        :
                      </div>
                    </Grid>
                    <Grid item md={8} className={classes.root}>
                      <div className={`${classes.colBlak} ${classes.pleft}`}>
                        {age} {age > 1 ? 'Years' : 'Year'}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container md={12} className={classes.contpadd}>
                    <Grid item md={4} className={classes.mAuto}>
                      <InputLabel
                        htmlFor="insurance"
                        className={`${classes.colBlak} ${classes.iBlock}`}
                      >
                        Insurance
                      </InputLabel>
                      <div
                        className={`${classes.colBlak} ${classes.iBlock} ${classes.fright} ${classes.paddingrt}`}
                      >
                        :
                      </div>
                    </Grid>
                    <Grid item md={8} className={classes.root}>
                      <div className={`${classes.colBlak} ${classes.pleft}`}>
                        {waitlistobj?.student_id?.insurance_company?.name || '---'}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container md={12} className={classes.contpadd}>
                    <Grid item md={4} className={classes.mAuto}>
                      <InputLabel
                        htmlFor="treatmentNeeded"
                        className={`${classes.colBlak} ${classes.iBlock}`}
                      >
                        Treatment Needed
                      </InputLabel>
                      <div
                        className={`${classes.colBlak} ${classes.iBlock} ${classes.fright} ${classes.paddingrt}`}
                      >
                        :
                      </div>
                    </Grid>
                    <Grid item md={8} className={classes.root}>
                      <div className={`${classes.colBlak} ${classes.pleft}`}>
                        {waitlistobj?.student_id?.student_specialities?.map((item, index) => (
                          <span>
                            {item?.speciality_id?.name}
                            {waitlistobj?.student_id?.student_specialities?.length - 1 === index
                              ? ''
                              : ','}
                            &nbsp;
                          </span>
                        ))}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container md={12} className={classes.contpadd}>
                    <Grid item md={4} className={classes.mAuto}>
                      <InputLabel
                        htmlFor="pscScore"
                        className={`${classes.colBlak} ${classes.iBlock}`}
                      >
                        PSC/CRAFFT
                      </InputLabel>
                      <div
                        className={`${classes.colBlak} ${classes.iBlock} ${classes.fright} ${classes.paddingrt}`}
                      >
                        :
                      </div>
                    </Grid>
                    <Grid item md={8} className={classes.root}>
                      <div className={`${classes.colBlak} ${classes.pleft}`}>
                        {waitlistobj?.student_id?.psc_score}/{waitlistobj?.student_id?.craft_score}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container md={12} className={classes.contpadd}>
                    <Grid item md={4} className={classes.mAuto}>
                      <div
                        className={`${
                          submitFailed && errors?.outReachType?.length
                            ? classes.errorCol
                            : classes.colBlak
                        } ${classes.fleft}`}
                      >
                        <InputLabel
                          htmlFor="outReachType"
                          className={`${
                            submitFailed && errors?.outReachType?.length
                              ? classes.errorCol
                              : classes.colBlak
                          } ${classes.iBlock}`}
                        >
                          Outreach Type
                        </InputLabel>
                        *
                      </div>
                      <div
                        className={`${classes.colBlak} ${classes.iBlock} ${classes.fright} ${classes.paddingrt}`}
                      >
                        :
                      </div>
                    </Grid>
                    <Grid item md={8} className={`${classes.root} ${classes.pleft}`}>
                      {outreachtypedata && (
                        <Select
                          name="outReachType"
                          labelId="demo-customized-select-label"
                          id="label-provider"
                          required
                          variant="outlined"
                        >
                          {outreachtypedata?.signalOutreachType?.map((item) => (
                            <MenuItem key={item?.id} value={item?.id?.toString()}>
                              {item?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container md={12} className={classes.contpadd}>
                    <Grid item md={4} className={classes.mAuto}>
                      <div
                        className={`${
                          submitFailed && errors?.notes?.length ? classes.errorCol : classes.colBlak
                        } ${classes.fleft}`}
                      >
                        <InputLabel
                          htmlFor="notes"
                          className={`${
                            submitFailed && errors?.notes?.length
                              ? classes.errorCol
                              : classes.colBlak
                          } ${classes.iBlock}`}
                        >
                          Notes
                        </InputLabel>
                        *
                      </div>
                      <div
                        className={`${classes.colBlak} ${classes.iBlock} ${classes.fright} ${classes.paddingrt}`}
                      >
                        :
                      </div>
                    </Grid>
                    <Grid item md={8} className={`${classes.root} ${classes.pleft}`}>
                      <TextField
                        id="label-mi"
                        fullWidth
                        variant="outlined"
                        name="notes"
                        multiline
                      />
                      {errors && errors?.notes?.length === 1 && errors?.notes[0] === notemaxErrMsg && (
                        <div
                          style={{ paddingTop: '5px', fontSize: '12px' }}
                          className={classes.errorCol}
                        >
                          {notemaxErrMsg}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              variant="outlined"
              className="m-t-5 m-b-20"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              aria-controls="simple-menu"
              aria-haspopup="true"
              className="mar-rt-15 m-t-5 m-b-20"
              variant="contained"
              size="medium"
              style={{ height: '36px' }}
              onClick={() => handleSubmit()}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  return (
    <>
      {(saveloading || typeloading) && <ProgressIndicator fullHeight />}
      <Form<CreateOutreachFormModel>
        render={renderForm}
        validate={validator}
        // initialValues={initialObj}
        onSubmit={handleFormSubmit}
      />
    </>
  )
}
