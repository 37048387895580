import React, { useEffect } from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import queryString from 'query-string'
import { Redirect } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { LoginForm, LoginFormProps } from './LoginForm'
import Paper from '../../panel/paper/Paper'
import AppBar from '../../panel/app-bar/AppBar'
import Footer from '../../panel/footer/Footer'
import { useAuth } from '../../../../hooks/useAuth'
import { clearQueryKey } from '../../../../helpers/clearQueryKey'
import { LoginFormModel } from './LoginFormModel'
import { useLoginLazyQuery } from '../../../../graphql/hooks.generated'
import ProgressIndicator from '../../panel/progress-indicator/ProgressIndicator'
import { useStyles } from './loginstyle'
import BackgroundImage from '../../../../assets/illustrations/backgroundimatterimage.png'

const { REACT_APP_LOGIN_URL } = process.env
const ERROR_QUERY_KEY = 'error'
const FALLBACK_QUERY_KEY = 'fallback'
const REDIRECT_URL_QUERY_KEY = 'redirect_url'
interface LoginQueryStringTypeMap {
  [ERROR_QUERY_KEY]?: string
  [FALLBACK_QUERY_KEY]?: string
  [REDIRECT_URL_QUERY_KEY]?: string
}
const backgroundStyle = {
  // backgroundImage: 'url(illustrations.png)',
  backgroundImage: `url(${BackgroundImage})`,
  backgroundSize: '100% 100%',
  backgroundRepeat: 'no-repeat',
  minHeight: '100vh'
}

export const Login = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-T644KWW'
    }
    TagManager.initialize(tagManagerArgs)
  }, [])
  const classes = useStyles()
  const { isAuthenticated, setFallbackTokens } = useAuth()
  const [executeQuery, { data, loading, error: loginQueryError }] = useLoginLazyQuery()
  const { search } = window.location
  const {
    [ERROR_QUERY_KEY]: error,
    [FALLBACK_QUERY_KEY]: fallback,
    [REDIRECT_URL_QUERY_KEY]: redirectUrl
  }: LoginQueryStringTypeMap = queryString.parse(search ?? '')

  if (error === 'Error: Verifier is missing') window.location.href = window.location.origin
  if (!localStorage.getItem('authentication:verifier'))
    window.location.href = window.location.origin

  const formProps: LoginFormProps = !fallback
    ? {
        loginUrl: REACT_APP_LOGIN_URL
      }
    : {
        onSubmit({ username, password }: LoginFormModel) {
          executeQuery({
            variables: {
              username,
              password
            }
          })
        }
      }
  useEffect(() => {
    if (data && redirectUrl) setFallbackTokens(data.login, redirectUrl)
  })
  const waitTime = 50 * 60 * 1000
  window.setInterval(function () {
    return !document.hasFocus() ? window.location.replace('/') : ''
  }, waitTime)

  if (isAuthenticated) return <Redirect to="/" />
  if (loading) return <ProgressIndicator fullHeight />
  if (error) clearQueryKey(ERROR_QUERY_KEY)

  return (
    <>
      <Grid container direction="column" justify="space-between" alignItems="center">
        <AppBar />
        <Grid container style={{ ...backgroundStyle, minHeight: 'calc(100vh - 60px - 75px)' }}>
          <Grid item sm={5} md={4} lg={3} style={{ margin: 'auto' }}>
            <Paper style={{ border: 'solid 1px #c7c7c7' }}>
              <Box px={3} py={4}>
                <Typography variant="h1">Log in</Typography>
              </Box>
              <Box p={3} pt={0}>
                {(error || loginQueryError) && (
                  <div className={classes.errormessage}>
                    <strong>{error ?? 'invalid email address or password'}</strong>
                  </div>
                )}
                <LoginForm {...formProps} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Footer />
      </Grid>
    </>
  )
}
