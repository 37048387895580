/* eslint-disable */
/* eslint-disable react/prop-types */

import React, { useMemo, useState, useEffect, useRef } from 'react'
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs'
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons'
import Button from '../../../../components/ui/panel/button/Button'
import {
  useCancelAppointmentMutation,
  useAppointmentsDataQuery,
  useResendAppointmentEmailLazyQuery
} from '../../../../graphql/hooks.generated'
import { useSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'

const InputField = ({ title, value }) => {
  const { enqueueSnackbar } = useSnackbar()
  const copyClipBoard = () => {
    const el = document.createElement('input')
    el.value = value
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    enqueueSnackbar('URL Copied Successfully', { variant: 'success' })
    // textAreaRef?.current?.select()
  }

  return (
    <React.Fragment>
      <div className="field-name-div p-x">
        <p className="field-name" style={{ width: '15%' }}>
          {title}
        </p>
        <p style={{ padding: '0 20px' }}>: </p>
        {title === 'Link' && (
          <>
            {value && (
              <>
                <a
                  href={value}
                  style={{
                    wordBreak: 'break-word',
                    paddingTop: '15px',
                    width: '215px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {value}
                </a>
                <span className="e-icons e-copy" onClick={() => copyClipBoard()}></span>
              </>
            )}
            {!value && <p>-</p>}
          </>
        )}
        {title === 'Name' && (
          <a style={{ wordBreak: 'break-word', paddingTop: '15px', width: '75%' }}>{value}</a>
        )}
        {title === 'Address' && (
          <div style={{ wordBreak: 'break-word', paddingTop: '15px', width: '75%' }}>
            <span>{`${value?.street_address}`}</span>
          </div>
        )}
        {/* <input id="Summary"  readOnly = {true} value={cellData?.Subject} className="e-field e-input " type="text" placeholder=" Title here" data-name="Summary" style={{ width: '100%' }}/> */}
      </div>
    </React.Fragment>
  )
}

const ResendAppointmentDialog = ({ cellData, onChange }) => {
  const _fetchingMsg = 'Please wait Apointment Details fetching'
  const _emailSent = 'Please wait email is sending'
  const { enqueueSnackbar } = useSnackbar()
  const [mailLoading, setMailLoading] = useState(false)
  const {
    data: resendEmailDeatils,
    loading: detailLoading,
    error,
    refetch
  } = useAppointmentsDataQuery({
    variables: { appointment_id: cellData?.id },
    // variables: { user_id: '8ccc9cdd-ba9c-4051-845f-279f47364582' },

    fetchPolicy: 'network-only'
  })
  const [address, setAddress] = useState<any>()
  const [resendEmail, { data: responseData, loading }] = useResendAppointmentEmailLazyQuery({
    variables: { appointment_id: cellData?.id },
    // variables: { user_id: '8ccc9cdd-ba9c-4051-845f-279f47364582' },

    fetchPolicy: 'network-only'
  })
  const ResendEmailQuery = useMemo(
    () => async () => {
      try {
        setMailLoading(true)
        await resendEmail()
        setMailLoading(false)
        enqueueSnackbar('Mail sent successfully', { variant: 'success' })
        onChange('emailBack')
      } catch {}
    },
    [resendEmail]
  )
  useEffect(() => {
    const tmpObj = {
      ...resendEmailDeatils?.appointmentsData?.[0]?.user_id?.signalAgencies?.[0],
      street_address: resendEmailDeatils?.appointmentsData?.[0]?.user_id?.street_address
    }
    setAddress(tmpObj)
  }, [resendEmailDeatils?.appointmentsData])

  return (
    <>
      {(mailLoading || detailLoading) && (
        <div className="scheduleLoader" style={{ display: 'flex', padding: '30px' }}>
          <CircularProgress />
          <p style={{ textAlign: 'center', marginLeft: '40px' }}>
            {loading ? _emailSent : _fetchingMsg}
          </p>
        </div>
      )}
      {!mailLoading && !detailLoading && (
        <div className="cancelContainer">
          <InputField title={'Name'} value={cellData?.EventStudents?.[0]?.name} />
          {cellData?.signal_service_id?.id === 2 && (
            <InputField title={'Link'} value={resendEmailDeatils?.appointmentsData?.[0]?.link} />
          )}
          {cellData?.signal_service_id?.id !== 2 && (
            <InputField title={'Address'} value={address} />
          )}

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              color="primary"
              aria-controls="simple-menu"
              aria-haspopup="true"
              className="mar-rt-15 m-t-5 m-b-20"
              variant="outlined"
              size="medium"
              style={{ height: '36px' }}
              onClick={() => onChange('emailBack')}
            >
              Back
            </Button>
            <Button
              color="primary"
              aria-controls="simple-menu"
              aria-haspopup="true"
              className="mar-rt-15 m-t-5 m-b-20"
              variant="contained"
              size="medium"
              style={{ height: '36px' }}
              // onClick={() => submitCreateAppointment()}
              onClick={() => ResendEmailQuery()}
            >
              Send Mail
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default ResendAppointmentDialog
