import { Grid } from '@material-ui/core'
import { FormApi } from 'final-form'
import { makeValidate } from 'mui-rff'
import React from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { CaseloadTypeSelect } from '../../../../components/ui/form/caseload-type-select/CaseloadTypeSelect'
import Button from '../../../../components/ui/panel/button/Button'
import { InfoButton } from '../../../../components/ui/panel/button/InfoButton'
import { StudentSelect } from '../../../../components/ui/form/student-select/StudentSelect'
import { UserSelect } from '../../../../components/ui/form/user-select/UserSelect'
import { Role, CaseloadType, Student } from '../../../../graphql/hooks.generated'

interface ProviderAssignmentsFormModel {
  studentId: string
  userId: string
  caseloadTypeId: number
}

export type AssignmentFormProps = {
  onSubmit(values: ProviderAssignmentsFormModel)
  initialStudents?: Pick<Student, 'id' | 'name'>[]
  submitLabel?: string
  initialValues?: Partial<ProviderAssignmentsFormModel>
}

const validator = makeValidate(
  yup.object<ProviderAssignmentsFormModel>({
    studentId: yup.string().label('Student').nullable().required(),
    userId: yup.string().label('Therapist').nullable().required(),
    // assignment: yup.mixed<ServiceRequestType>().label('Assignment').required()
    caseloadTypeId: yup.number().label('Assignment').nullable().required()
  })
)

export const AssignmentForm = (props: AssignmentFormProps) => {
  const history = useHistory()
  const { initialStudents, initialValues, submitLabel } = props
  const onSubmit = (values: ProviderAssignmentsFormModel) => props.onSubmit(values)
  const renderForm = (renderProps: FormRenderProps<ProviderAssignmentsFormModel>) => {
    const { handleSubmit } = renderProps

    return (
      <form>
        <Grid container spacing={3} style={{ maxWidth: '800px' }}>
          <Grid item sm={12}>
            <StudentSelect label="Client" name="studentId" initialOptions={initialStudents} />
          </Grid>
          <Grid item sm={12}>
            <UserSelect
              label="Provider"
              name="userId"
              withRoles={[Role.Therapist, Role.Therapist, Role.SchoolTherapist]}
            />
          </Grid>
          {/* <Grid item sm={12}>
            <CaseloadTypeSelect label="Assignment" required name="caseloadTypeId" />
          </Grid> */}
          <Grid item sm={12}>
            <CaseloadTypeSelect label="Assignment" required name="caseloadTypeId" />
          </Grid>

          <Grid item sm={12} style={{ paddingTop: 35 }}>
            <InfoButton
              size="large"
              variant="contained"
              onClick={handleSubmit}
              style={{ marginRight: 12 }}
            >
              {submitLabel}
            </InfoButton>
            <Button size="large" variant="outlined" color="primary" onClick={history.goBack}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <Form<ProviderAssignmentsFormModel>
      initialValues={initialValues ?? {}}
      validate={validator}
      render={renderForm}
      onSubmit={onSubmit}
    />
  )
}
