import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { values } from 'lodash'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import { SchoolForm } from '../form/SchoolForm'
import { useCreateSchoolMutation } from '../../../../graphql/hooks.generated'
import { UseParamsType } from '../../../../types'

interface CreateSchoolViewParams extends UseParamsType {
  id: string
}

export const CreateSchoolView = () => {
  const [createSchool] = useCreateSchoolMutation()
  const history = useHistory()
  const { id: districtId } = useParams<CreateSchoolViewParams>()

  return (
    <>
      <NavBar title="Add A New School" goBackBtn />
      <PageContainer>
        <SchoolForm
          initialValues={districtId ? { district: Number(districtId) } : {}}
          submitLabel="Save"
          onSubmit={(values) =>
            createSchool({ variables: { input: values } }).then(() =>
              history.push(`districts/${districtId}/schools`)
            )
          }
        />
      </PageContainer>
    </>
  )
}
