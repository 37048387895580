import React, { useMemo, useEffect } from 'react'
import { MenuItem } from '@material-ui/core'
import { Select } from 'mui-rff'
import { useTherapistDesciplineSelectLazyQuery } from '../../../../graphql/hooks.generated'
import { useStyles } from '../Styles'

interface TherapistDesciplineSelectProps {
  label: string
  name: string
  required?: boolean
}

export const TherapistSelect = (props: TherapistDesciplineSelectProps) => {
  const active: any = 'ACTIVE'
  const classes = useStyles()
  const { label, name } = props
  const [executeQuery, { data }] = useTherapistDesciplineSelectLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetch = useMemo(() => () => executeQuery(), [executeQuery])
  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <>
      {data?.therapistDescipline && data?.therapistDescipline?.length > 0 ? (
        <Select
          variant="filled"
          name={name}
          placeholder={name}
          label={label}
          className={classes.selectDropdown}
          required
          // multiple
          // placeholder="Age"
          // options={data?. therapistDescipline.filter(( therapistDescipline) =>
          //  therapistDescipline.status === active)}
          // getOptionLabel={(option) => option?.name}
          // getOptionValue={(option) => option?.name}
          // isMulti
        >
          {data?.therapistDescipline.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select {...props} variant="filled">
          <MenuItem>No Data Found</MenuItem>
        </Select>
      )}
    </>
  )
}
