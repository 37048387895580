import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField
} from '@material-ui/core'

interface RatingDialogueProps {
  onClose: () => void
  onRatingConfirmed: (rating: number) => void
  setStudentHearRating: (rating: boolean) => void
  setStudentSeeRating: (rating: boolean) => void
  setStudentComment: (comment: any) => void
  userType?: any
  callFeedBackByID?: any
  setSaved?: any
  saved?: any
}

export default function RatingDialogue({
  onClose,
  onRatingConfirmed,
  setStudentHearRating,
  setStudentSeeRating,
  setStudentComment,
  setSaved,
  saved,
  callFeedBackByID,
  userType
}: RatingDialogueProps) {
  const [hearRating, setHearRating] = useState(0)
  const [seeRating, setSeeRating] = useState(0)
  const [seeNoComment, setSeeNoComment] = useState<any>()
  const callFeedBackData = callFeedBackByID && callFeedBackByID?.getCallQualityFeedbackById[0]
  const seeing = callFeedBackData ? (callFeedBackData?.seeing_rate ? 1 : 2) : 0
  const hearing = callFeedBackData ? (callFeedBackData?.listening_rate ? 1 : 2) : 0
  useEffect(() => {
    if (callFeedBackData) {
      setHearRating(hearing)
      setSeeRating(seeing)
      setSeeNoComment(callFeedBackData?.comment)
      setStudentHearRating(callFeedBackData?.listening_rate)
      setStudentSeeRating(callFeedBackData?.seeing_rate)
      setStudentComment(callFeedBackData?.comment)
      setSaved({
        studentHearRating: callFeedBackData?.listening_rate,
        studentSeeRating: callFeedBackData?.seeing_rate,
        studentComment: callFeedBackData?.comment
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callFeedBackData, seeing, hearing])
  useEffect(() => {
    if (hearRating === 1 && seeRating === 1) setStudentComment('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hearRating, seeRating])
  const handleHearRatingChange = (event) => {
    const selectedValue = Number(event.target.value)
    setHearRating(Number(event.target.value))

    if (selectedValue === 1) setStudentHearRating(true)
    else setStudentHearRating(false)
  }
  const handleSeeRatingChange = (event) => {
    const selectedValue = Number(event.target.value)
    setSeeRating(selectedValue)

    if (selectedValue === 1) setStudentSeeRating(true)
    else setStudentSeeRating(false)
  }
  const handleSeeNoCommentChange = (event) => {
    const commentValue = event.target.value
    setSeeNoComment(event.target.value)
    setStudentComment(commentValue === '' ? null : commentValue)
  }
  const handleConfirm = () => {
    if (hearRating > 0 && seeRating > 0) onRatingConfirmed(1)
  }
  const radioContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '8px',
    marginLeft: '40px',
    fontFamily: 'Brandon Grotesque'
  }
  const radioContainerStyle2 = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '8px',
    marginLeft: '46px',
    fontFamily: 'Brandon Grotesque'
  }
  const ratingOptionStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
    fontFamily: 'Brandon Grotesque'
  }

  return (
    <Dialog disableBackdropClick open onClose={onClose}>
      <DialogTitle style={{ textAlign: 'center', fontFamily: 'Brandon Grotesque' }}>
        Call Feedback
      </DialogTitle>
      <DialogContent>
        <div style={ratingOptionStyle}>
          <span>
            Could you hear the {userType === 'Therapist' ? 'client' : 'provider'} throughout the
            session?
          </span>
          <RadioGroup value={hearRating} onChange={handleHearRatingChange} className="radio-group">
            <div style={radioContainerStyle}>
              <FormControlLabel value={1} control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel value={2} control={<Radio color="primary" />} label="No" />
            </div>
          </RadioGroup>
        </div>
        <div style={ratingOptionStyle}>
          <span>
            Could you see the {userType === 'Therapist' ? 'client' : 'provider'} throughout the
            session?
          </span>
          <RadioGroup value={seeRating} onChange={handleSeeRatingChange} className="radio-group">
            <div style={radioContainerStyle2}>
              <FormControlLabel value={1} control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel value={2} control={<Radio color="primary" />} label="No" />
            </div>
          </RadioGroup>
        </div>
        {(hearRating === 2 || seeRating === 2) && (
          <div>
            <span style={{ padding: '63px' }}>
              <strong>
                Please explain what happened as far as the video and/or audio quality.
              </strong>
            </span>
            <TextField
              id="label-mi"
              variant="outlined"
              name="notes"
              fullWidth
              multiline
              rows={4}
              value={seeNoComment}
              onChange={handleSeeNoCommentChange}
            />
          </div>
        )}
      </DialogContent>

      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => onClose()}
          style={{ float: 'inherit' }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="outlined"
          style={{ float: 'inherit' }}
          disabled={
            hearRating === 0 ||
            seeRating === 0 ||
            ((seeRating === 2 || hearRating === 2) && (!seeNoComment || !seeNoComment.trim()))
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
