/* eslint-disable */
/* eslint-disable react/prop-types */

import React, { useMemo, useState, useEffect, useRef } from 'react'
import {
  useUserUnbookedSlotsLazyQuery,
  useAddAppointmetnsListLazyQuery
} from '../../../../graphql/hooks.generated'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { useSnackbar } from 'notistack'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  Collapse,
  Typography
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { TimeSlots } from './timeSlots'
import useStyles from '../../../../components/ui/auth/register/Styles'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import moment from 'moment'

const InputField = ({ title, value }) => {
  return (
    <React.Fragment>
      <div
        className="field-name-div p-x"
        style={{ background: '#dae7f3', border: '1px solid', padding: '5px' }}
      >
        <Typography className="field-name" style={{ width: '20%' }}>
          {title}
          <span style={{ padding: '0 20px' }}>:</span>
        </Typography>
        <Typography style={{ color: ' #757575', fontWeight: '400' }}>
          {value ? value : '-'}
        </Typography>
      </div>
    </React.Fragment>
  )
}

const TherapistDetails = ({
  userDetails,
  userId,
  admin,
  onChange,
  errorObject,
  filterObj,
  therapistList,
  therapistLoader,
  pagination,
  serviceId
}) => {
  const stateObj = { flag: false, index: -1, item: {} }
  const [expand, setExpand] = useState(stateObj)
  const [therapistSelected, setTherapistSelected] = useState(false)
  const { flag, index } = expand
  const classes = useStyles()
  const [selectedSlot, setSelectedSlot] = useState('')
  const [listTherapist, setListTherapist] = useState([])
  const [listSlots, setListSlots] = useState()
  const [loadingTherapist, setLoadingTherapist] = useState(false)
  const [tid, setTid] = useState(admin ? null : userId)
  const [pageSettings, setPagesettings] = useState({})
  const [slotList, { data: slotsData, loading: slotsLoading }] = useUserUnbookedSlotsLazyQuery({
    variables: { tid, ttime: '', service_id: Number(serviceId) },
    // variables: { user_id: '8ccc9cdd-ba9c-4051-845f-279f47364582' },
    fetchPolicy: 'network-only'
  })
  const isLoading = () => (loadingTherapist || slotsLoading ? <ProgressIndicator fullHeight /> : '')
  const slotFetch = useMemo(() => () => slotList(), [slotList])
  const selectedTherapist = (i, e, item) => {
    setExpand({ index: i, flag: e, item })
    setTherapistSelected(true)
    setTid(item.id)
    setListSlots()
    slotFetch()
    if (!e) {
      slotSelected(undefined)
    }
  }
  const FormatTherapists = () => {
    if (!admin) {
      const signalAgencies =
        userDetails?.signalAgencies &&
        userDetails?.signalAgencies
          .map((itm) => {
            return itm.name
          })
          .join(', ')
      const obj = {
        id: userId,
        name: userDetails.name,
        street_address: userDetails.street_address,
        email: userDetails.email,
        service_name:
          userDetails.signal_service_id.id === 3
            ? 'In-Person and Telehealth'
            : userDetails.signal_service_id.name,
        agency_name: signalAgencies
      }
      const TherapistArr = [obj]
      setListTherapist(TherapistArr)
      selectedTherapist(0, true, obj)
    }
  }
  const slotSelected = (value) => {
    setSelectedSlot(value)
    onChange(value, expand.item)
  }
  useEffect(() => {
    if (!admin) {
      slotFetch()
      FormatTherapists()
    }
  }, [])
  useEffect(() => {
    if (admin) {
      setListTherapist(therapistList.addAppointmetnsList)
      setPagesettings({
        currentPage: therapistList?.currentPage,
        totalPageNumbers: therapistList?.totalPageNumbers
      })
      setExpand(stateObj)
    }
  }, [therapistList])

  useEffect(() => {
    if (slotsData?.userAvailableSp) {
      setListSlots(slotsData?.userAvailableSp)
    }
  }, [slotsData, setListSlots])
  useEffect(() => {
    setLoadingTherapist(therapistLoader)
  }, [therapistLoader])

  return (
    <Grid container className="detail-container">
      {isLoading()}

      <List
        className={`${classes.ListRoot} ${classes.ListItemBottom}`}
        style={{
          border: '1px solid',
          padding: '0px',
          minHeight: '70px',
          textAlign: 'center',
          margin: '5px 0'
        }}
      >
        {listTherapist?.length >= 1 &&
          !loadingTherapist &&
          listTherapist?.map((item, i) => {
            return (
              <ListItem alignItems="flex-start" className={classes.listItem}>
                <ListItemText
                  className={classes.div1}
                  primary={
                    <Grid container>
                      <Grid item sm={3}>
                        {item.name}
                      </Grid>
                      <Grid item sm={3}>
                        {item.agency_name}
                      </Grid>
                      <Grid item sm={3}>
                        {item.service_name}
                      </Grid>
                      <Grid item sm={3} style={{ maxWidth: '22%' }}>
                        {item.street_address}
                      </Grid>
                      {item?.type == true && (
                        <Grid
                          item
                          sm={3}
                          style={{ marginTop: '-23px', marginLeft: '95%', height: '45px' }}
                        >
                          {item.user_email}
                        </Grid>
                      )}
                    </Grid>
                  }
                />
                <Collapse
                  in={index === i && flag}
                  timeout="auto"
                  unmountOnExit
                  className={classes.div3}
                >
                  {slotsData && slotsData?.userAvailableSp && (
                    <List component="div" disablePadding>
                      <ListItem>
                        <TimeSlots
                          item={slotsData?.userAvailableSp}
                          slotSelected={(value) => slotSelected(value)}
                          provider
                        />
                      </ListItem>
                    </List>
                  )}
                </Collapse>
                {item?.type == false && (
                  <ListItemSecondaryAction>
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                      checked={index === i && flag}
                      name="checkedH"
                      onChange={(e) => {
                        selectedTherapist(i, e.target.checked, item)
                      }}
                      style={{ color: '#f1583a' }}
                      disabled={!admin}
                    />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            )
          })}
        {listTherapist?.length === 0 && (
          <Typography style={{ color: ' #757575', fontWeight: '600', padding: '25px 0' }}>
            No Results Found
          </Typography>
        )}
      </List>
      {errorObject?.slotSelected && (
        <Typography style={{ color: '#f50b2b', width: '100%' }}>Please Select slot</Typography>
      )}
      {admin && !loadingTherapist && listTherapist?.length >= 1 && (
        <Pagination
          className="therapist-pagination"
          count={pageSettings.totalPageNumbers}
          style={{ margin: 'auto', padding: '5px' }}
          defaultPage={pageSettings?.currentPage}
          onChange={(event, val) => {
            pagination(val)
          }}
        />
      )}

      <InputField
        title={'Selected Slot'}
        value={
          selectedSlot?.timeperiod
            ? `${moment(selectedSlot?.timeperiod).utc().format('MM-DD-YYYY')}, ${
                selectedSlot?.time_slot
              }`
            : ' - '
        }
      />
    </Grid>
  )
}

export default TherapistDetails
