import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  ToolbarItems,
  ExcelExport,
  Grid,
  Inject,
  Toolbar,
  Page,
  ExcelExportProperties,
  SortSettingsModel,
  Sort
} from '@syncfusion/ej2-react-grids'
import React, { useMemo, useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { Typography, Link, Select, MenuItem } from '@material-ui/core'
import { theme } from 'config/constants/theme'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import {
  useMandatoryreportLazyQuery,
  useMandatoryreportQuery,
  useTherapistListForDocLazyQuery,
  useListAgencyLazyQuery,
  useCurrentUserQuery,
  useProviderNameListLazyQuery,
  Role
} from '../../../../graphql/hooks.generated'
import { useStyles } from '../../../../config/constants/theme/profile'

export const Mandatoryreport = () => {
  const history = useHistory()
  const classes = useStyles()
  let grid: Grid | null
  const toolbar: ToolbarItems[] = ['ExcelExport']
  const [reportQuery, { data, loading, refetch }] = useMandatoryreportLazyQuery({
    fetchPolicy: 'network-only'
  })
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const [executeThepistQuery, { data: therapistData }] = useTherapistListForDocLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      district_id: Number(currentUserdata?.data?.currentUser?.district?.id)
    }
  })
  const fetchTherapists = useMemo(() => () => executeThepistQuery(), [executeThepistQuery])
  const [executeAgencyQuery, { data: agencyData }] = useListAgencyLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetchAgency = useMemo(() => () => executeAgencyQuery(), [executeAgencyQuery])
  // const fetch = useMemo(() => () => reportQuery(), [reportQuery])
  // useEffect(() => {
  //   fetch()
  // }, [fetch])
  const lastOneWeek = moment().subtract(7, 'days').format('YYYY-MM-DD')
  const currentDate = moment().format('YYYY-MM-DD')
  const [billfrom, BillFrom] = useState(lastOneWeek)
  const [billTo, BillTo] = useState(currentDate)
  const [statusTo, StatusTo] = useState()
  const [clientstatus, Clientstatus] = useState()
  const [userstatus, Userstatus] = useState()
  const [sessioncount, Sessioncount] = useState()
  const [userid, Userid] = useState()
  const [agencyid, Agencyid] = useState()
  const [providername, setProvidername] = useState('')
  const { hasAnyRole } = useCurrentUser()
  const [
    executeTherapistQuery,
    { data: providerData, loading: therapistDocumentLoading }
  ] = useProviderNameListLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      agency: String(
        currentUserdata?.data?.currentUser?.signalAgencies &&
          currentUserdata?.data?.currentUser?.signalAgencies[0]?.name
          ? currentUserdata?.data?.currentUser?.signalAgencies[0]?.name
          : 0
      )
    }
  })
  const fetchProvider = useMemo(() => () => executeTherapistQuery(), [executeTherapistQuery])
  useEffect(() => {
    fetchProvider()
  }, [fetchProvider])
  useEffect(() => {
    if (providerData && currentUserdata) {
      const provider = providerData?.providerName?.[0]?.provider_id
      const currentprovider = currentUserdata?.data?.currentUser?.roles?.[0]
      setProvidername(
        currentprovider === Role.AgencySupervisorProvider
          ? currentUserdata?.data?.currentUser?.id || ''
          : 'All' || ''
      )
    }
  }, [currentUserdata, providerData, providerData?.providerName])
  const providerchange = (e) => {
    setProvidername(e.target.value)
  }
  const FromDate = (e) => {
    BillFrom(e.target.value)
  }
  const ToDate = (e) => {
    BillTo(e.target.value)
  }
  const clientstatusone = (e) => {
    Clientstatus(e.target.value)
  }
  const userstatusone = (e) => {
    Userstatus(e.target.value)
  }
  const sessioncountone = (e) => {
    Sessioncount(e.target.value)
  }
  const useridone = (e) => {
    Userid(e.target.value)
  }
  const agencyidone = (e) => {
    Agencyid(e.target.value)
  }
  const statussone = (e) => {
    StatusTo(e.target.value)
  }
  const sortSettings: SortSettingsModel = {
    columns: [
      { field: 'agency', direction: 'Ascending' },
      { field: 'providername', direction: 'Ascending' },
      { field: 'appointment_date', direction: 'Ascending' }
    ]
  }
  const Fromformat = moment(billfrom).format('MM/DD/YYYY')
  const Toformat = moment(billTo).format('MM/DD/YYYY')
  const toolbarClick = (args) => {
    if (grid && args.item.id === 'grid_excelexport') {
      const excelExportProperties: ExcelExportProperties = {
        fileName: `MandatoryReport(${billfrom}_${billTo}).xlsx`,
        header: {
          headerRows: 4,
          rows: [
            {
              cells: [
                {
                  colSpan: 4,
                  value: 'Mandatory Report',
                  style: { fontSize: 15, bold: true }
                }
              ]
            },
            {
              cells: [
                {
                  colSpan: 4,
                  value: `From Date: ${Fromformat}`,
                  style: { fontSize: 13, bold: true }
                }
              ]
            },
            {
              cells: [
                {
                  colSpan: 4,
                  value: `To Date: ${Toformat}`,
                  style: { fontSize: 13, bold: true }
                }
              ]
            }
          ]
        }
      }
      grid.excelExport(excelExportProperties)
    }
  }
  const reportquery = useMemo(
    () => ({
      from_date,
      to_date,
      status_id,
      user_id,
      agency_id,
      sessions_count,
      client_status,
      user_status
    }) =>
      reportQuery({
        variables: {
          from_date,
          to_date,
          status_id,
          user_id,
          agency_id,
          sessions_count,
          client_status,
          user_status
        }
      }),
    [reportQuery]
  )
  const provider = hasAnyRole(Role.AgencySupervisor, Role.AgencySupervisorProvider)
  const agency = String(currentUserdata?.data?.currentUser?.signalAgencies?.[0]?.id)
  useEffect(() => {
    if (billfrom && billTo) {
      const input = {
        from_date: billfrom,
        to_date: billTo,
        status_id: statusTo,
        user_id: provider ? providername : userid,
        agency_id: provider ? agency : agencyid,
        sessions_count: sessioncount,
        client_status: clientstatus,
        user_status: userstatus
      }
      reportquery(input)
      fetchTherapists()
      fetchAgency()
    }
  }, [
    billfrom,
    billTo,
    statusTo,
    clientstatus,
    userstatus,
    sessioncount,
    userid,
    agencyid,
    reportquery,
    fetchTherapists,
    fetchAgency,
    provider,
    providername,
    agency
  ])

  return (
    <>
      <PageContainer>
        <div style={{ display: 'flex' }}>
          <Typography variant="h3" style={{ color: theme.palette.info.main, padding: '12px' }}>
            {' '}
            Mandatory Report{' '}
          </Typography>
          {hasAnyRole(Role.AgencySupervisorProvider, Role.AgencySupervisor) && (
            <div style={{ fontSize: '16px', padding: '12px', marginLeft: 'auto' }}>
              <span>
                {' '}
                <b style={{ fontSize: '16px' }}>Provider:</b>{' '}
                <Select
                  style={{
                    width: '150px',
                    height: '32px',
                    marginRight: '8px',
                    textAlign: 'initial'
                  }}
                  variant="filled"
                  value={providername}
                  className={classes.selectDropdown}
                  onChange={providerchange}
                  classes={{
                    root: 'muisc-root',
                    select: 'muisc-select',
                    selectMenu: 'muisc-select-menu'
                  }}
                >
                  <MenuItem value="All">All</MenuItem>
                  {providerData?.providerName && providerData?.providerName?.length > 0 ? (
                    providerData?.providerName?.map((listTherapists) => (
                      <MenuItem
                        key={listTherapists.provider_id?.toString()}
                        value={listTherapists.provider_id?.toString()}
                        style={{
                          maxWidth: '280px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {listTherapists.provider_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="No Data Found">No Data Found</MenuItem>
                  )}
                </Select>
              </span>
            </div>
          )}
        </div>
        <div style={{ position: 'relative' }}>
          <div
            style={{
              textAlign: 'end',
              position: 'absolute',
              top: '8px',
              right: '10px',
              zIndex: 99,
              display: 'flex'
            }}
          >
            <span style={{ display: 'flex', flexDirection: 'column' }}>
              {' '}
              <b style={{ fontSize: '16px', textAlign: 'left' }}>Session Count (or more):</b>{' '}
              <Select
                style={{
                  width: '146px',
                  height: '32px',
                  marginRight: '35px',
                  textAlign: 'initial'
                }}
                className={classes.selectDropdown}
                defaultValue={sessioncount}
                // label="assignto"
                variant="filled"
                onChange={sessioncountone}
                classes={{
                  root: 'muisc-root',
                  select: 'muisc-select',
                  selectMenu: 'muisc-select-menu'
                }}
              >
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="6">6</MenuItem>
              </Select>
            </span>
            <span style={{ display: 'flex', flexDirection: 'column' }}>
              {!hasAnyRole(Role.AgencySupervisorProvider, Role.AgencySupervisor) && (
                <span style={{ marginBottom: '5px' }}>
                  {' '}
                  <b style={{ fontSize: '16px' }}>Agency:</b>{' '}
                  <Select
                    style={{
                      width: '150px',
                      height: '32px',
                      marginRight: '8px',
                      textAlign: 'initial'
                    }}
                    className={classes.selectDropdown}
                    defaultValue={agencyid}
                    // label="assignto"
                    variant="filled"
                    onChange={agencyidone}
                    classes={{
                      root: 'muisc-root',
                      select: 'muisc-select',
                      selectMenu: 'muisc-select-menu'
                    }}
                  >
                    {agencyData?.agencyList?.map((listagency) => (
                      <MenuItem
                        key={listagency.id?.toString()}
                        // placeholder="Select provider"
                        value={listagency.id?.toString()}
                      >
                        {listagency.name}
                      </MenuItem>
                    ))}
                  </Select>
                </span>
              )}
              {!hasAnyRole(Role.AgencySupervisorProvider, Role.AgencySupervisor) && (
                <span>
                  {' '}
                  <b style={{ fontSize: '16px' }}>Provider:</b>{' '}
                  <Select
                    style={{
                      width: '150px',
                      height: '32px',
                      marginRight: '8px',
                      textAlign: 'initial'
                    }}
                    className={classes.selectDropdown}
                    defaultValue={userid}
                    // label="assignto"
                    variant="filled"
                    onChange={useridone}
                    classes={{
                      root: 'muisc-root',
                      select: 'muisc-select',
                      selectMenu: 'muisc-select-menu'
                    }}
                  >
                    {therapistData?.therapistListForDoc?.map((listTherapists) => (
                      <MenuItem
                        key={listTherapists.id?.toString()}
                        // placeholder="Select provider"
                        value={listTherapists.id?.toString()}
                      >
                        {listTherapists.name}
                      </MenuItem>
                    ))}
                  </Select>
                </span>
              )}
            </span>
            <span style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ marginBottom: '5px' }}>
                {' '}
                <b style={{ fontSize: '16px' }}>Client Status:</b>{' '}
                <Select
                  style={{
                    width: '85px',
                    height: '32px',
                    marginRight: '8px',
                    textAlign: 'initial'
                  }}
                  className={classes.selectDropdown}
                  defaultValue={clientstatus}
                  // label="assignto"
                  variant="filled"
                  onChange={clientstatusone}
                  classes={{
                    root: 'muisc-root',
                    select: 'muisc-select',
                    selectMenu: 'muisc-select-menu'
                  }}
                >
                  <MenuItem value="1">Open</MenuItem>
                  <MenuItem value="0">Closed</MenuItem>
                </Select>
              </span>
              <span>
                {' '}
                <b style={{ fontSize: '16px' }}>User Status:</b>{' '}
                <Select
                  style={{
                    width: '85px',
                    height: '32px',
                    marginRight: '8px',
                    textAlign: 'initial'
                  }}
                  className={classes.selectDropdown}
                  defaultValue={userstatus}
                  // label="assignto"
                  variant="filled"
                  onChange={userstatusone}
                  classes={{
                    root: 'muisc-root',
                    select: 'muisc-select',
                    selectMenu: 'muisc-select-menu'
                  }}
                >
                  <MenuItem value="ACTIVE">Active</MenuItem>
                  <MenuItem value="INACTIVE">Inactive</MenuItem>
                </Select>
              </span>
            </span>
            <span style={{ marginTop: '18px', marginRight: '8px' }}>
              {' '}
              <b style={{ fontSize: '16px' }}> From Date:</b>{' '}
              <TextField
                style={{ border: '1px solid #000', marginRight: '0px', width: '120px' }}
                name="FromDate"
                type="Date"
                onChange={FromDate}
                defaultValue={billfrom}
              />
            </span>{' '}
            <span style={{ marginTop: '18px' }}>
              {' '}
              <b style={{ fontSize: '16px' }}>To Date:</b>{' '}
              <TextField
                style={{ border: '1px solid #000', marginRight: '8px', width: '120px' }}
                name="ToDate"
                type="Date"
                onChange={ToDate}
                defaultValue={billTo}
              />
            </span>
            <span style={{ marginTop: '18px' }}>
              {' '}
              <b style={{ fontSize: '16px' }}>Status:</b>{' '}
              <Select
                style={{ width: '95px', height: '32px', marginRight: '8px', textAlign: 'initial' }}
                className={classes.selectDropdown}
                defaultValue={statusTo}
                // label="assignto"
                variant="filled"
                onChange={statussone}
                classes={{
                  root: 'muisc-root',
                  select: 'muisc-select',
                  selectMenu: 'muisc-select-menu'
                }}
              >
                <MenuItem value="1">Open to Provider</MenuItem>
                <MenuItem value="2">Open to Client</MenuItem>
                <MenuItem value="3">Completed</MenuItem>
                <MenuItem value="4">Open to Client & Provider</MenuItem>
              </Select>
              <br />
            </span>
          </div>
          {loading && <ProgressIndicator fullHeight />}
          <GridComponent
            dataSource={data?.mandatoryreports || []}
            allowTextWrap
            allowPaging
            pageSettings={{ pageSizes: [15, 25, 50, 100], pageSize: 15, pageCount: 5 }}
            allowSorting
            id="grid"
            toolbar={toolbar}
            allowExcelExport
            toolbarClick={(e) => toolbarClick(e)}
            ref={(g) => {
              grid = g
            }}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="agency"
                headerText="Agency"
                width="110"
                allowFiltering={false}
              />
              <ColumnDirective
                field="providername"
                headerText="Provider Name"
                width="120"
                allowFiltering={false}
              />
              <ColumnDirective
                field="client_name"
                template={(report) => (
                  <Link
                    onClick={(e) => {
                      history.push({
                        pathname: `/studentsinfo/${btoa(
                          `${report?.student_id}_${report?.student_id}`
                        )}`,
                        state: report?.provider_id
                      })
                    }}
                    style={{ color: 'black', cursor: 'pointer' }}
                  >
                    <span>{`${report?.client_name}`}</span>
                  </Link>
                )}
                headerText="Client"
                width="110"
              />
              <ColumnDirective
                field="client_id"
                headerText="Client Id"
                width="100"
                allowFiltering={false}
              />
              <ColumnDirective
                field="county"
                headerText="County"
                width="90"
                allowFiltering={false}
              />
              <ColumnDirective
                field="zip"
                headerText="Zip Code"
                width="80"
                allowFiltering={false}
              />
              <ColumnDirective
                field="appointment_date"
                headerText="Most Recent Present Appointment"
                template={(mandatory) => (
                  // <div>{moment(mandatory?.appointment_date).format('MM-DD-YYYY')}</div>
                  <div>
                    {mandatory?.appointment_date
                      ? moment(mandatory?.appointment_date).format('MM-DD-YYYY')
                      : ''}
                  </div>
                )}
                width="130"
                allowFiltering={false}
              />
              <ColumnDirective
                field="sessions_count"
                headerText="Completed Sessions"
                width="120"
                allowFiltering={false}
              />
              {/* <ColumnDirective
                field="start_time"
                headerText="Appointment Start Time"
                width="130"
                allowFiltering={false}
              />
              <ColumnDirective
                field="end_time"
                headerText="Appointment End Time"
                width="130"
                allowFiltering={false}
              /> */}
              <ColumnDirective
                field="servicename"
                headerText="In-Person/ Telehealth"
                width="110"
                allowFiltering={false}
              />
              <ColumnDirective
                field="Mandatory Disclosure Status"
                headerText="Mandatory Disclosure Status"
                width="130"
                allowFiltering={false}
              />
              <ColumnDirective
                field="user_status"
                headerText="User Status"
                width="130"
                allowFiltering={false}
              />
            </ColumnsDirective>
            <Inject services={[Toolbar, ExcelExport, Page, Sort]} />
          </GridComponent>
        </div>
      </PageContainer>
    </>
  )
}
