import { Grid, InputLabel, Box } from '@material-ui/core'
import { makeValidate, TextField, Checkboxes } from 'mui-rff'
import React, { useEffect, useState, useMemo } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Form, FormRenderProps } from 'react-final-form'
import { useHistory, useParams } from 'react-router-dom'
import { UseParamsType } from 'types'
import * as yup from 'yup'
import { useSurveyContactUsLazyQuery, useSurveyContactUsQuery } from 'graphql/hooks.generated'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { engLoc, spaLoc } from 'config/constants/translations'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import Button from '../../../components/ui/panel/button/Button'
import { PageContainer } from '../../../components/ui/panel/page-container/PageContainer'
import { InfoButton } from '../../../components/ui/panel/button/InfoButton'
import { Section } from '../../../components/ui/panel/section/Section'
import BackgroundImage from '../../../assets/illustrations/backgroundimatterimage.png'
// import { Language } from '@material-ui/icons'

export interface ContactUSFormModel {
  name: string
  email: any
  body: string
  // language: number
}

interface ContactUsParams extends UseParamsType {
  language_id: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelField: {
      '& .MuiOutlinedInput-input': {
        padding: '11px 14px',
        backgroundColor: '#fff !important',
        border: '1px solid #000',
        fontFamily: 'Poppins'
      },
      '& .MuiOutlinedInput-inputMultiline': {
        border: '1px solid #000'
      },
      '& .MuiOutlinedInput-multiline': {
        padding: '0px'
      },
      '& .MuiFormHelperText-root.Mui-error': {
        display: 'none'
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderWidth: '1px'
      }
    },
    contpadd: {
      padding: '0px 15px 0px 50px'
    },
    gridalign: {
      maxWidth: '700px',
      backgroundColor: '#FFFFFF',
      marginTop: '40px',
      marginLeft: '50px',
      marginBottom: '50px',
      fontFamily: 'Poppins'
    },
    colBlak: {
      color: '#000',
      fontFamily: 'Poppins !important'
    },
    iBlock: {
      display: 'inline-block',
      marginBottom: '10px'
    },
    errorCol: {
      color: '#f44336',
      fontFamily: 'Poppins'
    },
    backgroundImage: {
      backgroundImage: `url(${BackgroundImage})`,
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      minHeight: 'calc(100vh - 60px - 75px)'
    },
    labelError: {
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)'
      }
    }
  })
)
const notemaxErrMsg = 'Exceeded maximum 1000 characters'
const submaxErrMsg = 'Exceeded maximum 30 characters'
const newErrMsg = 'Email Address must be a valid email'
const contactUsvalidator = () =>
  makeValidate(
    yup.object<ContactUSFormModel>({
      name: yup
        .string()
        .label('First and Last Name')
        .required('First and Last Name is a required field')
        .min(1)
        .max(30),
      email: yup
        .string()
        .email('Email Address must be a valid email')
        .label('Email Address')
        .required('Email Address is a required field'),
      body: yup
        .mixed<string>()
        .label('Message')
        .required()
        .test('len', notemaxErrMsg, (val) => {
          let flag = false

          if (val && val?.toString().length < 1000) flag = true

          return flag
        })
      // language: yup.number()
    })
  )

export const ContactUsForm = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-T644KWW'
    }
    TagManager.initialize(tagManagerArgs)
  }, [])
  const history = useHistory()
  const classes = useStyles()
  const { language_id } = useParams<ContactUsParams>()
  const validator = useMemo(() => contactUsvalidator(), [])
  const [complete, setComplete] = useState(false)
  const [surveyContactUs, { loading }] = useSurveyContactUsLazyQuery({
    fetchPolicy: 'network-only'
  })
  let langcode
  const { t, i18n } = useTranslation()
  useMemo(() => {
    i18n.changeLanguage(language_id)
  }, [i18n, language_id])
  //   const onSubmit = (values: ClientFillingFormModel) => props.onSubmit(values)
  const handleFormSubmit = async (values: ContactUSFormModel) => {
    surveyContactUs({
      variables: {
        name: values.name,
        body: values.body,
        email: values.email,
        language: language_id === 'es' ? 2 : 1
      }
    })
    setComplete(true)
  }
  const isLoading = () => (loading ? <ProgressIndicator fullHeight /> : '')
  const renderForm = (renderProps: FormRenderProps<ContactUSFormModel>) => {
    const { handleSubmit, errors, submitFailed, values } = renderProps

    return (
      <>
        <div>
          <form>
            <Grid container className={classes.backgroundImage}>
              {complete === false ? (
                <Box>
                  <Grid container spacing={4} className={classes.gridalign}>
                    <Grid
                      item
                      sm={12}
                      style={{
                        paddingTop: 20,
                        paddingLeft: '65px',
                        paddingBottom: '0px',
                        fontFamily: 'Poppins',
                        fontSize: '17px'
                      }}
                    >
                      {language_id === 'en'
                        ? engLoc.contactus.headerTxt
                        : spaLoc.contactus.headerTxt}
                    </Grid>
                    <Grid item sm={12}>
                      <Grid
                        container
                        spacing={3}
                        className={`${classes.contpadd} ${classes.labelError}`}
                      >
                        <Grid item sm={11}>
                          <div
                            className={`${
                              submitFailed && errors?.name?.length
                                ? classes.errorCol
                                : classes.colBlak
                            }`}
                          >
                            <InputLabel
                              htmlFor="name"
                              className={`${
                                submitFailed && errors?.name?.length
                                  ? classes.errorCol
                                  : classes.colBlak
                              } ${classes.iBlock}`}
                            >
                              {language_id === 'en' ? engLoc.contactus.name : spaLoc.contactus.name}
                              * :
                            </InputLabel>
                          </div>
                          <TextField
                            required
                            id="label-mi"
                            variant="outlined"
                            name="name"
                            className={classes.labelField}
                          />
                          {values && values?.name?.length > 30 && (
                            <div
                              style={{ paddingTop: '5px', fontSize: '12px', fontFamily: 'Poppins' }}
                              className={classes.errorCol}
                            >
                              {submaxErrMsg}
                            </div>
                          )}
                        </Grid>
                        <Grid item sm={11}>
                          <div
                            className={`${
                              submitFailed && errors?.email?.length
                                ? classes.errorCol
                                : classes.colBlak
                            }`}
                          >
                            <InputLabel
                              htmlFor="email"
                              className={`${
                                submitFailed && errors?.email?.length
                                  ? classes.errorCol
                                  : classes.colBlak
                              }`}
                            >
                              {language_id === 'en'
                                ? engLoc.contactus.email
                                : spaLoc.contactus.email}
                              * :
                            </InputLabel>
                          </div>
                          <div style={{ paddingTop: '10px' }}>
                            <TextField
                              required
                              id="label-mi"
                              variant="outlined"
                              name="email"
                              className={classes.labelField}
                              // multiline
                              rows={7}
                            />
                            {errors &&
                              errors?.email?.length === 1 &&
                              errors?.email[0] === newErrMsg && (
                                <div
                                  style={{
                                    paddingTop: '5px',
                                    fontSize: '12px',
                                    fontFamily: 'Poppins'
                                  }}
                                  className={classes.errorCol}
                                >
                                  {newErrMsg}
                                </div>
                              )}
                          </div>
                        </Grid>
                        <Grid item sm={11}>
                          <div
                            className={`${
                              submitFailed && errors?.body?.length
                                ? classes.errorCol
                                : classes.colBlak
                            }`}
                          >
                            <InputLabel
                              htmlFor="body"
                              className={`${
                                submitFailed && errors?.body?.length
                                  ? classes.errorCol
                                  : classes.colBlak
                              }`}
                            >
                              {language_id === 'en'
                                ? engLoc.contactus.message
                                : spaLoc.contactus.message}
                              * :
                            </InputLabel>
                          </div>
                          <div style={{ paddingTop: '10px' }}>
                            <TextField
                              required
                              id="label-mi"
                              variant="outlined"
                              name="body"
                              className={classes.labelField}
                              multiline
                              rows={7}
                            />
                            {errors &&
                              errors?.body?.length === 1 &&
                              errors?.body[0] === notemaxErrMsg && (
                                <div
                                  style={{
                                    paddingTop: '5px',
                                    fontSize: '12px',
                                    fontFamily: 'Poppins'
                                  }}
                                  className={classes.errorCol}
                                >
                                  {notemaxErrMsg}
                                </div>
                              )}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item sm={12} style={{ paddingTop: 20, marginLeft: '5px' }}>
                      <Button
                        size="large"
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: '485px', marginRight: '30px', fontFamily: 'Poppins' }}
                        onClick={() => handleSubmit()}
                      >
                        {language_id === 'en' ? engLoc.contactus.sendbtn : spaLoc.contactus.sendbtn}
                      </Button>
                      {/* <Button size="large" variant="outlined" color="primary" onClick={history.goBack}>
            Cancel
          </Button> */}
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '350px',
                    fontSize: '20px',
                    color: 'white',
                    fontFamily: 'Poppins',
                    flex: 'auto'
                  }}
                >
                  <div
                    style={{
                      background: 'white',
                      borderRadius: '10px',
                      padding: '20px',
                      fontFamily: 'Poppins'
                    }}
                  >
                    <div style={{ color: 'black', display: 'inline-block' }}>
                      {language_id === 'en' ? engLoc.contactus.sendmesg : spaLoc.contactus.sendmesg}
                    </div>
                    {/* <span style={{ color: 'blue', marginLeft: '5px', display: 'inline-block' }}>
            <a href="mailto: support@imattercolorado.org">
              {langcode === 'en' ? engLoc.contactus.sendmssg : spaLoc.contactus.sendmssg}
            </a>
          </span> */}
                  </div>
                </div>
              )}
            </Grid>
            {isLoading()}
          </form>
        </div>
      </>
    )
  }

  return (
    <Form<ContactUSFormModel>
      validate={validator}
      render={renderForm}
      onSubmit={handleFormSubmit}
    />
  )
}
