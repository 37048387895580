import React, { useState } from 'react'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Search,
  Inject,
  ToolbarItems,
  Page,
  Sort,
  SortSettingsModel,
  Grid
} from '@syncfusion/ej2-react-grids'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  useTheme,
  Avatar
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { SearchFilterData } from 'views/redux/searchFilterRedux/actions/searchFilterActions'
import { ContactsOutlined, SettingsBackupRestoreOutlined } from '@material-ui/icons'
import Paper from '../../../../components/ui/panel/paper/Paper'
import Button from '../../../../components/ui/panel/button/Button'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { useUsersQuery, useDeleteUserMutation, Role } from '../../../../graphql/hooks.generated'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'
import { useCurrentUser } from '../../../../graphql/hooks/useCurrentUser/useCurrentUser'

export const UserListView: React.FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()
  const sfStoreState: any = useSelector((state) => state)
  let grid: Grid | null
  const { data: currentUserData, hasAnyRole } = useCurrentUser()
  const { data, loading, refetch } = useUsersQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        districtId: hasAnyRole(Role.TeleteachersAdmin)
          ? undefined
          : Number(currentUserData?.currentUser.district?.id)
      }
    }
  })
  const [deleteUser] = useDeleteUserMutation()
  const onDeleteUser = async (id) => {
    await deleteUser({ variables: { id } })
    refetch()
  }
  const handleClick = () => {
    history.push('/users/create')
  }
  const isLoading = () => (loading ? <ProgressIndicator fullHeight /> : '')
  const sortSettings: SortSettingsModel = {
    columns: [
      {
        field: sfStoreState?.userlistq?.columnName
          ? sfStoreState?.userlistq?.columnName
          : 'first_name',
        direction: sfStoreState?.userlistq?.columnDirection
          ? sfStoreState?.userlistq?.columnDirection
          : 'Ascending'
      }
      // { field: 'email', direction: 'Ascending' }
    ]
  }
  const searchOptions = {
    fields: ['first_name', 'last_name', 'email'],
    key: sfStoreState?.userlistq?.searchText,
    ignoreCase: true
  }
  const pageOptions = {
    pageSizes: [15, 25, 50, 100],
    pageSize: sfStoreState?.userlistq?.pageSize ? sfStoreState?.userlistq?.pageSize : 15,
    pageCount: 5,
    currentPage: sfStoreState?.userlistq?.currentPage ? sfStoreState?.userlistq?.currentPage : 1
  }
  const toolbarOptions: ToolbarItems[] = ['Search']
  const createdevent = () => {
    document
      .getElementById(`${grid?.element?.id}_searchbutton`)
      ?.addEventListener('click', (event) => {
        const val = (document.getElementById(`${grid?.element?.id}_searchbar`) as HTMLInputElement)
          ?.value
        dispatch(
          SearchFilterData({
            ...sfStoreState,
            userlistq: { ...sfStoreState.userlistq, searchText: val }
          })
        )
      })
    document
      .getElementById(`${grid?.element?.id}_searchbar`)
      ?.addEventListener('keyup', (event) => {
        if (event?.key === 'Enter') {
          const val = (document.getElementById(
            `${grid?.element?.id}_searchbar`
          ) as HTMLInputElement)?.value
          dispatch(
            SearchFilterData({
              ...sfStoreState,
              userlistq: { ...sfStoreState.userlistq, searchText: val }
            })
          )
        }
      })
  }
  const gridref = (e) => {
    grid = e
  }
  const handleActionBegin = (args) => {
    if (args?.requestType === 'sorting')
      dispatch(
        SearchFilterData({
          ...sfStoreState,
          userlistq: {
            ...sfStoreState.userlistq,
            columnName: args?.columnName,
            columnDirection: args?.direction
          }
        })
      )
    if (args && args?.requestType === 'paging')
      dispatch(
        SearchFilterData({
          ...sfStoreState,
          userlistq: {
            ...sfStoreState.userlistq,
            currentPage: grid?.pageSettings?.currentPage,
            pageSize: grid?.pageSettings?.pageSize
          }
        })
      )
  }

  return (
    <>
      <NavBar title="Users" goBackBtn />
      <PageContainer>
        <div className="control-pane">
          <div className="control-section">
            <GridComponent
              key={Math.floor(Math.random() * 100)}
              allowTextWrap
              dataSource={data?.users || []}
              width="auto"
              toolbar={toolbarOptions}
              allowPaging
              pageSettings={pageOptions}
              allowSorting
              sortSettings={sortSettings}
              searchSettings={searchOptions}
              className={classes.headerCls}
              ref={(g) => gridref(g)}
              created={createdevent}
              actionComplete={(value) => handleActionBegin(value)}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="first_name"
                  template={(names) => (
                    <span className={classes.nameCls}>
                      {`${names.first_name} ${names.last_name}`}
                    </span>
                  )}
                  headerText="Name"
                  width="200"
                />
                <ColumnDirective field="email" headerText="Email" width="230" />
                <ColumnDirective
                  // field="actions"
                  template={(editData) => (
                    <>
                      <Link
                        onClick={() => history.push(`/users/${editData.id}/edit`)}
                        style={{ color: 'black', cursor: 'pointer' }}
                      >
                        {' '}
                        Edit{' '}
                      </Link>
                      {/* &nbsp; &nbsp; */}
                      {/* <Link
                        onClick={() => onDeleteUser(editData.id)}
                        style={{ color: 'black', cursor: 'pointer' }}
                      >
                        {' '}
                        Delete{' '}
                      </Link> */}
                    </>
                  )}
                  headerText="Actions"
                  width="90"
                />
                <ColumnDirective field="status" headerText="Status" width="100" />
              </ColumnsDirective>
              <Inject services={[Search, Toolbar, Sort, Page]} />
              <div
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  width: 'auto',
                  paddingTop: '10px',
                  paddingLeft: '10px',
                  fontFamily: 'Brandon Grotesque'
                }}
              >
                <span style={{ color: theme.palette.info.main }}>Users</span>
              </div>
              <Button
                color="primary"
                variant="contained"
                size="small"
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="mar-rt-15 custom-btn addEvent"
                onClick={handleClick}
              >
                Add user
              </Button>
            </GridComponent>
          </div>
        </div>
        {isLoading()}
      </PageContainer>
    </>
  )
}
