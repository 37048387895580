import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '85%',
      margin: '0px auto 0px',
      background: '#fff',
      fontFamily: 'Poppins',
      [theme.breakpoints.down('xs')]: {
        '& .MuiStepper-root': {
          padding: '10px 0px'
        },
        '& .MuiStep-horizontal': {
          paddingLeft: '4px',
          paddingRight: '4px'
        },
        '& .MuiFormControlLabel-root': {
          alignItems: 'flex-start',
          marginTop: '7px'
        },
        '& .MuiRadio-root': {
          padding: '0px 9px 9px 9px'
        },
        width: '98%'
      }
    },
    ff: {
      fontFamily: 'Poppins !important',
      fontSize: ' 18px'
    },
    backButton: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    jleft: {
      justifyContent: 'flex-start'
    },
    p10: {
      padding: '10px'
    },
    p20: {
      padding: '20px'
    },
    pt10: {
      paddingTop: '10px'
    },
    pb10: {
      paddingBottom: '10px'
    },
    plr20: {
      paddingLeft: '20px',
      paddingRight: '20px'
    },
    ptbl20: {
      paddingLeft: '20px',
      paddingTop: '20px',
      paddingBottom: '20px'
    },
    center: {
      textAlign: 'center'
    },
    pr200: {
      paddingRight: '200px'
    },
    errorColor: {
      color: theme.palette.error.dark,
      fontFamily: 'Poppins'
    },
    disableBorder: {
      border: '1px solid lightgrey'
    },
    parentPop: {
      '& .MuiGrid-container': {
        fontFamily: 'poppin !important'
      }
    },
    activeBorder: {
      border: '1px solid #000'
    },
    defaultColor: {
      color: '#000'
    },
    primaryColor: {
      color: '#293f70'
    },
    selectDropdown: {
      bacgroundColor: '#fff',
      border: '1px solid #AAAAAA',
      backgroundColor: 'white',
      fontSize: '13px',
      fontFamily: 'Poppins',
      height: 30,
      width: '100px',
      paddingTop: 0,
      paddingLeft: 0,
      borderRadius: 3,
      '& .MuiFilledInput-input': {
        backgroundColor: '#fff !important'
      },
      '& .MuiSelect-filled.MuiSelect-filled': {
        backgroundColor: '#fff !important'
      },
      '& div': {
        padding: '6px'
      },
      selectMenu: {
        bacgroundColor: '#fff',
        padding: '0px !important',
        fontSize: '16px'
      }
    },
    line: {
      borderBottom: '1px solid lightgrey',
      marginTop: '15px'
    },
    bold: {
      fontWeight: 'bold',
      color: '#293f70'
    },
    pb5: {
      paddingBottom: '5px'
    },
    midiumtextBold: {
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#293f70'
    },
    smalltextBold: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#293f70'
    },
    popin: {
      fontFamily: 'Poppins !important'
    },
    fs: {
      fontSize: '16px'
    },
    fs1: {
      fontSize: '14px'
    },
    yuthfs: {
      fontSize: '16px'
    },
    floatLeft: {
      float: 'left'
    },
    mt3: {
      marginTop: '3px'
    },
    w15: {
      width: '15px'
    },
    h20: {
      height: '20px'
    },
    fs16: {
      fontSize: '16px'
    },
    widthFull: {
      width: '100%'
    },
    dInline: {
      display: 'inline-block'
    },
    pt5: {
      paddingTop: '5px'
    },
    w20: {
      width: '20px'
    },
    mt2: {
      marginTop: '2px'
    },
    pb15: {
      paddingBottom: '15px'
    },
    textcolor: {
      color: '#F1583A',
      fontSize: '15px',
      fontWeight: 'bold'
    },
    textJustify: {
      textAlign: 'justify'
    },
    btnPadd: {
      padding: '8px 30px'
    },
    pr5: {
      paddingRight: '5px'
    },
    dflex: {
      display: 'flex'
    },
    bodyh: {
      minHeight: 'calc(100vh - 60px - 65px)'
    },
    textNoUnderline: {
      textDecoration: 'none'
    },
    textdisable: {
      color: 'rgba(0, 0, 0, 0.26)'
    }
  })
)
