import React, { useEffect, useMemo, useState } from 'react'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/dist/echarts.esm'
import TableContainer from '@material-ui/core/TableContainer'
import { Role, useCurrentUserQuery, useSlotsAvailableLazyQuery } from 'graphql/hooks.generated'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import useStyles from '../Styles'
// import * as echarts from 'echarts/core'
// import {
//   GridComponent,
// eslint-disable-next-line @typescript-eslint/no-unused-vars
//   LegendComponent
//   TooltipComponent,
//   TitleComponent,
//   DatasetComponent
// } from 'echarts/components'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import { CanvasRenderer } from 'echarts/renderers'

// export interface AppProps {}
// export interface AppState {
//   name: string
// }
export interface GridProps {
  providerId: any
}

export const Chart = (props: GridProps) => {
  const classes = useStyles()
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const { providerId } = props
  const [providername, setProvidername] = useState('')
  const [
    executeAvailableQuery,
    { data: slotsAvailableData, loading: slotsLoading }
  ] = useSlotsAvailableLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetchSlotsAvailableQuery = useMemo(
    () => ({ user_id }) =>
      executeAvailableQuery({
        variables: { user_id }
      }),
    [executeAvailableQuery]
  )
  useEffect(() => {
    const rolesName = currentUserdata?.data?.currentUser?.roles?.[0]

    if (rolesName === Role.AgencySupervisor || rolesName === Role.AgencySupervisorProvider)
      setProvidername(providerId)
    else setProvidername(currentUserdata?.data?.currentUser?.id || '')
  }, [currentUserdata, providerId])
  useEffect(() => {
    if (currentUserdata) {
      const user_obj = { user_id: providername }
      fetchSlotsAvailableQuery(user_obj)
    }
  }, [currentUserdata, fetchSlotsAvailableQuery, providername])
  const genFormatter = (series) => {
    const seriesData = series

    return (param) => {
      // let sum = 0
      // seriesData.forEach((item) => {
      //   sum += item.data[param.dataIndex]
      // })

      return seriesData.data[param.dataIndex]
    }
  }
  const isLastSeries = (index) => {
    return index === 2 - 1
  }
  const seriesFormatter = () => {
    const data = slotsAvailableData?.Availableslots?.[0]?.series
    // data?.pop()
    const seriesData =
      data &&
      data?.map((item, index) => {
        const tmpObj = {
          ...data[item],
          type: 'bar',
          stack: true,
          label: {
            show: true,
            formatter: isLastSeries(index) ? genFormatter(data) : null,
            fontSize: isLastSeries(index) ? 20 : 15,
            color: 'black',
            position: isLastSeries(index) ? 'top' : 'inside'
          }
        }

        return tmpObj
      })

    return seriesData
  }
  const formatTotalSeries = (data) => {
    return data?.map((itm) => (itm ? 0 : itm))
  }
  const formatToolTip = () => {
    return (param) => {
      const prop = param
      // let sum = 0
      // seriesData.forEach((item) => {
      //   sum += item.data[param.dataIndex]
      // })

      // return series.data[param.dataIndex]
      return <h1>{prop?.value}</h1>
    }
  }
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
      },
      formatter: (params) => {
        const prop = params
        console.log(prop)

        return `<div>
            <h5>${prop[0].name}</h5>
            <p>Available slots : ${prop[0]?.value || 0}</p>
            <p>Booked slots : ${prop[1]?.value || 0}</p>
            <p>
              Total Slots :
              ${slotsAvailableData?.Availableslots?.[0]?.series?.[1].data[prop[0].dataIndex] || 0}
            </p>
          </div>`
      }
    },
    //   formatter: `<div>
    //   <h5>{b0}</h5>
    //   <p>Available slots : {c0}</p>
    //   <p>Booked slots : {c1}</p>
    //   <p>Total Slots : {c1}</p>
    //   </div>`
    // },
    legend: {
      data: [
        {
          name: slotsAvailableData?.Availableslots?.[0]?.series?.[0]?.name
        },
        {
          name: slotsAvailableData?.Availableslots?.[0]?.series?.[1]?.name
        }
      ]
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value'
    },
    yAxis: {
      type: 'category',
      // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      data: slotsAvailableData?.Availableslots?.[0]?.dates,
      axisLabel: {
        interval: 0,
        align: 'left',
        margin: 80
      }
    },
    // series: seriesFormatter()
    series: [
      {
        name: slotsAvailableData?.Availableslots?.[0]?.series?.[0]?.name,
        type: 'bar',
        stack: 'total',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        data: slotsAvailableData?.Availableslots?.[0]?.series?.[0]?.data
        // data: [320, 302, 301, 334]
      },
      {
        name: slotsAvailableData?.Availableslots?.[0]?.series?.[1]?.name,
        type: 'bar',
        stack: 'total',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        data: slotsAvailableData?.Availableslots?.[0]?.series?.[1]?.data
        // data: [120, 132, 101, 134]
      }
      // {
      //   name: 'Total',
      //   type: 'bar',
      //   stack: 'total',
      //   label: {
      //     show: true,
      //     position: 'right',
      //     formatter: genFormatter(slotsAvailableData?.Availableslots?.[0]?.series?.[2])
      //   },
      //   emphasis: {
      //     focus: 'series'
      //   },
      //   data: formatTotalSeries(slotsAvailableData?.Availableslots?.[0]?.series?.[2]?.data)
      //   // data: [null, null, null, null, null, null, null, null, null, null, null, null, null, null]
      // }

      // ,
      // {
      //   name: 'Affiliate Ad',
      //   type: 'bar',
      //   stack: 'total',
      //   label: {
      //     show: true
      //   },
      //   emphasis: {
      //     focus: 'series'
      //   },
      //   data: [220, 182, 191, 234, 290, 330, 310]
      // },
      // {
      //   name: 'Video Ad',
      //   type: 'bar',
      //   stack: 'total',
      //   label: {
      //     show: true
      //   },
      //   emphasis: {
      //     focus: 'series'
      //   },
      //   data: [150, 212, 201, 154, 190, 330, 410]
      // },
      // {
      //   name: 'Search Engine',
      //   type: 'bar',
      //   stack: 'total',
      //   label: {
      //     show: true
      //   },
      //   emphasis: {
      //     focus: 'series'
      //   },
      //   data: [820, 832, 901, 934, 1290, 1330, 1320]
      // }
    ]
  }

  //   render() {
  return (
    <div id="main" className={classes.chartHeight}>
      {/* <div className="container" > */}
      {/* <TableContainer className={classes.papercalender}> */}
      {/* <p>Ejemplo Pie chart</p> */}
      <h3 className={classes.h4}>
        <u>Availability</u>
      </h3>
      {!slotsLoading && (
        <ReactEChartsCore
          // className={classes.chartHeight}
          echarts={echarts}
          option={option}
          notMerge
          lazyUpdate
          theme="theme_name"
          opts={{}}
        />
      )}
      {/* <div className={classes.requestProgress}> */}
      {slotsLoading && <ProgressIndicator position="relative" />}
      {/* </div> */}

      {/* </TableContainer> */}
    </div>
  )
}
