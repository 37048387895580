import React from 'react'
import {
  Dialog as MDialog,
  DialogProps as MDialogProps,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Grid
} from '@material-ui/core'
import useStyles from './Styles'
import { InfoButton } from '../button/InfoButton'

export interface DialogProps extends MDialogProps {
  handleClose?: () => unknown
  handleSubmit?: () => unknown
  open: boolean
  cancel?: string | undefined
  submit?: string | undefined
  disabled?: boolean | undefined
  backDrop?: boolean | undefined
  custSubmitBtn?: boolean | undefined
  custCancelBtn?: boolean | undefined
  isoverflowY?: boolean | undefined
  isCustTitle?: boolean | undefined
}

const Dialog: React.FC<DialogProps> = (props: DialogProps) => {
  const {
    children,
    cancel,
    submit,
    title,
    open,
    handleClose,
    handleSubmit,
    disabled,
    backDrop,
    custSubmitBtn,
    custCancelBtn,
    isoverflowY,
    isCustTitle,
    ...mDialogProps
  } = props
  const classes = useStyles()

  return (
    <MDialog
      {...mDialogProps}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick={backDrop}
    >
      <DialogTitle id="form-dialog-title" className={classes.popupCls}>
        {isCustTitle && (
          <div className={classes.titleCls}>
            <h2>{title}</h2>
          </div>
        )}
        {!isCustTitle && <div className={classes.titleCls}>{title}</div>}
      </DialogTitle>
      <DialogContent style={isoverflowY ? { overflowY: 'unset' } : {}}>{children}</DialogContent>
      <DialogActions>
        {cancel && !custCancelBtn && (
          <Button onClick={handleClose} color="primary">
            {cancel}
          </Button>
        )}
        {submit && !custSubmitBtn && (
          <Button disabled={disabled} onClick={handleSubmit} color="primary">
            {submit}
          </Button>
        )}
        {submit && custSubmitBtn && (
          <Grid item sm={12} xs={12}>
            <Box
              display="flex"
              p={0}
              justifyContent="center"
              width="100%"
              marginBottom="15px"
              marginTop="15px"
            >
              {custCancelBtn && (
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  className={classes.mr}
                >
                  {cancel}
                </Button>
              )}
              <InfoButton
                variant="contained"
                size="medium"
                type="submit"
                disabled={disabled}
                onClick={handleSubmit}
              >
                {submit}
              </InfoButton>
            </Box>
          </Grid>
        )}
      </DialogActions>
    </MDialog>
  )
}

export default Dialog
