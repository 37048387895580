import { Grid } from '@material-ui/core'
import { makeValidate, TextField, Checkboxes } from 'mui-rff'
import React from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { TherapistDisciplineSelect } from 'components/ui/form/therapist-discipline-select/TherapistDisciplineSelect'
import Button from '../../../../components/ui/panel/button/Button'
import { InfoButton } from '../../../../components/ui/panel/button/InfoButton'
import { Section } from '../../../../components/ui/panel/section/Section'
import { TherapistDiscipline } from '../../../../graphql/hooks.generated'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'

export interface IcdCodeFormModel {
  name: string
  code: string
  therapistDiscipline?: TherapistDiscipline | null
  version: string
}

type IcdCodeFormProps = {
  onSubmit(values: IcdCodeFormModel): void
  submitLabel: string
  initialValues?: Partial<IcdCodeFormModel> | null
}
const validator = makeValidate(
  yup.object<IcdCodeFormModel>({
    name: yup.string().label('ICD Code Name').required(),
    code: yup.string().label('ICD Code').required(),
    version: yup.string().label('Version').required()
  })
)

export const IcdCodeForm = (props: IcdCodeFormProps) => {
  const history = useHistory()
  const classes = useStyles()
  const { initialValues } = props
  const onSubmit = (values: IcdCodeFormModel) => props.onSubmit(values)
  const renderForm = (renderProps: FormRenderProps<IcdCodeFormModel>) => {
    const { handleSubmit } = renderProps

    return (
      <form>
        <Grid container spacing={4} style={{ maxWidth: '800px' }}>
          <Grid item sm={12}>
            <Section title="New ICD Code ">
              <Grid container spacing={3}>
                <Grid item sm={6}>
                  <TextField
                    required
                    fullWidth
                    variant="filled"
                    name="name"
                    label="ICD Code Name"
                    className={classes.labelField}
                  />
                </Grid>

                <Grid item sm={6}>
                  <TextField
                    required
                    variant="filled"
                    name="code"
                    label="ICD Code"
                    className={classes.labelField}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TherapistDisciplineSelect
                    required
                    label="Therapist Discipline"
                    name="therapistDiscipline"
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    required
                    fullWidth
                    variant="filled"
                    name="version"
                    label="Version"
                    className={classes.labelField}
                  />
                </Grid>
              </Grid>
            </Section>
          </Grid>

          <Grid item sm={12} style={{ paddingTop: 35, marginLeft: '5px' }}>
            <InfoButton
              size="large"
              variant="contained"
              onClick={handleSubmit}
              style={{ marginRight: 12 }}
            >
              {props.submitLabel}
            </InfoButton>
            <Button size="large" variant="outlined" color="primary" onClick={history.goBack}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <Form<IcdCodeFormModel>
      initialValues={initialValues ?? {}}
      validate={validator}
      render={renderForm}
      onSubmit={onSubmit}
    />
  )
}
