import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import {
  useTherapistNewMetricsLazyQuery,
  useCurrentUserQuery,
  useDashboardRequestsLazyQuery,
  useSlotsAvailableLazyQuery,
  Role
} from 'graphql/hooks.generated'
import { Link } from 'react-router-dom'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import useStyles from '../Styles'

export interface TextParams {
  name: any
  value: any
  loading: boolean
}

export interface GridProps {
  metrics: any
  providerId: any
}

const GridMetrics = (props: TextParams) => {
  const classes = useStyles()
  const { name, value, loading } = props

  return (
    <Grid item xs={4} className={classes.columnOne}>
      <p className={classes.colSpanNew}>{name}</p>
      {!loading && <h1 className={classes.colSpanNum}>{value}</h1>}
      {loading && <ProgressIndicator position="relative" />}
    </Grid>
  )
}

export default function AutoGrid(props: GridProps) {
  const classes = useStyles()
  const { hasAnyRole } = useCurrentUser()
  const { metrics, providerId } = props
  const [providername, setProvidername] = useState('')
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const [
    executeMetricsQuery,
    { data: metricsData, loading: metricsLoading }
  ] = useTherapistNewMetricsLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetchMetricsQuery = useMemo(
    () => ({ user_id }) =>
      executeMetricsQuery({
        variables: { user_id }
      }),
    [executeMetricsQuery]
  )
  useEffect(() => {
    const rolesName = currentUserdata?.data?.currentUser?.roles?.[0]

    if (rolesName === Role.AgencySupervisor || rolesName === Role.AgencySupervisorProvider)
      setProvidername(providerId)
    else setProvidername(currentUserdata?.data?.currentUser?.id || '')
  }, [currentUserdata, providerId])
  useEffect(() => {
    if (currentUserdata) {
      const user_obj = { user_id: providername }
      fetchMetricsQuery(user_obj)
    }
  }, [currentUserdata, fetchMetricsQuery, providername])
  useEffect(() => {
    if (metricsData?.Datacount) metrics(metricsData?.Datacount[0])
  }, [metrics, metricsData])

  return (
    <div style={{ minHeight: '150px' }}>
      {/* {!metricsLoading && ( */}
      <>
        <Paper className={classes.paperNew}>
          <GridMetrics
            name="Active Clients "
            value={metricsData?.Datacount?.[0]?.active_students || 0}
            loading={metricsLoading}
          />
          <GridMetrics
            name="New Client Sign-Ups in the Last 7 Days"
            value={metricsData?.Datacount?.[0]?.new_signin_students || 0}
            loading={metricsLoading}
          />
          <GridMetrics
            name="Future Scheduled Sessions"
            value={metricsData?.Datacount?.[0]?.future_appointments || 0}
            loading={metricsLoading}
          />
        </Paper>
        <Paper className={classes.paperNew}>
          <GridMetrics
            name="Clients Exceeding 6 sessions in Program"
            value={metricsData?.Datacount?.[0]?.student_6count || 0}
            loading={metricsLoading}
          />
          <GridMetrics
            name="Incomplete Mandatory Disclosures"
            value={
              <Link
                to={{ pathname: '/caseload', state: providername }}
                className={classes.orangelink}
              >
                {metricsData?.Datacount?.[0]?.complete_sessions || 0}
              </Link>
            }
            loading={metricsLoading}
          />
          <GridMetrics
            name="Requested Further Sessions in the Last 7 Days"
            value={metricsData?.Datacount?.[0]?.further_session_7days || 0}
            loading={metricsLoading}
          />
        </Paper>
      </>
      {/* )} */}
      {/* <ProgressIndicator position="relative" /> */}
      {/* {metricsLoading && <ProgressIndicator position="relative" top="-60px" />} */}
    </div>
  )
}
