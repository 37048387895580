import React from 'react'
import { useParams } from 'react-router-dom'
import { ResourceQueryResult, useResourceQuery } from '../../../graphql/hooks.generated'
import { CmsResourceContentType } from '../../../graphql/enumTypes'
import { ArticleView } from './ArticleView'
import { PdfView } from './PdfView'
import { COGameView } from './COGameView'
import { DragRevealGameView } from './DragRevealGameView'
import { OrderingGameView } from './OrderingGameView'
import NavBar from '../../../components/ui/panel/nav-bar/NavBar'
import { UseParamsType } from '../../../types'

interface UpdateDistrictViewParams extends UseParamsType {
  id: string
}

export const ResourceView = () => {
  const { id } = useParams<UpdateDistrictViewParams>()
  const { data, loading, error }: ResourceQueryResult = useResourceQuery({ variables: { id } })

  if (loading) return null
  if (error) return null

  const Resource = () => {
    switch (data?.GCMS_resource?.content?.__typename) {
      case CmsResourceContentType.Article:
        return <ArticleView articleResource={data.GCMS_resource} />
      case CmsResourceContentType.Pdf:
        return <PdfView pdfResource={data.GCMS_resource} />
      case CmsResourceContentType.OrderingGame:
        return <OrderingGameView gameResource={data.GCMS_resource} />
      case CmsResourceContentType.CoGame:
        return <COGameView gameResource={data.GCMS_resource} />
      case CmsResourceContentType.DragBoard:
        return <DragRevealGameView gameResource={data.GCMS_resource} />
      default:
        return <div> Resource Coming Soon!</div>
    }
  }

  return (
    <>
      <NavBar title="Resources" goBackBtn />
      <Resource />
    </>
  )
}
