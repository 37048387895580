import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { CreateStateView } from './create/CreateStateView'
import { StateListView } from './list/StateListView'
import { UpdateStateView } from './update/UpdateStateView'

export const StatesRootView = () => {
  const { url } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${url}/create`} component={CreateStateView} />
      <Route path={`${url}/:id/edit`} component={UpdateStateView} />
      <Route component={StateListView} />
    </Switch>
  )
}
