import React, { useMemo, useEffect } from 'react'
import { Select } from 'mui-rff'
import { MenuItem } from '@material-ui/core'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { useDistrictSelectLazyQuery } from 'graphql/hooks.generated'
import { useStyles } from '../Styles'

interface DistrictSelectProps {
  label: string
  name: string
  required?: boolean
  disabled?: boolean
  selectdata?: any
  isStudentDistrict?: boolean
}

export const DistrictSelect = (props: DistrictSelectProps) => {
  const classes = useStyles()
  const active: any = 'ACTIVE'
  const { isStudentDistrict } = props
  const [executeQuery, { data, loading: loadingDistrictSelect }] = useDistrictSelectLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetch = useMemo(
    () => (query = '') =>
      executeQuery({
        variables: {
          input: { query }
        }
      }),
    [executeQuery]
  )
  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <>
      {loadingDistrictSelect ? <ProgressIndicator fullHeight /> : <></>}
      {!isStudentDistrict && data?.districts && data?.districts?.length > 0 ? (
        <Select {...props} variant="filled" className={classes.selectDropdown}>
          {(data?.districts)
            .filter((districts) => districts.status === active)
            .map((district) => (
              <MenuItem key={district.id} value={district.id}>
                {district.name}
              </MenuItem>
            ))}
        </Select>
      ) : isStudentDistrict && data?.districts && data?.districts?.length > 0 ? (
        <Select {...props} variant="filled" className={classes.selectDropdown}>
          {data?.districts
            ?.filter(
              (district) => Number(district.id) === props?.selectdata.initialValues?.district
            )
            .map((districtObj) => (
              <MenuItem key={districtObj.id} value={districtObj.id}>
                {districtObj.name}
              </MenuItem>
            ))}
        </Select>
      ) : (
        <Select {...props} variant="filled" className={classes.selectDropdown}>
          <MenuItem>No Data Found</MenuItem>
        </Select>
      )}
    </>
  )
}
