import React, { useEffect, useState, useMemo } from 'react'
import { Box, Grid, TextField } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Dialog from 'components/ui/panel/dialog/Dialog'
import { useParams } from 'react-router-dom'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { theme } from 'config/constants/theme'
import { UseParamsType } from '../../../../types'
import {
  useCreateCaseloadMutation,
  useIcdCodesLazyQuery,
  useUsersQuery,
  useCaseStudentsLazyQuery
} from '../../../../graphql/hooks.generated'
import { useStyles } from '../Styles'

interface AddStudentCaseloadFormProps {
  open: boolean
  handleClose: () => unknown
  handleSubmit: () => unknown
  currentUserdata: any
  enabledistricts?: any
  primaryDist?: string
}

interface RouterParams extends UseParamsType {
  id: string
  userId: string
}

const AddStudentToCaseLoadForm: React.FC<AddStudentCaseloadFormProps> = (
  props: AddStudentCaseloadFormProps
) => {
  const { open, handleClose, handleSubmit, currentUserdata, enabledistricts, primaryDist } = props
  const initailValues = { student: '', iepMin: '', billingCode: '', minPer: '', frequency: 'WEEK' }
  const [formValues, setFormValues] = useState(initailValues)
  const [showSpinner, setSpinner] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const { id: districId, userId } = useParams<RouterParams>()
  const [userDistricId, setUserDistricId] = useState<string>('')
  const [therapistId, settherapistId] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [iepMinError, setIepMinError] = useState('')
  const [minPerErr, setMinPerErr] = useState('')
  const classes = useStyles()
  // current user data
  // const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  // console.log('pppp', currentUserdata)
  // Get user data - therapist details
  const dId = !districId && !userId ? currentUserdata.data?.currentUser.district?.id : null
  const uId = !districId && !userId ? currentUserdata.data?.currentUser.id : userId
  const { data: userdata, loading: userdataLoading } = useUsersQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: { districtId: Number(dId), id: uId }
    }
  })
  // Get students from user distric
  const [
    executeQuery,
    { data: studentdata, loading: studentdataLoading }
  ] = useCaseStudentsLazyQuery({
    variables: { district_id: userDistricId, user_id: therapistId },
    fetchPolicy: 'network-only'
  })
  const fetchStudents = useMemo(() => () => executeQuery(), [executeQuery])
  // Get icd code
  const [executeIcdQuery, { data: icdCodesdata, loading: icdCodesLoading }] = useIcdCodesLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetchIcds = useMemo(() => ({ discipline }) => executeIcdQuery(), [executeIcdQuery])
  // Save student data to caseload
  const [createCaseloadMutation] = useCreateCaseloadMutation()
  const caseloadStudentFun = async ({
    userId,
    students,
    frequency,
    iep_minutes,
    billing_code,
    minutes_per,
    createdBy
  }) => {
    setSpinner(true)
    try {
      const result = await createCaseloadMutation({
        variables: {
          input: { userId, students, frequency, iep_minutes, billing_code, minutes_per, createdBy }
        }
      })
      // console.log('student save data', result)
      setSpinner(false)
      handleSubmit()
    } catch (e) {
      // console.log(e)
      setSpinner(false)
      handleSubmit()
    }
  }
  // Submit student form data
  const submitAddCaseloadForm = () => {
    const inputdata = {
      userId: !userId ? currentUserdata.data?.currentUser.id : userId,
      students: Number(formValues.student),
      iep_minutes: Number(formValues.iepMin),
      billing_code: formValues.billingCode,
      minutes_per: formValues.minPer ? Number(formValues.minPer) : undefined,
      frequency: formValues.frequency,
      createdBy: currentUserdata.data?.currentUser.id
    }
    caseloadStudentFun(inputdata)
  }
  useEffect(() => {
    if (userdata) {
      let desciplaneVal
      // console.log('user', userdata)
      userdata?.users?.forEach((item) => {
        const distId: any = enabledistricts.length ? enabledistricts.join(',') : item?.district?.id
        const therapstId = item.id
        desciplaneVal = item.therapist_discipline
        setUserDistricId(distId)
        settherapistId(therapstId)
        const modaltitle = `New Caseload for ${item.first_name} ${item.last_name}`
        setTitle(modaltitle)
      })
      fetchStudents()
      const inputdata = {
        discipline: desciplaneVal
      }
      fetchIcds(inputdata)
    }
  }, [title, districId, userId, userdata, fetchStudents, fetchIcds, enabledistricts])
  const handleFormChanges = (e) => {
    // console.log(e)
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
    setIsSubmitted(false)
  }
  const submitDialog = () => {
    // console.log('submit data', formValues)
    const numregex = /^[0-9]\d*$/
    let ieperr
    let minerr
    let emptyErr = false

    if (!formValues.student || !formValues.iepMin || !formValues.billingCode) emptyErr = true
    if (!numregex.test(formValues.iepMin)) ieperr = 'Invalid number'
    if (formValues.iepMin.length > 3) ieperr = 'Maximum 3 digits'
    if (formValues.minPer && !numregex.test(formValues.minPer)) minerr = 'Invalid number'
    if (formValues.minPer.length > 3) minerr = 'Maximum 3 digits'
    if (ieperr || minerr || emptyErr) {
      setIepMinError(ieperr)
      setMinPerErr(minerr)
      setIsSubmitted(true)

      return
    }

    submitAddCaseloadForm()
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {userdataLoading || studentdataLoading || icdCodesLoading || showSpinner ? (
        <ProgressIndicator fullHeight />
      ) : (
        ''
      )}
      {open && userdata && (
        <Dialog
          handleClose={handleClose}
          handleSubmit={submitDialog}
          disabled={false}
          submit="Save"
          cancel="Cancel"
          open={open}
          title={title}
          backDrop
          custSubmitBtn
          custCancelBtn
          isoverflowY
          isCustTitle
        >
          <form>
            <Grid className="InputCenter" container spacing={3} style={{ width: '550px' }}>
              <Grid item sm={12}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel htmlFor="filled-age-native-simple">Client</InputLabel>
                  <Select
                    native
                    value={formValues.student}
                    className={classes.selectDropdown}
                    onChange={handleFormChanges}
                    inputProps={{
                      name: 'student',
                      id: 'students'
                    }}
                  >
                    <option aria-label="None" value="" />
                    {studentdata &&
                      studentdata?.caseStudents &&
                      studentdata?.caseStudents.map((item) => (
                        <option key={item.id} value={item.id?.toString()}>
                          {item.student_name}&nbsp;-&nbsp;({item.districtname})&nbsp;-&nbsp;(
                          {item.birth_date})
                        </option>
                      ))}
                  </Select>
                </FormControl>
                {isSubmitted && !formValues.student && (
                  <div style={{ color: theme.palette.error.dark }}>Required Client</div>
                )}
              </Grid>

              <Grid item sm={6}>
                <FormControl variant="filled" fullWidth>
                  <TextField
                    id="iepMin"
                    name="iepMin"
                    fullWidth
                    className={classes.labelField}
                    variant="filled"
                    type="number"
                    label="IEP Minutes"
                    value={formValues.iepMin}
                    onChange={handleFormChanges}
                  />
                </FormControl>
                {isSubmitted && !formValues.iepMin && (
                  <div style={{ color: theme.palette.error.dark }}>Required IEP minutes</div>
                )}
                {isSubmitted && iepMinError && formValues.iepMin && (
                  <div style={{ color: theme.palette.error.dark }}>{iepMinError}</div>
                )}
              </Grid>
              <Grid item sm={6}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel htmlFor="filled-age-native-simple">Billing code</InputLabel>
                  <Select
                    native
                    value={formValues.billingCode}
                    className={classes.selectDropdown}
                    onChange={handleFormChanges}
                    inputProps={{
                      name: 'billingCode',
                      id: 'billingc'
                    }}
                  >
                    <option aria-label="None" value="" />
                    {icdCodesdata &&
                      icdCodesdata?.icdCodes &&
                      icdCodesdata?.icdCodes.map((item) => (
                        <option key={item.code} value={item?.code}>
                          {item.code}
                        </option>
                      ))}
                  </Select>
                </FormControl>
                {isSubmitted && !formValues.billingCode && (
                  <div style={{ color: theme.palette.error.dark }}>Required Billing code</div>
                )}
              </Grid>
              <Grid item sm={12}>
                <div>
                  <i>
                    If you&apos;re just doing an evaluation, you can leave the rest of the fields as
                    they are for now and come back to edit them later
                  </i>
                </div>
              </Grid>
              <Grid item sm={6}>
                <FormControl variant="filled" fullWidth>
                  <TextField
                    id="minper"
                    name="minPer"
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Minutes Per"
                    value={formValues.minPer}
                    onChange={handleFormChanges}
                    className={classes.labelField}
                  />
                </FormControl>
                {isSubmitted && minPerErr && (
                  <div style={{ color: theme.palette.error.dark }}>{minPerErr}</div>
                )}
              </Grid>
              <Grid item sm={6}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel htmlFor="filled-age-native-simple">Frequency</InputLabel>
                  <Select
                    native
                    value={formValues.frequency}
                    className={classes.selectDropdown}
                    onChange={handleFormChanges}
                    inputProps={{
                      name: 'frequency',
                      id: 'frequency'
                    }}
                  >
                    <option value="WEEK">WEEK</option>
                    <option value="DAY">DAY</option>
                    <option value="MONTH">MONTH</option>
                    <option value="YEAR">YEAR</option>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </Dialog>
      )}
    </Box>
  )
}

export default AddStudentToCaseLoadForm
