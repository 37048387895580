import { makeStyles } from '@material-ui/core'

export default makeStyles({
  root: {
    width: '100%',
    fontFamily: 'Brandon Grotesque'
  },
  rootCls: {
    width: 'auto',
    padding: '10px',
    '& > * + *': {
      marginTop: '11px'
    }
  },
  selectDropdown: {
    bacgroundColor: '#fff',
    border: '1px solid black',
    backgroundColor: 'white',
    fontSize: '15px',
    fontFamily: 'Brandon Grotesque',
    height: 30,
    width: '100%',
    paddingTop: 0,
    paddingLeft: 0,
    borderRadius: 3,
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff !important'
    },
    '& .MuiSelect-filled.MuiSelect-filled': {
      backgroundColor: '#fff !important'
    },
    '& div': {
      padding: '6px'
    },
    selectMenu: {
      bacgroundColor: '#fff',
      padding: '0px !important',
      fontSize: '16px'
    }
  },
  paper: {
    padding: 0,
    boxShadow: 'none',
    display: 'flex'
  },
  paperMain: {
    padding: 0,
    boxShadow: 'none'
  },
  lookupCls: { marginTop: '3px', borderRadius: '6px' },
  centerMainCls: {
    display: 'flex',
    float: 'right',
    marginBottom: '10px',
    fontSize: '17px',
    lineHeight: '25px',
    fontWeight: 'bold',
    fontFamily: 'Brandon Grotesque'
  },
  quickCls: {
    display: 'flex',
    marginBottom: '2px',
    fontSize: '17px',
    lineHeight: '25px',
    fontWeight: 'bold',
    fontFamily: 'Brandon Grotesque'
  },
  profileCls: {
    border: '1px solid #0d1f48',
    fontFamily: 'Brandon Grotesque',
    fontWeight: 'normal'
  },
  viewCls: {
    border: '1px solid #eeeeee',
    fontFamily: 'Brandon Grotesque',
    fontWeight: 'normal',
    paddingTop: '0',
    paddingBottom: '0',
    backgroundColor: '#eeeeee',
    paddingLeft: '9px',
    paddingRight: '10px'
  },
  AttachCls: {
    border: '1px solid #F1583A',
    marginRight: '10px',
    minWidth: '38px'
  },
  orangelink: {
    color: '#ff0000'
  },
  submitCls: {
    backgroundColor: '#F1583A',
    color: '#fff',
    fontFamily: 'Brandon Grotesque',
    '&&:focus': { backgroundColor: '#F1583A' },
    '&&:hover': {
      backgroundColor: '#F1583A'
    },
    '& selected': {
      backgroundColor: '#F1583A'
    }
  },
  tdd: {
    backgroundColor: '#fff',
    color: 'black'
    //  margin: '20px'
  },
  submitViewCls1: {
    backgroundColor: '#F1583A',
    color: '#fff',
    fontFamily: 'Brandon Grotesque',
    fontSize: '20px',
    fontWeight: 'normal',
    width: '90%',
    float: 'right',
    '&&:focus': { backgroundColor: '#F1583A' },
    '&&:hover': {
      backgroundColor: '#F1583A'
    },
    '& selected': {
      backgroundColor: '#F1583A'
    }
  },
  submitViewCls: {
    backgroundColor: '#F1583A',
    color: '#fff',
    fontFamily: 'Brandon Grotesque',
    fontSize: '17px',
    fontWeight: 'normal',
    '&&:focus': { backgroundColor: '#F1583A' },
    '&&:hover': {
      backgroundColor: '#F1583A'
    },
    '& selected': {
      backgroundColor: '#F1583A'
    }
  },
  parentCls: {
    border: '1px solid #0D1F48',
    backgroundColor: '#0D1F48',
    marginLeft: '10px',
    color: '#fff',
    fontFamily: 'Brandon Grotesque',
    fontWeight: 'normal'
  },
  h4: {
    padding: '10px'
  },
  tText: {
    fontSize: '15px',
    lineHeight: '35px'
  },
  ellipsistext: {
    maxWidth: '280px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  paperNew: {
    padding: 10,
    boxShadow: 'none',
    display: 'flex',
    backgroundColor: 'transparent',
    borderRadius: '0 !important'
  },
  pad: {
    paddingLeft: '10px'
  },
  reportsNew: {
    padding: 10,
    boxShadow: 'none',
    display: 'flex',
    paddingBottom: '0',
    backgroundColor: 'transparent'
  },
  paperNewCls: {
    padding: 10,
    paddingTop: '0',
    boxShadow: 'none',
    display: 'flex',
    backgroundColor: 'transparent'
  },
  leftContainer: { maxWidth: '60%', flexBasis: '60%', padding: '8px' },
  rightContainer: {
    maxWidth: '40%',
    flexBasis: '40%',
    padding: '8px'
  },
  rightCls1: {
    backgroundColor: '#EDF7FC',
    height: '100%'
  },
  rightCls: {
    backgroundColor: '#F6F6F6',
    height: 'auto'
  },
  coloneCls: { textAlign: 'center', borderRight: '2px solid #DAEBF4' },
  columnOne: {
    textAlign: 'center',
    backgroundColor: '#fff',
    borderRadius: '5px',
    marginRight: '10px',
    paddingBottom: '8px',
    flexDirection: 'column',
    paddingTop: '8px',
    '&h1': {
      color: '#FF0000'
    }
  },
  columnOneCls: {
    textAlign: 'center',
    backgroundColor: '#fff',
    borderRadius: '5px',
    marginRight: '2px',
    padding: '8px'
  },
  colSpan: {
    fontSize: '16px',
    float: 'right',
    textAlign: 'center',
    top: '20px',
    color: '#0D1F48',
    /* margin-top: -33px; */
    width: '60px',
    margin: '0 auto',
    paddingTop: '13px',
    fontWeight: 'bold',
    fontFamily: 'Brandon Grotesque'
  },
  textCls: {
    width: '60px',
    margin: '0 auto',
    marginTop: '10px',
    fontWeight: 'bold',
    fontSize: '16px',
    fontFamily: 'Brandon Grotesque'
  },
  textClsOne: {
    width: '95px',
    margin: '0 auto',
    marginTop: '10px',
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'Brandon Grotesque'
  },
  coloneClsOne: { textAlign: 'center' },
  iconCls: { height: '50px', marginTop: '10px', width: '50px', '& .a': { fill: '#2C9BD6' } },
  headingCls: {
    textAlign: 'left',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '20px',
    flexDirection: 'column',
    fontFamily: 'Brandon Grotesque'
  },
  headingSelect: {},
  headingText: {
    paddingRight: '10px',
    fontWeight: 'bold',
    fontFamily: 'Brandon Grotesque',
    lineHeight: '29px'
  },
  dataCls: {
    backgroundColor: '#EDF7FC',
    height: '100%',
    marginTop: '5px'
  },
  ClockCls: { height: '11px', '& .a': { fill: '#2C9BD6' } },
  table: {
    minWidth: 216,
    '& .MuiTableCell-root': {
      padding: '7px 7px',
      borderBottom: 'none',
      fontFamily: 'Brandon Grotesque',
      fontSize: '16px'
    },
    '& th.MuiTableCell-root.MuiTableCell-head': {
      borderBottom: '1px solid #ccc',
      fontWeight: 'bold'
    }
  },
  chartHeight: {
    height: '360px'
  },
  tableBorder: {
    minWidth: 216,
    '& .MuiTableCell-root': {
      padding: '7px 7px',
      borderBottom: '1px solid #ccc',
      borderRight: '1px solid #ccc',
      fontFamily: 'Brandon Grotesque',
      fontSize: '16px'
    },
    '& th.MuiTableCell-root.MuiTableCell-head': {
      borderBottom: '1px solid #ccc',
      fontWeight: 'bold'
    }
  },
  listTable: {
    minWidth: 216,
    '& .MuiTableCell-root': {
      // padding: '7px 7px',
      borderBottom: '1px solid #ccc',
      fontFamily: 'Brandon Grotesque',
      fontSize: '16px'
    },
    '& th.MuiTableCell-root.MuiTableCell-head': {
      borderBottom: '1px solid #ccc',
      fontWeight: 'bold'
    }
  },
  tableBorderOne: {
    '& .MuiTableCell-root': {
      padding: '9px 9px',
      borderBottom: '2px solid #ccc',
      fontFamily: 'Brandon Grotesque',
      fontSize: '16px'
    },
    '& th.MuiTableCell-root.MuiTableCell-head': {
      borderBottom: '1px solid #ccc',
      fontWeight: 'bold'
    }
  },
  tableDays: {
    minWidth: 216,
    '& .MuiTableCell-root': {
      padding: '7px 7px',
      borderBottom: 'none',
      fontFamily: 'Brandon Grotesque',
      fontSize: '14px'
    },
    '& th.MuiTableCell-root.MuiTableCell-head': {
      borderBottom: '1px solid #ccc',
      fontWeight: 'bold'
    }
  },
  nameCls: {},
  codeCls: {},
  daysCls: {},
  numCls: {
    color: '#0D1F48',
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '7px',
    marginBottom: '0'
  },
  stuCls: { color: '#0D1F48', fontSize: '13px', marginTop: '0', marginBottom: '3px' },
  owedCls: { color: '#2C9BD6', fontSize: '13px', fontWeight: 'bold', marginTop: '0' },
  tableCls: { marginRight: '10px', borderRadius: '5px' },
  tableProvideCls: { marginRight: '0px', borderRadius: '0px!important', marginBottom: '0px' },
  tableBgCls: { marginRight: '10px', backgroundColor: '#fff', borderRadius: '5px' },
  tableChartCls: { marginRight: '0px', backgroundColor: '#fff', borderRadius: '5px' },
  tableMainCls: { marginRight: '0', backgroundColor: '#fff', borderRadius: '5px' },
  tableNewCls: {
    textAlign: 'center',
    backgroundColor: '#EDF7FC',
    marginRight: '10px',
    fontFamily: 'Brandon Grotesque'
  },
  tableNewClsOne: {
    textAlign: 'center',
    backgroundColor: '#EDF7FC',
    fontFamily: 'Brandon Grotesque'
  },
  collaborationCls: {},
  centerCls: {
    display: 'flex',
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 'bold',
    paddingBottom: '1px',
    fontFamily: 'Brandon Grotesque'
  },
  msgCls: { paddingRight: '8px' },
  lookUpCls: {
    backgroundColor: '#fff'
  },
  fileCls: {
    '& .a': { fill: '#F1583A' }
  },
  containerCls: {
    minHeight: '250px',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px',
    borderRadius: '5px'
  },
  tableScroll: {
    flex: 1,
    minHeight: 0,
    overflow: 'scroll'
  },
  formControl: {},

  /* therapist styles */

  paperNewStyle: {
    padding: 10,
    boxShadow: 'none',
    display: 'flex',
    // backgroundColor: '#2A3E70',
    backgroundColor: '#0052cc',
    color: '#fff',
    borderRadius: '0 !important'
  },
  paperNewStyle1: {
    padding: 10,
    boxShadow: 'none',
    display: 'flex',
    backgroundColor: '#0052cc',
    color: '#fff',
    borderRadius: '10px!important',
    alignItems: 'center'
  },
  calenderCls: {},
  papercalender1: {
    // padding: 10,
    boxShadow: 'none',
    display: 'flex',
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: '0 !important',
    marginTop: '10px',
    margin: '10px',
    height: '300px'
  },
  papercalender: {
    padding: 10,
    boxShadow: 'none',
    display: 'flex',
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: '0 !important',
    marginTop: '10px',
    // margin: '10px',
    height: '600px'
  },
  papercalender2: {
    padding: 10,
    boxShadow: 'none',
    display: 'flex',
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: '0 !important',
    marginTop: '10px',
    margin: '10px'
    // height: '300px'
  },
  papercalender3: {
    padding: 10,
    boxShadow: 'none',
    display: 'flex',
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: '0 !important'
  },
  chart: {
    // width: '730px',
    margin: '10px',
    borderRadius: '0 !important'
  },
  tables: {
    minWidth: 216,
    '& .MuiTableCell-root': {
      padding: '7px 7px',
      borderBottom: 'none',
      fontFamily: 'Brandon Grotesque',
      fontSize: '16px',
      paddingTop: '6px'
    },
    '& th.MuiTableCell-root.MuiTableCell-head': {
      borderBottom: '1px solid #ccc',
      fontWeight: 'bold'
    }
  },
  headingClsNew: {
    textAlign: 'left',
    display: 'inline',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '20px',
    flexDirection: 'column',
    fontFamily: 'Brandon Grotesque',
    '& span': {
      paddingLeft: '10px'
    },
    '& p': {
      fontSize: '10px'
    }
  },
  headingClsNew1: {
    textAlign: 'center',
    fontFamily: 'Brandon Grotesque',
    // '& span': {
    //   paddingLeft: '10px'
    // },
    '& p': {
      fontSize: '14px',
      margin: 0,
      padding: '5px 0px'
    },
    '& span': {
      fontWeight: 'bold',
      fontSize: '24px'
    }
  },
  act: {
    textAlign: 'left',
    display: 'inline',
    fontWeight: 'bold',
    fontSize: '25px',
    lineHeight: '25px',
    flexDirection: 'column',
    fontFamily: 'Brandon Grotesque'
    // '& span': {
    //   paddingLeft: '10px'
    // },
  },
  button: {
    allignItems: 'center'
  },
  colSpanNew: {
    fontSize: '16px',
    textAlign: 'center',
    top: '20px',
    color: '#0D1F48',
    height: '80px',
    margin: '0 auto',
    padding: '10px',
    fontFamily: 'Brandon Grotesque'
  },
  colSpanNum: {
    fontSize: '25px',
    color: '#ff0000'
  },
  dashboarSchedule: {
    '& .control-wrapper.schedule-wrapper': {
      display: 'flex'
    },
    '& p': {
      color: '#FF0000'
    },
    '& .e-schedule .e-month-agenda-view .e-wrapper-container': {
      display: 'none'
    }
  },
  priorityIcon: {},
  alertIcon: {
    height: '50px',
    width: '50px'
  },
  noDataBox: {
    background: '#fff',
    borderRadius: '10px',
    border: '1px solid lightgrey'
  },
  noTDataBox: {
    background: '#fff',
    borderRadius: '10px',
    border: '1px solid lightgrey'
  },
  mt2: {
    marginTop: '2%'
  },
  h49: {
    height: '49%'
  },
  noDataHeader: {
    height: '15%',
    padding: '10px 10px 5px 10px',
    fontWeight: 'bold',
    borderBottom: '1px solid lightgrey'
  },
  noDataText: {
    height: '75%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noDataHeader2: {
    height: '15%',
    padding: '10px',
    fontWeight: 'bold',
    borderBottom: '1px solid lightgrey'
  },
  noDataText2: {
    height: '82%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dataCenter: {
    alignItems: 'center',
    padding: '20px',
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'center',
    height: '100%'
  },
  fullH: {
    height: '100%'
  },
  minH250: {
    minHeight: '250px'
  },
  comingSoonText: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    maxHeight: '780px',
    minHeight: '820px',
    overflowY: 'scroll'
    // fontSize: '20px'
  },
  tMintes: {
    alignItems: 'center',
    minHeight: '460px',
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'center'
  },
  requestProgress: {
    '&& .MuiBox-root': {
      height: '0px',
      top: '-60px'
    }
  },
  scheduleProgress: {
    '&& .MuiBox-root': {
      height: '0px',
      top: '-140px'
    }
  },
  selectCls: {
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px'
    }
  },
  feedsec: {
    // display: 'flex',
    alignItems: 'center'
    // justifyContent: 'center'
  }
})
