import React from 'react'
import { MenuItem as MMenuItem, MenuItemProps } from '@material-ui/core'
import useStyles from './Styles'

const MenuItem = React.forwardRef<HTMLLIElement, MenuItemProps>((props, ref) => {
  // https://github.com/mui-org/material-ui/issues/14971
  const classes = useStyles()

  return <MMenuItem ref={ref} {...props} button className={classes.root} />
})

export default MenuItem
