import React, { useMemo } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { UseParamsType } from 'types'
import { useParams } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useListStudentsProfileQuery } from 'graphql/hooks.generated'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import NavBar from '../../../../../components/ui/panel/nav-bar/NavBar'
import { PageContainer } from '../../../../../components/ui/panel/page-container/PageContainer'
import { StudentProfileForm } from './profile'
import { StudentProfileNotes } from './studentnotes'
import AppointmentInfo from './active'
import { useStyles, theme } from '../../../../../config/constants/theme/profile'
// import Iep from './iep/viewPage'
import SurveyDetails from './survey/viewPage'
import Documentation from './documentation/viewpage'
import Iep from './iep/viewPage'
import StudentDocumentation from './studentdocumentation/viewpage'
import ContactAttemptsInfo from './contactAttempts'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

interface ListStudentViewParams extends UseParamsType {
  stu_id: string
}

export default function SimpleTabs() {
  const { stu_id } = useParams<ListStudentViewParams>()
  let stud_id: any
  let decode1
  let decode2
  try {
    ;[decode1, decode2] = atob(stu_id).split('_')
  } catch (e) {
    // console.log(e)
  }

  if (decode1 && decode2 && decode1 === decode2) stud_id = decode1

  const classes = useStyles()
  const params = new URLSearchParams(window.location.search)
  const [value, setValue] = React.useState(
    params.get('t') ? (Number(params.get('t')) > 6 ? 1 : Number(params.get('t')) - 1) : 1
  )
  const [studentobj, setstudentobj] = React.useState({})
  // let studentobj
  const { data, loading } = useListStudentsProfileQuery({
    fetchPolicy: 'network-only',
    variables: { id: Number(stud_id) }
  })
  useMemo(() => {
    if (data) {
      const obj: any = data?.studentsProfile?.find(() => true)
      setstudentobj(obj)
    }
  }, [data, setstudentobj])
  // if (data) studentobj = data?.studentsProfile?.find(() => true)
  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue)
  }
  // const setuseridHandler = (studentDetails) => {
  //   setStudentDetails(studentDetails)
  // }

  return (
    <div className={classes.root}>
      {loading && <ProgressIndicator fullHeight />}
      <NavBar title="Client Info" goBackBtn />
      <PageContainer>
        <MuiThemeProvider theme={theme}>
          <AppBar position="static" className={classes.overrides}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              classes={{
                indicator: classes.indicator
              }}
            >
              <Tab
                classes={{
                  selected: classes.selected
                }}
                className={classes.tabRoot}
                label="Profile"
                {...a11yProps(0)}
              />
              <Tab
                classes={{
                  selected: classes.selected
                }}
                className={classes.tabRoot}
                label="Survey Details"
                {...a11yProps(1)}
              />
              <Tab
                classes={{
                  selected: classes.selected
                }}
                className={classes.tabRoot}
                label="Appointments"
                {...a11yProps(2)}
              />
              <Tab
                classes={{
                  selected: classes.selected
                }}
                className={classes.tabRoot}
                label="Documentation"
                {...a11yProps(3)}
              />
              <Tab
                classes={{
                  selected: classes.selected
                }}
                className={classes.tabRoot}
                label="Contact Attempts"
                {...a11yProps(4)}
              />
              <Tab
                classes={{
                  selected: classes.selected
                }}
                className={classes.tabRoot}
                label="Notes"
                {...a11yProps(3)}
              />
            </Tabs>
          </AppBar>
          <div className={classes.tabbackground}>
            <TabPanel value={value} index={0}>
              <StudentProfileForm />
            </TabPanel>
            <TabPanel value={value} index={1}>
              {studentobj && <SurveyDetails studentDetails={studentobj} />}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {studentobj && <AppointmentInfo studentDetails={studentobj} />}
            </TabPanel>
            <TabPanel value={value} index={3}>
              {studentobj && <StudentDocumentation studentDetails={studentobj} />}
            </TabPanel>
            <TabPanel value={value} index={4}>
              {studentobj && <ContactAttemptsInfo studentDetails={studentobj} />}
            </TabPanel>
            <TabPanel value={value} index={5}>
              <div className={classes.aligncenter}>
                {studentobj && <StudentProfileNotes studentDetails={studentobj} />}
              </div>
            </TabPanel>
          </div>
        </MuiThemeProvider>
      </PageContainer>
    </div>
  )
}
