import React from 'react'
import { Button as MButton, ButtonProps } from '@material-ui/core'
import useStyles from './Styles'

const Button: React.FC<ButtonProps> = (props) => {
  const classes = useStyles()

  return <MButton classes={{ root: classes.root }} disableElevation {...props} />
}

export default Button
