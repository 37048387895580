import React, { useMemo } from 'react'
import { useStudentSelectLazyQuery, Student } from '../../../../graphql/hooks.generated'
import { AsyncSelect, SelectOption } from '../async-select/AsyncSelect'

interface StudentSelectProps {
  label: string
  multiple?: boolean
  name: string
  required?: boolean
  initialOptions?: Pick<Student, 'id' | 'name'>[]
}
const idToString = (el) => ({
  ...el,
  id: el.id.toString()
})

export const StudentSelect = (props: StudentSelectProps) => {
  const { initialOptions, ...rest } = props
  const [executeQuery, { data }] = useStudentSelectLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetch = useMemo(() => () => executeQuery(), [executeQuery])

  return (
    <AsyncSelect
      {...rest}
      fetchOptions={fetch}
      getOptionValue={(option: SelectOption) => option?.id}
      getOptionLabel={(option: SelectOption) => option?.name || ''}
      options={data?.students?.map(idToString) ?? initialOptions?.map(idToString) ?? []}
    />
  )
}
