import React, { useMemo, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import {
  Button,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide
} from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Collapse from '@material-ui/core/Collapse'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'
import { TransitionProps } from '@material-ui/core/transitions'
// import CircularProgress from '@material-ui/core/CircularProgress'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { UseParamsType } from 'types'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import {
  useStudentDocumentationLazyQuery,
  useDistAdminDocumentLazyQuery,
  useUserDocumentLazyQuery,
  useCurrentUserQuery,
  Role,
  useDeleteIncompleteSessionsMutation
} from 'graphql/hooks.generated'
import { ReactComponent as EditIcon } from '../../../../../../assets/icons/84380.svg'
import { useStyles } from '../../../../../../config/constants/theme/profile'
import { PageContainer } from '../../../../../../components/ui/panel/page-container/PageContainer'

interface ListViewParams extends UseParamsType {
  session_id: string
  stu_id: string
  user_id: string
  caseload_id: string
  the_des: string
}
interface ParentParams {
  caseloaddata?: any
  fromDate: any
  toDate: any
  studentFilter?: any
  totalsession: (count) => unknown
  isTherapst?: boolean
  isAdmin?: boolean
  therapistFilter?: any
  providername?: any
}

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
  price: number
) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      { date: '2020-01-05', customerId: '11091700', amount: 3 },
      { date: '2020-01-02', customerId: 'Anonymous', amount: 1 }
    ]
  }
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function CollapsibleTable(props: ParentParams) {
  const { hasAnyRole } = useCurrentUser()
  const classes = useStyles()
  const history = useHistory()
  // Params
  const { stu_id } = useParams<ListViewParams>()
  // Props
  const {
    caseloaddata,
    fromDate,
    toDate,
    studentFilter,
    totalsession,
    isTherapst,
    isAdmin,
    therapistFilter,
    providername
  } = props
  const setFromDate = moment(fromDate).format()
  const setToDate = moment(toDate).format()
  // States
  const [open, setOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  // get therapist discipline
  let therapist_discipline

  if (caseloaddata)
    caseloaddata?.caseloads.map((userdata) => {
      therapist_discipline = userdata?.user_id?.therapist_discipline

      return true
    })
  // Student doc level api call

  const [executeQuery, { data, loading, refetch }] = useStudentDocumentationLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      student_id: Number(stu_id),
      therapist_discipline: String(therapist_discipline),
      FromDate: setFromDate,
      ToDate: setToDate
    }
  })
  const fetchStudentDocumentation = useMemo(() => () => executeQuery(), [executeQuery])
  // therapist level doc call
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const provider = hasAnyRole(Role.AgencySupervisor, Role.AgencySupervisorProvider)
  const agency = String(currentUserdata?.data?.currentUser?.signalAgencies?.[0]?.id)
  const [
    executeUserDocumentQuery,
    { data: userDocumentData, loading: userDocumentLoading, refetch: userDocumentrefetch }
  ] = useUserDocumentLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      user_id: providername,
      FromDate: setFromDate,
      ToDate: setToDate,
      StuId: studentFilter === -1 ? null : Number(studentFilter),
      agency_id: provider ? agency : ''
    }
  })
  const fetchUserDocument = useMemo(() => () => executeUserDocumentQuery(), [
    executeUserDocumentQuery
  ])
  const [
    adminDocQuery,
    { data: adminDocData, loading: adminDocLoading }
  ] = useDistAdminDocumentLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      user_id: therapistFilter,
      FromDate: setFromDate,
      ToDate: setToDate,
      StuId: Number(studentFilter),
      district_id: Number(currentUserdata?.data?.currentUser?.district?.id)
    }
  })
  const fetchAdminDocument = useMemo(() => () => adminDocQuery(), [adminDocQuery])
  // combination of student and therapist level call array
  let documentSessArray: any

  if (data) {
    documentSessArray = data?.studentDocumentation
    totalsession(data?.studentDocumentation?.length)
  }
  if (userDocumentData) {
    documentSessArray = userDocumentData?.userDocument
    totalsession(userDocumentData?.userDocument?.length)
  }
  if (adminDocData) {
    documentSessArray = adminDocData?.distAdminDocument
    totalsession(adminDocData?.distAdminDocument?.length)
  }

  const [deleteSessions, { loading: deleteLoading }] = useDeleteIncompleteSessionsMutation()
  const [openDelete, setOpenDelete] = React.useState(false)
  const [deleteId, setDeleteId] = useState('')
  const deleteSession = async (documentation) => {
    await deleteSessions({ variables: { sessionId: deleteId } })

    if (currentUserdata && !stu_id && isAdmin) await fetchAdminDocument()
    if (currentUserdata && !stu_id && isTherapst) await fetchUserDocument()

    setOpenDelete(false)
  }
  const handleClose = () => {
    setOpenDelete(false)
  }
  const handleClickOpen = (documentation) => {
    setOpenDelete(true)
    setDeleteId(documentation.id)
    // deleteSession(documentation)
  }
  // use effect
  useEffect(() => {
    if (stu_id) fetchStudentDocumentation()
    else if (currentUserdata && !stu_id && isTherapst) fetchUserDocument()
    else if (currentUserdata && !stu_id && isAdmin) fetchAdminDocument()
  }, [
    fetchStudentDocumentation,
    fetchUserDocument,
    fetchAdminDocument,
    currentUserdata,
    stu_id,
    isTherapst,
    isAdmin
  ])
  const CurrentuserId = currentUserdata?.data?.currentUser?.id
  // condition check for display students in the therapistRole screen
  const handleClick = (id) => {
    setSelectedIndex(id)

    if (id === selectedIndex) setOpen(!open)
    else setOpen(true)
  }
  const editStudentsSessioninfo = (id, fromDate, toDate) => {
    history.push(`/calendar/${id}/${fromDate}/${toDate}/sessions`)
  }
  const isLoading = () =>
    loading || userDocumentLoading || adminDocLoading || deleteLoading ? (
      <ProgressIndicator fullHeight />
    ) : (
      ''
    )
  const commonTableView = () => {
    return (
      <TableContainer
        // / style={{ paddingLeft: '0px', paddingRight: '0px' }}
        className={(classes.updatetable, classes.tabconAdjust)}
        component={Paper}
      >
        <Table aria-label="collapsible table" style={{ tableLayout: 'fixed' }}>
          <TableRow>
            <TableCell className={classes.dochead} width="42%">
              Date/Time
            </TableCell>
            <TableCell className={classes.dochead} width="30%">
              Provider
            </TableCell>
            {!stu_id && (
              <TableCell className={classes.dochead} width="26%">
                Clients
              </TableCell>
            )}
            {/* <TableCell className={classes.dochead} width="15%">
              Service
            </TableCell> */}
            <TableCell className={classes.dochead} width="28%">
              Request client resources/ referrals
            </TableCell>
            <TableCell className={classes.dochead} width="31%">
              On-Going Care
            </TableCell>
            <TableCell className={classes.dochead} width="37%">
              Status
            </TableCell>
            <TableCell className={`${classes.actionHeaders} `} width="25%">
              Attendance
            </TableCell>
            <TableCell className={`${classes.actionHeaders} ${classes.alignleft}`} width="28%">
              Actions
            </TableCell>
          </TableRow>
          <TableBody>
            {documentSessArray && documentSessArray?.length ? (
              <>
                {documentSessArray?.map((documentation, index) => (
                  <>
                    {((stu_id && documentation?.session_Goals?.length > 0) ||
                      (!stu_id && documentation?.session_students?.length > 0)) && (
                      <TableRow key={documentation?.session_id?.id} className={classes.root}>
                        <TableCell className={classes.forntSizes}>
                          {stu_id ? (
                            <div>
                              <div>{documentation?.appointment_id?.sdate} </div>
                              <div>
                                {' '}
                                {documentation?.appointment_id?.stime}-
                                {documentation?.appointment_id?.etime}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div>{moment(documentation?.sdate).format('MM/DD/YYYY')} </div>
                              <div>
                                {' '}
                                {documentation?.stime}-{documentation?.etime}
                              </div>
                            </div>
                          )}
                          {stu_id ? (
                            <div>
                              {documentation?.appointment_id?.sdate}
                              {documentation?.appointment_id?.edate}
                              {documentation?.appointment_id?.stime}
                              {documentation?.appointment_id?.etime}
                            </div>
                          ) : (
                            <div>
                              {/* {moment
                                .tz(documentation?.scheduled_start, 'America/Denver')
                                .format('hh:mm a')}{' '} */}
                              {documentation?.appointment_id?.sdate}
                              {/* {moment
                                .tz(documentation?.scheduled_end, 'America/Denver')
                                .format('hh:mm a')} */}
                              {documentation?.appointment_id?.edate}
                              {documentation?.appointment_id?.stime}
                              {documentation?.appointment_id?.etime}
                            </div>
                          )}
                        </TableCell>
                        <TableCell className={classes.forntSizes}>
                          {stu_id
                            ? documentation?.session_id?.user_id?.name
                            : documentation?.user_id?.name}
                        </TableCell>
                        {!stu_id && (
                          <TableCell className={classes.forntSizes}>
                            {documentation?.session_students?.map((Students, index) => (
                              <div>
                                {Students?.student_id?.name}
                                {documentation?.session_students?.length - 1 !== index && (
                                  <span>,&nbsp;</span>
                                )}
                              </div>
                            ))}
                          </TableCell>
                        )}
                        {/* <TableCell className={classes.forntSizes}>
                          {stu_id
                            ? documentation?.session_id?.appointment_id?.service_id?.[0]?.name
                            : documentation?.appointment_id?.service_id?.[0]?.name}
                        </TableCell> */}
                        <TableCell className={classes.text}>
                          {stu_id
                            ? documentation?.high_level_care
                              ? 'Yes'
                              : 'No'
                            : documentation?.session_students?.map((Students) => (
                                <div>{Students?.high_level_care ? 'Yes' : 'No'} </div>
                              ))}
                        </TableCell>
                        <TableCell className={classes.text}>
                          {stu_id
                            ? documentation?.further_sessions
                              ? 'Yes'
                              : 'No'
                            : documentation?.session_students?.map((Students) => (
                                <div>{Students?.further_sessions ? 'Yes' : 'No'} </div>
                              ))}
                        </TableCell>
                        <TableCell>
                          <div>
                            {' '}
                            {stu_id
                              ? !documentation?.session_id?.sign && (
                                  <div className={`${classes.alignRightt} ${classes.saveBtnnew}`}>
                                    ! Incomplete Notes
                                  </div>
                                )
                              : !documentation?.sign && (
                                  <div className={`${classes.alignRightt} ${classes.saveBtnnew}`}>
                                    ! Incomplete Notes
                                  </div>
                                )}
                            {stu_id
                              ? documentation?.session_id?.sign && (
                                  <div
                                    className={`${classes.saveBtnn}`}
                                    style={{ color: '#4da063' }}
                                  >
                                    <CheckBoxIcon className={`${classes.completedIcon}`} />
                                    completed
                                  </div>
                                )
                              : documentation?.sign && (
                                  <div
                                    className={`${classes.saveBtnn}`}
                                    style={{ color: '#4da063' }}
                                  >
                                    <CheckBoxIcon className={`${classes.completedIcon}`} />
                                    completed
                                  </div>
                                )}
                          </div>
                        </TableCell>
                        <TableCell className={classes.text}>
                          {stu_id
                            ? documentation?.present
                              ? 'Present'
                              : 'Absent'
                            : documentation?.session_students?.map((Students) => (
                                <div>{Students?.present ? 'Present' : 'Absent'} </div>
                              ))}
                        </TableCell>
                        <TableCell>
                          <div style={{ width: '90px', fontFamily: 'Brandon Grotesque' }}>
                            <IconButton
                              className={`${classes.alignRightt} ${classes.alignPadding}`}
                              aria-label="expand row"
                              style={{ paddingLeft: '10px' }}
                              size="small"
                              onClick={() => handleClick(index)}
                            >
                              {open && index === selectedIndex ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                            {!hasAnyRole(Role.TeleteachersAdmin) &&
                              !hasAnyRole(Role.SchoolAdmin) &&
                              !hasAnyRole(Role.AgencySupervisor) &&
                              // providername === CurrentuserId &&
                              documentation?.user_id?.id === CurrentuserId && (
                                <>
                                  {stu_id ? (
                                    <Link
                                      className={`${classes.alignRightt} ${classes.alignPadding}`}
                                      onClick={() =>
                                        editStudentsSessioninfo(
                                          documentation?.appointment_id?.id,
                                          moment(documentation?.appointment_id?.sdate).format(),
                                          moment(documentation?.appointment_id?.edate).format()
                                        )
                                      }
                                    >
                                      <EditIcon
                                        width="18"
                                        height="25"
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </Link>
                                  ) : (
                                    <Link
                                      className={`${classes.alignRightt} ${classes.alignPadding}`}
                                      onClick={() =>
                                        editStudentsSessioninfo(
                                          documentation?.appointment_id?.id,
                                          moment(documentation?.appointment_id?.sdate).format(),
                                          moment(documentation?.appointment_id?.edate).format()
                                        )
                                      }
                                    >
                                      <EditIcon
                                        width="18"
                                        height="25"
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </Link>
                                  )}
                                </>
                              )}
                            {(isAdmin || isTherapst) &&
                              !documentation?.sign &&
                              !hasAnyRole(Role.AgencySupervisor) &&
                              !hasAnyRole(Role.AgencySupervisorProvider) && (
                                <DeleteIcon
                                  style={{
                                    marginBottom: '-17px',
                                    cursor: 'pointer'
                                  }}
                                  color="primary"
                                  onClick={() => handleClickOpen(documentation)}
                                />
                              )}

                            {hasAnyRole(Role.AgencySupervisorProvider) &&
                              !documentation?.sign &&
                              // providername === CurrentuserId &&
                              documentation?.user_id?.id === CurrentuserId && (
                                <DeleteIcon
                                  style={{
                                    marginBottom: '-17px',
                                    cursor: 'pointer'
                                  }}
                                  color="primary"
                                  onClick={() => handleClickOpen(documentation)}
                                />
                              )}
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell className={`${classes.forntSizes} ${classes.ltblue}`} colSpan={12}>
                        <Collapse in={open && index === selectedIndex} timeout="auto" unmountOnExit>
                          <div>
                            <Grid item xs={12}>
                              <div className={classes.straightline} />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <h1 className={classes.dochead}>
                                  Goals/Benchmarks, Progress & Activity
                                </h1>
                                {documentation?.session_Goals?.map((session_Goals) => (
                                  <div className={classes.collapsetheme}>
                                    <Grid key={session_Goals?.goal_id?.id} container>
                                      {/* <div style={{ display: 'flex' }} key={session_Goals?.goal_id?.id}> */}
                            {/* <Grid item md={6} xs={6} className={classes.doccardpad}>
                                        <p className={classes.mar0}>
                                          <span>
                                            {session_Goals?.student_id?.name} {!stu_id && ':'}
                                            {session_Goals &&
                                            session_Goals.parent_goal_id &&
                                            session_Goals.parent_goal_id.length > 0
                                              ? ' BM '
                                              : ' Goal '}
                                            {session_Goals?.goal_id?.goal_number} :
                                          </span>
                                          <span>
                                            {' '}
                                            PROGRESS{' '}
                                            {session_Goals?.percentage
                                              ? session_Goals?.percentage
                                              : 0}{' '}
                                            %
                                          </span>
                                          ;{' '}
                                          <span>
                                            {session_Goals?.attempts ? session_Goals?.attempts : 0}/
                                            {session_Goals?.total ? session_Goals?.total : 10}{' '}
                                            ATTEMPTS{' '}
                                          </span>
                                          ,{' '}
                                          <span>
                                            {session_Goals?.assist ? session_Goals?.assist : 0}/
                                            {session_Goals?.total ? session_Goals?.total : 10}{' '}
                                            ASSISTS
                                          </span>
                                        </p>
                                      </Grid>
                                      <Grid item md={6} xs={6} className={classes.doccardpad}>
                                        <p className={classes.mar0}>
                                          {session_Goals &&
                                          session_Goals.parent_goal_id &&
                                          session_Goals.parent_goal_id.length > 0
                                            ? 'Activity: '
                                            : 'Activity: '}
                                          {session_Goals?.activity}
                                        </p>
                                      </Grid> */}
                            {/* </div> */}
                            {/* </Grid>
                                  </div>
                                ))}
                              </Grid>  */}
                            <Grid container spacing={3} className={classes.marbtneg}>
                              <Grid item xs={12}>
                                <h1 className={classes.dochead}>Data, Assessment & Plan</h1>
                                <Grid
                                  container
                                  className={`${classes.collapsetheme} ${classes.ptb10}`}
                                >
                                  {!stu_id &&
                                    documentation?.session_students?.length &&
                                    documentation?.session_students?.map((studInfo) => (
                                      <Grid item md={12} xs={12} className={classes.mar0}>
                                        <div className={classes.wraptext}>
                                          <strong>Data:</strong> {studInfo?.notes}
                                        </div>
                                        <div className={classes.wraptext}>
                                          <strong>Assessment:</strong> {studInfo?.assessment}
                                        </div>
                                        <div className={classes.wraptext}>
                                          <strong>Plan:</strong> {studInfo?.plan}
                                        </div>
                                        <div>
                                          <strong>Resources/referrals requested:</strong>{' '}
                                          {studInfo?.high_level_care_text}
                                        </div>
                                        <div>
                                          <strong>On-Going Care Notes:</strong>{' '}
                                          {studInfo?.further_sessions_text}
                                        </div>
                                        <div>
                                          <strong>Outcome of Session:</strong>{' '}
                                          {studInfo?.session_id?.outcome_id?.name}
                                        </div>
                                      </Grid>
                                    ))}
                                  {stu_id && (
                                    <Grid item md={12} xs={12} className={classes.mar0}>
                                      <div>
                                        <strong>Data:</strong> {documentation?.notes}
                                      </div>
                                      <div>
                                        <strong>Assessment:</strong> {documentation?.assessment}
                                      </div>
                                      <div>
                                        <strong>Plan:</strong> {documentation?.plan}
                                      </div>
                                      <div>
                                        <strong>Resources/referrals requested:</strong>{' '}
                                        {documentation?.high_level_care_text}
                                      </div>
                                      <div>
                                        <strong>On-Going Care Notes:</strong>{' '}
                                        {documentation?.further_sessions_text}
                                      </div>
                                      <div>
                                        <strong>Outcome of Session:</strong>{' '}
                                        {documentation?.session_id?.outcome_id?.name}
                                      </div>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                              {/* <Grid item xs={6}>
                                  <h1 className={classes.dochead}>Clips</h1>
                                  <div className={`${classes.collapsetheme} ${classes.ptb10}`}>
                                    <p className={classes.mar0}>Clip1</p>
                                  </div>
                                </Grid> */}
                            </Grid>
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell>
                  <span>
                    <h3>No Documentation</h3>
                  </span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {isLoading()}
      </TableContainer>
    )
  }

  return (
    <>
      {stu_id ? commonTableView() : <PageContainer>{commonTableView()}</PageContainer>}
      {openDelete && (
        <Dialog
          open={openDelete}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure want to delete session
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button onClick={deleteSession} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
