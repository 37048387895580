import { withWidth } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '80%',
      margin: 'auto',
      backgroundColor: '#fff',
      fontFamily: 'Brandon Grotesque',
      '& table, td': {
        border: '1px solid black',
        borderCollapse: 'collapse'
      },
      '& select:disabled': {
        backgroundColor: 'rgba(239, 239, 239, 0.6)'
      },
      [theme.breakpoints.down('xs')]: {
        '& table, td': {
          display: 'block'
        },
        '.MuiDialogContent': {
          padding: '4px 1px'
        }
      }
    },
    inputf: {
      border: '0px',
      fontSize: '16px',
      fontFamily: 'Brandon Grotesque',
      '&:focus-visible': {
        outline: 'none'
      }
    },
    p50: {
      padding: '50px'
    },
    pb50: {
      paddingBottom: '50px'
    },
    widthFull: {
      width: '100%'
    },
    tabletd: {
      width: '300px'
    },
    plr10: {
      padding: '0px 10px'
    },
    twoLineH: {
      height: '40px'
    },
    threeLineH: {
      height: '60px'
    },
    textheader: {
      paddingTop: '15px',
      fontSize: '17px',
      textDecoration: 'underline',
      fontWeight: 'bold',
      fontFamily: 'Brandon Grotesque'
    },
    pl15: {
      paddingLeft: '15px'
    },
    fbold: {
      fontWeight: 'bold',
      fontSize: '17px',
      fontFamily: 'Brandon Grotesque !important'
    },
    pt10: {
      paddingTop: '10px',
      fontSize: '17px'
    },
    fleft: {
      float: 'left'
    },
    mtb0: {
      marginTop: '0px',
      marginBottom: '0px',
      fontSize: '17px'
    },
    circle: {
      listStyleType: 'circle'
    },
    f12: {
      fontSize: '16px'
    },
    f13: {
      fontSize: '17px !important'
    },
    buttonStyles: {
      backgroundColor: '#F1583A',
      color: 'white',
      border: '1px solid #F1583A',
      padding: '6px 25px',
      fontSize: '16px',
      fontFamily: 'Brandon Grotesque !important',
      fontWeight: 600,
      borderRadius: '25px',
      marginRight: '5px',
      // textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#F1583A'
      }
    },
    buttonStyles1: {
      color: 'grey',
      border: '1px solid grey',
      fontWeight: 'normal',
      paddingLeft: '10px',
      paddingRight: '10px',
      borderRadius: '25px',
      float: 'right'
    },
    textCenter: {
      textAlign: 'center'
    },
    flexb: {
      display: 'flex',
      alignItems: 'flex-end',
      width: '100%'
    },
    borderb: {
      borderBottom: '1px solid #000'
    },
    pr50: {
      paddingRight: '50px'
    },
    dflex: {
      display: 'flex'
    },
    pb10: {
      paddingBottom: '10px'
    },
    texterror: {
      color: 'red'
    },
    relative: {
      position: 'relative'
    },
    signIcon: {
      position: 'absolute',
      right: '0px',
      marginTop: '-5px',
      cursor: 'pointer',
      bottom: '0px'
    },
    addSign: {
      textAlign: 'center',
      fontSize: '18px'
    },
    canvasDiv: {
      '& canvas': {
        border: '1px solid #ccc !important',
        borderRadius: '10px !important'
      },
      [theme.breakpoints.down('xs')]: {
        '& canvas': {
          width: '200px',
          height: '100px'
        }
      }
    },
    signimg: {
      backgroundSize: '200px 50px',
      width: '200px',
      height: '50px'
    },
    btm5: {
      bottom: '5px'
    },
    borBot: {
      borderBottom: '1px solid #000'
    },
    mr20: {
      marginRight: '20px'
    },
    [theme.breakpoints.down('xs')]: {
      p50: {
        padding: '0px',
        '& .MuiDialog-paperWidthLg': {
          fontFamily: 'Brandon Grotesque'
        }
      },
      buttonStyles: {
        width: '100%',
        marginTop: '10px',
        fontFamily: 'Brandon Grotesque'
      },
      pr50: {
        paddingRight: '0px'
      },
      tabletd: {
        width: '200px'
      },
      plr10: {
        padding: '0px 4px'
      },
      buttonStyles1: {
        width: '100%',
        marginTop: '10px'
      }
    }
  })
)
