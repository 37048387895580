import React, { useState } from 'react'
import { Grid, Chip, Typography } from '@material-ui/core'
import useStyles from '../../../../components/ui/auth/register/Styles'

export const TimeSlots = (item) => {
  const slots = item
  // const [timeSlots, setTimeSlots] = useState(slots?.item || {})
  const [selectedSlot, setSelectedSlot] = useState()
  const slotKeys = Object.keys(slots?.item)
  const classes = useStyles()
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const handleClick = (e, slot) => {
    e.target.classList.toggle('selected')
    setSelectedSlot(slot)
    slots.slotSelected(slot)
  }

  return (
    <>
      {slotKeys?.length >= 1 ? (
        slotKeys.map((item) => {
          return (
            <Grid
              sm={12}
              spacing={1}
              container
              direction="row"
              className={classes.slots}
              key={item}
            >
              <Grid item xs={12} sm={2} className={classes.slotBtnFirst}>
                <Typography style={{ color: ' #4b99d1', fontWeight: '600' }}>{item}</Typography>
                {/* <div>{item}</div> */}
              </Grid>
              <Grid item xs={12} sm={10}>
                {slots?.item[item].map((slot, i) => {
                  return (
                    <Chip
                      className={[
                        classes.therapistCustomChip,
                        slot?.service_type_id === 1
                          ? classes.inpersonBg
                          : slot?.service_type_id === 2
                          ? classes.teleBg
                          : slot?.service_type_id === 3
                          ? classes.bothBg
                          : '',
                        selectedSlot?.timeperiod === slot.timeperiod &&
                        selectedSlot?.time_slot === slot.time_slot
                          ? classes.therapistSelected
                          : ''
                      ]}
                      // className={classes.customChip}
                      label={slot.time_slot}
                      onClick={(e) => handleClick(e, slot)}
                    />
                  )
                })}
              </Grid>
            </Grid>
          )
        })
      ) : (
        <>
          <div style={{ color: '#FF0000' }}>
            “No slots currently available for this provider. Please choose another provider to
            continue.”
          </div>
        </>
      )}
    </>
  )
}
