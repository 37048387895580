import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import { IcdCodeFormModel, IcdCodeForm } from '../form/IcdCodeForm'
import {
  useUpdateIcdCodeMutation,
  useIcdCodeQuery,
  IcdCode,
  useDeleteIcdCodeMutation
} from '../../../../graphql/hooks.generated'
import { UseParamsType } from '../../../../types'

interface UpdateIcdCodeViewParams extends UseParamsType {
  id: string
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const UpdateIcdCodeView = () => {
  const history = useHistory()
  const { id } = useParams<UpdateIcdCodeViewParams>()
  const [updateIcdCode] = useUpdateIcdCodeMutation()
  const { data } = useIcdCodeQuery({
    variables: { id: parseInt(id) },
    fetchPolicy: 'network-only'
  })
  const [deleteIcdCode] = useDeleteIcdCodeMutation()
  const onDelete = async () => {
    await deleteIcdCode({ variables: { id: parseInt(id) } })
    history.goBack()
  }
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const icdCode:
    | ({ __typename?: 'IcdCode' | undefined } & Pick<
        IcdCode,
        'name' | 'code' | 'version' | 'therapist_discipline'
      >)
    | undefined = data?.icdCode
  const initialValues: IcdCodeFormModel | null = icdCode
    ? {
        name: icdCode.name,
        code: icdCode.code,
        version: icdCode.version,
        therapistDiscipline: icdCode.therapist_discipline
      }
    : null

  return (
    <>
      <NavBar title="Update IcdCode" goBackBtn />
      <PageContainer>
        <IcdCodeForm
          submitLabel="Save"
          initialValues={initialValues}
          onSubmit={(input: IcdCodeFormModel) =>
            updateIcdCode({
              variables: {
                id: parseInt(id),
                input: { ...input }
              }
            }).then(history.goBack)
          }
        />
        <Button
          style={{ marginLeft: 259, marginTop: -73 }}
          size="large"
          variant="outlined"
          color="primary"
          onClick={handleClickOpen}
        >
          Delete
        </Button>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure want to Delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button onClick={onDelete} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </PageContainer>
    </>
  )
}
