import React, { PropsWithChildren } from 'react'
import { useCurrentUser } from '../graphql/hooks/useCurrentUser/useCurrentUser'
import ProgressIndicator from '../components/ui/panel/progress-indicator/ProgressIndicator'

export const UserGate = ({ children }: PropsWithChildren<unknown>) => {
  const { user } = useCurrentUser()

  if (!user.email) return <ProgressIndicator fullHeight />

  return <>{children}</>
}
