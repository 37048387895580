import React from 'react'
import { SelectProps } from 'mui-rff'
import { therapistDisciplineDescription } from 'views/private/serviceRequests/util/enumDescription'
import { DictionarySelect } from '../dictionary-select/DictionarySelect'

type TherapistDisciplineSelectProps = SelectProps

export const TherapistDisciplineSelect = (props: TherapistDisciplineSelectProps) => {
  return <DictionarySelect optionsDictionary={therapistDisciplineDescription} {...props} />
}
