import React from 'react'
import {
  AppBar as MAppBar,
  AppBarProps,
  Container,
  Grid,
  Toolbar,
  Typography,
  Link
} from '@material-ui/core'
import { ArrowBackOutlined as ArrowBackIcon } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import IconButton from '../icon-button/IconButton'
import useStyles from './Styles'

interface NavOption {
  label: string
  path: string
}

interface NavBarProps extends Omit<AppBarProps, 'title'> {
  title: React.ReactNode
  navOptions?: Array<NavOption>
  goBackBtn?: boolean
}

const NavBar: React.FC<NavBarProps> = ({ title, navOptions, goBackBtn, ...props }: NavBarProps) => {
  const classes = useStyles()
  const history = useHistory()

  return <></>
  // (
  // <MAppBar color="transparent" position="static" {...props} className={classes.root}>
  //   <Container className={classes.container}>
  //     <Toolbar>
  //       <Grid container alignItems="center" alignContent="center">
  //         <Grid item xs={6}>
  //           {typeof title === 'string' ? (
  //             <Typography
  //               variant="h6"
  //               color="secondary"
  //               style={{ display: 'flex', alignItems: 'center' }}
  //             >
  //               {goBackBtn && (
  //                 <IconButton
  //                   style={{
  //                     height: '17px',
  //                     width: '17px',
  //                     borderWidth: '1.5px',
  //                     borderColor: 'inherit',
  //                     marginRight: '17px'
  //                   }}
  //                   bordered
  //                   color="inherit"
  //                   onClick={history.goBack}
  //                 >
  //                   <ArrowBackIcon style={{ fontSize: 15 }} />
  //                 </IconButton>
  //               )}
  //               {title}
  //             </Typography>
  //           ) : (
  //             title
  //           )}
  //         </Grid>

  //         <Grid item xs={6} classes={{ root: classes.links }}>
  //           {navOptions?.map(({ label, path }) => (
  //             <Link key={path} color="secondary" href={path} style={{ margin: '0 15px' }}>
  //               {label}
  //             </Link>
  //           ))}
  //         </Grid>
  //       </Grid>
  //     </Toolbar>
  //   </Container>
  // </MAppBar>
  // )
}

export default NavBar
