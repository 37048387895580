export const engLoc = {
  mandatoryform: {
    pcsig: 'Primary Clinician’s Signature',
    crsig: 'Client’s or Responsible Party’s Signature',
    save: 'Save',
    clear: 'clear',
    cancel: 'Cancel',
    csend: 'Complete and send',
    mdform: 'MANDATORY DISCLOSURE FORM',
    pcname: 'Primary Clinician Name',
    lctext: 'Licenses,Certifications',
    lcnumbers: '(including license/certification numbers)',
    school: 'Degrees Held',
    degreeSchool: 'School Attended',
    aname: 'Agency Name',
    agency: 'Agency Address',
    cphone: 'Clinician Phone',
    straining: 'Specialized Training and Areas of Clinical Competence',
    straining1: '(i.e.: treatment modalities, clinical orientation, populations served)',
    supervisor: 'Supervisor Name and Credentials',
    supervisor1:
      '(if clinician is working under supervisor’s license, certification, or registration)',
    about: 'About Your Services:',
    aboutT1:
      'You are entitled to receive information from the clinician listed above about methods of therapy, the techniques used, the expected duration of your therapy, and the fee structure.',
    aboutT2:
      'You can seek a second opinion from another clinician or terminate therapy at any time.',
    aboutT3:
      'Any attempt from a clinician to engage in a sexual relationship with a client is inappropriate and should be reported to the board that licenses, registers, or certifies the licensee, registrant, or certificate holder.',
    confidentiality: 'Confidentiality:',
    confidentialityT1:
      'The information you give in therapy sessions and your medical records are private and confidential. The clinician/clinician’s agency follows the Colorado Revised Statutes and the Division of Alcohol and Drug Abuse code of Federal Regulations, which increases the privacy of substance abuse records.',
    confidentialityT2:
      'None of your health information can be shared without your written approval, except in some cases. Exceptions are listed in the Colorado statutes (C.R.S. 12-245-220). There are exceptions to the rule of confidentiality regarding &ruot;threat of serious harm to yourself or others" and generally include:" ',
    cbp1: 'suspected abuse, neglect, or exploitation of a child, at-risk adult, or elder',
    cbp2: 'suicide risk',
    cbp3: 'homicide risk',
    cbp4: 'grave disability',
    cbp5: 'medical emergencies',
    cbp6: 'under a court order',
    cbp7: 'in response to any legal action taken by you against this agency',
    confidentialityT3:
      'Your records may not be maintained after seven years pursuant to section 12-245-226(1)(a)(II)(A).',
    qc: 'Questions or Complaints:',
    qrc1: 'If you have any questions or complaints, please contact the Care Navigator at',
    qrc2: 'A Care Navigator will respond to your request within 3 business days.',
    regulatory: 'Regulatory Agency:',
    regtext:
      'The Department of Regulatory Agencies (DORA) - Division of Professions and Occupations is in charge of mental health licenses, registrations, and certifications in the State of Colorado. This means that DORA watches over mental health and substance use professionals. The agency within the Department that has responsibility specifically for licensed, registered, and certified mental health professionals is the Department of Regulatory Agencies, Mental Health Section, 1560 Broadway, Suite 1350, Denver, Colorado 80202, (303) 894-7766. Below is more information about the different licenses',
    A: 'A',
    lcsw: 'Licensed Clinical Social Worker (LCSW)',
    lcswT:
      'must have a master’s or doctorate degree from a graduate school of social work, work as a social worker for at least two years, and pass an exam in social work.',
    lmft: 'Licensed Marriage and Family Provider (LMFT)',
    lmftT:
      'must have a master’s or doctorate degree in marriage and family counseling, work as a marriage and family provider for at least 2 years and pass an exam in marriage and family therapy.',
    lpc: 'Licensed Professional Counselor (LPC)',
    lpcT:
      'must have a master’s or doctorate degree in professional counseling, work as a professional counselor for at least 2 years, and pass an exam in professional counseling.',
    lp: 'Licensed Psychologist (LP)',
    lpT:
      'must have a doctorate degree in psychology, work as a psychologist for one year, and pass an exam in psychology.',
    lsw: 'Licensed Social Worker (LSW)',
    lswT:
      'must have a master’s degree from a school of social work and pass an exam in social work.',
    psycologist:
      'Psychologist Candidate, a Marriage and Family Provider Candidate, or a Licensed Professional Counselor Candidate',
    psycologistT:
      'must have graduate degree in their area and be in the process of finishing supervision for licensure.',
    cat: 'Certified Addiction Technician (CAT)',
    catT:
      'must have a high school diploma or equal, complete the required training classes, and obtain 1,000 hours of supervised work experience, and must pass the NAADAC exam at NCAC I level.',
    cas: 'Certified Addiction Specialist (CAS)',
    casT:
      'must have a bachelor’s degree in a clinical behavioral health concentration, obtain 2,000 hours of supervised work, and pass the NAADAC exam at NCAC II level.',
    lac: 'Licensed Addiction Counselor (LAC)',
    lacT:
      'must have a master’s degree in a relevant clinical field, meet the specific training requirements, obtain 3,000 hours of supervised work, and pass the NAADAC MAC exam.',
    unlicensed: 'Unlicensed Psychotherapist',
    unlicensedT:
      "is a psychotherapist listed in the state 's database and is authorized by law to practice psychotherapy in Colorado but is not licensed by the state and is not required to satisfy any standardized educational or testing requirements to obtain a registration from the state.",
    ack: 'Acknowledgement:',
    ackT1:
      'If you have any questions or would like additional information, please feel free to ask during the review of this document or at any time during your course of treatment.',
    ackT2:
      'I have read the preceding information and understand my rights as a patient or as the patient’s responsible party.',
    cname: 'Client’s Name (printed)',
    crpsignature: 'Client’s or Responsible Party’s Signature',
    dt: 'Date/Time',
    pcnprinted: 'Primary Clinician’s Name (printed)',
    pcsignature: 'Primary Clinician’s Signature',
    rtoc: 'Relationship to Client'
  },
  video: {
    start: 'Start',
    stop: 'Stop',
    record: 'Recording',
    flip: 'Flip Camera',
    end: 'Disconnect',
    joinmeeting: 'Joining Meeting',
    joinSession: 'Join Session',
    joinNow: 'Join Now',
    mute: 'Mute',
    unmute: 'Unmute',
    stopvideo: 'Stop Video',
    startvideo: 'Start Video',
    more: 'More',
    settings: 'Settings',
    about: 'About',
    audioVideoset: 'Audio and Video Settings',
    connectSet: 'Connection Settings',
    vidio: 'Video',
    audio: 'Audio',
    done: 'Done',
    videoInp: 'Video Input',
    nolocalVid: 'No Local Video',
    nolocalAud: 'No Local Audio',
    audInp: 'Audio Input',
    audOut: 'Audio Output',
    sysDefAudOut: 'System Default Audio Output',
    auto: 'Auto',
    manual: 'Manual',
    default: 'Default',
    contPrepMode: 'Content Preferences Mode',
    cliTraSwiOff: 'Client Track Switch Off Control',
    serDef: 'Server Default',
    present: 'Presentation',
    collabaration: 'Collaboration',
    grid: 'Grid',
    mode: 'Mode',
    disabled: 'Disabled',
    detected: 'Detected',
    predected: 'Predicted',
    trackSwifMode: 'Track Switch Off Mode',
    high: 'High',
    standard: 'Standard',
    low: 'Low',
    dominantSpeaker: 'Dominant Speaker Priority',
    theseSettNot: 'These settings cannot be changed when connected to a room',
    error: 'ERROR',
    errCode: 'Error Code',
    ok: 'OK'
  },
  clientmessage: {
    subject: 'Subject',
    message: 'Message',
    sendbtn: 'Send',
    sendmesg: 'Message has been sent to your provider. If you need further support please contact',
    sendmssg: 'support@imattercolorado.org',
    headerTxt:
      'Please leave a message for your provider below in order to change or update an appointment below. Just a reminder that this method of communication should not be used for any emergency communication.'
  },
  contactus: {
    name: 'First and Last Name',
    email: 'Email Address',
    message: 'Message',
    sendbtn: 'Send',
    sendmesg:
      'Thank you for your message. A member of the I Matter team will be in contact with you.',
    sendmssg: 'support@imattercolorado.org',
    headerTxt:
      'Please complete the required fields below and a member of the I Matter team will be in contact with you.'
  },
  clientFormDownload: {
    headerTxt:
      'According to our records, you have already completed the required Mandatory Disclosure form. ',
    text1: 'Please ',
    text2: 'click here ',
    text3: 'to download your copy. Thank you.'
  },
  clientMandateFormMessage: {
    text1:
      'According to our records, not all parties have signed the Mandatory Disclosure Form.  Be sure to ask your provider about this in your next session. Once all parties have signed, you have the ability to download the completed document. Thank you!'
  },
  clientMandatryRedirection: {
    text1:
      'Thank you for completing the Mandatory Disclosure form. Once both parties have signed, you will receive a copy to download in your inbox. Thank you.'
  },

  youthThankYouMessage: {
    text1: 'We thank you for your time spent taking this survey. Your response has been recorded.'
  },
  parentThankYouMessage: {
    text1:
      'Thank you for completing the survey! We hope to get feedback on I Matter from both parents/guardians and youth. If you are willing to have your child answer similar questions, please have them check their email to access their survey link.'
  },

  counsellorMessage: {
    text1:
      'Thank you for completing the survey! We hope to get feedback on I Matter from both parents/guardians or counselors, and youth. If you are willing to have your child or student answer similar questions, please have them check their email to access their survey link.'
  }
}

export const spaLoc = {
  mandatoryform: {
    pcsig: 'Firma del Médico Principal',
    crsig: 'Firma del Cliente o Parte Responsable',
    save: 'Ahorrar',
    clear: 'Clara',
    cancel: 'Cancelar',
    csend: 'Completar y enviar',
    mdform: 'FORMULARIO DE DIVULGACION OBLIGATORIA',
    pcname: 'Nombre del Médico Principal',
    lctext: 'Licencias, Certificaciones',
    lcnumbers: '(incluidos los números de licencia / certificación)',
    school: 'Título Otorgado',
    degreeSchool: 'Escuela a la que Asistió',
    aname: 'Nombre de la Agencia',
    agency: 'Dirección de la Agencia',
    cphone: 'Teléfono del Médico ',
    straining: 'Especialización y Áreas de Competencia Clínica',
    straining1:
      '(es decir, modalidades de tratamiento, orientación clínica, poblaciones asistidas)',
    supervisor: 'Nombre y Credenciales del Supervisor',
    supervisor1: '(si el médico trabaja con licencia, certificación o registro de supervisor)',
    about: 'Acerca de sus Servicios',
    aboutT1:
      'Usted tiene derecho a recibir información del médico mencionado anteriormente sobre los métodos de terapia, las técnicas utilizadas, la duración de su terapia y las tarifas.',
    aboutT2:
      'Usted puede buscar una segunda opinión de otro médico o interrumpir la terapia en cualquier momento.',
    aboutT3:
      'Se considera inapropiado cualquier intento de un médico de entablar una relación sexual con un cliente y debe informarse a la junta que otorga la licencia, registra o certifica al licenciatario, registrante o titular del certificado.',
    confidentiality: 'Confidencialidad:',
    confidentialityT1:
      'La información que usted brinde en las sesiones de terapia y sus registros médicos son privados y confidenciales. El medico /clínica. La agencia del médico / clínica sigue los Estatutos Revisados de Colorado y el código de Regulaciones Federales de la División de Abuso de Alcohol y Drogas que protege la privacidad de los registros de abuso de sustancias.',
    confidentialityT2:
      'No se puede compartir ninguna información sobre su salud sin su aprobación por escrito, excepto en algunos casos. Las excepciones se enumeran en los estatutos de Colorado (C.R.S.12-245-220). Existen excepciones a la regla de confidencialidad con respecto a la "amenaza de daño grave para usted o para otros" y generalmente incluyen:',
    cbp1: 'sospecha de abuso, negligencia o explotación de un niño, adulto en riesgo o anciano',
    cbp2: 'riesgo de suicidio',
    cbp3: 'riesgo de homicidio',
    cbp4: 'discapacidad grave',
    cbp5: 'emergencias médicas',
    cbp6: 'bajo una orden judicial',
    cbp7: 'en respuesta a cualquier acción legal tomada por usted contra esta agencia',
    confidentialityT3:
      'Es posible que sus registros no se mantengan después de siete años de conformidad con la sección 12-245-226 (1) (a) (II) (A).',
    qc: 'Preguntas o Quejas:',
    qrc1: 'Si usted tiene alguna pregunta o queja, comuníquese con un Coordinador de Casos al',
    qrc2: 'Un Coordinador de Casos responderá a su solicitud dentro de los 3 días hábiles.',
    regulatory: 'Agencia Reguladora:',
    regtext:
      'El Departamento de Agencias Reguladoras (DORA) - División de Profesiones y Ocupaciones está a cargo de las licencias, registros y certificaciones de salud mental en el estado de Colorado. Esto significa que DORA cuida a los profesionales de la salud mental y el uso de sustancias. La agencia dentro del Departamento que tiene la responsabilidad de los profesionales de salud mental con licencia, registrados y certificados es el Departamento de Agencias Reguladoras, Sección de Salud Mental, 1560 Broadway, Suite 1350, Denver, Colorado 80202, (303) 894-7766. A continuación, se muestra más información sobre las diferentes licencias.',
    A: 'Un',
    lcsw: 'Trabajador Social Clínico con Licencia (LCSW)',
    lcswT:
      'debe tener una maestría o un doctorado de una escuela de posgrado en trabajo social, trabajar como trabajador social durante al menos dos años y aprobar un examen en trabajo social.',
    lmft: 'Terapeuta Matrimonial y Familiar con Licencia (LMFT)',
    lmftT:
      'debe tener una maestría o un doctorado en consejería matrimonial y familiar, trabajar como terapeuta matrimonial y familiar durante al menos 2 años y aprobar un examen de terapia matrimonial y familiar.',
    lpc: 'Consejero Profesional Licenciado (LPC)',
    lpcT:
      'debe tener una maestría o un doctorado en consejería profesional, trabajar como consejero profesional durante al menos 2 años y aprobar un examen de consejería profesional.',
    lp: 'Psicólogo Licenciado (LP)',
    lpT:
      'debe tener un doctorado en psicología, trabajar como psicólogo durante un año y aprobar un examen de psicología.',
    lsw: 'Trabajador Social con Licencia (LSW)',
    lswT:
      ' debe tener una maestría de una escuela de trabajo social y aprobar un examen en trabajo social',
    psycologist:
      'Candidato a Psicólogo, un Candidato a Terapeuta Matrimonial y Familiar o un Candidato a Consejero Profesional con Licencia',
    psycologistT:
      'deben tener un título de posgrado en su área y estar en el proceso de terminar la supervisión para obtener la licencia.',
    cat: 'Técnico Certificado en Adicciones (CAT) ',
    catT:
      'debe tener un diploma de escuela secundaria o un equivalente, completar las clases de capacitación requeridas y obtener 1,000 horas de experiencia laboral supervisada y debe aprobar el examen NAADAC en el nivel NCAC I.',
    cas: ' Especialista Certificado en Adicciones (CAS)',
    casT:
      ' debe tener una licenciatura en una clínica de salud conductual, obtener 2,000 horas de trabajo supervisado y aprobar el examen NAADAC al nivel NCAC II.',
    lac: 'Consejero Licenciado en Adicciones (LAC) ',
    lacT:
      'debe tener una maestría en un campo clínico relevante, cumplir con los requisitos de capacitación específicos, obtener 3,000 horas de trabajo supervisado y aprobar el examen NAADAC MAC.',
    unlicensed: 'psicoterapeuta sin licencia',
    unlicensedT:
      'es un psicoterapeuta que figura en la base de datos del estado y está autorizado por ley para practicar psicoterapia en Colorado pero no está autorizado por el estado y no está obligado a cumplir con ningún requisito educativo o de evaluación para obtener un registro del estado.',
    ack: 'Reconocimiento:',
    ackT1:
      'Si usted tiene alguna pregunta o desea información adicional, no dude en hacer preguntas durante la revisión de este documento o en cualquier momento durante el curso de su tratamiento.',
    ackT2:
      'Yo he leído la información anterior y entiendo mis derechos como paciente o como parte responsable del paciente.',
    cname: 'Nombre del Niño/a (en letra de imprenta) ',
    crpsignature: 'Firma del Cliente o Parte Responsable',
    dt: 'Fecha/Hora',
    pcnprinted: 'Nombre del Médico Principal (en letra de imprenta)',
    pcsignature: 'Firma del Médico Principal',
    rtoc: 'Parentesco con el Cliente'
  },
  video: {
    start: 'Comienzo',
    stop: 'Detener',
    record: 'Grabación',
    flip: 'Voltear la Cámara',
    end: 'Desconectar',
    joinmeeting: 'Unirse a la reunión',
    joinSession: 'Unirse a la sesión',
    joinNow: 'Únete ahora',
    mute: 'Mute',
    unmute: 'Desactivar',
    stopvideo: 'Detener el vídeo',
    startvideo: 'Inicio de vídeo',
    more: 'Más',
    settings: 'Ajustes',
    about: 'Acerca de',
    audioVideoset: 'Ajustes de audio y vídeo',
    connectSet: 'Configuración de la conexión',
    vidio: 'Vídeo',
    audio: 'Audio',
    done: 'Hecho',
    videoInp: 'Entrada de vídeo',
    nolocalVid: 'No hay vídeo local',
    nolocalAud: 'No hay audio local',
    nolocAud: '',
    audInp: 'Entrada de audio',
    audOut: 'Salida de audio',
    sysDefAudOut: 'Salida de audio por defecto del sistema',
    auto: 'Auto',
    manual: 'Manual',
    default: 'Por defecto',
    contPrepMode: 'Modo de preferencias de contenido',
    cliTraSwiOff: 'Control de apagado de la pista del cliente',
    serDef: 'Servidor por defecto',
    present: 'Presentación',
    collabaration: 'Colaboración',
    grid: 'Rejilla',
    mode: 'Modo',
    disabled: 'Discapacitados',
    detected: 'Detectado',
    predected: 'Predicción',
    trackSwifMode: 'Modo de apagado de la pista',
    high: 'Alto',
    standard: 'Estándar',
    low: 'Bajo',
    dominantSpeaker: 'Prioridad del orador dominante',
    theseSettNot: 'Estos ajustes no se pueden cambiar cuando se conecta a una habitación',
    error: 'ERROR',
    errCode: 'Código de error',
    ok: 'OK'
  },
  clientmessage: {
    subject: 'Tema',
    message: 'Mensaje',
    sendbtn: 'Enviar',
    sendmesg: 'El mensaje ha sido enviado a su proveedor. Si necesita más ayuda, comuníquese con',
    sendmssg: 'support@imattercolorado.org',
    headerTxt:
      'Deje un mensaje para su proveedor a continuación para cambiar o actualizar una cita a continuación. Solo un recordatorio: este método de comunicación no debe usarse para ninguna comunicación de emergencia.'
  },
  contactus: {
    name: 'Nombre y Apellido',
    email: 'Dirección de correo electrónico',
    message: 'Mensaje',
    sendbtn: 'Enviar',
    sendmesg:
      'Gracias por tu mensaje. Un miembro del equipo de I Matter se pondrá en contacto contigo.',
    sendmssg: 'support@imattercolorado.org',
    headerTxt:
      'Complete los campos obligatorios a continuación y un miembro del equipo de I Matter se pondrá en contacto con usted.'
  },
  clientFormDownload: {
    headerTxt:
      'según nuestros registros, ya completó el formulario de Divulgación Obligatoria requerido. ',
    text1: 'Por favor ',
    text2: 'Haga clic aquí  ',
    text3: 'para descargar su copia. Gracias.'
  },
  clientMandateFormMessage: {
    text1:
      'Según nuestros registros, no todas las partes firmaron el Formulario de divulgación obligatoria. Asegúrese de preguntarle a su proveedor sobre esto en su próxima sesión. Una vez que todas las partes hayan firmado, podrá descargar el documento completo. ¡Gracias!'
  },
  clientMandatryRedirection: {
    text1:
      'Gracias por completar el formulario de divulgación obligatoria. Una vez que ambas partes hayan firmado, recibirá una copia para descargar en su bandeja de entrada. Gracias.'
  },
  youthThankYouMessage: {
    text1:
      'Gracias por el tiempo que has tomado en responder a esta encuesta. Tu respuesta ha sido registrada.'
  },
  parentThankYouMessage: {
    text1:
      'Gracias por completar la encuesta.Esperamos recibir comentarios sobre Yo Importo tanto de los padres/tutores como de los jóvenes.Si está dispuesto a que su hijo responda a preguntas similares, pídale que compruebe su correo electrónico para acceder al enlace de su encuesta.'
  },

  counsellorMessage: {
    text1:
      'Gracias por completar la encuesta. Esperamos recibir comentarios sobre Yo Importo tanto de los padres/tutores o consejeros, como de los jóvenes.Si está dispuesto a que su hijo o alumno responda a preguntas similares, pídales que comprueben su correo electrónico para acceder al enlace de su encuesta.'
  }
}
