import React from 'react'
import { Menu as MMenu, MenuProps } from '@material-ui/core'
import useStyles from './Styles'

const Menu = React.forwardRef<HTMLUListElement, MenuProps>((props, ref) => {
  const { paper, list } = useStyles()

  return <MMenu ref={ref} elevation={0} {...props} classes={{ paper, list }} />
})

export default Menu
