import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles(({ custom }: Theme) => ({
  nameLabel: {
    color: custom.palette.blue.main,
    fontSize: '18px',
    marginRight: '16px',
    fontWeight: 600
  },
  menu: {
    backgroundColor: custom.palette.blue.main
  },
  avatar: {
    height: '30px',
    width: '30px'
  }
}))
