import React, { useEffect, useMemo, useRef, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import SignatureCanvas from 'react-signature-canvas'
import { engLoc, spaLoc } from 'config/constants/translations'
import {
  AppBar,
  Box,
  Button,
  Tabs,
  Typography,
  Tab,
  IconButton,
  FormControl,
  MenuItem,
  Select,
  FormControlLabel,
  ThemeProvider,
  Switch,
  FormGroup
} from '@material-ui/core'
import { createMuiTheme } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import CloseIcon from '@material-ui/icons/CancelSharp'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import moment from 'moment'
import {
  SessionGoals,
  useServicesListQuery,
  useOutComeQuery,
  useReSendSurveyEmailMutation,
  useSurveyQuery,
  useCurrentUserQuery,
  useFormTemplateQuery,
  useSendSecondSessionSurveyEmailMutation
} from 'graphql/hooks.generated'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import { useStyles } from '../../../../config/constants/theme/sessions'
import { ReactComponent as Date } from '../../../../assets/icons/date.svg'
import { ReactComponent as Time } from '../../../../assets/icons/time.svg'
import { ReactComponent as Services } from '../../../../assets/icons/services.svg'
import { ReactComponent as Warning } from '../../../../assets/icons/warning.svg'
import { StudentNotes } from './studentNotes'
import VideoSession from './video'
import useVideoContext from './video/hooks/useVideoContext/useVideoContext'
import AppStateProvider, { useAppState } from './video/state'
import { VideoProvider } from './video/components/VideoProvider'
import useConnectionOptions from './video/utils/useConnectionOptions/useConnectionOptions'
import ErrorDialog from './video/components/ErrorDialog/ErrorDialog'
import { DisclosureForm } from '../../disclosureform/disclosureform'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}
enum Steps {
  roomNameStep,
  deviceSelectionStep
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const theme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      switchBase: {
        color: 'white !important'
      },
      colorSecondary: {
        '&$checked': {
          color: 'white !important'
        }
      },
      track: {
        backgroundColor: 'grey',
        '$checked$checked + &': {
          backgroundColor: 'green'
        }
      }
    }
  }
})

export const SessionView = (props: {
  sessionData
  actions
  studentActions
  goalActions
  clicksData
  goalsPercentageData
  formErrors
  handleSignModal
  openSign
  selectErrorTab
  sessionCount
  updateServiceValue
  updateOutcomeValue
  mandateformdata
  setshowMandateForm
  showMandateForm
  handleMandateFormClose
  referralhandleChange
  documentFill
  setDocumentFill
}) => {
  const {
    sessionData,
    actions,
    studentActions,
    goalActions,
    clicksData,
    goalsPercentageData,
    formErrors,
    handleSignModal,
    openSign,
    selectErrorTab,
    sessionCount,
    updateServiceValue,
    updateOutcomeValue,
    mandateformdata,
    setshowMandateForm,
    showMandateForm,
    handleMandateFormClose,
    referralhandleChange,
    documentFill,
    setDocumentFill
  } = props
  const history = useHistory()
  const classes = useStyles()
  const { error, setError } = useAppState()
  const connectionOptions = useConnectionOptions()
  const sessionNum = sessionCount.sessionCount.find(() => true)
  const [selectedStudentId, setSelectedStudent] = React.useState(
    sessionData?.session_students?.find(() => true).student_id?.id
  )
  // const [reSendSurveyyEmail] = useReSendSurveyEmailMutation()
  // const [sendSecondSessionSurveyyEmail] = useSendSecondSessionSurveyEmailMutation()
  const { room } = useVideoContext()
  const [loc, setLoc] = React.useState(
    sessionData?.session_students?.find(() => true).student_id?.primaryLanguage?.id
  )
  const { data: serviceListData, loading: serviceListLoading } = useServicesListQuery({
    fetchPolicy: 'network-only'
  })
  const { data: outcomedata, loading: outcomeLoading } = useOutComeQuery({
    fetchPolicy: 'network-only'
  })
  const handleTabChange = (event, newValue) => {
    setSelectedStudent(newValue)
  }
  const deleteStudent = (studentId) => {
    studentActions.deleteStudent(studentId)

    if (selectedStudentId === studentId && sessionData?.session_students?.length > 1)
      setSelectedStudent(
        sessionData?.session_students?.find((stu) => selectedStudentId !== stu.student_id?.id)
          .student_id?.id
      )
  }
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  // const [openSign, setOpenSign] = React.useState(false)
  const [openUnlock, setOpenUnlock] = React.useState(false)
  const [clicksHistory, getClicksHistory] = React.useState([{ key: String, icon: String }])
  const [trackersCount, setTrackersCount] = React.useState(clicksData)
  const [trackerMetric, setTrackerMetric] = React.useState(goalsPercentageData)
  const [hideNotes, setHideNotes] = useState(false)
  // const [showVideo, setShowVideo] = useState(false)
  const trackClicksHistory = (computedKey, icon) => {
    getClicksHistory([...clicksHistory, { key: computedKey, icon }])
  }
  const trackerCounts = (key, count) => {
    setTrackersCount((map) => new Map(map.set(String(key), count)))
  }
  const goalTrackerMetrics = (key, percentage) => {
    setTrackerMetric((map) => new Map(map.set(key, percentage)))
  }
  const redirectMandateForm = () => {
    // history.push('/disclosureform')
    window.scrollTo(0, 0)
    setshowMandateForm(true)
    setDocumentFill(true)
  }
  const current_date = moment.tz('America/Denver').format('YYYY-MM-DD')
  const april_date = moment.tz('America/Denver').format('2024-03-31')
  const may_date = moment.tz('America/Denver').format('2024-06-01')
  const from_date = moment(current_date)?.isAfter(april_date)
  const end_date = moment(current_date)?.isBefore(may_date)
  const studentdata = sessionData?.session_students?.find(() => true).student_id?.id
  const studentLanguage = sessionData?.session_students?.find(() => true).student_id
    ?.primaryLanguage?.id
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const handleClickOpenSave = () => {
    actions.saveSession(false, undefined, trackersCount, trackerMetric, true, false)
    room?.disconnect()

    // if (sessionData?.session_students?.[0]?.session_no === 2 && from_date && end_date)
    //   sendSecondSessionSurveyyEmail({
    //     variables: {
    //       student_id: studentdata,
    //       language_id: studentLanguage,
    //       user_id: String(currentUserdata?.data?.currentUser?.id)
    //     }
    //   })
    // else if (sessionData?.session_students?.[0]?.session_no === 3 && from_date && end_date)
    //   reSendSurveyyEmail({
    //     variables: {
    //       student_id: studentdata,
    //       language_id: studentLanguage,
    //       user_id: String(currentUserdata?.data?.currentUser?.id)
    //     }
    //   })
  }
  const handleClickOpenSign = () => {
    // handleSignModal(true)
    actions.saveSession(false, '', trackersCount, trackerMetric, false, true)
    room?.disconnect()
  }
  const handleCloseSign = () => {
    handleSignModal(false)
    setIsSubmitted(false)
    updateOutcomeValue(null)
  }
  const handleClickUnlock = () => {
    setOpenUnlock(true)
  }
  const handleCloseUnlock = () => {
    setOpenUnlock(false)
  }
  const [PointerEvent, setPointerEvent] = React.useState(sessionData?.sign)
  const signCanvas = useRef() as React.MutableRefObject<any>
  let signImg = ''
  const clearSign = () => signCanvas.current.clear()
  const saveSign = () => {
    signImg = signCanvas.current.toDataURL('image/png')
    setIsSubmitted(true)

    if (signCanvas.current.isEmpty() || !sessionData?.outcome_id) return

    actions.saveSession(
      !signCanvas.current.isEmpty(),
      signImg,
      trackersCount,
      trackerMetric,
      true,
      true
    )
    handleSignModal(false)
    setIsSubmitted(false)

    // if (sessionData?.session_students?.[0]?.session_no === 2 && from_date && end_date)
    //   sendSecondSessionSurveyyEmail({
    //     variables: {
    //       student_id: studentdata,
    //       language_id: studentLanguage,
    //       user_id: String(currentUserdata?.data?.currentUser?.id)
    //     }
    //   })
    // else if (sessionData?.session_students?.[0]?.session_no === 3 && from_date && end_date)
    //   reSendSurveyyEmail({
    //     variables: {
    //       student_id: studentdata,
    //       language_id: studentLanguage,
    //       user_id: String(currentUserdata?.data?.currentUser?.id)
    //     }
    //   })
  }
  const unlockSign = () => {
    actions.saveSession(false, '', trackersCount, trackerMetric, false, false)
    setOpenUnlock(false)
  }
  useEffect(() => {
    return () => {
      if (room?.state === 'connected') room?.disconnect()
    }
  }, [room])
  const serviceOnchange = (e, serviceObj) => {
    if (serviceObj) updateServiceValue({ ...serviceObj, id: e.target.value })
    else {
      const obj = { id: e.target.value, name: '' }
      updateServiceValue(obj)
    }
  }
  const outcomeOnchange = (e, outcomeObj) => {
    formErrors[0].hasError = ''

    if (outcomeObj) updateOutcomeValue({ ...outcomeObj, id: e.target.value })
    else {
      const obj = { id: e.target.value, name: '' }
      updateOutcomeValue(obj)
    }
  }
  const changeHideNotes = (e) => {
    setHideNotes(e.target.checked)
  }
  // const changeAddVideo = (e) => {
  //   setShowVideo(e.target.checked)
  //   if (!e.target.checked) setHideNotes(false)
  // }
  useEffect(() => {
    if (sessionData) setPointerEvent(sessionData.sign)
    if (selectErrorTab) setSelectedStudent(selectErrorTab)
  }, [sessionData, setPointerEvent, selectErrorTab, setSelectedStudent])

  if (sessionData && !showMandateForm)
    return (
      <div>
        {serviceListLoading && <ProgressIndicator fullHeight />}
        <NavBar title="Sessions" goBackBtn />
        <PageContainer>
          <div>
            <Box
              py={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className={classes.pageDiv}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.gridLabel}>
                  <Paper className={classes.paperTabone}>
                    <Typography className={classes.columnSize}>
                      <Date className={classes.iconStyle} />
                      <b>Date:</b>
                      <span>{moment(sessionData.started_at).format('MM/DD/YYYY')}</span>
                    </Typography>
                    <Typography className={classes.columnSize}>
                      <Time className={classes.iconStyle} />
                      <b>Time:</b>
                      <span>{moment(sessionData.started_at).format('hh:mm A')}</span>
                    </Typography>
                    <Typography className={classes.columnSize}>
                      {/* <Services className={classes.iconStyle} /> */}
                      <b
                        className={`${classes.labelText} ${
                          formErrors[0]?.service ? classes.errorText : ''
                        }`}
                      >
                        Service*:
                      </b>
                      {/* <span>{sessionData.appointment_id?.service_id?.[0]?.name}</span> */}
                      <span>
                        <FormControl className={classes.serviceSel}>
                          {serviceListData && (
                            <Select
                              id="session_rate_id"
                              name="session_rate_id"
                              className={`${classes.selectDropdown} ${
                                PointerEvent ? classes.noPonterEvents : ''
                              }`}
                              variant="filled"
                              value={sessionData?.service_id?.id || -1}
                              onChange={(e) => serviceOnchange(e, sessionData.service_id)}
                            >
                              {serviceListData?.servicesList?.map((sessionlist) => (
                                <MenuItem
                                  id="session_rate_id"
                                  key={sessionlist.id}
                                  value={sessionlist.id}
                                  className={`${classes.optionItem} ${classes.fs15}`}
                                >
                                  <span className={classes.optionItem}>{sessionlist.name}</span>
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </FormControl>
                      </span>
                    </Typography>
                    <Typography className={classes.columnSize}>
                      <span className={`${classes.fs15} ${classes.pl10}`}>
                        <strong>{sessionData?.signal_service_id?.name}</strong>
                      </span>
                    </Typography>
                    {/* {!sessionData.sign && (
                      <Typography className={classes.columnSize}>
                        <span className={classes.hidenotes}>Start Video</span>
                        <span>
                          <ThemeProvider theme={theme}>
                            <FormGroup>
                              <FormControlLabel
                                className={classes.pty}
                                control={
                                  <Switch
                                    checked={showVideo || false}
                                    onChange={(e) => changeAddVideo(e)}
                                    value={showVideo ? 'yes' : 'no'}
                                    name="checkedB"
                                    className={classes.switchChange}
                                  />
                                }
                                label={showVideo || false ? 'Yes' : 'No'}
                              />
                            </FormGroup>
                          </ThemeProvider>
                        </span>
                      </Typography>
                    )} */}
                    {!sessionData.sign && (
                      <Typography className={classes.columnSize}>
                        <span className={classes.hidenotes}>Hide Notes</span>
                        <span>
                          <ThemeProvider theme={theme}>
                            <FormGroup>
                              <FormControlLabel
                                className={classes.pty}
                                control={
                                  <Switch
                                    // disabled={!showVideo}
                                    checked={hideNotes || false}
                                    onChange={(e) => changeHideNotes(e)}
                                    value={hideNotes ? 'yes' : 'no'}
                                    name="checkedB"
                                    className={classes.switchChange}
                                  />
                                }
                                label={hideNotes || false ? 'Yes' : 'No'}
                              />
                            </FormGroup>
                          </ThemeProvider>
                        </span>
                      </Typography>
                    )}
                    {sessionData.sign ? (
                      <Typography className={classes.columnSizeLock}>
                        <span style={{ color: 'green', marginRight: '10px' }}>
                          <LockIcon style={{ fontSize: 19, margin: '-2px' }} />
                          <span style={{ fontSize: 20 }}>Documented & Locked</span>
                        </span>
                        <span style={{ marginRight: '10px' }}>
                          <Button className={classes.buttonStylesLock} onClick={handleClickUnlock}>
                            <LockOpenIcon style={{ fontSize: 19, margin: '-2px' }} />
                            <span style={{ fontSize: 20 }}>Unlock</span>
                          </Button>
                        </span>
                      </Typography>
                    ) : (
                      ''
                    )}
                    <div
                      style={{
                        flex: 'auto'
                      }}
                    >
                      <div className={classes.mandatoryStats}>Mandatory Disclosure Status</div>
                      <div style={{ marginRight: '10px' }}>
                        <span
                          style={{
                            height: '10px',
                            width: '12px',
                            backgroundColor: '#fc0000',
                            borderRadius: '50%',
                            display: 'inline-block',
                            marginRight: '4px'
                          }}
                        />
                        <b>Incomplete</b>
                      </div>
                      <div style={{ marginRight: '10px' }}>
                        <span
                          style={{
                            height: '10px',
                            width: '12px',
                            backgroundColor: '#4CAF50',
                            borderRadius: '50%',
                            display: 'inline-block',
                            marginRight: '4px'
                          }}
                        />
                        <b>Completed</b>
                      </div>
                    </div>
                  </Paper>
                  <Dialog
                    open={openUnlock}
                    maxWidth="lg"
                    onClose={handleCloseUnlock}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      <Warning className={classes.iconWarning} />
                      <div className={classes.addSign}>Are you sure?</div>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText
                        id="alert-dialog-description"
                        className={classes.txtWarning}
                      >
                        You are going to unlock session!
                      </DialogContentText>
                      <Button className={classes.buttonStyles} onClick={unlockSign}>
                        Ok
                      </Button>
                      <Button
                        onClick={handleCloseUnlock}
                        style={{
                          color: 'grey',
                          border: '1px solid grey',
                          fontWeight: 'normal',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                          float: 'right'
                        }}
                      >
                        Cancel
                      </Button>
                    </DialogContent>
                  </Dialog>
                </Grid>
                {/* {showVideo && ( */}
                <Grid item xs={12} sm={hideNotes ? 12 : 5}>
                  <Paper className={classes.paperVideo}>
                    {/* <VideoProvider options={connectionOptions} onError={setError}> */}
                    <ErrorDialog dismissError={() => setError(null)} error={error} />
                    <VideoSession
                      authToken={`${sessionData.appointment_id.id}|${sessionData.appointment_id.start_date}`}
                    />
                    {/* </VideoProvider> */}
                  </Paper>
                </Grid>
                {/* )} */}
                {!hideNotes && (
                  <>
                    <Grid item xs={12} sm={7}>
                      <Paper className={classes.paperTabtwo}>
                        <AppBar position="static" className={classes.appBar}>
                          <Grid
                            container
                            alignItems="center"
                            lg={10}
                            xs={10}
                            className={classes.gridContent}
                          >
                            {/* =============== students tab ======================= */}
                            <Tabs
                              variant="scrollable"
                              scrollButtons="auto"
                              className={classes.tabMenu}
                              classes={{ indicator: classes.indicator }}
                              onChange={handleTabChange}
                              value={selectedStudentId}
                            >
                              {sessionData.session_students?.map((tab, index) => (
                                <Tab
                                  key={tab.student_id?.id}
                                  value={tab.student_id?.id}
                                  label={
                                    <span>
                                      <span
                                        className={
                                          formErrors[index]?.hasError ? `${classes.errorText}` : ''
                                        }
                                      >
                                        {tab.student_id?.name}
                                      </span>
                                      {sessionData.session_students?.length > 1 && (
                                        <IconButton
                                          component="div"
                                          onClick={(event) => {
                                            event.stopPropagation()
                                            deleteStudent(tab.student_id?.id)
                                          }}
                                        >
                                          <CloseIcon
                                            fontSize="small"
                                            className={classes.closeIcon}
                                          />
                                        </IconButton>
                                      )}
                                    </span>
                                  }
                                  style={
                                    selectedStudentId === tab.student_id?.id
                                      ? {
                                          border: '1px solid #ccc',
                                          color: '#000',
                                          fontWeight: 'bold',
                                          borderBottom: '1px solid #fff',
                                          borderRadius: '5px 5px 0 0',
                                          marginLeft: '7px'
                                        }
                                      : {}
                                  }
                                  classes={{
                                    root: classes.tabHovernew,
                                    selected: classes.tabSelected
                                  }}
                                />
                              ))}
                            </Tabs>
                          </Grid>
                          <Grid item lg={7} xs={7}>
                            {mandateformdata && (
                              <Button
                                disabled={
                                  mandateformdata.mandatoryDetailsList[0].status_id.id === 3 ||
                                  mandateformdata.mandatoryDetailsList[0].status_id.id === 2
                                }
                                className={
                                  mandateformdata.mandatoryDetailsList[0].status_id.id === 3
                                    ? classes.mandatoryButtonStylesDis
                                    : classes.mandatoryButtonStyles
                                }
                                onClick={redirectMandateForm}
                              >
                                {`Mandatory Disclosure ${mandateformdata?.mandatoryDetailsList[0]?.status_id.name}`}
                              </Button>
                            )}
                          </Grid>
                        </AppBar>
                        {sessionData.session_students?.map((student, goalIndex) =>
                          selectedStudentId === student?.student_id?.id && student ? (
                            <>
                              <TabPanel
                                value={student?.student_id?.id}
                                index={student?.student_id?.id}
                              >
                                <StudentNotes
                                  key={student.student_id?.id || 0}
                                  session_no={student?.session_no || 0}
                                  present={student.present || false}
                                  notes={student.notes || ''}
                                  assessment={student.assessment || ''}
                                  further_sessions={student.further_sessions}
                                  highLevelCareCheck={student.high_level_care}
                                  highLevelCareText={student.high_level_care_text || ''}
                                  furtherSessionText={student.further_sessions_text || ''}
                                  additional_sessions={student.additional_sessions}
                                  plan={student.plan}
                                  sessionGoals={
                                    student.session_Goals
                                      ? ((student.session_Goals as unknown) as SessionGoals[])
                                      : undefined
                                  }
                                  id={student.student_id?.id || 0}
                                  name={student.student_id?.name || ''}
                                  updateStudent={studentActions.updateStudent}
                                  updateGoals={goalActions.updateGoal}
                                  deleteGoal={goalActions.deleteGoal}
                                  copyGoalActivity={goalActions.copyGoalActivity}
                                  // updateTracker={goalActions.updateTracker}
                                  trackClicksHistory={trackClicksHistory}
                                  clicksHistory={clicksHistory}
                                  trackerCounts={trackerCounts}
                                  trackersCount={trackersCount}
                                  goalTrackerMetrics={goalTrackerMetrics}
                                  trackerMetric={trackerMetric}
                                  formErrors={formErrors[goalIndex]}
                                  PointerEvent={PointerEvent}
                                  referraldata={sessionData.requestReferral}
                                  referralhandleChange={referralhandleChange}
                                />
                              </TabPanel>
                            </>
                          ) : (
                            <></>
                          )
                        )}
                      </Paper>
                    </Grid>
                  </>
                )}
                <Grid
                  item
                  xs={12}
                  className={PointerEvent ? classes.buttonInfoNew : classes.buttonInfo}
                  style={{ textAlign: 'right' }}
                >
                  {formErrors.find((error) => error.hasError) && (
                    <span className={`${classes.errorText} ${classes.pr10}`}>
                      Please fill the mandatory (*) fields
                    </span>
                  )}
                  <Button className={classes.buttonStyles} onClick={handleClickOpenSave}>
                    Save
                  </Button>
                  <Button className={classes.buttonStyles} onClick={handleClickOpenSign}>
                    Save and Document
                  </Button>
                  {/* {mandateformdata && (
                    <Button
                      disabled={mandateformdata.mandatoryDetailsList[0].status_id.id !== 1}
                      className={classes.buttonStyles}
                      onClick={redirectMandateForm}
                    >
                      {`Disclosure Form (${mandateformdata.mandatoryDetailsList[0].status_id.name})`}
                    </Button>
                  )} */}
                  <Dialog
                    open={openSign}
                    maxWidth="md"
                    onClose={handleCloseSign}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick
                  >
                    <DialogTitle id="alert-dialog-title">
                      <div className={classes.addSign}>Sign and Document</div>
                    </DialogTitle>
                    <DialogContent>
                      <Typography className={classes.columnSize}>
                        <b
                          className={`${classes.labelText} ${
                            isSubmitted && !sessionData?.outcome_id ? classes.errorText : ''
                          }`}
                        >
                          Outcome of Session*:
                        </b>
                        &nbsp;
                        <span>
                          <FormControl className={classes.servicesSel}>
                            {outcomedata && (
                              <Select
                                name="outcome_id"
                                id="outcome_id"
                                required
                                className={`${classes.selectDropdown} ${
                                  PointerEvent ? classes.noPonterEvents : ''
                                }`}
                                variant="filled"
                                value={sessionData?.outcome_id?.id || -1}
                                onChange={(e) => outcomeOnchange(e, sessionData.outcome_id)}
                              >
                                {outcomedata?.outCome?.map((catObj) => (
                                  <MenuItem
                                    id="outcome_id"
                                    key={catObj.id}
                                    value={catObj.id}
                                    className={`${classes.optionItem} ${classes.fs15}`}
                                  >
                                    <span className={classes.optionItem}>{catObj.name}</span>
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </FormControl>
                        </span>
                      </Typography>
                      <Button
                        onClick={clearSign}
                        style={{
                          color: 'red',
                          fontWeight: 'normal',
                          float: 'right'
                        }}
                      >
                        Clear
                      </Button>
                      <DialogContentText
                        id="alert-dialog-description"
                        className={classes.canvasDiv}
                      >
                        <SignatureCanvas
                          ref={signCanvas}
                          canvasProps={{ width: 400, height: 130 }}
                        />
                      </DialogContentText>
                      {isSubmitted && signCanvas?.current?.isEmpty() && (
                        <div className={`${classes.errorText} ${classes.pb10}`}>
                          Required Your Sign
                        </div>
                      )}
                      <Button className={classes.buttonStyles} onClick={saveSign}>
                        Document
                      </Button>
                      <Button
                        onClick={handleCloseSign}
                        style={{
                          color: 'grey',
                          border: '1px solid grey',
                          fontWeight: 'normal',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                          float: 'right'
                        }}
                      >
                        Cancel
                      </Button>
                    </DialogContent>
                  </Dialog>
                </Grid>
              </Grid>
            </Box>
          </div>
        </PageContainer>
      </div>
    )
  if (sessionData && showMandateForm)
    return (
      <>
        <Dialog
          open={showMandateForm}
          maxWidth="lg"
          onClose={handleMandateFormClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div className={classes.addSign}>
              {loc === 1 ? engLoc.mandatoryform.mdform : spaLoc.mandatoryform.mdform}
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ color: '#000' }}>
              <DisclosureForm
                mandateformdata={mandateformdata?.mandatoryDetailsList[0]}
                handleMandateFormClose={handleMandateFormClose}
                loc={loc === 1 ? 'en' : 'es'}
                docFill={documentFill}
              />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </>
    )

  return <div>No Sessions Found</div>
}
