import React, { useEffect, useState } from 'react'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Search,
  Inject,
  ToolbarItems,
  Page,
  Sort,
  SortSettingsModel,
  Filter,
  FilterSettingsModel,
  Grid
} from '@syncfusion/ej2-react-grids'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  useTheme,
  // Grid,
  TextField,
  FormLabel,
  Avatar
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { SearchFilterData } from 'views/redux/searchFilterRedux/actions/searchFilterActions'
import Paper from '../../../../components/ui/panel/paper/Paper'
import Button from '../../../../components/ui/panel/button/Button'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { useDistrictsQuery, useDeleteDistrictMutation } from '../../../../graphql/hooks.generated'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'
import { districtLevelOfServiceDescriptions } from '../../../../graphql/enumDescriptions'
import { getTherapyLogUrl } from '../../../../helpers/getTherapyLogUrl'

export const DistrictListView: React.FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()
  const sfStoreState: any = useSelector((state) => state)
  let grid: Grid | null
  const { data, loading, refetch } = useDistrictsQuery({ fetchPolicy: 'network-only' })
  const [deleteDistrict] = useDeleteDistrictMutation()
  const [districtsData, setData] = useState<any>()
  const onDeleteDistrict = async (id) => {
    await deleteDistrict({ variables: { id } })
    refetch()
  }
  const sortSettings: SortSettingsModel = {
    columns: [
      {
        field: sfStoreState?.districtlistq?.columnName
          ? sfStoreState?.districtlistq?.columnName
          : 'name',
        direction: sfStoreState?.districtlistq?.columnDirection
          ? sfStoreState?.districtlistq?.columnDirection
          : 'Ascending'
      }
      // { field: 'street_address', direction: 'Ascending' },
      // { field: 'level_of_service', direction: 'Ascending' }
    ]
  }
  const handleClick = () => {
    history.push('/districts/create')
  }
  const isLoading = () => (loading ? <ProgressIndicator fullHeight /> : '')
  useEffect(() => {
    if (data !== undefined && districtsData === undefined) {
      const active = 'active'

      if (active && active.trim().length > 0) {
        const districtsData = data?.districts?.filter(
          (district) => district.status.toLocaleLowerCase() === active
        )
        setData(districtsData)
      }
    }
  }, [data, districtsData])
  const handleChange = (e) => {
    const keyWord = e.target.value

    if (keyWord && keyWord.trim().length > 0) {
      const districtsData = data?.districts?.filter(
        (obj) => obj.status.toLocaleLowerCase() === keyWord.toLocaleLowerCase()
      )
      setData(districtsData)
    }
  }
  const handleSearch = (e) => {
    const keyWord = e.target.value

    if (keyWord && keyWord.trim().length > 0) {
      const districtsData = data?.districts?.filter(
        (obj) => obj.name.toLowerCase().search(keyWord.toLowerCase()) !== -1
      )
      setData(districtsData)
    } else setData(data?.districts)
  }
  const searchOptions = {
    fields: ['name', 'street_address', 'status', 'level_of_service'],
    key: sfStoreState?.districtlistq?.searchText,
    ignoreCase: true
  }
  const toolbarOptions: ToolbarItems[] = ['Search']
  const filterOption: FilterSettingsModel = {
    ignoreAccent: true,
    type: 'Excel'
  }
  const pageOptions = {
    pageSizes: [15, 25, 50, 100],
    pageSize: sfStoreState?.districtlistq?.pageSize ? sfStoreState?.districtlistq?.pageSize : 15,
    pageCount: 5,
    currentPage: sfStoreState?.districtlistq?.currentPage
      ? sfStoreState?.districtlistq?.currentPage
      : 1
  }
  const createdevent = () => {
    document
      .getElementById(`${grid?.element?.id}_searchbutton`)
      ?.addEventListener('click', (event) => {
        const val = (document.getElementById(`${grid?.element?.id}_searchbar`) as HTMLInputElement)
          ?.value
        dispatch(
          SearchFilterData({
            ...sfStoreState,
            districtlistq: { ...sfStoreState.districtlistq, searchText: val }
          })
        )
      })
    document
      .getElementById(`${grid?.element?.id}_searchbar`)
      ?.addEventListener('keyup', (event) => {
        if (event?.key === 'Enter') {
          const val = (document.getElementById(
            `${grid?.element?.id}_searchbar`
          ) as HTMLInputElement)?.value
          dispatch(
            SearchFilterData({
              ...sfStoreState,
              districtlistq: { ...sfStoreState.districtlistq, searchText: val }
            })
          )
        }
      })
  }
  const gridref = (e) => {
    grid = e
  }
  const handleActionBegin = (args) => {
    if (args?.requestType === 'sorting')
      dispatch(
        SearchFilterData({
          ...sfStoreState,
          districtlistq: {
            ...sfStoreState.districtlistq,
            columnName: args?.columnName,
            columnDirection: args?.direction
          }
        })
      )
    if (args && args?.requestType === 'paging')
      dispatch(
        SearchFilterData({
          ...sfStoreState,
          districtlistq: {
            ...sfStoreState.districtlistq,
            currentPage: grid?.pageSettings?.currentPage,
            pageSize: grid?.pageSettings?.pageSize
          }
        })
      )
  }

  return (
    <>
      <NavBar title="Districts" goBackBtn />
      <PageContainer>
        <div className={classes.plr10}>
          <Box py={3} display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Typography variant="h3" style={{ color: theme.palette.info.main }}>
                Districts
              </Typography>
            </Box>
          </Box>
          <div className="control-pane">
            <div className="control-section">
              {data && (
                <GridComponent
                  key={Math.floor(Math.random() * 100)}
                  allowTextWrap
                  dataSource={data?.districts || []}
                  width="auto"
                  toolbar={toolbarOptions}
                  allowPaging
                  pageSettings={pageOptions}
                  allowSorting
                  // filterSettings={filterOption}
                  // allowFiltering
                  searchSettings={searchOptions}
                  sortSettings={sortSettings}
                  className={classes.headerCls}
                  ref={(g) => gridref(g)}
                  created={createdevent}
                  actionComplete={(value) => handleActionBegin(value)}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="name"
                      template={(disname) => (
                        <span className={classes.nameCls}>{disname.name}</span>
                      )}
                      headerText="NAME"
                      width="130"
                      filter={{ type: 'CheckBox' }}
                    />
                    <ColumnDirective
                      field="street_address"
                      template={(locations) => (
                        <span style={{ color: 'black' }}>
                          {`${locations.street_address} ${locations.city} ${locations.zip}`}
                        </span>
                      )}
                      headerText="LOCATION"
                      width="180"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      field="level_of_service"
                      template={(service) => (
                        <span style={{ color: 'black' }}>
                          {districtLevelOfServiceDescriptions[service.level_of_service]}
                        </span>
                      )}
                      headerText="LEVEL OF SERVICE"
                      width="120"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      field="status"
                      headerText="STATUS"
                      width="90"
                      filter={{ type: 'CheckBox' }}
                    />
                    <ColumnDirective
                      template={(district) => (
                        <Link
                          onClick={() => history.push(`/districts/${district.id}/edit`)}
                          style={{ color: 'black', cursor: 'pointer' }}
                        >
                          {' '}
                          Edit{' '}
                        </Link>
                      )}
                      headerText="ACTIONS"
                      width="65"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      template={(district) => (
                        <Link
                          style={{ color: 'black', cursor: 'pointer' }}
                          onClick={() => history.push(`/districts/${district.id}/schools`)}
                        >
                          {' '}
                          Schools{' '}
                        </Link>
                      )}
                      width="68"
                    />
                    <ColumnDirective
                      template={(district) => (
                        <Link
                          style={{ color: 'black', cursor: 'pointer' }}
                          onClick={() => history.push(`/districts/${district.id}/students`)}
                        >
                          {' '}
                          Clients{' '}
                        </Link>
                      )}
                      width="68"
                    />
                    <ColumnDirective
                      template={(district) => (
                        <Link
                          style={{ color: 'black', cursor: 'pointer' }}
                          onClick={() => history.push(`/districts/${district.id}/therapists`)}
                        >
                          {' '}
                          Users{' '}
                        </Link>
                      )}
                      width="68"
                    />
                  </ColumnsDirective>
                  <Inject services={[Search, Toolbar, Sort, Page]} />
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    className="mar-rt-15 custom-btn addEvent"
                    onClick={handleClick}
                  >
                    Add district
                  </Button>
                </GridComponent>
              )}
            </div>
          </div>
        </div>
        {isLoading()}
      </PageContainer>
    </>
  )
}
