import { Form, FormRenderProps, Field } from 'react-final-form'
import { useHistory, useRouteMatch, Redirect } from 'react-router-dom'
import { Checkboxes, DatePicker, Select, TextField } from 'mui-rff'
import React, { useMemo, useEffect, useRef, useState, useCallback } from 'react'
import { Button, Grid, Link, MenuItem, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import SignatureCanvas from 'react-signature-canvas'
import CreateIcon from '@material-ui/icons/Create'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {
  useUpdateMandatoryFormMutation,
  useRelationshipToClientQuery,
  useUpdateMandatoryDataMutation,
  useAddMandatoryDetailsMutation
} from 'graphql/hooks.generated'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { engLoc, spaLoc } from 'config/constants/translations'
import { DiclosureFormModel } from './disclosureFormModel'
import { useStyles } from './disclosureformstyle'

interface FormProps {
  mandateformdata: any
  handleMandateFormClose(flag?): void
  mandatoryInvite?: any
  loc?: string
  appointmentClient?: any
  docFill?: any
}

export const DisclosureForm = (props: FormProps) => {
  const { t, i18n } = useTranslation()
  const locallang = i18n?.language || 'en'
  const classes = useStyles()
  const history = useHistory()
  const { url } = useRouteMatch()
  const {
    mandateformdata,
    handleMandateFormClose,
    mandatoryInvite,
    loc,
    appointmentClient,
    docFill
  } = props
  const initialData: any = useMemo(() => {
    const init = {
      primaryClinicianName: '',
      licenseCert: '',
      digreeSchoolAttend: '',
      agencyname: '',
      agencyaddr: '',
      clinicianPhone: '',
      specializedTraining: '',
      supervisorname: '',
      clientname: '',
      clientsign: '',
      relToClient: '',
      clientdatetime: '',
      primaryCliName: '',
      primaryCliSign: '',
      primaryDatetime: '',
      school_attended: ''
    }

    return init
  }, [])
  const { data, loading, error } = useRelationshipToClientQuery()
  // console.log(data)
  // const [initialValues, setInitialValues] = useState<any>()
  const [clientsignimg, setclientsignimg] = useState('')
  const [primaryclisign, setprimaryclisign] = useState('')
  const [isclientsign, setisclientsign] = useState(false)
  const [openSign, setopenSign] = useState(false)
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false)
  const [UpdateMandatoryFormMutation, { loading: saveloading }] = useUpdateMandatoryFormMutation()
  const [AddMandatoryFormMutation, { loading: resendLoading }] = useAddMandatoryDetailsMutation()
  const [
    UpdateMandatoryInviteMutation,
    { loading: mandatoryInviteLoading }
  ] = useUpdateMandatoryDataMutation()
  const onSubmit = async (values: DiclosureFormModel) => {
    // console.log('Values', values)
    setIsFormSubmitted(true)
    let signValue

    if (!appointmentClient && !primaryclisign && docFill !== true) signValue = null
    else signValue = primaryclisign
    if (!primaryclisign && !appointmentClient && docFill === true) return
    if (mandatoryInvite && !clientsignimg) return

    const obj = mandateformdata
    const inputdata = {
      user_id: obj?.user_id?.id,
      student_id: obj?.student_id?.id,
      status_id: obj?.stutus_id?.id,
      comment: obj?.comment,
      // session_id: '',
      licenses_and_certifications: obj?.licenses_and_certifications,
      degreesheld_and_schoolattended: values?.digreeSchoolAttend,
      school_attended: values?.school_attended,
      clinician_phone: values?.clinicianPhone,
      relationship_to_client_id: Number(values?.relToClient),
      specializedtraining_and_areasofclinicalcompetence: values?.specializedTraining,
      student_date: values?.clientdatetime ? new Date(values?.clientdatetime).toISOString() : null,
      student_sign: clientsignimg,
      supervisorname_and_credentials: values?.supervisorname,
      user_date: values?.primaryDatetime ? new Date(values?.primaryDatetime).toISOString() : null,
      user_sign: signValue,
      updated_at: new Date().toISOString()
    }
    try {
      if (!mandatoryInvite && mandateformdata.resend !== true)
        await UpdateMandatoryFormMutation({
          variables: {
            id: obj?.id,
            input: { ...inputdata }
          }
        })
      if (mandatoryInvite)
        await UpdateMandatoryInviteMutation({
          variables: {
            id: obj?.id,
            input: { ...inputdata }
          }
        }).then(() =>
          history.push(
            `/public/ClientDisclosureFormRedirectMessage/${mandateformdata?.student_id?.primaryLanguage?.id}`
          )
        )
      if (!mandatoryInvite && mandateformdata.resend === true)
        await AddMandatoryFormMutation({
          variables: {
            input: { ...inputdata, status_id: 2 },
            resend: true
          }
        })

      handleMandateFormClose(true)
    } catch (e) {
      console.log(e)
    }
  }
  useMemo(() => {
    if (mandateformdata) {
      const obj = mandateformdata
      initialData.agencyaddr = obj?.user_id?.street_address
      initialData.agencyname = obj?.user_id?.signalAgencies[0]?.name
      initialData.clientname = obj?.student_id?.name
      initialData.primaryCliName = obj?.user_id?.name
      initialData.primaryClinicianName = obj?.user_id?.name
      initialData.licenseCert = obj?.user_id?.license_number
      initialData.digreeSchoolAttend = obj?.degreesheld_and_schoolattended
      initialData.school_attended = obj?.school_attended
      initialData.supervisorname = obj?.supervisorname_and_credentials
      initialData.clinicianPhone = obj?.clinician_phone
      initialData.specializedTraining = obj?.specializedtraining_and_areasofclinicalcompetence
      initialData.primaryDatetime = obj?.user_date
        ? moment(obj?.user_date).format('MM/D/YYYY')
        : moment().format('MM/D/YYYY')
      initialData.clientdatetime = obj?.student_date
        ? moment(obj?.student_date).format('MM/D/YYYY')
        : mandatoryInvite
        ? moment().format('MM/D/YYYY')
        : null
      initialData.primaryCliSign = obj?.user_sign ? obj?.user_sign : ''
      initialData.clientsign = obj?.student_sign ? obj?.student_sign : ''
      initialData.relToClient = obj?.relationship_to_client_id?.id
        ? obj?.relationship_to_client_id?.id
        : ''
      // setInitialValues(initialData)
      setprimaryclisign(obj?.user_sign ? obj?.user_sign : '')
      setclientsignimg(obj?.student_sign ? obj?.student_sign : '')
    }
  }, [mandateformdata, initialData, mandatoryInvite])
  const phonepattern = /^[0-9]{10,14}$/
  const required = (value) => (value ? undefined : 'Required')
  const validatephone = (value) => (phonepattern.test(value) ? undefined : 'Required')
  const composeValidators = (...validators) => (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined)
  const signCanvas = useRef() as React.MutableRefObject<any>
  const clearSign = () => signCanvas.current.clear()
  const handleCloseSign = () => {
    setopenSign(false)
    setIsSubmitted(false)
  }
  const openSignModal = (e, flag) => {
    setopenSign(true)
    setisclientsign(flag)
  }
  const [scrollError, setScrollError] = useState(true)
  const getclientSign = () => {
    setIsSubmitted(true)
    setIsFormSubmitted(false)

    if (signCanvas.current.isEmpty()) return
    if (isclientsign) setclientsignimg(signCanvas.current.toDataURL('image/png'))
    else setprimaryclisign(signCanvas.current.toDataURL('image/png'))

    handleCloseSign()
    setIsSubmitted(false)
  }
  const scrollToTop = () => {
    const element = document.getElementById('scrollTop')

    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
  }
  const renderForm = (renderProps: FormRenderProps<DiclosureFormModel>) => {
    const { handleSubmit, values, submitting, hasValidationErrors } = renderProps
    // console.log('data', values)

    if (mandatoryInvite !== undefined) setScrollError(false)
    else if (hasValidationErrors && mandatoryInvite === undefined) setScrollError(true)
    if (scrollError)
      setTimeout(() => {
        scrollToTop()
      }, 0)

    return (
      <>
        <div
          id="scrollTop"
          className={`${classes.root} ${
            mandateformdata && mandateformdata?.status_id?.id === 1 ? classes.widthFull : ''
          }`}
        >
          <form>
            {/* add goal section */}
            <Grid container className={classes.p50}>
              <Grid item xs={12} sm={12} md={12}>
                {mandateformdata && mandateformdata?.status_id?.id === 2 && (
                  <div className={`${classes.pb50} ${classes.fbold}`}>
                    {loc === 'en' ? engLoc.mandatoryform.mdform : spaLoc.mandatoryform.mdform}
                  </div>
                )}
                <table className={`${classes.widthFull} ${classes.f13}`}>
                  <tbody>
                    <tr>
                      <Field name="primaryClinicianName">
                        {({ input, meta }) => (
                          <>
                            <td className={`${classes.tabletd} ${classes.plr10} ${classes.fbold}`}>
                              <div className={meta.error && meta.touched ? classes.texterror : ''}>
                                {loc === 'en'
                                  ? engLoc.mandatoryform.pcname
                                  : spaLoc.mandatoryform.pcname}
                              </div>
                            </td>
                            <td>
                              <div>
                                <input
                                  disabled
                                  className={`${classes.inputf} ${classes.widthFull}`}
                                  {...input}
                                  type="text"
                                />
                              </div>
                            </td>
                          </>
                        )}
                      </Field>
                    </tr>
                    <tr>
                      <Field name="licenseCert">
                        {({ input, meta }) => (
                          <>
                            <td className={`${classes.plr10} ${classes.fbold}`}>
                              <div className={meta.error && meta.touched ? classes.texterror : ''}>
                                {loc === 'en'
                                  ? engLoc.mandatoryform.lctext
                                  : spaLoc.mandatoryform.lctext}{' '}
                                <span className={classes.f12}>
                                  {loc === 'en'
                                    ? engLoc.mandatoryform.lcnumbers
                                    : spaLoc.mandatoryform.lcnumbers}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div>
                                <input
                                  disabled
                                  className={`${classes.inputf} ${classes.twoLineH} ${classes.widthFull}`}
                                  {...input}
                                  type="text"
                                />
                              </div>
                            </td>
                          </>
                        )}
                      </Field>
                    </tr>
                    <tr>
                      <Field name="digreeSchoolAttend" validate={required}>
                        {({ input, meta }) => (
                          <>
                            <td className={`${classes.plr10} ${classes.fbold}`}>
                              <div className={meta.error && meta.touched ? classes.texterror : ''}>
                                {loc === 'en'
                                  ? engLoc.mandatoryform.school
                                  : spaLoc.mandatoryform.school}
                              </div>
                            </td>
                            <td>
                              <input
                                disabled={
                                  (mandateformdata && mandateformdata?.status_id?.id === 2) ||
                                  (mandateformdata?.status_id?.id === 1 && appointmentClient) ||
                                  (mandateformdata?.status_id?.id === 4 && appointmentClient) ||
                                  (!docFill && mandateformdata?.status_id?.id === 4)
                                }
                                className={`${classes.inputf} ${classes.widthFull}`}
                                {...input}
                                type="text"
                              />
                            </td>
                          </>
                        )}
                      </Field>
                    </tr>
                    <tr>
                      <Field
                        name="school_attended"
                        validate={
                          mandateformdata && mandateformdata?.status_id?.id === 1
                            ? required
                            : undefined
                        }
                      >
                        {({ input, meta }) => (
                          <>
                            <td className={`${classes.plr10} ${classes.fbold}`}>
                              <div className={meta.error && meta.touched ? classes.texterror : ''}>
                                {' '}
                                <b>
                                  {loc === 'en'
                                    ? engLoc.mandatoryform.degreeSchool
                                    : spaLoc.mandatoryform.degreeSchool}
                                </b>
                              </div>
                            </td>
                            <td>
                              {mandateformdata && mandateformdata?.status_id?.id === 1 ? (
                                <input
                                  disabled={
                                    (mandateformdata && mandateformdata?.status_id?.id === 2) ||
                                    (mandateformdata?.status_id?.id === 1 && appointmentClient) ||
                                    (mandateformdata?.status_id?.id === 4 && appointmentClient) ||
                                    (!docFill && mandateformdata?.status_id?.id === 4)
                                  }
                                  className={`${classes.inputf} ${classes.widthFull}`}
                                  {...input}
                                  type="text"
                                />
                              ) : (
                                <input
                                  disabled={mandateformdata && mandateformdata?.status_id?.id === 2}
                                  className={`${classes.inputf} ${classes.widthFull}`}
                                  {...input}
                                  type="text"
                                />
                              )}
                            </td>
                          </>
                        )}
                      </Field>
                    </tr>
                    <tr>
                      <td className={`${classes.plr10} ${classes.fbold}`}>
                        {loc === 'en' ? engLoc.mandatoryform.aname : spaLoc.mandatoryform.aname}
                      </td>
                      <td>
                        <div>
                          <Field
                            disabled
                            className={`${classes.inputf} ${classes.widthFull}`}
                            name="agencyname"
                            component="input"
                            type="text"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className={`${classes.plr10} ${classes.fbold}`}>
                        {loc === 'en' ? engLoc.mandatoryform.agency : spaLoc.mandatoryform.agency}
                      </td>
                      <td>
                        <div>
                          <Field
                            disabled
                            className={`${classes.inputf} ${classes.widthFull}`}
                            name="agencyaddr"
                            component="input"
                            type="text"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <Field
                        name="clinicianPhone"
                        validate={composeValidators(required, validatephone)}
                      >
                        {({ input, meta }) => (
                          <>
                            <td className={`${classes.plr10} ${classes.fbold}`}>
                              <div className={meta.error && meta.touched ? classes.texterror : ''}>
                                {loc === 'en'
                                  ? engLoc.mandatoryform.cphone
                                  : spaLoc.mandatoryform.cphone}
                              </div>
                            </td>
                            <td>
                              <div>
                                <input
                                  disabled={
                                    (mandateformdata && mandateformdata?.status_id?.id === 2) ||
                                    (mandateformdata?.status_id?.id === 1 && appointmentClient) ||
                                    (mandateformdata?.status_id?.id === 4 && appointmentClient) ||
                                    (!docFill && mandateformdata?.status_id?.id === 4)
                                  }
                                  className={`${classes.inputf} ${classes.widthFull}`}
                                  {...input}
                                  type="text"
                                />
                              </div>
                            </td>
                          </>
                        )}
                      </Field>
                    </tr>
                    <tr>
                      <Field name="specializedTraining" validate={required}>
                        {({ input, meta }) => (
                          <>
                            <td className={`${classes.plr10} ${classes.fbold}`}>
                              <div className={meta.error && meta.touched ? classes.texterror : ''}>
                                {loc === 'en'
                                  ? engLoc.mandatoryform.straining
                                  : spaLoc.mandatoryform.straining}{' '}
                                <span className={classes.f12}>
                                  {loc === 'en'
                                    ? engLoc.mandatoryform.straining1
                                    : spaLoc.mandatoryform.straining1}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div>
                                <input
                                  disabled={
                                    (mandateformdata && mandateformdata?.status_id?.id === 2) ||
                                    (mandateformdata?.status_id?.id === 1 && appointmentClient) ||
                                    (mandateformdata?.status_id?.id === 4 && appointmentClient) ||
                                    (!docFill && mandateformdata?.status_id?.id === 4)
                                  }
                                  className={`${classes.inputf} ${classes.threeLineH} ${classes.widthFull}`}
                                  {...input}
                                  type="text"
                                />
                              </div>
                            </td>
                          </>
                        )}
                      </Field>
                    </tr>
                    <tr>
                      <Field
                        name="supervisorname"
                        validate={!appointmentClient && docFill ? required : undefined}
                      >
                        {({ input, meta }) => (
                          <>
                            <td className={`${classes.plr10} ${classes.fbold}`}>
                              <div className={meta.error && meta.touched ? classes.texterror : ''}>
                                {loc === 'en'
                                  ? engLoc.mandatoryform.supervisor
                                  : spaLoc.mandatoryform.supervisor}{' '}
                                <span className={classes.f12}>
                                  {loc === 'en'
                                    ? engLoc.mandatoryform.supervisor1
                                    : spaLoc.mandatoryform.supervisor1}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div>
                                <input
                                  disabled={
                                    (mandateformdata && mandateformdata?.status_id?.id === 2) ||
                                    (mandateformdata?.status_id?.id === 1 && appointmentClient) ||
                                    (mandateformdata?.status_id?.id === 4 && appointmentClient) ||
                                    (!docFill && mandateformdata?.status_id?.id === 4)
                                  }
                                  className={`${classes.inputf} ${classes.threeLineH} ${classes.widthFull}`}
                                  {...input}
                                  type="text"
                                />
                              </div>
                            </td>
                          </>
                        )}
                      </Field>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <div className={classes.textheader}>
                    {loc === 'en' ? engLoc.mandatoryform.about : spaLoc.mandatoryform.about}
                  </div>
                  <div>
                    <ul className={`${classes.pl15} ${classes.mtb0}`}>
                      <li>
                        {loc === 'en' ? engLoc.mandatoryform.aboutT1 : spaLoc.mandatoryform.aboutT1}
                      </li>
                      <li>
                        {' '}
                        {loc === 'en' ? engLoc.mandatoryform.aboutT2 : spaLoc.mandatoryform.aboutT2}
                      </li>
                      <li>
                        {loc === 'en' ? engLoc.mandatoryform.aboutT3 : spaLoc.mandatoryform.aboutT3}
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div className={classes.textheader}>
                    {loc === 'en'
                      ? engLoc.mandatoryform.confidentiality
                      : spaLoc.mandatoryform.confidentiality}
                  </div>
                  <div>
                    <ul className={`${classes.pl15} ${classes.mtb0}`}>
                      <li>
                        {loc === 'en'
                          ? engLoc.mandatoryform.confidentialityT1
                          : spaLoc.mandatoryform.confidentialityT1}
                      </li>
                      <li>
                        {loc === 'en'
                          ? engLoc.mandatoryform.confidentialityT2
                          : spaLoc.mandatoryform.confidentialityT2}
                        <ul className={classes.circle}>
                          <li>
                            {loc === 'en' ? engLoc.mandatoryform.cbp1 : spaLoc.mandatoryform.cbp1}
                          </li>
                          <li>
                            {loc === 'en' ? engLoc.mandatoryform.cbp2 : spaLoc.mandatoryform.cbp2}
                          </li>
                          <li>
                            {loc === 'en' ? engLoc.mandatoryform.cbp3 : spaLoc.mandatoryform.cbp3}
                          </li>
                          <li>
                            {loc === 'en' ? engLoc.mandatoryform.cbp4 : spaLoc.mandatoryform.cbp4}
                          </li>
                          <li>
                            {loc === 'en' ? engLoc.mandatoryform.cbp5 : spaLoc.mandatoryform.cbp5}
                          </li>
                          <li>
                            {loc === 'en' ? engLoc.mandatoryform.cbp6 : spaLoc.mandatoryform.cbp6}
                          </li>
                          <li>
                            {loc === 'en' ? engLoc.mandatoryform.cbp7 : spaLoc.mandatoryform.cbp7}
                          </li>
                        </ul>
                      </li>
                      <li>
                        {loc === 'en'
                          ? engLoc.mandatoryform.confidentialityT3
                          : spaLoc.mandatoryform.confidentialityT3}
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div className={classes.textheader}>
                    {loc === 'en' ? engLoc.mandatoryform.qc : spaLoc.mandatoryform.qc}
                  </div>
                  <div className={classes.pt10}>
                    {loc === 'en' ? engLoc.mandatoryform.qrc1 : spaLoc.mandatoryform.qrc1}{' '}
                    <a href="mailto: support@imattercolorado.org.">support@imattercolorado.org.</a>{' '}
                    {loc === 'en' ? engLoc.mandatoryform.qrc2 : spaLoc.mandatoryform.qrc2}
                  </div>
                </div>
                <div>
                  <div className={classes.textheader}>
                    {loc === 'en'
                      ? engLoc.mandatoryform.regulatory
                      : spaLoc.mandatoryform.regulatory}
                  </div>
                  <div className={classes.pt10}>
                    {loc === 'en' ? engLoc.mandatoryform.regtext : spaLoc.mandatoryform.regtext}
                  </div>
                </div>
                <Grid container className={`${classes.p50} ${classes.f12}`}>
                  <Grid item md={6}>
                    <div>
                      {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}{' '}
                      <strong>
                        {loc === 'en' ? engLoc.mandatoryform.lcsw : spaLoc.mandatoryform.lcsw}
                      </strong>{' '}
                      {loc === 'en' ? engLoc.mandatoryform.lcswT : spaLoc.mandatoryform.lcswT}
                    </div>
                    <div>
                      {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}
                      <strong>
                        {loc === 'en' ? engLoc.mandatoryform.lmft : spaLoc.mandatoryform.lmft}
                      </strong>{' '}
                      {loc === 'en' ? engLoc.mandatoryform.lmftT : spaLoc.mandatoryform.lmftT}
                    </div>
                    <div>
                      {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}
                      <strong>
                        {loc === 'en' ? engLoc.mandatoryform.lpc : spaLoc.mandatoryform.lpc}
                      </strong>{' '}
                      {loc === 'en' ? engLoc.mandatoryform.lpcT : spaLoc.mandatoryform.lpcT}
                    </div>
                    <div>
                      {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}
                      <strong>
                        {loc === 'en' ? engLoc.mandatoryform.lp : spaLoc.mandatoryform.lp}
                      </strong>
                      {loc === 'en' ? engLoc.mandatoryform.lpT : spaLoc.mandatoryform.lpT}
                    </div>
                    <div>
                      {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}
                      <strong>
                        {loc === 'en' ? engLoc.mandatoryform.lsw : spaLoc.mandatoryform.lsw}
                      </strong>{' '}
                      {loc === 'en' ? engLoc.mandatoryform.lswT : spaLoc.mandatoryform.lswT}
                    </div>
                    <div>
                      {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}
                      <strong>
                        {loc === 'en'
                          ? engLoc.mandatoryform.psycologist
                          : spaLoc.mandatoryform.psycologist}
                      </strong>{' '}
                      {loc === 'en'
                        ? engLoc.mandatoryform.psycologistT
                        : spaLoc.mandatoryform.psycologistT}{' '}
                    </div>
                  </Grid>
                  <Grid item md={6} className={classes.pl15}>
                    <div>
                      {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}
                      <strong>
                        {loc === 'en' ? engLoc.mandatoryform.cat : spaLoc.mandatoryform.cat}
                      </strong>{' '}
                      {loc === 'en' ? engLoc.mandatoryform.catT : spaLoc.mandatoryform.catT}
                    </div>
                    <div>
                      {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}
                      <strong>
                        {loc === 'en' ? engLoc.mandatoryform.cas : spaLoc.mandatoryform.cas}
                      </strong>{' '}
                      {loc === 'en' ? engLoc.mandatoryform.casT : spaLoc.mandatoryform.casT}
                    </div>
                    <div>
                      {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}
                      <strong>
                        {loc === 'en' ? engLoc.mandatoryform.lac : spaLoc.mandatoryform.lac}
                      </strong>
                      {loc === 'en' ? engLoc.mandatoryform.lacT : spaLoc.mandatoryform.lacT}
                    </div>
                    <div>
                      {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}
                      <strong>
                        {loc === 'en'
                          ? engLoc.mandatoryform.unlicensed
                          : spaLoc.mandatoryform.unlicensed}
                      </strong>
                      {loc === 'en'
                        ? engLoc.mandatoryform.unlicensedT
                        : spaLoc.mandatoryform.unlicensedT}
                    </div>
                  </Grid>
                </Grid>
                <div>
                  <div className={`${classes.fbold} ${classes.pt10}`}>
                    {loc === 'en' ? engLoc.mandatoryform.ack : spaLoc.mandatoryform.ack}
                  </div>
                  <div>
                    {loc === 'en' ? engLoc.mandatoryform.ackT1 : spaLoc.mandatoryform.ackT1}
                  </div>
                  <div className={`${classes.pt10}`}>
                    {loc === 'en' ? engLoc.mandatoryform.ackT2 : spaLoc.mandatoryform.ackT2}
                  </div>
                </div>
                <Grid container>
                  <Grid item sm={6} md={6} className={classes.pr50}>
                    <div className={`${classes.pb10} ${classes.pt10}`}>
                      <div>
                        <Field
                          disabled
                          className={`${classes.inputf} ${classes.widthFull} ${classes.borderb}`}
                          name="clientname"
                          component="input"
                          type="text"
                        />
                      </div>
                      <div>
                        {loc === 'en' ? engLoc.mandatoryform.cname : spaLoc.mandatoryform.cname}
                      </div>
                    </div>
                    <div className={classes.pb10}>
                      <div className={classes.relative}>
                        {!clientsignimg && (
                          <Field
                            disabled
                            className={`${classes.inputf} ${classes.widthFull} ${classes.borderb}`}
                            name="clientsign"
                            component="input"
                            type="text"
                          />
                        )}
                        {mandateformdata &&
                          (mandateformdata?.status_id?.id === 2 ||
                            (mandateformdata?.status_id?.id === 4 && docFill !== true) ||
                            appointmentClient) && (
                            <CreateIcon
                              className={`${classes.signIcon} ${clientsignimg ? classes.btm5 : ''}`}
                              onClick={(e) => openSignModal(e, true)}
                            />
                          )}
                        {clientsignimg && (
                          <div className={`${classes.widthFull} ${classes.borBot}`}>
                            <img
                              alt="client sign"
                              className={classes.signimg}
                              src={clientsignimg}
                            />
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          mandatoryInvite && !clientsignimg && isFormSubmitted
                            ? classes.texterror
                            : ''
                        }
                      >
                        {loc === 'en'
                          ? engLoc.mandatoryform.crpsignature
                          : spaLoc.mandatoryform.crpsignature}
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    md={6}
                    className={`${classes.pr50} ${classes.widthFull} ${classes.dflex}`}
                  >
                    <div className={`${classes.flexb} ${classes.pb10}`}>
                      <div className={classes.widthFull}>
                        <Field
                          name="relToClient"
                          validate={
                            mandateformdata &&
                            (mandateformdata?.status_id?.id === 2 ||
                              (mandateformdata?.status_id?.id === 4 && docFill !== true) ||
                              appointmentClient)
                              ? required
                              : undefined
                          }
                        >
                          {({ input, meta }) => (
                            <>
                              <select
                                disabled={
                                  mandateformdata &&
                                  (mandateformdata?.status_id?.id === 1 ||
                                    (mandateformdata?.status_id?.id === 4 && docFill === true)) &&
                                  !appointmentClient
                                }
                                className={`${classes.inputf} ${classes.widthFull} ${classes.borderb}`}
                                {...input}
                              >
                                <option aria-label="None" value={undefined} />
                                <>
                                  {data &&
                                    data?.relationshipToClient?.map((dropDown) => (
                                      <option value={Number(dropDown?.id)}>
                                        {loc === 'en' ? dropDown?.name : dropDown?.spanish_name}
                                      </option>
                                    ))}
                                </>
                              </select>
                              <div className={meta.error && meta.touched ? classes.texterror : ''}>
                                {loc === 'en'
                                  ? engLoc.mandatoryform.rtoc
                                  : spaLoc.mandatoryform.rtoc}
                              </div>
                            </>
                          )}
                        </Field>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    sm={6}
                    md={6}
                    xs={12}
                    className={`${classes.widthFull} ${classes.pr50}`}
                  >
                    <div className={classes.pb10}>
                      <div>
                        <Field
                          disabled
                          className={`${classes.inputf} ${classes.widthFull} ${classes.borderb}`}
                          name="clientdatetime"
                          component="input"
                          type="text"
                        />
                      </div>
                      <div>{loc === 'en' ? engLoc.mandatoryform.dt : spaLoc.mandatoryform.dt}</div>
                    </div>
                    <div className={classes.pb10}>
                      <div>
                        <Field
                          disabled
                          className={`${classes.inputf} ${classes.widthFull} ${classes.borderb}`}
                          name="primaryCliName"
                          component="input"
                          type="text"
                        />
                      </div>
                      <div>
                        {loc === 'en'
                          ? engLoc.mandatoryform.pcnprinted
                          : spaLoc.mandatoryform.pcnprinted}
                      </div>
                    </div>
                    <div className={classes.pb10}>
                      <Field name="primaryCliSign">
                        {({ input, meta }) => (
                          <>
                            <div className={classes.relative}>
                              {clientsignimg && docFill === true ? (
                                <div style={{ float: 'right' }}>
                                  {mandateformdata &&
                                    (mandateformdata?.status_id?.id === 1 ||
                                      (mandateformdata?.status_id?.id === 4 && docFill !== true)) &&
                                    !appointmentClient && (
                                      <>
                                        <CreateIcon
                                          className={`${classes.signIcon}${
                                            clientsignimg ? classes.btm5 : ''
                                          }`}
                                          onClick={(e) => openSignModal(e, false)}
                                        />
                                      </>
                                    )}
                                </div>
                              ) : (
                                <div>
                                  {mandateformdata &&
                                    (mandateformdata?.status_id?.id === 1 ||
                                      (mandateformdata?.status_id?.id === 4 && docFill === true)) &&
                                    !appointmentClient && (
                                      <>
                                        <CreateIcon
                                          className={`${classes.signIcon}${
                                            clientsignimg ? classes.btm5 : ''
                                          }`}
                                          onClick={(e) => openSignModal(e, false)}
                                        />
                                      </>
                                    )}
                                </div>
                              )}

                              {!primaryclisign && (
                                <div>
                                  <input
                                    disabled
                                    className={`${classes.inputf} ${classes.widthFull} ${classes.borderb}`}
                                    {...input}
                                    type="text"
                                  />
                                </div>
                              )}
                              {primaryclisign && (
                                <div className={`${classes.widthFull} ${classes.borBot}`}>
                                  <img
                                    alt="primary client sign"
                                    className={classes.signimg}
                                    src={primaryclisign}
                                  />
                                </div>
                              )}
                            </div>
                            <div
                              className={
                                !primaryclisign &&
                                !appointmentClient &&
                                isFormSubmitted &&
                                docFill === true
                                  ? classes.texterror
                                  : ''
                              }
                            >
                              {loc === 'en'
                                ? engLoc.mandatoryform.pcsignature
                                : spaLoc.mandatoryform.pcsignature}
                            </div>
                          </>
                        )}
                      </Field>
                    </div>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    md={6}
                    className={`${classes.pr50} ${classes.dflex} ${classes.widthFull}`}
                  >
                    <div className={`${classes.flexb} ${classes.pb10}`}>
                      <div className={classes.widthFull}>
                        <Field
                          disabled
                          className={`${classes.inputf} ${classes.widthFull} ${classes.borderb}`}
                          name="primaryDatetime"
                          component="input"
                          type="text"
                        />
                        <div>
                          {loc === 'en' ? engLoc.mandatoryform.dt : spaLoc.mandatoryform.dt}
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <div className={`${classes.pt10} ${classes.textCenter}`}>
                  {mandateformdata && mandateformdata?.status_id?.id === 1 && (
                    <Button
                      className={`${classes.buttonStyles} ${classes.mr20} ${classes.fbold}`}
                      onClick={(e) => handleMandateFormClose(false)}
                    >
                      {loc === 'en' ? engLoc.mandatoryform.cancel : spaLoc.mandatoryform.cancel}
                    </Button>
                  )}
                  <Button
                    className={`${classes.buttonStyles} ${classes.fbold}`}
                    onClick={handleSubmit}
                  >
                    {loc === 'en' ? engLoc.mandatoryform.csend : spaLoc.mandatoryform.csend}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
        <Dialog
          open={openSign}
          maxWidth="md"
          onClose={handleCloseSign}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div className={classes.addSign}>
              {isclientsign
                ? `${loc === 'en' ? engLoc.mandatoryform.crsig : spaLoc.mandatoryform.crsig}`
                : `${loc === 'en' ? engLoc.mandatoryform.pcsig : spaLoc.mandatoryform.pcsig}`}
            </div>
          </DialogTitle>
          <DialogContent>
            <Button
              onClick={clearSign}
              style={{
                color: 'red',
                fontWeight: 'normal',
                float: 'right'
              }}
            >
              {loc === 'en' ? engLoc.mandatoryform.clear : spaLoc.mandatoryform.clear}
            </Button>
            <DialogContentText id="alert-dialog-description" className={classes.canvasDiv}>
              <SignatureCanvas ref={signCanvas} canvasProps={{ width: 400, height: 130 }} />
            </DialogContentText>
            {isSubmitted && signCanvas?.current?.isEmpty() && (
              <div className={`${classes.texterror} ${classes.pb10}`}>Required Your Sign</div>
            )}
            <Button className={classes.buttonStyles} onClick={getclientSign}>
              {loc === 'en' ? engLoc.mandatoryform.save : spaLoc.mandatoryform.save}
            </Button>
            <Button
              className={classes.buttonStyles1}
              onClick={handleCloseSign}
              // style={{
              //   color: 'grey',
              //   border: '1px solid grey',
              //   fontWeight: 'normal',
              //   paddingLeft: '10px',
              //   paddingRight: '10px',
              //   float: 'right',
              //   marginTop: '10px'
              // }}
            >
              {loc === 'en' ? engLoc.mandatoryform.cancel : spaLoc.mandatoryform.cancel}
            </Button>
          </DialogContent>
        </Dialog>
      </>
    )
  }

  return (
    <>
      {(saveloading || mandatoryInviteLoading || resendLoading) && <ProgressIndicator fullHeight />}
      {/* {initialValues && ( */}
      <Form<DiclosureFormModel>
        onSubmit={onSubmit}
        initialValues={initialData}
        render={renderForm}
      />
      {/* )} */}
    </>
  )
}
