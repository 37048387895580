import React from 'react'
import { Select, SelectProps } from 'mui-rff'
import { MenuItem } from '@material-ui/core'
import { serviceRequestDisciplineDescription } from 'views/private/serviceRequests/util/enumDescription'
import { ServiceRequestDiscipline } from '../../../../graphql/hooks.generated'
import { useStyles } from '../Styles'

const options = [
  ServiceRequestDiscipline.Behavioral,
  ServiceRequestDiscipline.Occupational,
  ServiceRequestDiscipline.Physical,
  ServiceRequestDiscipline.Speech
]

type ServiceRequestDisciplineSelectProps = SelectProps

export const ServiceRequestDisciplineSelect = (props: ServiceRequestDisciplineSelectProps) => {
  const classes = useStyles()

  return (
    <Select variant="filled" {...props} className={classes.selectDropdown}>
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {serviceRequestDisciplineDescription[option]}
        </MenuItem>
      ))}
    </Select>
  )
}
