import React, { useContext } from 'react'
import { LocalAudioTrack } from 'twilio-video'
import { FormControl, MenuItem, Typography, Select, Grid } from '@material-ui/core'
import { VideoContext } from 'views/public/video/video'
import { engLoc, spaLoc } from 'config/constants/translations'
import AudioLevelIndicator from '../../AudioLevelIndicator/AudioLevelIndicator'
import { SELECTED_AUDIO_INPUT_KEY } from '../../../constants'
import useDevices from '../../../hooks/useDevices/useDevices'
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack'
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext'

export default function AudioInputList() {
  const { audioInputDevices } = useDevices()
  const { localTracks } = useVideoContext()
  const loc = useContext(VideoContext)
  const localAudioTrack = localTracks.find((track) => track.kind === 'audio') as LocalAudioTrack
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack)
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId

  function replaceTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId)
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } })
  }

  return (
    <div>
      <Typography variant="subtitle2" gutterBottom>
        {loc === 'es' ? spaLoc.video.audInp : engLoc.video.audInp}
      </Typography>
      <Grid container>
        <div className="inputSelect">
          {audioInputDevices.length > 1 ? (
            <FormControl fullWidth>
              <Select
                onChange={(e) => replaceTrack(e.target.value as string)}
                value={localAudioInputDeviceId || ''}
                variant="outlined"
              >
                {audioInputDevices.map((device) => (
                  <MenuItem value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography>
              {localAudioTrack?.mediaStreamTrack.label ||
                `${loc === 'es' ? spaLoc.video.nolocalAud : engLoc.video.nolocalAud}`}
            </Typography>
          )}
        </div>
        <AudioLevelIndicator audioTrack={localAudioTrack} color="black" />
      </Grid>
    </div>
  )
}
