import React from 'react'
import { SchoolStatusDescription } from 'views/private/serviceRequests/util/enumDescription'
import { SelectProps } from 'mui-rff'
import { SchoolStatus } from '../../../../graphql/hooks.generated'
import { DictionarySelect } from '../dictionary-select/DictionarySelect'

const options = Object.values(SchoolStatus)

type SchoolStatusSelectProps = SelectProps

export const SchoolStatusSelect = (props: SchoolStatusSelectProps) => (
  <DictionarySelect optionsDictionary={SchoolStatusDescription} options={options} {...props} />
)
