import { withStyles, createStyles, Theme } from '@material-ui/core'
import Menu from '../menu/Menu'
import MenuItem from '../menu/item/MenuItem'

const StyledMenu = withStyles(
  createStyles((theme: Theme) => ({
    root: {
      width: '220px',
      backgroundColor: theme.custom.palette.blue.main
    }
  }))
)(Menu)

export const SettingsItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.custom.palette.blue.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light
      }
    }
  })
)(MenuItem)

export default StyledMenu
