import axios from 'axios'
import httpPublic from './http-service-public'

const { REACT_APP_API_URL } = process.env
const httpsRequest = (token: any) => {
  const http = axios.create({
    baseURL: `${REACT_APP_API_URL}/chat`,
    headers: {
      'Content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`
    }
  })

  return http
}
const validateToken = (data: { user_email: string }) => {
  return httpPublic.post<any>('/chatToken', data) // provider type interface instead of any decalaration
}
const checkChatAuthentication = (userId: any, tokenKey: any) => {
  return httpsRequest(tokenKey).get<any>(`/listChatEnable/${userId}`)
}
const ServiceProvider = {
  validateToken,
  checkChatAuthentication
}

export default ServiceProvider
