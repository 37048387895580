import React, { useMemo, useEffect, useState } from 'react'
import { Select } from 'mui-rff'
import { MenuItem } from '@material-ui/core'
import { useStyles } from '../Styles'

interface DistrictSelectProps {
  label: string
  name: string
  required?: boolean
  disabled?: boolean
  selectdata?: any
  isStudentDistrict?: boolean
  districts?: any
}

export const DistrictTherapistSelect = (props: DistrictSelectProps) => {
  const active: any = 'ACTIVE'
  const { districts } = props
  const classes = useStyles()

  return (
    <>
      {districts && districts?.length > 0 ? (
        <Select {...props} variant="filled" className={classes.selectDropdown}>
          {districts
            .filter((districts) => districts.status === active)
            .map((district) => (
              <MenuItem key={district.id} value={district.id}>
                {district.name}
              </MenuItem>
            ))}
        </Select>
      ) : (
        <Select {...props} variant="filled" className={classes.selectDropdown}>
          <MenuItem>No Data Found</MenuItem>
        </Select>
      )}
    </>
  )
}
