/* eslint-disable @typescript-eslint/dot-notation */
import React, { useCallback, useMemo, useState, useEffect } from 'react'
import {
  useCreateSessionInfoLazyQuery,
  useDeleteStudentFromSessionMutation,
  useSessionsListLazyQuery,
  useSessionCountLazyQuery,
  useUpdateSessionsMutation,
  useMandatoryDetailsListLazyQuery
} from 'graphql/hooks.generated'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import { useParams, useHistory } from 'react-router-dom'
import { UseParamsType } from 'types'
import produce from 'immer'
import useVideoContext from '../view/video/hooks/useVideoContext/useVideoContext'
import { SessionView } from '../view'
import { updateTrackers } from '../operations/UpdateTracker'
import { VideoProvider } from '../view/video/components/VideoProvider'

interface ListAppointmentIDParams extends UseParamsType {
  id: string
  startTime: string
  endTime: string
}

export const SessionContainer = () => {
  // console.log('update tracker', updateTrackers)
  const errorStuObj = {
    plan: '',
    notes: '',
    assessment: '',
    further_sessions: undefined,
    high_level_care_text: '',
    further_sessions_text: '',
    requestReferral: '',
    service: '',
    outcome: '',
    goal: [],
    hasError: false,
    stuId: undefined
  }
  const errorGoalObj = {
    index: 0,
    activity: '',
    metric: ''
  }
  const { room } = useVideoContext()
  let sessionNum
  const [formErrors, setErrorData] = useState<any[]>([])
  const [openSign, setOpenSign] = React.useState(false)
  const [showMandateForm, setshowMandateForm] = useState(false)
  const [documentFill, setDocumentFill] = useState(false)
  const [selectErrorTab, setErrorTab] = React.useState(Number(null))
  const [isSubmittedSaveDoc, setIsSubmittedSaveDoc] = useState(false)
  let formErrorInit: any = []
  const history = useHistory()
  const clicksData = new Map()
  const goalsPercentageData = new Map()
  const [
    createSessionInfo,
    { data: sessionId, loading: createSessionLoading }
  ] = useCreateSessionInfoLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [
    sessionsDataInfo,
    { data: sessionData, loading: sessionListLoading }
  ] = useSessionsListLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [
    getSessionCount,
    { data: sessioncountdata, loading: sessionCountListLoading }
  ] = useSessionCountLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [
    sessionsNewDataInfo,
    { data: sessionNewData, loading: newSessiondataLoading }
  ] = useSessionsListLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [
    getmandatoryformquery,
    { data: mandateformdata, loading: mandateformloading }
  ] = useMandatoryDetailsListLazyQuery({ fetchPolicy: 'network-only' })
  const { data: currentUserData } = useCurrentUser()
  const {
    id: appointmentId,
    startTime: start_dt,
    endTime: end_dt
  } = useParams<ListAppointmentIDParams>()
  useMemo(() => {
    if (currentUserData && currentUserData.currentUser)
      createSessionInfo({
        variables: {
          Appointmentid: appointmentId,
          TherapistId: currentUserData?.currentUser?.id,
          s_start: start_dt,
          s_end: end_dt
        }
      })
  }, [appointmentId, createSessionInfo, currentUserData, end_dt, start_dt])
  const getSessionListdata = useCallback(() => {
    let newSessionId
    sessionId?.createSessionInfo?.map((idSession) => {
      newSessionId = idSession.getSessionId

      return true
    })

    if (newSessionId) sessionsDataInfo({ variables: { id: newSessionId } })
  }, [sessionId?.createSessionInfo, sessionsDataInfo])
  const getSessionsCount = useCallback(() => {
    if (sessionData) {
      const stuListdata = sessionData?.sessionsList?.find(() => true)
      const stuObj = stuListdata?.session_students?.find(() => true)
      getSessionCount({
        variables: {
          student_id: stuObj?.student_id?.id,
          user_id: stuListdata?.user_id?.id
        }
      })
    }
  }, [getSessionCount, sessionData])
  const getMandateFormDet = useCallback(() => {
    if (sessionData) {
      const stuListdata = sessionData?.sessionsList?.find(() => true)
      const stuObj = stuListdata?.session_students?.find(() => true)
      getmandatoryformquery({
        variables: {
          student_id: stuObj?.student_id?.id,
          user_id: stuListdata?.user_id?.id
        }
      })
    }
  }, [getmandatoryformquery, sessionData])
  useMemo(() => {
    getSessionListdata()
  }, [getSessionListdata])
  useMemo(() => {
    getSessionsCount()
  }, [getSessionsCount])
  useMemo(() => {
    getMandateFormDet()
  }, [getMandateFormDet])
  const [session, setSessionState] = useState(sessionData?.sessionsList?.find(() => true))
  useMemo(() => {
    if (sessionData?.sessionsList) setSessionState(sessionData?.sessionsList?.find(() => true))
  }, [sessionData])
  useMemo(() => {
    const referralids: Array<any> = []

    if (sessionData?.sessionsList)
      sessionData?.sessionsList
        ?.find(() => true)
        ?.requestReferral?.map((item) => {
          referralids.push(item?.id)

          return true
        })

    const sessionNew = produce(
      sessionData?.sessionsList?.find(() => true),
      (draft) => {
        if (draft) draft.requestReferral = referralids
      }
    )
    setSessionState(sessionNew)
  }, [sessionData])
  const [updateSession, { loading: updateSessionLoading }] = useUpdateSessionsMutation()
  const handleMandateFormClose = (isRecall?) => {
    setshowMandateForm(false)

    if (isRecall) getMandateFormDet()
  }
  const isFormValid = (sessiondata) => {
    const errorsInitData = [...formErrorInit]
    let isValid = true
    let stuId = 0
    sessiondata.session_students?.map((sessionStudent, stuIndex) => {
      if (sessionStudent.present) {
        let isStuFormValid = true

        if (!sessionStudent.plan) {
          isValid = false
          isStuFormValid = false
          errorsInitData[stuIndex].plan = 'Required'
          errorsInitData[stuIndex].hasError = true
        } else errorsInitData[stuIndex].plan = ''
        if (!sessionStudent.notes) {
          isValid = false
          isStuFormValid = false
          errorsInitData[stuIndex].notes = 'Required'
          errorsInitData[stuIndex].hasError = true
        } else errorsInitData[stuIndex].notes = ''
        if (!sessionStudent.assessment) {
          isValid = false
          isStuFormValid = false
          errorsInitData[stuIndex].assessment = 'Required'
          errorsInitData[stuIndex].hasError = true
        } else errorsInitData[stuIndex].assessment = ''
        if (
          sessionStudent.session_no >= 6 &&
          sessionStudent.further_sessions === (undefined || null)
        ) {
          isValid = false
          isStuFormValid = false
          errorsInitData[stuIndex].further_sessions = 'Required'
          errorsInitData[stuIndex].hasError = true
        }
        if (
          sessionStudent.session_no >= 6 &&
          sessionStudent.further_sessions !== (undefined || null)
        ) {
          errorsInitData[stuIndex].further_sessions = ''

          if (!sessionStudent.further_sessions_text && sessionStudent.further_sessions) {
            isValid = false
            isStuFormValid = false
            errorsInitData[stuIndex].further_sessions_text = 'Required'
            errorsInitData[stuIndex].hasError = true
          }
        }
        if (sessionStudent.high_level_care && !sessiondata?.requestReferral?.length) {
          isValid = false
          isStuFormValid = false
          errorsInitData[stuIndex].requestReferral = 'Required'
          errorsInitData[stuIndex].hasError = true
        } else errorsInitData[stuIndex].requestReferral = ''
        if (sessionStudent.high_level_care && !sessionStudent?.high_level_care_text) {
          isValid = false
          isStuFormValid = false
          errorsInitData[stuIndex].high_level_care_text = 'Required'
          errorsInitData[stuIndex].hasError = true
        } else errorsInitData[stuIndex].high_level_care_text = ''
        if (!sessiondata.service_id) {
          isValid = false
          isStuFormValid = false
          errorsInitData[stuIndex].service = 'Required'
          errorsInitData[stuIndex].hasError = true
        } else errorsInitData[stuIndex].service = ''

        sessionStudent?.session_Goals?.map((goals, goalInd) => {
          if (!goals.activity) {
            isValid = false
            isStuFormValid = false
            errorsInitData[stuIndex].goal[goalInd].activity = 'Required'
            errorsInitData[stuIndex].hasError = true
          } else errorsInitData[stuIndex].goal[goalInd].activity = ''
          if (!goals.session_rate_id) {
            isValid = false
            isStuFormValid = false
            errorsInitData[stuIndex].goal[goalInd].metric = 'Required'
            errorsInitData[stuIndex].hasError = true
          } else errorsInitData[stuIndex].goal[goalInd].metric = ''

          return true
        })

        if (isStuFormValid) errorsInitData[stuIndex].hasError = false
        if (!isStuFormValid && !stuId) stuId = sessionStudent?.student_id?.id
      } else {
        errorsInitData[stuIndex].plan = ''
        errorsInitData[stuIndex].notes = ''
        errorsInitData[stuIndex].assessment = ''
        errorsInitData[stuIndex].further_sessions = ''
        errorsInitData[stuIndex].hasError = false
        errorsInitData[stuIndex].stuId = sessionStudent?.student_id?.id
        errorsInitData[stuIndex]?.goal.map((goalObj) => {
          goalObj.activity = ''
          goalObj.metric = ''

          return true
        })
      }

      return true
    })
    setErrorData(errorsInitData)
    setErrorTab(stuId)

    return isValid
  }
  // update session
  const saveSession = async (
    isSigned,
    signImage,
    trackersCount,
    trackerMetric,
    redirect,
    checkValidation
  ) => {
    if (session) {
      const saveSession1 = { ...session, updated_by: currentUserData?.currentUser.id }
      let saveSession

      if (saveSession1.service_id)
        saveSession = { ...saveSession1, service_id: saveSession1.service_id.id }
      else saveSession = saveSession1
      if (isSigned) saveSession.sign = true
      else saveSession.sign = false
      if (saveSession.signature_history)
        saveSession.signature_history = { ...saveSession.signature_history, sign: signImage }
      else saveSession.signature_history = { sign: signImage, id: '' }
      if (saveSession.outcome_id)
        saveSession = { ...saveSession, outcome_id: saveSession.outcome_id.id }
      else saveSession = saveSession1

      let sessionStudents
      let goalTrackers
      let goalsdata

      if (saveSession)
        sessionStudents = saveSession.session_students?.map((sessionStudent) => {
          goalsdata = sessionStudent?.session_Goals?.map((goals) => {
            goalTrackers = goals?.session_tracker?.map((tracker) => {
              const computedId = `${tracker?.tracker_id?.id}_${sessionStudent.student_id?.id}${goals?.goal_id?.goal_number}`

              if (trackersCount && trackersCount.has(computedId))
                tracker = { ...tracker, clicks: trackersCount.get(computedId) }
              else tracker = { ...tracker }

              return tracker
            })
            let gaolClone

            if (trackerMetric && trackerMetric.has(goals?.goal_id?.id))
              gaolClone = {
                ...goals,
                attempts: trackerMetric.get(goals?.goal_id?.id)[0],
                assist: trackerMetric.get(goals?.goal_id?.id)[1],
                total: trackerMetric.get(goals?.goal_id?.id)[2],
                percentage: trackerMetric.get(goals?.goal_id?.id)[3]
              }
            else gaolClone = { ...goals }

            return { ...gaolClone, session_tracker: goalTrackers }
          })

          return { ...sessionStudent, session_Goals: goalsdata }
        })

      const saveSessionData = {
        ...saveSession,
        session_students: sessionStudents
      }
      try {
        if (checkValidation) {
          // console.log(saveSessionData)
          setIsSubmittedSaveDoc(true)
          const isvalidForm = isFormValid(saveSessionData)

          if (!isvalidForm) return
          if (isvalidForm && !signImage) setOpenSign(true)
          if (isvalidForm && signImage) {
            const newSaveSessionData = {
              ...saveSessionData,
              service_id: saveSessionData?.service_id
                ? saveSessionData?.service_id?.id || saveSessionData?.service_id
                : null
            }
            await updateSession({
              variables: {
                input: newSaveSessionData
              }
            })
          }
        } else {
          setErrorData(formErrorInit)
          setIsSubmittedSaveDoc(false)
          const newSaveSessionData = {
            ...saveSessionData,
            service_id: saveSessionData?.service_id
              ? saveSessionData?.service_id?.id || saveSessionData?.service_id
              : null
          }
          await updateSession({
            variables: {
              input: newSaveSessionData
            }
          })
          getSessionListdata()
        }

        room?.disconnect()

        if (redirect) history.goBack()
      } catch (e) {
        // console.log(e)
      }
    }
  }
  const handleRefresh = (
    studentId: number,
    sessionId,
    isSigned: boolean,
    signImage: string,
    trackersCount,
    trackerMetric,
    redirect,
    checkValidation
  ) => {
    saveSession(isSigned, signImage, trackersCount, trackerMetric, redirect, checkValidation)
    sessionsNewDataInfo({ variables: { id: sessionId } })

    if (sessionNewData) {
      const newSession = sessionNewData?.sessionsList?.find(() => true)
      const newStudent = newSession?.session_students?.find(
        (student) => (student?.student_id?.id || 0) === studentId
      )

      if (newStudent) {
        const sessionNew = produce(session, (draft) => {
          draft?.session_students?.push(newStudent)
        })
        setSessionState(sessionNew)
      }
    }
  }
  const updateStudent = (studentId, studentObject) => {
    const sessionNew = produce(session, (draft) => {
      const index = draft?.session_students?.findIndex(
        (stu) => studentId === (stu?.student_id?.id || 0)
      )

      if (index !== undefined && index !== -1)
        Object.entries(studentObject).forEach(([k, v]) => {
          const stu = draft?.session_students?.length ? draft?.session_students[index] : []
          stu[k] = v
        })
    })
    setSessionState(sessionNew)

    if (isSubmittedSaveDoc) isFormValid(sessionNew)
  }
  const referralhandleChange = (data) => {
    const sessionNew = produce(session, (draft) => {
      if (draft) draft.requestReferral = data
    })
    setSessionState(sessionNew)

    if (isSubmittedSaveDoc) isFormValid(sessionNew)
  }
  const [deleteStu] = useDeleteStudentFromSessionMutation()
  const deleteStudent = async (studentId) => {
    if (session) await deleteStu({ variables: { session_id: session?.id, student_id: studentId } })

    // const sessionNew = produce(session, (draft) => {
    //   const index = draft?.session_students?.findIndex(
    //     (stu) => studentId === (stu?.student_id?.id || 0)
    //   )

    //   if (index !== undefined && index !== -1) draft?.session_students?.splice(index, 1)
    // })
    setErrorData([])
    setErrorTab(Number(null))
    // setSessionState(sessionNew)
    getSessionListdata()
  }
  const copyGoalActivity = (text: string) => {
    const sessionNew = produce(session, (draft) => {
      draft?.session_students?.forEach((stu) => {
        stu.session_Goals?.forEach((goal) => {
          if (goal) goal.activity = text
        })
      })
    })
    setSessionState(sessionNew)

    if (isSubmittedSaveDoc) isFormValid(sessionNew)
  }
  const deleteGoal = (studentId, goalId) => {
    const sessionNew = produce(session, (draft) => {
      const index = draft?.session_students?.findIndex((stu) => studentId === stu?.student_id?.id)

      if (index !== undefined && index !== -1) {
        const stu = (draft?.session_students || [])[index]

        if (stu) {
          const goalIndex = stu.session_Goals?.findIndex((goal) => goal?.id === goalId)

          if (goalIndex !== undefined && goalIndex !== -1 && stu?.session_Goals?.length) {
            const gId = stu.session_Goals[goalIndex]?.id

            if (!stu.deleted_goals?.length) stu.deleted_goals = []
            if (gId) stu.deleted_goals?.push(gId)

            stu.session_Goals?.splice(goalIndex, 1)
          }
        }
      }
    })
    setSessionState(sessionNew)
  }
  const updateGoal = (studentId, goalId, goalObj) => {
    const sessionNew = produce(session, (draft) => {
      const index = draft?.session_students?.findIndex(
        (stu) => studentId === (stu?.student_id?.id || 0)
      )

      if (index !== undefined && index !== -1) {
        const stu = (draft?.session_students || [])[index]

        if (stu) {
          const goalIndex = stu.session_Goals?.findIndex((goal) => goal?.goal_id?.id === goalId)

          if (goalIndex !== undefined && goalIndex !== -1)
            Object.entries(goalObj).forEach(([k, v]) => {
              const goal = stu.session_Goals?.length ? stu.session_Goals[goalIndex] : []

              if (goal) goal[k] = v
            })
        }
      }
    })
    setSessionState(sessionNew)

    if (isSubmittedSaveDoc) isFormValid(sessionNew)
  }
  const updateServiceValue = (service_id) => {
    console.log('aaaaaaaa', service_id)

    if (session) {
      const saveSession = { ...session, service_id }
      setSessionState(saveSession)

      if (isSubmittedSaveDoc) isFormValid(saveSession)
    }
  }
  const updateOutcomeValue = (outcome_id) => {
    if (session) {
      const saveSession = { ...session, outcome_id }
      setSessionState(saveSession)
    }
  }
  // const updateTracker = async (studentId, goalId, trackerId, actionType) => {
  //   const sessionNew = updateTrackers(session, studentId, goalId, trackerId, actionType)

  //   if (sessionNew) setSessionState(sessionNew as any)
  // }

  if (session) {
    let errorDataPrep: any[] = []
    session.session_students?.map((sessionStudent) => {
      let stuObj: any = {}
      stuObj = { ...errorStuObj }
      let errorGoalArray: any[] = []
      sessionStudent?.session_Goals?.map((goals, ind) => {
        goals?.session_tracker?.map((tracker) => {
          const computedid = `${tracker?.tracker_id?.id}_${sessionStudent?.student_id?.id || 0}${
            goals?.goal_id?.goal_number
          }`

          if (tracker?.clicks !== 0) clicksData.set(computedid, tracker?.clicks)

          return true
        })

        if (goals && goals.total)
          goalsPercentageData.set(goals?.goal_id?.id, [
            goals.attempts ? goals.attempts : 0,
            goals.assist ? goals.assist : 0,
            goals.total ? goals.total : 0,
            goals.percentage ? goals.percentage : 0
          ])

        const errorGoalObj1: any = errorGoalObj
        errorGoalObj1.index = ind
        errorGoalArray = [...errorGoalArray, { ...errorGoalObj1 }]

        return true
      })
      stuObj = { ...stuObj, goal: [...errorGoalArray], stuId: sessionStudent?.student_id?.id }
      errorDataPrep = [...errorDataPrep, stuObj]

      return true
    })
    // setErrorData(errorDataPrep)
    formErrorInit = errorDataPrep
  }
  if (sessioncountdata) {
    const sessionNumobj = sessioncountdata?.sessionCount?.find(() => true)

    if (sessionNumobj) sessionNum = sessionNumobj?.count
  }

  return (
    <>
      {(createSessionLoading ||
        sessionListLoading ||
        newSessiondataLoading ||
        updateSessionLoading ||
        sessionCountListLoading ||
        mandateformloading) && <ProgressIndicator fullHeight />}
      {session && sessioncountdata && formErrorInit && (
        <SessionView
          sessionData={session || undefined}
          clicksData={clicksData}
          sessionCount={sessioncountdata}
          mandateformdata={mandateformdata}
          goalsPercentageData={goalsPercentageData}
          formErrors={formErrors && formErrors.length ? formErrors : formErrorInit}
          handleSignModal={setOpenSign}
          openSign={openSign}
          setshowMandateForm={setshowMandateForm}
          showMandateForm={showMandateForm}
          documentFill={documentFill}
          setDocumentFill={setDocumentFill}
          handleMandateFormClose={handleMandateFormClose}
          selectErrorTab={selectErrorTab}
          updateServiceValue={updateServiceValue}
          updateOutcomeValue={updateOutcomeValue}
          referralhandleChange={referralhandleChange}
          actions={{
            handleRefresh: (
              studentId: number,
              sessionId,
              isSigned: boolean,
              signImage: string,
              trackersCount,
              trackerMetric,
              redirect,
              checkValidation
            ) =>
              handleRefresh(
                studentId,
                sessionId,
                isSigned,
                signImage,
                trackersCount,
                trackerMetric,
                redirect,
                checkValidation
              ),
            saveSession: (
              isSigned: boolean,
              signImage: string,
              trackersCount,
              trackerMetric,
              redirect,
              checkValidation
            ) =>
              saveSession(
                isSigned,
                signImage,
                trackersCount,
                trackerMetric,
                redirect,
                checkValidation
              )
          }}
          studentActions={{
            updateStudent: (studentId: number, studentObject) =>
              updateStudent(studentId, studentObject),
            deleteStudent: (studentId: number) => deleteStudent(studentId)
          }}
          goalActions={{
            updateGoal: (studentId, goalId, goalObj) => updateGoal(studentId, goalId, goalObj),
            deleteGoal: (studentId, goalId) => deleteGoal(studentId, goalId),
            copyGoalActivity: (text: string) => copyGoalActivity(text)
            // updateTracker: (studentId, goalId, trackerId, actionType) =>
            //   updateTracker(studentId, goalId, trackerId, actionType)
          }}
        />
      )}
    </>
  )
}
