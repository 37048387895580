import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { CreateDistrictView } from './create/CreateDistrictView'
import { UpdateDistrictView } from './update/UpdateDistrictView'
import { DistrictListView } from './list/DistrictListView'
import { SchoolsRootView } from '../schools/SchoolsRootView'
import { StudentsRootView } from '../students/StudentsRootView'
import { TherapistsRootView } from '../therapist/TherapistsRootView'

export const DistrictsRootView = () => {
  const { url } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${url}/create`} component={CreateDistrictView} />
      <Route path={`${url}/:id/edit`} component={UpdateDistrictView} />
      <Route path={`${url}/:id/schools`} component={SchoolsRootView} />
      <Route path={`${url}/:id/students`} component={StudentsRootView} />
      <Route path={`${url}/:id/therapists`} component={TherapistsRootView} />
      <Route component={DistrictListView} />
    </Switch>
  )
}
