import React from 'react'
import { SelectProps } from 'mui-rff'
import { timeZoneDescription } from 'views/private/serviceRequests/util/enumDescription'
import { DictionarySelect } from '../dictionary-select/DictionarySelect'

type TimeZoneSelectProps = SelectProps

export const TimeZoneSelect = (props: TimeZoneSelectProps) => {
  return <DictionarySelect optionsDictionary={timeZoneDescription} {...props} />
}
