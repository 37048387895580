import React from 'react'
import { UserStatusDescription } from 'views/private/serviceRequests/util/enumDescription'
import { SelectProps } from 'mui-rff'
import { UserStatus } from '../../../../graphql/hooks.generated'
import { DictionarySelect } from '../dictionary-select/DictionarySelect'

const options = Object.values(UserStatus)

type UserStatusSelectProps = SelectProps

export const UserStatusSelect = (props: UserStatusSelectProps) => (
  <DictionarySelect optionsDictionary={UserStatusDescription} options={options} {...props} />
)
