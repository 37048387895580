import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    leftIcon: {
      marginRight: theme.spacing(1)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    iconSmall: {
      fontSize: 20
    },
    root: {
      padding: theme.spacing(3, 2)
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400
    },
    labeltext: {
      padding: '5px 10px',
      color: 'lightslategray',
      fontSize: '12px'
    },
    fileType: {
      padding: '10px',
      width: '408px',
      color: 'lightslategray',
      fontSize: '12px'
    },
    fileSelect: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '400px',
      height: '50px'
    },
    errortext: {
      color: theme.palette.error.dark
    },
    textLeft: {
      textAlign: 'left'
    },
    pl10: {
      paddingLeft: '10px'
    },
    textcenter: {
      textAlign: 'center'
    }
  })
)
