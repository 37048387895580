import React from 'react'
import { planTypeDescription } from 'views/private/serviceRequests/util/enumDescription'
import { SelectProps } from 'mui-rff'
import { PlanType } from '../../../../graphql/hooks.generated'
import { DictionarySelect } from '../dictionary-select/DictionarySelect'

const options = [
  PlanType.Iep,
  PlanType.Section504,
  PlanType.Behavior,
  PlanType.SupportSchoolPersonnel,
  PlanType.Individual,
  PlanType.NonResident,
  PlanType.NonIdea
]

type PlanTypeSelectProps = SelectProps

export const PlanTypeSelect = (props: PlanTypeSelectProps) => (
  <DictionarySelect optionsDictionary={planTypeDescription} options={options} {...props} />
)
