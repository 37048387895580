import './decoratorExtension.d'
import createDecorator from 'final-form-calculate'
import { Role, UserInput } from 'graphql/hooks.generated'
import { Decorator } from 'final-form'
import { UserFormModel } from './UserFormModel'

export const calculator = createDecorator<Role[], UserInput>({
  field: 'roles',
  updates: {
    roles: (
      roles: Role[] | undefined,
      allValues: UserInput | undefined,
      prevValues: UserInput | undefined
    ) => {
      if (roles?.includes(Role.Therapist) && !roles?.includes(Role.Therapist))
        return [...roles, Role.Therapist]
      if (prevValues?.roles?.includes(Role.Therapist) && !roles?.includes(Role.Therapist))
        return roles?.filter((role) => role !== Role.Therapist)

      return allValues?.roles || []
    }
  }
}) as Decorator<UserFormModel>
