import React, { useContext, useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { engLoc, spaLoc } from 'config/constants/translations'
import {
  useCreateCallFeedbackMutation,
  useGetCallQualityFeedbackByIdQuery
} from 'graphql/hooks.generated'
import { VideoContext } from 'views/public/video/video'
import CallEndIcon from '@material-ui/icons/CallEnd'
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext'
import RatingDialogue from '../../ratingDialog/ratingDialog'

interface EndCallButtonProps {
  className?: string
  ratingProp?: React.Dispatch<React.SetStateAction<boolean>> | undefined
  clientdata?: any
}

export default function EndCallButton({ className, ratingProp, clientdata }: EndCallButtonProps) {
  const { room } = useVideoContext()
  const loc = useContext(VideoContext)
  const [showRatingDialogue, setShowRatingDialogue] = useState(false)
  const [studentHearRating, setStudentHearRating] = useState(false)
  const [studentSeeRating, setStudentSeeRating] = useState(false)
  const [clientpresent, setClientpresent] = useState(false)
  const [studentComment, setStudentComment] = useState('')
  const [callFeedBack, { loading }] = useCreateCallFeedbackMutation()
  const [userType, steUserType] = useState(room?.localParticipant.identity)
  const [autosave, setAutosave] = React.useState(false)
  const [saved, setSaved] = useState({
    studentHearRating: false,
    studentSeeRating: false,
    studentComment: ''
  })
  const provider = room?.localParticipant.identity === 'Therapist'
  const client = room?.localParticipant.identity !== 'Therapist'
  const {
    data: callFeedBackByID,
    loading: callfeedbackLoading
  } = useGetCallQualityFeedbackByIdQuery({
    variables: {
      appointment_id: String(room?.name),
      is_provider_rated: provider,
      is_student_rated: client
    },
    fetchPolicy: 'network-only'
  })
  // const handleEndCall = () => {
  //   if (showRatingDialogue) room?.disconnect()
  //   else setShowRatingDialogue(true)
  // }
  useEffect(() => {
    if (clientdata !== 'Therapist' && clientdata) setClientpresent(true)
  }, [clientdata])
  // console.log(clientpresent, clientdata, 'clientdataaas')
  const handleEndCall = () => {
    if (
      clientdata !== 'Therapist' ||
      (room?.participants.size !== 0 && clientdata === 'Therapist') ||
      clientpresent
    )
      if (showRatingDialogue) room?.disconnect()
      else setShowRatingDialogue(true)
    else room?.disconnect()
  }
  const handleRatingConfirmed = () => {
    if (ratingProp) ratingProp(true)

    let feedbackObj

    if (room?.localParticipant.identity !== 'Therapist')
      feedbackObj = {
        appointment_id: String(room?.name),
        seeing_rate: studentSeeRating,
        listening_rate: studentHearRating,
        comment: studentComment,
        is_provider_rated: false,
        is_student_rated: true
      }
    else
      feedbackObj = {
        appointment_id: String(room?.name),
        seeing_rate: studentSeeRating,
        listening_rate: studentHearRating,
        comment: studentComment,
        is_provider_rated: true,
        is_student_rated: false
      }

    callFeedBack({
      variables: {
        input: feedbackObj
      }
    })
    setShowRatingDialogue(false)
    room?.disconnect()
  }
  const handleRatingautosave = () => {
    if (ratingProp) ratingProp(true)

    setSaved({
      studentHearRating,
      studentSeeRating,
      studentComment
    })
    let feedbackObj

    if (room?.localParticipant.identity !== 'Therapist')
      feedbackObj = {
        appointment_id: String(room?.name),
        seeing_rate: studentSeeRating,
        listening_rate: studentHearRating,
        comment: studentComment,
        is_provider_rated: false,
        is_student_rated: true
      }
    else
      feedbackObj = {
        appointment_id: String(room?.name),
        seeing_rate: studentSeeRating,
        listening_rate: studentHearRating,
        comment: studentComment,
        is_provider_rated: true,
        is_student_rated: false
      }

    callFeedBack({
      variables: {
        input: feedbackObj
      }
    })
  }
  useEffect(() => {
    const autosave = setInterval(function () {
      setAutosave(true)
    }, 5 * 60 * 1000) // runs every minute

    return () => {
      setAutosave(false) // turn autosave off
      clearInterval(autosave) // clear autosave on dismount
    }
  }, [])
  // autosave if changes
  useEffect(() => {
    if (
      autosave &&
      (studentHearRating !== saved.studentHearRating ||
        studentSeeRating !== saved.studentSeeRating ||
        studentComment !== saved.studentComment)
    ) {
      handleRatingautosave()
      setAutosave(false) // toggle autosave off
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autosave, studentHearRating, studentSeeRating, studentComment, handleRatingautosave])

  return (
    <>
      <Button
        onClick={handleEndCall}
        // eslint-disable-next-line react/destructuring-assignment
        className={className}
        style={{ backgroundColor: '#F1583A', padding: '6px 15px', color: 'white' }}
        data-cy-disconnect
      >
        <CallEndIcon style={{ marginRight: '8px', fontSize: 'medium' }} />
        {loc === 'es' ? spaLoc.video.end : engLoc.video.end}
      </Button>
      {showRatingDialogue && (
        <RatingDialogue
          onClose={() => setShowRatingDialogue(false)}
          onRatingConfirmed={handleRatingConfirmed}
          setStudentHearRating={setStudentHearRating}
          setStudentSeeRating={setStudentSeeRating}
          setStudentComment={setStudentComment}
          userType={userType}
          callFeedBackByID={callFeedBackByID}
          setSaved={setSaved}
          saved={saved}
        />
      )}
    </>
  )
}
