import { makeStyles, createStyles, Theme, createMuiTheme } from '@material-ui/core/styles'
import createTheme from './create-theme'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary
    },
    alignRightt: {
      float: 'right'
    },
    alignPadding: {
      paddingTop: '5px'
    },
    textField: {
      border: '1px solid #000'
    },
    forntSizes: {
      fontSize: '16px',
      fontFamily: 'Brandon Grotesque'
    },
    selectDropdown: {
      bacgroundColor: '#fff',
      border: '1px solid black',
      backgroundColor: 'white',
      fontSize: '13px',
      fontFamily: 'Brandon Grotesque',
      height: 30,
      width: '100%',
      paddingTop: 0,
      paddingLeft: 0,
      borderRadius: 3,
      '& .MuiFilledInput-input': {
        backgroundColor: '#fff !important'
      },
      '& .MuiSelect-filled.MuiSelect-filled': {
        backgroundColor: '#fff !important'
      },
      '& div': {
        padding: '6px'
      },
      selectMenu: {
        bacgroundColor: '#fff',
        padding: '0px !important',
        fontSize: '16px'
      }
    },
    edit: {
      backgroundColor: '#2C9BD6',
      borderRadius: '50%',
      color: 'white',
      minWidth: '50px',
      height: '50px'
    },
    name: {
      fontSize: '20px',
      margin: '0px 0px 0px 15px',
      color: ' #F1583A',
      fontFamily: 'Brandon Grotesque'
    },
    namecard: {
      display: 'flex',
      alignItems: 'center'
    },
    buttonadjust: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    flex: {
      display: 'flex'
    },
    flexStretch: {
      alignItems: 'stretch'
    },
    namelabel: {
      margin: '0px 0px 0px 15px'
    },
    avatar: {
      width: 70,
      height: 70
    },
    sessionStyle: {
      margin: '0px 0px 0px 30px',
      backgroundColor: '#4b99d133',
      padding: '14px 28px',
      marginTop: '16px',
      display: 'flex',
      borderRadius: '5px'
    },
    session: {
      margin: '0px 0px 0px 30px',
      backgroundColor: '#4b99d133',
      padding: '11px 20px',
      display: 'flex',
      borderRadius: '5px'
    },
    studentheaders: {
      fontSize: '20px',
      fontFamily: 'Brandon Grotesque',
      color: '#4B99D1',
      letterSpacing: '0.5px',
      margin: '0px 0px 0px 10px'
    },
    label: {
      fontSize: '16px',
      color: '#0D1F48',
      margin: '0px 0px 0px 15px',
      fontWeight: 600,
      fontFamily: 'Brandon Grotesque'
    },
    hintText: {
      '& label': {
        alignItems: 'flex-start',
        marginRight: '0px'
      },
      '& span': {
        fontFamily: 'Brandon Grotesque',
        fontSize: '14px',
        paddingTop: '0px'
      },
      '& .Mui-checked': {
        color: 'rgb(241, 88, 58)'
      }
    },
    allcards: {
      border: '1px solid #E4E4E4',
      borderRadius: '5px',
      height: '100%'
    },
    headersalign: {
      padding: 15,
      borderBottom: '1px solid #E4E4E4',
      display: 'flex'
    },
    pad15: {
      padding: 15,
      height: 'auto'
    },
    studentlabels: {
      fontSize: '16px',
      color: '#0D1F48',
      fontWeight: 600,
      fontFamily: 'Brandon Grotesque',
      margin: '10px 0px'
    },
    notesdate: {
      fontSize: '14px',
      color: 'grey',
      fontWeight: 500,
      fontFamily: 'Brandon Grotesque',
      margin: '10px 0px'
    },
    studentmesseg: {
      fontSize: '15px',
      color: '#0D1F48',
      fontWeight: 500,
      fontFamily: 'Brandon Grotesque'
    },
    studentlabelsize: {
      width: '117px'
    },
    studentlabelsize1: {
      width: '140px'
    },
    blueback: {
      fontFamily: 'Brandon Grotesque',
      fontSize: '16px',
      color: '#6E6E6E',
      margin: '0px'
    },
    bluelabel: {
      fontFamily: 'Brandon Grotesque',
      fontSize: '20px',
      color: '#000000',
      marginLeft: '5px'
    },
    studentdata: {
      color: '#000000',
      fontSize: '16px',
      fontFamily: 'Brandon Grotesque',
      margin: '10px 0px',
      width: '100%',
      wordWrap: 'break-word'
    },
    straightline: {
      border: 'none',
      borderTop: '2px dashed #E4E4E4'
    },
    straightlineStyle: {
      // Thrapist role
      marginTop: '15px',
      marginBottom: '15px',
      border: 'none',
      borderTop: '2px dashed #E4E4E4'
    },
    sessionsize: {
      width: '120px'
    },
    notes: {
      overflowY: 'auto',
      maxHeight: '86px',
      minHeight: '30px'
    },
    cancel: {
      textTransform: 'capitalize',
      padding: '6px 25px',
      border: '1px solid #C5C5C5',
      borderRadius: 25,
      color: '#000000',
      fontSize: '15px',
      fontFamily: 'Brandon Grotesque',
      fontWeight: 600
    },
    save: {
      backgroundColor: '#F1583A',
      textTransform: 'capitalize',
      padding: '6px 25px',
      marginLeft: '5px',
      borderRadius: 25,
      color: '#FFFFFF',
      fontSize: '15px',
      fontWeight: 600,
      fontFamily: 'Brandon Grotesque',
      '&:hover': {
        background: '#F2684D'
      }
    },
    saveBtn: {
      // backgroundColor: '#F1583A',
      textTransform: 'capitalize',
      padding: '10px 10px',
      borderRadius: 25,
      color: '#F1583A',
      fontSize: '12',
      fontWeight: 600,
      textAlign: 'center',
      width: ' 150px',
      marginRight: '10px'
    },
    buttongroup: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: 12,
      width: '100%'
    },
    specificedit: {
      width: '62%'
    },
    contactedit: {
      width: '62%'
    },
    pad12: {
      padding: 12
    },
    fullwidth: {
      width: '100%'
    },
    frequencysize: {
      width: '193px'
    },
    editnotes: {
      height: '75px !important',
      overflow: 'auto !important',
      color: '#000000',
      fontSize: '16px',
      fontFamily: 'Brandon Grotesque',
      width: ' 100% !important ',
      margin: 0
    },
    default: {
      display: 'flex',
      width: '100%'
    },
    updatetable: {
      boxShadow: '0px 1px 6px #00000029',
      borderRadius: '5px'
    },
    headers: {
      color: '#0D1F48',
      fontFamily: 'Brandon Grotesque',
      fontSize: '16px',
      fontWeight: 600,
      letterSpacing: '0.5px'
    },
    table: {
      minWidth: 650,
      '& .e-pager .e-currentitem': {
        backgroundColor: '#F1583A'
      },
      '&.e-row:hover': {
        backgroundColor: 'transparent'
      },
      '& .e-headertext': {
        fontSize: '18px'
      }
    },
    noteModaltitle: {
      '& h2': {
        fontWeight: 'bold',
        fontFamily: 'Brandon Grotesque'
      }
    },
    data: {
      color: '#000000',
      fontFamily: 'Brandon Grotesque',
      fontSize: '16px',
      fontWeight: 100,
      letterSpacing: '0.5px'
    },
    MuiCircularProgress: {
      color: '#F1583A'
    },
    bottom: {
      color: '!important #F1583A',
      marginLeft: '540px'
    },
    root: {
      flexGrow: 1
    },
    overrides: {
      backgroundColor: 'white',
      color: 'black'
    },
    tabbackground: {
      backgroundColor: 'white',
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
    },
    tabRoot: {
      color: '#0D1F48',
      fontWeight: 600,
      textTransform: 'capitalize',
      fontSize: 16,
      fontFamily: 'Brandon Grotesque'
    },
    indicator: {
      backgroundColor: '#F1583A'
    },
    selected: {
      color: '#F1583A'
    },
    mail: {
      color: '#000000',
      fontSize: '16px',
      fontFamily: 'Brandon Grotesque',
      margin: '10px 0px',
      width: '100%',
      wordWrap: 'break-word',
      maxWidth: '198px'
    },
    brandonFF: {
      fontFamily: 'Brandon Grotesque'
    },
    errorColor: {
      color: theme.palette.error.dark
    },
    textarea: {
      '&:focus-visible': {
        outline: 'none !important'
      }
    },
    input: {
      '&:focus-visible': {
        outline: 'none !important'
      }
    },
    hide: {
      display: 'none'
    },
    dochead: {
      color: '#0D1F48',
      fontSize: 16,
      fontFamily: 'Brandon Grotesque',
      fontWeight: 600,
      letterSpacing: 0.5
      // width: '30%'
    },
    docsubH: {
      color: '#0D1F48',
      fontSize: 16,
      fontFamily: 'Brandon Grotesque',
      fontWeight: 600,
      letterspacing: 0.5,
      margin: 0
    },
    docD: {
      margin: 0,
      fontSize: 16,
      fontFamily: 'Brandon Grotesque'
    },
    ltblue: {
      backgroundColor: '#EAF3F9',
      padding: '0px 5px 0px 5px'
    },
    collapsetheme: {
      backgroundColor: 'white',
      paddingLeft: 10,
      paddingRight: 10,
      marginBottom: 5,
      borderRadius: 10,
      display: 'flex'
    },
    ptb10: {
      paddingTop: '10px',
      paddingBottom: '10px'
    },
    headsection: {
      display: 'flex',
      justifyContent: 'end',
      position: 'absolute',
      right: '250px',
      top: '5px',
      zIndex: 9
    },
    mar0: {
      margin: 0
    },
    marBt: {
      marginBottom: 0
    },
    marbtneg: {
      marginBottom: '-6px'
    },
    docTableAlign: {
      padding: 15
    },
    docbuttonsalign: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    docbuttonsalignStyle: {
      display: 'flex',
      alignItems: 'center'
    },
    filteralign: {
      marginLeft: 15,
      marginRight: 10,
      color: '#0D1F48',
      fontSize: 16,
      fontFamily: 'Brandon Grotesque',
      fontWeight: 600,
      letterSpacing: 0.5
    },
    alignright: {
      textAlign: 'right'
    },
    aligncenter: {
      textAlign: 'center'
    },
    alignLeft: {
      textAlign: 'left'
    },
    tabconAdjust: {
      overflow: 'unset'
    },
    docsession: {
      margin: 0,
      display: 'flex',
      padding: '11px 20px',
      borderRadius: 5,
      backgroundColor: '#4b99d133'
    },
    doccardpad: {
      padding: 15
    },
    pr15: {
      paddingRight: '15px'
    },
    wraptext: {
      wordWrap: 'break-word'
    },
    iBlock: {
      display: 'inline-block'
    },
    inputPadding: {
      '& input': {
        padding: '10px',
        fontFamily: 'Brandon Grotesque'
      }
    },
    phoneinputPadding: {
      '& input': {
        padding: '10px 10px 10px 0px',
        fontFamily: 'Brandon Grotesque'
      }
    },
    phoneHelperTxt: {
      '& #label-phone-helper-text': {
        color: ' #f56568'
      },
      '& #label-parent2phone-helper-text': {
        color: ' #f56568'
      },
      '& #label-parentphone-helper-text': {
        color: ' #f56568'
      }
    },
    '.Mui-error': {
      borderColor: 'red'
    },
    dob: {
      '& .e-input-group, .e-input-group.e-control-wrapper': {
        padding: '3px 5px',
        borderRadius: '4px'
      }
    },
    pb5: {
      paddingBottom: '5px'
    },
    pb10: {
      paddingBottom: '10px'
    },
    dropDown: {
      marginTop: '10px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '4px'
    },
    mt20: {
      marginTop: '20px'
    },
    mt0: {
      marginTop: '0px'
    },
    pl0: {
      paddingLeft: '0px'
    }
  })
)

export const theme = createMuiTheme({
  overrides: {
    MuiTabs: {
      flexContainer: {
        display: 'flex',
        justifyContent: 'space-between'
      }
    },
    MuiInputBase: {
      multiline: {
        fontFamily: 'Brandon Grotesque !important'
      }
    }
  }
})
