/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-case-declarations */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/react-in-jsx-scope */
import { ActionType } from '../action-types'
import { Action } from '../actions'

export type UnreadMessagesState = Record<string, number>

const initialState: UnreadMessagesState = {}
const reducer = (state = initialState, action: Action): UnreadMessagesState => {
  switch (action.type) {
    case ActionType.UPDATE_UNREAD_MESSAGES:
      // get convo sid and messages to add from payload
      const { channelSid, unreadCount } = action.payload

      // overwrite the channelSid unread count
      return { ...state, [channelSid]: unreadCount }
    default:
      return state
  }
}

export default reducer
