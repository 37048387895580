import React from 'react'
import { MenuItem } from '@material-ui/core'
import { Select, SelectProps } from 'mui-rff'
import { useStyles } from '../Styles'

export const LOCATION_CODES = ['03', '11', '12', '14', '22', '99']

type LocationCodeSelectProps = SelectProps

export const LocationCodeSelect = (props: LocationCodeSelectProps) => {
  const classes = useStyles()

  return (
    <>
      <Select {...props} variant="filled" className={classes.selectDropdown}>
        {LOCATION_CODES.map((code) => (
          <MenuItem key={code} value={code}>
            {code}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}
