import React from 'react'
import { useCreateServiceRequestMutation } from 'graphql/hooks.generated'
import { Section } from 'components/ui/panel/section/Section'
import { PageContainer } from 'components/ui/panel/page-container/PageContainer'
import NavBar from 'components/ui/panel/nav-bar/NavBar'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { CreateServiceRequestFormModel } from './CreateServiceRequestFormModel'
import { CreateServiceRequestForm } from './CreateServiceRequestForm'

export const CreateServiceRequestView = () => {
  const [execute] = useCreateServiceRequestMutation()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const handleSubmit = (values: CreateServiceRequestFormModel) =>
    execute({
      variables: { input: values }
    })
      .then(() => enqueueSnackbar('Request sent', { variant: 'success' }))
      .then(() => history.push('/serviceRequests/my'))

  return (
    <>
      <NavBar title="Request service" goBackBtn />
      <PageContainer>
        <Section title="Request service">
          <CreateServiceRequestForm onSubmit={handleSubmit} />
        </Section>
      </PageContainer>
    </>
  )
}
