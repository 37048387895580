import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { SchoolListView } from './list/SchoolListView'
import { CreateSchoolView } from './create/CreateSchoolView'
import { UpdateSchoolView } from './update/UpdateSchoolView'

export const SchoolsRootView = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/create`} component={CreateSchoolView} />
      <Route path={`${path}/:school_id/edit`} component={UpdateSchoolView} />
      <Route component={SchoolListView} />
    </Switch>
  )
}
