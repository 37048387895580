import React, { useState } from 'react'
import NavBar from '../../components/ui/panel/nav-bar/NavBar'
import { useCurrentUser } from '../../graphql/hooks/useCurrentUser/useCurrentUser'
import { AdminDashboard } from './types/admin/AdminDashboard'
import { DashboardMenu } from '../../components/ui/dashboard/menu/DashboardMenu'
import { TherapistDashboard } from './types/therapist/TherapistDashboard'
import { ParentDashboard } from './types/ParentDashboard'
import { Role } from '../../graphql/hooks.generated'
import { TeleteachersAdminDashboard } from './types/TeleteachersAdminDashboard'
import ArtboardBG from '../../components/ui/artboard-bg/ArtboardBG'
import { DashboardType } from '../../types'
import { getTherapyLogUrl } from '../../helpers/getTherapyLogUrl'

interface SelectedDashboardProps {
  type: DashboardType
}
const SelectedDashboard = (props: SelectedDashboardProps) => {
  const { type } = props
  localStorage.setItem('user', type)
  switch (type) {
    case DashboardType.ADMIN:
      return <AdminDashboard />
    case DashboardType.TELETEACHERS_ADMIN:
      return <TeleteachersAdminDashboard />
    case DashboardType.THERAPIST:
      return <TherapistDashboard />
    case DashboardType.AGENCY_SUPERVISOR_PROVIDER:
      return <TherapistDashboard />
    case DashboardType.AGENCY_SUPERVISOR:
      return <TherapistDashboard />
    case DashboardType.PARENT:
      return <ParentDashboard />
    default:
      return null
  }
}

export const DashboardView = () => {
  const { hasAnyRole } = useCurrentUser()
  const navOptions = [
    {
      label: 'Messages',
      path: '/messages'
    }
  ]
  const dashboardTypes: DashboardType[] = []

  if (hasAnyRole(Role.TeleteachersAdmin)) dashboardTypes.push(DashboardType.TELETEACHERS_ADMIN)
  if (hasAnyRole(Role.TeleteachersAdmin, Role.SchoolAdmin)) dashboardTypes.push(DashboardType.ADMIN)
  if (hasAnyRole(Role.TeleteachersAdmin, Role.SchoolAdmin, Role.Therapist, Role.SchoolTherapist))
    dashboardTypes.push(DashboardType.THERAPIST)
  if (hasAnyRole(Role.AgencySupervisorProvider, Role.Therapist, Role.SchoolTherapist))
    dashboardTypes.push(DashboardType.AGENCY_SUPERVISOR_PROVIDER)
  if (hasAnyRole(Role.AgencySupervisor, Role.SchoolAdmin, Role.Therapist, Role.SchoolTherapist))
    dashboardTypes.push(DashboardType.AGENCY_SUPERVISOR)
  if (hasAnyRole(Role.TeleteachersAdmin, Role.SchoolAdmin, Role.Parent))
    dashboardTypes.push(DashboardType.PARENT)

  const [selectedDashboardType, setSelectedDashboardType] = useState<DashboardType>(
    dashboardTypes[0]
  )

  if (hasAnyRole(Role.SchoolAdmin, Role.Therapist, Role.SchoolTherapist))
    navOptions.push({
      label: 'Caseload',
      path: getTherapyLogUrl(`/caseload`)
    })

  return (
    <ArtboardBG>
      {/* <NavBar
        title={
          <DashboardMenu
            selectedOption={selectedDashboardType}
            onOptionSelected={setSelectedDashboardType}
            options={dashboardTypes}
          />
        }
        // navOptions={navOptions}
      /> */}
      <SelectedDashboard type={selectedDashboardType} />
    </ArtboardBG>
  )
}
