import React from 'react'
import { Grid } from '@material-ui/core'
import { TimeOwed } from '../../../graphql/hooks.generated'
import DashboardCard, { DashboardCardProps } from './cards/dashboard/DashboardCard'
import MessageCard from './cards/message/MessageCard'
import TimeOwedCard from './cards/metric/TimeOwedCard'
import { MyGeneratedReports } from '../my-generated-reports/MyGeneratedReports'
import { PageContainer } from '../panel/page-container/PageContainer'
import useStyles from './Styles'

export type DashboardCardOptions = DashboardCardProps & { visible?: boolean }

export interface DashboardProps {
  cards: Array<DashboardCardOptions>
  children?: React.ReactNode
  showGeneratedReports?: boolean
  timeOwed?: TimeOwed
}

type DashboardCardListProps = {
  cards: DashboardCardOptions[]
}

const DashboardCardList = ({ cards }: DashboardCardListProps) => (
  <Grid item sm={12}>
    <Grid container spacing={3}>
      {cards
        .filter((card) => card.visible ?? true)
        .map((card) => (
          <Grid item sm={12} className="dashboard-card" key={`dashboard-cards-${card.title}`}>
            <DashboardCard icon={card.icon} title={card.title} handler={card.handler} />
          </Grid>
        ))}
    </Grid>
  </Grid>
)
const Dashboard: React.FC<DashboardProps> = (props: DashboardProps) => {
  const { cards = [], children, showGeneratedReports = true, timeOwed = {} } = props
  const classes = useStyles()
  // console.log('cards--', cards)

  return (
    <div className={classes.root}>
      <PageContainer>
        <Grid container alignItems="flex-start" spacing={3}>
          <Grid item md={8} sm={12}>
            <Grid container spacing={3}>
              {children}
              <DashboardCardList cards={cards} />
              {showGeneratedReports && (
                <Grid item sm={12}>
                  <MyGeneratedReports />
                </Grid>
              )}
            </Grid>
          </Grid>

          {cards.length > 0 && (
            <Grid item md={4} sm={12}>
              <Grid container spacing={3}>
                <Grid item sm={12}>
                  <MessageCard />
                </Grid>
                {timeOwed.minutes && timeOwed.studentsCount ? (
                  <Grid item sm={12}>
                    <TimeOwedCard {...timeOwed} />
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          )}
          {cards.length === 0 && (
            <>
              <Grid item md={3} sm={false} />
              <Grid item md={6} sm={12}>
                <MessageCard />
              </Grid>
            </>
          )}
        </Grid>
      </PageContainer>
    </div>
  )
}

export default Dashboard
