import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { CreateUserView } from './create/CreateUserView'
import { UserListView } from './list/UserListView'
import { UpdateUserView } from './update/UpdateUserView'

export const UsersRootView = () => {
  const { url } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${url}/create`} component={CreateUserView} />
      <Route path={`${url}/:id/edit`} component={UpdateUserView} />
      <Route component={UserListView} />
    </Switch>
  )
}
