import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link
} from '@material-ui/core'
import { format } from 'date-fns'
import React from 'react'
import {
  MyGeneratedReportsQuery,
  useDeleteReportMutation,
  useMyGeneratedReportsQuery
} from '../../../graphql/hooks.generated'
import Paper from '../panel/paper/Paper'
import { useStyles } from './Styles'
import { TableCell } from '../panel/table/cell/TableCell'
import { useAuth } from '../../../hooks/useAuth'
import { getTherapyLogUrl } from '../../../helpers/getTherapyLogUrl'

interface GeneratedReportRowProps {
  report: NonNullable<MyGeneratedReportsQuery['myGeneratedReports']>[0]
  onDelete?: () => void
}

const GeneratedReportRow = (props: GeneratedReportRowProps) => {
  const { report, onDelete } = props
  const [executeDelete] = useDeleteReportMutation()
  const onDeleteReport = async () => {
    await executeDelete({ variables: { id: report.id } })
    onDelete?.()
  }

  return (
    <TableRow>
      <TableCell>
        <Link color="inherit" href={getTherapyLogUrl(`/reports/${report.id}/download`)}>
          {report.fileName}
        </Link>
      </TableCell>
      <TableCell>{format(new Date(report.createdAt), 'MM/dd/yyyy')}</TableCell>
      <TableCell align="right">
        <Link color="inherit" component="button" onClick={onDeleteReport}>
          Delete
        </Link>
      </TableCell>
    </TableRow>
  )
}

export const MyGeneratedReports = () => {
  const styles = useStyles()
  const { data, refetch } = useMyGeneratedReportsQuery()

  if (!data || !data.myGeneratedReports?.length) return null

  return (
    <TableContainer square component={Paper}>
      <div className={styles.title}>
        <Typography variant="h5">
          <Link color="inherit" href={getTherapyLogUrl(`/reports`)}>
            Generated Reports
          </Link>
        </Typography>
      </div>
      <Table>
        <TableHead classes={{ root: styles.headRoot }}>
          <TableRow>
            <TableCell>REPORT</TableCell>
            <TableCell>CREATED</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.myGeneratedReports?.map((report) => (
            <GeneratedReportRow key={report.id} report={report} onDelete={refetch} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
