/* eslint-disable react/destructuring-assignment */
import React, { createContext, useContext, useReducer, useState } from 'react'
import { TwilioError } from 'twilio-video'
import { RecordingRules, RoomType } from '../types'
import {
  settingsReducer,
  initialSettings,
  Settings,
  SettingsAction
} from './settings/settingsReducer'
import useActiveSinkId from './useActiveSinkId/useActiveSinkId'

export interface StateContextType {
  error: TwilioError | Error | null
  setError(error: TwilioError | Error | null): void
  getToken(
    name: string,
    room: string,
    passcode?: string
  ): Promise<{
    room_type: RoomType
    token: string
    status: number
    room: string
    user_identity: string
  }>
  user?: null | { displayName: undefined; photoURL: undefined; passcode?: string }
  signIn?(passcode?: string): Promise<void>
  signOut?(): Promise<void>
  isAuthReady?: boolean
  isFetching: boolean
  activeSinkId: string
  setActiveSinkId(sinkId: string): void
  settings: Settings
  dispatchSetting: React.Dispatch<SettingsAction>
  roomType?: RoomType
  auth_token: string
  setAuthToken(token: string): void
  // eslint-disable-next-line @typescript-eslint/ban-types
  updateRecordingRules(room_sid: string, rules: RecordingRules): Promise<object>
}

export const StateContext = createContext<StateContextType>(null!)

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
// eslint-disable-next-line @typescript-eslint/ban-types
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null)
  const [isFetching, setIsFetching] = useState(false)
  const [activeSinkId, setActiveSinkId] = useActiveSinkId()
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings)
  const [roomType, setRoomType] = useState<RoomType>()
  const [name, setName] = useState<string>('')
  const [roomName, setRoomName] = useState<string>('')
  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomType
  } as StateContextType
  contextValue = {
    ...contextValue,
    getToken: async (user_identity, room_name) => {
      const endpoint = `${process.env.REACT_APP_API_URL}/video/validateToken`
      const TOKENS_ITEM_KEY = 'authentication:tokens'
      const localTokensJSON = localStorage.getItem(TOKENS_ITEM_KEY) || ''

      return fetch(endpoint, {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          user_identity,
          room_name
        })
      })
        .then((res: Response) => {
          return res.json()
        })
        .catch((err) => setError(err))
    }
  }
  const getToken: StateContextType['getToken'] = (name, room) => {
    setIsFetching(true)

    return contextValue
      .getToken(name, room)
      .then((res) => {
        if (res.status <= 400) {
          setRoomType(res.room_type)
          setRoomName(res.room)
          setName(res.user_identity)
        } else return Promise.reject(res)

        setIsFetching(false)

        return res
      })
      .catch((err) => {
        setError(err.error)
        setIsFetching(false)

        return Promise.reject(err.error)
      })
  }

  return (
    <StateContext.Provider value={{ ...contextValue, getToken }}>
      {props.children}
    </StateContext.Provider>
  )
}

export function useAppState() {
  const context = useContext(StateContext)

  if (!context) throw new Error('useAppState must be used within the AppStateProvider')

  return context
}
