import ServiceProvider from './services/services-provider'

export async function getToken(data: { user_email: string }) {
  try {
    const response = await ServiceProvider.validateToken(data)

    return response
  } catch (error) {
    return error as string
  }
}

export const getWaitList = async (userId: any, token: any) => {
  try {
    const res = await ServiceProvider.checkChatAuthentication(userId, token)

    return res
  } catch (e) {
    return e as string
  }
}
