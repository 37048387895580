import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { IcdCodeListView } from './list/IcdCodeListView'
import { CreateIcdCodeView } from './create/CreateIcdCodesView'
import { UpdateIcdCodeView } from './update/UpdateIcdCodeView'

export const IcdCodesRootView = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/create`} component={CreateIcdCodeView} />
      <Route path={`${path}/:id/edit`} component={UpdateIcdCodeView} />
      <Route component={IcdCodeListView} />
    </Switch>
  )
}
