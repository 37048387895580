/* eslint-disable */
/* eslint-disable react/prop-types */

import React, { useMemo, useState, useEffect } from 'react'
import moment from 'moment'
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs'
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons'
import Button from '../../../../components/ui/panel/button/Button'
import { useCancelAppointmentMutation } from '../../../../graphql/hooks.generated'
import { useSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'
const InputField = ({ title, value }) => {
  const copyClipBoard = () => {
    const el = document.createElement('input')
    el.value = value
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    // textAreaRef?.current?.select()
  }

  return (
    <React.Fragment>
      <div className="field-name-div p-x">
        <p className="field-name" style={{ width: '15%' }}>
          {title}
        </p>
        <p style={{ padding: '0 20px' }}>: </p>
        {title === 'Link' && (
          <>
            {value && (
              <>
                <a
                  href={value}
                  style={{
                    wordBreak: 'break-word',
                    paddingTop: '15px',
                    width: '215px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {value}
                </a>
                <span className="e-icons e-copy" onClick={() => copyClipBoard()}></span>
              </>
            )}
            {!value && <p>-</p>}
          </>
        )}
        {title === 'Name' && (
          <a style={{ wordBreak: 'break-word', paddingTop: '15px', width: '75%' }}>{value}</a>
        )}
        {title === 'Address' && (
          <div style={{ wordBreak: 'break-word', paddingTop: '15px', width: '75%' }}>
            <span>{`${value?.address}, ${value?.city}, ${value?.name}`}</span>
            <span>{`${value?.state}, ${value?.zipcode}`}</span>
          </div>
        )}
        {/* <input id="Summary"  readOnly = {true} value={cellData?.Subject} className="e-field e-input " type="text" placeholder=" Title here" data-name="Summary" style={{ width: '100%' }}/> */}
      </div>
    </React.Fragment>
  )
}
const CancelAppointmentDialog = ({ cellData, onChange }) => {
  const _deleteLoadingMsg = 'Please wait Apointmentis canceling'
  const [cancelAppointment] = useCancelAppointmentMutation()
  const { enqueueSnackbar } = useSnackbar()
  const [note, setNote] = useState(null)
  const [sendMail, setSendMail] = useState(true)
  const [noteValid, setNoteValid] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const onchangeHandel = (key, value) => {
    if (key === 'note') {
      setNote(value?.value)
      if (key === 'note' && value?.value?.length > 0) setNoteValid(false)
      else setNoteValid(true)
    }
    if (key === 'mail') setSendMail(value?.target?.checked)
  }

  const cancelUpdateAppointment = () => {
    if (note?.length > 0) {
      setDeleteLoading(true)
      cancelAppointment({
        variables: { id: cellData?.id, input: { note }, sendMail: sendMail }
      }).then((response) => {
        setDeleteLoading(false)

        enqueueSnackbar('Appointmnet Canceled', { variant: 'success' })
        onChange('canceled')
      })
    } else setNoteValid(true)
  }

  return (
    <>
      {deleteLoading && (
        <div className="scheduleLoader" style={{ display: 'flex', padding: '30px' }}>
          <CircularProgress />
          <p style={{ textAlign: 'center', marginLeft: '40px' }}>{_deleteLoadingMsg}</p>
        </div>
      )}
      {!deleteLoading && (
        <>
          <div className="field-name-div p-x">
            <span className="field-name" style={{ width: '15%' }}>
              Provider
            </span>
            <span className="p-r-10">:</span>
            <span>{`${cellData?.user_id?.first_name} ${cellData?.user_id?.last_name}`}</span>
          </div>
          <div className="field-name-div p-x">
            <span className="field-name" style={{ width: '15%' }}>
              Slot
            </span>
            <span className="p-r-10">:</span>
            <span>{`${moment(cellData?.StartTime).format('MM/D/YYYY hh:mm A')} - ${moment(
                cellData?.EndTime
              ).format('hh:mm A')}`}</span>
          </div>
          <div className="cancelContainer">
            <div style={{ width: '100%' }}>
              <TextBoxComponent
                className="p-x"
                id="EventNotes"
                data-name="EventNotes"
                multiline
                placeholder="Enter Notes"
                style={{ marginBottom: ' 15px' }}
                //   value={addEditData?.note}
                onChange={(value) => onchangeHandel('note', value)}
              />
              {noteValid && <p style={{ margin: '0px', color: '#f44336' }}>Please Enter Notes</p>}
            </div>
            <CheckBoxComponent
              label="Send Email"
              onChange={(value) => onchangeHandel('mail', value)}
              checked
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                color="primary"
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="mar-rt-15 m-t-5 m-b-20"
                variant="outlined"
                size="medium"
                style={{ height: '36px' }}
                onClick={() => onChange('back')}
              >
                Back
              </Button>
              <Button
                color="primary"
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="mar-rt-15 m-t-5 m-b-20"
                variant="contained"
                size="medium"
                style={{ height: '36px' }}
                // onClick={() => submitCreateAppointment()}
                onClick={() => cancelUpdateAppointment()}
              >
                Cancel Appointment
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default CancelAppointmentDialog
