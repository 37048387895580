import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { StudentListView } from './list/StudentListView'
import { CreateStudentView } from './create/CreateStudentView'
import { UpdateStudentView } from './update/UpdateStudentView'
import { ActiveproviderView } from './ActiveProviders'
import { AppointmentsRootView } from '../appointments/AppointmentsRootView'

export const StudentsRootView = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/create`} component={CreateStudentView} />
      <Route path={`${path}/:id/providers`} component={ActiveproviderView} />
      <Route path={`${path}/:id/appointments`} component={AppointmentsRootView} />
      <Route path={`${path}/:student_id/edit`} component={UpdateStudentView} />
      <Route component={StudentListView} />
    </Switch>
  )
}
