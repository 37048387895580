import React from 'react'
import { Select, SelectProps } from 'mui-rff'
import { MenuItem } from '@material-ui/core'
import { useStyles } from '../Styles'

export interface DictionarySelectProps extends SelectProps {
  optionsDictionary: Record<string, string>
  options?: string[]
}

export const DictionarySelect = (props: DictionarySelectProps) => {
  const { options, optionsDictionary, ...rest } = props
  const classes = useStyles()

  return (
    <Select variant="filled" {...rest} className={classes.selectDropdown}>
      {(options ?? Object.keys(optionsDictionary)).map((option) => (
        <MenuItem key={option} value={option}>
          {optionsDictionary[option]}
        </MenuItem>
      ))}
    </Select>
  )
}
