const listFilters = {
  searchText: '',
  currentPage: 0,
  pageSize: 0,
  columnName: '',
  columnDirection: ''
}
const initialState = {
  providerlistq: listFilters,
  userlistq: listFilters,
  clientlistq: listFilters,
  agencylistq: listFilters,
  ongngcarelistq: listFilters,
  waitlistq: listFilters,
  districtlistq: listFilters,
  servicelistq: listFilters,
  schoolslistq: listFilters,
  noteslistq: listFilters,
  caseloadlistq: listFilters,
  caseloadid: ''
}

export const SearchFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SEARCHFILTER':
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
