/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState, useMemo } from 'react'
import { Button, InputLabel, Grid, MenuItem } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Select, makeValidate, TextField } from 'mui-rff'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Field, Form, FormRenderProps } from 'react-final-form'
import * as yup from 'yup'
import {
  useCurrentUserQuery,
  useAdminUsersListQuery,
  useCreateWaitlistAssignMutation
} from 'graphql/hooks.generated'
import moment from 'moment'

interface CreateAssignFormModel {
  assignTo: any
}

export interface AssignFormProps {
  open: boolean
  handleClose: () => unknown
  waitlistobj: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiOutlinedInput-input': {
        padding: '11px 14px',
        backgroundColor: '#fff !important',
        border: '1px solid #000'
      },
      '& .MuiFormHelperText-root.Mui-error': {
        display: 'none'
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderWidth: '1px'
      }
    },
    labelField: {
      '& .MuiFilledInput-input': {
        backgroundColor: '#fff !important',
        border: '1px solid #000',
        padding: '11px 12px 10px'
      },
      '& .MuiSelect-filled.MuiSelect-filled': {
        backgroundColor: '#fff !important'
      }
    },
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff !important'
    },
    '& .MuiSelect-filled.MuiSelect-filled': {
      backgroundColor: '#fff !important'
    },
    fs20: {
      fontSize: '20px'
    },
    colBlak: {
      color: '#000'
    },
    paddingrt: {
      paddingRight: '10px'
    },
    pleft: {
      paddingLeft: '10px'
    },
    fleft: {
      float: 'left'
    },
    iBlock: {
      display: 'inline-block'
    },
    fright: {
      float: 'right'
    },
    contpadd: {
      padding: '15px 15px 0px 10px'
    },
    mAuto: {
      margin: 'auto'
    },
    relative: {
      position: 'relative'
    },
    errorCol: {
      color: '#f44336'
    },
    fForm: {
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)'
      }
    }
  })
)
const addAssignvalidator = () =>
  makeValidate(
    yup.object<CreateAssignFormModel>({
      assignTo: yup.mixed<string>().label('Assign To').required('Required')
    })
  )

export const AssignForm = (props: AssignFormProps) => {
  const classes = useStyles()
  const { open, handleClose, waitlistobj } = props
  const { data: adminList, loading: adminlistloading } = useAdminUsersListQuery({
    fetchPolicy: 'network-only'
  })
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const [createAssignmutation, { loading: assigntoloading }] = useCreateWaitlistAssignMutation()
  const validator = useMemo(() => addAssignvalidator(), [])
  const age = moment().diff(moment(waitlistobj?.student_id?.birth_date, 'YYYY-MM-DD'), 'years')
  const handleFormSubmit = async (values: CreateAssignFormModel) => {
    // console.log('Values', values)
    let assigneduserArr

    if (adminList)
      assigneduserArr = adminList?.adminUsersList?.filter((item) => item?.id === values?.assignTo)

    const { name } = assigneduserArr?.find(() => true)
    const inputobj = {
      waitlist_id: waitlistobj?.id,
      assigned_user_id: values?.assignTo,
      updated_by: currentUserdata?.data?.currentUser?.id,
      assigned_user_name: name || ''
    }
    try {
      await createAssignmutation({
        variables: {
          input: inputobj
        }
      })
      handleClose()
    } catch (e) {
      console.log(e)
    }
  }
  const renderForm = (renderProps: FormRenderProps<CreateAssignFormModel>) => {
    const { handleSubmit, submitFailed, errors } = renderProps

    return (
      <>
        <Dialog
          fullWidth
          open={open}
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div className={classes.fs20}>Assign</div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid container xs={12} md={12} sm={12} lg={12} className={classes.fForm}>
                <Grid item md={12}>
                  <Grid container md={12} className={`${classes.contpadd}`}>
                    <Grid item md={4} className={classes.mAuto}>
                      <InputLabel
                        htmlFor="clientName"
                        className={`${classes.colBlak} ${classes.iBlock}`}
                      >
                        Client Name
                      </InputLabel>
                      <div
                        className={`${classes.colBlak} ${classes.iBlock} ${classes.paddingrt} ${classes.fright}`}
                      >
                        :
                      </div>
                    </Grid>
                    <Grid item md={8} className={classes.root}>
                      <div className={`${classes.colBlak} ${classes.pleft}`}>
                        {waitlistobj?.name || '---'}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container md={12} className={classes.contpadd}>
                    <Grid item md={4}>
                      <InputLabel
                        htmlFor="contactDetails"
                        className={`${classes.colBlak} ${classes.iBlock}`}
                      >
                        Contact Details
                      </InputLabel>
                      <div
                        className={`${classes.colBlak} ${classes.iBlock}  ${classes.paddingrt} ${classes.fright}`}
                      >
                        :
                      </div>
                    </Grid>
                    <Grid item md={8} className={classes.root}>
                      <div className={`${classes.colBlak} ${classes.pleft}`}>
                        <div>Email - {waitlistobj?.student_id?.contact_email || '---'}</div>
                        <div>Phone - {waitlistobj?.student_id?.contact_phone_number || '---'}</div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container md={12} className={classes.contpadd}>
                    <Grid item md={4} className={classes.mAuto}>
                      <InputLabel htmlFor="age" className={`${classes.colBlak} ${classes.iBlock}`}>
                        Age
                      </InputLabel>
                      <div
                        className={`${classes.colBlak} ${classes.iBlock}  ${classes.paddingrt} ${classes.fright}`}
                      >
                        :
                      </div>
                    </Grid>
                    <Grid item md={8} className={classes.root}>
                      <div className={`${classes.colBlak} ${classes.pleft}`}>
                        {age} {age > 1 ? 'Years' : 'Year'}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container md={12} className={classes.contpadd}>
                    <Grid item md={4} className={classes.mAuto}>
                      <InputLabel
                        htmlFor="insurance"
                        className={`${classes.colBlak} ${classes.iBlock}`}
                      >
                        Insurance
                      </InputLabel>
                      <div
                        className={`${classes.colBlak} ${classes.iBlock}  ${classes.paddingrt} ${classes.fright}`}
                      >
                        :
                      </div>
                    </Grid>
                    <Grid item md={8} className={classes.root}>
                      <div className={`${classes.colBlak} ${classes.pleft}`}>
                        {waitlistobj?.student_id?.insurance_company?.name || '---'}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container md={12} className={classes.contpadd}>
                    <Grid item md={4} className={classes.mAuto}>
                      <div
                        className={`${
                          submitFailed && errors?.assignTo?.length
                            ? classes.errorCol
                            : classes.colBlak
                        } ${classes.fleft}`}
                      >
                        <InputLabel
                          htmlFor="assignTo"
                          className={`${classes.iBlock} ${
                            submitFailed && errors?.assignTo?.length
                              ? classes.errorCol
                              : classes.colBlak
                          }`}
                        >
                          Assign To
                        </InputLabel>
                        *
                      </div>
                      <div
                        className={`${classes.colBlak} ${classes.iBlock} ${classes.paddingrt} ${classes.fright}`}
                      >
                        :
                      </div>
                    </Grid>
                    <Grid item md={8} className={`${classes.root} ${classes.pleft}`}>
                      {adminList && (
                        <Select
                          name="assignTo"
                          labelId="assignto"
                          id="label-provider"
                          required
                          variant="outlined"
                        >
                          {adminList?.adminUsersList?.map((user) => (
                            <MenuItem key={user.id} value={user?.id?.toString()}>
                              {user?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              variant="outlined"
              className="m-t-5 m-b-20"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              aria-controls="simple-menu"
              aria-haspopup="true"
              className="mar-rt-15 m-t-5 m-b-20"
              variant="contained"
              size="medium"
              style={{ height: '36px' }}
              onClick={() => handleSubmit()}
            >
              Assign
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  return (
    <>
      {(adminlistloading || assigntoloading) && <ProgressIndicator fullHeight />}
      <Form<CreateAssignFormModel>
        render={renderForm}
        validate={validator}
        // initialValues={initialObj}
        onSubmit={handleFormSubmit}
      />
    </>
  )
}
