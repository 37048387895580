import React from 'react'
import { SelectProps } from 'mui-rff'
import { UserGenderDescription } from '../../../../views/private/serviceRequests/util/enumDescription'
import { UserGender } from '../../../../graphql/hooks.generated'
import { DictionarySelect } from '../dictionary-select/DictionarySelect'

const options = Object.values(UserGender)

type UserGenderSelectProps = SelectProps

export const UserGenderSelect = (props: UserGenderSelectProps) => (
  <DictionarySelect optionsDictionary={UserGenderDescription} options={options} {...props} />
)
