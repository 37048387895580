import React from 'react'
import { CaseloadType, useStudentQuery } from '../../../../graphql/hooks.generated'
import { AssignmentForm, AssignmentFormProps } from './AssignmentForm'

type StudentAssignmentFormProps = {
  student: string
  // type?: number
}

export const StudentAssignmentForm = (props: StudentAssignmentFormProps & AssignmentFormProps) => {
  const { student, onSubmit, submitLabel } = props
  const { data } = useStudentQuery({ variables: { id: parseInt(student) } })

  if (data)
    return (
      <AssignmentForm
        initialStudents={[data.student]}
        initialValues={{ studentId: data.student.id.toString() }}
        onSubmit={onSubmit}
        submitLabel={submitLabel}
      />
    )

  return null
}
