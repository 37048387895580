import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import {
  Box,
  Paper,
  styled,
  Typography,
  Grid,
  Icon,
  Popover,
  Avatar,
  useTheme,
  Input,
  TextField,
  InputAdornment
} from '@material-ui/core'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import moment from 'moment'
import { tz } from 'moment-timezone'
import { PageContainer } from 'components/ui/panel/page-container/PageContainer'
import { useSnackbar } from 'notistack'
import {
  useCreateAppointmentListMutation,
  useUpdateAppointmentListMutation,
  useCreateWaitlistAppointmentMutation
  // useCreateAppointmentListMutation
} from '../../../../graphql/hooks.generated'
import EventDetails from '../component/eventDetails'
import FilterComponent from '../component/filterComponent'
import TherapistDetails from '../component/therapistDetails'
import Button from '../../../../components/ui/panel/button/Button'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import { useCurrentUser } from '../../../../graphql/hooks/useCurrentUser/useCurrentUser'
import { CreateAppointmentModal } from '../createappointmentmodal/createappointmentmodal'

interface UpdateProps {
  onChange: (value) => any
  initialValues: any
  userDetails: any
  userId: any
  admin: boolean
  cellData: any
  providerName: any
}

interface ActivateAccountFormModel {
  password: string
  passwordConfirmation: string
  aboutMe: any
}
interface SlotDropdownModel {
  name: string
  values: any
}

export const NewScheduleUpdate = (props: UpdateProps) => {
  const { onChange, initialValues, userDetails, userId, admin, cellData, providerName } = props
  const initialFiltrObj = {
    speciality_id: [],
    language_id: null,
    county_id: null,
    ages_id: null,
    insurance_id: null,
    service_id: null,
    type: false
  }
  const initialPagination = {
    offset: 1,
    limit: 5
  }
  const initialSearchValue =
    (admin && !cellData?.edit) || (admin && cellData?.edit) ? providerName : null
  const slot_created_msg = 'Appointment has been created.'
  const slot_reschedule_msg = 'Appointment has been rescheduled.'
  const { enqueueSnackbar } = useSnackbar()
  const { data: currentUserData } = useCurrentUser()
  const validateFeilds = ['Client', 'Service', 'slotSelected']
  const [submitData, setSubmitData] = useState<any>({})
  const [formatedData, setFormatedData] = useState<any>({})
  const [errorObject, setErrorObject] = useState<any>({})
  const [filterObject, setFilterObject] = useState<any>(initialFiltrObj)
  const [therapistList, setTherapistList] = useState<any>([])
  const [filterLoader, setFilterLoader] = useState(false)
  const [therapistLoader, setTherapistLoader] = useState(false)
  const [appointmentAddLoader, setAppointmentAddLoader] = useState(false)
  const [pagination, setPagination] = useState<any>(initialPagination)
  const [pageClicked, setPageClicked] = useState(false)
  const [textView, setTextView] = useState(false)
  const [clientDetails, setClientDetails] = useState<any>(undefined)
  const [searchValue, setSearchValue] = useState(initialSearchValue)
  const [filterSearch, setFilterSearch] = useState(null)
  const [studentId, setStudentId] = useState(null)
  const [serviceId, setServiceId] = useState(null)
  const [county, setCounty] = useState(null)
  const [values, setValues] = useState(false)
  const [openUnAvlModal, setOpenUnAvlModal] = useState(false)
  const theme = useTheme()
  const childRef = useRef<any>()
  const [createAppointmentList, { loading: createAppLoading }] = useCreateAppointmentListMutation()
  const [updateAppointment, { loading: updateAppLoading }] = useUpdateAppointmentListMutation()
  const [
    createWaitListAppointmentList,
    { loading: createWtLoading }
  ] = useCreateWaitlistAppointmentMutation()
  const isLoading = () =>
    appointmentAddLoader || createAppLoading || updateAppLoading || createWtLoading ? (
      <ProgressIndicator fullHeight />
    ) : (
      ''
    )
  const formatData = useCallback(() => {
    const formatObj = {
      Client: cellData?.EventStudents?.[0]?.id,
      title: cellData?.waitlist ? cellData?.EventStudents?.[0]?.name : cellData?.Subject,
      Service: cellData?.signal_service_id?.id || cellData?.appointment_type_id?.id,
      currentSlot: `${moment(cellData?.startTime).format('MM/D/YYYY hh:mm A')} - ${moment(
        cellData?.endTime
      ).format('hh:mm A')}`,
      edit: cellData?.edit,
      userId: cellData?.user_id?.id,
      userName: `${cellData?.user_id?.first_name}  ${cellData?.user_id?.last_name}`,
      clientName: cellData?.EventStudents?.[0]?.name,
      waitlist: cellData?.waitlist,
      serviceName: cellData?.signal_service_id?.name
    }
    setFormatedData(formatObj)
    setSubmitData(formatObj)
  }, [cellData])
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') setFilterSearch(searchValue)
  }
  const slotTime = (slot) => {
    const slplit = slot.time_slot.split('-')
    // const timeOffSet = `${slot.timeperiod}T${moment(slplit[0], ['h:mm A']).format('HH:mm:ss')}`
    // const timeZoneOffset = moment.tz(timeOffSet, 'US/Mountain').format('Z')
    const timeZoneOffset = moment.tz(slot.timeperiod, 'US/Mountain').format('Z')
    const mapsplit = slplit.map((itm) => {
      const momentStr = moment(itm, ['h:mm A']).format('HH:mm:ss')
      const dates = moment(slot.timeperiod).utc().format('YYYY-MM-DD')
      // const withDate = moment(`${slot.timeperiod} ${momentStr}`).format()
      const withDate = moment(`${dates} ${momentStr}`).format(
        `YYYY-MM-DDTHH:mm:ss${timeZoneOffset}`
      )

      return withDate
    })

    return mapsplit
  }
  const validateSubmitData = () => {
    let tmpObj = {}
    const validfields = validateFeilds.map((field) => {
      if (field !== 'slotSelected' && !submitData?.[field]) {
        tmpObj = { ...tmpObj, [field]: true }

        return true
      }
      if (field === 'slotSelected' && !submitData?.slotObj) {
        tmpObj = { ...tmpObj, slotSelected: true }

        return true
      }

      return false
    })
    setErrorObject(tmpObj)

    return validfields.some((itm) => itm)
  }
  const confirmMsg = (info, msg) => {
    if (info === 0) {
      onChange('reload')
      enqueueSnackbar(msg, { variant: 'success' })
    } else {
      enqueueSnackbar('Selected slot is already booked, Can you Plaese select another slot', {
        variant: 'info'
      })
      setSubmitData({ ...submitData, slotobj: null })
      setAppointmentAddLoader(false)
    }
  }
  const signalSubmitCreate = (action) => {
    const therapistId = userId || submitData?.therapistDetails?.id
    const therapistDistrictId = currentUserData?.currentUser?.district?.id
    const validate = validateSubmitData()

    if (!validate) {
      setAppointmentAddLoader(true)
      const startEndTime = slotTime(submitData?.slotObj)
      const temObj = {
        appointmentTypeId: '1',
        userId: submitData?.therapistDetails?.id,
        createdBy: therapistId,
        updatedBy: therapistId,
        districtId: Number(therapistDistrictId),
        students: submitData?.Client,
        title: submitData.title || submitData?.clientName,
        endDate: startEndTime[1],
        startDate: startEndTime[0],
        slot_date: submitData?.slotObj.timeperiod,
        slot_time: submitData?.slotObj.user_availability_id,
        week_day: submitData?.slotObj.weekday,
        signal_service_id: Number(submitData?.Service)
      }

      if (!cellData?.edit && !cellData?.waitlist)
        createAppointmentList({ variables: { input: temObj } }).then((response) => {
          confirmMsg(response?.data?.createAppointmentList?.info, slot_created_msg)
        })
      else if (cellData?.edit)
        updateAppointment({
          variables: {
            id: cellData.id,
            caseloadInsert: admin,
            input: { ...temObj, appointment_slot_id: cellData.appointment_slot_id }
          }
        }).then((response) => {
          confirmMsg(response?.data?.updateAppointmentList?.info, slot_reschedule_msg)
        })
      else if (cellData?.waitlist)
        createWaitListAppointmentList({ variables: { input: temObj } }).then((response) => {
          confirmMsg(response?.data?.createWaitlistAppointment?.info, slot_created_msg)
          // submitEditOccurenceAndSeriesAppointment('series')
        })
    }
  }
  const eventChangeHandle = (title, value) => {
    if (title === 'Service') setFilterObject({ ...filterObject, service_id: value })

    setSubmitData({
      ...submitData,
      [title]: value
    })
    setErrorObject({ ...errorObject, [title]: false })
  }
  useEffect(() => {
    if (cellData?.edit || cellData?.waitlist) formatData()
  }, [cellData, formatData])
  useEffect(() => {
    if ((providerName && !cellData?.edit) || (providerName && cellData?.edit))
      setFilterSearch(providerName)
  }, [providerName, cellData?.edit])
  useEffect(() => {
    if (filterSearch !== undefined && filterSearch !== null) childRef?.current()
  }, [filterSearch])
  const outsideAvailability = (e) => {
    setOpenUnAvlModal(true)
  }
  const handleclose = () => {
    setOpenUnAvlModal(false)
  }

  return (
    <>
      <PageContainer>
        {isLoading()}
        <div className="pd-25">
          <div style={{ border: '1px solid', padding: '10px', background: '#dae7f3' }}>
            <Typography variant="h3" style={{ color: '#f1583a', fontSize: '24px' }}>
              {cellData?.edit
                ? 'Reschedule Appointment'
                : cellData?.waitlist
                ? 'Waitlist Assignment'
                : 'Add Appointment'}
            </Typography>
            <EventDetails
              userDetails={userDetails}
              userId={userId}
              admin={admin}
              onChange={(title, value) => eventChangeHandle(title, value)}
              appointmentDetails={formatedData}
              studetentDetails={(clientDetails) => {
                setFilterObject({ ...filterObject, ...clientDetails })
                setClientDetails(clientDetails)
              }}
              filterLoading={(value) => setFilterLoader(value)}
              setSubmitDetails={(values) => setSubmitData(values)}
              errorObject={errorObject}
              waitlist={cellData?.waitlist}
              setStudentId={setStudentId}
              serviceId={serviceId}
              setServiceId={setServiceId}
              setCounty={setCounty}
              setValues={setValues}
            />
            {admin && (
              <FilterComponent
                onChange={(filterValues) => setFilterObject(filterValues)}
                filterObj={filterObject}
                setTextView={setTextView}
                therapistListData={(list) => setTherapistList(list)}
                loader={filterLoader}
                therapistLoading={(value) => setTherapistLoader(value)}
                ref={childRef}
                pagination={pagination}
                pageClicked={pageClicked}
                setPageClicked={() => setPageClicked(false)}
                client={submitData?.Client}
                clientDet={clientDetails}
                edit={cellData?.edit}
                waitlist={cellData?.waitlist}
                providerName={filterSearch}
                studentId={studentId}
                serviceId={serviceId}
                county={county}
                setCounty={setCounty}
                values={values}
                setValues={setValues}
                // setpagination={(value) => setPagination({ ...pagination, offset: value })}
              />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px',
              alignItems: 'center'
            }}
          >
            {textView && admin && (
              <div style={{ fontSize: '18px', fontWeight: 600 }}>
                <span style={{ padding: '2px 5px', borderRadius: '5px' }}>
                  Providers listed below do not have upcoming availability.
                </span>
              </div>
            )}
            {textView === false && (
              <div style={{ fontSize: '15px', fontWeight: 600 }}>
                <span style={{ background: '#ADD8E6', padding: '2px 5px', borderRadius: '5px' }}>
                  Blue
                </span>{' '}
                - Telehealth Only, &nbsp;&nbsp;&nbsp;
                <span style={{ background: '#90EE90', padding: '2px 5px', borderRadius: '5px' }}>
                  Green
                </span>{' '}
                - In Person Only, &nbsp;&nbsp;&nbsp;
                <span style={{ background: '#FFFF66', padding: '2px 5px', borderRadius: '5px' }}>
                  Yellow
                </span>{' '}
                - Both
              </div>
            )}
            {!textView && (
              <Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  size="medium"
                  style={{ marginTop: '2px' }}
                  disabled={!submitData?.Client}
                  onClick={(e) => outsideAvailability(e)}
                >
                  Schedule Outside Availability
                </Button>
              </Typography>
            )}
            {admin && (
              <TextField
                className="searchBox"
                id="input-with-icon-textfield"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  )
                }}
                placeholder="Provider/Agency Name"
                value={searchValue}
                onChange={(value) => setSearchValue(value.target.value)}
                onKeyDown={handleKeyDown}
              />
            )}
          </div>
          {serviceId && (
            <TherapistDetails
              userDetails={userDetails}
              userId={userId}
              admin={admin}
              onChange={(selectedSlot, selectedTherapist) => {
                setSubmitData({
                  ...submitData,
                  slotObj: selectedSlot,
                  therapistDetails: selectedTherapist
                })
                setErrorObject({ ...errorObject, slotSelected: false })
              }}
              errorObject={errorObject}
              filterObj={filterObject}
              therapistList={therapistList}
              therapistLoader={therapistLoader}
              pagination={(value) => {
                setPagination({ ...pagination, offset: value })
                setPageClicked(true)
              }}
              serviceId={serviceId}
            />
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              color="primary"
              aria-controls="simple-menu"
              aria-haspopup="true"
              className="mar-rt-15 m-t-5 m-b-20"
              variant="outlined"
              size="medium"
              style={{ height: '36px' }}
              onClick={() => onChange('cancel')}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              aria-controls="simple-menu"
              aria-haspopup="true"
              className="mar-rt-15 m-t-5 m-b-20"
              variant="contained"
              size="medium"
              style={{ height: '36px' }}
              // onClick={() => submitCreateAppointment()}
              onClick={() => signalSubmitCreate('Add')}
            >
              Save
            </Button>
          </div>
        </div>
      </PageContainer>
      {openUnAvlModal && currentUserData && (
        <CreateAppointmentModal
          open={openUnAvlModal}
          handleClose={handleclose}
          userId={
            admin
              ? cellData?.waitlist
                ? currentUserData.currentUser.id
                : userId
              : currentUserData.currentUser.id
          }
          submitdata={submitData}
          distId={currentUserData?.currentUser?.district?.id}
          istherapist={!admin}
          therapistName={currentUserData?.currentUser?.name}
          onChange={onChange}
          appointmentId={cellData?.id || ''}
          filterobj={filterObject}
          waitlist={cellData?.waitlist}
        />
      )}
    </>
  )
}
