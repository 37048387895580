/* eslint-disable */
/* eslint-disable react/prop-types */

import React, { useMemo, useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import Button from '../../../../components/ui/panel/button/Button'
import {
  useCountiesSelectLazyQuery,
  useSpecialitiesSelectLazyQuery,
  useAgesServedSelectLazyQuery,
  usePrimaryLanguageLazyQuery,
  useAddAppointmetnsListLazyQuery,
  useInsuranceSelectLazyQuery,
  useSignalSlotsLazyQuery
} from '../../../../graphql/hooks.generated'
import { useSnackbar } from 'notistack'
import { Grid, MenuItem, InputLabel, Select, Typography, Checkbox } from '@material-ui/core'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons'

// import { TextField, Select, DatePicker, makeValidate } from 'mui-rff'

const InputField = ({ title, value, defaultValue, onSelect, valueKey, lableKey }) => {
  return (
    <React.Fragment>
      <div className="field-name-div p-x">
        <Typography className="field-name" style={{ width: '30%' }}>
          {title}
        </Typography>
        <Typography style={{ padding: '0 15px' }}>: </Typography>
        {title === 'Speciality' && (
          <Select
            labelId="demo-customized-select-label"
            id="label-age"
            required
            className="eventDropdown"
            variant="filled"
            value={defaultValue || []}
            onChange={(value) => onSelect(title, value.target.value)}
            multiple
          >
            {value?.map((itm) => (
              <MenuItem value={itm[valueKey]}>{itm[lableKey]}</MenuItem>
            ))}
          </Select>
        )}
        {title !== 'Speciality' && (
          <Select
            labelId="demo-customized-select-label"
            id="label-age"
            required
            className="eventDropdown"
            variant="filled"
            value={Number(defaultValue)}
            onChange={(value) => onSelect(title, Number(value.target.value))}
          >
            {value?.map((itm) => (
              <MenuItem value={itm[valueKey]}>{itm[lableKey]}</MenuItem>
            ))}
          </Select>
        )}
      </div>
    </React.Fragment>
  )
}
const initialFiltrObj = {
  speciality_id: [],
  language_id: null,
  county_id: null,
  ages_id: null,
  insurance_id: null,
  search: null
}
interface FilterProps {
  onChange: (value) => any
  filterObj: any
  therapistListData: any
  loader: any
  therapistLoading: (value) => any
  pagination: any
  pageClicked: any
  setPageClicked: () => any
  client: any
  clientDet: any
  edit: any
  waitlist: any
  providerName: any
  studentId: any
  serviceId: any
  county: any
  setCounty: any
  values: any
  setValues: any
  setTextView: any
  // countyDefault: any
  // setpagination: (value) => any
}
const FilterComponent = forwardRef((props: FilterProps, ref) => {
  const {
    onChange,
    filterObj,
    therapistListData,
    loader,
    therapistLoading,
    pagination,
    pageClicked,
    setPageClicked,
    client,
    clientDet,
    edit,
    waitlist,
    providerName,
    studentId,
    serviceId,
    county,
    setCounty,
    values,
    setValues,
    setTextView
    // setpagination
  } = props
  const [filterObject, setFilteerObject] = useState(filterObj)
  const [slotTimes, setSlotTime] = useState<any>([])
  const [changeStatusList, setChangeStatusList] = useState(false)
  const ChangeType = (e) => {
    setChangeStatusList(e.target.checked)
    setFilteerObject({ ...filterObject, type: e.target.checked })
    if (e.target.checked === true) {
      setTextView(true)
    } else if (e.target.checked === false) {
      setTextView(false)
    }
    // if (!changeStatusList) {
    //   return
    // }
    // therapistFetch(filterObject, pagination)
    // const tempChangeStatusList = changeStatusList
    // therapistFetch(filterObject, pagination)
    // setChangeStatusList(tempChangeStatusList)
  }
  const [
    executeCountyQuery,
    { data: countys, loading: countyLoading }
  ] = useCountiesSelectLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [
    executeSpecialitiesQuery,
    { data: specialities, loading: specialitiesLoading }
  ] = useSpecialitiesSelectLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [
    executeInsuranceQuery,
    { data: insurance, loading: insuranceLoading }
  ] = useInsuranceSelectLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [
    executeAgesServedQuery,
    { data: agesServed, loading: agesServedLoading }
  ] = useAgesServedSelectLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [
    executeLanguagesQuery,
    { data: languages, loading: languagesLoading }
  ] = usePrimaryLanguageLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [executeSlotsDropDownQuery, { data: slotsDropdownData }] = useSignalSlotsLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [
    executeTherapistListQuery,
    { data: therapistList, loading: therapistLoader }
  ] = useAddAppointmetnsListLazyQuery({
    fetchPolicy: 'network-only'
  })
  const isLoading = () =>
    loader ||
    countyLoading ||
    specialitiesLoading ||
    insuranceLoading ||
    agesServedLoading ||
    languagesLoading ? (
      <ProgressIndicator fullHeight />
    ) : (
      ''
    )
  const fetchCounties = useMemo(() => () => executeCountyQuery(), [executeCountyQuery])
  const fetchSpecialities = useMemo(() => () => executeSpecialitiesQuery(), [
    executeSpecialitiesQuery
  ])
  const fetchAgesServed = useMemo(() => () => executeAgesServedQuery(), [executeAgesServedQuery])
  const fetchLanguages = useMemo(() => () => executeLanguagesQuery(), [executeLanguagesQuery])
  const fetchInsurance = useMemo(() => () => executeInsuranceQuery(), [executeInsuranceQuery])
  const therapistFetch = useMemo(
    () => (filterValues, pageSettings) =>
      executeTherapistListQuery({
        variables: {
          filter: filterValues,
          pagination: pageSettings
        }
      }),
    [executeTherapistListQuery]
  )

  const fetchSlots = useMemo(() => () => executeSlotsDropDownQuery(), [executeSlotsDropDownQuery])
  const ChangeHandel = (title, value) => {
    const tempObj = {
      ...filterObject,
      [title]: value
    }
    if (title === 'county_id') {
      setCounty(value)
    }
    onChange(tempObj)
    setFilteerObject({ tempObj })
  }
  const applyFilter = () => {
    const pageOptions = {
      offset: 1,
      limit: 5
    }
    const tmpFilter = {
      ...filterObject,
      service_id: Number(filterObject.service_id),
      search: providerName,
      county_id: county,
      type: changeStatusList
    }
    // setpagination(1)
    therapistFetch(tmpFilter, pageOptions)
  }
  useImperativeHandle(ref, () => applyFilter)
  useEffect(() => {
    fetchCounties()
    fetchSpecialities()
    fetchAgesServed()
    fetchLanguages()
    fetchInsurance()
    fetchSlots()
  }, [])
  // useEffect(() => {
  //   console.log('county', county, 'serviceId', serviceId)
  //   console.log('1234', serviceId)
  //   // if (values && serviceId === 2) {
  //   //   console.log('abcd', serviceId)
  //   //   setCounty(1)
  //   // } else {
  //   //   setCounty(county)
  //   // }

  // }, [setCounty, serviceId, values, county])
  useEffect(() => {
    if (therapistList?.addAppointmetnsList) {
      therapistLoading(true)
      therapistListData(therapistList?.addAppointmetnsList)
    }
  }, [therapistList?.addAppointmetnsList])
  useEffect(() => {
    if (filterObj) setFilteerObject({ ...filterObj })
  }, [filterObj])
  useEffect(() => {
    if (slotsDropdownData?.signalSlots) {
      const startSlots = []
      const endSlots = []
      const slots = slotsDropdownData?.signalSlots.map((slot) => {
        const split = slot?.time_slot?.split('-')
        const temObj = {
          startTime: split?.[0],
          endTime: split?.[1],
          value: slot.id
        }

        return temObj
      })
      setSlotTime(slots)
    }
  }, [slotsDropdownData?.signalSlots])
  useEffect(() => {
    therapistLoading(therapistLoader)
  }, [therapistLoader])
  useEffect(() => {
    if (pageClicked) {
      const tempObj = {
        ...filterObj,
        county_id: county,
        type: changeStatusList
      }
      therapistFetch(tempObj, pagination)
      setPageClicked()
    }
  }, [pagination])
  useEffect(() => {
    if ((!edit && !waitlist) || ((edit || waitlist) && clientDet)) applyFilter()
  }, [filterObject])
  const type = window.location.pathname
  // useEffect(() => {
  //   if ((edit || waitlist) && clientDet) {
  //     therapistFetch(filterObj, pagination)
  //   }
  // }, [clientDet])

  return (
    <form>
      <Typography variant="h3" style={{ color: '#f1583a', background: '#dae7f3' }}>
        {'Filter'}
      </Typography>
      {isLoading()}
      <Grid container className="detail-container filter-container" style={{ background: '#fff' }}>
        {/* <Grid item sm={3}>
          <InputField
            title={'Start Time'}
            value={slotTimes}
            valueKey={'value'}
            lableKey={'startTime'}
            onSelect={(field, value) => ChangeHandel('start_time', value)}
            defaultValue={filterObject?.start_time}
          />
        </Grid>
        <Grid item sm={3}>
          <InputField
            title={'End Time'}
            value={slotTimes}
            valueKey={'value'}
            lableKey={'endTime'}
            onSelect={(field, value) => ChangeHandel('end_time', value)}
            defaultValue={filterObject?.end_time}
          />
        </Grid> */}
        <Grid item sm={4}>
          <InputField
            title={'Speciality'}
            value={specialities?.specialities}
            onSelect={(field, value) => ChangeHandel('speciality_id', value)}
            defaultValue={filterObject?.speciality_id}
            valueKey={'id'}
            lableKey={'name'}
          />
        </Grid>
        <Grid item sm={4}>
          <InputField
            title={'County Served'}
            value={countys?.counties}
            onSelect={(field, value) => ChangeHandel('county_id', value)}
            defaultValue={county}
            valueKey={'id'}
            lableKey={'name'}
          />
        </Grid>
        <Grid item sm={4}>
          <InputField
            title={'Language'}
            value={languages?.primaryLanguage}
            valueKey={'id'}
            lableKey={'name'}
            onSelect={(field, value) => ChangeHandel('language_id', value)}
            defaultValue={filterObject?.language_id}
          />
        </Grid>
        <Grid item sm={4}>
          <InputField
            title={'Ages Served'}
            value={agesServed?.agesServed}
            valueKey={'id'}
            lableKey={'name'}
            onSelect={(field, value) => ChangeHandel('ages_id', value)}
            defaultValue={filterObject?.ages_id}
          />
        </Grid>
        <Grid item sm={4}>
          <InputField
            title={'Insurance Accepted'}
            value={insurance?.insurance}
            onSelect={(field, value) => ChangeHandel('insurance_id', value)}
            defaultValue={filterObject?.insurance_id}
            valueKey={'id'}
            lableKey={'name'}
          />
        </Grid>
        {type == '/waitlist' && (
          <Grid item sm={4}>
            <span style={{ paddingRight: '10px', fontSize: '26px' }}>
              <CheckBoxComponent type="checkbox" onChange={ChangeType} checked={changeStatusList} />
            </span>
            <span
              style={{
                fontSize: '16px',
                fontFamily: 'Brandon Gratosque !important'
              }}
            >
              Show providers with no availability
            </span>
          </Grid>
        )}
        <Grid item sm={8} className="filterButtons">
          {/* <Button
            color="primary "
            aria-controls="simple-menu"
            aria-haspopup="true"
            variant="outlined"
            size="medium"
            style={{ height: '36px' }}
            onClick={() => setFilteerObject(initialFiltrObj)}
            className="reset"
          >
            Reset
          </Button> */}
          {/* <Button
            color="primary"
            aria-controls="simple-menu"
            aria-haspopup="true"
            variant="contained"
            size="medium"
            className="filter-apply-button"
            style={{ height: '36px' }}
            onClick={() => applyFilter()}
          >
            Apply
          </Button> */}
        </Grid>
      </Grid>
    </form>
  )
})

export default FilterComponent
