import NavBar from 'components/ui/panel/nav-bar/NavBar'
import { PageContainer } from 'components/ui/panel/page-container/PageContainer'
import { useCreateServiceMutation } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useErrorHandler } from 'graphql/hooks/useErrorHandler/useErrorHandler'
import { ServiceForm, ServiceFormModel } from '../form/ServiceForm'

export const CreateServiceView = () => {
  const [createService] = useCreateServiceMutation()
  const handleError = useErrorHandler()
  const history = useHistory()
  const handleFormSubmit = (values: ServiceFormModel) =>
    createService({
      variables: {
        input: {
          ...values
        }
      }
    })
      .then(() => {
        history.push('/services')
      })
      .catch(handleError)

  return (
    <>
      <NavBar title="Add a Service" goBackBtn />
      <PageContainer>
        <ServiceForm onSubmit={handleFormSubmit} submitLabel="Create Sevice" />
      </PageContainer>
    </>
  )
}
