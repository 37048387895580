import React from 'react'
import { Select, SelectProps } from 'mui-rff'
import { MenuItem } from '@material-ui/core'
import { districtLevelOfServiceDescriptions } from '../../../../graphql/enumDescriptions'
import { useStyles } from '../Styles'

type DistrictLevelOfServiceSelectProps = SelectProps

export const DistrictLevelOfServiceSelect = (props: DistrictLevelOfServiceSelectProps) => {
  const classes = useStyles()

  return (
    <Select variant="filled" {...props} className={classes.selectDropdown}>
      {Object.keys(districtLevelOfServiceDescriptions).map((option) => (
        <MenuItem key={option} value={option}>
          {districtLevelOfServiceDescriptions[option]}
        </MenuItem>
      ))}
    </Select>
  )
}
