import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import { DistrictForm, DistrictsFormModel } from '../form/DistrictForm'
import {
  useUpdateDistrictMutation,
  useDistrictQuery,
  useDeleteDistrictMutation,
  useSchoolsQuery
} from '../../../../graphql/hooks.generated'
import { UseParamsType } from '../../../../types'

interface UpdateDistrictViewParams extends UseParamsType {
  id: string
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const UpdateDistrictView = () => {
  const history = useHistory()
  const { id } = useParams<UpdateDistrictViewParams>()
  const [updateDistrict] = useUpdateDistrictMutation()
  const { data } = useDistrictQuery({ variables: { id: Number(id) }, fetchPolicy: 'network-only' })
  const district = data?.district
  const { data: schools } = useSchoolsQuery({
    variables: { district_id: Number(id) },
    fetchPolicy: 'network-only'
  })
  const val = schools?.schools
  const [records, setRecords] = useState<any>()
  const [deleteDistrict] = useDeleteDistrictMutation()
  const onDelete = async () => {
    await deleteDistrict({ variables: { id } })
    history.goBack()
  }
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = async () => {
    // console.log(district)
    setOpen(true)

    if (val !== null && val !== undefined) {
      const datavalue = val.map((test, i) => {
        return test.status === 'ACTIVE'
      })
      // console.log(datavalue)
      await setRecords(datavalue)
    }
  }
  const handleClose = () => {
    setOpen(false)
    // console.log(records)
  }
  const deleteDialoge = () => {
    return (
      <>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure want to Delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button onClick={onDelete} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
  const showDialoge = () => {
    return (
      <>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Active Associated Schools Exists
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
  const handleTestOpen = () => {
    // console.log(schools?.schools)
    // console.log(val)

    if (val !== null && val !== undefined) {
      const data = val.map((test, i) => {
        return test.status
      })
      // console.log(data)
    }
  }
  const initialValues: DistrictsFormModel | null = district
    ? {
        allowConferencing: district.allow_conferencing,
        billTrackingIdEnabled: district.bill_tracking_records_enabled,
        enableProviderPins: district.provider_pins_enabled,
        therapyComponentEnabled: district.therapy_component_enabled,
        offerBillingQuestion: district.offer_billing_question,
        zip: district.zip,
        city: district.city,
        levelOfService: district.level_of_service,
        name: district.name,
        planTypes: district.planTypes,
        signatureRequired: district.signature_required,
        streetAddress: district.street_address,
        stateId: district.state?.id
      }
    : null

  return (
    <>
      <NavBar title="Update District" goBackBtn />
      <PageContainer>
        <DistrictForm
          submitLabel="Save"
          initialValues={initialValues}
          onSubmit={(values) => {
            // console.log(initialValues)
            updateDistrict({ variables: { id: Number(id), input: { ...values } } }).then(
              history.goBack
            )
          }}
        />
        <Button
          style={{ marginLeft: 259, marginTop: -73 }}
          size="large"
          variant="outlined"
          color="primary"
          onClick={handleClickOpen}
        >
          Delete
        </Button>
        {records && records[0] !== true ? deleteDialoge() : showDialoge()}
      </PageContainer>
    </>
  )
}
