import NavBar from 'components/ui/panel/nav-bar/NavBar'
import { PageContainer } from 'components/ui/panel/page-container/PageContainer'
import { useCreateStateMutation } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useErrorHandler } from 'graphql/hooks/useErrorHandler/useErrorHandler'
import { StateForm, StateFormModel } from '../form/StateForm'

export const CreateStateView = () => {
  const [createState] = useCreateStateMutation()
  const handleError = useErrorHandler()
  const history = useHistory()
  const handleFormSubmit = (values: StateFormModel) =>
    createState({
      variables: {
        input: {
          ...values
        }
      }
    })
      .then(() => {
        history.push('/')
      })
      .catch(handleError)

  return (
    <>
      <NavBar title="Add a state" goBackBtn />
      <PageContainer>
        <StateForm onSubmit={handleFormSubmit} submitLabel="Create state" />
      </PageContainer>
    </>
  )
}
