import { Grid, InputLabel, Box } from '@material-ui/core'
import { makeValidate, TextField, Checkboxes } from 'mui-rff'
import React, { useEffect, useState, useMemo } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Form, FormRenderProps } from 'react-final-form'
import { useHistory, useParams } from 'react-router-dom'
import { UseParamsType } from 'types'
import * as yup from 'yup'
import {
  useClientFillingAttemptsMutation,
  useClientMandatoryDownloadLazyQuery,
  useClientMandatoryDownloadQuery
} from 'graphql/hooks.generated'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { engLoc, spaLoc } from 'config/constants/translations'
import { pdf, PDFDownloadLink } from '@react-pdf/renderer'
import BackgroundImage from 'assets/illustrations/backgroundimatterimage.png'
import Button from '../../../components/ui/panel/button/Button'
import { PageContainer } from '../../../components/ui/panel/page-container/PageContainer'
import { InfoButton } from '../../../components/ui/panel/button/InfoButton'
import { Section } from '../../../components/ui/panel/section/Section'
import { MandatoryPdf } from './mandatoryPdf'

interface ClientFormParams extends UseParamsType {
  token: string
  language_id: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelField: {
      '& .MuiOutlinedInput-input': {
        padding: '11px 14px',
        backgroundColor: '#fff !important',
        border: '1px solid #000'
      },
      '& .MuiOutlinedInput-inputMultiline': {
        border: '1px solid #000'
      },
      '& .MuiOutlinedInput-multiline': {
        padding: '0px'
      },
      '& .MuiFormHelperText-root.Mui-error': {
        display: 'none'
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderWidth: '1px'
      }
    },
    contpadd: {
      padding: '0px 15px 0px 50px'
    },
    gridalign: {
      maxWidth: '700px',
      backgroundColor: '#FFFFFF',
      marginTop: '40px',
      marginLeft: '50px',
      marginBottom: '50px'
    },
    colBlak: {
      color: '#000',
      fontFamily: 'Brandon Grotesque !important'
    },
    iBlock: {
      display: 'inline-block',
      marginBottom: '10px'
    },
    errorCol: {
      color: '#f44336'
    },
    backgroundImage: {
      backgroundImage: `url(${BackgroundImage})`,
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      minHeight: 'calc(100vh - 60px - 75px)'
    },
    labelError: {
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)'
      }
    }
  })
)

export const ClientDisclosureFormDownload = () => {
  const history = useHistory()
  const classes = useStyles()
  const { token, language_id } = useParams<ClientFormParams>()
  let langcode

  if (language_id)
    if (language_id === '1') langcode = 'en'
    else langcode = 'es'
  else langcode = 'en'

  const { data, loading: mandateformloading } = useClientMandatoryDownloadQuery({
    fetchPolicy: 'network-only',
    variables: {
      token: String(token)
    }
  })
  const isLoading = () => (mandateformloading ? <ProgressIndicator fullHeight /> : '')

  return (
    <Grid container className={classes.backgroundImage}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '350px',
          fontSize: '20px',
          color: 'white',
          flex: 'auto'
        }}
      >
        <div style={{ background: 'white', borderRadius: '10px', padding: '20px' }}>
          <div style={{ color: 'black', display: 'inline-block' }}>
            {langcode === 'en'
              ? engLoc.clientFormDownload.headerTxt
              : spaLoc.clientFormDownload.headerTxt}
            {langcode === 'en' ? engLoc.clientFormDownload.text1 : spaLoc.clientFormDownload.text1}
            {data && data?.clientMandatoryDownload && (
              <PDFDownloadLink
                document={
                  <MandatoryPdf mandatoryDetails={data?.clientMandatoryDownload} loc={langcode} />
                }
                fileName="MANDATORY_DISCLOSURE_FORM.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading
                    ? 'wait loading your document...'
                    : `${
                        langcode === 'en'
                          ? engLoc.clientFormDownload.text2
                          : spaLoc.clientFormDownload.text2
                      }`
                }
              </PDFDownloadLink>
            )}
            {langcode === 'en' ? engLoc.clientFormDownload.text3 : spaLoc.clientFormDownload.text3}
          </div>
        </div>
      </div>
      {isLoading()}
    </Grid>
  )
}
