import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles(({ palette }: Theme) => ({
  labelText: {
    fontSize: '14px',
    color: '#2C9BD6',
    fontWeight: 'bold',
    fontFamily: 'Brandon Grotesque'
  },
  labelFieldNew: {
    backgroundColor: 'white !important',
    '& div': {
      border: '1px solid #000',
      backgroundColor: '#fff !important'
    },
    '& p.MuiFormHelperText-root': {
      color: 'red'
    },
    '.MuiFilledInput-root': {
      backgroundColor: '#fff !important'
    },
    '&.MuiFilledInput-adornedStart': {
      backgroundColor: '#fff !important',
      border: '1px solid #000',
      '.MuiFilledInput-root': {
        backgroundColor: '#fff'
      }
    }
  },
  labelField: {
    border: '1px solid #000',
    backgroundColor: '#fff !important',
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff !important',
      border: '1px solid #000'
    },
    '& .MuiSelect-filled.MuiSelect-filled': {
      backgroundColor: '#fff !important'
    }
  },
  labelFieldOne: {
    border: '1px solid #000',
    backgroundColor: '#fff !important',
    marginTop: '19px',
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff !important',
      border: '1px solid #000'
    },
    '& div': {
      '.MuiFilledInput-root': {
        backgroundColor: '#fff !important'
      }
    }
  },
  '&.MuiFilledInput-adornedStart': {
    backgroundColor: '#fff !important',
    border: '1px solid #000'
  },
  '&.MuiFilledInput-root': {
    backgroundColor: '#fff !important'
  },
  selectDropdown: {
    width: '100%',
    backgroundColor: '#fff',
    cursor: 'pointer',
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff !important',
      border: '1px solid #000'
    },
    '& .MuiSelect-filled.MuiSelect-filled': {
      backgroundColor: '#fff !important'
    }
  },
  title: {
    borderBottom: `1px dotted ${palette.divider}`,
    padding: '16px 32px',
    '& > *': {
      color: palette.info.main
    }
  }
}))
