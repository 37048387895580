import { Box, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import 'video-react/dist/video-react.css'
import { ResourceList } from './components/ResourceList'
import { ResourcesFilterBar, SearchInput } from './components/ResourcesFilterBar'
import NavBar from '../../components/ui/panel/nav-bar/NavBar'
import { PageContainer } from '../../components/ui/panel/page-container/PageContainer'
import { useStyles } from '../../components/ui/my-generated-reports/Styles'

export const ResourcesView = () => {
  const [searchInput, setSearchInput] = useState<SearchInput>()
  const classes = useStyles()

  return (
    <>
      <NavBar title="Resources" goBackBtn />
      <PageContainer>
        <div className={classes.plr10}>
          <Box style={{ paddingBottom: '16px' }}>
            <ResourcesFilterBar onSearch={setSearchInput} />
          </Box>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <ResourceList search={searchInput} />
            </Grid>
          </Grid>
        </div>
      </PageContainer>
    </>
  )
}
