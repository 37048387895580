import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { SessionContainer } from './containers/SessionContainer'
import { VideoProvider } from './view/video/components/VideoProvider'
import useConnectionOptions from './view/video/utils/useConnectionOptions/useConnectionOptions'
import AppStateProvider, { useAppState } from './view/video/state'

export const SessionRootView = () => {
  // const { path } = useRouteMatch()
  const { error, setError } = useAppState()
  const connectionOptions = useConnectionOptions()

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <Switch>
        {/* <Route path={`${path}/:id/sessions`} component={SessionContainer} /> */}

        <Route component={SessionContainer} />
      </Switch>
    </VideoProvider>
  )
}
