import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Snackbar from '@material-ui/core/Snackbar'
import { Button, Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import { useStyles } from '../../../../config/constants/theme/reports'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import {
  Role,
  useCreateProgressReportMutation,
  useCreateProviderMinutesReportMutation,
  useCurrentUserQuery,
  useReportusersQuery
} from '../../../../graphql/hooks.generated'

export default function ServiceProviderMinutesreport() {
  const classes = useStyles()
  const initialValues = {
    fromDate: '',
    toDate: '',
    fileType: 'excel',
    userId: '',
    therapistId: ''
  }
  const [open, setOpen] = useState(false)
  const [formInput, setFormInput] = useState(initialValues)
  const [studentsData] = useCreateProviderMinutesReportMutation()
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const history = useHistory()
  const { hasAnyRole } = useCurrentUser()
  const { data: therapistData } = useReportusersQuery({
    fetchPolicy: 'network-only',
    variables: {
      district_id: hasAnyRole(Role.TeleteachersAdmin)
        ? undefined
        : Number(currentUserdata?.data?.currentUser?.district?.id)
    }
  })
  const handleSubmit = (evt) => {
    evt.preventDefault()
    const data = {
      parameters: {
        from_date: formInput.fromDate,
        to_date: formInput.toDate,
        file_type: formInput.fileType,
        therapist_id: formInput.therapistId || currentUserdata.data?.currentUser.id,
        user_id: currentUserdata.data?.currentUser.id,
        created_by: currentUserdata.data?.currentUser.id
      }
    }
    studentsData({ variables: { input: data } }).then(() => {})
    setTimeout(() => {
      history.push('/reports')
    }, 2000)
  }
  const handleClick = () => {
    if (!formInput.fromDate || !formInput.toDate || !formInput.therapistId) return

    setOpen(true)
  }
  const handleClose = () => {
    if (!formInput.fromDate || !formInput.toDate) return

    setOpen(false)
  }
  const handleFromDateChange = async (e) => {
    await setFormInput({ ...formInput, fromDate: e.target.value })
  }
  const handleToDateChange = async (e) => {
    await setFormInput({ ...formInput, toDate: e.target.value })
  }
  const handleFileTypeInput = (evt) => {
    setFormInput({ ...formInput, fileType: evt.target.value })
  }
  const handleTherapistIdInput = (evt) => {
    setFormInput({ ...formInput, therapistId: evt.target.value })
  }

  return (
    <div>
      <NavBar title="Provider Service Minutes Report" goBackBtn />
      <br />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={0} direction="column" alignItems="center" justify="center">
          <Grid item sm={6}>
            <TextField
              id="date"
              label="From Date"
              type="date"
              required
              onChange={handleFromDateChange}
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <br />
          <Grid item sm={6}>
            <TextField
              id="date"
              label="To Date"
              type="date"
              required
              onChange={handleToDateChange}
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          {hasAnyRole(Role.TeleteachersAdmin, Role.SchoolAdmin) && (
            <>
              <br />
              <span className={classes.fileType}>User Select</span>
              <Grid item sm={6}>
                <select onChange={handleTherapistIdInput} className={classes.fileSelect} required>
                  <option aria-label="None" value="" />
                  {therapistData?.reportusers?.map((listTherapists) => (
                    <option key={listTherapists.id} value={listTherapists.id?.toString()}>
                      {listTherapists.name}
                    </option>
                  ))}
                </select>
              </Grid>
            </>
          )}
          <br />
          <span className={classes.fileType}>File Type</span>
          <Grid item sm={6}>
            <select onChange={handleFileTypeInput} className={classes.fileSelect}>
              {/* <option>pdf</option> */}
              <option>excel</option>
            </select>
          </Grid>
          <Button
            onClick={handleClick}
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Build Report
          </Button>
          <Grid item sm={6}>
            <Snackbar
              open={open}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert onClose={handleClose}>
                {' '}
                Report is being generated. Email will be sent to{' '}
                {currentUserdata.data?.currentUser.email} with a link when it is finished.
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}
