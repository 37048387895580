import { Card, CardActions, CardContent, Typography, useTheme } from '@material-ui/core'
import React from 'react'
import GraphImg from 'graphcms-image'
import Button from '../../../components/ui/panel/button/Button'
import { CmsAsset } from '../../../graphql/hooks.generated'

interface ResourceCardProps {
  title: string
  image: { __typename?: 'CmsAsset' | undefined } & Pick<
    CmsAsset,
    'height' | 'width' | 'id' | 'url' | 'handle'
  >
  onView: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const ResourceCard = (props: ResourceCardProps) => {
  const { title, onView, image } = props
  const theme = useTheme()

  return (
    <Card square={false} elevation={0}>
      <GraphImg image={image} style={{ height: 192 }} fit="scale" />
      <CardContent style={{ padding: '32px 32px 0px 32px' }}>
        <Typography color="secondary" gutterBottom variant="h5">
          {title}
        </Typography>
      </CardContent>
      <CardActions style={{ padding: '24px 32px 32px 32px' }}>
        <Button
          style={{
            backgroundColor: theme.palette.info.main,
            color: theme.palette.common.white
          }}
          size="small"
          variant="contained"
          onClick={onView}
        >
          View
        </Button>
      </CardActions>
    </Card>
  )
}
