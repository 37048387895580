import { Grid } from '@material-ui/core'
import { makeValidate, TextField } from 'mui-rff'
import React from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import Button from '../../../../components/ui/panel/button/Button'
import { InfoButton } from '../../../../components/ui/panel/button/InfoButton'
import { Section } from '../../../../components/ui/panel/section/Section'

export interface StateFormModel {
  name: string
  abbreviation: string
  medicaidName?: string
  ein?: string
  streetAddress?: string
  city?: string
  zip?: string
  contactInfo?: string
}

type StateFormProps = {
  onSubmit(values: StateFormModel): void
  submitLabel: string
  initialValues?: Partial<StateFormModel> | null
}
const validator = makeValidate(
  yup.object<StateFormModel>({
    city: yup.string().label('City').notRequired(),
    name: yup.string().label('State name').required(),
    abbreviation: yup.string().min(2).max(2).label('Abbreviation').required(),
    ein: yup.string().label('EIN').notRequired(),
    contactInfo: yup.string().label('Contact info').notRequired(),
    medicaidName: yup.string().label('Medicaid name').notRequired(),
    streetAddress: yup.string().label('Street Address').notRequired(),
    zip: yup.string().label('Zip').notRequired()
  })
)

export const StateForm = (props: StateFormProps) => {
  const history = useHistory()
  const { initialValues } = props
  const onSubmit = (values: StateFormModel) => props.onSubmit(values)
  const renderForm = (renderProps: FormRenderProps<StateFormModel>) => {
    const { handleSubmit } = renderProps

    return (
      <form>
        <Grid container spacing={4} style={{ maxWidth: '800px' }}>
          <Grid item sm={8}>
            <Section title="Name">
              <TextField required fullWidth variant="filled" name="name" label="State name" />
            </Section>
          </Grid>

          <Grid item sm={4}>
            <Section title="Abbreviation">
              <TextField
                required
                fullWidth
                variant="filled"
                name="abbreviation"
                label="State abbreviation"
              />
            </Section>
          </Grid>

          <Grid item sm={12}>
            <Section title="Medicaid name">
              <TextField fullWidth variant="filled" name="medicaidName" label="Medicaid name" />
            </Section>
          </Grid>

          <Grid item sm={12}>
            <Section title="EIN">
              <TextField fullWidth variant="filled" name="ein" label="EIN" />
            </Section>
          </Grid>

          <Grid item sm={12}>
            <Section title="Street address">
              <TextField fullWidth variant="filled" name="streetAddress" label="Street address" />
            </Section>
          </Grid>

          <Grid item sm={12}>
            <Section title="City">
              <TextField fullWidth variant="filled" name="city" label="City" />
            </Section>
          </Grid>

          <Grid item sm={12}>
            <Section title="Zip">
              <TextField fullWidth variant="filled" name="zip" label="Zip" />
            </Section>
          </Grid>

          <Grid item sm={12} style={{ paddingTop: 35 }}>
            <InfoButton
              size="large"
              variant="contained"
              onClick={handleSubmit}
              style={{ marginRight: 12 }}
            >
              {props.submitLabel}
            </InfoButton>
            <Button size="large" variant="outlined" color="primary" onClick={history.goBack}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <Form<StateFormModel>
      initialValues={initialValues ?? {}}
      validate={validator}
      render={renderForm}
      onSubmit={onSubmit}
    />
  )
}
