import { Role } from 'graphql/hooks.generated'
import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import { PrivateRoute } from '../../../components/router/PrivateRoute'
import { AllServiceRequestsView } from './all/AllServiceRequestsView'
import { CreateServiceRequestView } from './create/CreateServiceRequestView'
import { MyServiceRequestsView } from './my/MyServiceRequestsView'

export const ServiceRequestsRootView = () => {
  const { url } = useRouteMatch()

  return (
    <Switch>
      <PrivateRoute
        path={`${url}/create`}
        roles={[Role.TeleteachersAdmin, Role.SchoolAdmin]}
        component={CreateServiceRequestView}
      />
      <PrivateRoute
        path={`${url}/my`}
        roles={[Role.TeleteachersAdmin, Role.SchoolAdmin]}
        component={MyServiceRequestsView}
      />
      <PrivateRoute roles={[Role.TeleteachersAdmin]} component={AllServiceRequestsView} />
    </Switch>
  )
}
