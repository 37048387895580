import React, { useEffect, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import UndoIcon from '@material-ui/icons/Undo'
import {
  FormControl,
  FormGroup,
  MenuItem,
  Paper,
  Select,
  Typography,
  FormLabel,
  Chip,
  FormControlLabel,
  ThemeProvider,
  Switch,
  Button,
  Radio,
  RadioGroup,
  Checkbox,
  OutlinedInput
} from '@material-ui/core'
import { createMuiTheme, Theme, useTheme } from '@material-ui/core/styles'
import {
  SessionGoals,
  useSessionRateSelectLazyQuery,
  useReferralQuery
  // useAdditionalnumberInsertMutation
} from 'graphql/hooks.generated'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs'
import { useStyles } from '../../../../config/constants/theme/sessions'
import { ReactComponent as Delete } from '../../../../assets/icons/delete.svg'
import { ReactComponent as Copy } from '../../../../assets/icons/copy.svg'
import { ReactComponent as SubtractionIcon } from '../../../../assets/icons/Subtraction.svg'
import { ReactComponent as AcceptIcon } from '../../../../assets/icons/accept.svg'
import { ReactComponent as RejectIcon } from '../../../../assets/icons/reject.svg'

const theme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      switchBase: {
        color: 'white !important'
      },
      colorSecondary: {
        '&$checked': {
          color: 'white !important'
        }
      },
      track: {
        backgroundColor: 'grey',
        '$checked$checked + &': {
          backgroundColor: 'green'
        }
      }
    },
    MuiSelect: {
      selectMenu: {
        color: 'red'
      },
      select: {
        '&:focus': {
          background: '#3A3660'
        },
        color: 'white'
      }
    }
  }
})
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}
const getStyles = (name: any, referraldata: any[], theme: Theme) => {
  return {
    fontWeight:
      referraldata.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  }
}

export const StudentNotes = (props: {
  present
  id: number
  name: string
  session_no: number
  notes: string
  plan: string
  assessment: string
  further_sessions: any
  highLevelCareCheck: any
  highLevelCareText: string
  furtherSessionText: string
  additional_sessions: number
  sessionGoals: SessionGoals[] | undefined
  updateStudent: (studentId, studentObj) => void
  updateGoals: (studentId, goalId, goalObj) => void
  deleteGoal: (studentId, goalId) => void
  copyGoalActivity: (text: string) => void
  // updateTracker: (studentId, goalId, trackerId, actionType) => void
  clicksHistory: any
  trackClicksHistory: (computeKey, icon) => void
  trackerCounts: (key, count) => void
  trackersCount: any
  goalTrackerMetrics: (key, percentage) => void
  trackerMetric: any
  formErrors: any
  PointerEvent
  referraldata
  referralhandleChange: (data) => void
}) => {
  const css = `.MuiFormControlLabel-label {
    font-family: 'Brandon Grotesque'
    }
    .MuiFormControlLabel-label {
      color:#000
    }`
  const multiselectcss = `.MuiOutlinedInput-input {
    padding: 10.5px 14px
  }`
  const classes = useStyles()
  const {
    present,
    id,
    name,
    notes,
    plan,
    session_no,
    assessment,
    further_sessions,
    highLevelCareCheck,
    highLevelCareText,
    furtherSessionText,
    additional_sessions,
    sessionGoals,
    updateStudent,
    updateGoals,
    deleteGoal,
    copyGoalActivity,
    // updateTracker,
    clicksHistory,
    trackClicksHistory,
    trackerCounts,
    trackersCount,
    goalTrackerMetrics,
    trackerMetric,
    formErrors,
    PointerEvent,
    referraldata,
    referralhandleChange
  } = props
  const [studentPresent, setPresentState] = React.useState(present)
  const [grantedNumber, setGrantedNumber] = useState<any>(null)
  const { data: currentUserData } = useCurrentUser()
  const [executeQuery, { data: ProgressRate }] = useSessionRateSelectLazyQuery({
    fetchPolicy: 'network-only'
  })
  const { data: referaldata, loading: referalloading } = useReferralQuery({
    fetchPolicy: 'network-only'
  })
  const fetch = useMemo(() => () => executeQuery(), [executeQuery])
  useEffect(() => {
    fetch()
  }, [fetch])
  const handleChangePresent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPresentState(event.target.checked)
    updateStudent(id, { present: event.target.checked })
  }
  const theme1 = useTheme()
  const referralChange = (event) => {
    const {
      target: { value }
    } = event
    referralhandleChange(value)
  }
  const decrementPercentage = (key, icon) => {
    const currentPercentage = trackerMetric.get(key) ? trackerMetric.get(key) : [0, 0, 0, 0]
    const successAttempts = currentPercentage[0]
    const assistAttempts = currentPercentage[1]
    const totalAttempts = currentPercentage[2]

    if (icon !== 'ASSIST') currentPercentage[2] = totalAttempts - 1
    if (icon === 'YES') currentPercentage[0] = successAttempts - 1
    if (icon === 'ASSIST') currentPercentage[1] = assistAttempts - 1
    if (currentPercentage[2] !== 0)
      currentPercentage[3] = Math.round((currentPercentage[0] * 100) / currentPercentage[2])
    if (currentPercentage[0] === 0) currentPercentage[3] = 0

    goalTrackerMetrics(key, currentPercentage)
  }
  const updatePercentage = (key, icon) => {
    const currentPercentage = trackerMetric.get(key) ? trackerMetric.get(key) : [0, 0, 0, 0]
    const successAttempts = currentPercentage[0]
    const assistAttempts = currentPercentage[1]
    const totalAttempts = currentPercentage[2]

    if (icon !== 'ASSIST') currentPercentage[2] = totalAttempts + 1
    if (icon === 'YES') currentPercentage[0] = successAttempts + 1
    if (icon === 'ASSIST') currentPercentage[1] = assistAttempts + 1
    if (currentPercentage[2] !== 0)
      currentPercentage[3] = Math.round((currentPercentage[0] * 100) / currentPercentage[2])

    goalTrackerMetrics(key, currentPercentage)
  }
  const incrementChip = (key, icon, goalId) => {
    trackClicksHistory(key, icon)
    const currentItemCount = trackersCount.get(key) ? trackersCount.get(key) : 0
    trackerCounts(key, currentItemCount + 1)
    updatePercentage(goalId, icon)
  }
  const handleStudentChange = (event) => {
    updateStudent(id, { [event.target.id]: event.target.value })
  }
  const handleFurtherChange = (event) => {
    if (event.target.value !== '0') updateStudent(id, { [event.target.name]: true })
    else updateStudent(id, { [event.target.name]: false, further_sessions_text: null })
  }
  const handleHighLevelCareChange = (event) => {
    if (event.target.checked) updateStudent(id, { [event.target.name]: event.target.checked })
    else
      updateStudent(id, {
        [event.target.name]: event.target.checked,
        high_level_care_text: null,
        requestReferral: []
      })
  }
  const handleSessionNumChange = (event) => {
    updateStudent(id, { [event.target.name]: event.target.value })
  }
  const handleGoalsChange = (event, goalId) => {
    let property

    if ('id' in event.target) property = event.target.id.split('_', 1)
    else property = event.target.name

    updateGoals(id, goalId, { [property]: event.target.value })
  }
  const handleGoalDelete = (event, goalId) => {
    deleteGoal(id, goalId)
  }
  const handleCopyGoalActivity = (text) => {
    copyGoalActivity(text)
  }
  // const handleUpdateTracker = (studentId, goalId, trackerId, actionType) => {
  //   updateTracker(studentId, goalId, trackerId, actionType)
  const decrementChip = (goalKey, goalId) => {
    if (clicksHistory.length === 1) return

    let lastIndex = -1
    let icon = ''
    clicksHistory.forEach((item, index) => {
      if (String(item.key).endsWith(goalKey)) {
        lastIndex = index
        icon = String(item.icon)
      }
    })

    if (lastIndex >= 0) {
      const item = clicksHistory.splice(lastIndex, 1)
      const currentItemCount = trackersCount.get(String(item[0].key))
      trackerCounts(item[0].key, currentItemCount - 1)

      if (clicksHistory.length > 0) decrementPercentage(goalId, icon)
    }
  }

  return (
    <div className={PointerEvent ? classes.noPonterEvents : ''}>
      <div className={classes.rootMain}>
        {/* <div className={classes.rootMainScroll}> */}
        <Grid item xs={12}>
          <Typography className={classes.columnSizeNew}>
            <span className={classes.studentName}>
              Was <b> {name.split(' ', 1)} </b> present
            </span>
            <span>
              <ThemeProvider theme={theme}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={studentPresent || false}
                        onChange={handleChangePresent}
                        value={studentPresent ? 'yes' : 'no'}
                        name="checkedB"
                        className={classes.switchChange}
                      />
                    }
                    label={studentPresent || false ? 'Yes' : 'No'}
                  />
                </FormGroup>
              </ThemeProvider>
            </span>
          </Typography>
        </Grid>
        {/* {studentPresent &&
            sessionGoals?.map((goal, goalIndex) => {
              const undoTrackerId = `_${id}${goal?.goal_id?.goal_number}`

              return (
                <Grid container spacing={1} className={classes.goalLayout}>
                  <Grid item xs={12}>
                    <Paper className={classes.paperDivTabs}>
                      <span className={classes.goalTxt}>
                        {goal && goal.parent_goal_id && goal.parent_goal_id.length > 0
                          ? 'BM '
                          : 'Goal '}
                        {goal?.goal_id?.goal_number}:
                      </span>
                      <span className={classes.deleteIcon}>
                        <Delete
                          onClick={(e) => {
                            handleGoalDelete(e, goal?.id)
                          }}
                        />
                      </span>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper className={classes.paperDivContent}>
                      <Typography className={classes.studentTxt}>{goal?.goal_id?.name}</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper className={classes.paperDivTabs}>
                      <Grid xs={6} className={classes.activityTxt}>
                        <div>
                          <FormLabel
                            className={`${classes.labelText} ${
                              formErrors?.goal[goalIndex]?.activity ? classes.errorText : ''
                            }`}
                          >
                            Activity *
                          </FormLabel>
                          <FormLabel className={classes.copyIcon}>
                            <div>
                              <Button
                                className={classes.iconCls}
                                onClick={() => handleCopyGoalActivity(goal?.activity || '')}
                              >
                                <Copy />{' '}
                              </Button>{' '}
                              Copy to group
                            </div>
                          </FormLabel>
                          <TextareaAutosize
                            name="activity"
                            id={`activity_${goal?.goal_id?.id}`}
                            defaultValue={goal?.activity || ''}
                            onChange={(e) => handleGoalsChange(e, goal?.goal_id?.id || 0)}
                            rowsMax={3}
                            aria-label="maximum height"
                            className={classes.namefieldsize}
                          />
                        </div>
                      </Grid>
                      <Grid xs={6} className={classes.chipIcons}>
                        <Grid xs={12} className={classes.chipSpacing}>
                          {goal?.session_tracker?.map((trackers) => {
                            let icon: JSX.Element | undefined

                            if (String(trackers?.tracker_id?.icon?.toUpperCase()) === 'YES')
                              icon = <AcceptIcon />
                            else if (String(trackers?.tracker_id?.icon?.toUpperCase()) === 'NO')
                              icon = <RejectIcon />
                            else if (String(trackers?.tracker_id?.icon?.toUpperCase()) === 'ASSIST')
                              icon = <SubtractionIcon />

                            const goalTrackerId = `${trackers?.tracker_id?.id}${undoTrackerId}`

                            return (
                              <>
                                <ul className={classes.trackersInfo}>
                                  <li className={classes.listCls}>
                                    <a>
                                      <Chip
                                        id={trackers?.tracker_id?.id}
                                        icon={icon}
                                        label={trackers?.tracker_id?.name}
                                        className={classes.spaceing}
                                        onClick={() => {
                                          // handleUpdateTracker(
                                          //   id,
                                          //   goal?.goal_id?.id,
                                          //   trackers?.tracker_id?.id,
                                          //   '++'
                                          // )
                                          incrementChip(
                                            goalTrackerId,
                                            trackers?.tracker_id?.icon?.toUpperCase(),
                                            goal?.goal_id?.id
                                          )
                                        }}
                                      />
                                    </a>
                                  </li>
                                  <li className={classes.countInfo}>
                                    <a>
                                      {trackersCount.get(goalTrackerId) !== undefined
                                        ? trackersCount.get(goalTrackerId)
                                        : 0}
                                    </a>
                                  </li>
                                </ul>
                              </>
                            )
                          })}
                        </Grid>
                        <Typography>
                          <Button
                            variant="outlined"
                            size="small"
                            className={classes.undoText}
                            startIcon={<UndoIcon className={classes.undoCls} />}
                            onClick={() => {
                              decrementChip(undoTrackerId, goal?.goal_id?.id)
                            }}
                          >
                            Undo
                          </Button>
                        </Typography>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              )
            })} */}
        {/* </div> */}
        <Grid item xs={12} className={classes.progressDiv}>
          <Paper className={classes.paperDivTabs}>
            <Grid xs={12} md={12} className={classes.activityTxt}>
              {studentPresent && (
                <>
                  <div>
                    <Grid sm={12}>
                      {/* <FormLabel
                        className={`${classes.labelText} ${
                          formErrors?.session_no ? classes.errorText : ''
                        }`}
                      > */}
                      <div>
                        <span className={`${classes.labelText}`}>Session Number:&nbsp;</span>
                        <span className={`${classes.colorBlack} ${classes.fnormal}`}>
                          {session_no}
                        </span>
                      </div>
                      {/* </FormLabel>
                      <Select
                        id="session_no"
                        name="session_no"
                        className={classes.selectDropdown}
                        onChange={handleSessionNumChange}
                        variant="filled"
                        value={session_no}
                      >
                        <MenuItem value={1} className={classes.optionItem}>
                          <span className={classes.optionItem}>{1}</span>
                        </MenuItem>
                        <MenuItem value={2} className={classes.optionItem}>
                          <span className={classes.optionItem}>{2}</span>
                        </MenuItem>
                        <MenuItem value={3} className={classes.optionItem}>
                          <span className={classes.optionItem}>{3}</span>
                        </MenuItem>
                      </Select> */}
                    </Grid>
                  </div>
                  <div>
                    <FormLabel
                      className={`${classes.labelText} ${
                        formErrors?.notes ? classes.errorText : ''
                      }`}
                    >
                      Data *
                    </FormLabel>
                    <TextareaAutosize
                      id="notes"
                      rowsMax={3}
                      defaultValue={notes}
                      aria-label="maximum height"
                      className={classes.namefieldsize}
                      onChange={handleStudentChange}
                      maxLength={1024}
                    />
                  </div>
                  <div>
                    <FormLabel
                      className={`${classes.labelText} ${
                        formErrors?.assessment ? classes.errorText : ''
                      }`}
                    >
                      Assessment *
                    </FormLabel>
                    <TextareaAutosize
                      id="assessment"
                      rowsMax={3}
                      defaultValue={assessment}
                      aria-label="maximum height"
                      className={classes.namefieldsize}
                      onChange={handleStudentChange}
                      maxLength={1024}
                    />
                  </div>
                </>
              )}
              <div>
                <FormLabel
                  className={`${classes.labelText} ${formErrors?.plan ? classes.errorText : ''}`}
                >
                  Plan *
                </FormLabel>
                <TextareaAutosize
                  id="plan"
                  rowsMax={3}
                  defaultValue={plan}
                  aria-label="maximum height"
                  className={classes.namefieldsize}
                  onChange={handleStudentChange}
                  maxLength={1024}
                />
              </div>
              {studentPresent && (
                <>
                  <div>
                    <FormControlLabel
                      name="high_level_care"
                      className={classes.textColor}
                      control={<Checkbox defaultChecked={highLevelCareCheck} />}
                      label="Request client resources/referrals"
                      onChange={handleHighLevelCareChange}
                    />
                  </div>
                  {highLevelCareCheck && (
                    <>
                      <div>
                        <FormLabel
                          className={`${classes.labelText} ${
                            formErrors?.requestReferral ? classes.errorText : ''
                          }`}
                        >
                          Resources/referrals requested *
                        </FormLabel>
                        <div style={{ padding: '5px 0px 10px 0px' }}>
                          <style>{multiselectcss}</style>
                          <Select
                            name="requestReferral"
                            style={{ width: '100%' }}
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            value={referraldata}
                            onChange={referralChange}
                            input={<OutlinedInput style={{ padding: '10.5px 14px' }} />}
                            MenuProps={MenuProps}
                          >
                            {referaldata?.referral?.map((refobj) => (
                              <MenuItem
                                key={refobj?.id}
                                value={refobj?.id}
                                style={getStyles(refobj?.id, referraldata, theme1)}
                              >
                                {refobj?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div>
                        <FormLabel
                          className={`${classes.labelText} ${
                            formErrors?.high_level_care_text ? classes.errorText : ''
                          }`}
                        >
                          Notes *
                        </FormLabel>
                        <TextareaAutosize
                          id="high_level_care_text"
                          rowsMax={3}
                          defaultValue={highLevelCareText}
                          aria-label="maximum height"
                          className={classes.namefieldsize}
                          onChange={handleStudentChange}
                          maxLength={1024}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
              {studentPresent && session_no >= 6 && (
                <>
                  <div>
                    <FormLabel
                      className={`${classes.labelText} ${
                        formErrors?.further_sessions ? classes.errorText : ''
                      }`}
                    >
                      Request additional sessions through I Matter
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="further_sessions"
                      key={Math.floor(Math.random() * 100)}
                      defaultValue={
                        further_sessions === (undefined || null)
                          ? null
                          : further_sessions === true
                          ? '1'
                          : further_sessions === false
                          ? '0'
                          : further_sessions
                      }
                      onChange={handleFurtherChange}
                    >
                      <style>{css}</style>
                      <FormControlLabel
                        // key={Math.floor(Math.random() * 100)}
                        value="1"
                        control={<Radio color="primary" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        // key={Math.floor(Math.random() * 100)}
                        value="0"
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </RadioGroup>
                  </div>
                  {further_sessions && further_sessions !== '0' && (
                    <div>
                      <p className="timeZoneTxt1">
                        <span>
                          When selecting yes, please indicate how many additional sessions beyond
                          the 6 you are requesting with the client. Keep in mind that these
                          additional sessions should be used for the purpose of transition planning
                          and not for long-term therapy with the client.
                        </span>
                      </p>
                      <h4 style={{ color: '#0D1F48', marginTop: '6px' }}>
                        Number of additional Sessions*
                      </h4>
                      <div
                        style={{
                          width: '100px',
                          fontSize: '16px',
                          marginTop: '-8px'
                        }}
                      >
                        <NumericTextBoxComponent
                          min={0}
                          step={1}
                          id="additional_sessions"
                          value={additional_sessions}
                          name="additional_sessions"
                          onChange={handleStudentChange}
                          // change={(value) => setGrantedNumber(value?.value || '')}
                          format="###.##"
                        />
                      </div>
                      <FormLabel
                        className={`${classes.labelText} ${
                          formErrors?.further_sessions_text ? classes.errorText : ''
                        }`}
                      >
                        Notes *
                      </FormLabel>
                      <TextareaAutosize
                        id="further_sessions_text"
                        rowsMax={3}
                        defaultValue={furtherSessionText}
                        aria-label="maximum height"
                        className={classes.namefieldsize}
                        onChange={handleStudentChange}
                        maxLength={1024}
                      />
                    </div>
                  )}
                </>
              )}
            </Grid>
            {/* <Grid xs={6} className={classes.bottomDiv}>
              {studentPresent && (
                <>
                  <div>
                    {sessionGoals?.map((goal, goalIndex) => {
                      const goalTrackerId = `${goal?.goal_id?.id}`
                      const curPer = `${
                        trackerMetric.get(goalTrackerId) !== undefined
                          ? trackerMetric.get(goalTrackerId)
                          : [0, 0, 0, 0]
                      }`.split(',')

                      return (
                        <Typography className={classes.typoBars}>
                          <Typography>
                            <span className={classes.labelSpan}>
                              <FormLabel
                                className={`${classes.labelText} ${
                                  formErrors?.goal[goalIndex]?.metric ? classes.errorText : ''
                                }`}
                              >
                                {goal && goal.parent_goal_id && goal.parent_goal_id.length > 0
                                  ? 'BM '
                                  : 'Goal '}
                                {goal?.goal_id?.goal_number} *:
                              </FormLabel>
                            </span>
                            <span className={classes.progressBarCls}>
                              <FormControl className={classes.selectTextProgress}>
                                {ProgressRate && (
                                  <Select
                                    id="session_rate_id"
                                    name="session_rate_id"
                                    className={classes.selectDropdown}
                                    onChange={(e) => handleGoalsChange(e, goal?.goal_id?.id || 0)}
                                    variant="filled"
                                    value={goal.session_rate_id}
                                    // disabled={curPer[2] === '0' || !curPer[2]}
                                  >
                                    {ProgressRate?.sessionRateList?.map((sessionRate) => (
                                      <MenuItem
                                        id="session_rate_id"
                                        key={sessionRate.id}
                                        value={sessionRate.id}
                                        className={classes.optionItem}
                                      >
                                        <span className={classes.optionItem}>
                                          {sessionRate.name}
                                        </span>
                                      </MenuItem>
                                    ))}
                                  </Select>
                                )}
                              </FormControl>
                            </span>
                          </Typography>
                          <Typography className={classes.progressRate}>
                            {curPer[3]}%; &nbsp;{curPer[0]}/{curPer[2]}
                            Attempts &nbsp;{curPer[1]} Assists
                          </Typography>
                        </Typography>
                      )
                    })}
                  </div>
                </>
              )}
            </Grid> */}
          </Paper>
        </Grid>
      </div>
    </div>
  )
}
