import React from 'react'
import { useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { PageContainer } from 'components/ui/panel/page-container/PageContainer'
import { UserListView } from 'views/private/users/list/UserListView'
import MetricCard from '../../../../components/ui/dashboard/cards/metric/MetricCard'
import Dashboard from '../../../../components/ui/dashboard/Dashboard'
import Tour from '../../../../components/ui/tour/Tour'
import { Role, useAdminDashboardMetricsQuery } from '../../../../graphql/hooks.generated'
import { useCurrentUser } from '../../../../graphql/hooks/useCurrentUser/useCurrentUser'
import { ReactComponent as DirectServicesIcon } from '../../../../assets/icons/attachments.svg'
import { ReactComponent as BlackoutDaysIcon } from '../../../../assets/icons/blackout-days.svg'
import { ReactComponent as GroupsIcon } from '../../../../assets/icons/groups.svg'
import { ReactComponent as ReportsIcon } from '../../../../assets/icons/reports.svg'
import { ReactComponent as StudentsIcon } from '../../../../assets/icons/students.svg'
import { ReactComponent as TherapistsIcon } from '../../../../assets/icons/therapists.svg'
import { getTherapyLogUrl } from '../../../../helpers/getTherapyLogUrl'
import useStyles from '../Styles'
import { MainDashboard } from '../mainDashboard'

export const AdminDashboard = () => {
  const { hasAnyRole } = useCurrentUser()
  const history = useHistory()
  const classes = useStyles()
  const { data } = useAdminDashboardMetricsQuery({
    returnPartialData: true,
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  })

  return (
    <>
      <Tour steps={Role.SchoolAdmin} />
      <div className={classes.root}>
        <PageContainer>
          <MainDashboard />
        </PageContainer>
        {/* <UserListView /> */}
      </div>
    </>
  )
}
