import React, { useEffect, useMemo } from 'react'
import { useRouteMatch, useHistory, useParams } from 'react-router-dom'
import { Grid, Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { UseParamsType } from 'types'
import TagManager from 'react-gtm-module'
import Footer from '../../panel/footer/Footer'
import { PageContainer } from '../../panel/page-container/PageContainer'
import AppBar from '../../panel/app-bar/AppBar'
import Button from '../../panel/button/Button'
import useStyles from './Styles'
import ConfirmImg from '../../../../assets/illustrations/confirm.jpg'

interface ListTokenParams extends UseParamsType {
  loc: string
}

export const Confirm = () => {
  // useEffect(() => {
  //   const tagManagerArgs = {
  //     gtmId: 'GTM-T644KWW'
  //   }
  //   TagManager.initialize(tagManagerArgs)
  // }, [])
  const { loc } = useParams<ListTokenParams>()
  const { t, i18n } = useTranslation()
  useMemo(() => {
    if (loc === 'en' || loc === 'es') i18n.changeLanguage(loc || 'en')
    else i18n.changeLanguage('en')
  }, [i18n, loc])
  const classes = useStyles()
  const { url } = useRouteMatch()
  const history = useHistory()
  const studentName = sessionStorage.getItem('s_name')
  const params = new URLSearchParams(window.location.search)
  let waitlistflow = false

  if (params.get('wl') === 'true') waitlistflow = true

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div
      className={classes.root}
      style={{
        background: '#00DE74',
        marginTop: '-30px',
        marginBottom: '-30px',
        boxShadow: 'none'
      }}
    >
      <PageContainer>
        <Grid
          container
          direction="row"
          className={classes.popin}
          style={{
            boxShadow: 'none'
          }}
        >
          <Grid item md={12} sm={12} style={{ padding: '20px', color: '#315089' }}>
            <Grid>
              {!waitlistflow && (
                <Grid item md={12} sm={12}>
                  <h2 style={{ fontSize: '24px' }}>
                    {t('sessionconfirmation.hi')} {studentName}, {t('sessionconfirmation.note1')}
                  </h2>
                  <Box mt={2}>
                    <Typography style={{ marginTop: '20px' }} className={classes.popin}>
                      {t('sessionconfirmation.noteconf')}
                    </Typography>
                    <Typography style={{ marginTop: '20px' }} className={classes.popin}>
                      {t('sessionconfirmation.noteconf2')}
                    </Typography>
                    <Typography style={{ marginTop: '20px' }} className={classes.popin}>
                      {t('sessionconfirmation.noteconf3')}
                      <span> {t('sessionconfirmation.mail1')}.</span>{' '}
                      <span>{t('sessionconfirmation.note2')}</span>
                      <span>
                        {' '}
                        <a
                          style={{ textDecoration: 'none', color: '#0052cc' }}
                          href="mailto: support@imattercolorado.org."
                        >
                          {t('sessionconfirmation.mail1')}.
                        </a>
                      </span>{' '}
                      <span>{t('sessionconfirmation.note2a')}</span>
                      <span>
                        {' '}
                        <a
                          style={{ textDecoration: 'none', color: '#0052cc' }}
                          href="mailto: support@imattercolorado.org."
                        >
                          {t('sessionconfirmation.mail2')}
                        </a>
                      </span>{' '}
                      <span>{t('sessionconfirmation.note2b')}</span>
                    </Typography>
                    <Typography style={{ marginTop: '20px' }} className={classes.popin}>
                      <b>{t('sessionconfirmation.ccsT1')}</b>
                      {', '}
                      {t('sessionconfirmation.ccsT2')}{' '}
                      <a
                        href={`${
                          params.get('loc') === 'es'
                            ? 'https://coloradocrisisservices.org/es/'
                            : 'https://coloradocrisisservices.org'
                        }`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('sessionconfirmation.ccsT3')}
                      </a>{' '}
                      {t('sessionconfirmation.ccsT4')}
                      {t('sessionconfirmation.ccsT5')}
                      <a
                        href="https://imattercolorado.org/content/resource.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('sessionconfirmation.ccsT7')}
                      </a>{' '}
                      {t('sessionconfirmation.ccsT6')}
                    </Typography>
                  </Box>
                </Grid>
              )}
              {waitlistflow && (
                <Grid item md={12} sm={12}>
                  <h2 style={{ fontSize: '24px' }} className={classes.popin}>
                    {t('sessionconfirmation.waitlist1')} {studentName},
                  </h2>
                  <Box mt={2}>
                    <Typography style={{ marginTop: '20px' }} className={classes.popin}>
                      {t('sessionconfirmation.waitlist2')}
                    </Typography>
                    <Typography style={{ marginTop: '20px' }} className={classes.popin}>
                      {t('sessionconfirmation.waitlist3')} {t('sessionconfirmation.ccsT1')}
                      {', '}
                      {t('sessionconfirmation.ccsT2')}{' '}
                      <a
                        href={`${
                          i18n.language === 'es'
                            ? 'https://coloradocrisisservices.org/es/'
                            : 'https://coloradocrisisservices.org/'
                        }`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('sessionconfirmation.ccsT3')}
                      </a>{' '}
                      {t('sessionconfirmation.ccsT4')}
                      {t('sessionconfirmation.ccsT5')}
                      <a
                        href="https://imattercolorado.org/content/resource.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('sessionconfirmation.ccsT7')}
                      </a>
                      {t('sessionconfirmation.ccsT6')}
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </PageContainer>
    </div>
  )
}
