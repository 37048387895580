import React from 'react'
import { TextFieldProps } from 'mui-rff'
import { Field } from 'react-final-form'
import MuiPhoneNumber from 'material-ui-phone-number-for-mui-rff'
import { useStyles } from '../Styles'

export const PhoneInput = React.forwardRef((props: TextFieldProps, ref) => {
  const classes = useStyles()
  const { name } = props
  const handleOnChange = (e, onChange) => {
    const formattedNumber = e.replace(/\D+/g, '')
    onChange(formattedNumber.length < 2 ? '' : formattedNumber)
  }

  return (
    <Field name={name} ref={ref} className={classes.labelFieldNew}>
      {(p) => (
        <MuiPhoneNumber
          countryCodeEditable={false}
          defaultCountry="us"
          className={classes.labelFieldNew}
          onlyCountries={['us']}
          value={p.input.value}
          onChange={(e) => handleOnChange(e, p.input.onChange)}
          {...props}
        />
      )}
    </Field>
  )
})
