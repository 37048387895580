/* eslint-disable array-callback-return */
import { Grid } from '@material-ui/core'
import Button from 'components/ui/panel/button/Button'
import { InfoButton } from 'components/ui/panel/button/InfoButton'
import { FileField } from 'components/ui/form/file-field/FileField'
import { ServiceRequestTypeSelect } from 'components/ui/form/service-request-type-select/ServiceRequestTypeSelect'
import { StudentGradeSelect } from 'components/ui/form/student-grade/StudentGradeSelect'
import React, { useMemo, useState, useEffect } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import { ServiceRequestDisciplineSelect } from 'components/ui/form/service-request-discipline-select/ServiceDisciplineTypeSelect'
import { Checkboxes, TextField } from 'mui-rff'
import { ServiceRequestType } from 'graphql/hooks.generated'
// import TextField from '@material-ui/core/TextField'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { validator } from './CreateServiceRequestValidator'
import { CreateServiceRequestFormModel } from './CreateServiceRequestFormModel'
import { useStudentGradesLazyQuery } from '../../../../graphql/hooks.generated'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'

interface CreateServiceRequestFormProps {
  onSubmit(values: CreateServiceRequestFormModel): Promise<unknown>
}
const requiredFileDescription = {
  [ServiceRequestType.Evaluation]: 'consent for services',
  [ServiceRequestType.RecordReview]: 'consent for services',
  [ServiceRequestType.Screening]: 'consent for services',
  [ServiceRequestType.Therapy]: 'IEP'
}

export const CreateServiceRequestForm = (props: CreateServiceRequestFormProps) => {
  const [grade, setGrade] = useState('')
  const classes = useStyles()
  const { onSubmit } = props
  const history = useHistory()
  const [executeQuery, { data: gradeData, loading: gradeloading }] = useStudentGradesLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetch = useMemo(
    () => ({ id }) =>
      executeQuery({
        variables: { id }
      }),
    [executeQuery]
  )
  useEffect(() => {
    if (grade) {
      const inputData = {
        id: grade
      }
      fetch(inputData)
    }

    return console.log('')
  }, [fetch, grade])
  const renderForm = (renderProps: FormRenderProps<CreateServiceRequestFormModel>) => {
    const { handleSubmit, values } = renderProps

    if (gradeData && gradeData.studentGrades)
      gradeData.studentGrades.map((grades) => {
        values.grade = grades.grade
      })
    // console.log('``````````````````````````form values', values)
    if (values && values.student) setGrade(values.student)

    return (
      <form>
        <Grid container spacing={3}>
          {gradeloading ? <ProgressIndicator fullHeight /> : ''}
          <Grid item sm={12}>
            <StudentGradeSelect required name="student" label="Student" />
          </Grid>
          <Grid item sm={12}>
            <ServiceRequestTypeSelect required name="type" label="Type" />
          </Grid>
          <Grid item sm={12}>
            <ServiceRequestDisciplineSelect name="discipline" label="Discipline" />
          </Grid>
          <Grid item sm={6}>
            {gradeData && gradeData?.studentGrades && gradeData?.studentGrades.length > 0 ? (
              gradeData?.studentGrades?.map((grades) => (
                <TextField
                  style={{ width: '100%' }}
                  variant="filled"
                  name="grade"
                  label="Grade"
                  disabled
                  value={grades.grade}
                  className={classes.labelField}
                />
              ))
            ) : (
              <TextField
                variant="filled"
                style={{ width: '100%' }}
                name="grade"
                label="Grade"
                disabled
                value=""
                className={classes.labelField}
              />
            )}
          </Grid>
          <Grid item sm={6}>
            <TextField
              style={{ width: '100%' }}
              variant="filled"
              name="minutes"
              label="Minutes"
              className={classes.labelField}
            />
          </Grid>
          <Grid item sm={12}>
            <Checkboxes name="group" data={{ label: 'Group therapy', value: true }} />
          </Grid>
          <Grid item sm={12}>
            <FileField
              name="upload"
              subLabel={values.type && `Select the ${requiredFileDescription[values.type]} file`}
              label="Upload"
              required
            />
          </Grid>
          <Grid item sm={12} style={{ marginTop: 24 }}>
            <InfoButton
              variant="contained"
              size="large"
              onClick={handleSubmit}
              style={{ marginRight: 16 }}
            >
              Submit
            </InfoButton>
            <Button variant="outlined" color="primary" size="large" onClick={history.goBack}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <Form<CreateServiceRequestFormModel>
      render={renderForm}
      validate={validator}
      onSubmit={onSubmit}
    />
  )
}
