import { makeStyles, createStyles, Theme, createMuiTheme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    py20: {
      padding: '20px 0px'
    },
    textCenter: {
      textAlign: 'center'
    },
    plr20: {
      padding: '0px 20px 20px 20px'
    },
    dflex: {
      display: 'flex'
    },
    prb10: {
      paddingRight: '10px',
      paddingBottom: '10px'
    },
    pb5: {
      paddingBottom: '5px'
    },
    pt10: {
      paddingTop: '10px'
    }
  })
)
