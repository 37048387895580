import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import StudentsInfo from './components/studentsinfoView'
import { CaseloadStudentDetailView } from '../caseload/studentDetails/studentdetails'

export const StudentsinfoRootView = () => {
  const { path } = useRouteMatch()
  // console.log('students-------------', path)

  return (
    <Switch>
      <Route component={StudentsInfo} />
      <Route component={CaseloadStudentDetailView} />
    </Switch>
  )
}
