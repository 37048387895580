import React, { useState, useMemo, useEffect } from 'react'
import moment from 'moment'
import { useHistory, Route, useParams } from 'react-router-dom'
import { Link, makeStyles, Theme } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import NavBar from 'components/ui/panel/nav-bar/NavBar'
import DeleteIcon from '@material-ui/icons/Delete'
import { theme } from 'config/constants/theme'
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  PageSettingsModel,
  Sort,
  SortSettingsModel,
  Search,
  Toolbar,
  ToolbarItems
} from '@syncfusion/ej2-react-grids'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { UseParamsType } from 'types'
import Button from '../../../components/ui/panel/button/Button'
import { ReactComponent as EditIcon } from '../../../assets/icons/84380.svg'
import { PageContainer } from '../../../components/ui/panel/page-container/PageContainer'
import Blackoutdays from './blackoutdays'
import {
  useCurrentUserQuery,
  useListBlackoutDaysLazyQuery,
  useDeleteBlackoutDaysMutation
} from '../../../graphql/hooks.generated'
import { useStyles } from './blackoutListStyle'

interface UrlParams {
  id: string
}

export const Blackoutdayslist = () => {
  const classes = useStyles()
  const { id } = useParams<UrlParams>()
  const history = useHistory()
  const [blackoutData, setData] = useState<any>()
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const [
    blackoutdaysexecutequery,
    { data: listdata, loading: listLoading }
  ] = useListBlackoutDaysLazyQuery({ fetchPolicy: 'network-only' })
  const fetchblackoutdayslist = useMemo(
    () => ({ user_id, appointment_type_id }) =>
      blackoutdaysexecutequery({ variables: { user_id, appointment_type_id } }),
    [blackoutdaysexecutequery]
  )
  const pageSettings: PageSettingsModel = { pageSizes: true, pageSize: 5, pageCount: 5 }
  const sortSettings: SortSettingsModel = {
    columns: [{ field: 'start_date', direction: 'Ascending' }]
  }
  const toolbarOptions: ToolbarItems[] = ['Search']
  useEffect(() => {
    if (currentUserdata)
      fetchblackoutdayslist({
        user_id: currentUserdata.data?.currentUser.id,
        appointment_type_id: '3'
      })
  }, [currentUserdata, fetchblackoutdayslist])
  const editBlackoutday = (id) => {
    history.push(`/blackoutdays/${id}`)
  }
  const gotoBlackout = () => {
    history.push(`/blackoutdays/create`)
  }
  const [
    deleteBlackoutDays,
    { data: delData, loading: delLoading }
  ] = useDeleteBlackoutDaysMutation()
  const refetchlist = () => {
    fetchblackoutdayslist({
      user_id: currentUserdata.data?.currentUser.id,
      appointment_type_id: '3'
    })
  }
  const onDelete = (id) => {
    deleteBlackoutDays({ variables: { id: String(id) } }).then(() => {
      refetchlist()
    })
  }
  const dateTemplate = (props) => {
    const obj = props

    return <div>{moment(obj.start_date).format('MM-DD-YYYY')}</div>
  }
  const dayPortionTemplate = (props) => {
    const obj = props

    return (
      <div>
        {obj?.day_portion
          ? `Full Day`
          : `${moment(obj?.start_date).format('LT')} - ${moment(obj?.end_date).format('LT')}`}
      </div>
    )
  }
  const commentTemplate = (props) => {
    const obj = props

    return <div>{obj?.title}</div>
  }
  const actionTemplate = (props) => {
    const obj = props

    return (
      <>
        <Link onClick={() => editBlackoutday(obj?.id)}>
          <EditIcon width="18" height="25" style={{ cursor: 'pointer' }} />
        </Link>
        &nbsp;&nbsp;&nbsp;
        <Link onClick={() => onDelete(obj?.id)}>
          <DeleteIcon
            style={{
              color: theme.palette.error.dark,
              cursor: 'pointer'
            }}
          />
        </Link>
      </>
    )
  }

  return (
    <div className={classes.root}>
      {(listLoading || delLoading) && <ProgressIndicator fullHeight />}
      <NavBar title="Blackout Days" goBackBtn />
      <PageContainer>
        <Paper>
          <div className={`${classes.pt15} ${classes.center} ${classes.brandonFF}`}>
            <h1>{`Blackout Dates for ${
              currentUserdata ? currentUserdata?.data?.currentUser?.district?.name : `Admin`
            }`}</h1>
            <div>
              The intent is that this way providers will not inadvertenty schedule therapy over a
              school holiday.
            </div>
          </div>
          <br />
          <Grid>
            {listdata && listdata?.blackoutDays && listdata?.blackoutDays?.length > 0 ? (
              <GridComponent
                key={Math.floor(Math.random() * 100)}
                allowTextWrap
                dataSource={listdata?.blackoutDays}
                allowPaging
                pageSettings={{ pageSizes: [15, 25, 50, 100], pageSize: 15, pageCount: 5 }}
                allowSorting
                sortSettings={sortSettings}
                toolbar={toolbarOptions}
                className={`${classes.headerCls} ${classes.brandonFF}`}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    headerText="DATE"
                    template={dateTemplate}
                    field="start_date"
                    width="100"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    template={dayPortionTemplate}
                    headerText="DAY PROTION"
                    field="start_date"
                    width="100"
                  />
                  <ColumnDirective
                    template={commentTemplate}
                    headerText="COMMENTS"
                    field="title"
                    width="100"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    template={actionTemplate}
                    headerText=""
                    width="80"
                    textAlign="Right"
                  />
                </ColumnsDirective>
                <Inject services={[Page, Sort, Filter, Group, Toolbar]} />
              </GridComponent>
            ) : (
              <div className={`${classes.center} ${classes.brandonFF}`}>No Blackout days</div>
            )}
          </Grid>
          <br />
          <br />
          <div className={`${classes.pb15} ${classes.center}`}>
            <Button
              onClick={gotoBlackout}
              className={`${classes.textLinkClr} ${classes.brandonFF}`}
            >
              Blackout a Day
            </Button>
          </div>
        </Paper>
      </PageContainer>
    </div>
  )
}
