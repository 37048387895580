import React, { useMemo, useEffect } from 'react'
import { Select } from 'mui-rff'
import { MenuItem } from '@material-ui/core'
import { UseParamsType } from 'types'
import { useParams } from 'react-router-dom'
import { useStudentSelectLazyQuery } from '../../../../graphql/hooks.generated'
import { useStyles } from '../Styles'

interface StudentDistrictSelectProps {
  label: string
  name: string
  required?: boolean
}
interface StudentDistrictSelectViewParams extends UseParamsType {
  id: string
}

export const StudentGradeSelect = (props: StudentDistrictSelectProps) => {
  const { id: districtId } = useParams<StudentDistrictSelectViewParams>()
  const classes = useStyles()
  const [executeQuery, { data }] = useStudentSelectLazyQuery({
    variables: { district_id: Number(districtId) },
    fetchPolicy: 'network-only'
  })
  const fetch = useMemo(() => () => executeQuery(), [executeQuery])
  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <>
      {data && data?.students && data?.students?.length > 0 ? (
        <Select
          style={{ width: '100%' }}
          {...props}
          variant="filled"
          className={classes.selectDropdown}
        >
          {data &&
            data?.students?.map((student) => (
              <MenuItem key={student.id} value={student.id.toString()}>
                {student.name}
              </MenuItem>
            ))}
        </Select>
      ) : (
        <Select {...props} variant="filled" className={classes.selectDropdown}>
          <MenuItem>No Data Found</MenuItem>
        </Select>
      )}
    </>
  )
}
