import React from 'react'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import { Typography, useTheme, Grid } from '@material-ui/core'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { useListsurveystudentQuery, useListStudentCaseloadQuery } from 'graphql/hooks.generated'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { UseParamsType } from '../../../../types'
import { useStyles } from './studentstyle'

interface ListUserViewParams extends UseParamsType {
  stu_id: string
}

export const CaseloadStudentDetailView: React.FC = () => {
  const theme = useTheme()
  const classes = useStyles()
  const { stu_id } = useParams<ListUserViewParams>()
  let pscdata: any[] = []
  let craftdata: any[] = []
  const { data: surveystudData, loading: loading1 } = useListsurveystudentQuery({
    fetchPolicy: 'network-only',
    variables: {
      sid: Number(stu_id)
    }
  })
  const { data: studDet, loading: loading2 } = useListStudentCaseloadQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: Number(stu_id)
    }
  })
  const phoneNumberFormat = (phoneNumberString) => {
    console.log()
    const cleaned = phoneNumberString?.replace(/\D/g, '')
    const match = cleaned?.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

    if (match) {
      const intlCode = match[1] ? '+1 ' : ''

      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }

    return phoneNumberString
  }
  const somtimes: any = `Selected "Sometimes" in the survey`
  const crafftSurvey: any = `Answered Yes to CRAFFT Survey questions:`
  const often: any = `Selected "Often" in the survey`

  if (surveystudData && (surveystudData?.surveystudent[4] || surveystudData?.surveystudent[5]))
    pscdata = surveystudData?.surveystudent[4] || surveystudData?.surveystudent[5]
  if (surveystudData && surveystudData?.surveystudent[7])
    craftdata = surveystudData?.surveystudent[7]

  return (
    <>
      {(loading1 || loading2) && <ProgressIndicator fullHeight />}
      <NavBar title="Client Details" goBackBtn />
      <PageContainer>
        <Grid>
          <Typography
            variant="h2"
            className={`${classes.py20} ${classes.textCenter}`}
            style={{ color: theme.palette.secondary.main }}
          >
            <strong>Client Details</strong>
          </Typography>
        </Grid>
        <Grid container className={classes.plr20}>
          <Grid item sm={4} className={`${classes.dflex} ${classes.prb10}`}>
            <Grid item sm={5}>
              <strong> Client Name: </strong>
            </Grid>
            <Grid item sm={7}>
              {`${studDet?.student?.first_name ? studDet?.student?.first_name : ''} ${
                studDet?.student?.last_name ? studDet?.student?.last_name : ''
              }`}
            </Grid>
          </Grid>
          <Grid item sm={4} className={`${classes.dflex} ${classes.prb10}`}>
            <Grid item sm={5}>
              <strong>Client Email: </strong>
            </Grid>
            <Grid item sm={7}>
              {studDet?.student?.contact_email || ''}
            </Grid>
          </Grid>
          <Grid item sm={4} className={`${classes.dflex} ${classes.prb10}`}>
            <Grid item sm={5}>
              <strong>Client Phone: </strong>
            </Grid>
            <Grid item sm={7}>
              {phoneNumberFormat(studDet?.student?.contact_phone_number) || ''}
            </Grid>
          </Grid>
          <Grid item xs={4} className={`${classes.dflex} ${classes.prb10}`}>
            <Grid item sm={5}>
              <strong> Parent Name: </strong>
            </Grid>
            <Grid item sm={7}>
              {studDet?.student?.parent_name || ''}
            </Grid>
          </Grid>
          <Grid item sm={4} className={`${classes.dflex} ${classes.prb10}`}>
            <Grid item sm={5}>
              <strong>Parent Email: </strong>
            </Grid>
            <Grid item sm={7}>
              {studDet?.student?.parent1_email || ''}
            </Grid>
          </Grid>
          <Grid item sm={4} className={`${classes.dflex} ${classes.prb10}`}>
            <Grid item sm={5}>
              <strong>Parent Phone: </strong>
            </Grid>
            <Grid item sm={7}>
              {phoneNumberFormat(studDet?.student?.parent1_phone_number) || ''}
            </Grid>
          </Grid>
          <Grid item sm={4} className={`${classes.dflex} ${classes.prb10}`}>
            <Grid item sm={5}>
              <strong>Special Requests:</strong>
            </Grid>
            <Grid item sm={7}>
              {studDet?.student?.accommodations || ''}
            </Grid>
          </Grid>
          <Grid item sm={12} className={`${classes.pt10} ${classes.pb5}`}>
            <div>
              <strong>PSC Survey: </strong>
            </div>
          </Grid>
          <Grid item sm={6} className={`${classes.pb5}`}>
            <div>
              <strong>{somtimes}</strong>
            </div>
            {pscdata.length > 0 &&
              pscdata.map((item) => (
                <>{item?.score === 1 && <div className={classes.pb5}>{item?.questionName}</div>}</>
              ))}
          </Grid>
          <Grid item sm={6} className={`${classes.pb5}`}>
            <div>
              <strong>{often}</strong>
            </div>
            {pscdata.length > 0 &&
              pscdata.map((item) => (
                <>{item?.score === 2 && <div className={classes.pb5}>{item?.questionName}</div>}</>
              ))}
          </Grid>
          {craftdata.length > 0 && (
            <Grid item sm={12} className={`${classes.pt10} ${classes.pb5}`}>
              <div>
                <strong>{crafftSurvey} </strong>
              </div>
              {craftdata.map((item) => (
                <>
                  <div className={classes.pb5}>{item?.questionName}</div>
                </>
              ))}
            </Grid>
          )}
        </Grid>
      </PageContainer>
    </>
  )
}
