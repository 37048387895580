import { makeStyles, Theme } from '@material-ui/core'

export interface MetricCardStyleProps {
  backgroundColor?: string
}

export default makeStyles<Theme, MetricCardStyleProps>((theme) => ({
  metricNumber: {
    backgroundColor: ({ backgroundColor }) => backgroundColor ?? theme.palette.background.default,
    color: ({ backgroundColor }) =>
      backgroundColor ? theme.palette.common.white : theme.palette.secondary.main,
    fontWeight: 500,
    fontSize: 32
  },
  cardFooter: {
    borderTop: `1px dashed ${theme.palette.divider}`
  }
}))
