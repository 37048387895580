import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import { AssignmentForm as BaseAssignmentForm } from '../form/AssignmentForm'
import { StudentAssignmentForm } from '../form/StudentAssignmentForm'
import { useCreateAssignmentMutation } from '../../../../graphql/hooks.generated'
import { UseParamsType } from '../../../../types'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'

interface CreateAssignmentViewParams extends UseParamsType {
  student: string | undefined
  assignmentType: string | undefined
}

export const CreateAssignmentView = () => {
  const [createAssignment] = useCreateAssignmentMutation()
  const history = useHistory()
  const classes = useStyles()
  const { student, assignmentType } = useParams<CreateAssignmentViewParams>()

  function AssignmentForm(props) {
    if (student && assignmentType)
      return <StudentAssignmentForm student={student} type={assignmentType} {...props} />

    return <BaseAssignmentForm {...props} />
  }

  return (
    <>
      <NavBar title="Assign a Provider " goBackBtn />
      <PageContainer>
        <div className={classes.plr10}>
          <AssignmentForm
            submitLabel="Assign"
            onSubmit={(values) => {
              createAssignment({ variables: { input: values } }).then(history.goBack)
            }}
          />{' '}
        </div>
      </PageContainer>
    </>
  )
}
