import { FieldMetaState } from 'react-final-form'

export interface ShowErrorProps<TFieldValueType> {
  meta: FieldMetaState<TFieldValueType>
}

export function showError<TFieldValueType>({
  meta: { submitError, dirtySinceLastSubmit, error, touched, modified }
}: ShowErrorProps<TFieldValueType>) {
  return !!(((submitError && !dirtySinceLastSubmit) || error) && (touched || modified))
}
