import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { Typography, Grid, Hidden } from '@material-ui/core'
import EndCallButton from '../Buttons/EndCallButton/EndCallButton'
import Menu from './Menu/Menu'
import useRoomState from '../../hooks/useRoomState/useRoomState'
import useVideoContext from '../../hooks/useVideoContext/useVideoContext'
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton'
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton'
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#FAFAFA',
      width: '100%',
      borderBottom: '1px dashed #c7c7c7',
      top: 0,
      left: 0,
      right: 0,
      // height: `10vh`,
      position: 'fixed',
      paddingTop: '0.5em',
      marginTop: '3.7rem',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `56px`,
        padding: 0
      }
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: '72 px',
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white'
      },
      '& button': {
        background: 'white',
        border: `2px solid `,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7'
        }
      }
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  })
)
interface MenuBarprops {
  clientdata: any
}

export default function MenuBar({ clientdata }: MenuBarprops) {
  const classes = useStyles()
  const { isSharingScreen, toggleScreenShare } = useVideoContext()
  const roomState = useRoomState()
  const isReconnecting = roomState === 'reconnecting'
  const { room } = useVideoContext()

  return (
    <>
      {/* {isSharingScreen && (
        <Grid container className={classes.screenShareBanner}>
          <Typography variant="h6">You are sharing your screen</Typography>
          <Button onClick={() => toggleScreenShare()}>Stop Sharing</Button>
        </Grid>
      )} */}
      <footer className={classes.container}>
        <Grid
          container
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '3.5rem'
          }}
        >
          <Grid
            item
            xs={12}
            sm={3}
            md={4}
            lg={5}
            style={{ padding: '1rem', justifyContent: 'center' }}
          >
            <Typography variant="body1">{room!.name}</Typography>
          </Grid>
          <Grid item xs={12} sm={7} md={8} lg={6} style={{ padding: '5px' }}>
            <ToggleAudioButton disabled={isReconnecting} />
            <ToggleVideoButton disabled={isReconnecting} />
            {!isSharingScreen && <ToggleScreenShareButton disabled={isReconnecting} />}
            <Menu />

            <EndCallButton clientdata={clientdata} />
          </Grid>
        </Grid>
      </footer>
    </>
  )
}
