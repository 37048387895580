import { useHistory, useParams } from 'react-router-dom'
import { Form, FormRenderProps } from 'react-final-form'
import { Checkboxes, DatePicker, TextField } from 'mui-rff'
import { Grid, Link, Typography } from '@material-ui/core'
import React, { useMemo, useEffect, useState } from 'react'
import { Decorator } from 'final-form'
import { PhoneInput } from 'components/ui/form/phone-input/PhoneInput'
import { StateSelect } from 'components/ui/form/state-select/StateSelect'
import { theme } from 'config/constants/theme'
import { UserStatusSelect } from 'components/ui/form/user-status-select/UserStatusSelect'
import { TimeZoneSelect } from 'components/ui/form/time-zone-select/TimeZoneSelect'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import ClearIcon from '@material-ui/icons/Clear'
import {
  useUserListDistrictsSelectLazyQuery,
  useDeleteUserListDistrictMutation,
  useUpdateAddStudentPermissionMutation,
  useDisplayAddStudentPermissionLazyQuery,
  useCurrentUserQuery,
  useUpdateAddStudentPermissionForUserDistrictsMutation,
  useUserQuery,
  Role,
  useDistrictSelectLazyQuery
} from 'graphql/hooks.generated'
import { Section } from '../../../../components/ui/panel/section/Section'
import { DistrictSelect } from '../../../../components/ui/form/district-select/DistrictSelect'
import { DistrictTherapistSelect } from '../../../../components/ui/form/district-therapist-select/DistrictTherapistSelect'
import { InfoButton } from '../../../../components/ui/panel/button/InfoButton'
import Button from '../../../../components/ui/panel/button/Button'
import { useCurrentUser } from '../../../../graphql/hooks/useCurrentUser/useCurrentUser'
import { TherapistValidator } from './therapistFormValidation'
import { calculator } from '../../users/form/userCalculator'
import { UserFormModel } from '../../users/form/UserFormModel'
import { TherapistDisciplineSelect } from '../../../../components/ui/form/therapist-discipline-select/TherapistDisciplineSelect'
import { UseParamsType } from '../../../../types'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'

export interface UserFormProps {
  onSubmit(values: UserFormModel): void
  submitLabel: string
  initialValues?: Partial<UserFormModel> | null
  decorators?: Decorator<UserFormModel, Partial<UserFormModel>>[]
}
interface UserListDistrictsSelectViewParams extends UseParamsType {
  user_id: string
  id: any
}

export const TherapistEditForm = (props: UserFormProps) => {
  const classes = useStyles()
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  /// const [deleteDistData, setDeleteDistData] = useState([])
  const [districtsSelected, setDistrictsSelected] = useState([] as any)
  const [deleteDistArray, setDeleteDistArray] = useState([] as any)
  const [disableAddNewDistrict, setDisableAddNewDistrict] = useState(true)
  const [distErrorMessage, setDistErrorMessage] = useState(false)
  const deleteDistData: any = []
  const { user_id: userId, id: districtId } = useParams<UserListDistrictsSelectViewParams>()
  const { data: userDataForDistrictId, loading: loadingUserData } = useUserQuery({
    variables: { id: userId },
    fetchPolicy: 'network-only'
  })
  const [
    executeDistrictQuery,
    { data: districtSelectData, loading: loadingDistrictSelect }
  ] = useDistrictSelectLazyQuery({
    fetchPolicy: 'network-only'
  })
  const DistIdForPrimaryDistrict = userDataForDistrictId?.user.district?.id
  const [
    executeQuery,
    { data, loading: loadingUserListDistrictsSelect }
  ] = useUserListDistrictsSelectLazyQuery({
    variables: { user_id: String(userId) },
    fetchPolicy: 'network-only'
  })
  const [
    executeSecondaryQuery,
    { data: displayAddStudentData, loading: loadingDisplayAddStudentPermission }
  ] = useDisplayAddStudentPermissionLazyQuery({
    fetchPolicy: 'network-only'
  })
  // Update Add Student Permission for primary district
  const [
    executeAddStudentPermissionQuery,
    { loading: loadingUpdateAddStudentPermission }
  ] = useUpdateAddStudentPermissionMutation()
  const fetchAddStudentPermission = useMemo(
    () => (userId, input) =>
      executeAddStudentPermissionQuery({
        variables: { user_id: String(userId), input }
      }),
    [executeAddStudentPermissionQuery]
  )
  const fetchDistSelect = useMemo(
    () => (query = '') =>
      executeDistrictQuery({
        variables: {
          input: { query }
        }
      }),
    [executeDistrictQuery]
  )
  // Update Add Student Permission for multiple district
  const [
    executeUpdateAddStudentPermissionForUserDistrictsQuery,
    { loading: loadingUpdateAddStudentPermissionForUserDistricts }
  ] = useUpdateAddStudentPermissionForUserDistrictsMutation()
  const fetchUpdateAddStudentPermissionForUserDistricts = useMemo(
    () => (userId, input) =>
      executeUpdateAddStudentPermissionForUserDistrictsQuery({
        variables: { user_id: String(userId), input }
      }),
    [executeUpdateAddStudentPermissionForUserDistrictsQuery]
  )
  const fetch = useMemo(() => () => executeQuery(), [executeQuery])
  // display Add students
  const SecondaryDistfetch = useMemo(
    () => ({ user_id, id }) =>
      executeSecondaryQuery({
        variables: { user_id, id }
      }),
    [executeSecondaryQuery]
  )
  let did

  if (!districtId && currentUserdata) did = currentUserdata?.data?.currentUser.district?.id
  else did = districtId

  // Disable add student button for School_admin
  const RolesList = currentUserdata?.data?.currentUser.roles ?? []
  const ListOFRoles = RolesList.toString()
  const ExpectedRole = [Role.SchoolAdmin].toString()
  // Enable primary district to the district admin role
  const CurrdistId = currentUserdata.data?.currentUser.district?.id.toString()
  const DistrictAdminId = [Number(currentUserdata.data?.currentUser.district?.id)]
  const PrimaryDistId = userDataForDistrictId?.user.district?.id.toString()
  const GroupDistIds = data?.userListDistricts ?? []
  const multiDistId = GroupDistIds.map(function (val) {
    return val.district_id
  })
  const common = DistrictAdminId.filter((x) => multiDistId.indexOf(x) !== -1).toString()
  const DisablePrimaryDistData = ListOFRoles === ExpectedRole && CurrdistId !== PrimaryDistId
  const DisableMultiDistData = ListOFRoles === ExpectedRole
  useEffect(() => {
    window.scrollTo(0, 0)
    fetchDistSelect()
    fetch()
    let input

    if (!districtId && DistIdForPrimaryDistrict) {
      input = {
        user_id: userId,
        id: DistIdForPrimaryDistrict
      }
      SecondaryDistfetch(input)
    } else {
      input = {
        user_id: userId,
        id: DistIdForPrimaryDistrict
      }
      SecondaryDistfetch(input)
    }
  }, [
    fetch,
    SecondaryDistfetch,
    userId,
    districtId,
    DistIdForPrimaryDistrict,
    currentUserdata,
    fetchDistSelect
  ])
  const { user } = useCurrentUser()
  // const { id } = useParams<UpdateUserViewParams>()
  const history = useHistory()
  const { initialValues } = props
  const [
    deleteUserList,
    { loading: loadingDeleteUserListDistrict }
  ] = useDeleteUserListDistrictMutation()
  const onDeleteUserDistricts = async (deleteDistData, user_id) => {
    await deleteUserList({
      variables: {
        user_id,
        input: deleteDistData
      }
    })
    // refetch()
  }
  const onSubmit = (values: UserFormModel) => {
    // Create multiple dist data
    setDistErrorMessage(false)
    let distErrorCheck = false
    const CurrentUserId = currentUserdata.data?.currentUser.id
    const CreateMultiDistData = values.therapistDistrict
      .filter((filterData) => filterData && !filterData.user_id)
      .map((item) => {
        // if (!item.user_id && item) {

        return {
          // ...item,
          district_id: item.district_id,
          alt_id: item.alt_id ? item.alt_id.toString() : '0',
          hourly_rate: item.hourly_rate ? item.hourly_rate : '0',
          add_student_permission: item.add_student_permission ? item.add_student_permission : false,
          status: item.status ? item.status : false,
          user_id: userId,
          created_by: CurrentUserId,
          updated_by: CurrentUserId
        }
      })

    if (CreateMultiDistData && CreateMultiDistData.length)
      CreateMultiDistData.map((distMessage) => {
        if (!distMessage.district_id) {
          distErrorCheck = true
          setDistErrorMessage(true)
        }

        return true
      })
    if (distErrorCheck) return

    const primaryValues = {
      add_student_permission: !!values?.add_student_permission,
      alt_id: values?.altId ? values?.altId : '0',
      hourly_rate: values?.hourlyRate ? values?.hourlyRate : '0',
      status: !!values?.status
    }
    onDeleteUserDistricts(deleteDistArray, userId)
    fetchAddStudentPermission(userId, primaryValues)
    // Update dist data
    const updateDistData = values?.therapistDistrict
      .filter((filterData) => filterData && filterData.user_id)
      .map((UpdateUserListDistricts) => {
        // if (UpdateUserListDistricts.user_id) {
        return {
          district_id: UpdateUserListDistricts.district_id,
          alt_id: UpdateUserListDistricts.alt_id ? UpdateUserListDistricts.alt_id.toString() : '0',
          hourly_rate: UpdateUserListDistricts.hourly_rate
            ? UpdateUserListDistricts.hourly_rate
            : '0',
          add_student_permission: UpdateUserListDistricts.add_student_permission,
          status: UpdateUserListDistricts.status
        }
        // }
      })
    fetchUpdateAddStudentPermissionForUserDistricts(userId, updateDistData)
    // console.log('===========================', CreateMultiDistData)
    const datValues = {
      ...values,
      therapistDistrict: CreateMultiDistData
    }
    props.onSubmit(datValues)
  }
  const validator = useMemo(() => TherapistValidator(user), [user])
  const renderForm = (renderProps: FormRenderProps<UserFormModel>) => {
    const {
      handleSubmit,
      /// values,
      // pristine,
      submitting,
      form: {
        mutators: { push }
      },
      values
      // form
    } = renderProps
    // delete row & store in array
    const deleteDist = (index) => {
      const dlist = values.therapistDistrict
      dlist.map((item, ind) => {
        if (index === ind && item?.user_id)
          // console.log('delete dist id', typeof item.district_id)
          setDeleteDistArray([...deleteDistArray, { district_id: item.district_id }])
        if (index === ind && !item?.user_id) setDisableAddNewDistrict(true)

        return true
      })
    }

    // primary dist add student checkbox selection
    if (displayAddStudentData && displayAddStudentData?.displayAddStudentPermission?.length)
      displayAddStudentData?.displayAddStudentPermission?.map((displayAddStudent) => {
        if (initialValues) {
          initialValues.add_student_permission = displayAddStudent?.add_student_permission
          initialValues.altId = displayAddStudent?.alt_id?.toString()
          initialValues.hourlyRate = displayAddStudent?.hourly_rate
          initialValues.status = !!displayAddStudent?.status
        }

        return true
      })

    // display secondary dist except primary dist
    let filterDistdataForPermission

    if (data && data?.userListDistricts) {
      filterDistdataForPermission = data?.userListDistricts

      if (initialValues)
        initialValues.therapistDistrict = filterDistdataForPermission.map((item) => ({
          ...item,
          disabled: true,
          DistDisable: true
        }))
      if (ListOFRoles === ExpectedRole && initialValues)
        initialValues.therapistDistrict = initialValues.therapistDistrict.filter((item) => {
          DistrictAdminId.map((id) => {
            const UniqueId = [item?.district_id]
            const common = DistrictAdminId.filter((x) => UniqueId.indexOf(x) !== -1).toString()

            if (common === id.toString()) {
              const DistrictAdminPermission = (item.disabled = false) && (item.DistDisable = true)
            }

            return true
          })

          return true
        })
    }

    const filterDistricts = () => {
      let newSetArray: any
      const secondaryDistIDs: any = []
      const primaryDistId = values?.district ? [Number(values?.district)] : values?.district
      const secondaryDistID = values?.therapistDistrict?.map((dist: { district_id: any }) => {
        if (dist?.district_id) secondaryDistIDs.push(Number(dist.district_id))

        return true
      })

      if (primaryDistId) newSetArray = [...new Set([...primaryDistId, ...secondaryDistIDs])]

      const therapistDistrict = values?.therapistDistrict
      let districts: any

      if (therapistDistrict?.length > 0)
        districts = therapistDistrict?.map((dist, i) => {
          const r = districtSelectData?.districts?.filter(
            (elem) => !newSetArray.find((id) => Number(elem.id) === id)
          )

          return r
        })
      else
        districts = districtSelectData?.districts?.filter(
          (elem) => !newSetArray.find((id) => Number(elem.id) === id)
        )

      return therapistDistrict?.length > 0 ? districts[districts?.length - 1] : districts
    }
    const newDistrictAdd = () => {
      const distSelectArr = filterDistricts()
      push('therapistDistrict', {
        add_student_permission: false,
        alt_id: undefined,
        disabled: false,
        DistDisable: false,
        hourly_rate: '',
        status: false,
        selectArray: distSelectArr
      })
      setDisableAddNewDistrict(false)
    }
    // console.log('-------------------', initialValues)

    return (
      <form style={{ margin: '0 auto', paddingBottom: '10px', width: '98%' }}>
        {loadingDeleteUserListDistrict ||
        loadingUserListDistrictsSelect ||
        loadingUpdateAddStudentPermission ||
        loadingUpdateAddStudentPermissionForUserDistricts ||
        loadingUserData ||
        loadingDisplayAddStudentPermission ? (
          <ProgressIndicator fullHeight />
        ) : (
          <></>
        )}
        <Section title="Edit Account" />
        <Grid>
          <Typography variant="h2" style={{ color: theme.palette.secondary.main }}>
            <section style={{ padding: '25px 440px', width: '100px' }}>
              <strong>Name</strong>
            </section>
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <Grid container spacing={5}>
            <Grid item sm={5} container alignItems="center">
              <strong>First Name:</strong>
              <TextField
                required
                variant="filled"
                name="firstName"
                label="FirstName"
                className={classes.labelField}
              />
            </Grid>
            <Grid item sm={5} container alignItems="center">
              <strong>Middle Initial:</strong>
              <TextField
                variant="filled"
                name="middleInitial"
                label="Middle initial"
                className={classes.labelField}
              />
            </Grid>
            <Grid item sm={5} container alignItems="center">
              <strong>Last Name:</strong>
              <TextField
                required
                variant="filled"
                name="lastName"
                label="LastName"
                className={classes.labelField}
              />
            </Grid>
          </Grid>
          <Grid>
            <Typography variant="h2" style={{ color: theme.palette.secondary.main }}>
              <section style={{ padding: '10px 440px' }}>
                <strong>Contact Info</strong>
              </section>
            </Typography>
          </Grid>
          &nbsp;
          <Grid className="App" container spacing={2}>
            <Grid item sm={3}>
              <strong>District:</strong>
              <DistrictSelect disabled required name="district" label="District" />
            </Grid>
            <Grid item sm={2} container alignItems="center">
              <section style={{ padding: '5px 90px' }}>
                <strong>Active:</strong>
                <Checkboxes
                  disabled={DisablePrimaryDistData}
                  name="status"
                  data={[{ label: '', value: false }]}
                />
              </section>
            </Grid>
            <Grid item sm={2}>
              <section>
                <strong>Alt ID:</strong>
                <TextField
                  disabled={DisablePrimaryDistData}
                  variant="filled"
                  name="altId"
                  label=" "
                  className={classes.labelField}
                />
              </section>
            </Grid>
            <Grid item sm={2}>
              <section>
                <strong>Hourly Pay Rate:</strong>
                <TextField
                  disabled={DisablePrimaryDistData}
                  variant="filled"
                  name="hourlyRate"
                  label=" "
                  className={classes.labelField}
                />
              </section>
            </Grid>
            <Grid item sm={1} container alignItems="center">
              <section style={{ padding: '5px 90px' }}>
                <strong>Add Client:</strong>
                <Checkboxes
                  disabled={DisablePrimaryDistData}
                  name="add_student_permission"
                  data={{ label: '', value: false }}
                />
              </section>
            </Grid>
          </Grid>
          <>
            <FieldArray style={{ width: '100%' }} name="therapistDistrict">
              {({ fields }) => {
                // setDistrictsSelected([...new Set([...districts, ...districtsSelected])])
                const FielDComponent = fields.map((name1, index) => {
                  return (
                    <Grid key={name1} container spacing={2}>
                      <Grid item sm={3}>
                        <strong>District:</strong>
                        <DistrictTherapistSelect
                          disabled={
                            values?.therapistDistrict[index]?.user_id &&
                            (values?.therapistDistrict[index]?.DistDisable ||
                              values?.therapistDistrict[index]?.disabled)
                          }
                          name={`${name1}.district_id`}
                          required
                          label="District"
                          districts={
                            fields?.value[index]?.selectArray || districtSelectData?.districts
                          }
                        />
                      </Grid>
                      <Grid item sm={2} container alignItems="center">
                        <section style={{ padding: '5px 90px' }}>
                          <strong>Active:</strong>
                          <Checkboxes
                            disabled={
                              initialValues?.therapistDistrict[index]?.disabled &&
                              DisableMultiDistData
                            }
                            name={`${name1}.status`}
                            data={[{ label: '', value: true }]}
                          />
                        </section>
                      </Grid>
                      <Grid item sm={2}>
                        <section>
                          <strong>Alt ID:</strong>
                          <TextField
                            disabled={
                              initialValues?.therapistDistrict[index]?.disabled &&
                              DisableMultiDistData
                            }
                            name={`${name1}.alt_id`}
                            variant="filled"
                            label=" "
                            className={classes.labelField}
                          />
                        </section>
                      </Grid>
                      <Grid item sm={2}>
                        <section>
                          <strong>Hourly Pay Rate:</strong>
                          <TextField
                            disabled={
                              initialValues?.therapistDistrict[index]?.disabled &&
                              DisableMultiDistData
                            }
                            name={`${name1}.hourly_rate`}
                            variant="filled"
                            label=" "
                            className={classes.labelField}
                          />
                        </section>
                      </Grid>
                      <Grid item sm={1} container alignItems="center">
                        <section style={{ padding: '5px 90px' }}>
                          <strong>Add Client:</strong>
                          <Checkboxes
                            disabled={
                              initialValues?.therapistDistrict[index]?.disabled &&
                              DisableMultiDistData
                            }
                            name={`${name1}.add_student_permission`}
                            data={[{ label: '', value: true }]}
                          />
                        </section>
                      </Grid>
                      <Grid
                        item
                        sm={2}
                        style={{ width: '242px', padding: '42.438px 95px', cursor: 'pointer' }}
                        onClick={() => {
                          if (ListOFRoles !== ExpectedRole) {
                            deleteDist(index)
                            fields.remove(index)
                          }
                        }}
                      >
                        <ClearIcon />
                      </Grid>
                    </Grid>
                  )
                })

                return FielDComponent
              }}
            </FieldArray>
          </>
          <Grid item sm={5}>
            <Link
              disabled={
                !disableAddNewDistrict ||
                ListOFRoles === ExpectedRole ||
                (values.therapistDistrict?.length &&
                  !values.therapistDistrict[values.therapistDistrict.length - 1].user_id)
              }
              onClick={() =>
                disableAddNewDistrict ? newDistrictAdd() : setDisableAddNewDistrict(false)
              }
              color="inherit"
              component="button"
              value="0"
            >
              <Typography>New District Membership</Typography>
            </Link>
          </Grid>
          &nbsp;&nbsp;&nbsp;
          <Grid container spacing={5}>
            <Grid item sm={5} container alignItems="center">
              <strong>Email Address:</strong>
              <TextField
                required
                variant="filled"
                name="email"
                label="Email address"
                className={classes.labelField}
              />
            </Grid>
            <Grid item sm={5} container alignItems="center">
              <strong>Street Address:</strong>
              <TextField
                required
                variant="filled"
                name="streetAddress"
                label="Street Address"
                className={classes.labelField}
              />
            </Grid>
            <Grid item sm={5} container alignItems="center">
              <strong>Mobile Phone:</strong>
              <PhoneInput required variant="filled" name="mobilePhone" label="Mobile Phone" />
            </Grid>
            <Grid item sm={5} container alignItems="center">
              <strong>Home Phone:</strong>
              <PhoneInput required variant="filled" name="homePhone" label="Home Phone" />
            </Grid>
            <Grid item sm={5} container alignItems="center">
              <section style={{ padding: '0px 3px' }}>
                <strong>Time Zone:</strong>
              </section>
              <TimeZoneSelect name="timeZone" label="Time Zone" />
            </Grid>
            <Grid item sm={5} container alignItems="center">
              <strong>City:</strong>
              <TextField
                required
                variant="filled"
                name="city"
                label="City"
                className={classes.labelField}
              />
            </Grid>
            <Grid item sm={5} container alignItems="center">
              <strong>Zip Code:</strong>
              <TextField
                required
                variant="filled"
                name="zip"
                label="Zip Code"
                className={classes.labelField}
              />
            </Grid>
          </Grid>
          <Grid>
            <Typography variant="h2" style={{ color: theme.palette.secondary.main }}>
              <section style={{ padding: '25px 440px' }}>Administrative Details</section>
            </Typography>
          </Grid>
          <Grid container spacing={5}>
            <Grid item sm={5} container alignItems="center">
              <strong>User Roles:</strong>
              <TherapistDisciplineSelect
                required
                variant="filled"
                name="therapistDiscipline"
                label="Provider discipline"
              />
            </Grid>
            <Grid item sm={5} container alignItems="center">
              <strong>Status:</strong>
              <UserStatusSelect label="Status" name="status" />
            </Grid>
            <Grid item sm={5} container alignItems="center">
              <strong>License Number:</strong>
              <TextField
                required
                variant="filled"
                name="licenseNumber"
                label="License Number"
                className={classes.labelField}
              />
            </Grid>
            <Grid item sm={5} container alignItems="center">
              <strong>State License:</strong>
              <StateSelect name="stateLicense" label="State License" />
            </Grid>
            <Grid item sm={5} container alignItems="center">
              <strong>Cert Date:</strong>
              <DatePicker
                clearable
                maxDate={new Date()}
                name="certDate"
                label="Cert Date"
                inputVariant="filled"
                format="MM/dd/yyyy"
                className={classes.labelField}
              />
            </Grid>
            <Grid item sm={5} container alignItems="center">
              <strong>Cert Expire Date:</strong>
              <DatePicker
                clearable
                name="certExpireDate"
                label="Cert Expire Date"
                inputVariant="filled"
                format="MM/dd/yyyy"
                className={classes.labelField}
              />
            </Grid>
          </Grid>
        </Grid>
        {distErrorMessage && (
          <Grid>
            <div style={{ color: 'red', paddingTop: '15px' }}>
              Please select the user district from the dropdown select !
            </div>
          </Grid>
        )}
        <Grid item sm={12} style={{ paddingTop: 35 }}>
          <InfoButton
            className="buttons"
            size="large"
            variant="contained"
            onClick={handleSubmit}
            style={{ marginRight: 12 }}
            disabled={submitting}
          >
            {props.submitLabel}
          </InfoButton>
          <Button size="large" variant="outlined" color="primary" onClick={history.goBack}>
            Cancel
          </Button>
        </Grid>
      </form>
    )
  }

  return (
    <Form<UserFormModel>
      initialValues={initialValues ?? {}}
      validate={validator}
      render={renderForm}
      onSubmit={onSubmit}
      decorators={[calculator]}
      mutators={{
        ...arrayMutators
      }}
    />
  )
}
