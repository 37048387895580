import React from 'react'
import { TableCell as MTableCell, TableCellProps as MTableCellProps } from '@material-ui/core'
import { useStyles } from './Styles'

export type TableCellProps = MTableCellProps

export const TableCell = (props: TableCellProps) => {
  const styles = useStyles()

  return <MTableCell classes={{ root: styles.root }} {...props} />
}
