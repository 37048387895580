import { Form, FormRenderProps } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import { Box, Grid, Link, Theme, Typography } from '@material-ui/core'
import { theme } from 'config/constants/theme'
import { makeValidate, TextField } from 'mui-rff'
import React, { useState } from 'react'
import * as yup from 'yup'
import InputAdornment from '@material-ui/core/InputAdornment'
import MailIcon from '@material-ui/icons/Mail'
import HttpsIcon from '@material-ui/icons/Https'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from 'components/ui/panel/icon-button/IconButton'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { LoginFormModel } from './LoginFormModel'
import { InfoButton } from '../../panel/button/InfoButton'

export type LoginFormProps = LoginFormSubmitProps | LoginFormUrlProps

interface LoginFormSubmitProps {
  onSubmit(values: LoginFormModel): void
}

interface LoginFormUrlProps {
  loginUrl: string | undefined
}

const validator = makeValidate(
  yup.object<LoginFormModel>({
    username: yup.string().label('Email').required(),
    password: yup.string().label('Password').required()
  })
)
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1)
    },
    rightIcon: {
      '& :visited': { color: 'blue' },
      '& :hover': { color: 'blue' },
      '& :active': { color: 'blue' }
    },
    leftIcon: {
      color: 'slategray'
    },
    font: {
      '& .MuiFilledInput-input': {
        fontSize: '16px'
      }
    }
  })
)

export const LoginForm = (props: LoginFormProps) => {
  const [shownewPaswd, toggleNewPaswd] = useState<boolean>(false)
  const { onSubmit } = 'onSubmit' in props ? props : ({} as LoginFormSubmitProps)
  let formProps: { method?: string; action?: string | undefined }
  const classes = useStyles()

  if ('onSubmit' in props) formProps = {}
  else {
    const { loginUrl } = props
    formProps = {
      method: 'POST',
      action: loginUrl
    }
  }

  const history = useHistory()
  const renderForm = (renderProps: FormRenderProps<LoginFormModel>) => {
    const { handleSubmit } = renderProps
    const buttonProps = 'onSubmit' in props ? { onClick: handleSubmit } : {}
    const handleEnterKey = (ev) => {
      // console.log(`Pressed keyCode ${ev.key}`)
      if (ev.key === 'Enter') ev.preventDefault()
    }

    return (
      <form {...formProps}>
        <Grid container spacing={3} style={{ fontFamily: 'Brandon Grotesque', fontSize: '16px' }}>
          <Grid item sm={12}>
            <TextField
              variant="filled"
              className={classes.font}
              name="username"
              label="Email"
              onKeyPress={(ev) => handleEnterKey(ev)}
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              variant="filled"
              fullWidth
              name="password"
              id="password"
              label="Password"
              type={shownewPaswd ? 'text' : 'password'}
              onKeyPress={(ev) => handleEnterKey(ev)}
              InputProps={{
                endAdornment: (
                  <InputAdornment className={classes.rightIcon} position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => toggleNewPaswd(!shownewPaswd)}
                      edge="end"
                    >
                      {shownewPaswd ? (
                        <Visibility style={{ color: 'slategray' }} />
                      ) : (
                        <VisibilityOff style={{ color: 'slategray' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Link
            color="inherit"
            component="button"
            style={{ marginLeft: '13px', color: theme.palette.info.dark }}
            onClick={() => history.push(`/forgotpassword`)}
          >
            Forgot password?
          </Link>
          <Grid item sm={12}>
            <Box display="flex" p={0} justifyContent="center" alignItems="center">
              <InfoButton
                style={{ fontSize: '18px' }}
                variant="contained"
                size="large"
                type="submit"
                {...buttonProps}
              >
                Login
              </InfoButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <Form<LoginFormModel>
      render={renderForm}
      validate={validator}
      onSubmit={onSubmit || (() => undefined)}
    />
  )
}
