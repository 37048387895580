import React, { useMemo, useState, createContext } from 'react'
import { useParams } from 'react-router-dom'
import { UseParamsType } from 'types'
import AppStateProvider, { useAppState } from '../../private/newSessions/view/video/state'
import { VideoProvider } from '../../private/newSessions/view/video/components/VideoProvider'
import VideoSession from '../../private/newSessions/view/video'
import ErrorDialog from '../../private/newSessions/view/video/components/ErrorDialog/ErrorDialog'
import useConnectionOptions from '../../private/newSessions/view/video/utils/useConnectionOptions/useConnectionOptions'

interface ListTokenParams extends UseParamsType {
  token: string
  loc: string
}

enum Steps {
  roomNameStep,
  deviceSelectionStep
}

export const VideoContext = createContext('')

export const VideoView = () => {
  const { error, setError } = useAppState()
  const connectionOptions = useConnectionOptions()
  const { token, loc } = useParams<ListTokenParams>()
  const [auth_token, setAuthToken] = useState<string>()
  let getLoc

  if (loc)
    if (loc === '1') getLoc = 'en'
    else getLoc = 'es'
  else getLoc = 'en'

  useMemo(() => {
    setAuthToken(token)
  }, [setAuthToken, token])
  // Validate Token

  return (
    <VideoContext.Provider value={getLoc}>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <VideoSession authToken={auth_token || ''} />
      </VideoProvider>
    </VideoContext.Provider>
  )
}
