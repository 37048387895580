import NavBar from 'components/ui/panel/nav-bar/NavBar'
import { PageContainer } from 'components/ui/panel/page-container/PageContainer'
import { useCreateIcdCodeMutation } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useErrorHandler } from 'graphql/hooks/useErrorHandler/useErrorHandler'
import { IcdCodeForm, IcdCodeFormModel } from '../form/IcdCodeForm'

export const CreateIcdCodeView = () => {
  const [createIcdCode] = useCreateIcdCodeMutation()
  const handleError = useErrorHandler()
  const history = useHistory()
  const handleFormSubmit = (values: IcdCodeFormModel) =>
    createIcdCode({
      variables: {
        input: {
          ...values
        }
      }
    })
      .then(() => {
        history.push('/icdCodes')
      })
      .catch(handleError)

  return (
    <>
      <NavBar title="Add a IcdCode" goBackBtn />
      <PageContainer>
        <IcdCodeForm onSubmit={handleFormSubmit} submitLabel="Create ICD Code" />
      </PageContainer>
    </>
  )
}
