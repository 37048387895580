import { Grid } from '@material-ui/core'
import { makeValidate, TextField } from 'mui-rff'
import React from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { SchoolStatusSelect } from 'components/ui/form/school-status-select/SchoolStatusSelect'
import {
  LocationCodeSelect,
  LOCATION_CODES
} from '../../../../components/ui/form/location-code-select/LocationCodeSelect'
import { DistrictSelect } from '../../../../components/ui/form/district-select/DistrictSelect'
import { StateSelect } from '../../../../components/ui/form/state-select/StateSelect'
import Button from '../../../../components/ui/panel/button/Button'
import { InfoButton } from '../../../../components/ui/panel/button/InfoButton'
import { Section } from '../../../../components/ui/panel/section/Section'
import { SchoolStatus } from '../../../../graphql/hooks.generated'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'

export interface SchoolsFormModel {
  city: string
  district: number
  location_code: string
  name: string
  state: number
  status: SchoolStatus
  street_address: string
  zip: string
}

type SchoolFormProps = {
  onSubmit(values: SchoolsFormModel): void
  submitLabel: string
  initialValues?: Partial<SchoolsFormModel> | null
}
const validator = makeValidate(
  yup.object<SchoolsFormModel>({
    city: yup.string().label('City').required(),
    district: yup.number().label('District').required(),
    location_code: yup.mixed().oneOf(LOCATION_CODES).label('location code').required(),
    name: yup.string().label('School Name').required(),
    state: yup.number().label('State').required(),
    status: yup.mixed<SchoolStatus>().label('Status').required(),
    street_address: yup.string().label('Street Address').required(),
    zip: yup.string().label('Zip').required()
  })
)

export const SchoolForm = (props: SchoolFormProps) => {
  const history = useHistory()
  const classes = useStyles()
  const { initialValues } = props
  const onSubmit = (values: SchoolsFormModel) => props.onSubmit(values)
  const renderForm = (renderProps: FormRenderProps<SchoolsFormModel>) => {
    const { handleSubmit } = renderProps

    return (
      <form>
        <Grid container spacing={4} style={{ maxWidth: '800px' }}>
          <Grid item sm={12}>
            <Section title="Name">
              <TextField
                required
                fullWidth
                variant="filled"
                name="name"
                label="School name"
                className={classes.labelField}
              />
            </Section>
          </Grid>
          <Grid item sm={12}>
            <Section title="Location">
              <Grid container spacing={3}>
                <Grid item sm={6}>
                  <TextField
                    required
                    variant="filled"
                    name="street_address"
                    label="Street Address"
                    className={classes.labelField}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    required
                    variant="filled"
                    name="city"
                    label="City"
                    className={classes.labelField}
                  />
                </Grid>
                <Grid item sm={6}>
                  <StateSelect name="state" label="State" required />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    required
                    variant="filled"
                    name="zip"
                    label="Zip"
                    className={classes.labelField}
                  />
                </Grid>
                <Grid item sm={6}>
                  <DistrictSelect disabled name="district" label="District" required />
                </Grid>
                <Grid item sm={6}>
                  <LocationCodeSelect required label="Location Code" name="location_code" />
                </Grid>
              </Grid>
            </Section>
          </Grid>
          <Grid item sm={12}>
            <Section title="Status">
              <SchoolStatusSelect required label="Status" name="status" />
            </Section>
          </Grid>
          <Grid item sm={12} style={{ paddingTop: 35, marginLeft: 5 }}>
            <InfoButton
              size="large"
              variant="contained"
              onClick={handleSubmit}
              style={{ marginRight: 12 }}
            >
              {props.submitLabel}
            </InfoButton>
            <Button size="large" variant="outlined" color="primary" onClick={history.goBack}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <Form<SchoolsFormModel>
      initialValues={initialValues ?? {}}
      validate={validator}
      render={renderForm}
      onSubmit={onSubmit}
    />
  )
}
