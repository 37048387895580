import { Step } from 'react-joyride'
import ttAdmin from './tt-admin'
import schoolDistrictAdm from './school-district-adm'
import schoolTherapist from './school-therapist'
import therapist from './therapist'
import parent from './parent'
import { Role } from '../../../../graphql/hooks.generated'

type TourSteps = Record<Role, Step[]>

export default {} as TourSteps
