import React from 'react'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import { Typography, Box, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useStyles from './Styles'
import './custom.css'
// import cdhLogo from '../../../../assets/illustrations/colorado_img.png'

interface FooterProps {
  ispublic?: boolean
}

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const { ispublic } = props

  return (
    <Box className={`${classes.root} ${ispublic ? classes.footerPublic : classes.footerPrivate}`}>
      {/* <Typography align="right" variant="body2">
        Do you have a bug to report or suggestions for new features? <a href="/">Contact us</a>
      </Typography> */}
      <Grid container>
        <Grid item style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
          <TooltipComponent
            content={
              window.location.href.split('/').slice(-1)[0] === '2' ||
              window.location.href.split('/').slice(-2, -1)[0] === '2'
                ? `
                ${t(
                  'teleteacherssp.teletextsp'
                )}</br></br><a style="color: white" href="https://teleteachers.com/" target="_blank">MIYO Health</a>`
                : `
                ${t(
                  'teleteachers.teletext'
                )}</br></br><a style="color: white" href="https://teleteachers.com/" target="_blank">MIYO Health</a>`
            }
            position="TopCenter"
            // opensOn="Click"
            isSticky
            cssClass={`${ispublic ? 'publicfo customtip' : 'privatefo customtip'}`}
          >
            <Typography variant="body2">
              <Typography variant="body2">
                {window.location.href.split('/').slice(-2, -1)[0] === '2' ||
                window.location.href.split('/').slice(-1)[0] === '2' ? (
                  <a
                    className={`${classes.cursor} ${classes.pbtcolor} ${
                      ispublic ? classes.popin : classes.brandan
                    }`}
                  >
                    {t('parentsurveypage.footersp')}
                  </a>
                ) : (
                  <a
                    className={`${classes.cursor} ${classes.pbtcolor} ${
                      ispublic ? classes.popin : classes.brandan
                    }`}
                  >
                    {t('parentsurveypage.footer')}
                  </a>
                )}
                {/* <a
                className={`${classes.cursor} ${classes.pbtcolor} ${
                  ispublic ? classes.popin : classes.brandan
                }`}
              >
                {t('parentsurveypage.footer')}
              </a> */}
              </Typography>
            </Typography>
          </TooltipComponent>
        </Grid>
        <Grid item className={classes.gridstyle}>
          <div>
            <a href="https://bha.colorado.gov/" target="_blank" rel="noreferrer">
              <div className={classes.footerbg} />
            </a>
          </div>
          <div>
            <a href="https://coloradocrisisservices.org" target="_blank" rel="noreferrer">
              <div className={classes.footerccsbg} />
            </a>
          </div>
        </Grid>
      </Grid>
      {/* <img src={cdhLogo} alt="CDHS logo" /> */}
    </Box>
  )
}

export default Footer
