import { Grid } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { PageContainer } from 'components/ui/panel/page-container/PageContainer'
import { ScheduleRootView } from 'views/private/schedule/scheduleRootView'
import AppointmentsAlert from '../../../../components/ui/panel/appointments-alert/AppointmentsAlert'
import Dashboard from '../../../../components/ui/dashboard/Dashboard'
import Tour from '../../../../components/ui/tour/Tour'
import MetricCard from '../../../../components/ui/dashboard/cards/metric/MetricCard'
import { Role, useTherapistMetricsQuery } from '../../../../graphql/hooks.generated'
import { useCurrentUser } from '../../../../graphql/hooks/useCurrentUser/useCurrentUser'
import { ReactComponent as AttachmentsIcon } from '../../../../assets/icons/attachments.svg'
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg'
import { ReactComponent as CaseloadIcon } from '../../../../assets/icons/caseload.svg'
import { ReactComponent as ReportsIcon } from '../../../../assets/icons/reports.svg'
import { ReactComponent as ResourcesIcon } from '../../../../assets/icons/resources.svg'
import { ReactComponent as StudentsIcon } from '../../../../assets/icons/students.svg'
import { getZentryUrl } from '../../../../helpers/getZentryUrl'
import useStyles from '../Styles'
import { MainTherapistDashboard } from '../mainTherapistDashboard'

export const TherapistDashboard = () => {
  const { hasAnyRole } = useCurrentUser()
  const { data } = useTherapistMetricsQuery({
    returnPartialData: true,
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  })
  const history = useHistory()
  const classes = useStyles()

  return (
    <>
      <Tour steps={hasAnyRole(Role.Therapist) ? Role.Therapist : Role.Therapist} />
      <div className={classes.root}>
        <PageContainer>
          <MainTherapistDashboard />
        </PageContainer>
        {/* <ScheduleRootView /> */}
      </div>
    </>
  )
}
