import React, { useState, useEffect } from 'react'
import { Player } from 'video-react'
import 'video-react/dist/video-react.css'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { Grid, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { ResourceCard } from './ResourceCard'
import { useResourcesQuery, ResourceQuery } from '../../../graphql/hooks.generated'
import ProgressIndicator from '../../../components/ui/panel/progress-indicator/ProgressIndicator'
import { SearchInput } from './ResourcesFilterBar'
import { CmsResourceContentType } from '../../../graphql/enumTypes'

interface ResourceListProps {
  search?: SearchInput
}

type VideoDialog = {
  open: boolean
  title?: string
  url?: string
}

export const ResourceList = (props: ResourceListProps) => {
  const { search } = props
  const { data, fetchMore, loading } = useResourcesQuery({
    fetchPolicy: 'network-only',
    variables: { search: search?.searchQuery, discipline: search?.discipline },
    notifyOnNetworkStatusChange: true
  })
  const history = useHistory()
  const { url } = useRouteMatch()
  const [videoDialog, setVideo] = useState<VideoDialog>({ open: false })
  const [loadedAll, setLoadedAll] = useState(false)
  const onView = (resource: ResourceQuery['GCMS_resource']) => {
    if (resource?.content?.__typename === CmsResourceContentType.Video) {
      const { url: fileUrl } = resource?.content.file

      return () => setVideo({ open: true, title: resource?.title, url: fileUrl })
    }

    return () => history.push(`${url}/${resource?.id}`)
  }
  const resources = data?.GCMS_resources || []
  const lastResource = resources[resources.length - 1]
  const cursor = lastResource?.id
  const onScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight && !loading && !loadedAll)
      fetchMore({
        variables: { search: search?.searchQuery, discipline: search?.discipline, cursor },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult?.GCMS_resources.length) {
            setLoadedAll(true)

            return previousResult
          }

          const previousResources = previousResult.GCMS_resources
          const newResources = fetchMoreResult?.GCMS_resources || []
          const newCursor = newResources[newResources.length - 1].id

          return {
            cursor: newCursor,
            GCMS_resources: [...previousResources, ...newResources]
          }
        }
      })
  }
  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  })
  const isLoading = () => (loading ? <ProgressIndicator fullHeight /> : '')

  return (
    <>
      <Grid container spacing={2}>
        {data?.GCMS_resources?.map((resource) => (
          <Grid key={resource.id} item sm={12} md={4}>
            {resource.content?.__typename}
            <ResourceCard
              title={resource.title}
              image={resource.coverImage}
              onView={onView(resource as ResourceQuery['GCMS_resource'])}
            />
          </Grid>
        ))}
      </Grid>
      {isLoading()}
      <Dialog
        onClose={() => setVideo({ open: false })}
        open={videoDialog.open}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{videoDialog.title}</DialogTitle>
        <DialogContent dividers>
          <Player>
            <source src={videoDialog.url} />
          </Player>
        </DialogContent>
      </Dialog>
    </>
  )
}
