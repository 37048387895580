import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  Dialog,
  Slide,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import { SchoolForm, SchoolsFormModel } from '../form/SchoolForm'
import {
  useUpdateSchoolMutation,
  useSchoolQuery,
  useDeleteSchoolMutation,
  School
} from '../../../../graphql/hooks.generated'
import { UseParamsType } from '../../../../types'

interface UpdateSchoolViewParams extends UseParamsType {
  school_id: string
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const UpdateSchoolView = () => {
  const history = useHistory()
  const { school_id } = useParams<UpdateSchoolViewParams>()
  const [updateSchool] = useUpdateSchoolMutation()
  const { data } = useSchoolQuery({
    variables: { id: Number(school_id) },
    fetchPolicy: 'network-only'
  })
  const [deleteSchool] = useDeleteSchoolMutation()
  const onDelete = async () => {
    await deleteSchool({ variables: { id: parseInt(school_id) } })
    history.goBack()
  }
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const school = data?.school
  const initialValues: SchoolsFormModel | null = school
    ? {
        district: Number(school.district.id),
        location_code: school.location_code,
        status: school.status,
        zip: school.zip,
        city: school.city,
        name: school.name,
        street_address: school.street_address,
        state: school.state?.id
      }
    : null

  return (
    <>
      <NavBar title="Update School" goBackBtn />
      <PageContainer>
        <SchoolForm
          submitLabel="Save"
          initialValues={initialValues}
          onSubmit={(values) =>
            updateSchool({ variables: { id: Number(school_id), input: { ...values } } }).then(
              history.goBack
            )
          }
        />
        <Button
          style={{ marginLeft: 259, marginTop: -73 }}
          size="large"
          variant="outlined"
          color="primary"
          onClick={handleClickOpen}
        >
          Delete
        </Button>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure want to Delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button onClick={onDelete} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </PageContainer>
    </>
  )
}
