import React from 'react'
import { IconButton as IconButtonBase } from '@material-ui/core'
import useStyles from './Styles'
import { IProps } from './Interface'

const IconButton: React.FC<IProps> = ({ bordered, ...props }: IProps) => {
  const classes = useStyles({ bordered })

  return <IconButtonBase color="secondary" className={classes.root} {...props} />
}

export default IconButton
