import React, { useContext } from 'react'
import Button from '@material-ui/core/Button'
import { VideoContext } from 'views/public/video/video'
import { engLoc, spaLoc } from 'config/constants/translations'
import MicIcon from '../../../icons/MicIcon'
import MicOffIcon from '../../../icons/MicOffIcon'
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle'
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext'

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle()
  const { localTracks } = useVideoContext()
  const loc = useContext(VideoContext)
  const hasAudioTrack = localTracks.some((track) => track.kind === 'audio')

  return (
    <Button
      // eslint-disable-next-line react/destructuring-assignment
      className={props.className}
      onClick={toggleAudioEnabled}
      // eslint-disable-next-line react/destructuring-assignment
      disabled={!hasAudioTrack || props.disabled}
      startIcon={isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
      data-cy-audio-toggle
    >
      {!hasAudioTrack
        ? 'No Audio'
        : isAudioEnabled
        ? `${loc === 'es' ? spaLoc.video.mute : engLoc.video.mute}`
        : `${loc === 'es' ? spaLoc.video.unmute : engLoc.video.unmute}`}
    </Button>
  )
}
