import React from 'react'
import { Link, Typography, Box } from '@material-ui/core'
import { getTherapyLogUrl } from '../../../../../helpers/getTherapyLogUrl'
import MetricCard from './MetricCard'
import { TimeOwed } from '../../../../../graphql/hooks.generated'
import { customPalette } from '../../../../../config/constants/theme/palette'

const colorPicker = (minutes) => {
  if (minutes >= 120) return customPalette.red.main
  if (minutes >= 30) return customPalette.yellow.main

  return customPalette.green.main
}
const TimeOwedCard: React.FC<TimeOwed> = ({ minutes, studentsCount }: TimeOwed) => {
  return (
    <MetricCard
      title="Minutes Owed"
      metricNumber={minutes ?? 0}
      backgroundColor={colorPicker(minutes)}
      summary={`Accross ${studentsCount ?? 0} students`}
      cardFooter={
        <Box display="flex" alignItems="center">
          <Typography
            style={{ flexGrow: 1, marginTop: 5, marginRight: 2 }}
            gutterBottom
            variant="subtitle1"
          >
            For more information go to
            <Link
              onClick={() => window.open(getTherapyLogUrl('/reporting/my_reports'), '_self')}
              style={{ marginLeft: 8, color: customPalette.blue.light, cursor: 'pointer' }}
              variant="subtitle1"
              gutterBottom
            >
              {'My Reports ->'}
            </Link>
          </Typography>
        </Box>
      }
    />
  )
}

export default TimeOwedCard
