import React from 'react'
import { districtSignatureRequiredDescription } from 'views/private/serviceRequests/util/enumDescription'
import { SelectProps } from 'mui-rff'
import { DistrictSignatureRequired } from '../../../../graphql/hooks.generated'
import { DictionarySelect } from '../dictionary-select/DictionarySelect'

const options = [
  DistrictSignatureRequired.NotRequired,
  DistrictSignatureRequired.Always,
  DistrictSignatureRequired.Billable
]

type DistrictSignatureRequiredSelectProps = SelectProps

export const DistrictSignatureRequiredSelect = (props: DistrictSignatureRequiredSelectProps) => {
  return (
    <DictionarySelect
      optionsDictionary={districtSignatureRequiredDescription}
      options={options}
      {...props}
    />
  )
}
