import { Box, Typography, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import GraphImg from 'graphcms-image'
import { PageContainer } from '../../../components/ui/panel/page-container/PageContainer'
import { DraggableCard } from './Games/DraggableCard'
import { DropArea } from './Games/DropArea'
import { ResourceQuery } from '../../../graphql/hooks.generated'
import { CmsResourceContentType } from '../../../graphql/enumTypes'

interface DragRevealGameViewProps {
  gameResource: ResourceQuery['GCMS_resource']
}

export const DragRevealGameView = (props: DragRevealGameViewProps) => {
  const { gameResource } = props

  if (gameResource?.content?.__typename !== CmsResourceContentType.DragBoard)
    throw Error('Unexpected type of Resource content')

  const [gameDone] = useState(false)
  const { title, content } = gameResource
  const images = content?.images

  return (
    <PageContainer>
      <Box my={3}>
        <Typography gutterBottom variant="h1" color="secondary">
          {title}
        </Typography>
      </Box>
      {!gameDone && (
        <>
          <DropArea game="drag-reveal" />

          <Grid container spacing={8}>
            {images &&
              images.map((image) => (
                <Grid key={image.handle} item sm={6} md={3}>
                  <Box height={200} width={200}>
                    <GraphImg
                      image={image}
                      maxWidth={200}
                      style={{ height: 200, overflow: 'hidden', borderRadius: 5 }}
                    />
                    <DraggableCard height={200} width={200} game="drag-reveal" />
                  </Box>
                </Grid>
              ))}
          </Grid>
        </>
      )}
      {gameDone && (
        <Box my={3}>
          <Typography gutterBottom variant="h4" color="secondary">
            {`Congratulations! You've finished ${title}.`}
          </Typography>
          <Typography gutterBottom variant="subtitle1" color="secondary">
            HERE CAN LATER BE LINKS TO OTHER GAMES WITH SIMILAR TAGS
          </Typography>
        </Box>
      )}
    </PageContainer>
  )
}
