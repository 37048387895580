import React, { useMemo, useEffect } from 'react'
import { Select } from 'mui-rff'
import { MenuItem } from '@material-ui/core'
import {
  Caseload,
  CaseloadType,
  useCaseloadTypeLazyQuery
} from '../../../../graphql/hooks.generated'

interface CaseloadTypeSelectProps {
  label: string
  name: string
  required?: boolean
  initialOptions?: Pick<CaseloadType, 'id' | 'name'>[]
}

export const CaseloadTypeSelect = (props: CaseloadTypeSelectProps) => {
  const [executeQuery, { data }] = useCaseloadTypeLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetch = useMemo(() => () => executeQuery(), [executeQuery])
  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <>
      {data?.caseloadType && data?.caseloadType?.length > 0 ? (
        <Select {...props} variant="filled">
          {data?.caseloadType?.map((caseloadType) => (
            <MenuItem key={caseloadType.id} value={Number(caseloadType.id)}>
              {caseloadType.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select {...props} variant="filled">
          <MenuItem value="No Data Found">No Data Found</MenuItem>
        </Select>
      )}
    </>
  )
}
