import { makeStyles, Theme } from '@material-ui/core'
import cdhLogo from '../../../../assets/illustrations/colorado_newimg.png'
import BHLogo from '../../../../assets/illustrations/BHA1.png'
import ccslogo1 from '../../../../assets/illustrations/ccslogo1.jpeg'

export default makeStyles(({ breakpoints, palette }: Theme) => ({
  root: {
    borderTop: `1px dashed ${palette.divider}`,
    color: '#848484',
    '& > *': {
      flexGrow: 1
    }
  },
  footerPrivate: {
    display: 'flex',
    width: '100%',
    height: '75px',
    alignItems: 'center',
    margin: 'auto',
    paddingLeft: '3.5%'
  },
  footerPublic: {
    display: 'flex',
    width: '100%',
    height: '60px',
    alignItems: 'center',
    margin: 'auto',
    paddingLeft: '3.5%',
    [breakpoints.down('xs')]: {
      paddingLeft: '5px'
    }
  },
  brandan: {
    fontFamily: 'Brandon Grotesque '
  },
  footerbg: {
    alignItems: 'center',
    marginRight: '15px',
    width: '210px',
    height: '45px',
    backgroundSize: '210px',
    backgroundPosition: '100% 100%',
    backgroundImage: `url(${cdhLogo})`,
    backgroundRepeat: 'no-repeat',
    [breakpoints.down('xs')]: {
      marginRight: '5px',
      width: '175px',
      backgroundSize: '175px',
      marginTop: '13px'
    }
  },
  footerbhg: {
    alignItems: 'center',
    marginRight: '15px',
    width: '410px',
    height: '45px',
    backgroundSize: '380px',
    // backgroundPosition: '100% 100%',
    backgroundImage: `url(${BHLogo})`,
    backgroundRepeat: 'no-repeat',
    [breakpoints.down('xs')]: {
      marginRight: '5px',
      width: '175px',
      backgroundSize: '175px',
      marginTop: '13px'
    }
  },
  footerccsbg: {
    alignItems: 'center',
    marginRight: '15px',
    width: '210px',
    height: '42px',
    backgroundSize: '212px',
    backgroundPosition: '100%',
    backgroundImage: `url(${ccslogo1})`,
    backgroundRepeat: 'no-repeat',
    [breakpoints.down('xs')]: {
      marginRight: '5px',
      width: '175px',
      backgroundSize: '175px'
    }
  },
  cursor: {
    cursor: 'pointer'
  },
  pbtcolor: {
    color: '#293f70'
  },
  popin: {
    fontFamily: 'Poppins !important'
  },
  gridstyle: {
    width: '50%',
    display: 'flex',
    justifyContent: 'end',
    [breakpoints.down('xs')]: {
      width: '50%',
      display: 'block',
      justifyContent: 'end'
    }
  }
}))
