import React, { useMemo, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Link, Button, DialogActions } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import { saveAs } from 'file-saver'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { UseParamsType } from 'types'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import moment from 'moment'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import {
  useStudentDocumentationLazyQuery,
  useCurrentUserQuery,
  useMandatoryResendDetailsLazyQuery,
  Role,
  useReSendMandatoryEmailMutation,
  useMandatoryPdfListCallQuery,
  useMandatoryDetailsListQuery,
  useMandatoryPdfListCallLazyQuery,
  useListCaseloadLazyQuery
} from 'graphql/hooks.generated'
import { PDFDownloadLink, pdf, PDFViewer } from '@react-pdf/renderer'
import { MandatoryPdf } from 'views/private/disclosureform/mandatoryPdf'
import { engLoc, spaLoc } from 'config/constants/translations'
import { ReactComponent as EditIcon } from '../../../../../../assets/icons/84380.svg'
import { useStyles } from '../../../../../../config/constants/theme/profile'
import { PageContainer } from '../../../../../../components/ui/panel/page-container/PageContainer'
import { DisclosureForm } from '../../../../disclosureform/disclosureform'

interface ListViewParams extends UseParamsType {
  id: string
  stu_id: string
}

export default function CollapsibleTable2() {
  const history = useHistory()
  const classes = useStyles()
  const location = useLocation()
  const { hasRole, user } = useCurrentUser()
  const [providername, setProvidername] = useState('')
  const isSchoolTherapist = hasRole(Role.SchoolTherapist)
  const isagencyprovider = hasRole(Role.AgencySupervisorProvider)
  const isTherapist = hasRole(Role.Therapist)
  const isDAdmin = hasRole(Role.SchoolAdmin)
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const { stu_id, id } = useParams<ListViewParams>()
  const [openDisclouserFormDialog, setOpenDisclouserFormDialog] = useState(false)
  const [documentFill, setDocumentFill] = useState(false)
  let stud_id = '-1'
  let decode1
  let decode2
  let loc
  let mandatoryLoc
  let mandatoryDetails
  try {
    ;[decode1, decode2] = atob(stu_id).split('_')
  } catch (e) {
    // console.log(e)
  }

  if (decode1 && decode2 && decode1 === decode2) stud_id = decode1

  const [openDialog, setOpenDialog] = useState(false)
  const [executeQuery, { data, loading, refetch }] = useMandatoryResendDetailsLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      student_id: Number(stud_id)
    }
  })
  const rolesName = currentUserdata?.data?.currentUser?.roles?.[0]
  useEffect(() => {
    const rolesName = currentUserdata?.data?.currentUser?.roles?.[0]
    const currentuser_provider =
      (rolesName === Role.Therapist ||
        rolesName === Role.SchoolTherapist ||
        rolesName === Role.AgencySupervisorProvider) &&
      currentUserdata?.data?.currentUser?.id

    if (rolesName === Role.AgencySupervisor || rolesName === Role.AgencySupervisorProvider)
      setProvidername((location.state as string) || (currentuser_provider as string))
    else setProvidername(currentUserdata?.data?.currentUser?.id || '')
  }, [currentUserdata, location.state])
  const { data: mandateformdata, loading: mandateformloading } = useMandatoryDetailsListQuery({
    fetchPolicy: 'network-only',
    variables: {
      student_id: Number(stud_id),
      user_id: providername
    }
    // skip:
    //   rolesName === Role.SchoolAdmin || rolesName === Role.TeleteachersAdmin
    //     ? false
    //     : !providername
    //     ? true
    //     : false
  })
  const [
    studentDataListQuery,
    { data: providerdata, loading: listLoading }
  ] = useListCaseloadLazyQuery({
    fetchPolicy: 'network-only'
  })
  const fetchStudentListData = useMemo(
    () => ({ user_id }) => studentDataListQuery({ variables: { user_id } }),
    [studentDataListQuery]
  )
  useEffect(() => {
    const input = {
      user_id: currentUserdata?.data?.currentUser?.id
    }
    fetchStudentListData(input)
  }, [fetchStudentListData, currentUserdata?.data?.currentUser?.id])
  const filterstudent = providerdata?.caseload?.map((i) => i.students.id)
  const [reSendMandatoryEmail, { loading: resendloading }] = useReSendMandatoryEmailMutation()
  const [resendStdId, setResendStdId] = useState<any>()
  const handleOpenDialog = (value, document) => {
    setOpenDialog(true)
    setResendStdId(document?.id)
  }
  const [
    mandatoryPdf,
    { data: mandatoryPdfData, loading: mandatoryLoading }
  ] = useMandatoryPdfListCallLazyQuery({
    fetchPolicy: 'network-only'
  })
  useEffect(() => {
    if (data && currentUserdata) {
      const userObj = data?.mandatoryResendDetails?.filter(
        (v) => v?.user_id?.id === currentUserdata.data?.currentUser?.id
      )
      // console.log(userObj)

      if (userObj) {
        const mandatorytoObj = userObj.find(() => true)
        const mandatoryId = mandatorytoObj?.id

        if (mandatoryId !== undefined)
          mandatoryPdf({
            variables: {
              id: String(mandatoryId)
            }
          })
        // console.log(mandatoryId)
      }
    }
  }, [data, mandatoryPdf, currentUserdata])
  // console.log(mandatoryPdfData)
  const [mandatoryData, setMandatoryData] = useState({})
  // console.log(mandateformdata, '----------------------', mandatoryData)
  useEffect(() => {
    setMandatoryData({ ...data?.mandatoryResendDetails?.[0], resend: true })
  }, [data])
  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  const handleOpenDisclouserFormDialog = (document) => {
    setOpenDisclouserFormDialog(true)
    setMandatoryData(document)
    setDocumentFill(true)
  }
  const handleResendMandatoryEmail = () => {
    reSendMandatoryEmail({ variables: { id: resendStdId } })
    setOpenDialog(false)
    // refetch()
  }

  if (mandatoryPdfData && mandatoryPdfData?.mandatoryListCall) {
    mandatoryDetails = mandatoryPdfData?.mandatoryListCall?.[0]
    loc =
      mandatoryPdfData?.mandatoryListCall?.[0]?.student_id?.primaryLanguage?.id === 1 ? 'en' : 'es'
  }
  if (mandateformdata?.mandatoryDetailsList?.[0])
    mandatoryLoc =
      mandateformdata?.mandatoryDetailsList?.[0]?.student_id?.primaryLanguage?.id === 1
        ? 'en'
        : 'es'
  if (mandatoryData)
    mandatoryLoc =
      data?.mandatoryResendDetails?.[0]?.student_id?.primaryLanguage?.id === 1 ? 'en' : 'es'

  const fetchStudentDocumentation = useMemo(() => () => executeQuery(), [executeQuery])
  const handleCloseDisclouserFormDialog = () => {
    setOpenDisclouserFormDialog(false)
    fetchStudentDocumentation()
  }
  let documentmandatory: any

  if (data) documentmandatory = data?.mandatoryResendDetails

  const documentfilter = documentmandatory?.map((i) => i.student_id?.id)
  useEffect(() => {
    if (stu_id) fetchStudentDocumentation()
  }, [fetchStudentDocumentation, currentUserdata, stu_id])
  const isLoading = () =>
    loading || resendloading || mandatoryLoading ? <ProgressIndicator fullHeight /> : ''
  const agencyfiltering = filterstudent?.some((i) => documentfilter?.includes(i))
  // const agency = documentmandatory?.some((i) => currentUserdata?.data?.currentUser?.id === i.user_id?.id)
  const commonTableView = () => {
    return (
      <>
        <TableContainer className={(classes.updatetable, classes.tabconAdjust)} component={Paper}>
          <Table aria-label="collapsible table" style={{ tableLayout: 'fixed' }}>
            <TableRow>
              <TableCell className={classes.dochead} width="28%">
                Provider Name
              </TableCell>
              <TableCell className={classes.dochead} width="25%">
                Status
              </TableCell>
              {hasRole(Role.SchoolAdmin) && (
                <TableCell className={`${classes.headers} ${classes.aligncenter}`} width="45%">
                  Actions
                </TableCell>
              )}
              {!hasRole(Role.AgencySupervisor) && agencyfiltering && (
                <TableCell className={`${classes.headers} ${classes.aligncenter}`} width="45%">
                  Actions
                </TableCell>
              )}
            </TableRow>
            {hasRole(Role.SchoolAdmin) ? (
              <TableBody>
                {documentmandatory && documentmandatory?.length ? (
                  <>
                    {documentmandatory?.map((document, index) => (
                      <>
                        {stu_id && (
                          <TableRow className={classes.root}>
                            <TableCell className={classes.forntSizes}>
                              {document?.user_id?.name}
                            </TableCell>
                            <TableCell className={classes.forntSizes}>
                              {document?.status_id?.name}
                            </TableCell>
                            {hasRole(Role.SchoolAdmin) && (
                              <TableCell style={{ fontFamily: 'Brandon Grotesque' }}>
                                <>
                                  <Button
                                    disabled={
                                      !(isTherapist || isSchoolTherapist || isagencyprovider) ||
                                      !(
                                        ((isTherapist || isSchoolTherapist || isagencyprovider) &&
                                          document?.user_id?.id ===
                                            currentUserdata?.data?.currentUser?.id &&
                                          document?.status_id?.id === 1) ||
                                        document?.status_id?.id === 4
                                      )
                                    }
                                    color="primary"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className="mar-rt-15 "
                                    variant="contained"
                                    size="medium"
                                    style={{
                                      padding: '6px 25x',
                                      // marginLeft: '5px',
                                      borderRadius: 25,
                                      fontFamily: 'Brandon Grotesque'
                                      // left: '70px'
                                    }}
                                    onClick={() => handleOpenDisclouserFormDialog(document)}
                                  >
                                    Fill Mandatory Form
                                  </Button>
                                </>
                                <>
                                  <Button
                                    disabled={
                                      // !(isTherapist || isSchoolTherapist) ||
                                      ((isTherapist || isSchoolTherapist || isagencyprovider) &&
                                        document?.user_id?.id !==
                                          currentUserdata?.data?.currentUser?.id) ||
                                      document?.status_id?.id === 1 ||
                                      document?.status_id?.id === 3
                                    }
                                    color="primary"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className="mar-rt-15 "
                                    variant="contained"
                                    size="medium"
                                    style={{
                                      padding: '6px 25px',
                                      // marginLeft: '5px',
                                      borderRadius: 25,
                                      fontFamily: 'Brandon Grotesque'
                                      // left: '70px'
                                    }}
                                    onClick={(value) => handleOpenDialog(value, document)}
                                  >
                                    Resend
                                  </Button>
                                </>
                                <>
                                  <Button
                                    disabled={
                                      // !(isTherapist || isSchoolTherapist) ||
                                      ((isTherapist || isSchoolTherapist || isagencyprovider) &&
                                        document?.user_id?.id !==
                                          currentUserdata?.data?.currentUser?.id) ||
                                      document?.status_id?.id !== 3
                                    }
                                    color="primary"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className="mar-rt-15 "
                                    variant="contained"
                                    size="medium"
                                    style={{
                                      padding: '6px 25px',
                                      // marginLeft: '5px',
                                      borderRadius: 25,
                                      fontFamily: 'Brandon Grotesque',
                                      marginTop: '10px'
                                      // left: '75px'
                                    }}
                                    onClick={async () => {
                                      const doc = (
                                        <MandatoryPdf
                                          mandatoryDetails={document}
                                          loc={mandatoryLoc}
                                        />
                                      )
                                      const asPdf = pdf()
                                      asPdf.updateContainer(doc)
                                      const blob = await asPdf.toBlob()
                                      saveAs(blob, 'MANDATORY_DISCLOSURE_FORM.pdf')
                                    }}
                                  >
                                    Download
                                  </Button>
                                </>
                              </TableCell>
                            )}
                            {!hasRole(Role.AgencySupervisor) && agencyfiltering && (
                              <TableCell style={{ fontFamily: 'Brandon Grotesque' }}>
                                <>
                                  <Button
                                    disabled={
                                      !(isTherapist || isSchoolTherapist || isagencyprovider) ||
                                      document?.user_id?.id !==
                                        currentUserdata?.data?.currentUser?.id ||
                                      !(
                                        ((isTherapist || isSchoolTherapist || isagencyprovider) &&
                                          document?.user_id?.id ===
                                            currentUserdata?.data?.currentUser?.id &&
                                          document?.status_id?.id === 1) ||
                                        document?.status_id?.id === 4
                                      )
                                    }
                                    color="primary"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className="mar-rt-15 "
                                    variant="contained"
                                    size="medium"
                                    style={{
                                      padding: '6px 25x',
                                      // marginLeft: '5px',
                                      borderRadius: 25,
                                      fontFamily: 'Brandon Grotesque'
                                      // left: '70px'
                                    }}
                                    onClick={() => handleOpenDisclouserFormDialog(document)}
                                  >
                                    Fill Mandatory Form
                                  </Button>
                                </>
                                <>
                                  <Button
                                    disabled={
                                      document?.user_id?.id !==
                                        currentUserdata?.data?.currentUser?.id ||
                                      // !(isTherapist || isSchoolTherapist) ||
                                      ((isTherapist || isSchoolTherapist || isagencyprovider) &&
                                        document?.user_id?.id !==
                                          currentUserdata?.data?.currentUser?.id) ||
                                      document?.status_id?.id === 1 ||
                                      document?.status_id?.id === 3
                                    }
                                    color="primary"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className="mar-rt-15 "
                                    variant="contained"
                                    size="medium"
                                    style={{
                                      padding: '6px 25px',
                                      // marginLeft: '5px',
                                      borderRadius: 25,
                                      fontFamily: 'Brandon Grotesque'
                                      // left: '70px'
                                    }}
                                    onClick={(value) => handleOpenDialog(value, document)}
                                  >
                                    Resend
                                  </Button>
                                </>
                                <>
                                  <Button
                                    disabled={
                                      // !(isTherapist || isSchoolTherapist) ||
                                      ((isTherapist || isSchoolTherapist || isagencyprovider) &&
                                        document?.user_id?.id !==
                                          currentUserdata?.data?.currentUser?.id) ||
                                      document?.status_id?.id !== 3
                                    }
                                    color="primary"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className="mar-rt-15 "
                                    variant="contained"
                                    size="medium"
                                    style={{
                                      padding: '6px 25px',
                                      // marginLeft: '5px',
                                      borderRadius: 25,
                                      fontFamily: 'Brandon Grotesque',
                                      marginTop: '10px'
                                      // left: '75px'
                                    }}
                                    onClick={async () => {
                                      const doc = (
                                        <MandatoryPdf
                                          mandatoryDetails={document}
                                          loc={mandatoryLoc}
                                        />
                                      )
                                      const asPdf = pdf()
                                      asPdf.updateContainer(doc)
                                      const blob = await asPdf.toBlob()
                                      saveAs(blob, 'MANDATORY_DISCLOSURE_FORM.pdf')
                                    }}
                                  >
                                    Download
                                  </Button>
                                </>
                              </TableCell>
                            )}
                          </TableRow>
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell style={{ fontFamily: 'Brandon Grotesque' }}>
                      <span>
                        <h3>No active providers found</h3>
                      </span>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                {mandateformdata && mandateformdata ? (
                  <>
                    {mandateformdata?.mandatoryDetailsList?.map((document, index) => (
                      <>
                        {stu_id && (
                          <TableRow className={classes.root}>
                            <TableCell className={classes.forntSizes}>
                              {document?.user_id?.name}
                            </TableCell>
                            <TableCell className={classes.forntSizes}>
                              {document?.status_id?.name}
                            </TableCell>
                            {hasRole(Role.SchoolAdmin) && (
                              <TableCell style={{ fontFamily: 'Brandon Grotesque' }}>
                                <>
                                  <Button
                                    disabled={
                                      !(isTherapist || isSchoolTherapist || isagencyprovider) ||
                                      !(
                                        ((isTherapist || isSchoolTherapist || isagencyprovider) &&
                                          document?.user_id?.id ===
                                            currentUserdata?.data?.currentUser?.id &&
                                          document?.status_id?.id === 1) ||
                                        document?.status_id?.id === 4
                                      )
                                    }
                                    color="primary"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className="mar-rt-15 "
                                    variant="contained"
                                    size="medium"
                                    style={{
                                      padding: '6px 25x',
                                      // marginLeft: '5px',
                                      borderRadius: 25,
                                      fontFamily: 'Brandon Grotesque'
                                      // left: '70px'
                                    }}
                                    onClick={() => handleOpenDisclouserFormDialog(document)}
                                  >
                                    Fill Mandatory Form
                                  </Button>
                                </>
                                <>
                                  <Button
                                    disabled={
                                      // !(isTherapist || isSchoolTherapist) ||
                                      ((isTherapist || isSchoolTherapist || isagencyprovider) &&
                                        document?.user_id?.id !==
                                          currentUserdata?.data?.currentUser?.id) ||
                                      document?.status_id?.id === 1 ||
                                      document?.status_id?.id === 3
                                    }
                                    color="primary"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className="mar-rt-15 "
                                    variant="contained"
                                    size="medium"
                                    style={{
                                      padding: '6px 25px',
                                      // marginLeft: '5px',
                                      borderRadius: 25,
                                      fontFamily: 'Brandon Grotesque'
                                      // left: '70px'
                                    }}
                                    onClick={(value) => handleOpenDialog(value, document)}
                                  >
                                    Resend
                                  </Button>
                                </>
                                <>
                                  <Button
                                    disabled={
                                      // !(isTherapist || isSchoolTherapist) ||
                                      ((isTherapist || isSchoolTherapist || isagencyprovider) &&
                                        document?.user_id?.id !==
                                          currentUserdata?.data?.currentUser?.id) ||
                                      document?.status_id?.id !== 3
                                    }
                                    color="primary"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className="mar-rt-15 "
                                    variant="contained"
                                    size="medium"
                                    style={{
                                      padding: '6px 25px',
                                      // marginLeft: '5px',
                                      borderRadius: 25,
                                      fontFamily: 'Brandon Grotesque',
                                      marginTop: '10px'
                                      // left: '75px'
                                    }}
                                    onClick={async () => {
                                      const doc = (
                                        <MandatoryPdf
                                          mandatoryDetails={document}
                                          loc={mandatoryLoc}
                                        />
                                      )
                                      const asPdf = pdf()
                                      asPdf.updateContainer(doc)
                                      const blob = await asPdf.toBlob()
                                      saveAs(blob, 'MANDATORY_DISCLOSURE_FORM.pdf')
                                    }}
                                  >
                                    Download
                                  </Button>
                                </>
                              </TableCell>
                            )}
                            {!hasRole(Role.AgencySupervisor) && agencyfiltering && (
                              <TableCell style={{ fontFamily: 'Brandon Grotesque' }}>
                                <>
                                  <Button
                                    disabled={
                                      !(isTherapist || isSchoolTherapist || isagencyprovider) ||
                                      document?.user_id?.id !==
                                        currentUserdata?.data?.currentUser?.id ||
                                      !(
                                        ((isTherapist || isSchoolTherapist || isagencyprovider) &&
                                          document?.user_id?.id ===
                                            currentUserdata?.data?.currentUser?.id &&
                                          document?.status_id?.id === 1) ||
                                        document?.status_id?.id === 4
                                      )
                                    }
                                    color="primary"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className="mar-rt-15 "
                                    variant="contained"
                                    size="medium"
                                    style={{
                                      padding: '6px 25x',
                                      // marginLeft: '5px',
                                      borderRadius: 25,
                                      fontFamily: 'Brandon Grotesque'
                                      // left: '70px'
                                    }}
                                    onClick={() => handleOpenDisclouserFormDialog(document)}
                                  >
                                    Fill Mandatory Form
                                  </Button>
                                </>
                                <>
                                  <Button
                                    disabled={
                                      document?.user_id?.id !==
                                        currentUserdata?.data?.currentUser?.id ||
                                      // !(isTherapist || isSchoolTherapist) ||
                                      ((isTherapist || isSchoolTherapist || isagencyprovider) &&
                                        document?.user_id?.id !==
                                          currentUserdata?.data?.currentUser?.id) ||
                                      document?.status_id?.id === 1 ||
                                      document?.status_id?.id === 3
                                    }
                                    color="primary"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className="mar-rt-15 "
                                    variant="contained"
                                    size="medium"
                                    style={{
                                      padding: '6px 25px',
                                      // marginLeft: '5px',
                                      borderRadius: 25,
                                      fontFamily: 'Brandon Grotesque'
                                      // left: '70px'
                                    }}
                                    onClick={(value) => handleOpenDialog(value, document)}
                                  >
                                    Resend
                                  </Button>
                                </>
                                <>
                                  <Button
                                    disabled={
                                      // !(isTherapist || isSchoolTherapist) ||
                                      ((isTherapist || isSchoolTherapist || isagencyprovider) &&
                                        document?.user_id?.id !==
                                          currentUserdata?.data?.currentUser?.id) ||
                                      document?.status_id?.id !== 3
                                    }
                                    color="primary"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className="mar-rt-15 "
                                    variant="contained"
                                    size="medium"
                                    style={{
                                      padding: '6px 25px',
                                      // marginLeft: '5px',
                                      borderRadius: 25,
                                      fontFamily: 'Brandon Grotesque',
                                      // left: '75px'
                                      marginTop: '10px'
                                    }}
                                    onClick={async () => {
                                      const doc = (
                                        <MandatoryPdf
                                          mandatoryDetails={document}
                                          loc={mandatoryLoc}
                                        />
                                      )
                                      const asPdf = pdf()
                                      asPdf.updateContainer(doc)
                                      const blob = await asPdf.toBlob()
                                      saveAs(blob, 'MANDATORY_DISCLOSURE_FORM.pdf')
                                    }}
                                  >
                                    Download
                                  </Button>
                                </>
                              </TableCell>
                            )}
                          </TableRow>
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell style={{ fontFamily: 'Brandon Grotesque' }}>
                      <span>
                        <h3>No active providers found</h3>
                      </span>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
          <Dialog
            open={openDialog}
            maxWidth="md"
            onClose={handleOpenDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure want to resend the email?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                No
              </Button>
              <Button onClick={handleResendMandatoryEmail} color="primary">
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <>
            <Dialog
              open={openDisclouserFormDialog}
              maxWidth="lg"
              onClose={setOpenDisclouserFormDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Brandon Grotesque' }}>
                <div className={classes.addSign}>
                  {loc === 'en' ? engLoc.mandatoryform.mdform : spaLoc.mandatoryform.mdform}
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{ color: '#000' }}>
                  <DisclosureForm
                    loc={mandatoryLoc}
                    mandateformdata={
                      mandateformdata?.mandatoryDetailsList?.[0] !== undefined
                        ? mandateformdata?.mandatoryDetailsList?.[0]
                        : mandatoryData
                    }
                    docFill={documentFill}
                    handleMandateFormClose={handleCloseDisclouserFormDialog}
                  />
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </>
          {isLoading()}
        </TableContainer>
      </>
    )
  }

  return (
    <>
      <PageContainer> {commonTableView()} </PageContainer>
    </>
  )
}
