import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import {
  User,
  District,
  useUpdateUserMutation,
  useUserQuery,
  useCreateUserDistrictMutation
} from 'graphql/hooks.generated'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import { UserFormModel } from '../../users/form/UserFormModel'
import { UseParamsType } from '../../../../types'
import { TherapistEditForm } from './TherapistForm'

interface UpdateUserViewParams extends UseParamsType {
  user_id: string
}

export const UpdateTherapistView = () => {
  const history = useHistory()
  const { user_id: id } = useParams<UpdateUserViewParams>()
  const [updateUser] = useUpdateUserMutation()
  const [createUserDistrict] = useCreateUserDistrictMutation()
  // const [updateUserDistrict] = useUpdateAddStudentPermissionForUserDistrictsMutation()
  const { data } = useUserQuery({ variables: { id }, fetchPolicy: 'network-only' })
  const user:
    | ({
        __typename?: 'User'
        district?: ({ __typename?: 'District' } & Pick<District, 'id' | 'name'>) | null
      } & Pick<
        User,
        | 'email'
        | 'first_name'
        | 'last_name'
        | 'middle_initial'
        | 'maiden_name'
        | 'roles'
        | 'mobile_phone'
        | 'home_phone'
        | 'street_address'
        | 'license_number'
        | 'cert_date'
        | 'cert_expire_date'
        | 'city'
        | 'zip'
        | 'time_zone'
        | 'status'
        | 'therapist_discipline'
        | 'state_license'
        | 'therapistDistrict'
        | 'hourly_rate'
        | 'add_student_permission'
      >)
    | undefined = data?.user
  const initialValues: UserFormModel | null = user
    ? {
        district: user.district?.id ?? '',
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name ?? '',
        middleInitial: user.middle_initial ?? undefined,
        roles: user.roles ?? [],
        mobilePhone: user.mobile_phone ?? null,
        homePhone: user.home_phone ?? null,
        streetAddress: user.street_address ?? null,
        licenseNumber: user.license_number ?? null,
        certDate: user.cert_date ? new Date(user.cert_date) : undefined,
        certExpireDate: user.cert_expire_date ? new Date(user.cert_expire_date) : undefined,
        city: user.city ?? null,
        zip: user.zip ?? null,
        timeZone: user.time_zone ?? null,
        // status: user.status ?? null,
        therapistDiscipline: user.therapist_discipline ? user.therapist_discipline : undefined,
        stateLicense: user.state_license?.id,
        therapistDistrict: user.therapistDistrict ?? [],
        add_student_permission: user.add_student_permission
      }
    : null

  return (
    <>
      <NavBar title="Update User" goBackBtn />
      <PageContainer>
        <TherapistEditForm
          submitLabel="Save"
          initialValues={initialValues}
          onSubmit={async ({
            district,
            email,
            firstName,
            lastName,
            middleInitial,
            roles,
            stateLicense,
            mobilePhone,
            homePhone,
            streetAddress,
            licenseNumber,
            certDate,
            certExpireDate,
            city,
            zip,
            timeZone,
            therapistDiscipline,
            therapistDistrict
          }: UserFormModel) =>
            (await updateUser({
              variables: {
                id,
                input: {
                  district,
                  email,
                  firstName,
                  lastName,
                  middleInitial,
                  roles,
                  stateLicense,
                  mobilePhone,
                  homePhone,
                  streetAddress,
                  licenseNumber,
                  certDate: certDate && format(certDate, 'yyyy-MM-dd'),
                  certExpireDate: certExpireDate && format(certExpireDate, 'yyyy-MM-dd'),
                  city,
                  zip,
                  timeZone,
                  therapistDiscipline
                }
              }
            })) &&
            createUserDistrict({
              variables: {
                input: therapistDistrict
              }
            }).then(history.goBack)
          }
        />
      </PageContainer>
    </>
  )
}
