import React, { useEffect, useState } from 'react'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Search,
  Inject,
  ToolbarItems,
  Page,
  Sort,
  Filter,
  FilterSettingsModel,
  SortSettingsModel
} from '@syncfusion/ej2-react-grids'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  useTheme,
  Grid,
  Avatar
} from '@material-ui/core'
import Paper from '../../../../components/ui/panel/paper/Paper'
import Button from '../../../../components/ui/panel/button/Button'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { useListIcdCodesQuery, useDeleteIcdCodeMutation } from '../../../../graphql/hooks.generated'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'

export const IcdCodeListView: React.FC = () => {
  const [icdCodesData, setData] = useState<any>()
  const theme = useTheme()
  const history = useHistory()
  const { url } = useRouteMatch()
  const classes = useStyles()
  const { data, loading, refetch } = useListIcdCodesQuery({
    fetchPolicy: 'network-only'
  })
  const [deleteIcdCode] = useDeleteIcdCodeMutation()
  const handleClick = () => {
    history.push(`${url}/create`)
  }
  const onDeleteIcdCode = async (id) => {
    await deleteIcdCode({ variables: { id } })
    refetch()
  }
  const isLoading = () => (loading ? <ProgressIndicator fullHeight /> : '')
  useEffect(() => {
    if (data !== undefined && icdCodesData === undefined) {
      const active = 'active'

      if (active && active.trim().length > 0) {
        const dataval = data?.icdCodes?.filter(
          (icdCode) => icdCode.status.toLocaleLowerCase() === active
        )
        setData(dataval)
        const datasorting = dataval?.sort((a, b) =>
          a.name.localeCompare(b.name, 'es', { sensitivity: 'base' })
        )
        setData(datasorting)
      }
    }
  }, [data, icdCodesData])
  const handleChange = (e) => {
    const keyWord = e.target.value

    if (keyWord && keyWord.trim().length > 0) {
      const icdCodesData = data?.icdCodes?.filter(
        (obj) => obj.status.toLocaleLowerCase() === keyWord.toLocaleLowerCase()
      )
      const datasorting = icdCodesData?.sort((a, b) =>
        a.name.localeCompare(b.name, 'es', { sensitivity: 'base' })
      )
      setData(datasorting)
      setData(icdCodesData)
    }
  }
  const toolbarOptions: ToolbarItems[] = ['Search']
  const filterOption: FilterSettingsModel = {
    ignoreAccent: true,
    type: 'Excel'
  }
  const sortSettings: SortSettingsModel = {
    columns: [{ field: 'name', direction: 'Ascending' }]
  }

  return (
    <>
      <NavBar title="ICD 10 Codes" goBackBtn />
      <PageContainer>
        <div className={classes.plr10}>
          <Box py={3} display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Typography variant="h3" style={{ color: theme.palette.info.main }}>
                ICD 10 Codes
              </Typography>
            </Box>
          </Box>
          <div className="control-pane">
            <div className="control-section">
              {data && (
                <GridComponent
                  key={Math.floor(Math.random() * 100)}
                  allowTextWrap
                  dataSource={data?.icdCodes || []}
                  width="auto"
                  toolbar={toolbarOptions}
                  allowPaging
                  pageSettings={{ pageSizes: [15, 25, 50, 100], pageSize: 15, pageCount: 5 }}
                  allowSorting
                  filterSettings={filterOption}
                  allowFiltering
                  sortSettings={sortSettings}
                  className={classes.headerCls}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="name"
                      headerText="NAME"
                      width="220"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      field="code"
                      headerText="CODE"
                      width="60"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      field="therapist_discipline"
                      headerText="PROVIDER  DISCIPLINE"
                      width="100"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      field="version"
                      headerText="VERSION"
                      width="50"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      field="status"
                      headerText="STATUS"
                      width="70"
                      filter={{ type: 'CheckBox' }}
                    />
                    <ColumnDirective
                      template={(icdCode) => (
                        <Link
                          onClick={() => history.push(`${url}/${icdCode.id}/edit`)}
                          style={{ color: 'black', cursor: 'pointer' }}
                        >
                          {' '}
                          Edit{' '}
                        </Link>
                      )}
                      headerText="ACTIONS"
                      width="80"
                      allowFiltering={false}
                    />
                  </ColumnsDirective>
                  <Inject services={[Filter, Search, Toolbar, Sort, Page]} />
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    className="mar-rt-15 custom-btn addEvent"
                    onClick={handleClick}
                  >
                    Add ICD Code
                  </Button>
                </GridComponent>
              )}
            </div>
          </div>
        </div>
        {isLoading()}
      </PageContainer>
    </>
  )
}
