import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import { StudentForm } from '../form/StudentForm'
import { useCreateStudentMutation } from '../../../../graphql/hooks.generated'
import { useCurrentUser } from '../../../../graphql/hooks/useCurrentUser/useCurrentUser'
import { UseParamsType } from '../../../../types'

interface CreateStudentViewParams extends UseParamsType {
  id: string
}

export const CreateStudentView = () => {
  const [createStudent] = useCreateStudentMutation()
  const history = useHistory()
  const { id: districtId } = useParams<CreateStudentViewParams>()
  const { data: currentUserData } = useCurrentUser()

  return (
    <>
      <NavBar title="Add A New Client" goBackBtn />
      <PageContainer>
        {/* <StudentForm
          initialValues={
            districtId
              ? { district: Number(districtId) }
              : { district: Number(currentUserData?.currentUser.district?.id) }
          }
          submitLabel="Save"
          onSubmit={(values) =>
            createStudent({
              variables: {
                input: {
                  ...values,
                  birthDate: values.birthDate && format(values.birthDate, 'yyyy-MM-dd'),
                  iepDate: values.iepDate && format(values.iepDate, 'yyyy-MM-dd'),
                  reEvlDate: values.reEvlDate && format(values.reEvlDate, 'yyyy-MM-dd')
                }
              }
            }).then(() => history.push(`districts/${districtId}/students`))
          }
        /> */}
      </PageContainer>
    </>
  )
}
