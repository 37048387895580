import { Grid, MenuItem } from '@material-ui/core'
import { makeValidate, Select } from 'mui-rff'
import React from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { DistrictLevelOfServiceSelect } from '../../../../components/ui/form/district-level-of-service-select/DistrictLevelOfServiceSelect'
import { PlanTypeSelect } from '../../../../components/ui/form/plan-type-select/PlanTypeSelect'
import { DistrictSignatureRequiredSelect } from '../../../../components/ui/form/district-signature-required-select/DistrictSignatureRequiredSelect'
import Button from '../../../../components/ui/panel/button/Button'
import { InfoButton } from '../../../../components/ui/panel/button/InfoButton'
import { Section } from '../../../../components/ui/panel/section/Section'
import {
  DistrictLevelOfService,
  useStatesQuery,
  PlanType,
  DistrictSignatureRequired
} from '../../../../graphql/hooks.generated'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'

export interface DistrictsFormModel {
  city: string
  levelOfService: DistrictLevelOfService
  name: string
  offerBillingQuestion?: boolean | null
  billTrackingIdEnabled?: boolean | null
  allowConferencing?: boolean | null
  therapyComponentEnabled?: boolean | null
  enableProviderPins?: boolean | null
  planTypes: PlanType[]
  signatureRequired: DistrictSignatureRequired
  streetAddress: string
  stateId: number
  zip: string
}

type DistrictFormProps = {
  onSubmit(values: DistrictsFormModel): void
  initialValues?: Partial<DistrictsFormModel> | null
}
const validator = makeValidate(
  yup.object<DistrictsFormModel>({
    city: yup.string().label('City').required(),
    levelOfService: yup.mixed<DistrictLevelOfService>().label('Level of service').required(),
    name: yup.string().label('District name').required(),
    planTypes: yup.array<PlanType>().label('Plan Type').required(),
    signatureRequired: yup
      .mixed<DistrictSignatureRequired>()
      .label('Signature Required')
      .required(),
    streetAddress: yup.string().label('Street Address').required(),
    stateId: yup.number().label('State').required(),
    zip: yup.string().label('Zip').required()
  })
)

export const DistrictForm = (props: DistrictFormProps) => {
  const { data } = useStatesQuery()
  const history = useHistory()
  const { initialValues } = props
  const onSubmit = (values: DistrictsFormModel) => props.onSubmit(values)
  const renderForm = (renderProps: FormRenderProps<DistrictsFormModel>) => {
    const { handleSubmit } = renderProps

    return (
      <PageContainer>
        <form>
          <Grid container spacing={4} style={{ maxWidth: '800px' }}>
            <Grid item sm={12}>
              <Section title="Client Progress Report">
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    {data?.states?.length ? (
                      <Select name="stateId" label="From Date" required variant="filled">
                        {data?.states?.map((state) => (
                          <MenuItem key={state.id} value={state.id}>
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      ''
                    )}
                  </Grid>

                  <Grid item sm={6}>
                    {data?.states?.length ? (
                      <Select name="stateId" label="To Date" required variant="filled">
                        {data?.states?.map((state) => (
                          <MenuItem key={state.id} value={state.id}>
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Section>
            </Grid>
            <Grid item sm={12}>
              <Grid container spacing={3}>
                <Grid item sm={6}>
                  Therapist
                  <DistrictSignatureRequiredSelect
                    required
                    name="select provider"
                    // label="Signature Required"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Grid container spacing={3}>
                <Grid item sm={6}>
                  Client
                  <DistrictSignatureRequiredSelect
                    required
                    name="select client"
                    // label="Signature Required"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid item sm={6}>
                Format
                {data?.states?.length ? (
                  <Select name="stateId" label="Excelspreadsheet" required variant="filled">
                    {data?.states?.map((state) => (
                      <MenuItem key={state.id} value={state.id}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
            <Grid item sm={12} style={{ paddingTop: 35 }}>
              <Button size="large" variant="outlined" color="primary" onClick={history.goBack}>
                Report
              </Button>
            </Grid>
          </Grid>
        </form>
      </PageContainer>
    )
  }

  return (
    <Form<DistrictsFormModel>
      initialValues={initialValues ?? {}}
      validate={validator}
      render={renderForm}
      onSubmit={onSubmit}
    />
  )
}
