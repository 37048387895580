export const defaultRegistrationState = {
  step: 1,
  firstName: '',
  mi: '',
  lastName: '',
  dob: '',
  gender: '',
  pronouns: '',
  email: '',
  phone: '',
  address: '',
  county: '',
  city: '',
  zip: '',
  language: '',
  mode: '',
  accommodations: '',
  needArea: '',
  type: '',
  insurance: '',
  insuranceCompany: ''
}
