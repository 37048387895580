import debounce from 'lodash/debounce'
import React, { useEffect, useMemo, useState } from 'react'
import { Autocomplete, AutocompleteProps, AutocompleteOption } from '../autocomplete/Autocomplete'

export type SelectOption = AutocompleteOption

export interface AsyncSelectProps<T> extends AutocompleteProps<T> {
  fetchOptions(query?: string): void
}

export const AsyncSelect = (props: AsyncSelectProps<AutocompleteOption>) => {
  const { fetchOptions, ...rest } = props
  const [open, setOpen] = useState(false)
  // const [inputValue, setInputValue] = useState('')
  const fetch = useMemo(() => debounce((query?: string) => fetchOptions(query), 400), [
    fetchOptions
  ])
  useEffect(() => {
    if (open) fetch()
  }, [open, fetch])

  // useEffect(() => {
  //   inputValue && fetch(inputValue)
  // }, [inputValue, fetch])

  // TODO: show validation errors correctly and improve usabillity
  return (
    <Autocomplete
      {...rest}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      textFieldProps={{ variant: 'filled' }}
    />
  )
}
/* <Autocomplete
      {...rest}
      variant="outlined"
      filterOptions={(x) => x}
      onInputChange={(event, newInputValue) => {
        console.log(event)
        event?.type === 'change' && setInputValue(newInputValue)
      }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          required={rest.required}
          label={rest.label}
          variant="filled"
          InputProps={{
            ...params.InputProps,
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
          }}
        />
      )}
        /> */
