import React from 'react'
import { useHistory } from 'react-router-dom'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import { DistrictForm } from '../form/DistrictForm'
import { useCreateDistrictMutation } from '../../../../graphql/hooks.generated'

export const CreateDistrictView = () => {
  const [createDistrict] = useCreateDistrictMutation()
  const history = useHistory()

  return (
    <>
      <NavBar title="Add A New District" goBackBtn />
      <PageContainer>
        <DistrictForm
          submitLabel="Save"
          initialValues={{ offerBillingQuestion: true }}
          onSubmit={(values) =>
            createDistrict({ variables: { input: values } }).then(() => history.push('/districts'))
          }
        />
      </PageContainer>
    </>
  )
}
