import { styled } from '@material-ui/core'
import Button from './Button'

export const InfoButton = styled(Button)(({ theme }) => ({
  background: theme.palette.info.main,
  color: theme.palette.common.white,
  '&:hover': {
    background: theme.palette.info.dark
  }
}))
