// @todo: return activate query logic after DB separtion works done
/* eslint-disable */

import { Box, Paper, styled, Typography } from '@material-ui/core'
import Button from 'components/ui/panel/button/Button'
import { PageContainer } from 'components/ui/panel/page-container/PageContainer'
import { useActivateAccountMutation, useUserInviteQuery } from 'graphql/hooks.generated'
// import { useUserInviteQuery } from 'graphql/hooks.generated'
import { makeValidate, TextField } from 'mui-rff'
import React, { useEffect, useState } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { useHistory, useParams } from 'react-router-dom'
import * as yup from 'yup'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import IconButton from 'components/ui/panel/icon-button/IconButton'
import { theme } from 'config/constants/theme'
import { TherapistInviteView } from './TherapistInvite'
import ProgressIndicator from '../../../components/ui/panel/progress-indicator/ProgressIndicator'

interface ActivateAccountParams {
  token: string
}

interface ActivateAccountFormModel {
  password: string
  passwordConfirmation: string
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 8,
  margin: 'auto',
  width: 400
}))
const PaperHeader = styled(Box)({
  padding: 32
})
const FormRow = styled(Box)({
  padding: '12px 32px'
})
const ButtonsRow = styled(Box)({
  padding: '32px'
})
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1)
    },
    rightIcon: {
      '& :visited': { color: 'blue' },
      '& :hover': { color: 'blue' },
      '& :active': { color: 'blue' }
    },
    leftIcon: {
      color: 'slategray'
    },
    invalidTokenCss: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '350px',
      fontSize: '20px'
    }
  })
)
const validator = makeValidate(
  yup.object<ActivateAccountFormModel>({
    // password: yup.string().label('Password').min(8).required(),
    password: yup
      .string()
      .required('Please Enter your password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{7,15}$/,
        `Password should contain 8 - 15 characters with at least one uppercase, lowercase, numeric
        and special character, white space not allowed!`
      ),
    passwordConfirmation: yup
      .string()
      .label('Password confirmation')
      .required()
      .equals([yup.ref('password')], '${path} must be the same as password')
  })
)

export const ActivateAccountView = () => {
  const history = useHistory()
  const params = useParams<ActivateAccountParams>()
  const [shownewPaswd, toggleNewPaswd] = useState<boolean>(false)
  const classes = useStyles()
  // const [showErrorPattern, setErrorPattern] = useState<string>('')
  const { data, loading, error } = useUserInviteQuery({
    variables: { token: params.token }
  })
  const [activateAccount, { loading: updateLoading }] = useActivateAccountMutation()

  if (loading) return <div>Loading invite info...</div>
  if (error)
    // console.log(error)
    return (
      <div className={classes.invalidTokenCss}>
        This email link is no longer valid. If you already have an account &nbsp;
        <a href={window.location.origin}> Please login here </a>. &nbsp;If not please contact your
        administrator to re-send this email.
      </div>
    )
  // setErrorPattern(error.graphQLErrors[0].message)

  const handleFormSubmit = (values: ActivateAccountFormModel) => {
    activateAccount({
      variables: {
        input: {
          token: params.token,
          password: values.password,
          passwordConfirmation: values.passwordConfirmation
        }
      }
    }).then(() => history.push('/login'))
  }
  const renderForm = (renderProps: FormRenderProps<ActivateAccountFormModel>) => {
    const { handleSubmit } = renderProps

    return (
      <>
        {updateLoading && <ProgressIndicator fullHeight />}
        {data?.userInvite?.district && (
          <FormRow>
            <TextField
              name="district"
              variant="filled"
              disabled
              fullWidth
              label="District"
              value={data?.userInvite?.district?.name}
            />
          </FormRow>
        )}
        <FormRow>
          <TextField
            name="name"
            variant="filled"
            disabled
            fullWidth
            label="Full name"
            value={data?.userInvite?.name}
          />
        </FormRow>
        <FormRow>
          <TextField
            name="email"
            variant="filled"
            disabled
            fullWidth
            label="Email"
            value={data?.userInvite?.email}
          />
        </FormRow>
        <FormRow>
          <TextField
            type={shownewPaswd ? 'text' : 'password'}
            name="password"
            variant="filled"
            fullWidth
            label="Password"
            // type="password"
            required
            InputProps={{
              endAdornment: (
                <InputAdornment className={classes.rightIcon} position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => toggleNewPaswd(!shownewPaswd)}
                    edge="end"
                  >
                    {shownewPaswd ? (
                      <Visibility style={{ color: 'slategray' }} />
                    ) : (
                      <VisibilityOff style={{ color: 'slategray' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </FormRow>
        <FormRow>
          <TextField
            type={shownewPaswd ? 'text' : 'password'}
            name="passwordConfirmation"
            variant="filled"
            fullWidth
            label="Re-enter password"
            // type="password"
            required
            InputProps={{
              endAdornment: (
                <InputAdornment className={classes.rightIcon} position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => toggleNewPaswd(!shownewPaswd)}
                    edge="end"
                  >
                    {shownewPaswd ? (
                      <Visibility style={{ color: 'slategray' }} />
                    ) : (
                      <VisibilityOff style={{ color: 'slategray' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </FormRow>

        <ButtonsRow>
          <Box display="flex">
            <Button
              variant="contained"
              fullWidth
              color="primary"
              size="large"
              onClick={handleSubmit}
            >
              Sign up
            </Button>
          </Box>
        </ButtonsRow>
      </>
    )
  }

  return (
    <PageContainer>
      {data?.userInvite?.roles?.[0] !== 'THERAPIST' &&
        data?.userInvite?.roles?.[0] !== 'SCHOOL_THERAPIST' &&
        data?.userInvite?.roles?.[0] !== 'AGENCY_SUPERVISOR_PROVIDER' && (
          <StyledPaper elevation={0}>
            <PaperHeader>
              <Typography variant="h3" color="secondary">
                Join I Matter
              </Typography>
              <p style={{ fontSize: '12px' }}>
                Password should have at least one uppercase, lowercase, numeric and special
                character!
              </p>
            </PaperHeader>
            <Form<ActivateAccountFormModel>
              render={renderForm}
              onSubmit={handleFormSubmit}
              validate={validator}
            />
          </StyledPaper>
        )}
      {(data?.userInvite?.roles?.[0] === 'THERAPIST' ||
        data?.userInvite?.roles?.[0] === 'SCHOOL_THERAPIST' ||
        data?.userInvite?.roles?.[0] === 'AGENCY_SUPERVISOR_PROVIDER') && <TherapistInviteView />}
    </PageContainer>
  )
}
