import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { AuthenticatedContext } from './context'
import { AuthenticationTokens } from '../../../graphql/hooks.generated'
import {
  useAuthenticationTokensQuery,
  ExecutionQuery,
  QueryState
} from './useAuthenticationTokensQuery'
import { AuthenticationContext } from '../../../types'
import { clearQueryKey } from '../../../helpers/clearQueryKey'
import { generateChallenge, SSOChallenge } from '../../../helpers/pkce'

const { REACT_APP_AUTHORIZE_URL, REACT_APP_LOGOUT_URL, REACT_APP_COGNITO_CLIENT_ID } = process.env
const TOKENS_ITEM_KEY = 'authentication:tokens'
const VERIFIER_ITEM_KEY = 'authentication:verifier'
const PUBLIC_PATHS_REGEX = /^(\/|)(public|login)/
const CODE_QUERY_KEY = 'code'
const VERIFIER_LENGTH = 64

export const AuthProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { push } = useHistory()
  const [
    executeQuery,
    { data: tokensQueryResult, loading: tokensQueryLoading, reset: resetTokensQuery }
  ]: [ExecutionQuery, QueryState] = useAuthenticationTokensQuery()
  const localTokensJSON = localStorage.getItem(TOKENS_ITEM_KEY)
  const localTokens: AuthenticationTokens | undefined =
    localTokensJSON && localTokensJSON !== 'undefined'
      ? (JSON.parse(localTokensJSON) as AuthenticationTokens)
      : undefined
  const [isAuthenticated, setIsAuthenticated]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(!!localTokens)
  const [tokens, setTokens]: [
    AuthenticationTokens | undefined,
    Dispatch<SetStateAction<AuthenticationTokens | undefined>>
  ] = useState<AuthenticationTokens | undefined>(localTokens)

  if (!localTokens && tokensQueryResult) {
    clearQueryKey(CODE_QUERY_KEY)
    setTokens(tokensQueryResult)
    setIsAuthenticated(true)
    localStorage.setItem(TOKENS_ITEM_KEY, JSON.stringify(tokensQueryResult))
    resetTokensQuery()
  }

  useEffect(() => {
    const { search, pathname } = window.location
    const { [CODE_QUERY_KEY]: code } = queryString.parse(search)
    const isRedirectDenied: boolean = PUBLIC_PATHS_REGEX.test(pathname)

    if (!isAuthenticated && !tokensQueryResult && !tokensQueryLoading)
      if (code) executeQuery(code as string, localStorage.getItem(VERIFIER_ITEM_KEY))
      else if (!isRedirectDenied) {
        // else if (!isRedirectDenied || !localStorage.getItem(VERIFIER_ITEM_KEY)) {
        const { verifier, challenge }: SSOChallenge = generateChallenge(VERIFIER_LENGTH)
        localStorage.setItem(VERIFIER_ITEM_KEY, verifier)
        window.location.href = `${REACT_APP_AUTHORIZE_URL}?${queryString.stringify({
          // redirect_uri: window.location.href,
          redirect_uri: window.location.origin,
          code_challenge: challenge,
          client_id: REACT_APP_COGNITO_CLIENT_ID
        })}`
      }
  }, [executeQuery, isAuthenticated, tokensQueryLoading, tokensQueryResult])
  const values: AuthenticationContext = useMemo((): AuthenticationContext => {
    const logout: () => void = () => {
      setTokens(undefined)
      setIsAuthenticated(false)
      localStorage.removeItem(TOKENS_ITEM_KEY)
      localStorage.removeItem(VERIFIER_ITEM_KEY)
      document.cookie = 'authenticated=; expires= Thu, 21 Aug 2014 20:00:00 UTC; path=/auth '
      localStorage.clear()
      // window.location.href = REACT_APP_LOGOUT_URL as string
      document.location.href = '/'
    }

    return {
      isAuthenticated,
      tokens,
      logout,
      setFallbackTokens(tokens: AuthenticationTokens, redirectUrl: string) {
        setTokens(tokens)
        setIsAuthenticated(true)
        localStorage.setItem(TOKENS_ITEM_KEY, JSON.stringify(tokens))
        push(redirectUrl.replace(window.location.origin, ''))
        // push(window.location.origin)
      }
    }
  }, [isAuthenticated, tokens, push])

  return <AuthenticatedContext.Provider value={values}>{children}</AuthenticatedContext.Provider>
}

export { useAuth } from '../../../hooks/useAuth'
