import { Box, Button, ButtonGroup, makeStyles, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import Link from '../../../components/ui/panel/link/Link'
import { ResourceQuery } from '../../../graphql/hooks.generated'
import { CmsResourceContentType } from '../../../graphql/enumTypes'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
const useStyles = makeStyles(() => ({
  pdfDocument: {
    margin: '16px 0px',
    boxShadow: `0px 2px 12px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)`
  }
}))

interface PdfViewProps {
  pdfResource: ResourceQuery['GCMS_resource']
}

type OnDocumentLoadArguments = {
  numPages: number
}

export const PdfView = (props: PdfViewProps) => {
  const { pdfResource } = props

  if (pdfResource?.content?.__typename !== CmsResourceContentType.Pdf)
    throw Error('Unexpected type of Resource content')

  const [numPages, setNumPages] = useState<number | null>(null)
  const [pageNumber, setPageNumber] = useState(1)
  const styles = useStyles()
  const onDocumentLoadSuccess = (args: OnDocumentLoadArguments) => {
    setNumPages(args.numPages)
  }
  const onPrevPage = () => {
    setPageNumber((curr) => --curr)
  }
  const onNextPage = () => {
    setPageNumber((curr) => ++curr)
  }

  return (
    <>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Box my={3}>
          <Typography gutterBottom variant="h1" color="secondary">
            {pdfResource.title}
          </Typography>
        </Box>

        <Document
          className={styles.pdfDocument}
          file={pdfResource.content?.file.url}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <ButtonGroup>
          <Button onClick={onPrevPage} disabled={pageNumber === 1}>
            Previous page
          </Button>
          <Button onClick={onNextPage} disabled={pageNumber === numPages}>
            Next page
          </Button>
        </ButtonGroup>
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </Box>
      <Link href={pdfResource.content?.file.url}>Download file</Link>

      <Typography variant="body2" color="textSecondary" component="p">
        Last updated: {format(new Date(pdfResource.updatedAt), 'MM/dd/yyyy')}
      </Typography>
    </>
  )
}
