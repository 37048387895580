import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles<Theme>({
  root: {},
  mr: {
    marginRight: '20px'
  },
  popupCls: {
    backgroundColor: '#2c9bd6',
    paddingTop: '0',
    paddingBottom: '0',
    color: '#fff',
    titleCls: {
      margin: '0 auto'
    },
    '& div': {
      margin: '0 auto',
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 100,
      fontFamily: 'Brandon Grotesque',
      '& h2': {
        fontWeight: 100,
        marginTop: '10px',
        marginBottom: '10px'
      }
    }
  }
})
