import React from 'react'
import axios from 'axios'

const { REACT_APP_API_URL } = process.env

export const Urlservice = {
  questionsUrl: 'signal/survey/questions',
  saveQuestionsUrl: 'signal/survey/savesurvey',
  surveyScore: 'signal/survey/scorecard',
  geturl: (url, postdata?) => {
    const uri = `${REACT_APP_API_URL}/${url}`

    return axios.get(uri, postdata)
  },
  posturl: (url, postdata?) => {
    const uri = `${REACT_APP_API_URL}/${url}`

    return axios.post(uri, postdata)
  }
}
