import React, { useMemo, useEffect, useState, useRef } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import Paper from '@material-ui/core/Paper'
import { UseParamsType } from 'types'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import moment from 'moment'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import DeleteIcon from '@material-ui/icons/Delete'
import { useCurrentUserQuery, Role, FileuploadQuery } from 'graphql/hooks.generated'
import { useStyles } from '../../../../../../config/constants/theme/profile'
import { PageContainer } from '../../../../../../components/ui/panel/page-container/PageContainer'

interface ParentProps {
  filedata: FileuploadQuery
  onDeleteuploadfile: (val) => any
  isconfirmmodalopen: boolean
  setisconfirmmodalopen: (flag) => any
}
interface ListViewParams extends UseParamsType {
  id: string
  stu_id: string
}

export const CollapsibleTable3 = (props: ParentProps) => {
  const { filedata, onDeleteuploadfile, isconfirmmodalopen, setisconfirmmodalopen } = props
  const classes = useStyles()
  const location = useLocation()
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const { stu_id, id } = useParams<ListViewParams>()
  let stud_id = '-1'
  let decode1
  let decode2
  try {
    ;[decode1, decode2] = atob(stu_id).split('_')
  } catch (e) {
    // console.log(e)
  }

  if (decode1 && decode2 && decode1 === decode2) stud_id = decode1

  const { hasRole } = useCurrentUser()
  const isDAdmin = hasRole(Role.SchoolAdmin)
  const [deleteid, setdeleteid] = useState('')
  const [providername, setProvidername] = useState('')
  const onDelete = () => {
    onDeleteuploadfile(deleteid)
  }
  const openConfirmModal = (deletid) => {
    setdeleteid(deletid)
    setisconfirmmodalopen(true)
  }
  const cancelConfirmModal = () => {
    setisconfirmmodalopen(false)
  }
  useEffect(() => {
    const rolesName = currentUserdata?.data?.currentUser?.roles?.[0]
    const currentuser_provider =
      (rolesName === Role.Therapist ||
        rolesName === Role.SchoolTherapist ||
        rolesName === Role.AgencySupervisorProvider) &&
      currentUserdata?.data?.currentUser?.id

    if (rolesName === Role.AgencySupervisor || rolesName === Role.AgencySupervisorProvider)
      setProvidername((location.state as string) || (currentuser_provider as string))
    else setProvidername(currentuser_provider || '')
  }, [currentUserdata, location.state])
  // const filedatas = filedata?.fileUploadList?.[0]?.user_id?.id

  return (
    <>
      <PageContainer>
        <TableContainer className={(classes.updatetable, classes.tabconAdjust)} component={Paper}>
          <Table
            aria-label="collapsible table"
            style={{
              tableLayout: 'fixed',
              wordWrap: 'break-word',
              fontFamily: 'Brandon Grotesque'
            }}
          >
            <TableRow>
              <TableCell className={classes.dochead} width="25%">
                File Name
              </TableCell>
              <TableCell className={classes.dochead} width="25%">
                Category
              </TableCell>
              <TableCell className={classes.dochead} width="25%">
                Date & Time Uploaded
              </TableCell>
              <TableCell className={classes.dochead} width="25%">
                Created By
              </TableCell>
              {!hasRole(Role.AgencySupervisor) && !hasRole(Role.AgencySupervisorProvider) && (
                <TableCell className={`${classes.headers} ${classes.alignleft}`} width="20%">
                  Actions
                </TableCell>
              )}
              {!hasRole(Role.Therapist) &&
                hasRole(Role.AgencySupervisorProvider) &&
                providername === currentUserdata?.data?.currentUser?.id && (
                  <TableCell className={`${classes.headers} ${classes.alignleft}`} width="20%">
                    Actions
                  </TableCell>
                )}
            </TableRow>
            <TableBody>
              {filedata && filedata?.fileUploadList?.length ? (
                <>
                  {filedata?.fileUploadList?.map((details) => (
                    <>
                      {stu_id && (
                        <TableRow className={classes.root}>
                          {hasRole(Role.AgencySupervisor) && details?.user_id?.id === providername && (
                            <>
                              <TableCell className={classes.forntSizesline}>
                                {details?.file_name}
                              </TableCell>
                              <TableCell className={classes.forntSizesline}>
                                {details?.categories?.categories}
                              </TableCell>
                              <TableCell className={classes.forntSizesline}>
                                {' '}
                                {moment
                                  // .tz(details?.created_on, 'MM/DD/YYYY h:mm A', 'US/Mountain').format()
                                  .tz(details?.created_on, 'America/Denver')
                                  .format('MM-DD-YYYY h:mm A')}
                              </TableCell>
                              <TableCell className={classes.forntSizesline}>
                                {details?.user_id?.first_name}
                                {details?.user_id?.last_name}
                              </TableCell>
                            </>
                          )}

                          {hasRole(Role.AgencySupervisorProvider) &&
                            details?.user_id?.id === providername && (
                              <>
                                <TableCell className={classes.forntSizesline}>
                                  {details?.file_name}
                                </TableCell>
                                <TableCell className={classes.forntSizesline}>
                                  {details?.categories?.categories}
                                </TableCell>
                                <TableCell className={classes.forntSizesline}>
                                  {' '}
                                  {moment
                                    // .tz(details?.created_on, 'MM/DD/YYYY h:mm A', 'US/Mountain').format()
                                    .tz(details?.created_on, 'America/Denver')
                                    .format('MM-DD-YYYY h:mm A')}
                                </TableCell>
                                <TableCell className={classes.forntSizesline}>
                                  {details?.user_id?.first_name}
                                  {details?.user_id?.last_name}
                                </TableCell>
                              </>
                            )}
                          {!hasRole(Role.Therapist) &&
                            hasRole(Role.AgencySupervisorProvider) &&
                            providername === currentUserdata?.data?.currentUser?.id &&
                            details?.user_id?.id === currentUserdata?.data?.currentUser?.id && (
                              <>
                                <Button
                                  // disabled={
                                  //   !isDAdmin &&
                                  //   details?.user_id?.id !== currentUserdata?.data?.currentUser?.id
                                  // }
                                  color="primary"
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  className="mar-rt-15 "
                                  variant="contained"
                                  size="medium"
                                  style={{
                                    padding: '6px 25x',
                                    // borderRadius: 25,
                                    marginTop: '20px',
                                    float: 'left'
                                  }}
                                >
                                  <a className={classes.hideL} href={details?.fileUrl || ''}>
                                    Download
                                  </a>
                                </Button>
                                <DeleteIcon
                                  onClick={(e) => openConfirmModal(details?.id)}
                                  style={{
                                    marginTop: '22px'
                                  }}
                                  className={`${classes.dels} ${
                                    !isDAdmin &&
                                    details?.user_id?.id !== currentUserdata?.data?.currentUser?.id
                                      ? classes.nopointer
                                      : ''
                                  }`}
                                />
                              </>
                            )}
                          {hasRole(Role.SchoolAdmin) ? (
                            <>
                              <TableCell className={classes.forntSizesline}>
                                {details?.file_name}
                              </TableCell>
                              <TableCell className={classes.forntSizesline}>
                                {details?.categories?.categories}
                              </TableCell>
                              <TableCell className={classes.forntSizesline}>
                                {' '}
                                {moment
                                  // .tz(details?.created_on, 'MM/DD/YYYY h:mm A', 'US/Mountain').format()
                                  .tz(details?.created_on, 'America/Denver')
                                  .format('MM-DD-YYYY h:mm A')}
                              </TableCell>
                              <TableCell className={classes.forntSizesline}>
                                {details?.user_id?.first_name}
                                {details?.user_id?.last_name}
                              </TableCell>
                            </>
                          ) : (
                            <>
                              {!hasRole(Role.AgencySupervisorProvider) &&
                                details?.user_id?.id === currentUserdata?.data?.currentUser?.id && (
                                  <>
                                    <TableCell className={classes.forntSizesline}>
                                      {details?.file_name}
                                    </TableCell>
                                    <TableCell className={classes.forntSizesline}>
                                      {details?.categories?.categories}
                                    </TableCell>
                                    <TableCell className={classes.forntSizesline}>
                                      {' '}
                                      {moment
                                        // .tz(details?.created_on, 'MM/DD/YYYY h:mm A', 'US/Mountain').format()
                                        .tz(details?.created_on, 'America/Denver')
                                        .format('MM-DD-YYYY h:mm A')}
                                    </TableCell>
                                    <TableCell className={classes.forntSizesline}>
                                      {details?.user_id?.first_name}
                                      {details?.user_id?.last_name}
                                    </TableCell>
                                  </>
                                )}
                            </>
                          )}
                          {!hasRole(Role.AgencySupervisor) && (
                            <TableCell className={classes.forntSizesline}>
                              <>
                                {hasRole(Role.SchoolAdmin) ? (
                                  <>
                                    <Button
                                      // disabled={
                                      //   !isDAdmin &&
                                      //   details?.user_id?.id !== currentUserdata?.data?.currentUser?.id
                                      // }
                                      color="primary"
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                      className="mar-rt-15 "
                                      variant="contained"
                                      size="medium"
                                      style={{
                                        padding: '6px 25x',
                                        borderRadius: 25,
                                        float: 'left'
                                      }}
                                    >
                                      <a className={classes.hideL} href={details?.fileUrl || ''}>
                                        Download
                                      </a>
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    {!hasRole(Role.AgencySupervisorProvider) &&
                                      details?.user_id?.id ===
                                        currentUserdata?.data?.currentUser?.id && (
                                        <Button
                                          // disabled={
                                          //   !isDAdmin &&
                                          //   details?.user_id?.id !== currentUserdata?.data?.currentUser?.id
                                          // }
                                          color="primary"
                                          aria-controls="simple-menu"
                                          aria-haspopup="true"
                                          className="mar-rt-15 "
                                          variant="contained"
                                          size="medium"
                                          style={{
                                            padding: '6px 25x',
                                            borderRadius: 25,
                                            float: 'left'
                                          }}
                                        >
                                          <a
                                            className={classes.hideL}
                                            href={details?.fileUrl || ''}
                                          >
                                            Download
                                          </a>
                                        </Button>
                                      )}
                                  </>
                                )}
                              </>
                              <>
                                {!hasRole(Role.AgencySupervisorProvider) &&
                                  details?.user_id?.id ===
                                    currentUserdata?.data?.currentUser?.id && (
                                    <DeleteIcon
                                      onClick={(e) => openConfirmModal(details?.id)}
                                      className={`${classes.dels} ${
                                        !isDAdmin &&
                                        details?.user_id?.id !==
                                          currentUserdata?.data?.currentUser?.id
                                          ? classes.nopointer
                                          : ''
                                      }`}
                                    />
                                  )}
                              </>
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell style={{ fontFamily: 'Brandon Grotesque' }}>
                    <span>
                      <h3>No files are found</h3>
                    </span>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </PageContainer>
      {isconfirmmodalopen && (
        <Dialog
          open={isconfirmmodalopen}
          maxWidth="md"
          onClose={cancelConfirmModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to remove file from upload list?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelConfirmModal} color="primary">
              No
            </Button>
            <Button onClick={onDelete} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
