import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  useTheme
} from '@material-ui/core'
import Paper from '../../../../components/ui/panel/paper/Paper'
import Button from '../../../../components/ui/panel/button/Button'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { useListStatesQuery, useDeleteStateMutation } from '../../../../graphql/hooks.generated'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'

export const StateListView: React.FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const classes = useStyles()
  const { data, loading, refetch } = useListStatesQuery({ fetchPolicy: 'network-only' })
  const [deleteState] = useDeleteStateMutation()
  const onDeleteState = async (id) => {
    await deleteState({ variables: { id } })
    refetch()
  }
  const handleClick = () => {
    history.push('/states/create')
  }
  const isLoading = () => (loading ? <ProgressIndicator fullHeight /> : '')

  return (
    <>
      <NavBar title="States" goBackBtn />
      <PageContainer>
        <Box py={3} display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" style={{ color: theme.palette.info.main }}>
              States
            </Typography>
          </Box>
          <Box>
            <Button color="primary" variant="contained" size="small" onClick={handleClick}>
              Add state
            </Button>
          </Box>
        </Box>
        <TableContainer square={false} component={Paper}>
          <Table>
            <TableHead className={classes.headRoot}>
              <TableRow>
                <TableCell>ABBR</TableCell>
                <TableCell>NAME</TableCell>
                <TableCell>MEDICAID NAME</TableCell>
                <TableCell>ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.states?.map((state) => (
                <TableRow key={state.id}>
                  <TableCell>{state.abbreviation}</TableCell>
                  <TableCell>{state.name}</TableCell>
                  <TableCell>{state.medicaid_name}</TableCell>
                  <TableCell>
                    <Link
                      color="inherit"
                      component="button"
                      style={{ marginRight: '15px' }}
                      onClick={() => history.push(`/states/${state.id}/edit`)}
                    >
                      Edit
                    </Link>
                    <Link
                      onClick={() => onDeleteState(state.id)}
                      color="primary"
                      component="button"
                    >
                      Delete
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading()}
      </PageContainer>
    </>
  )
}
