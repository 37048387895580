import React from 'react'
import Dashboard from '../../../components/ui/dashboard/Dashboard'
import Tour from '../../../components/ui/tour/Tour'
import { Role } from '../../../graphql/hooks.generated'

export const ParentDashboard = () => {
  return (
    <>
      <Tour steps={Role.Parent} />
      <Dashboard cards={[]} showGeneratedReports={false} />
    </>
  )
}
