import { useCallback } from 'react'
import { client } from '../../../components/providers/graphql'
import { useAuth } from '../../../components/providers/auth'
import { AuthenticationContext } from '../../../types'

export const useLogout = () => {
  const { logout }: AuthenticationContext = useAuth()

  return useCallback(() => {
    client.clearStore()
    logout()
  }, [logout])
}
