import React from 'react'
import { useRouteMatch, Switch } from 'react-router-dom'
import { PrivateRoute } from '../../../components/router/PrivateRoute'
import { Role } from '../../../graphql/hooks.generated'
import { CreateAssignmentView } from './create/CreateAssignmentView'
import { MyAssignmentsView } from './my/MyAssignmentsView'

export const ProviderAssignmentsRootView = () => {
  const { url } = useRouteMatch()

  return (
    <Switch>
      <PrivateRoute
        path={`${url}/create/:student?/:assignmentType?`}
        roles={[Role.TeleteachersAdmin]}
        component={CreateAssignmentView}
      />
      <PrivateRoute
        path={`${url}/my`}
        roles={[Role.Therapist, Role.SchoolTherapist, Role.SchoolAdmin, Role.TeleteachersAdmin]}
        component={MyAssignmentsView}
      />
    </Switch>
  )
}
