import React, { useState, useEffect, useCallback } from 'react'
import { withStyles, createStyles, Theme, Box, Modal, Typography } from '@material-ui/core'
import { useCurrentUser } from '../../../../graphql/hooks/useCurrentUser/useCurrentUser'
import Paper from '../../panel/paper/Paper'
import Button from '../../panel/button/Button'
import { useAuth } from '../../../providers/auth'
import { AuthenticationContext } from '../../../../types'

interface AutoLogoutProps {
  classes: {
    modal?: string
    header?: string
  }
}
const logoutTime = 90 * 60 * 1000 // 90 min
const idleTime = 85 * 60 * 1000 // 85 min
const AutoLogout: React.FC<AutoLogoutProps> = (props: AutoLogoutProps) => {
  const { refetch } = useCurrentUser()
  const { logout }: AuthenticationContext = useAuth()
  const [time, setTime] = useState(0)
  const resetTime = () => {
    setTime(0)
  }
  const keydowmFun = useCallback(() => {
    resetTime()
  }, [])

  if (time === 0) {
    window.addEventListener('keydown', keydowmFun)
    document.addEventListener('mousedown', keydowmFun)
    document.addEventListener('mousemove', keydowmFun)
    document.addEventListener('mouseover', keydowmFun)
  }
  if (time === idleTime) {
    window.removeEventListener('keydown', keydowmFun)
    document.removeEventListener('mousedown', keydowmFun)
    document.removeEventListener('mousemove', keydowmFun)
    document.removeEventListener('mouseover', keydowmFun)
  }

  useEffect(() => {
    const t = setTimeout(() => {
      setTime(time + 1000)
    }, 1000)

    if (time >= logoutTime) logout()

    return () => clearTimeout(t)
  }, [logout, time])
  const resetIddle = () => {
    refetch()
    resetTime()
  }
  const { classes } = props
  const isIddle = time >= idleTime
  const dtime = logoutTime - time
  const minutes = Math.floor((dtime / 1000 / 60) % 60).toString()
  const seconds = Math.floor((dtime / 1000) % 60).toString()
  const formattedTime = `${minutes}:${seconds.padStart(2, '0')}`
  // console.log('Remain sec....', seconds)

  return (
    <Modal open={isIddle} className={classes.modal ?? ''}>
      <Paper style={{ maxWidth: '415px' }}>
        <Box
          className={classes.header ?? ''}
          p={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography gutterBottom variant="subtitle1">
            Auto log out
          </Typography>
          <span>{formattedTime}</span>
        </Box>
        <Box p={3} textAlign="center">
          <Typography>You will be disconnected due inactivity</Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '30px', fontWeight: 500 }}
            onClick={resetIddle}
          >
            Continue
          </Button>
        </Box>
      </Paper>
    </Modal>
  )
}

export default withStyles(({ palette }: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    header: {
      borderBottom: `1px dashed ${palette.divider}`
    }
  })
)(AutoLogout)
