import {
  ServiceRequestType,
  ServiceRequestDiscipline,
  PlanType,
  SchoolStatus,
  StudentGender,
  DistrictSignatureRequired,
  TherapistDiscipline,
  UserStatus,
  TimeZone,
  UserGender
} from 'graphql/hooks.generated'

export const serviceRequestTypeDescription = {
  [ServiceRequestType.Evaluation]: 'Evaluation',
  [ServiceRequestType.RecordReview]: 'Record review',
  [ServiceRequestType.Screening]: 'Screening',
  [ServiceRequestType.Therapy]: 'Therapy'
}

export const serviceRequestDisciplineDescription = {
  [ServiceRequestDiscipline.Behavioral]: 'Behavioral health',
  [ServiceRequestDiscipline.Occupational]: 'Occupational therapy',
  [ServiceRequestDiscipline.Physical]: 'Physical therapy',
  [ServiceRequestDiscipline.Speech]: 'Speech-language pathology'
}

export const planTypeDescription = {
  [PlanType.Iep]: 'IEP',
  [PlanType.Section504]: '504 Plan',
  [PlanType.Behavior]: 'Rti Plan',
  [PlanType.SupportSchoolPersonnel]: 'Support School Personnel Plan',
  [PlanType.Individual]: 'Individual Service Plan',
  [PlanType.NonResident]: 'Non Resident Plan',
  [PlanType.NonIdea]: 'BH Plan (Non IDEA)'
}

export const SchoolStatusDescription = {
  [SchoolStatus.Active]: 'Active',
  [SchoolStatus.Inactive]: 'Inactive'
}

export const timeZoneDescription = {
  [TimeZone.CentralTimeUsAndCanada]: 'Central Time (US & Canada)',
  [TimeZone.EasternTimeUsAndCanada]: 'Eastern Time (US & Canada)',
  [TimeZone.MountainTimeUsAndCanada]: 'Mountain Time (US & Canada)',
  [TimeZone.PacificTimeUsAndCanada]: 'Pacific Time (US & Canada)'
}

export const StudentGenderDescription = {
  [StudentGender.Male]: 'Male',
  [StudentGender.Female]: 'Female'
}

export const districtSignatureRequiredDescription = {
  [DistrictSignatureRequired.NotRequired]: 'Not required',
  [DistrictSignatureRequired.Always]: 'Always',
  [DistrictSignatureRequired.Billable]: 'Billable'
}

export const UserStatusDescription = {
  [UserStatus.Active]: 'Active',
  [UserStatus.Inactive]: 'Inactive'
}

export const therapistDisciplineDescription = {
  [TherapistDiscipline.All]: 'All',
  [TherapistDiscipline.Occupational]: 'Occupational',
  [TherapistDiscipline.Physical]: 'Physical',
  [TherapistDiscipline.PtAndOt]: 'PT/OT',
  [TherapistDiscipline.SchoolCounselor]: 'School Counselor',
  [TherapistDiscipline.SchoolPsychologist]: 'School Psychologist',
  [TherapistDiscipline.SpedTeacher]: 'Sped Teacher',
  [TherapistDiscipline.Speech]: 'Speech',
  [TherapistDiscipline.TviTeacher]: 'TVI Teacher',
  [TherapistDiscipline.SocialWorker]: 'Social Worker'
}

export const UserGenderDescription = {
  [UserGender.Male]: 'Male',
  [UserGender.Female]: 'Female',
  [UserGender.Nonthird]: 'Non-binary/third gender',
  [UserGender.Oprefer]: 'Prefer not to say',
  [UserGender.Pother]: 'Other'
}
