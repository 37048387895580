// dashboad helper
import moment from 'moment'

export const GetDashboardTimePeriods = (searchtype, q?) => {
  const date = new Date()
  const month = date.getMonth()
  const m = month + 1
  switch (searchtype) {
    case 'YTD': {
      const obj = { startDate: '', endDate: '' }

      if (m >= 8) {
        obj.startDate = `${date.getFullYear()}-08-01`
        obj.endDate = `${date.getFullYear() + 1}-07-31`
      } else {
        obj.startDate = `${date.getFullYear() - 1}-08-01`
        obj.endDate = `${date.getFullYear()}-07-31`
      }

      return obj
    }
    case 'CM':
      return {
        startDate: moment().clone().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().clone().endOf('month').format('YYYY-MM-DD')
      }
    case 'PM':
      return {
        startDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
      }
    case 'QD':
      return {
        startDate: moment().quarter(q).startOf('quarter').format('YYYY-MM-DD'),
        endDate: moment().quarter(q).endOf('quarter').format('YYYY-MM-DD')
      }
    default:
      return { startDate: '', endDate: '' }
  }
}
