import { Action, combineReducers } from 'redux'
import { Client, Conversation } from '@twilio/conversations'
import tokenReducer from './tokenReducer'
import unreadMessagesReducer, { UnreadMessagesState } from './unreadMessagesReducer'
import { ActionType } from '../action-types'

export type AppState = {
  token: string
  sid: string
  unreadMessages: UnreadMessagesState
  client: Client
}

export const initialState = {
  token: '',
  sid: '',
  unreadMessages: {},
  client: new Client('null')
}
const appReducer = combineReducers({
  token: tokenReducer,
  unreadMessages: unreadMessagesReducer
})
const reducers = (state: AppState | any, action: Action) => {
  if (action.type === ActionType.LOGOUT) {
    localStorage.removeItem('username')

    return appReducer(initialState, action)
  }

  return appReducer(state, action)
}

export default reducers
