import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import AppStateProvider from 'views/private/newSessions/view/video/state'
import CalendarView from './calendar'
import { SessionRootView } from '../newSessions/sessionRootView'

export const ScheduleRootView = () => {
  const { path } = useRouteMatch()
  // console.log('session', path)

  return (
    <AppStateProvider>
      <Switch>
        {/* <Route component={ScheduleView} /> */}
        <Route path={`${path}/:id/:startTime/:endTime/sessions`} component={SessionRootView} />
        <Route component={CalendarView} />
      </Switch>
    </AppStateProvider>
  )
}
