import React from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import { useListTrainingQuery } from 'graphql/hooks.generated'
import { theme } from 'config/constants/theme'
import { Section } from '../../../../components/ui/panel/section/Section'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1)
    },
    fs14: {
      fontSize: '17px',
      color: '#FF0000'
    }
  })
)

export const ProviderManulaDoc = () => {
  const classes = useStyles()
  const { data, loading, refetch } = useListTrainingQuery({
    fetchPolicy: 'network-only'
  })

  return (
    <>
      {loading && <ProgressIndicator fullHeight />}

      {data &&
        data?.reportshistory?.map(
          (reports, index) =>
            index === 0 && (
              <PageContainer>
                <Section title="">
                  <Typography
                    variant="h3"
                    style={{
                      color: theme.palette.info.main,
                      top: '-41px',
                      position: 'relative'
                    }}
                  >
                    {' '}
                    Training Materials{' '}
                  </Typography>
                  <Grid>
                    <Grid>
                      <a
                        className={`${classes.fs14}`}
                        onClick={() => refetch()}
                        href={reports?.YRMHR_Provider_Manual || ''}
                        target="_blank"
                        rel="noreferrer"
                      >
                        About the Program
                      </a>
                      <br />
                      <br />
                      <div style={{ fontSize: '16px' }}>I Matter Provider Manual PDF</div>
                    </Grid>
                    <br />
                    {/* <Grid>
                      <a
                        className={`${classes.fs14}`}
                        onClick={() => refetch()}
                        href={reports?.IMatter_Provider_Manual || ''}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Platform Training Guide
                      </a>{' '}
                      <br />
                      <br />
                      <div style={{ fontSize: '16px' }}>
                        IMatter Colorado Platform Training Guide PDF
                      </div>
                    </Grid>
                    <br /> */}
                    {/* <Grid>
                      <a
                        className={`${classes.fs14}`}
                        // href={`http://${process.env.S3_BUCKET_NAME}.s3.amazonaws.com/training-docs/IMatter+Provider+Manual.pdf`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        Provider Platform Training Demo
                      </a>{' '}
                      <br />
                      <br />
                      <div style={{ fontSize: '16px' }}>Demo</div>
                    </Grid> */}
                  </Grid>
                </Section>
              </PageContainer>
            )
        )}
    </>
  )
}
