import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import { useCreateAgencyMutation } from '../../../../graphql/hooks.generated'
import { useErrorHandler } from '../../../../graphql/hooks/useErrorHandler/useErrorHandler'
import { AgencyForm, AgencyFormModel } from '../form/AgencyForm'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'

export const CreateAgencyView = () => {
  const [createAgency] = useCreateAgencyMutation()
  const handleError = useErrorHandler()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const handleFormSubmit = (values: AgencyFormModel) =>
    createAgency({
      variables: {
        input: {
          ...values
        }
      }
    })
      .then(() => {
        history.push('/agency')
      })
      .catch(() => {
        enqueueSnackbar('Agency already exists', {
          variant: 'error'
        })
      })

  return (
    <>
      <NavBar title="Add a agency" goBackBtn />
      <PageContainer>
        <AgencyForm onSubmit={handleFormSubmit} submitLabel="Create Agency" />
      </PageContainer>
    </>
  )
}
