import React from 'react'
import { styled, Box as MBox, BoxProps, CircularProgress } from '@material-ui/core'

interface IProps extends BoxProps {
  fullHeight?: boolean
}
const Box = styled(MBox)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // position: 'fixed',
  // top: '0px',
  left: '0px',
  width: '100%',
  zIndex: 9999
})
const ProgressIndicator: React.FC<IProps> = ({ fullHeight, top, position }: IProps) => {
  return (
    <Box
      height={fullHeight ? '100vh' : '100px'}
      top={top ? 'unset' : '0px'}
      position={position || 'fixed'}
    >
      <CircularProgress style={{ color: '#F1583A' }} />
    </Box>
  )
}

export default ProgressIndicator
