import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  ToolbarItems,
  ExcelExport,
  Grid,
  Inject,
  Toolbar,
  Page,
  ExcelExportProperties,
  SortSettingsModel,
  Sort
} from '@syncfusion/ej2-react-grids'
import React, { useMemo, useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { Typography, Link, MenuItem, Select } from '@material-ui/core'
import { theme } from 'config/constants/theme'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import {
  Role,
  useCurrentUserQuery,
  useProviderNameListLazyQuery,
  useReportLazyQuery
} from '../../../../graphql/hooks.generated'
import { useStyles } from '../../../../config/constants/theme/profile'

export const Billingreport = () => {
  const history = useHistory()
  let grid: Grid | null
  const toolbar: ToolbarItems[] = ['ExcelExport']
  const [reportQuery, { data, loading, refetch }] = useReportLazyQuery({
    fetchPolicy: 'network-only'
  })
  const lastOneWeek = moment().subtract(7, 'days').format('YYYY-MM-DD')
  const currentDate = moment().format('YYYY-MM-DD')
  const [billfrom, BillFrom] = useState(lastOneWeek)
  const [billTo, BillTo] = useState(currentDate)
  const classes = useStyles()
  const [providername, setProvidername] = useState('')
  const { hasAnyRole } = useCurrentUser()
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const [
    executeThepistQuery,
    { data: therapistData, loading: therapistDocumentLoading }
  ] = useProviderNameListLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      agency: String(
        currentUserdata?.data?.currentUser?.signalAgencies &&
          currentUserdata?.data?.currentUser?.signalAgencies[0]?.name
          ? currentUserdata?.data?.currentUser?.signalAgencies[0]?.name
          : 0
      )
    }
  })
  const fetchTherapists = useMemo(() => () => executeThepistQuery(), [executeThepistQuery])
  useEffect(() => {
    fetchTherapists()
  }, [fetchTherapists])
  useEffect(() => {
    if (therapistData && currentUserdata) {
      const provider = therapistData?.providerName?.[0]?.provider_id
      const currentprovider = currentUserdata?.data?.currentUser?.roles?.[0]
      setProvidername(
        currentprovider === Role.AgencySupervisorProvider
          ? currentUserdata?.data?.currentUser?.id || ''
          : 'All' || ''
      )
    }
  }, [currentUserdata, therapistData, therapistData?.providerName])
  const providerchange = (e) => {
    setProvidername(e.target.value)
  }
  const FromDate = (e) => {
    BillFrom(e.target.value)
  }
  const ToDate = (e) => {
    BillTo(e.target.value)
  }
  const sortSettings: SortSettingsModel = {
    columns: [
      { field: 'agency', direction: 'Ascending' },
      { field: 'providername', direction: 'Ascending' }
    ]
  }
  const Fromformat = moment(billfrom).format('MM/DD/YYYY')
  const Toformat = moment(billTo).format('MM/DD/YYYY')
  const toolbarClick = (args) => {
    if (grid && args.item.id === 'grid_excelexport') {
      const excelExportProperties: ExcelExportProperties = {
        fileName: `BillingReport(${billfrom}_${billTo}).xlsx`,
        header: {
          headerRows: 4,
          rows: [
            {
              cells: [
                {
                  colSpan: 4,
                  value: 'Billing Report',
                  style: { fontSize: 15, bold: true }
                }
              ]
            },
            {
              cells: [
                {
                  colSpan: 4,
                  value: `From Date: ${Fromformat}`,
                  style: { fontSize: 13, bold: true }
                }
              ]
            },
            {
              cells: [
                {
                  colSpan: 4,
                  value: `To Date: ${Toformat}`,
                  style: { fontSize: 13, bold: true }
                }
              ]
            }
          ]
        }
      }
      grid.excelExport(excelExportProperties)
    }
  }
  const reportquery = useMemo(
    () => ({ from_date, to_date, user_id, agency_id }) =>
      reportQuery({
        variables: { from_date, to_date, user_id, agency_id }
      }),
    [reportQuery]
  )
  const provider = hasAnyRole(Role.AgencySupervisor, Role.AgencySupervisorProvider)
  const agency = String(currentUserdata?.data?.currentUser?.signalAgencies?.[0]?.id)
  useEffect(() => {
    if (billfrom && billTo) {
      const input = {
        from_date: billfrom,
        to_date: billTo,
        user_id: provider ? providername : '',
        agency_id: provider ? agency : ''
      }
      reportquery(input)
    }
  }, [billfrom, billTo, reportquery, providername, provider, agency])

  return (
    <>
      <PageContainer>
        <div style={{ display: 'flex' }}>
          <Typography variant="h3" style={{ color: theme.palette.info.main, padding: '12px' }}>
            {' '}
            Billing Report{' '}
          </Typography>
          {hasAnyRole(Role.AgencySupervisorProvider, Role.AgencySupervisor) && (
            <div style={{ fontSize: '16px', padding: '12px', marginLeft: 'auto' }}>
              <span>
                {' '}
                <b style={{ fontSize: '16px' }}>Provider:</b>{' '}
                <Select
                  style={{
                    width: '150px',
                    height: '32px',
                    marginRight: '8px',
                    textAlign: 'initial'
                  }}
                  variant="filled"
                  value={providername}
                  className={classes.selectDropdown}
                  onChange={providerchange}
                  classes={{
                    root: 'muisc-root',
                    select: 'muisc-select',
                    selectMenu: 'muisc-select-menu'
                  }}
                >
                  <MenuItem value="All">All</MenuItem>
                  {therapistData?.providerName && therapistData?.providerName?.length > 0 ? (
                    therapistData?.providerName?.map((listTherapists) => (
                      <MenuItem
                        key={listTherapists.provider_id?.toString()}
                        value={listTherapists.provider_id?.toString()}
                        style={{
                          maxWidth: '280px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {listTherapists.provider_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="No Data Found">No Data Found</MenuItem>
                  )}
                </Select>
              </span>
            </div>
          )}
        </div>
        <div style={{ position: 'relative' }}>
          <div
            style={{
              textAlign: 'end',
              position: 'absolute',
              top: '21px',
              right: '10px',
              zIndex: 99,
              fontSize: '16px',
              lineHeight: 'normal'
            }}
          >
            <span>
              {' '}
              <b> From Date:</b>{' '}
              <TextField
                style={{ border: '1px solid #000' }}
                name="FromDate"
                type="Date"
                onChange={FromDate}
                defaultValue={billfrom}
              />
            </span>{' '}
            &nbsp;
            <span>
              {' '}
              <b>To Date:</b>{' '}
              <TextField
                style={{ border: '1px solid #000' }}
                name="ToDate"
                type="Date"
                onChange={ToDate}
                defaultValue={billTo}
              />
            </span>
          </div>
          {loading && <ProgressIndicator fullHeight />}
          <GridComponent
            dataSource={data?.report || []}
            allowTextWrap
            allowPaging
            pageSettings={{ pageSizes: [15, 25, 50, 100], pageSize: 15, pageCount: 5 }}
            allowSorting
            id="grid"
            toolbar={toolbar}
            allowExcelExport
            toolbarClick={(e) => toolbarClick(e)}
            ref={(g) => {
              grid = g
            }}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="agency"
                headerText="Agency"
                width="120"
                allowFiltering={false}
              />
              <ColumnDirective
                field="providername"
                headerText="Provider Name"
                width="120"
                allowFiltering={false}
              />
              <ColumnDirective
                field="service"
                headerText="Service Name"
                width="120"
                allowFiltering={false}
              />
              <ColumnDirective
                field="client"
                template={(report) => (
                  <Link
                    onClick={(e) => {
                      history.push({
                        pathname: `/studentsinfo/${btoa(
                          `${report?.student_id}_${report?.student_id}`
                        )}`,
                        state: report?.provider_id
                      })
                    }}
                    style={{ color: 'black', cursor: 'pointer' }}
                  >
                    <span>{`${report?.client}`}</span>
                  </Link>
                )}
                headerText="Client"
                width="120"
              />
              <ColumnDirective
                field="client_id"
                headerText="Client Id"
                width="120"
                allowFiltering={false}
              />
              <ColumnDirective
                field="county"
                headerText="County"
                width="120"
                allowFiltering={false}
              />
              <ColumnDirective
                field="zip"
                headerText="Zip Code"
                width="120"
                allowFiltering={false}
              />
              <ColumnDirective
                field="signalservice"
                headerText="In-Person/ Telehealth"
                width="120"
                allowFiltering={false}
              />
              <ColumnDirective field="Date" headerText="Date" width="120" allowFiltering={false} />
              <ColumnDirective
                field="fromtime"
                headerText="From Time"
                width="120"
                allowFiltering={false}
              />
              <ColumnDirective
                field="totime"
                headerText="To Time"
                width="120"
                allowFiltering={false}
              />
              <ColumnDirective
                field="sign"
                headerText="Documentation"
                width="150"
                allowFiltering={false}
              />
              <ColumnDirective
                field="present"
                headerText="Present/ Absent"
                width="120"
                allowFiltering={false}
              />
              {/* <ColumnDirective
                field="TimeElapsed"
                headerText="Time Elapsed"
                width="110"
                allowFiltering={false}
              /> */}
              <ColumnDirective
                field="BillableTime"
                headerText="Billable Time"
                width="120"
                allowFiltering={false}
              />
              <ColumnDirective
                field="Totalhours"
                headerText="Total hours"
                width="120"
                allowFiltering={false}
              />
              <ColumnDirective
                field="status"
                headerText="Status"
                width="120"
                allowFiltering={false}
              />
            </ColumnsDirective>
            <Inject services={[Toolbar, ExcelExport, Page, Sort]} />
          </GridComponent>
        </div>
      </PageContainer>
    </>
  )
}
