import { Avatar, Box } from '@material-ui/core'
import { ExitToAppOutlined as ExitIcon, AccountBox as Profile } from '@material-ui/icons'
import { Role } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory } from 'react-router-dom'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ListIcon from '@material-ui/icons/List'
import { useCurrentUser } from '../../../../graphql/hooks/useCurrentUser/useCurrentUser'
import IconButton from '../../panel/icon-button/IconButton'
import SettingsMenu, { SettingsItem } from '../../panel/settings-menu/SettingsMenu'
import { IProps } from './Interface'
import useStyles from './Styles'

const LoggedUser: React.FC<IProps> = (props) => {
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null)
  const { data, logout, hasAnyRole } = useCurrentUser()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget)
  }
  const handleClose = () => {
    setAnchor(null)
  }
  // const comingSoon = (name: string) => {
  //   alert(`${name} coming soon!`)
  // }
  const onLogout = () => {
    handleClose()
    logout()
  }
  const history = useHistory()
  const editForm = () => {
    handleClose()
    history.push('/TherapistInviteUpdate')
  }
  const resourceDoc = () => {
    handleClose()
    const url = 'https://imattercolorado.org/content/resource.pdf'
    window.open(url, '_blank')
  }
  const Training = () => {
    handleClose()
    history.push('/Trainingdocument')
  }
  const classes = useStyles()
  let isTherapist = false
  let isAgenctSupervisorProvider = false

  if (data) isTherapist = hasAnyRole(Role.Therapist, Role.SchoolTherapist)
  if (data) isAgenctSupervisorProvider = hasAnyRole(Role.AgencySupervisorProvider)

  const settings = [
    // {
    //   icon: <DistrictIcon fontSize="small" />,
    //   name: 'User Settings',
    //   visible: hasAnyRole(Role.TeleteachersAdmin, Role.SchoolAdmin),
    //   handleClick: () => {
    //     history.push('/users/create')
    //   }
    // },
    // {
    //   icon: <DistrictIcon fontSize="small" />,
    //   name: 'District Settings',
    //   visible: hasAnyRole(Role.SchoolAdmin),
    //   handleClick: () => {
    //     comingSoon('district settings')
    //   }
    // },
    // {
    //   icon: <SettingsIcon fontSize="small" />,
    //   name: 'Account Settings',
    //   visible: false,
    //   handleClick: () => {
    //     comingSoon('account settings')
    //   }
    // },
    {
      icon: <AccountCircleIcon fontSize="small" />,
      name: 'Profile',
      visible: isTherapist,
      handleClick: editForm
    },
    {
      icon: <AccountCircleIcon fontSize="small" />,
      name: 'Profile',
      visible: isAgenctSupervisorProvider,
      handleClick: editForm
    },
    {
      icon: <ListIcon fontSize="small" />,
      name: 'Training Docs',
      visible: isTherapist,
      handleClick: Training
    },
    {
      icon: <ListIcon fontSize="small" />,
      name: 'Resources',
      visible: isTherapist,
      handleClick: resourceDoc
    },
    {
      icon: <ExitIcon fontSize="small" />,
      name: 'Log Out',
      visible: true,
      handleClick: onLogout
    }
  ]

  return (
    <>
      <IconButton
        {...props}
        bordered
        style={{
          borderRadius: '24px',
          padding: '8px 18px',
          fontFamily: 'Brandon Grotesque',
          overflow: 'hidden',
          maxWidth: '300px'
        }}
        onClick={handleClick}
      >
        <span
          className={classes.nameLabel}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%'
          }}
        >
          {data?.currentUser.name}
        </span>
        {/* @todo:  different user avatars or profile pictures */}
        <Avatar
          className={classes.avatar}
          alt={data?.currentUser.name}
          src="https://teleteachers.com/wp-content/uploads/2019/08/Homepage_TeleTeachers_Homepage_Match-SLPs_Color.png"
        />
      </IconButton>
      <SettingsMenu
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={handleClose}
      >
        {data &&
          settings
            .filter((setting) => setting.visible)
            .map((setting) => (
              <SettingsItem onClick={setting.handleClick} key={setting.name}>
                <Box mr={1}>{setting.icon}</Box>
                <div style={{ marginTop: '-7px' }}>{setting.name}</div>
              </SettingsItem>
            ))}
      </SettingsMenu>
    </>
  )
}

export default LoggedUser
