import React, { useEffect } from 'react'
import { ButtonGroup, Button } from '@material-ui/core'
import { withAuth } from '../../components/HOC/chat/with-auth/withAuth'
import { withDM } from '../../components/HOC/chat/with-dm/withDM'
import { withUserList } from '../../components/HOC/chat/with-user-list/withUserList'
import { withChatGroup } from '../../components/HOC/chat/with-chat-group/withChatGroup'
import { ChatWindow } from '../../components/ui/chat/chat-window/ChatWindow'
import { ChatNavBar } from '../../components/ui/chat/chat-nav-bar/ChatNavBar'
import { DMDialog } from '../../components/ui/chat/dm-dialog/DMDialog'
import { ChatDialog } from '../../components/ui/chat/chat-dialog/ChatDialog'
import { InviteDialog } from '../../components/ui/chat/invite-dialog/InviteDialog'

const { REACT_APP_CHAT_APP_URL } = process.env
const withChatHOCs = (component) => withAuth(withUserList(withDM(withChatGroup(component))))

export const MessagesRootView = () => {
  // ({
  //   credentialsLoading,
  //   chatCredentials,
  //   getUsers,
  //   getDMUsers,
  //   getCurrentChatUsers,
  //   chatMembersLoading,
  //   getChatList,
  //   usersAvailable,
  //   startDirectMessaging,
  //   startChatGroup,
  //   inviteToChatGroup,
  //   fetchChatGroupMembers
  // }) => {
  //   const [openDM, setOpenDM] = React.useState(false)
  //   const [openChat, setOpenChat] = React.useState(false)
  //   const [openInvite, setOpenInvite] = React.useState(false)

  const hostName = window.location.origin
  const redirectUrl = `${hostName}/chat/`
  useEffect(() => {
    window.open(redirectUrl, '_blank', 'noopener,noreferrer')
    window.history.back()
  }, [redirectUrl])

  return (
    <>
      {/* {usersAvailable() && (
          <>
            <DMDialog
              handleSubmit={startDirectMessaging}
              users={getDMUsers()}
              open={openDM}
              setOpen={setOpenDM}
            />
            <ChatDialog
              handleSubmit={startChatGroup}
              users={getUsers()}
              open={openChat}
              setOpen={setOpenChat}
            />
            <InviteDialog
              handleSubmit={inviteToChatGroup}
              users={getCurrentChatUsers()}
              fetchCurrentChatUsers={fetchChatGroupMembers}
              loading={chatMembersLoading}
              chats={getChatList()}
              open={openInvite}
              setOpen={setOpenInvite}
            />
          </>
        )}
        <ChatNavBar title="Messages">
          {usersAvailable() && (
            <ButtonGroup color="primary" aria-label="contained primary button group">
              <Button onClick={() => setOpenChat(true)}>Create chat</Button>
              <Button onClick={() => setOpenInvite(true)}>Invite to chat</Button>
              <Button onClick={() => setOpenDM(true)}>Send DM</Button>
            </ButtonGroup>
          )}
        </ChatNavBar>
        <ChatWindow
          token={!credentialsLoading && chatCredentials?.authToken}
          chatUrl={REACT_APP_CHAT_APP_URL}
        /> */}
    </>
  )
}
