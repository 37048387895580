import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles(({ palette }: Theme) => ({
  title: {
    textTransform: 'uppercase',
    margin: '0px 32px'
  },
  icon: {
    backgroundColor: palette.background.default
  }
}))
