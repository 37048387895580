import React, { useMemo, useEffect, useRef, useState, useCallback } from 'react'
import { Form, FormRenderProps, Field } from 'react-final-form'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Grid } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { useTranslation } from 'react-i18next'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { UseParamsType } from 'types'
import { RegistrationNewFormModel } from './RegistrationFormModel'
import useStyles from './Styles'

// interface ListTokenParams extends UseParamsType {
//   loc: string
// }
interface WaitlistformProps {
  nextStep: () => void
  prevStep: () => void
  values: any
  handleChange: (input: any) => any
}

export const WaitListForm = (props: WaitlistformProps) => {
  const { t, i18n } = useTranslation()
  // const { loc } = useParams<ListTokenParams>()
  // useMemo(() => {
  //   if (loc === 'en' || loc === 'es') i18n.changeLanguage(loc || 'en')
  //   else i18n.changeLanguage('en')
  // }, [i18n, loc])
  const { nextStep, handleChange, values, prevStep } = props
  const classes = useStyles()
  const history = useHistory()
  const initialData: any = useMemo(() => {
    const init = {
      availability_notes: values?.availability_notes,
      preference_notes: values?.preference_notes
    }

    return init
  }, [values])
  const prevHandler = (e) => {
    e.preventDefault()
    prevStep()
  }
  const onSubmit = async (formvalues: RegistrationNewFormModel) => {
    const newvalues = {
      ...values,
      availability_notes: formvalues.availability_notes,
      preference_notes: formvalues.preference_notes
    }
    handleChange(newvalues)
    nextStep()
  }
  const required = (value) => (value ? undefined : 'Required')
  const renderForm = (renderProps: FormRenderProps<RegistrationNewFormModel>) => {
    const { handleSubmit, values, submitting } = renderProps

    return (
      <div className={`${classes.waitlistroot}`}>
        <form>
          <Grid container className={classes.popin}>
            <Grid item xs={12} sm={12} md={12} className={classes.pt15}>
              <Field name="availability_notes" validate={required}>
                {({ input, meta }) => (
                  <>
                    <div
                      className={`${classes.fs1} ${
                        meta.error && meta.touched ? classes.texterror : ''
                      }`}
                    >
                      {t('waitlist.note1')}
                    </div>
                    <div>
                      <textarea {...input} maxLength={1024} className={classes.textaream} />
                    </div>
                  </>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={classes.pt15}>
              <Field name="preference_notes" validate={required}>
                {({ input, meta }) => (
                  <>
                    <div
                      className={`${classes.fs1} ${
                        meta.error && meta.touched ? classes.texterror : ''
                      }`}
                    >
                      {t('waitlist.note2')}
                    </div>
                    <div className={`${classes.fs1}`}>
                      <textarea {...input} maxLength={1024} className={classes.textaream} />
                    </div>
                  </>
                )}
              </Field>
              <div style={{ paddingTop: '15px' }}>
                {t('waitlist.note3')}
                {', '}
                {t('waitlist.ccsT1')}
                {', '}
                {t('waitlist.ccsT2')}{' '}
                <a
                  href={
                    i18n.language === 'es'
                      ? 'https://coloradocrisisservices.org/es/'
                      : 'https://coloradocrisisservices.org/'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('waitlist.ccsT3')}
                </a>{' '}
                {t('waitlist.ccsT4')}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={classes.pt15}>
              <div>
                <Button
                  variant="outlined"
                  startIcon={<NavigateBeforeIcon>Next</NavigateBeforeIcon>}
                  className={classes.customBtnCls}
                  onClick={prevHandler}
                >
                  {t('registrationform.back')}
                </Button>
                <Button
                  className={`${classes.customBtnCls} ${classes.registrationWaitlistStep} ${classes.ml20}`}
                  endIcon={<NavigateNextIcon>Next</NavigateNextIcon>}
                  onClick={handleSubmit}
                >
                  {t('registrationform.next')}
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    )
  }

  return (
    <Form<RegistrationNewFormModel>
      onSubmit={onSubmit}
      initialValues={initialData}
      render={renderForm}
    />
  )
}
