import { makeStyles, createStyles, Theme, createMuiTheme } from '@material-ui/core/styles'
import createTheme from './create-theme'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary
    },
    alignRightt: {
      float: 'right'
    },
    text: {
      fontFamily: 'Brandon Grotesque',
      fontSize: '16px',
      '& .MuiTableCell': {
        fontFamily: 'Brandon Grotesque !important'
      }
    },
    fileUpload: {
      height: '80px',
      border: '1px solid #d5d5da',
      padding: '5px 15px',
      fontFamily: 'Brandon Grotesque',
      '& .fileName': {
        display: 'flex',
        justifyContent: 'space-between'
      }
    },
    uploaderstyle: {
      '& .e-upload-actions': {
        display: 'none'
      },
      '& .e-upload .e-file-select-wrap .e-btn': {
        fontFamily: 'Brandon Grotesque'
      },
      '& .e-upload .e-file-select-wrap .e-file-drop': {
        fontFamily: 'Brandon Grotesque !important'
      }
    },
    errorcol: {
      color: '#f44336 !important'
    },
    iBlock: {
      display: 'inline-block'
    },
    pb10: {
      paddingBottom: '10px'
    },
    dropDown: {
      marginTop: '10px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '4px',
      width: '100%'
    },
    alignPadding: {
      paddingTop: '5px'
    },
    nopointer: {
      pointerEvents: 'none'
    },
    textField: {
      border: '1px solid #000'
    },
    forntSizes: {
      fontSize: '16px',
      fontFamily: 'Brandon Grotesque'
    },
    forntSizesline: {
      fontSize: '16px',
      fontFamily: 'Brandon Grotesque',
      borderBottom: 'none'
    },
    selectDropdown: {
      bacgroundColor: '#fff',
      border: '1px solid black',
      backgroundColor: 'white',
      fontSize: '13px',
      fontFamily: 'Brandon Grotesque',
      height: 30,
      width: '100%',
      paddingTop: 0,
      paddingLeft: 0,
      borderRadius: 3,
      '& .MuiFilledInput-input': {
        backgroundColor: '#fff !important'
      },
      '& .MuiSelect-filled.MuiSelect-filled': {
        backgroundColor: '#fff !important'
      },
      '& div': {
        padding: '6px'
      },
      selectMenu: {
        bacgroundColor: '#fff',
        padding: '0px !important',
        fontSize: '16px'
      }
    },
    selectDropdowns: {
      bacgroundColor: '#fff',
      border: '1px solid black',
      backgroundColor: 'white',
      fontSize: '13px',
      height: 30,
      width: '100%',
      paddingTop: 0,
      paddingLeft: 0,
      borderRadius: 3,
      '& .MuiFilledInput-input': {
        backgroundColor: '#fff !important'
      },
      '& .MuiSelect-filled.MuiSelect-filled': {
        backgroundColor: '#fff !important'
      },
      '& div': {
        padding: '6px'
      },
      selectMenu: {
        bacgroundColor: '#fff',
        padding: '0px !important',
        fontSize: '16px'
      }
    },
    edit: {
      backgroundColor: '#2C9BD6',
      borderRadius: '50%',
      color: 'white',
      minWidth: '50px',
      height: '50px'
    },
    name: {
      fontSize: '20px',
      margin: '0px 0px 0px 15px',
      color: ' #F1583A',
      fontFamily: 'Brandon Grotesque'
    },
    nameSdh: {
      fontSize: '20px',
      margin: '0px 0px 0px 15px',
      color: '#293f70',
      fontFamily: 'Brandon Grotesque',
      textAlign: 'justify'
    },
    nameSd: {
      fontSize: '16px',
      padding: '0px 15px 5px 15px',
      color: '#293f70',
      // fontFamily: 'Brandon Grotesque-Regular,sans-serif',
      fontFamily: 'Brandon Grotesque',
      textAlign: 'justify'
    },
    namecard: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Brandon Grotesque',
      '& .MuiInput-underline:before': {
        borderBottom: 'none'
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none'
      }
    },
    buttonadjust: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    flex: {
      display: 'flex'
    },
    flexStretch: {
      alignItems: 'stretch'
    },
    namelabel: {
      margin: '0px 0px 0px 15px'
    },
    avatar: {
      width: 70,
      height: 70
    },
    dmar: {
      marginTop: '20px'
    },
    sessionStyle: {
      margin: '0px 0px 0px 30px',
      backgroundColor: '#4b99d133',
      padding: '14px 28px',
      marginTop: '16px',
      display: 'flex',
      borderRadius: '5px'
    },
    sessionstyl: {
      backgroundColor: '#4b99d133',
      marginLeft: '10px',
      borderRadius: '5px',
      padding: '8px 20px'
    },
    clientname: {
      fontWeight: 'bold',
      color: '#ff6400',
      fontSize: '22px'
    },
    age: {
      float: 'left',
      padding: '10px'
    },
    session: {
      margin: '0px 0px 0px 30px',
      backgroundColor: '#4b99d133',
      padding: '11px 20px',
      display: 'flex',
      borderRadius: '7px'
    },
    sessionSurvey: {
      backgroundColor: '#4b99d133',
      padding: '11px 20px',
      display: 'flex'
    },
    surveyBorder: {
      borderBottom: '20px solid #4b99d133',
      borderLeft: '5px solid #4b99d133',
      borderRight: '5px solid #4b99d133',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px'
    },
    makeStylesRoot1258: {
      width: '100%'
    },
    studentheaders: {
      fontSize: '20px',
      fontFamily: 'Brandon Grotesque',
      color: '#4B99D1',
      letterSpacing: '0.5px',
      margin: '0px 0px 0px 10px'
    },
    label: {
      fontSize: '16px',
      color: '#0D1F48',
      margin: '0px 0px 0px 15px',
      fontWeight: 600,
      fontFamily: 'Brandon Grotesque'
    },
    allcards: {
      border: '1px solid #E4E4E4',
      borderRadius: '5px',
      height: '100%'
    },
    headersalign: {
      padding: 15,
      borderBottom: '1px solid #E4E4E4',
      display: 'flex'
    },
    pad15: {
      padding: 15,
      height: 'auto'
    },
    studentlabels: {
      fontSize: '16px',
      color: '#0D1F48',
      fontWeight: 600,
      fontFamily: 'Brandon Grotesque',
      margin: '10px 0px'
    },
    studentlabelsize: {
      width: '117px'
    },
    studentlabelsize1: {
      width: '140px'
    },
    blueback: {
      fontFamily: 'Brandon Grotesque',
      fontSize: '18px',
      color: '#6E6E6E',
      margin: '0px -8px'
    },
    bluelabel: {
      fontFamily: 'Brandon Grotesque',
      fontSize: '20px',
      color: '#000000',
      marginLeft: '5px'
    },
    studentdata: {
      color: '#000000',
      fontSize: '16px',
      fontFamily: 'Brandon Grotesque',
      margin: '10px 0px',
      width: '100%',
      wordWrap: 'break-word'
    },
    straightline: {
      border: 'none',
      borderTop: '2px dashed #E4E4E4',
      borderBottom: '5px'
    },
    straigh: {
      border: 'none',
      borderTop: '2px dashed #E4E4E4',
      marginTop: '15px',
      marginLeft: '25px',
      marginRight: '25px'
    },
    straightlineStyle: {
      marginTop: '15px',
      marginBottom: '15px',
      border: 'none',
      borderTop: '2px dashed #E4E4E4'
    },
    sessionsize: {
      width: '120px'
    },
    notes: {
      overflowY: 'auto',
      maxHeight: '86px',
      minHeight: '30px'
    },
    cancel: {
      textTransform: 'capitalize',
      padding: '6px 25px',
      border: '1px solid #C5C5C5',
      borderRadius: 25,
      color: '#000000',
      fontSize: '12',
      fontWeight: 600
    },
    save: {
      backgroundColor: '#F1583A',
      textTransform: 'capitalize',
      padding: '6px 25px',
      marginLeft: '5px',
      borderRadius: 25,
      color: '#FFFFFF',
      fontSize: '12',
      fontWeight: 600,
      '&:hover': {
        background: '#F2684D'
      }
    },
    saveBtn: {
      // backgroundColor: '#F1583A',
      textTransform: 'capitalize',
      padding: '10px 10px',
      borderRadius: 25,
      color: '#F1583A',
      fontSize: '16px',
      fontWeight: 600,
      fontFamily: 'Brandon Grotesque',
      textAlign: 'center',
      width: ' 150px',
      marginRight: '10px'
    },
    saveBtnnew: {
      // backgroundColor: '#F1583A',
      textTransform: 'capitalize',
      padding: '10px 0px',
      borderRadius: 25,
      color: '#F1583A',
      fontSize: '16px',
      fontWeight: 600,
      fontFamily: 'Brandon Grotesque',
      textAlign: 'center',
      width: ' 114px',
      marginRight: '1px'
    },
    buttongroup: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: 12
    },
    specificedit: {
      width: '62%'
    },
    contactedit: {
      width: '62%'
    },
    sx: {
      width: '100%',
      height: '40',
      marginRight: '15',
      fontFamily: 'Brandon Grotesque',
      margin: '0px 0px 10px 0px'
    },
    noteModaltitle: {
      '& h2': {
        fontWeight: 'bold',
        fontFamily: 'Brandon Grotesque'
      }
    },
    sx1: {
      float: 'right',
      margin: '20px 0px 0px 0px',
      fontFamily: 'Brandon Grotesque'
    },
    div: {
      margin: '20px 0px 0px 0px',
      fontFamily: 'Brandon Grotesque'
    },
    pad12: {
      padding: 12
    },
    // w10: {
    //   width: '100px'
    // },
    fullwidth: {
      width: '100%'
    },
    frequencysize: {
      width: '193px'
    },
    editnotes: {
      height: '75px !important',
      overflow: 'auto !important',
      color: '#000000',
      fontSize: '16px',
      fontFamily: 'Brandon Grotesque',
      width: ' 100% !important ',
      margin: 0
    },
    default: {
      display: 'flex',
      width: '100%'
    },
    updatetable: {
      boxShadow: '0px 1px 6px #00000029',
      borderRadius: '5px',
      fontFamily: 'Brandon Grotesque'
    },
    headers: {
      color: '#0D1F48',
      fontFamily: 'Brandon Grotesque',
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0.5px'
    },
    table: {
      minWidth: 650
    },
    data: {
      color: '#000000',
      fontFamily: 'Brandon Grotesque',
      fontSize: '16px',
      fontWeight: 100,
      letterSpacing: '0.5px'
    },
    MuiCircularProgress: {
      color: '#F1583A'
    },
    completedIcon: {
      marginBottom: '-8px',
      marginRight: '10px',
      fontFamily: 'Brandon Grotesque'
    },
    actionHeaders: {
      color: '#0D1F48',
      fontFamily: 'Brandon Grotesque',
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0.5px'
      // alignItems: 'right'
      // width: '25px'
    },
    saveBtnn: {
      // backgroundColor: '#F1583A',
      textTransform: 'capitalize',
      padding: '10px 0px',
      borderRadius: 25,
      color: '#F1583A',
      fontFont: 'Brandon Grotesque !important',
      fontSize: '16px',
      fontWeight: 600,
      textAlign: 'left',
      width: ' 150px',
      marginRight: '10px',
      '& .makeStyles-saveBtnn-324': {
        fontFamily: 'Brandon Grotesque'
      }
    },
    bottom: {
      color: '!important #F1583A',
      marginLeft: '540px'
    },
    root: {
      flexGrow: 1,
      fontFamily: 'Brandon Grotesque',
      borderBottom: 'none'
    },
    tdline: {
      borderBottom: 'none'
    },
    overrides: {
      backgroundColor: 'white',
      color: 'black'
    },
    tabbackground: {
      backgroundColor: 'white',
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
    },
    tabRoot: {
      color: '#0D1F48',
      fontWeight: 600,
      textTransform: 'capitalize',
      fontSize: 18,
      fontFamily: 'Brandon Grotesque'
    },
    indicator: {
      backgroundColor: '#F1583A'
    },
    selected: {
      color: '#F1583A'
    },
    mail: {
      color: '#000000',
      fontSize: '16px',
      fontFamily: 'Brandon Grotesque',
      margin: '10px 0px',
      width: '100%',
      wordWrap: 'break-word',
      maxWidth: '198px'
    },
    hideL: {
      textDecoration: 'none',
      color: '#FFFFFF'
    },
    dels: {
      color: '#F1583A',
      cursor: 'pointer',
      marginTop: '6px',
      float: 'left'
    },
    labelText: {
      fontSize: '16px',
      color: '#0D1F48',
      fontWeight: 'bold',
      fontFamily: 'Brandon Grotesque'
    },
    ctext: {
      fontWeight: 400,
      lineHeight: '2em',
      color: 'black',
      fontSize: '1rem',
      fontFamily: 'Brandon Grotesque'
    },
    brandonFF: {
      fontFamily: 'Brandon Grotesque'
    },
    errorColor: {
      color: theme.palette.error.dark
    },
    textarea: {
      '&:focus-visible': {
        outline: 'none !important'
      }
    },
    input: {
      '&:focus-visible': {
        outline: 'none !important'
      }
    },
    hide: {
      display: 'none'
    },
    dochead: {
      color: '#0D1F48',
      fontSize: 18,
      fontFamily: 'Brandon Grotesque',
      fontWeight: 600,
      letterSpacing: 0.5
      // width: '30%'
    },
    docsubH: {
      color: '#0D1F48',
      fontSize: 16,
      fontFamily: 'Brandon Grotesque',
      fontWeight: 600,
      letterspacing: 0.5,
      margin: 0
    },
    docD: {
      margin: 0,
      fontSize: 16,
      fontFamily: 'Brandon Grotesque'
    },
    ltblue: {
      backgroundColor: '#EAF3F9',
      padding: '0px 5px 0px 5px'
    },
    collapsetheme: {
      backgroundColor: 'white',
      paddingLeft: 10,
      paddingRight: 10,
      marginBottom: 5,
      borderRadius: 10,
      display: 'flex'
    },
    ptb10: {
      paddingTop: '10px',
      paddingBottom: '10px'
    },
    mar0: {
      margin: 0
    },
    marBt: {
      marginBottom: 0
    },
    marbtneg: {
      marginBottom: '-6px'
    },
    docTableAlign: {
      padding: 15
    },
    docbuttonsalign: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    docbuttonsalignStyle: {
      display: 'flex',
      alignItems: 'center'
    },
    filteralign: {
      marginLeft: 15,
      marginRight: 10,
      color: '#0D1F48',
      fontSize: 18,
      fontFamily: 'Brandon Grotesque',
      fontWeight: 600,
      letterSpacing: 0.5
    },
    alignright: {
      textAlign: 'right'
    },
    alignleft: {
      textAlign: 'left'
    },
    aligncenter: {
      textAlign: 'center'
    },
    tabconAdjust: {
      overflow: 'unset'
    },
    docsession: {
      margin: 0,
      display: 'flex',
      padding: '11px 20px',
      borderRadius: 5,
      backgroundColor: '#4b99d133'
    },
    doccardpad: {
      padding: 15
    },
    pr15: {
      paddingRight: '15px'
    },
    wraptext: {
      wordWrap: 'break-word'
    },
    addSign: {
      textAlign: 'center',
      fontSize: '16px',
      fontFamily: 'Brandon Grotesque'
    }
  })
)

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#F1583A'
    }
  },
  overrides: {
    MuiTabs: {
      flexContainer: {
        display: 'flex',
        justifyContent: 'space-between'
      }
    },
    MuiInputBase: {
      multiline: {
        fontFamily: 'Brandon Grotesque'
      }
    }
  }
})
