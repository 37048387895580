import { Box, styled, Typography } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'

interface SectionProps {
  title: string
  subtitle?: string
  addon?: React.ReactNode
}
const Header = styled(Box)(() => ({
  paddingBottom: '18px'
}))
const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontWeight: 'normal'
}))
const Subtitle = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 'normal',
  paddingBottom: '28px'
}))

export const Section = (props: PropsWithChildren<SectionProps>) => {
  const { addon, children, subtitle, title } = props

  return (
    <Box padding="10px">
      <Header>
        <Box display="flex" alignItems="center" justifyContent="space-between" paddingBottom="18px">
          <Title variant="h1" style={{ fontFamily: 'Brandon Grotesque' }}>
            {title}
          </Title>
          {addon}
        </Box>
        {subtitle && <Subtitle style={{ fontFamily: 'Brandon Grotesque' }}>{subtitle}</Subtitle>}
      </Header>
      {children}
    </Box>
  )
}
