import { useContext } from 'react'
import { AuthenticationContext } from '../types'
import { AuthenticatedContext } from '../components/providers/auth/context'

export const useAuth: () => AuthenticationContext = (): AuthenticationContext => {
  const context: AuthenticationContext | null = useContext(AuthenticatedContext)

  if (!context) throw new Error('`useAuth` hook must be used within a `AuthProvider` component')

  return context
}
