import React from 'react'
import { Box, TableRow, TableCell } from '@material-ui/core'

interface NoDataProps {
  message: string
  colSpan: number
}

export const NoData = ({ message, colSpan }: NoDataProps) => (
  <TableRow>
    <TableCell colSpan={colSpan}>
      <Box display="flex" alignItems="center">
        {message}
      </Box>
    </TableCell>
  </TableRow>
)
